import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";

const IconCard = ({ iconName, IconComponent }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isTextCopied, setTextCopied] = useState(false);
  const [isSvgCopied, setSvgCopied] = useState(false);

  const handleTextCopy = () => {
    CopyToClipboard(iconName);
    setTextCopied(true);
    setTimeout(() => setTextCopied(false), 1000);
  };

  const handleSvgCopy = () => {
    CopyToSVGClipboard(<IconComponent className="some-class" />);
    setSvgCopied(true);
    setTimeout(() => setSvgCopied(false), 1000);
  };

  const CopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const CopyToSVGClipboard = (iconComponent) => {
    const svgString = ReactDOMServer.renderToStaticMarkup(iconComponent);
    navigator.clipboard.writeText(svgString);
  };

  const iconColStyle = {
    boxShadow: `${isHovered ? "0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset" : " 0px 0px 2px rgba(0,0,0,0.2)"}`,
    borderRadius: "5px",
    padding: "15px",
  };
  const boxStyle = {
    right: "5px",
    top: "5px",
    filter: "drop-shadow(2px 4px 6px rgba(0,0,0,0.2))",
    gap: "5px",
  };

  return (
    <div className="text-center position-relative" style={iconColStyle} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <div className="icon text-primary">
        <IconComponent className="w-32 h-32" />
      </div>
      <div className="d-flex align-items-center justify-content-center mt-10 flex-wrap">
        <div className="text-muted font-medium">{iconName}</div>
        {isHovered && (
          <div className="d-flex flex-column position-absolute align-items-end" style={boxStyle}>
            <button className="btn btn-sm btn-primary font-10 line-height-15" disabled={isTextCopied} onClick={handleTextCopy}>
              {isTextCopied ? 'Copied!' : 'Copy'}
            </button>
            <button className="btn btn-sm btn-primary font-10 line-height-15" disabled={isSvgCopied} onClick={handleSvgCopy}>
              {isSvgCopied ? 'Copied!' : 'Copy SVG'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default IconCard;
