import React, { useState } from "react";
import EmptyReturnTab from "../Components/EmptyReturnTab";
import TopEmptyCard from './Components/TopEmptyCard';
import SearchFilter from './Components/searchFilter';
import EmptyTable from "./Components/EmptyTable";
import TableHeader from "./Components/TableHeader";

const ssls = [
    {
        code: 1,
        title: "CHV"
    },
    {
        code: 2,
        title: "BAL"
    },
    {
        code: 3,
        title: "MAERSK"
    },
    {
        code: 4,
        title: "MSC"
    },
    {
        code: 5,
        title: "COSCO"
    },
    {
        code: 6,
        title: "CMA CGM"
    }
]

const EmptyReturnLocations = () => {
    return (
        <div className="position-relative subheader-wrapper">
            <EmptyReturnTab activeTab={3} />
            <div className="page-fluid">
                <div className="top-page">
                    <SearchFilter />
                    <TopEmptyCard />
                    <table className="table-card table-card--borderless w-100">
                        <TableHeader />
                        {ssls.map((ssl) =>
                            <EmptyTable data={ssl} />
                        )}
                    </table>
                </div>
            </div>
        </div>
    );
};

export default EmptyReturnLocations;
