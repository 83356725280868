import React, { useState } from "react";

import { IconDownload, IconSendEmail } from "../../../../common/icons/index";

const columns = [
  "Customer",
  "0-30 Days",
  "30-60 Days",
  "60-90 Days",
  "90-120 Days",
  "120+ Days",
  "Total",
];

const tableData = [];
for (let i = 0; i < 20; i++) {
  tableData.push({
    id: i,
    customer: "HMM Co. Ltd.",
    first: 435 + 10 * i,
    second: 3 + 10 * i,
    third: 19 + 10 * i,
    fourth: 14 + 10 * i,
    fifth: 14 + 10 * i,
  });
}

const options = [
  { value: "jessica.hanson@example.com", label: "jessica.hanson@example.com" },
  { value: "john.doe@example.com", label: "john.doe@example.com" },
  { value: "alex@example.com", label: "alex@example.com" },
];

const StaticTableContent = () => {
  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 364px)" }}
      >
        <table className="table table-card table-card--ls">
          <thead>
            <tr>
              <th scope="col">Customer</th>
              <th scope="col" className="text-right">
                0-30 Days
              </th>
              <th scope="col" className="text-right">
                30-60 Days
              </th>
              <th scope="col" className="text-right">
                60-90 Days
              </th>
              <th scope="col" className="text-right">
                90-120 Days
              </th>
              <th scope="col" className="text-right">
                120+ Days
              </th>
              <th scope="col" className="text-right">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((tdata) => {
              return (
                <tr key={tdata.id}>
                  <td>{tdata.customer}</td>
                  <td className="text-right">${tdata.first}</td>
                  <td className="text-right">${tdata.second}</td>
                  <td className="text-right">${tdata.third}</td>
                  <td className="text-right">${tdata.fourth}</td>
                  <td className="text-right">${tdata.fifth}</td>
                  <td className="text-right font-weight-500">
                    $
                    {tdata.first +
                      tdata.second +
                      tdata.third +
                      tdata.fourth +
                      tdata.fifth}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default StaticTableContent;
