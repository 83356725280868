import React, { useState } from "react";
import { IconAngleArrowRight } from "../../../../common/icons/index";
import Pagination from "../../common-elements/Pagination";
import RadialChart from "../../common-elements/RadialChart";

const cardItems = [
  {
    id: 1,
    count: 540,
    title: "Completed",
    price: "17,710",
    percent: 40,
  },
  {
    id: 2,
    count: 703,
    title: "Approved",
    price: "4,002",
    percent: 30,
  },
  {
    id: 3,
    count: 740,
    title: "Invoiced",
    price: "2,980",
    percent: 30,
  },
  {
    id: 4,
    count: 994,
    title: "Unapproved",
    price: "6,133",
    percent: 10,
  },
  {
    id: 5,
    count: 423,
    title: "Partially paid",
    price: " 3,871",
    percent: 10,
  },
  {
    id: 6,
    count: 561,
    title: "Paid",
    price: "8,009",
    percent: 5,
  },
];

const TopPage = () => {
  const [isCardActive, setCardActive] = useState(1);

  return (
    <div className="top-page">
      <div className="pb-15 d-flex align-items-center">
        <h4 className="mb-0">Sub Accounts Receivable</h4>
        {/* Pagination  */}
        <Pagination />
      </div>
      {/* Cards Row */}
      <div className={`form-row ${isCardActive ? "hasactive" : ""}`}>
        {cardItems.map((cardItem) => (
          <div className="col-md-2 my-2">
            <div
              className={`ncard d-flex flex-column justify-content-between p-3 ${
                isCardActive === cardItem.id ? "active" : ""
              }`}
              onClick={() => setCardActive(cardItem.id)}
            >
              <div className="d-flex align-items-center mb-10">
                <div className="mr-1 sbox sbox--default px-2 h-100">
                  {cardItem.count}
                </div>
                <div className="ml-2">
                  <h6 className="font-weight-bold mb-0">{cardItem.title}</h6>
                  <span className="text-muted">{cardItem.date}</span>
                </div>
                <div className="ml-auto">
                  <IconAngleArrowRight />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between form-row mt-2">
                <div className="col-8">
                  {cardItem.title == "Unapproved" ? (
                    <div className="font-weight-bold font-20 text-danger">
                      ${cardItem.price}
                      <span className="font-size-regular">.45</span>
                    </div>
                  ) : (
                    <div className="font-weight-bold font-20">
                      ${cardItem.price}
                      <span className="font-size-regular">.45</span>
                    </div>
                  )}
                </div>

                <div className="col-4 d-flex justify-content-end p-0">
                  <RadialChart percent={cardItem.percent} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopPage;
