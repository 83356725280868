import React from 'react';
import { IconDownload, Icondots } from '../../../common/icons';

const Group = ({className}) => {
  return (
    <div className="dlist" style={{ height: 'calc(100% - 111px)' }}>
      <div className={`clist__card mb-1 ${className} `}>
        <div className="dlist__info d-flex align-items-center pl-2 py-2 pr-3">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                OEC Group
              </a>
            </h5>
            <div className=" text-muted mb-0">
              <span>I can't reach to Josh. Could you ask..</span>
            </div>
          </div>
          <div class="">
            <Icondots />
          </div>
        </div>
      </div>
      <div className="clist__card mb-1 bg-secondary">
        <div className="dlist__info d-flex align-items-center pl-2 py-2 pr-3">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
              className="avatar rounded-circle border-white"
              style={{ border: '2px solid' }}
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-white">
                Port City
              </a>
            </h5>
            <div className=" text-white text-white mb-0">
              <span>Hi guys...</span>
            </div>
          </div>
          <div class="">
            <Icondots />
          </div>
        </div>
      </div>
      <div className={`clist__card mb-1 ${className} `}>
        <div className="dlist__info d-flex align-items-center pl-2 py-2 pr-3">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                OEC Group
              </a>
            </h5>
            <div className=" text-muted mb-0">
              <span>Check you mailbox</span>
            </div>
          </div>
          <div class="">
            <Icondots />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Group;
