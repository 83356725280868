import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IconCalendar, IconCopy, IconLink, IconNewTab, IconPen } from '../../../common/icons';

const LiveShareModal = (props) => {
  const { showModal, onCancel } = props;
  return (
    <Modal show={showModal} centered animation={false} size="sm">
      <Modal.Header className='d-flex flex-column align-items-start' >
        <div className='font-20 font-weight-500 mb-10'>Live Tracking</div>
        <div className='text-gray-500 font-weight-400 '>Share a public link for others to view ETA and live location </div>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="form-row">
          <div className="form-group col-lg-10">
            <input type="text" className="form-control" placeholder="cloud.dsdsadasdasdasdada" />
          </div>
          <div className="form-group col-lg-2">
            <button
              className="btn btn-primary">
              <IconCopy/>
            </button>
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <IconNewTab className='mr-2'/>
          <div className ="font-12 font-weight-400"><a href="#" target="_blank">or open in new tab</a></div>
        </div>
        <hr></hr>
        <div>
          <div className="font-12 font-weight-500 mb-1">Link expiration</div>
          <div className="form-wrapper">
            <form>
              <div className="form-row d-flex align-items-center">
                
                <div className="col-md-2">
                  <div className="form-check form-group mb-0">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="24hr"
                      name="link-expiration"
                    />
                    <label className="form-check-label" for="24hr">
                      24hr
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-check form-group mb-0">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="Oneweek"
                      name="link-expiration"
                    />
                    <label className="form-check-label" for="Oneweek">
                      One week
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-check form-group mb-0">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="Custom"
                      name="link-expiration"
                    />
                    <label className="form-check-label" for="Custom">
                      Custom
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-0">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" MM/DD/YY"
                      />
                      <div className="input-icon">
                        <IconCalendar />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
       
      </Modal.Body>
      <Modal.Footer>
        <div className="">
            <button className="btn btn-outline-light" onClick={onCancel}>
            Close
            </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default LiveShareModal;