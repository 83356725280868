import { useState } from "react";
import { Modal } from "react-bootstrap";
import { IconPlus } from "../../../common/icons";
import TopCards from "../../../common/TopCards/TopCards";
import PortLogin from "./Components/PortLogin";
import SelectPortMarkets from "./Components/SelectPortMarkets";
import LoginCredential from './Components/LoginCredential';

const OnBoarding = ({}) => {
  const [showPortModal, setShowPortModal] = useState(false);

  const handleShowPortModal = () => {
    setShowPortModal(true);
  };
  const handleHidePortModal = () => {
    setShowPortModal(false);
  };

  const [showLoginCredentialModal, setLoginCredentialModal] = useState(false);

  const handleShowLoginModal = () => {
    setLoginCredentialModal(true);
    setShowPortModal(false);
  };
  const handleHideLoginModal = () => {
    setLoginCredentialModal(true);
    setShowPortLoginModal(false);
  };

  const [showPortLoginModal, setShowPortLoginModal] = useState(false);

  const ShowLoginModal = () => {
    setShowPortLoginModal(true);
    setShowPortModal(false);
  };
  const HideLoginModal = () => {
    setShowPortLoginModal(false);
    setShowPortModal(true);
  };

  const cardData = [
    {
      id: 1,
      cardMainName: 'Containers on Vessel',
      cardSubNameOne: 'On Hold',
      cardSubNameTwo: 'Released',
    },
    {
      id: 2,
      cardMainName: 'Empties Out',
      cardSubNameOne: 'Last Free Days',
      cardSubNameTwo: 'Pickup Appts',
    },

    {
      id: 3,
      cardMainName: 'Loads In',
      cardSubNameOne: 'In Port',
      cardSubNameTwo: 'In Yard',
    },
  ];
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center">
          <div className="d-flex flex-row align-items-center justify-content-between w-100">
            <div className="font-14 font-medium">Bookings</div>
            <div className="ml-auto">
              <button className="btn btn-success" onClick={handleShowPortModal}>
                <IconPlus className="mr-2" />
                Add New Booking
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-15 d-flex align-items-center">
            <h4 className="mb-0">757 Bookings</h4>
          </div>
          {/*Cards */}
          <TopCards cardData={cardData} />
        </div>
      </div>
      {showPortModal && (
        <SelectPortMarkets
          showModal={handleShowLoginModal}
          hideModal={handleHidePortModal}
        />
      )}
      {showLoginCredentialModal && (
        <LoginCredential
          showModal={handleShowLoginModal}
          showNextModal={ShowLoginModal}
          hideModal={handleHideLoginModal}
        />
      )}
      {showPortLoginModal && (
        <PortLogin showModal={ShowLoginModal} hideModal={HideLoginModal} />
      )}
    </div>
  );
};

export default OnBoarding;
