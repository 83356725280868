import React, { useState } from "react";
import {
  IconAngleArrowLeft,
  IconAngleArrowRight,
  IconPrinter,
  IconTrash,
} from "../../../common/icons";
import CombinedBillsPopup from "./combinedBillPopup";
import TopPage from "./elements/TopPage";

const CombinedBills = () => {
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center ">
          <div className="d-flex flex-row align-items-center">
            <button
              className="btn btn-outline-light"
              
            >
              <IconAngleArrowLeft />
            </button>
            <div className="d-flex ml-3 align-items-center">
              <h5 className="mb-0">C112383</h5>
              <span className="badge badge-brown ml-3">
                Billing
              </span>
            </div>
          </div>
          <div className="ml-auto position-relative d-flex">
            <button className="btn btn-link mr-1">
              <IconPrinter />
            </button>

            <button className="btn btn-link mr-1">
              <IconTrash />
            </button>
            <button
              className="btn btn-outline-light mr-1"
            >
              Cancel
            </button>
            <button
              className="btn btn-success mr-20"
            >
              Save Changes
            </button>

            <button className="btn btn-outline-light mr-2">
              <IconAngleArrowLeft />
            </button>
            <button className="btn btn-outline-light">
              <IconAngleArrowRight />
            </button>
          </div>
        </div>
      </div>
      <CombinedBillsPopup />
    </div>
  );
};

export default CombinedBills;
