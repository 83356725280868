import React, { useState } from "react";

const tabItems = [
  {
    id: 1,
    title: "Icons",
    path: "/documentation/icons"
  },
  {
    id: 2,
    title: "Custom Icons",
    path: "/documentation/custom-icons"
  },
  
];

const IconTabs = ({ activeTab, button }) => {
  const [isTabActive, setIsTabActive] = useState(activeTab);

  return (
    <div className="d-flex flex-row align-items-center justify-content-between w-100">
      {/*  Sub Header Tabs*/}
      <ul className="nav nav-compact border-0 p-0 mb-20" role="tablist">
        {tabItems.map((tabItem) => (
          <li className="nav-item" key={tabItem.id}>
            <a
              href={tabItem.path}
              className={`nav-link mr-1 ${
                isTabActive === tabItem.title ? " active" : "rounded-3 bg-light"
              }`}
              onClick={() => setIsTabActive(tabItem.title)}
            >
              {tabItem.title}
            </a>
          </li>
        ))}
      </ul>

      {/* CTA BUTTON */}
      {button}
    </div>
  );
};

export default IconTabs;
