import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomSelect from "../../../../common/CustomSelect";
import {
  IconClock,
  IconClock9,
  IconMinus,
  IconPlus,
} from "../../../../common/icons";
const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const AddNewReport = (props) => {
  const { hideAddNewReport, showAddNewReport } = props;

  const [isTabActive, setIsTabActive] = useState(1);

  return (
    <React.Fragment>
      <Modal show={showAddNewReport} centered animation={false} size="sm">
        <Modal.Header>
          <Modal.Title>Add New Report</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="form-group">
            <label>
              <span className="text-danger mr-1">*</span>
              Report Name
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Report Name"
            />
          </div>
          <div className="form-group">
            <label>
              <span className="text-danger mr-1">*</span>
              Email
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Email"
            />
            <div className="text-muted">
              Use ,(comma) for entering mulitple emails
            </div>
          </div>
          <div className="form-group">
            <label>
              <span className="text-danger mr-1">*</span>
              Frequency
            </label>
            <div className="form-check mb-10">
              <input
                type="radio"
                className="form-check-input"
                id="daily"
                name="route"
              />
              <label className="form-check-label" for="daily">
                Daily
              </label>
            </div>
            <div className="form-check mb-10">
              <input
                type="radio"
                className="form-check-input"
                id="weekly"
                name="route"
              />
              <label className="form-check-label" for="weekly">
                Weekly
              </label>
            </div>
            <div className="form-check mb-10">
              <input
                type="radio"
                className="form-check-input"
                id="2-weeks"
                name="route"
              />
              <label className="form-check-label" for="2-weeks">
                Every 2 Weeks
              </label>
            </div>
            <div className="form-check mb-10">
              <input
                type="radio"
                className="form-check-input"
                id="each-month"
                name="route"
              />
              <label className="form-check-label" for="each-month">
                Specific Day of each month
              </label>
              <CustomSelect
                options={options}
                placeholder="select"
                size="small"
                className="w-150 ml-2"
              />
            </div>
          </div>
          <div className="hr-light"></div>
          <div className="form-group">
            <label>Time</label>
            <div className="form-row">
              <div className="col-md-4 d-flex align-items-center">
                <div className="input-wrapper">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Select.."
                  />
                  <span className="input-icon">
                    <IconClock />
                  </span>
                </div>
                <button className="btn btn-circle btn-xs bg-soft-danger ml-1">
                  <IconMinus />
                </button>
              </div>
              <div className="col-md-4 d-flex align-items-center">
                <div className="input-wrapper">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Select.."
                  />
                  <span className="input-icon">
                    <IconClock />
                  </span>
                </div>
                <button className="btn btn-circle btn-xs bg-soft-danger ml-1">
                  <IconMinus />
                </button>
              </div>
              <div className="col-md-4 d-flex align-items-center">
                <div className="input-wrapper">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Select.."
                  />
                  <span className="input-icon">
                    <IconClock />
                  </span>
                </div>
                <button className="btn btn-circle btn-xs bg-soft-danger ml-1">
                  <IconMinus />
                </button>
              </div>
            </div>
          </div>
          <a href="#!" class="text-primary font-weight-500 d-flex align-items-center">
            <IconPlus className="mr-2" />
            Add Charge
          </a>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" onClick={hideAddNewReport}>
            Cancel
          </button>
          <button className="btn btn-success">Create Load</button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default AddNewReport;
