import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import CustomSelect from '../../../../common/CustomSelect';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const AddCompanyModal = (props) => {
  const { showModal, hideModal, showNextModal } = props;
  return (
    <Modal show={showModal} centered animation={false} size="sm">
      <Modal.Header className="py-20 px-30 justify-content-center">
        <Modal.Title>
          <div className="text-center">
            <div className="font-20 font-weight-500 text-gray-900 pb-1">
              Company Info
            </div>
            <div className="font-12 font-weight-normal text-gray-500">
              Fill out required company info
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="nav-border-bottom">
        <div className="card mb-0">
          <div className="form-wrapper">
            <form>
              <div className="form-row ">
                <div className="form-group col-md-12">
                  <label className="col-form-label">Your Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Group Name"
                  />
                </div>
                <div className="form-group col-md-12">
                  <label className="col-form-label">Company Type</label>
                  <CustomSelect
                    name={'name'}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select Here.."
                  />
                </div>
                <div className="form-group col-md-12">
                  <label className="col-form-label">Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="e.g BigTruckCompany"
                  />
                </div>
                <div className="form-group col-md-12">
                  <label className="col-form-label">Company Address</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="e.g 2118 Thornridge Cir. Syracuse, Connecticut 35624"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="">
          <button className="btn btn-outline-light mr-10" onClick={hideModal}>
            Back
          </button>
          <button
            className="btn btn-light text-gray-400"
            onClick={showNextModal}
          >
            Continue
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCompanyModal;
