import React from 'react';
import {
  IconDownload,
  MessageIllustrator,
  IconPlus,
} from '../../../common/icons';
import SubHeader from '../subheader';
import StaticTableContent from './elements/StaticTableContent';
import CustomerTabs from '../common-elements/CustomerTabs';
import Pagination from '../../../common/pagination';

const DeletedLoads = () => {
  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <CustomerTabs activeTab={9} />
        <div className="page-fluid mt-60 ">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <Pagination className="ml-auto" />
            </div>
          </div>
          <StaticTableContent />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DeletedLoads;
