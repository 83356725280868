import React, { useState, Component } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import CustomerTabs from '../common-elements/CustomerTabs';

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTabActive: false,
    };
  }

  handleTabActive = (i) => {
    this.setState({
      isTabActive: this.state.isTabActive === i ? null : i,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="position-relative subheader-wrapper">
          <CustomerTabs activeTab={6} />
          <div className="page-fluid">
            {/* Calendar Plugin */}
            <div className="custom-calendar-theme">
              <FullCalendar
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  bootstrapPlugin,
                ]}
                initialView="dayGridMonth"
                headerToolbar={{
                  right: 'prev,title,next',
                  left: 'dayGridMonth,timeGridWeek,timeGridDay today',
                }}
                eventContent={renderEventContent}
                events={[
                  {
                    id: 1,
                    title: 'APPT - 7',
                    date: '2021-09-03',
                    backgroundColor: '#56BB9D',
                  },
                  {
                    id: 2,
                    title: 'Cutoff - 6',
                    date: '2021-09-03',
                    backgroundColor: '#A3B3C6',
                  },
                  {
                    id: 3,
                    title: 'APPT - 7',
                    date: '2021-09-03',
                    backgroundColor: '#DD5E56',
                  },
                  {
                    id: 4,
                    title: 'APPT - 7',
                    date: '2021-09-03',
                    backgroundColor: '#558EF2',
                  },
                  {
                    id: 5,
                    title: 'APPT - 7',
                    date: '2021-09-14',
                    backgroundColor: '#DD5E56',
                  },
                  {
                    id: 6,
                    title: 'APPT - 7',
                    date: '2021-09-14',
                    backgroundColor: '#56BB9D',
                  },
                  {
                    id: 7,
                    title: 'APPT - 7',
                    date: '2021-09-19',
                    backgroundColor: '#558EF2',
                  },
                  {
                    id: 8,
                    title: 'APPT - 7',
                    date: '2021-09-29',
                    backgroundColor: '#56BB9D',
                    // start: '2021-09-29T10:30:00',
                    // end: '2021-09-29T2:00:00',
                  },
                ]}
                contentHeight={'calc(100vh - 223px)'}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function renderEventContent(eventInfo) {
  return <div className="custom-event">{eventInfo.event.title}</div>;
}

export default Calendar;
