import TopCards from "../../../common/TopCards/TopCards";

const TrackOsImportAvailabilityCards = ({}) => {
  const cardData = [
    {
      id: 1,
      cardMainName: "Containers on Vessel",
      cardSubNameOne: "On Hold",
      cardSubNameTwo: "Released",
    },
    {
      id: 2,
      cardMainName: "Containers on Vessel",
      cardSubNameOne: "On Hold",
      cardSubNameTwo: "Released",
    },

    {
      id: 3,
      cardMainName: "Gates/Outgated",
      cardSubNameOne: "In Gated",
      cardSubNameTwo: "Out Gates",
    },
    {
      id: 4,
      cardMainName: "Containers at Port",
      cardSubNameOne: "Last Free Days",
      cardSubNameTwo: "Pickup Appts",
    },
    {
      id: 5,
      cardMainName: "Container Returned",
      cardSubNameOne: "In Port",
      cardSubNameTwo: "In Yard",
    },
  ];

  return <TopCards cardData={cardData} />;
};

export default TrackOsImportAvailabilityCards;
