import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import CustomCreatableSelect from '../../../../common/CustomSelect/creatable';
import CustomSelect from '../../../../common/CustomSelect/index';

import { IconDownload, IconSendEmail } from '../../../../common/icons/index';
import { CustomIconAlert } from '../../../../common/customIcons';
import ConfirmationModal from '../../common-elements/ConfirmationModal';
import SettlementModal from './SettlementModal';

const columns = [
  'Settlement#',
  'Driver',
  'Owner',
  'Truck',
  'Driver Pay',
  'Deduction',
  'Ney Pay',
  '',
  '',
  'Status',
  '',
];

const data = [
  {
    id: 1,
    settlement: '393',
    status: 'Unreviewed',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    owner: 'DB Trucks',
    truck: '1469',
    driverPay: 275.43,
    deduction: -5.43,
    neyPay: 1.28,
  },
  {
    id: 2,
    settlement: '393',
    status: 'Unreviewed',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    owner: 'DB Trucks',
    truck: '1469',
    driverPay: 275.43,
    deduction: -5.43,
    neyPay: 1.28,
  },
  {
    id: 3,
    settlement: '393',
    status: 'Unreviewed',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    owner: 'DB Trucks',
    truck: '1469',
    driverPay: 275.43,
    deduction: -5.43,
    neyPay: 1.28,
  },
  {
    id: 4,
    settlement: '393',
    status: 'Reviewed',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    owner: 'DB Trucks',
    truck: '1469',
    driverPay: 275.43,
    deduction: -5.43,
    neyPay: 1.28,
  },
  {
    id: 5,
    settlement: '393',
    status: 'Reviewed',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    owner: 'DB Trucks',
    truck: '1469',
    driverPay: 275.43,
    deduction: -5.43,
    neyPay: 1.28,
  },
  {
    id: 6,
    settlement: '393',
    status: 'Reviewed',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    owner: 'DB Trucks',
    truck: '1469',
    driverPay: 275.43,
    deduction: -5.43,
    neyPay: 1.28,
  },
  {
    id: 7,
    settlement: '393',
    status: 'Reviewed',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    owner: 'DB Trucks',
    truck: '1469',
    driverPay: 275.43,
    deduction: -5.43,
    neyPay: 1.28,
  },
  {
    id: 8,
    settlement: '393',
    status: 'Reviewed',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    owner: 'DB Trucks',
    truck: '1469',
    driverPay: 275.43,
    deduction: -5.43,
    neyPay: 1.28,
  },
  {
    id: 9,
    settlement: '393',
    status: 'Finalized',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    owner: 'DB Trucks',
    truck: '1469',
    driverPay: 275.43,
    deduction: -5.43,
    neyPay: 1.28,
  },
  {
    id: 10,
    settlement: '393',
    status: 'Finalized',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    owner: 'DB Trucks',
    truck: '1469',
    driverPay: 275.43,
    deduction: -5.43,
    neyPay: 1.28,
  },
  {
    id: 11,
    settlement: '393',
    status: 'Finalized',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    owner: 'DB Trucks',
    truck: '1469',
    driverPay: 275.43,
    deduction: -5.43,
    neyPay: 1.28,
  },
  {
    id: 12,
    settlement: '393',
    status: 'Finalized',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    owner: 'DB Trucks',
    truck: '1469',
    driverPay: 275.43,
    deduction: -5.43,
    neyPay: 1.28,
  },
  {
    id: 13,
    settlement: '393',
    status: 'Finalized',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    owner: 'DB Trucks',
    truck: '1469',
    driverPay: 275.43,
    deduction: -5.43,
    neyPay: 1.28,
  },
];

const options = [
  { value: 'jessica.hanson@example.com', label: 'jessica.hanson@example.com' },
  { value: 'john.doe@example.com', label: 'john.doe@example.com' },
  { value: 'alex@example.com', label: 'alex@example.com' },
];

const StaticTableContent = () => {
  const [showModal, setShowModal] = useState(false);
  const [isSettlementModal, setIsSettlementModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isFinalized, setIsFinalized] = useState(null);

  const finalized = (i) => {
    setIsFinalized(i);
  };

  const handleShowModal = (event) => {
    setShowModal(true);
  };

  const handleHideModal = (event) => {
    setShowModal(false);
  };

  const showSettlementModal = (event) => {
    setIsSettlementModal(true);
  };

  const hideSettlementModal = (event) => {
    setIsSettlementModal(false);
  };

  const showConfirmationModal = (event) => {
    setConfirmationModal(true);
  };

  const hideConfirmationModal = (event) => {
    setConfirmationModal(false);
  };

  return (
    <div className="table-responsive" style={{ height: 'calc(100vh - 408px)' }}>
      <table className="table table-card table-card--ls">
        <thead>
          <tr>
            <th>
              <input
                className="form-check-input rounded-3 border-secondary"
                type="checkbox"
                id="sr_no"
              />
            </th>
            {columns.map((column) => {
              return (
                <th scope="col" key={column}>
                  {column}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((datum) => {
            return (
              <tr key={datum.id}>
                <td style={{ width: '2%' }}>
                  <input
                    className="form-check-input rounded-3 border-secondary"
                    type="checkbox"
                    id="sr_no"
                  />
                </td>
                <td style={{ width: '10%' }}>
                  <a
                    href="javascript:void(0)"
                    className="text-primary font-weight-500"
                  >
                    {datum.settlement}
                  </a>
                </td>
                <td style={{ width: '15%' }}>
                  <div className="d-flex" onClick={showSettlementModal}>
                    <div className="mr-2 align-self-center">
                      <img
                        src={datum.img}
                        className="avatar-xs rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <h5 className="mb-0 align-self-center">
                      <a href="#" className="text-dark">
                        {datum.driver}
                      </a>
                    </h5>
                  </div>
                </td>
                <td style={{ width: '25%' }}>{datum.owner}</td>
                <td>{datum.truck}</td>
                <td style={{ width: '5%' }} className="text-center">
                  ${datum.driverPay}
                </td>
                <td style={{ width: '5%' }} className="text-right">
                  ${datum.deduction}
                </td>
                <td style={{ width: '5%' }} className="text-right">
                  ${datum.neyPay}
                </td>
                <td style={{ width: '2%' }}>
                  <button className="btn btn-link">
                    <IconDownload />
                  </button>
                </td>
                <td style={{ width: '2%' }}>
                  <button className="btn btn-link" onClick={handleShowModal}>
                    <IconSendEmail />
                  </button>
                </td>
                <td style={{ width: '5%' }}>
                  <div className="text-center">
                    {datum.status == 'Unreviewed' ? (
                      <span className="badge badge-sm px-1 badge-soft-warning">
                        {datum.status}
                      </span>
                    ) : datum.status === 'Reviewed' ? (
                      <span className="badge badge-sm px-1 badge-brand-50">
                        {datum.status}
                      </span>
                    ) : (
                      <span className="badge badge-sm px-1 badge-purple-50">
                        {datum.status}
                      </span>
                    )}
                  </div>
                </td>
                <td style={{ width: '5%' }}>
                  {datum.status === 'Unreviewed' ? (
                    <button
                      className={`btn btn-light ${
                        isFinalized === 1 ? null : 'd-block'
                      }`}
                      onClick={showConfirmationModal}
                    >
                      Finalize
                    </button>
                  ) : datum.status === 'Reviewed' ? (
                    <button
                      className={`btn btn-purple-500 ${
                        isFinalized === 1 ? null : 'd-block'
                      }`}
                      onClick={showConfirmationModal}
                    >
                      Finalize
                    </button>
                  ) : (
                    <div
                      className={`badge-purple-50 ${
                        isFinalized === 1 ? 'd-block' : null
                      }`}
                    >
                      Finalized
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Send Reports Modal */}
      <Modal show={showModal} centered animation={false}>
        <Modal.Header>
          <Modal.Title>Send Reports</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-wrapper">
            <form>
              <div className="form-group mb-30">
                <div className="form-check form-group mb-3">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="byEmail"
                    name="sendReport"
                  />
                  <label className="form-check-label" for="byEmail">
                    Send Reports by Email
                  </label>
                </div>
                <CustomCreatableSelect
                  name={'name'}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Enter email address.."
                />
              </div>
              <div className="form-group mb-30">
                <div className="form-check form-group mb-3">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="byMail"
                    name="sendReport"
                  />
                  <label className="form-check-label" for="">
                    Send Reports in Mail to Driver
                  </label>
                </div>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="jessica.hanson@example.com"
                  className="w-100"
                />
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" onClick={handleHideModal}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleHideModal}>
            Send reports
          </button>
        </Modal.Footer>
      </Modal>

      {/* Settlement Modal */}
      {isSettlementModal && (
        <SettlementModal
          showSettlementModal={isSettlementModal}
          onClose={hideSettlementModal}
        />
      )}

      {/* Confirmation Modal */}
      {confirmationModal && (
        <ConfirmationModal
          icon={<CustomIconAlert />}
          title={
            <h4 className="font-20">
              Are you sure you want to finalize Settlement #393?
            </h4>
          }
          message="Your won’t be able to edit this settlement after finalizing"
          buttons={
            <div className="d-flex">
              <button
                className="btn btn-none mr-10 px-20"
                onClick={hideConfirmationModal}
              >
                Cancel
              </button>
              <button
                className="btn btn-purple-500"
                onClick={() => finalized()}
              >
                Yes, finalize
              </button>
            </div>
          }
        />
      )}
    </div>
  );
};

export default StaticTableContent;
