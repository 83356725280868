import React, { useState } from "react";
import { IconDownload, IconPlus } from "../../../common/icons";
import Pagination from "../../../common/pagination/index";
import CounterCards from "../common-elements/CounterCards";
import SubHeader from "../common-elements/SubHeader";
import AddNewPricing from "./elements/AddNewPricing";
import StaticTableContent from "./elements/StaticTableContent";

const cardItems = [
  {
    id: 1,
    count: 540,
    title: "Available",
  },
  {
    id: 2,
    count: 703,
    title: "Dispatched",
  },
  {
    id: 3,
    count: 740,
    title: "Loaded",
  },
  {
    id: 4,
    count: 561,
    title: "Empty",
  },
];

const DynamicPricingSettings = () => {
  const [isCardActive, setCardActive] = useState(1);
  const [showAddModal, setShowAddModal] = useState(false);

  const handleShowAddModal = (event) => {
    setShowAddModal(true);
  };

  const handleHideAddModal = (event) => {
    setShowAddModal(false);
  };

  return (
    <div className="position-relative subheader-wrapper">
      <SubHeader
        mainTitle="Dynamic Pricing"
        button={
          <div className="d-flex">
            <button
              className="ml-auto btn btn-success"
              onClick={handleShowAddModal}
            >
              <IconPlus className="mr-2" />
              Add New Price
            </button>
          </div>
        }
      />
      <div className="page-fluid">
        <div className="top-page">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h4 className="font-20 mb-0">12 Prices</h4>
            <Pagination />
          </div>
        </div>

        {/* Static Table Content */}
        <StaticTableContent />

        {/* Add New Truck */}
        {showAddModal && (
          <AddNewPricing
            onCancel={handleHideAddModal}
            onSubmit={handleHideAddModal}
          />
        )}
      </div>
    </div>
  );
};

export default DynamicPricingSettings;
