import React from "react";
import { useDropzone } from "react-dropzone";
import CustomSelect from "../CustomSelect";
import {
  IconUpload,
  IconTrash,
  IconTimes,
  IconInfo,
  IconCheck,
} from "../icons";

import { CustomIconFileUpload } from "../customIcons";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
const UploadDeduction = (props) => {
  const { className } = props;
  //For DROPZONE
  const { acceptedFiles, fileRejections, getRootProps, getInputProps, open } =
    useDropzone({
      accept: "image/jpeg, image/png",
      noClick: true,
      noKeyboard: true,
      multiple: true,
    });
  const acceptedFileItems = acceptedFiles.map((file) => (
    <React.Fragment>
      <div
        className="dropzone-fileupload dropzone-fileupload--uploading d-flex"
        key={file.path}
      >
        <IconUpload className="mr-20" />
        <div className="w-100">
          <div className="font-medium d-flex justify-content-between w-100 mb-1">
            <div>
              {file.path} - {file.size} bytes
            </div>
            <div className="ml-auto">25%</div>
          </div>
          <div className="progress">
            <div
              className="progress-bar bg-primary"
              role="progressbar"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: "25%" }}
            ></div>
          </div>
        </div>
        <button className="btn btn-white btn-circle btn-xs ml-3 shadow-none">
          <IconTimes />
        </button>
      </div>
      <div className="row mt-20">
        <div className="col-md-6 form-group">
          <label>Description</label>
          <CustomSelect
            name={"name"}
            options={("PDF", "CSV")}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Perfect Christmas Trees"
          />
        </div>
        <div className="col-md-6 form-group">
          <label>Apply Date</label>
          <CustomSelect
            name={"name"}
            options={("PDF", "CSV")}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Perfect Christmas Trees"
          />
        </div>
      </div>
    </React.Fragment>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));
  return (
    <div className={`${className}`}>
      {acceptedFileItems.length <= 0 ? (
        <div {...getRootProps({ className: "dropzone" })}>
          <div className="dz-message needsclick" tabindex="0">
            <input
              multiple=""
              type="file"
              autocomplete="off"
              tabindex="-1"
              {...getInputProps()}
            />
            <div className="d-flex align-items-center justify-content-center">
              <CustomIconFileUpload />
              <div className="ml-30">
                <div className="title mb-10">
                  Drop files here or click to upload.
                </div>
                <button className="btn btn-primary btn-sm" onClick={open}>
                  Choose from your device...
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {acceptedFileItems}

      {/* After File is Uploaded sue the code block below */}
    </div>
  );
};

export default UploadDeduction;
