import React, { useState } from 'react';
import {
  IconColumn,
  IconFilter,
  IconPen,
  IconTrash,
} from '../../../common/icons';
import Pagination from '../../../common/pagination';
import SubHeader from '../subheader';
import ChassisLoadPage from './chassisLoadPage';
import ChassisLoadSubHeader from './chassisLoadSubHeader';
import { IconPlus, IconDownload } from '../../../common/icons/index';
const cardItems = [
  {
    id: 1,
    count: 540,
    title: 'Available',
  },
  {
    id: 2,
    count: 703,
    title: 'Dispatched',
  },
  {
    id: 3,
    count: 740,
    title: 'Loaded',
  },
  {
    id: 4,
    count: 994,
    title: 'Empty',
  },
];

const tableHeaderData = [
  'First name',
  'Last Name',
  'Truck #',
  'Fleet Owner',
  'Phone',
  'DL Exp.',
  'Med.Exp.',
  'Sealink Exp.',
  'Sealink #',
  'Twic exp.',
  'Date of Birth',
  'Email',
  'Emergency Contact',
  'Emergency Contact Number',
];
const tableData = [];
for (let i = 0; i < 20; i++) {
  tableData.push({
    id: i,
    firstName: 'Gerg',
    lastName: 'Richard',
    truck: 'FZ1234',
    fleetOwner: 'Salam Pacific Indonesia Lines',
    phone: '+1-202-555-0171',
    dlExp: '06/11/21',
    medExp: '06/11/21',
    sealinkExp: '06/11/21',
    sealink: '4121232121',
    twicExp: '06/11/21',
    dateOfBirth: '06/11/21',
    email: 'kenzi.lawson@example.com',
    emergencyContact: 'Bessie Cooper',
    emergencyname: 'Wife',
    emergencyContactNumber: '1-300-555-0161',
  });
}

const DriverProfiles = () => {
  const [isCardActive, setCardActive] = useState(1);
  const [ChassisLoad, setChassisLoad] = useState(false);
  const handleShowChassisLoad = (event) => {
    setChassisLoad(true);
  };

  const handleHideChassisLoad = (event) => {
    setChassisLoad(false);
  };

  return (
    <div className="position-relative subheader-wrapper">
      {!ChassisLoad ? (
        <React.Fragment>
          <div className="subheader">
            <div className="subheader__inner d-flex align-items-center py-0">
              <SubHeader
                activeTab={1}
                button={
                  <div className="d-flex">
                    <button className="ml-auto btn btn-success">
                      <IconPlus className="mr-2" />
                      Add New Load
                    </button>
                  </div>
                }
              />
            </div>
          </div>
          <div className="page-fluid">
            <div className="top-page">
              <div className="pb-15 d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <h4 className="font-20 mb-0">Drivers</h4>
                </div>
                {/* Pagination  */}
                <Pagination className="ml-auto" />
              </div>
              {/* Cards Row */}
              <div className={`form-row ${isCardActive ? 'hasactive' : ''}`}>
                {cardItems.map((cardItem) => (
                  <div className="col-md my-2">
                    <div
                      className={`ncard d-flex flex-column justify-content-between p-10 ${
                        isCardActive === cardItem.id ? 'active' : ''
                      }`}
                      onClick={() => setCardActive(cardItem.id)}
                    >
                      <div className="d-flex align-items-center">
                        <div className="mr-1 sbox sbox--default px-2 h-100">
                          {cardItem.count}
                        </div>
                        <div>{cardItem.title}</div>
                        <div className="ml-auto">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Filter Row */}
              <div className="filter-row d-flex align-items-center">
                <div className="app-search header-search">
                  <span className="search-icon"> </span>
                  <div className="position-relative">
                    <input
                      type="search"
                      className="form-control input-search-left"
                      placeholder="Search..."
                      value=""
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check--gray pr-30">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="Available"
                    />
                    <label className="form-check-label" for="Available">
                      Enabled
                      <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                        47
                      </span>
                    </label>
                  </div>
                  <div className="form-check form-check--gray pr-30">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="Pending"
                    />
                    <label className="form-check-label" for="Pending">
                      Disabled
                      <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                        6
                      </span>
                    </label>
                  </div>
                </div>
                <div className="ml-auto d-flex">
                  <div className="position-relative">
                    <button className="btn btn-outline-light position-relative">
                      <IconColumn />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Table */}
            <div
              className="table-responsive"
              style={{ height: 'calc(100vh - 319px)' }}
            >
              <table className="table table-card table-card--ls">
                <thead>
                  <tr>
                    {tableHeaderData.map((headetItem) => {
                      return (
                        <th scope="col" key={headetItem}>
                          {headetItem}
                        </th>
                      );
                    })}
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((tdata) => {
                    return (
                      <tr key={tdata.id}>
                        <td>{tdata.firstName}</td>
                        <td>{tdata.lastName}</td>
                        <td>{tdata.truck}</td>
                        <td>{tdata.fleetOwner}</td>
                        <td>{tdata.phone}</td>
                        <td className="bg-warning-500">{tdata.dlExp}</td>
                        <td className="bg-warning-500">{tdata.medExp}</td>
                        <td className="bg-warning-50">{tdata.sealinkExp}</td>
                        <td>{tdata.sealink}</td>
                        <td className="bg-warning-50">{tdata.twicExp}</td>
                        <td className="bg-warning-50">{tdata.dateOfBirth}</td>
                        <td>{tdata.email}</td>
                        {/* <td>{tdata.emergencyContact}</td> */}
                        <td>
                          <div>{tdata.emergencyContact}</div>
                          <div className="text-muted">
                            {tdata.emergencyname}
                          </div>
                        </td>
                        <td>{tdata.emergencyContactNumber}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            <button className="btn btn-link">
                              <a href="#" onClick={handleShowChassisLoad}>
                                <IconPen className="text-gray-400" />
                              </a>
                            </button>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <button className="btn btn-link">
                              <IconTrash />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ChassisLoadSubHeader closeChassisLoad={handleHideChassisLoad} />
          <ChassisLoadPage />
        </React.Fragment>
      )}
    </div>
  );
};

export default DriverProfiles;
