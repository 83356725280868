import React, { useState, Component } from "react";
import { IconCalendar, IconPause, IconPlay } from "../../common/icons";
import RangeSlider from "../../common/rangeSlider";

class DriverDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelectCard: false,
      isPlayed: false,
    };
  }

  handleSelectCard = (i) => {
    this.setState({
      isSelectCard: this.state.isSelectCard === i ? null : i,
    });
  };
  handlePlayed = (e) => {
      this.setState({isPlayed: !this.state.isPlayed});
  }
  render() {
    return (
      <React.Fragment>
        {/* START TrackBar Design */}
        <div
          className="mapbar mapbar-big"
          style={{ height: "calc(100vh - 120px)" }}
        >
          <div
            className={`dlist ${
              this.state.isSelectCard ? "dlist--cardselected" : ""
            }`}
            style={{ height: "calc(100% - 10px)" }}
          >
            <div
              className={`dlist__card dlist__card--complete mb-1 p-20 ${
                this.state.isSelectCard === 1 ? "dlist__card--selected" : ""
              }`}
              onClick={() => this.handleSelectCard(1)}
            >
              <div className="d-flex align-items-start py-2">
                <div className="flex-grow-1">
                  <h5 className="font-14 mb-1">
                    <a href="#" className="text-dark">
                      M07982233
                    </a>
                  </h5>
                  <p className="text-muted mb-0">
                    <span>21 Aug, 2021, 06:32 pm</span>
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <div className="text-muted">40’ HC</div>
                  <div className="text-muted">UJSK8223432</div>
                </div>
              </div>
              <div className="dlist__distance">
                <div className="dlist__distance--row d-flex">
                  <div className="dlist__timeframe">
                    <div className="dlist__timeframe--icon">
                      <i className="uil uil-check"></i>
                    </div>
                    <div className="dlist__timeframe--miles">423 mi</div>
                  </div>
                  <div className="dlist__distance--info w-100">
                    <div className="d-flex align-items-start pb-2">
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="#" className="text-dark">
                            VG - Van Group
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>Springville, AR</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-soft-success">
                          Hookcontainer
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center pb-2">
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                      <div className="flex-shrink-0 mx-2">
                        <span className="badge bg-light">7 min</span>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                    </div>
                    <div className="dlist__hr">
                      <span className="badge badge-light font-12 dlist__hr__time">
                        7 min 32 sec
                      </span>
                    </div>
                  </div>
                </div>
                <div className="dlist__distance--row d-flex">
                  <div className="dlist__timeframe">
                    <div className="dlist__timeframe--icon">
                      <i className="uil uil-check"></i>
                    </div>
                  </div>
                  <div className="dlist__distance--info w-100">
                    <div className="d-flex align-items-start pb-2">
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="#" className="text-dark">
                            VG - Van Group
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>Springville, AR</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-soft-success">
                          Hookcontainer
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center pb-2">
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                      <div className="flex-shrink-0 mx-2">
                        <span className="badge bg-light">7 min</span>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`dlist__card dlist__card--active mb-1 p-20 ${
                this.state.isSelectCard === 2 ? "dlist__card--selected" : ""
              }`}
              onClick={() => this.handleSelectCard(2)}
            >
              <div className="d-flex align-items-start py-2">
                <div className="flex-grow-1">
                  <h5 className="font-14 mb-1">
                    <a href="#" className="text-dark">
                      M07982233
                    </a>
                  </h5>
                  <p className="text-muted mb-0">
                    <span>21 Aug, 2021, 06:32 pm</span>
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <div className="text-muted">40’ HC</div>
                  <div className="text-muted">UJSK8223432</div>
                </div>
              </div>
              <div className="dlist__distance">
                <div className="dlist__distance--row d-flex">
                  <div className="dlist__timeframe">
                    <div className="dlist__timeframe--icon">A</div>
                    <div className="dlist__timeframe--miles">423 mi</div>
                  </div>
                  <div className="dlist__distance--info w-100">
                    <div className="d-flex align-items-start pb-2">
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="#" className="text-dark">
                            VG - Van Group
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>Springville, AR</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-soft-success">
                          Hookcontainer
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center pb-2">
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                      <div className="flex-shrink-0 mx-2">
                        <span className="badge bg-light">7 min</span>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                    </div>
                    <div className="dlist__hr">
                      <span className="badge badge-light font-12 dlist__hr__time">
                        7 min 32 sec
                      </span>
                    </div>
                  </div>
                </div>
                <div className="dlist__distance--row d-flex">
                  <div className="dlist__timeframe">
                    <div className="dlist__timeframe--icon">B</div>
                    <div className="dlist__timeframe--miles">13 mi</div>
                  </div>
                  <div className="dlist__distance--info w-100">
                    <div className="d-flex align-items-start pb-2">
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="#" className="text-dark">
                            VG - Van Group
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>Springville, AR</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-soft-success">
                          Hookcontainer
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center pb-2">
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                      <div className="flex-shrink-0 mx-2">
                        <span className="badge bg-light">7 min</span>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                    </div>
                    <div className="dlist__hr">
                      <span className="badge badge-light font-12 dlist__hr__time">
                        7 min 32 sec
                      </span>
                    </div>
                  </div>
                </div>
                <div className="dlist__distance--row d-flex">
                  <div className="dlist__timeframe">
                    <div className="dlist__timeframe--icon">C</div>
                  </div>
                  <div className="dlist__distance--info w-100">
                    <div className="d-flex align-items-start pb-2">
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="#" className="text-dark">
                            VG - Van Group
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>Springville, AR</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-soft-success">
                          Hookcontainer
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center pb-2">
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                      <div className="flex-shrink-0 mx-2">
                        <span className="badge bg-light">7 min</span>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`dlist__card dlist__card--upcoming mb-1 p-20 ${
                this.state.isSelectCard === 3 ? "dlist__card--selected" : ""
              }`}
              onClick={() => this.handleSelectCard(3)}
            >
              <div className="d-flex align-items-start py-2">
                <div className="flex-grow-1">
                  <h5 className="font-14 mb-1">
                    <a href="#" className="text-dark">
                      M07982233
                    </a>
                  </h5>
                  <p className="text-muted mb-0">
                    <span>21 Aug, 2021, 06:32 pm</span>
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <div className="text-muted">40’ HC</div>
                  <div className="text-muted">UJSK8223432</div>
                </div>
              </div>
              <div className="dlist__distance">
                <div className="dlist__distance--row d-flex">
                  <div className="dlist__timeframe">
                    <div className="dlist__timeframe--icon">
                      <i className="uil uil-clock-nine"></i>
                    </div>
                  </div>
                  <div className="dlist__distance--info w-100">
                    <div className="d-flex align-items-start pb-2">
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="#" className="text-dark">
                            VG - Van Group
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>Springville, AR</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-soft-success">
                          Hookcontainer
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center pb-2">
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                      <div className="flex-shrink-0 mx-2">
                        <span className="badge bg-light">7 min</span>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dlist__distance--row d-flex">
                  <div className="dlist__timeframe">
                    <div className="dlist__timeframe--icon">
                      <i className="uil uil-clock-nine"></i>
                    </div>
                  </div>
                  <div className="dlist__distance--info w-100">
                    <div className="d-flex align-items-start pb-2">
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="#" className="text-dark">
                            VG - Van Group
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>Springville, AR</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-soft-success">
                          Hookcontainer
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center pb-2">
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                      <div className="flex-shrink-0 mx-2">
                        <span className="badge bg-light">7 min</span>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* END TrackBar Design */}
        {this.state.isSelectCard && (
          <div
            className="card mb-0 p-10 shadow-5 d-flex align-items-center flex-row"
            style={{
              position: "absolute",
              bottom: "20px",
              left: "calc(440px + 120px)",
            }}
          >
            <div className="input-wrapper mr-10">
              <input
                className="form-control"
                type="text"
                placeholder="From - To"
              />
              <span className="input-icon">
                <IconCalendar />
              </span>
            </div>
            <button
              className="btn btn-primary"
              onClick={(e) => this.handlePlayed(e)}

            >
              {this.state.isPlayed ? <IconPlay /> : <IconPause />}
            </button>
            {/* Range Slider */}
            <RangeSlider className="ml-20 mr-10 align-self-end mb-1" />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default DriverDetails;
