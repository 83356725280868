import React, { Component } from "react";
import { CustomIconTruck } from "../../../../common/customIcons";
import UploadDocument from "../../../../common/uploadDocument";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

class TabNotes extends Component {
  render() {
    return (
      <div className="tab" id="tab-document">
        <div className="card card--shadow-5">
          <div className="px-30 py-20">
            <div className="d-flex align-items-center mb-20">
              <CustomIconTruck className="mr-10" />
              <h5 className="font-16 mb-0">Driver</h5>
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                placeholder="Notes for driver"
                rows="10"
              ></textarea>
            </div>
          </div>

          <div className="card-footer">
            <button className="btn btn-close">Cancel</button>
            <button className="btn btn-primary">Add Note</button>
          </div>
        </div>
      </div>
    );
  }
}

export default TabNotes;
