import React, { useState } from 'react';

const tabItems = [
  {
    id: 1,
    title: 'Driver',
    path: '/customerService/customer-messaging',
  },
  {
    id: 2,
    title: 'Employee',
    path: '/customerService/employee-messaging',
  },
  {
    id: 3,
    title: 'Group',
    path: '/customerService/group-message',
  },
  {
    id: 4,
    title: 'Customer',
    path: '',
  },
];

const MessageTabs = ({ activeTab }) => {
  const [isTabActive, setIsTabActive] = useState(activeTab);

  return (
    <div className="d-flex flex-row align-items-center justify-content-between w-100">
      {/*  Sub Header Tabs*/}
      <ul className="nav nav-compact border-0 p-0 mb-15" role="tablist">
        {tabItems.map((tabItem) => (
          <li className="nav-item" key={tabItem.id}>
            <a
              href={tabItem.path}
              className={`nav-link ml-1 ${
                isTabActive === tabItem.title ? ' active' : 'rounded-3 bg-light'
              }`}
              onClick={() => setIsTabActive(tabItem.title)}
            >
              {tabItem.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MessageTabs;
