import React, { useState } from 'react';

import { IconPen, IconTrash } from '../../../../../common/icons/index';

const columns = ['Size'];

const data = [
  {
    id: 1,
    size: 53,
  },
  {
    id: 2,
    size: '20-40',
  },
  {
    id: 3,
    size: '20-40',
  },
  {
    id: 4,
    size: 53,
  },
  {
    id: 5,
    size: 45,
  },
  {
    id: 6,
    size: 40,
  },
  {
    id: 7,
    size: 20,
  },
];

const StaticTableContent = () => {
  return (
    <React.Fragment>
      {/* Table Content */}
      <div
        className="table-responsive"
        style={{ height: 'calc(100vh - 272px)' }}
      >
        <table className="table table-card">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
              <th style={{ width: '2%' }}></th>
              <th style={{ width: '2%' }}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((datum) => {
              return (
                <tr key={datum.id}>
                  <td>{datum.size}'</td>
                  <td>
                    <button className="btn btn-link">
                      <IconPen className="text-muted" />
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-link">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
