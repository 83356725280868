import React from "react";
import { IconLock } from "../../../../common/icons";

const Notes = () => {
  return (
    <div className="form-wrapper mx-20 my-20">
      <form action="">
        <div className="form-group mb-15">
          <h5>Public Notes</h5>
          <textarea
            className="form-control font-14"
            placeholder="Enter a public notes"
            rows="6"
          ></textarea>
        </div>
        <div className="form-group">
          <h5 className="d-flex align-items-center">
            <IconLock className="text-dark mr-1" />
            Private Notes
          </h5>
          <textarea
            className="form-control font-14"
            placeholder="Enter a private notes"
            rows="6"
          ></textarea>
        </div>
      </form>
    </div>
  );
};

export default Notes;
