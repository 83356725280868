import React, { useState } from "react";
import { IconPlus } from "../../../common/icons";
import Pagination from "../../../common/pagination/index";
import SettingsTab from "../common-elements/SettingsTabs";
import StaticTableContent from "./elements/StaticTableContent";

const UserSettings = () => {
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <SettingsTab
            activeTab={1}
            button={
              <button
                className="ml-auto btn btn-success"
              >
                <IconPlus className="mr-2" />
                Add New Role
              </button>
            }
          />
        </div>
      </div>
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-2 d-flex align-items-center justify-content-between">
            <h4 className="font-20 mb-0">Assign Permission Role</h4>
            <Pagination />
          </div>
        </div>

        {/* Static Table Content */}
        <StaticTableContent />
      </div>
    </div>
  );
};

export default UserSettings;
