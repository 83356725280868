import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { IconDownload, IconFire, IconPrinter, IconSnowFlake } from "../../../common/icons";

const TrackOsEmptyReturnTable = ({}) => {
  const [showBookingModal, setShowBookingModal] = useState(false);

  const handleShowBookingModal = () => {
    setShowBookingModal(true);
  };
  const handleHideAddModal = () => {
    setShowBookingModal(false);
  };

  //Table Datas
  const tableDatas = [];
  for (let i = 1; i < 20; i++) {
    tableDatas.push({
      id: i,
    });
  }

  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 249px)" }}
      >
        <table className="table table-card">
          <thead>
            <tr>
              <th width="40">
                <div className="from-check form-check--gray">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="sr_no"
                  />
                </div>
              </th>
              <th>Terminal</th>
              <th>Issues</th>
              <th width="150">Date</th>
            </tr>
          </thead>
          <tbody>
            {tableDatas.map((tabledata) => {
              return (
                <tr key={tabledata.id}>
                  <td>
                    <div className="from-check form-check--gray">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="sr_no"
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      className="pointer text-primary"
                      onClick={handleShowBookingModal}
                    >
                      APM Terminals
                    </div>
                  </td>
                  <td>6</td>
                  <td>25/08/2021 • 11:44</td>
                </tr>
              );
            })}
            ;
          </tbody>
        </table>
      </div>
      <Modal show={showBookingModal} centered animation={false} size="xl">
        <Modal.Header className="d-flex align-items-start modal-header--gray modal-header">
          <div className="d-flex flex-column">
            <Modal.Title>APM Terminals</Modal.Title>
            <div className="text-muted mb-1">25/08/2021 • 11:44</div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <img
            width="100%"
            className="img-fluid"
            src="https://i.imgur.com/QD7URsK.png"
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-light" onClick={handleHideAddModal}>
            <IconDownload className="mr-2" />
            Download
          </button>
          <button className="btn btn-outline-light" onClick={handleHideAddModal}>
            <IconPrinter className="mr-2" />
            Print
          </button>
          <button className="btn btn-primary" onClick={handleHideAddModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default TrackOsEmptyReturnTable;
