import React, { useState } from 'react';
import { IconPen, IconPlus } from '../../../../common/icons';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const groups = [
  {
    id: 1,
    groupImage:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg',
    groupName: 'Brooklyn Simmons',
  },
  {
    id: 2,
    groupImage:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    groupName: 'Darrell Steward',
  },
  {
    id: 3,
    groupImage:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-1.jpg',
    groupName: 'Guy Hawkins',
  },
  {
    id: 4,
    groupImage:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    groupName: 'Leslie Alexander',
  },
  {
    id: 5,
    groupImage:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg',
    groupName: 'Esther Howard',
  },
  {
    id: 6,
    groupImage:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-4.jpg',
    groupName: 'Albert Flores',
  },
];

const Driver = () => {
  const [isAdded, setIsAdded] = useState(null);
  return (
    <div className="form-wrapper p-30">
      <form action="">
        <div className="form-group mb-0">
          <label className="col-form-label">Members</label>
          {/* Search Member Groups */}
          <div className="app-search bg-gray-50 rounded-lg mb-10">
            <input
              type="text"
              className="input-search-left form-control py-2"
              placeholder="Search..."
            />
            <span className="search-icon ml-1"></span>
          </div>
          {/* Members Group List */}
          <div
            className="members-list mt-20"
            style={{ height: 'calc(100% - 55px)' }}
          >
            {groups.map((group) => (
              <div class="d-flex align-items-center mb-10" key={group.id}>
                <div class="flex-shrink-0 mr-2 align-self-center">
                  <img
                    src={group.groupImage}
                    class="avatar rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <div class="flex-grow-1">
                  <h5 class="font-14 mb-0">{group.groupName}</h5>
                </div>
                <div class="flex-shrink-0">
                  {isAdded === 3 ? (
                    <button class="btn btn-sm btn-outline-light">Added</button>
                  ) : (
                    <button class="btn btn-sm btn-primary">
                      <IconPlus className="mr-10" />
                      Add
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </form>
    </div>
  );
};

export default Driver;
