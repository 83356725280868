import React, { Component } from 'react';
import { IconPen, IconTrash } from '../../../../../common/icons';

class StaticTableContent extends Component {
  render() {
    return (
      <div
        className="table-responsive"
        style={{ height: 'calc(100vh - 326.8px)' }}
      >
        <table className="table table-card ">
          <thead>
            <tr>
              <th width="20%">Company Name</th>
              <th width="">Phone</th>
              <th width="">Main Contact name</th>
              <th width="20%">Address</th>
              <th width="10%">City</th>
              <th width="10%">State</th>
              <th width="3%"></th>
              <th width="3%"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>2972 Westheimer Rd. Santa Ana, Illinois 85486 </td>
              <td>Austin</td>
              <td>IL</td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default StaticTableContent;
