import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { CustomIconSpreadSheet } from "../../../common/customIcons";
import { IconDownload, IconPlus, IconSendEmail } from "../../../common/icons";
import Pagination from "../../../common/pagination";
import TrackOsProfileTable from "./EmptyReturnTable";
import TrackOsProfileFilterRow from "./FilterRow";

const TrackOsProfile = ({}) => {
  const [showAddModal, setShowAddModal] = useState(false);

  const handleShowAddModal = () => {
    setShowAddModal(true);
  };
  const handleHideAddModal = () => {
    setShowAddModal(false);
  };
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <div className="d-flex flex-row align-items-center justify-content-between w-100">
            <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
              <li className="nav-item">
                <a href="#" className={`nav-link`}>
                  <div className="">Active</div>
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className={`nav-link`}>
                  <div className="">Archived</div>
                </a>
              </li>
            </ul>
            <div className="ml-auto">
              <button className="btn btn-success" onClick={handleShowAddModal}>
                <IconPlus className="mr-2" />
                Add New Profile
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-15 d-flex align-items-center">
            <h4 className="mb-0">253 Profiles</h4>
            <Pagination className={"ml-auto"} />
          </div>
          <TrackOsProfileFilterRow size={"small"} />
        </div>
        <TrackOsProfileTable />
      </div>

      <Modal show={showAddModal} centered animation={false} size="sm">
        <Modal.Header>
          <Modal.Title>Add New Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-wrapper">
            <div className="form-group">
              
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" onClick={handleHideAddModal}>
            Cancel
          </button>
          <button className="btn btn-primary">Add Profile</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TrackOsProfile;
