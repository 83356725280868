import React, { useState } from "react";

import {
  IconDownload,
  IconMoon,
  IconSendEmail,
  IconSun,
} from "../../../../common/icons/index";

const columns = ["Driver", "Shift Types"];

const tableData = [];
for (let i = 0; i < 20; i++) {
  tableData.push({
    id: i,
    customer: "Kristin Watson",
    shiftTypes: i,
  });
}

const options = [
  { value: "jessica.hanson@example.com", label: "jessica.hanson@example.com" },
  { value: "john.doe@example.com", label: "john.doe@example.com" },
  { value: "alex@example.com", label: "alex@example.com" },
];

const StaticTableContent = () => {
  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 340px)" }}
      >
        <table className="table table-card table-card--ls">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableData.map((tdata) => {
              return (
                <tr key={tdata.id}>
                  <td>
                    <div className="d-flex">
                      <div className="mr-3 align-self-center">
                        <div className="avatar-circle bg-light">
                          <div className="avatar-circle__initial ">AB</div>
                        </div>
                      </div>
                      <h6 className="mb-0 align-self-center d-flex align-items-center">
                        <a href="#" className="text-dark">
                          {tdata.customer}
                        </a>
                      </h6>
                    </div>
                  </td>
                  <td>
                    {tdata.shiftTypes % 2 === 0 ? (
                      <span className="badge badge-primary">
                        <IconSun className="mr-2" />
                        Day Shift
                      </span>
                    ) : (
                      <span className="badge bg-gray-500 text-white">
                        <IconMoon className="mr-2" />
                        Night Shift
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default StaticTableContent;
