import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomSelect from "../CustomSelect";
import CustomCreatableSelect from "../CustomSelect/creatable";
import { IconCalendar } from "../icons";
import UploadDocument from "../uploadDocument";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];
const tabItems = [
  {
    id: 1,
    title: "Manually",
  },
  {
    id: 2,
    title: "Automatically",
  },
];

const AddLoad = (props) => {
  const { hideAddModal, showAddModal } = props;
  const [isTabActive, setIsTabActive] = useState(1);

  return (
    <React.Fragment>
      <Modal show={showAddModal} centered animation={false} size="sm">
        <Modal.Header>
          <Modal.Title>New Load</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <ul className="nav nav-compact bg-gray-50 w-100 mb-30" role="tablist">
            {tabItems.map((tabItem) => (
              <li className="nav-item w-50 text-center" key={tabItem.id}>
                <a
                  href="#!"
                  className={`nav-link ml-1 ${
                    isTabActive === tabItem.id ? " active" : "rounded-3"
                  }`}
                  onClick={() => setIsTabActive(tabItem.id)}
                >
                  {tabItem.title}
                </a>
              </li>
            ))}
          </ul>
          {isTabActive == 1 && (
            <div className="form-wrapper">
              <form>
                <div className="form-group mb-20">
                  <label className="mb-10">
                    <span className="text-danger">*</span> Load Type
                  </label>
                  <div className="row">
                    <div className="col-md">
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="Import"
                          name="Import"
                        />
                        <label className="form-check-label" for="Import">
                          Import
                        </label>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="Export"
                          name="Export"
                        />
                        <label className="form-check-label" for="Export">
                          Export
                        </label>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="Road"
                          name="Road"
                        />
                        <label className="form-check-label" for="Road">
                          Road
                        </label>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="billonly"
                          name="billonly"
                        />
                        <label className="form-check-label" for="billonly">
                          Bill Only
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-20">
                  <label className="mb-10">
                    <span className="text-danger">*</span> Customer
                  </label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select Customer"
                  />
                </div>

                <div className="form-group mb-20">
                  <div className="form-check mb-10">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="Available"
                    />
                    <label className="form-check-label" for="Available">
                      Send Email to Employee
                    </label>
                  </div>
                  <CustomCreatableSelect
                    name={"name"}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Search by name or email"
                  />
                </div>
                <div className="form-group mb-20">
                  <label className="mb-10">
                    <span className="text-danger">*</span> Port
                  </label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select Port"
                  />
                </div>
                <div className="form-group mb-20">
                  <label className="mb-10">
                    <span className="text-danger">*</span> Consignee
                  </label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select Consignee"
                  />
                </div>
                <div className="form-group mb-20">
                  <label className="mb-10">
                    <span className="text-danger">*</span> Select Route for this
                    load
                  </label>

                  <div className="form-check mb-10">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="prepull-drop-hook"
                      name="route"
                    />
                    <label className="form-check-label" for="prepull-drop-hook">
                      Prepull + Drop & Hook
                    </label>
                  </div>

                  <div className="form-check mb-10">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="prepull-live-unload"
                      name="route"
                    />
                    <label
                      className="form-check-label"
                      for="prepull-live-unload"
                    >
                      Prepull + Live Unload
                    </label>
                  </div>

                  <div className="form-check mb-10">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="pick-run-drop-hook"
                      name="route"
                    />
                    <label
                      className="form-check-label"
                      for="pick-run-drop-hook"
                    >
                      Pick and Run + Drop & Hook
                    </label>
                  </div>

                  <div className="form-check mb-10">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="one-way-move"
                      name="route"
                    />
                    <label className="form-check-label" for="one-way-move">
                      One Way Move
                    </label>
                  </div>
                  <div className="form-check mb-10">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="pick-run-live-unload"
                      name="route"
                    />
                    <label
                      className="form-check-label"
                      for="pick-run-live-unload"
                    >
                      Pick and Run + Live Unload
                    </label>
                  </div>
                </div>
              </form>
            </div>
          )}
          {isTabActive == 2 && (
            <div className="form-wrapper">
              <form>
                <div className="form-group mb-20">
                  <label className="mb-10">
                    <span className="text-danger">*</span> Load Type
                  </label>
                  <div className="row">
                    <div className="col-md">
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="Import"
                          name="Import"
                        />
                        <label className="form-check-label" for="Import">
                          Import
                        </label>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="Export"
                          name="Export"
                        />
                        <label className="form-check-label" for="Export">
                          Export
                        </label>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="Road"
                          name="Road"
                        />
                        <label className="form-check-label" for="Road">
                          Road
                        </label>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="billonly"
                          name="billonly"
                        />
                        <label className="form-check-label" for="billonly">
                          Bill Only
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <UploadDocument className="mb-20" />
                <div className="form-group mb-20">
                  <label className="mb-10">
                    <span className="text-danger">*</span> Select Route for this
                    load
                  </label>

                  <div className="form-check mb-10">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="prepull-drop-hook"
                      name="route"
                    />
                    <label className="form-check-label" for="prepull-drop-hook">
                      Prepull + Drop & Hook
                    </label>
                  </div>

                  <div className="form-check mb-10">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="prepull-live-unload"
                      name="route"
                    />
                    <label
                      className="form-check-label"
                      for="prepull-live-unload"
                    >
                      Prepull + Live Unload
                    </label>
                  </div>

                  <div className="form-check mb-10">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="pick-run-drop-hook"
                      name="route"
                    />
                    <label
                      className="form-check-label"
                      for="pick-run-drop-hook"
                    >
                      Pick and Run + Drop & Hook
                    </label>
                  </div>

                  <div className="form-check mb-10">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="one-way-move"
                      name="route"
                    />
                    <label className="form-check-label" for="one-way-move">
                      One Way Move
                    </label>
                  </div>
                  <div className="form-check mb-10">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="pick-run-live-unload"
                      name="route"
                    />
                    <label
                      className="form-check-label"
                      for="pick-run-live-unload"
                    >
                      Pick and Run + Live Unload
                    </label>
                  </div>
                </div>
              </form>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" onClick={hideAddModal}>
            Cancel
          </button>
          <button className="btn btn-success">Create Load</button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default AddLoad;
