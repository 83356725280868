import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./assets/SCSS/style/style.scss";
import Header from "./common/header";
import Sidebar from "./common/sidebar";
import Home from "./components/home";
import NewTrack from "./components/track";
import Dispatcher from "./components/dispatcher";
import DroppedContainers from "./components/customerService/droppedcontainers";
import MainCustomer from "./components/dispatcher/billing/MainCustomer";
import SubCustomer from "./components/dispatcher/billing/SubCustomer";
import Calendar from "./components/customerService/calendar";
import BulkUpload from "./components/customerService/bulkupload/index";
import UploadAuditDocument from "./components/billing/chassisAudit/uploadAuditDocument";
import { useRouteMatch } from "react-router-dom";

//Messaging
import DrayosSignup from "./components/Auth/DrayosSignup";
import CustomerMessaging from "./components/customerService/customerMessaging/index";
import DriverMessaging from "./components/customerService/driverMessaging/index";
import GroupMessaging from "./components/customerService/groupMessage";
import EmployeeMessaging from "./components/customerService/employeeMessaging";
import MeetingCalendar from '../src/common/MeetingCalendar/index'

//customerServices
import CustomerDashboard from "./components/customerService/customerDashboard";
import Quotes from "./components/customerService/quotes";
import CustomerLoads from "./components/customerService/loads";
import TenderedLoads from "./components/customerService/tenderedLoads";
// accountpayable pages
import DriverSettlements from "./components/accountPayable/settlements";
import DriverPay from "./components/accountPayable/driver-pay";
import DynamicPricing from "./components/accountPayable/dynamicPricing";
// driverprofiles page
import DriverProfiles from "./components/driver/driverProfiles";
import AssignTruck from "./components/driver/assignTruck";
import TruckOwner from "./components/driver/truckOwner";
import DriverPayRates from "./components/driver/driverPayRates";
import DriverDeductionRates from "./components/driver/driverDeductionRates";
// equipment page
import TruckProfiles from "./components/equipment/TruckProfiles";
import Chassis from "./components/equipment/chassis";
import Trailers from "./components/equipment/trailers";
// Customer Pages
import ProfileCustomer from "./components/customers/profile/customer";
import CustomerRates from "./components/customers/customerRates";
import CustomerDynamicPricing from "./components/customers/dynamicPricing";
import CustomerDiscount from "./components/customers/customerDiscount";
import ProfileGroupCustomer from "./components/customers/profile/groupcustomers";
import Aging from "./components/billing/aging";
import ChassisAudit from "./components/billing/chassisAudit";
import AccountsReceivable from "./components/billing/accountsReceivable";
import DesyncedQBLoads from "./components/billing/desyncedQBLoads";
import SubAccountsReceivable from "./components/billing/subAccountsReceivable";
import DeletedLoads from "./components/customerService/deletedLoads";
import ChassisValidation from "./components/customerService/chassisValidation";
import BatchApplyPayments from "./components/billing/batchApplyPayments";
import Trucks from "./components/safety/trucks";
import DynamicExpense from "./components/others/dynamicExpense";
import ProfileContact from "./components/others/profile/contact";
import ProfileDriver from "./components/others/profile/driver";
import ProfileTruck from "./components/others/profile/truck";
import ProfileChassis from "./components/others/profile/chassis";
import ProfileTrailer from "./components/others/profile/trailer";
import ContainerOwner from "./components/settings/container/owner";
import ContainerSize from "./components/settings/container/size";
import ContainerType from "./components/settings/container/type";
import ContainerFreeDay from "./components/settings/container/freeDay/index";
import DriverItinerary from "./components/dispatcher/driverItinerary";
import DoubleMoves from "./components/dispatcher/doubleMoves";
import StreetTurns from "./components/dispatcher/streetTurns";
import AssignPermissionRole from "./components/others/assignPermissionRole";
import RoleManagement from "./components/others/roleManagement";
import UsersSettings from "./components/settings/users";
// Chasis Page Sub Pages
import ChassisOwner from "./components/settings/chassisType/owner";
import ChassisType from "./components/settings/chassisType/type";
import ChassisSize from "./components/settings/chassisType/size";
//Settings Page
import TrucksSettings from "./components/settings/trucks";
import TrailersSettings from "./components/settings/trailers";
import ChassisSettings from "./components/settings/chassis";
import CustomerSettings from "./components/settings/customer";
import DriverRatesSettings from "./components/settings/driverRates";
import AssignTruckSettings from "./components/settings/assignTruck";
import CustomerRatesSettings from "./components/settings/customerRates";
import DynamicPricingSettings from "./components/settings/dynamicPricing";
import GeneralExpenseSettings from "./components/settings/generalExpense";
import NotificationSettings from "./components/settings/notifications";
import EmailNotificationsSettings from "./components/settings/emailNotifications";
import CommoditySettings from "./components/settings/commodity";
import TerminalSettings from "./components/settings/terminal";

import LoadStatusColorSettings from "./components/settings/loadStatusColor";
import CarrierGroupSettings from "./components/settings/carrierGroup";
import DispatchColorSettings from "./components/settings/dispatchColorSetting";

import Permission from "./components/settings/permission";
import SettingRoleManagement from "./components/settings/settingRoleManagement";

import Checkout from './common/Checkout/index';

//Reports Page
import Charge from "./components/reports/charge";
import CustomerRevenue from "./components/reports/customerRevenue";
import Loads from "./components/reports/loads";
import Productivity from "./components/reports/productivity";
import Vessel from "./components/reports/vessel";
import LFD from "./components/reports/lfd";
import ClockReport from "./components/reports/clockReport";
import LoadMasterReport from "./components/reports/loadMasterReport";
import CustomMasterReport from "./components/reports/customMasterReport";
import AdminDashboard from "./components/reports/adminDashboard";
import ImprovementDashboard from "./components/reports/ImprovementDashboard";
import DriverPayReport from "./components/reports/driverPayReport";
import DriverRevenue from "./components/reports/driverRevenue";
import NewDriverRevenue from "./components/reports/newDriverRevenue";
import LegTypeReport from "./components/reports/legTypeReport";
import DriverStatusReport from "./components/reports/driverStatusReport";
import AgingReport from "./components/reports/aging";
import EditReport from "./components/reports/customMasterReport/ReportTabComponent/EditReport";

//Documentation
import DocumentationIcons from "./components/documentation/icons";
import DocumentationCustomIcons from "./components/documentation/icons/CustomIcons";
import DocumentationLoader from "./components/documentation/loader";
import ProblemContainer from "./components/dispatcher/problemContainer";
import SAgeneralInfo from "./components/settings/SAgeneralInfo";
import SAequipmentParameters from "./components/settings/SAequipmentParameters";
import SAdocumentConfiguration from "./components/settings/SAdocumentConfiguration";
import SAemaildocumentConfiguration from "./components/settings/SAemailConfiguration";
import CustomerPortalTrack from "./components/customerPortal-track";
import ChangeColor from "./components/changeColor";
import EmailPage from "./components/email";
import CombinedBills from "./components/billing/accountsReceivable/combinedBills";

//TrackOs Pages
import TrackOsTracking from "./components/trackOs/Tracking/tracking";
import TrackOsImportAvailability from "./components/trackOs/ImportAvailability";
import TrackOsBookings from "./components/trackOs/Bookings";
import TrackOsEmptyReturn from "./components/trackOs/EmptyReturn";
import TrackOsProfile from "./components/trackOs/Profiles";
import TrackOsSidebar from "./common/sidebar/TrackOsSidebar";
import ShipOsSidebar from "./common/sidebar/ShipOsSidebar";
import ShipOsTracking from "./components/ShipOs/Tracking/index";
import Subscription from './components/trackOs/Subscription';
import Signup from "./components/trackOs/Auth/Signup";
import OnBoarding from "./components/trackOs/OnBoarding";
import TrackOsCompanyInfoForm from './components/trackOs/CompanyInfoForm';
import PaymentOptions from "./components/trackOs/PaymentOptions";
import EmptyReturnLocations from "./components/trackOs/EmptyReturn/Locations";
import EmptyHistory from './components/trackOs/EmptyReturn/History';
import LiveShare from "./components/ShipOs/LiveShare";
import ContainerTracking from "./components/DrayosLite";

const SmartRoute = () => {
  const role = localStorage.getItem('role');
  if (role === 'trackos') {
    return <TrackOsRoute />;
  } else if (role === 'shipos') {
    return <ShipOsRoute />;
  } else if (role === 'drayos') {
    return <DrayOsRoute />;
  } else {
    return <DrayOsRoute />;
  }
};

const TrackOsRoute = () => {
  return (
    <Router>
      <Header />
      <TrackOsSidebar />
      <div className="page-wrapper" style={{ display: 'block' }}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/trackos/signup" />
          </Route>
          <Route path="/trackos/signup" component={Signup}></Route>
          <Route path="/trackos/tracking" component={TrackOsTracking}></Route>
          <Route
            exact
            path="/trackos/import-availability"
            component={TrackOsImportAvailability}
          ></Route>
          <Route path="/trackos/bookings" component={TrackOsBookings}></Route>
          <Route
            path="/trackos/empty-return"
            component={TrackOsEmptyReturn}
          ></Route>
          <Route
            path="/trackos/empty-return-locations"
            component={EmptyReturnLocations}
          ></Route>
          <Route path="/trackos/profile" component={TrackOsProfile}></Route>
          <Route path="/trackos/subscription" component={Subscription}></Route>
          <Route path="/trackos/onboarding" component={OnBoarding}></Route>
          <Route
            path="/trackos/company-info"
            component={TrackOsCompanyInfoForm}
          ></Route>
          <Route
            path="/trackos/payment-options"
            component={PaymentOptions}
          ></Route>
          <Route
            path="/trackos/empty-return-history"
            component={EmptyHistory}
          ></Route>
        </Switch>
      </div>
    </Router>
  );
};

const ShipOsRoute = () => {
  let match = useRouteMatch("/trackos/live-share");

  return (
    <Router>
      <Header />
      <ShipOsSidebar />
      <div className="page-wrapper" style={{ display: "block" }}>
        {/* <div className={`page-wrapper ${match ? "top-0 ml-60 h-0" : ""}`} style={{ display: "block" }}> */}
        <Switch>
          <Route exact path="/">
            <Redirect to="/shipos/tracking" />
          </Route>
          <Route path="/shipos/tracking" component={ShipOsTracking}></Route>
          <Route
            exact
            path="/trackos/import-availability"
            component={TrackOsImportAvailability}
          ></Route>
          <Route path="/trackos/bookings" component={TrackOsBookings}></Route>
          <Route
            path="/trackos/empty-return"
            component={TrackOsEmptyReturn}
          ></Route>
          <Route path="/trackos/profile" component={TrackOsProfile}></Route>
          <Route path="/shipos/live-share" component={LiveShare}></Route>

        </Switch>
      </div>
    </Router>
  );
};

const DrayOsRoute = () => {
  return (
    <Router>
      <Header />
      <Sidebar />
      <div className="page-wrapper" style={{ display: "block" }}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/dispatcher" />
          </Route>
          <Route path="/auth" component={DrayosSignup}></Route>
          <Route path="/track" component={NewTrack}></Route>
          <Route path="/checkout" component={Checkout}></Route>
          <Route path="/schedule" component={MeetingCalendar}></Route>
          <Route path="/customerportal-track">
            <CustomerPortalTrack />
          </Route>
          <Route path="/dispatcher" component={Dispatcher}></Route>
          <Route path="/driver-itinerary" component={DriverItinerary}></Route>
          <Route path="/double-moves" component={DoubleMoves}></Route>
          <Route path="/street-turns" component={StreetTurns}></Route>
          <Route path="/problem-container" component={ProblemContainer}></Route>

          <Route path="/main-customer" component={MainCustomer}></Route>
          <Route path="/sub-customer" component={SubCustomer}></Route>
          <Route path="/audit-document" component={UploadAuditDocument}></Route>

          {/* Customer Service */}
          <Route
            path="/customer-dashboard"
            component={CustomerDashboard}
          ></Route>
          <Route path="/customerServices/quotes" component={Quotes}></Route>
          <Route
            path="/customerServices/loads"
            component={CustomerLoads}
          ></Route>
          <Route
            path="/customerServices/tender"
            component={TenderedLoads}
          ></Route>
          <Route
            path="/customerService/dropped-containers"
            component={DroppedContainers}
          ></Route>
          <Route path="/customerService/calendar" component={Calendar}></Route>
          <Route
            path="/customerService/bulk-uploads"
            component={BulkUpload}
          ></Route>
          <Route
            path="/customerService/chassis-validation"
            component={ChassisValidation}
          ></Route>
          <Route
            path="/customerService/customer-messaging"
            component={CustomerMessaging}
          ></Route>
          <Route
            path="/customerService/employee-messaging"
            component={EmployeeMessaging}
          ></Route>
          <Route
            path="/customerService/driver-messaging"
            component={DriverMessaging}
          ></Route>
          {/* <Route
              path="/customerService/group-messaging"
              component={GroupMessaging}
            ></Route> */}
          <Route
            path="/customerService/group-message"
            component={GroupMessaging}
          ></Route>
          <Route
            path="/customerService/deleted-loads"
            component={DeletedLoads}
          ></Route>

          {/* AccountPayable */}
          <Route
            path="/accountpayable/driver-pay"
            component={DriverPay}
          ></Route>
          <Route
            path="/accountpayable/driver-settlements"
            component={DriverSettlements}
          ></Route>
          <Route
            path="/accountpayable/dynamic-pricing"
            component={DynamicPricing}
          ></Route>

          {/* Driver */}
          <Route
            path="/driver/driver-profiles"
            component={DriverProfiles}
          ></Route>
          <Route path="/driver/assign-truck" component={AssignTruck}></Route>
          <Route path="/driver/truck-owner" component={TruckOwner}></Route>
          <Route
            path="/driver/driver-pay-rates"
            component={DriverPayRates}
          ></Route>
          <Route
            path="/driver/driver-deduction-rate"
            component={DriverDeductionRates}
          ></Route>

          {/* Equipment */}
          <Route
            path="/equipment/truck-profiles"
            component={TruckProfiles}
          ></Route>
          <Route path="/equipment/chassis" component={Chassis}></Route>
          <Route path="/equipment/trailers" component={Trailers}></Route>

          <Route
            path="/billing/accounts-receivable"
            component={AccountsReceivable}
          ></Route>
          <Route
            path="/accounts-receivable/combined-bills"
            component={CombinedBills}
          ></Route>
          <Route
            path="/billing/desynced-quickbooks"
            component={DesyncedQBLoads}
          ></Route>
          <Route
            path="/billing/sub-accounts-receivable"
            component={SubAccountsReceivable}
          ></Route>
          <Route
            path="/billing/batch-apply-payments"
            component={BatchApplyPayments}
          ></Route>
          <Route path="/billing/aging" component={Aging}></Route>
          <Route path="/billing/chassis-audit" component={ChassisAudit}></Route>

          {/* Safety */}
          <Route path="/safety/trucks" component={Trucks}></Route>
          <Route path="/safety/trailers" component={Trailers}></Route>

          {/* Others */}
          <Route
            path="/other/dynamic-expense"
            component={DynamicExpense}
          ></Route>

          {/* Customer Pages */}
          <Route
            path="/customers/profile/customer"
            component={ProfileCustomer}
          ></Route>
          <Route
            path="/customers/customer-rates"
            component={CustomerRates}
          ></Route>
          <Route
            path="/customers/customer-dynamic-pricing"
            component={CustomerDynamicPricing}
          ></Route>
          <Route
            path="/customer/customer-discount"
            component={CustomerDiscount}
          ></Route>
          <Route
            path="/customer/profile/group-customer"
            component={ProfileGroupCustomer}
          ></Route>

          <Route
            path="/other/profile/contact"
            component={ProfileContact}
          ></Route>
          <Route path="/other/profile/driver" component={ProfileDriver}></Route>
          <Route path="/other/profile/truck" component={ProfileTruck}></Route>
          <Route
            path="/other/profile/chassis"
            component={ProfileChassis}
          ></Route>
          <Route
            path="/other/profile/trailer"
            component={ProfileTrailer}
          ></Route>

          {/* Assign Permission Pages */}
          <Route
            path="/other/assign-permission-role"
            component={AssignPermissionRole}
          ></Route>
          <Route
            path="/other/assign-role-management"
            component={RoleManagement}
          ></Route>
          <Route
            path="/other/container/owner"
            component={ContainerOwner}
          ></Route>
          <Route path="/other/container/type" component={ContainerType}></Route>
          <Route path="/other/container/size" component={ContainerSize}></Route>
          <Route
            path="/other/container/free-day"
            component={ContainerFreeDay}
          ></Route>

          {/* Chasis Sub Pages */}
          <Route path="/other/chassis/owner" component={ChassisOwner}></Route>
          <Route path="/other/chassis/type" component={ChassisType}></Route>
          <Route path="/other/chassis/size" component={ChassisSize}></Route>

          {/* Reports */}
          <Route path="/reports/charge" component={Charge}></Route>
          <Route
            path="/reports/customer-revenue"
            component={CustomerRevenue}
          ></Route>
          <Route path="/reports/loads" component={Loads}></Route>
          <Route path="/reports/productivity" component={Productivity}></Route>
          <Route path="/reports/vessel" component={Vessel}></Route>
          <Route path="/reports/lfd" component={LFD}></Route>
          <Route path="/reports/clock-report" component={ClockReport}></Route>
          <Route
            path="/reports/load-master-report"
            component={LoadMasterReport}
          ></Route>
          <Route
            path="/reports/custom-master-report"
            component={CustomMasterReport}
          ></Route>
          <Route
            path="/reports/custom-master-report"
            component={CustomMasterReport}
          ></Route>
          <Route
            path="/reports/admin-dashboard"
            component={AdminDashboard}
          ></Route>
          <Route
            path="/reports/improvement-dashboard"
            component={ImprovementDashboard}
          ></Route>
          <Route
            path="/reports/driver-pay-report"
            component={DriverPayReport}
          ></Route>
          <Route
            path="/reports/driver-revenue"
            component={DriverRevenue}
          ></Route>
          <Route
            path="/reports/leg-type-report"
            component={LegTypeReport}
          ></Route>
          <Route
            path="/reports/driver-status-report"
            component={DriverStatusReport}
          ></Route>
          <Route
            path="/reports/new-driver-revenue"
            component={NewDriverRevenue}
          ></Route>
          <Route path="/reports/aging" component={AgingReport}></Route>
          <Route path="/report/edit-report" component={EditReport}></Route>

          {/* Settings */}
          <Route path="/settings/users" component={UsersSettings}></Route>
          <Route path="/settings/trucks" component={TrucksSettings}></Route>
          <Route path="/settings/trailers" component={TrailersSettings}></Route>
          <Route path="/settings/chassis" component={ChassisSettings}></Route>
          <Route path="/settings/customer" component={CustomerSettings}></Route>
          <Route
            path="/settings/driver-rates"
            component={DriverRatesSettings}
          ></Route>
          <Route
            path="/settings/assign-truck"
            component={AssignTruckSettings}
          ></Route>
          <Route
            path="/settings/customer-rates"
            component={CustomerRatesSettings}
          ></Route>
          <Route
            path="/settings/dynamic-pricing"
            component={DynamicPricingSettings}
          ></Route>
          <Route
            path="/settings/general-expense"
            component={GeneralExpenseSettings}
          ></Route>
          <Route
            path="/settings/notifications"
            component={NotificationSettings}
          ></Route>
          <Route
            path="/settings/email-notifications"
            component={EmailNotificationsSettings}
          ></Route>
          <Route
            path="/settings/commodity-profile"
            component={CommoditySettings}
          ></Route>
          <Route
            path="/settings/commodity-terminal"
            component={TerminalSettings}
          ></Route>
          <Route
            path="/settings/commodity-dispatch-color"
            component={DispatchColorSettings}
          ></Route>
          <Route
            path="/settings/load-status-color"
            component={LoadStatusColorSettings}
          ></Route>
          <Route
            path="/settings/carrier-group"
            component={CarrierGroupSettings}
          ></Route>
          <Route path="/settings/permission" component={Permission}></Route>
          <Route
            path="/settings/role-management"
            component={SettingRoleManagement}
          ></Route>
          <Route
            path="/settings/container-tracking"
            component={ContainerTracking}
          ></Route>

          {/* Super Admin Pages */}
          <Route
            path="/settings/general-info"
            component={SAgeneralInfo}
          ></Route>
          <Route
            path="/settings/equipment-parameters"
            component={SAequipmentParameters}
          ></Route>
          <Route
            path="/settings/document-configuration"
            component={SAdocumentConfiguration}
          ></Route>
          <Route
            path="/settings/email-configuration"
            component={SAemaildocumentConfiguration}
          ></Route>

          {/* Documentation */}
          <Route
            path="/documentation/icons"
            component={DocumentationIcons}
          ></Route>
          <Route
            path="/documentation/custom-icons"
            component={DocumentationCustomIcons}
          ></Route>
          <Route
            path="/documentation/loader"
            component={DocumentationLoader}
          ></Route>
          <Route path="/change-color" component={ChangeColor}></Route>

          <Route path="/email" component={EmailPage}></Route>
        </Switch>
      </div>
    </Router>
  );
};

function App() {
  return (
    <Router>
      <div id="main-wrapper">
        <Switch>
          <SmartRoute />
        </Switch>
      </div>
    </Router>
  );
}
export default App;
