import React, { useState } from 'react';
import CustomSelect from '../../../../common/CustomSelect';

const options = [
    { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
    { value: 'John Doe', label: 'John Doe' },
    { value: 'Alex Mendes', label: 'Alex Mendes' },
];

const Permissions = (props) => {
    return (
        <div className="form-wrapper py-20 px-30">
            <div className="d-flex align-items-center my-20">
                <div className="mr-15">
                    {/* Select Customer */}
                    <CustomSelect
                        name={'name'}
                        options={options}
                        value={1}
                        isMulti={true}
                        isClearable={true}
                        size="small"
                        placement="right"
                        placeholder="Select role"
                        className="w-200 z-2"
                    />
                </div>
                <h6 className="text-gray-500 mb-0">
                    You can select the role or assign each permission customly
                </h6>
            </div>
            <div className="border mb-20"></div>
            <form>
                <div className="form-row mt-4">
                    <div className="col">
                        <div className="d-flex flex-column">
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="other"
                                    
                                />
                                <label className="form-check-label" for="other">
                                    Other
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="Permission"
                                    
                                />
                                <label className="form-check-label" for="permission">
                                    Permission
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="truck"
                                    
                                />
                                <label className="form-check-label" for="truck">
                                    Truck
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="trailer"
                                    
                                />
                                <label className="form-check-label" for="calender">
                                    Trailer
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-column">
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="other"
                                    
                                />
                                <label className="form-check-label" for="other">
                                    Other
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="Permission"
                                    
                                />
                                <label className="form-check-label" for="permission">
                                    Permission
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="truck"
                                    
                                />
                                <label className="form-check-label" for="truck">
                                    Truck
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="trailer"
                                    
                                />
                                <label className="form-check-label" for="calender">
                                    Trailer
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-column">
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="other"
                                    checked={true}
                                />
                                <label className="form-check-label" for="other">
                                    Other
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="Permission"
                                    checked={true}
                                />
                                <label className="form-check-label" for="permission">
                                    Permission
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="truck"
                                    checked={true}
                                />
                                <label className="form-check-label" for="truck">
                                    Truck
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="trailer"
                                    checked={true}
                                />
                                <label className="form-check-label" for="calender">
                                    Trailer
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-column">
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="other"
                                    checked={true}
                                />
                                <label className="form-check-label" for="other">
                                    Other
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="Permission"
                                    checked={true}
                                />
                                <label className="form-check-label" for="permission">
                                    Permission
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="truck"
                                    checked={true}
                                />
                                <label className="form-check-label" for="truck">
                                    Truck
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="trailer"
                                    checked={true}
                                />
                                <label className="form-check-label" for="calender">
                                    Trailer
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-column">
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="other"
                                    checked={true}
                                />
                                <label className="form-check-label" for="other">
                                    Other
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="Permission"
                                    checked={true}
                                />
                                <label className="form-check-label" for="permission">
                                    Permission
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="truck"
                                    checked={true}
                                />
                                <label className="form-check-label" for="truck">
                                    Truck
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="trailer"
                                    checked={true}
                                />
                                <label className="form-check-label" for="calender">
                                    Trailer
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-column">
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="other"
                                    checked={true}
                                />
                                <label className="form-check-label" for="other">
                                    Other
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="Permission"
                                    checked={true}
                                />
                                <label className="form-check-label" for="permission">
                                    Permission
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="truck"
                                    checked={true}
                                />
                                <label className="form-check-label" for="truck">
                                    Truck
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="trailer"
                                    checked={true}
                                />
                                <label className="form-check-label" for="calender">
                                    Trailer
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-column">
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="other"
                                    checked={true}
                                />
                                <label className="form-check-label" for="other">
                                    Other
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="Permission"
                                    checked={true}
                                />
                                <label className="form-check-label" for="permission">
                                    Permission
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="truck"
                                    checked={true}
                                />
                                <label className="form-check-label" for="truck">
                                    Truck
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="trailer"
                                    checked={true}
                                />
                                <label className="form-check-label" for="calender">
                                    Trailer
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-column">
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="other"
                                    
                                />
                                <label className="form-check-label" for="other">
                                    Other
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="Permission"
                                    
                                />
                                <label className="form-check-label" for="permission">
                                    Permission
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="truck"
                                    
                                />
                                <label className="form-check-label" for="truck">
                                    Truck
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="trailer"
                                    
                                />
                                <label className="form-check-label" for="calender">
                                    Trailer
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-column">
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="other"
                                    
                                />
                                <label className="form-check-label" for="other">
                                    Other
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="Permission"
                                    
                                />
                                <label className="form-check-label" for="permission">
                                    Permission
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="truck"
                                    
                                />
                                <label className="form-check-label" for="truck">
                                    Truck
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="trailer"
                                    
                                />
                                <label className="form-check-label" for="calender">
                                    Trailer
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-column">
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="other"
                                    
                                />
                                <label className="form-check-label" for="other">
                                    Other
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="Permission"
                                    
                                />
                                <label className="form-check-label" for="permission">
                                    Permission
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="truck"
                                    
                                />
                                <label className="form-check-label" for="truck">
                                    Truck
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="trailer"
                                    
                                />
                                <label className="form-check-label" for="calender">
                                    Trailer
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-row mt-4">
                    <div className="col">
                        <div className="d-flex flex-column">
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="other"
                                    
                                />
                                <label className="form-check-label" for="other">
                                    Other
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="Permission"
                                    
                                />
                                <label className="form-check-label" for="permission">
                                    Permission
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="truck"
                                    
                                />
                                <label className="form-check-label" for="truck">
                                    Truck
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="trailer"
                                    
                                />
                                <label className="form-check-label" for="calender">
                                    Trailer
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-column">
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="other"
                                    
                                />
                                <label className="form-check-label" for="other">
                                    Other
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="Permission"
                                    
                                />
                                <label className="form-check-label" for="permission">
                                    Permission
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="truck"
                                    
                                />
                                <label className="form-check-label" for="truck">
                                    Truck
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="trailer"
                                    
                                />
                                <label className="form-check-label" for="calender">
                                    Trailer
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-column">
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="other"
                                    
                                />
                                <label className="form-check-label" for="other">
                                    Other
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="Permission"
                                    
                                />
                                <label className="form-check-label" for="permission">
                                    Permission
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="truck"
                                    
                                />
                                <label className="form-check-label" for="truck">
                                    Truck
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="trailer"
                                    
                                />
                                <label className="form-check-label" for="calender">
                                    Trailer
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-column">
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="other"
                                    
                                />
                                <label className="form-check-label" for="other">
                                    Other
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="Permission"
                                    
                                />
                                <label className="form-check-label" for="permission">
                                    Permission
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="truck"
                                    
                                />
                                <label className="form-check-label" for="truck">
                                    Truck
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="trailer"
                                    
                                />
                                <label className="form-check-label" for="calender">
                                    Trailer
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-column">
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="other"
                                    
                                />
                                <label className="form-check-label" for="other">
                                    Other
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="Permission"
                                    
                                />
                                <label className="form-check-label" for="permission">
                                    Permission
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="truck"
                                    
                                />
                                <label className="form-check-label" for="truck">
                                    Truck
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="trailer"
                                    
                                />
                                <label className="form-check-label" for="calender">
                                    Trailer
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-column">
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="other"
                                    
                                />
                                <label className="form-check-label" for="other">
                                    Other
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="Permission"
                                    
                                />
                                <label className="form-check-label" for="permission">
                                    Permission
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="truck"
                                    
                                />
                                <label className="form-check-label" for="truck">
                                    Truck
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="trailer"
                                    
                                />
                                <label className="form-check-label" for="calender">
                                    Trailer
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-column">
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="other"
                                    
                                />
                                <label className="form-check-label" for="other">
                                    Other
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="Permission"
                                    
                                />
                                <label className="form-check-label" for="permission">
                                    Permission
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="truck"
                                    
                                />
                                <label className="form-check-label" for="truck">
                                    Truck
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="trailer"
                                    
                                />
                                <label className="form-check-label" for="calender">
                                    Trailer
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-column">
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="other"
                                    
                                />
                                <label className="form-check-label" for="other">
                                    Other
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="Permission"
                                    
                                />
                                <label className="form-check-label" for="permission">
                                    Permission
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="truck"
                                    
                                />
                                <label className="form-check-label" for="truck">
                                    Truck
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="trailer"
                                    
                                />
                                <label className="form-check-label" for="calender">
                                    Trailer
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-column">
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="other"
                                    
                                />
                                <label className="form-check-label" for="other">
                                    Other
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="Permission"
                                    
                                />
                                <label className="form-check-label" for="permission">
                                    Permission
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="truck"
                                    
                                />
                                <label className="form-check-label" for="truck">
                                    Truck
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="trailer"
                                    
                                />
                                <label className="form-check-label" for="calender">
                                    Trailer
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex flex-column">
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="other"
                                    
                                />
                                <label className="form-check-label" for="other">
                                    Other
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="Permission"
                                    
                                />
                                <label className="form-check-label" for="permission">
                                    Permission
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="truck"
                                    
                                />
                                <label className="form-check-label" for="truck">
                                    Truck
                                </label>
                            </div>
                            <div className="form-check mb-1">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id="trailer"
                                    
                                />
                                <label className="form-check-label" for="calender">
                                    Trailer
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Permissions;
