import React, { useState } from 'react';
import {
  IconAngleArrowDown,
  IconAngleArrowRight,
} from '../../../../../common/icons/index';

const cardItems = [
  {
    id: 1,
    Shift: 'Shift 1',
    Date: 'Mon, Feb 14 2022',
  },
  {
    id: 2,
    Shift: 'Shift 1',
    Date: 'Mon, Feb 14 2022',
  },
  {
    id: 3,
    Shift: 'Shift 1',
    Date: 'Mon, Feb 14 2022',
  },
  {
    id: 4,
    Shift: 'Shift 1',
    Date: 'Mon, Feb 14 2022',
  },
  {
    id: 5,
    Shift: 'Shift 1',
    Date: 'Mon, Feb 14 2022',
  },
  {
    id: 6,
    Shift: 'Shift 1',
    Date: 'Mon, Feb 14 2022',
  },
  {
    id: 7,
    Shift: 'Shift 1',
    Date: 'Mon, Feb 14 2022',
  },
  {
    id: 8,
    Shift: 'Shift 1',
    Date: 'Mon, Feb 14 2022',
  },
];
const TopEmptyCard = () => {
  const [isCardActive, setCardActive] = useState(1);
  return (
    <div className="top-page mb-10">
      {/* Cards Row */}
      <div className={`form-row ${isCardActive ? 'hasactive' : ''}`}>
        {cardItems.map((cardItem) => (
          <div className="col-md">
            <div
              className={`ncard d-flex justify-content-between p-10 ${
                isCardActive === cardItem.id ? 'active' : ''
              }`}
              onClick={() => setCardActive(cardItem.id)}
            >
              <div className="d-flex flex-column">
                <div className="font-12 font-weight-500 text-gray-900">
                  {cardItem.Shift}
                </div>
                <div className="font-12 font-weight-normal text-gray-500">
                  {cardItem.Date}
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="ml-auto">
                  {isCardActive === cardItem.id ? (
                    <IconAngleArrowDown />
                  ) : (
                    <IconAngleArrowRight />
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopEmptyCard;
