import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomSelect from "../../../../../common/CustomSelect";


const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const AddContainerOwner = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;

  return (
    <Modal show={showModal} centered animation={false}>
      <Modal.Header>
        <Modal.Title>Add Chassis Owner</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="form-wrapper">
          <form>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label"> <span className="text-danger">*</span>Company name</label>
                <input className="form-control" placeholder="Enter Company name" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label"> <span className="text-danger">*</span>Contact name</label>
                <input className="form-control" placeholder="Enter Full contact name" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label"> <span className="text-danger">*</span>Address</label>
                <input className="form-control" placeholder="e.g. 1111 Parker Rd. Allentown, New Mexico 11111" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label className="col-form-label"> <span className="text-danger">*</span>State</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select State"
                  className="w-100"
                />
              </div>
              <div className="form-group col-md-6">
                <label className="col-form-label"> <span className="text-danger">*</span>ZIP</label>
                <input className="form-control" placeholder="Enter Zip" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label"> <span className="text-danger">*</span>SCAC</label>
                <input className="form-control" placeholder="e.g. 1111 Parker Rd. Allentown, New Mexico 11111" />
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-success" onClick={onSubmit}>
          Add Chassis Owner
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddContainerOwner;
