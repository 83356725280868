import React, { useState } from "react";
import CustomSelect from "../../../../common/CustomSelect/index";

import {
  IconColumn,
  IconFilter,
  IconPen,
  IconCheckCircle,
  IconTrash,
  IconCancelCircle,
} from "../../../../common/icons/index";

const columns = [
  "Charge name",
  "Unit of measure",
  "Description of Charge",
  "Add on Demand",
  "$ per unit",
  "Min. total price",
  "Free Units",
];

const data = [
  {
    id: 1,
    chargeName: `Detention`,
    unitOfMeasure: "Per Day",
    desc: "Waiting to Unload",
    appPrice: "Yes",
    perUnit: 250.0,
    minPrice: 250.0,
    freeUnits: 1,
  },
  {
    id: 2,
    chargeName: `Toll`,
    unitOfMeasure: "Per Day",
    desc: "Terminal",
    appPrice: "No",
    perUnit: 250.0,
    minPrice: 250.0,
    freeUnits: 1,
  },
  {
    id: 1,
    chargeName: `Detention`,
    unitOfMeasure: "Per Day",
    desc: "Waiting to Unload",
    appPrice: "Yes",
    perUnit: 250.0,
    minPrice: 250.0,
    freeUnits: 1,
  },
  {
    id: 3,
    chargeName: `Port Congestion Fee`,
    unitOfMeasure: "Per Day",
    desc: "Waiting to Unload",
    appPrice: "Yes",
    perUnit: 250.0,
    minPrice: 250.0,
    freeUnits: 1,
  },
  {
    id: 4,
    chargeName: `Dry Run`,
    unitOfMeasure: "Per Day",
    desc: "Waiting to Unload",
    appPrice: "Yes",
    perUnit: 250.0,
    minPrice: 250.0,
    freeUnits: 1,
  },
  {
    id: 5,
    chargeName: `Tri Axle`,
    unitOfMeasure: "Per Day",
    desc: "-",
    appPrice: "Yes",
    perUnit: 250.0,
    minPrice: 250.0,
    freeUnits: 1,
  },
  {
    id: 6,
    chargeName: `Over Weight`,
    unitOfMeasure: "Per Mins",
    desc: "-",
    appPrice: "Yes",
    perUnit: 250.0,
    minPrice: 250.0,
    freeUnits: 1,
  },
  {
    id: 7,
    chargeName: `Hazmat`,
    unitOfMeasure: "Fixed",
    desc: "-",
    appPrice: "No",
    perUnit: 250.0,
    minPrice: 250.0,
    freeUnits: 1,
  },
  {
    id: 8,
    chargeName: `Fuel`,
    unitOfMeasure: "Percentage",
    desc: "Terminal",
    appPrice: "No",
    perUnit: 250.0,
    minPrice: 250.0,
    freeUnits: 1,
  },
  {
    id: 9,
    chargeName: `Fuel`,
    unitOfMeasure: "Percentage",
    desc: "Terminal",
    appPrice: "No",
    perUnit: 250.0,
    minPrice: 250.0,
    freeUnits: 1,
  },
  {
    id: 10,
    chargeName: `Fuel`,
    unitOfMeasure: "Percentage",
    desc: "Terminal",
    appPrice: "No",
    perUnit: 250.0,
    minPrice: 250.0,
    freeUnits: 1,
  }
];

const StaticTableContent = () => {
  const [isPopDrop, setIsPopDrop] = useState(false);

  const handlePopDrop = (event) => {
    setIsPopDrop(!isPopDrop);
  };
  return (
    <React.Fragment>
      {/* Filter Row */}
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="form-check form-check--gray pr-30">
            <input
              className="form-check-input"
              type="checkbox"
              id="Available"
            />
            <label className="form-check-label" for="Available">
              Enabled
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                47
              </span>
            </label>
          </div>
          <div className="form-check form-check--gray pr-30">
            <input className="form-check-input" type="checkbox" id="Pending" />
            <label className="form-check-label" for="Pending">
              Disabled
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                6
              </span>
            </label>
          </div>
        </div>
        <div className="ml-auto d-flex">
          <button className="btn btn-outline-light mr-2">
            <IconFilter className="mr-2" />
            Filter
          </button>
          <div className="position-relative">
            <button className="btn btn-outline-light position-relative">
              <IconColumn />
            </button>
          </div>
        </div>
      </div>
      {/* Table Content */}
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 248px)" }}
      >
        <table className="table table-card">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
              <th style={{ width: "2%" }}></th>
              <th style={{ width: "2%" }}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((datum) => {
              return (
                <tr key={datum.id}>
                  <td>{datum.chargeName}</td>
                  <td>{datum.unitOfMeasure}</td>
                  <td>{datum.desc}</td>
                  {datum.appPrice === "Yes" ? (
                    <td style={{ width: "6%" }} className="text-center">
                      <IconCheckCircle className="text-success mr-2" />
                      {datum.appPrice}
                    </td>
                  ) : (
                    <td style={{ width: "6%" }} className="text-center">
                      <IconCancelCircle className="text-gray-50 mr-2" />
                      {datum.appPrice}
                    </td>
                  )}
                  <td className="text-right">${datum.perUnit}</td>
                  <td className="text-right">${datum.minPrice}</td>
                  <td className="text-right">{datum.freeUnits}</td>
                  <td>
                    <button className="btn btn-link">
                      <IconPen className="text-primary" />
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-link">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
