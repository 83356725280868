import React, { useState } from 'react';
import { IconInvoice } from '../../../../common/icons/index';

const columns = [
  'Invoice',
  'Driver',
  'Customer',
  'Load Status',
  'Movement',
  'Container',
  'Reference #',
  'BOL/BKG',
  'Port',
  'Consignee',
  'Location',
  'Bill Date',
  'Invoice Amount ',
];

const data = [
  {
    id: 1,
    invoice: 'POP2_M100520',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    status: 'Pending',
    movement: 'Import',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    billDate: '06/11',
    invoiceAmt: '767.50',
  },
  {
    id: 2,
    invoice: 'POP2_M100520',
    status: 'Pending',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    movement: 'Import',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    billDate: '06/11',
    invoiceAmt: '767.50',
  },
  {
    id: 3,
    invoice: 'POP2_M100520',
    status: 'Pending',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    movement: 'Import',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    billDate: '06/11',
    invoiceAmt: '767.50',
  },
  {
    id: 4,
    invoice: 'POP2_M100520',
    status: 'Pending',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    movement: 'Import',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    billDate: '06/11',
    invoiceAmt: '767.50',
  },
  {
    id: 5,
    invoice: 'POP2_M100520',
    status: 'Pending',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    movement: 'Import',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    billDate: '06/11',
    invoiceAmt: '767.50',
  },
  {
    id: 6,
    invoice: 'POP2_M100520',
    status: 'Pending',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    movement: 'Import',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    billDate: '06/11',
    invoiceAmt: '767.50',
  },
  {
    id: 7,
    invoice: 'POP2_M100520',
    status: 'Pending',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    movement: 'Import',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    billDate: '06/11',
    invoiceAmt: '767.50',
  },
  {
    id: 8,
    invoice: 'POP2_M100520',
    status: 'Pending',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    movement: 'Import',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    billDate: '06/11',
    invoiceAmt: '767.50',
  },
  {
    id: 9,
    invoice: 'POP2_M100520',
    status: 'Pending',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    movement: 'Import',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    billDate: '06/11',
    invoiceAmt: '767.50',
  },
  {
    id: 10,
    invoice: 'POP2_M100520',
    status: 'Pending',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    movement: 'Import',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    billDate: '06/11',
    invoiceAmt: '767.50',
  },
  {
    id: 11,
    invoice: 'POP2_M100520',
    status: 'Pending',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    movement: 'Import',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    billDate: '06/11',
    invoiceAmt: '767.50',
  },
  {
    id: 12,
    invoice: 'POP2_M100520',
    status: 'Pending',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    movement: 'Import',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    billDate: '06/11',
    invoiceAmt: '767.50',
  },
  {
    id: 13,
    invoice: 'POP2_M100520',
    status: 'Pending',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    movement: 'Import',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    billDate: '06/11',
    invoiceAmt: '767.50',
  },
  {
    id: 14,
    invoice: 'POP2_M100520',
    status: 'Pending',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    movement: 'Import',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    billDate: '06/11',
    invoiceAmt: '767.50',
  },
];

const options = [
  { value: 'jessica.hanson@example.com', label: 'jessica.hanson@example.com' },
  { value: 'john.doe@example.com', label: 'john.doe@example.com' },
  { value: 'alex@example.com', label: 'alex@example.com' },
];

const StaticTableContent = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (event) => {
    setShowModal(true);
  };

  const handleHideModal = (event) => {
    setShowModal(false);
  };

  return (
    <div className="table-responsive" style={{ height: 'calc(100vh - 408px)' }}>
      <table className="table table-card table-card--ls">
        <thead>
          <tr>
            <th>
              <input
                className="form-check-input rounded-3 border-secondary"
                type="checkbox"
                id="sr_no"
              />
            </th>
            {columns.map((column) => {
              return (
                <th scope="col" key={column}>
                  {column}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((datum) => {
            return (
              <tr key={datum.id}>
                <td>
                  <input
                    className="form-check-input rounded-3 border-secondary"
                    type="checkbox"
                    id="sr_no"
                  />
                </td>
                <td>
                  <a
                    href="javascript:void(0)"
                    className="text-primary font-weight-500"
                  >
                    {datum.invoice}
                    <IconInvoice className="ml-2 text-muted" />
                  </a>
                </td>
                <td>
                  <div className="d-flex">
                    <div className="mr-2 align-self-center">
                      <img
                        src={datum.img}
                        className="avatar-xs rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <h5 className="mb-0 align-self-center">
                      <a href="#" className="text-dark">
                        {datum.driver}
                      </a>
                    </h5>
                  </div>
                </td>
                <td>{datum.customer}</td>
                <td>
                  <div className="">
                    {datum.status == 'Pending' && (
                      <span className="badge badge-sm badge-soft-warning  ">
                        {datum.status}
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className="">
                    <span className="badge badge-sm badge-gray-50 ">
                      {datum.movement}
                    </span>
                  </div>
                </td>
                <td>{datum.container}</td>
                <td>{datum.reference}</td>
                <td>{datum.bol}</td>
                <td>{datum.port}</td>
                <td>{datum.consignee}</td>
                <td>{datum.Location}</td>
                <td>{datum.billDate}</td>
                <td>${datum.invoiceAmt}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default StaticTableContent;
