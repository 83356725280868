const AnnualSwitch = () => {
    return (
        <div className="form-check form-switch form-switch-md justify-content-center mb-40">
            <input
                type="checkbox"
                className="form-check-input"
                id="annual"
                checked
            />
            <label for="annual" className="mb-0 ml-15">Anually</label>
        </div>
    )
}

export default AnnualSwitch;