import React, { useState } from "react";
import { IconAngleArrowDown, IconAngleArrowRight } from "../../../common/icons";

const CounterCards = (props) => {
  const {
    id = "",
    title = "",
    count = "",
    isCardActive = true,
    onCardClicked = () =>
      console.error("You passed no action to the component"),
  } = props;

  return (
    <React.Fragment>
      <div className="col-md my-3">
        <div
          className={`ncard d-flex flex-column justify-content-between p-10 ${
            isCardActive ? "active" : ""
          }`}
          onClick={onCardClicked}
        >
          <div className="d-flex align-items-center">
            <div className="mr-1 sbox sbox--default px-2 h-100">{count}</div>
            <div className="ml-2">{title}</div>
            <div className="ml-auto">
              {isCardActive === id ? (
                <IconAngleArrowDown />
              ) : (
                <IconAngleArrowRight />
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CounterCards;
