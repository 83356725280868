import React, { useState } from 'react';
import { IconDownload, IconPlus } from '../../../../common/icons';
import ContainerTabs from '../ContainerTabs';
import Pagination from '../../../../common/pagination';
import StaticTableContent from './elements/StaticTableContent';
import AddNewSize from './elements/AddNewSize';
import SettingsTab from '../../common-elements/SettingsTabs';

const ContainerSize = () => {
  const [showNewSizeModal, setShowNewSizeModal] = useState(false);

  const handleShowNewSizeModal = (event) => {
    setShowNewSizeModal(true);
  };

  const handleHideNewSizeModal = (event) => {
    setShowNewSizeModal(false);
  };
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <SettingsTab
            activeTab={5}
            button={
              <div className="d-flex">
                <button className="mr-2 btn btn-outline-light ">
                  <IconDownload className="mr-2" />
                  Downlaod CVS
                </button>
                <button className=" btn btn-success ">
                  <IconPlus className="mr-2" />
                  Add Customers
                </button>
              </div>
            }
          />
        </div>
      </div>
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-15 d-flex align-items-center justify-content-between">
            <ContainerTabs
              activeTab={'Size'}
              button={
                <button
                  className="ml-auto btn btn-success"
                  onClick={handleShowNewSizeModal}
                >
                  <IconPlus className="mr-2" />
                  Add New Size
                </button>
              }
            />
          </div>
          <div className="pb-15 d-flex align-items-center justify-content-between">
            <h4 className="font-20 mb-0">10 Container Size</h4>
            <Pagination />
          </div>
        </div>

        {/* Static table Content */}
        <StaticTableContent />
      </div>
      {/* Add New Pricing */}
      {showNewSizeModal && (
        <AddNewSize
          onCancel={handleHideNewSizeModal}
          onSubmit={handleHideNewSizeModal}
        />
      )}
    </div>
  );
};

export default ContainerSize;
