import React from 'react';
import { Icondots } from '../../../common/icons';

const DriverList = () => {
  return (
    <>
      <div className="card mb-30 rounded-5">
        <div className="d-flex pb-15 px-20 pt-20 justify-content-between align-items-center">
          <div className="d-flex flex-column">
            <div className="font-16 font-weight-500 mb-1">Top 10 Drivers</div>
            <div className=" mb-1 text-muted font-weight-500">
              Based on Revenue
            </div>
          </div>
          <div className="ml-auto">
            <Icondots className="text-muted" />
          </div>
        </div>
        <div className=" p-20">
          <div className="d-flex justify-content-between pb-20">
            <div className="d-flex">
              <div className="mr-10 align-self-center">
                <div className="avatar-circle ">
                  <div className="font-12">AB</div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <h5 className="mb-0 align-self-left font-12">
                  <a href="#" className="text-dark">
                    Bessie Cooper
                  </a>                
                </h5>
                <div className="text-muted mt-1 font-12">08:00 AM - 5 November</div>
              </div>
            </div>
            <div className="d-flex flex-column text-right">
              <div className="text-accent-500 text-accent-500">$4,250</div>
              <div className="text-gray-500">Earned</div>
            </div>
          </div>
          <div className="d-flex justify-content-between pb-20">
            <div className="d-flex">
              <div className="mr-10 align-self-center">
                <div className="avatar-circle ">
                  <div className="font-12">AB</div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <h5 className="mb-0 align-self-left font-12">
                  <a href="#" className="text-dark">
                    Bessie Cooper
                  </a>                
                </h5>
                <div className="text-muted mt-1 font-12">08:00 AM - 5 November</div>
              </div>
            </div>
            <div className="d-flex flex-column text-right">
              <div className="text-accent-500 text-accent-500">$4,250</div>
              <div className="text-gray-500">Earned</div>
            </div>
          </div>
          <div className="d-flex justify-content-between pb-20">
            <div className="d-flex">
              <div className="mr-10 align-self-center">
                <div className="avatar-circle ">
                  <div className="font-12">AB</div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <h5 className="mb-0 align-self-left font-12">
                  <a href="#" className="text-dark">
                    Bessie Cooper
                  </a>                
                </h5>
                <div className="text-muted mt-1 font-12">08:00 AM - 5 November</div>
              </div>
            </div>
            <div className="d-flex flex-column text-right">
              <div className="text-accent-500 text-accent-500">$4,250</div>
              <div className="text-gray-500">Earned</div>
            </div>
          </div>
          <div className="d-flex justify-content-between pb-20">
            <div className="d-flex">
              <div className="mr-10 align-self-center">
                <div className="avatar-circle ">
                  <div className="font-12">AB</div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <h5 className="mb-0 align-self-left font-12">
                  <a href="#" className="text-dark">
                    Bessie Cooper
                  </a>                
                </h5>
                <div className="text-muted mt-1 font-12">08:00 AM - 5 November</div>
              </div>
            </div>
            <div className="d-flex flex-column text-right">
              <div className="text-accent-500 text-accent-500">$4,250</div>
              <div className="text-gray-500">Earned</div>
            </div>
          </div>
          <div className="d-flex justify-content-between pb-20">
            <div className="d-flex">
              <div className="mr-10 align-self-center">
                <div className="avatar-circle ">
                  <div className="font-12">AB</div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <h5 className="mb-0 align-self-left font-12">
                  <a href="#" className="text-dark">
                    Bessie Cooper
                  </a>                
                </h5>
                <div className="text-muted mt-1 font-12">08:00 AM - 5 November</div>
              </div>
            </div>
            <div className="d-flex flex-column text-right">
              <div className="text-accent-500 text-accent-500">$4,250</div>
              <div className="text-gray-500">Earned</div>
            </div>
          </div>
          <div className="d-flex justify-content-between pb-20">
            <div className="d-flex">
              <div className="mr-10 align-self-center">
                <div className="avatar-circle ">
                  <div className="font-12">AB</div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <h5 className="mb-0 align-self-left font-12">
                  <a href="#" className="text-dark">
                    Bessie Cooper
                  </a>                
                </h5>
                <div className="text-muted mt-1 font-12">08:00 AM - 5 November</div>
              </div>
            </div>
            <div className="d-flex flex-column text-right">
              <div className="text-accent-500 text-accent-500">$4,250</div>
              <div className="text-gray-500">Earned</div>
            </div>
          </div>
          <div className="d-flex justify-content-between pb-20">
            <div className="d-flex">
              <div className="mr-10 align-self-center">
                <div className="avatar-circle ">
                  <div className="font-12">AB</div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <h5 className="mb-0 align-self-left font-12">
                  <a href="#" className="text-dark">
                    Bessie Cooper
                  </a>                
                </h5>
                <div className="text-muted mt-1 font-12">08:00 AM - 5 November</div>
              </div>
            </div>
            <div className="d-flex flex-column text-right">
              <div className="text-accent-500 text-accent-500">$4,250</div>
              <div className="text-gray-500">Earned</div>
            </div>
          </div>
          <div className="d-flex justify-content-between pb-20">
            <div className="d-flex">
              <div className="mr-10 align-self-center">
                <div className="avatar-circle ">
                  <div className="font-12">AB</div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <h5 className="mb-0 align-self-left font-12">
                  <a href="#" className="text-dark">
                    Bessie Cooper
                  </a>                
                </h5>
                <div className="text-muted mt-1 font-12">08:00 AM - 5 November</div>
              </div>
            </div>
            <div className="d-flex flex-column text-right">
              <div className="text-accent-500 text-accent-500">$4,250</div>
              <div className="text-gray-500">Earned</div>
            </div>
          </div>
          <div className="d-flex justify-content-between pb-20">
            <div className="d-flex">
              <div className="mr-10 align-self-center">
                <div className="avatar-circle ">
                  <div className="font-12">AB</div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <h5 className="mb-0 align-self-left font-12">
                  <a href="#" className="text-dark">
                    Bessie Cooper
                  </a>                
                </h5>
                <div className="text-muted mt-1 font-12">08:00 AM - 5 November</div>
              </div>
            </div>
            <div className="d-flex flex-column text-right">
              <div className="text-accent-500 text-accent-500">$4,250</div>
              <div className="text-gray-500">Earned</div>
            </div>
          </div>
          <div className="d-flex justify-content-between pb-20">
            <div className="d-flex">
              <div className="mr-10 align-self-center">
                <div className="avatar-circle ">
                  <div className="font-12">AB</div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <h5 className="mb-0 align-self-left font-12">
                  <a href="#" className="text-dark">
                    Bessie Cooper
                  </a>                
                </h5>
                <div className="text-muted mt-1 font-12">08:00 AM - 5 November</div>
              </div>
            </div>
            <div className="d-flex flex-column text-right">
              <div className="text-accent-500 text-accent-500">$4,250</div>
              <div className="text-gray-500">Earned</div>
            </div>
          </div>
          <div className="d-flex justify-content-between pb-20">
            <div className="d-flex">
              <div className="mr-10 align-self-center">
                <div className="avatar-circle ">
                  <div className="font-12">AB</div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <h5 className="mb-0 align-self-left font-12">
                  <a href="#" className="text-dark">
                    Bessie Cooper
                  </a>                
                </h5>
                <div className="text-muted mt-1 font-12">08:00 AM - 5 November</div>
              </div>
            </div>
            <div className="d-flex flex-column text-right">
              <div className="text-accent-500 text-accent-500">$4,250</div>
              <div className="text-gray-500">Earned</div>
            </div>
          </div>
          <div className="d-flex justify-content-between pb-20">
            <div className="d-flex">
              <div className="mr-10 align-self-center">
                <div className="avatar-circle ">
                  <div className="font-12">AB</div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <h5 className="mb-0 align-self-left font-12">
                  <a href="#" className="text-dark">
                    Bessie Cooper
                  </a>                
                </h5>
                <div className="text-muted mt-1 font-12">08:00 AM - 5 November</div>
              </div>
            </div>
            <div className="d-flex flex-column text-right">
              <div className="text-accent-500 text-accent-500">$4,250</div>
              <div className="text-gray-500">Earned</div>
            </div>
          </div>
          <div className="d-flex justify-content-between mb-10">
            <div className="d-flex">
              <div className="mr-10 align-self-center">
                <div className="avatar-circle ">
                  <div className="font-12">AB</div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <h5 className="mb-0 align-self-left font-12">
                  <a href="#" className="text-dark">
                    Bessie Cooper
                  </a>                
                </h5>
                <div className="text-muted mt-1 font-12">08:00 AM - 5 November</div>
              </div>
            </div>
            <div className="d-flex flex-column text-right">
              <div className="text-accent-500 text-accent-500">$4,250</div>
              <div className="text-gray-500">Earned</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DriverList;
