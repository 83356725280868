import React, { useState } from "react";
import Chatpop from "../chatpop";

const messagelist = [
  {
    id: 1,
    img: "",
    name: "Darlene Robertson",
    category: "customer",
    company: "Company name",
    message: "ok, see you later",
    sent: "10:10 am",
    isOnline: false,
  },
  {
    id: 2,
    img: "https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png",
    name: "Ralph Edwards",
    category: "customer",
    company: "Company name",
    message: "How are you?",
    sent: "9:45 am",
    isOnline: false,
  },
];

const CustomerMsgList = (props) => {
  const { messageClicked = () => {} } = props;

  return (
    <React.Fragment>
      <div className="clist" style={{ height: "calc(100vh - 172px)" }}>
        {messagelist.map((message) => (
          <div
            className="clist__card clist__card--outline mb-1"
            onClick={messageClicked}
            key={message.id}
          >
            <div className="d-flex align-items-start p-10">
              <div className="flex-shrink-0 mr-10 mt-1">
                {message.img == "" ? (
                  <div class="avatar-circle avatar bg-light">
                    <div class="avatar-circle__initial ">
                      <span className="text-dark">DR</span>
                    </div>
                  </div>
                ) : (
                  <img
                    src={message.img}
                    className="avatar rounded-circle"
                    width="30"
                    alt=""
                  />
                )}
              </div>
              <div className="flex-grow-1">
                <div className="d-flex align-items-center mb-1">
                  <a href="#" className="font-14 font-weight-500 text-dark">
                    {message.name}
                  </a>
                  {message.isOnline == true && (
                    <span className="show-online bg-primary d-inline-block ml-2"></span>
                  )}
                </div>
                {message.category === "customer" && (
                  <div className="badge badge-gray-300 mb-2">
                    {message.company}
                  </div>
                )}
                <p className="text-muted mb-0">{message.message}</p>
              </div>
              <div class="flex-shrink-0">
                <span className="text-muted">{message.sent}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default CustomerMsgList;
