import React from 'react';

const Message = () => {
  return (
    <div className="dlist" style={{ height: 'calc(100% - 111px)' }}>
      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Darrell Steward
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>active 2 hrs ago</span>
            </p>
          </div>
        </div>
      </div>

      <div className="clist__card mb-1 bg-secondary ">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
              className="avatar rounded-circle  border-white"
              style={{ border: '2px solid' }}
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-white ">
                Wade Warren
              </a>
            </h5>
            <p className="text-white mb-0">
              <span>active 2 hrs ago</span>
            </p>
          </div>
        </div>
      </div>
      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Esther Horward
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>26 Aug 2021, 10:10 am</span>
            </p>
          </div>
        </div>
      </div>
      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Arlene McCoy
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>26 Aug 2021, 10:10 am</span>
            </p>
          </div>
        </div>
      </div>

      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Robert Fox
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>26 Aug 2021, 10:10 am</span>
            </p>
          </div>
        </div>
      </div>

      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Jenny Wilson
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>26 Aug 2021, 10:10 am</span>
            </p>
          </div>
        </div>
      </div>

      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Albert Flores
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>August 2, 2013</span>
            </p>
          </div>
        </div>
      </div>

      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Marvin McKinney
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>February 29, 2012</span>
            </p>
          </div>
        </div>
      </div>
      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Marvin McKinney
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>February 29, 2012</span>
            </p>
          </div>
        </div>
      </div>
      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Marvin McKinney
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>February 29, 2012</span>
            </p>
          </div>
        </div>
      </div>
      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Marvin McKinney
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>February 29, 2012</span>
            </p>
          </div>
        </div>
      </div>
      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Marvin McKinney
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>February 29, 2012</span>
            </p>
          </div>
        </div>
      </div>
      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Marvin McKinney
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>February 29, 2012</span>
            </p>
          </div>
        </div>
      </div>
      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Marvin McKinney
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>February 29, 2012</span>
            </p>
          </div>
        </div>
      </div>
      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Marvin McKinney
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>February 29, 2012</span>
            </p>
          </div>
        </div>
      </div>
      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Marvin McKinney
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>February 29, 2012</span>
            </p>
          </div>
        </div>
      </div>
      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Marvin McKinney
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>February 29, 2012</span>
            </p>
          </div>
        </div>
      </div>
      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Marvin McKinney
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>February 29, 2012</span>
            </p>
          </div>
        </div>
      </div>
      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Marvin McKinney
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>February 29, 2012</span>
            </p>
          </div>
        </div>
      </div>
      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Marvin McKinney
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>February 29, 2012</span>
            </p>
          </div>
        </div>
      </div>
      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Marvin McKinney
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>February 29, 2012</span>
            </p>
          </div>
        </div>
      </div>
      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Marvin McKinney
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>February 29, 2012</span>
            </p>
          </div>
        </div>
      </div>
      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Marvin McKinney
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>February 29, 2012</span>
            </p>
          </div>
        </div>
      </div>
      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Marvin McKinney
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>February 29, 2012</span>
            </p>
          </div>
        </div>
      </div>

      <div className="clist__card mb-1">
        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
          <div className="flex-shrink-0 mr-2 align-self-center">
            <img
              src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
              className="avatar rounded-circle"
              width="30"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-14 mb-1">
              <a href="#" className="text-dark">
                Eleanor Pena
              </a>
            </h5>
            <p className="text-muted mb-0">
              <span>May 12, 2019</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
