import React, { useState } from 'react';
import CustomSelect from '../../../common/CustomSelect';
import { IconDownload, IconPlus } from '../../../common/icons';
import Pagination from '../../../common/pagination/index';
import SubHeader from '../common-elements/SubHeader';
import SettingsTab from '../common-elements/SettingsTabs';

const options = [
  { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
  { value: 'John Doe', label: 'John Doe' },
  { value: 'Alex Mendes', label: 'Alex Mendes' },
];

const NotificationSettings = () => {
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <SettingsTab
            activeTab={1}
            button={
              <div className="d-flex">
                <button className="mr-2 btn btn-outline-light ">
                  <IconDownload className="mr-2" />
                  Downlaod CVS
                </button>
                <button className=" btn btn-success ">
                  <IconPlus className="mr-2" />
                  Add Customers
                </button>
              </div>
            }
          />
        </div>
      </div>
      <div className="page-fluid">
        <div className="card px-3 py-4">
          <h5 className="mb-3">Profile Settings</h5>
          <div className="form-check mb-10">
            <input
              className="form-check-input"
              type="checkbox"
              id="Available"
            />
            <label className="form-check-label" for="Available">
              You want to see status changes notifications?
            </label>
          </div>
          <div className="form-check mb-10">
            <input
              className="form-check-input"
              type="checkbox"
              id="Available"
            />
            <label className="form-check-label" for="Available">
              You want to see ready for pickup notifications?
            </label>
          </div>
          <div className="form-check mb-10">
            <input
              className="form-check-input"
              type="checkbox"
              id="Available"
            />
            <label className="form-check-label" for="Available">
              Do you use QuickBooks Online?
            </label>
          </div>
          <div className="form-check mb-10">
            <input
              className="form-check-input"
              type="checkbox"
              id="Available"
            />
            <label className="form-check-label" for="Available">
              Do you want driver preferences applied?
            </label>
          </div>
          <div className="form-check mb-10">
            <input
              className="form-check-input"
              type="checkbox"
              id="Available"
            />
            <label className="form-check-label" for="Available">
              Extra Data from Tracer
            </label>
          </div>
          <div className="form-check mb-10">
            <input
              className="form-check-input"
              type="checkbox"
              id="Available"
            />
            <label className="form-check-label" for="Available">
              Chassis Tracking Enabled
            </label>
          </div>
          <div className="form-group mt-10 mb-0">
            <label className="mb-10 font-12">Double Dispatcher</label>
            <CustomSelect
              name={'name'}
              options={options}
              value={1}
              isMulti={true}
              isClearable={true}
              size="small"
              placement="right"
              placeholder="Select customer"
              className="w-200"
            />
          </div>
        </div>
        <div className="card mt-2 px-3 py-4">
          <h5 className="mb-3">Grouped Customers</h5>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label className="mb-10 font-12">Double Dispatcher</label>
              <input
                type="text"
                placeholder="Group name"
                className="form-control"
              />
            </div>
            <div className="form-group col-md-9">
              <label className="mb-10 font-12">Select Customers</label>
              <input
                type="text"
                placeholder="Enter City"
                className="form-control"
              />
              <small className="text-muted mt-2">Multiselection</small>
            </div>
            <div className="form-group col-md-12">
              <button className="btn btn-sm btn-outline-primary">
                <IconPlus className="mr-2" />
                Add New Group
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
