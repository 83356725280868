import React from 'react';
import Chart from 'react-apexcharts';
import { Icondots } from '../../../common/icons';

const Chartoptions = {
  chart: {
    height: 350,
    type: 'line',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  colors: ['#367BF6'],

  markers: {
    fillColor: '#ddd',
    strokeColor: '#fff',
    size: 5,
    shape: 'circle',
  },

  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
  },
  xaxis: {
    tooltip: {
      enabled: false,
    },
    categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],

    labels: {
      show: true,

      style: {
        colors: '#A3A3A3',
        fontSize: '10px',
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    axisBorder: {
      show: true,
      offsetX: 0,
    },
    labels: {
      formatter: function (val) {
        return val + 'K';
      },
      show: true,
      style: {
        colors: '#A3A3A3',
        fontSize: '10px',
      },
    },
    axisTicks: {
      show: false,
    },
  },

  tooltip: {
    enabled: true,
    enabledOnSeries: undefined,
    shared: false,
    followCursor: false,
    intersect: true,
    inverseOrder: false,
    theme: 'dark',
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      return (
        '<div class="arrow_box">' +
        '<span class="text-white">' +
        'Revenue' +
        ': ' +
        series[seriesIndex][dataPointIndex] +
        '</span>' +
        '<span class="badge badge-sm badge-accent-100 ml-1 ">' +
        '5.8%' +
        ' ' +
        '</span>' +
        '<div class="mt-1 text-gray-300">' +
        '21 November, 2021' +
        '</div>' +
        '</div>'
      );
    },
    style: {
      fontSize: '12px',
      fontFamily: 'Inter',
    },
    onDatasetHover: {
      highlightDataSeries: false,
    },
    x: {
      show: true,
      // format: 'dd MMM',
      // formatter: undefined,
    },
    y: {
      show: true,
      // formatter: undefined,
      // title: {
      //   formatter: (seriesName) => seriesName,
      // },
    },

    marker: {
      show: false,
    },
    items: {
      display: 'flex',
    },
    fixed: {
      enabled: false,
      position: 'topRight',
      offsetX: 0,
      offsetY: 0,
    },
  },
};
const Chartseries = [
  {
    name: 'series1',
    data: [20, 40, 80, 50, 60, 50, 80],
  },
];

const ColorChartoptions = {
  chart: {
    height: 350,
    type: 'line',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  colors: ['#fff'],

  markers: {
    fillColor: '#ddd',
    strokeColor: '#fff',
    size: 5,
    shape: 'circle',
  },

  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
  },
  xaxis: {
    tooltip: {
      enabled: false,
    },
    categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],

    labels: {
      show: true,

      style: {
        colors: '#FFFFFF',
        fontSize: '12px',
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    axisBorder: {
      show: true,
      offsetX: 0,
    },
    labels: {
      formatter: function (val) {
        return val + 'K';
      },
      show: true,
      style: {
        colors: '#FFFFFF',
        fontSize: '12px',
      },
    },
    axisTicks: {
      show: false,
    },
  },

  tooltip: {
    enabled: true,
    enabledOnSeries: undefined,
    shared: false,
    followCursor: false,
    intersect: true,
    inverseOrder: false,
    theme: 'dark',
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      return (
        '<div class="arrow_box">' +
        '<span class="text-white">' +
        'Revenue' +
        ': ' +
        series[seriesIndex][dataPointIndex] +
        '</span>' +
        '<span class="badge badge-sm badge-accent-100 ml-1 ">' +
        '5.8%' +
        ' ' +
        '</span>' +
        '<div class="mt-1 text-gray-300">' +
        '21 November, 2021' +
        '</div>' +
        '</div>'
      );
    },
    style: {
      fontSize: '12px',
      fontFamily: 'Inter',
    },
    onDatasetHover: {
      highlightDataSeries: false,
    },
    x: {
      show: true,
      // format: 'dd MMM',
      // formatter: undefined,
    },
    y: {
      show: true,
      // formatter: undefined,
      // title: {
      //   formatter: (seriesName) => seriesName,
      // },
    },

    marker: {
      show: false,
    },
    items: {
      display: 'flex',
    },
    fixed: {
      enabled: false,
      position: 'topRight',
      offsetX: 0,
      offsetY: 0,
    },
  },
};
const ColorChartseries = [
  {
    name: 'series1',
    data: [20, 40, 80, 50, 60, 50, 80],
  },
];

const AreaChart = () => {
  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <div className="card mb-20">
            <div className="px-20 pt-20 pb-15 d-flex justify-content-between align-items-center ">
              <div className="d-flex flex-column">
                <div className="font-16 font-weight-500">Double Moves</div>
                <div className="font-30 mt-10 mb-10 font-weight-500">$8,500</div>
                <div className=" d-flex flex-row font-12 font-weight-normal">
                  <div className="mr-1 text-muted">Past Week:</div>
                  <div className="text-accent-500 mr-1 text-accent-500">$4,250</div>
                  <div className=" badge badge-sm badge-accent-100 px-1 rounded-10">
                    +5.8%
                  </div>
                </div>
              </div>
              <div className="ml-auto">
                <Icondots className="text-muted" />
              </div>
            </div>
            <div className="p-20 pt-0">
              <div id="chart">
                <Chart
                  options={Chartoptions}
                  series={Chartseries}
                  type="area"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card mb-20 bg-primary">
            <div className="px-20 pt-20 pb-15 d-flex justify-content-between align-items-center ">
              <div className="d-flex flex-column">
                <div className="font-16 text-white font-weight-500">
                  Past Due Receivable
                </div>
                <div className="font-30 my-15 text-white font-weight-500">
                  $2,000,000
                  <span className="font-14 font-weight-500">.00</span>
                </div>
                <div className=" d-flex flex-row font-12 font-weight-normal">
                  <div className="mr-1 text-brand-200">Past Week:</div>
                  <div className="font-12 text-white mr-1">$4,250</div>
                  <div className=" badge badge-sm badge-accent-100 px-1 rounded-10">
                    +5.8%
                  </div>
                </div>
              </div>
              <div className="ml-auto">
                <Icondots className="text-muted" />
              </div>
            </div>
            <div className="p-20 pt-0">
              <div id="chart">
                <Chart
                  options={ColorChartoptions}
                  series={ColorChartseries}
                  type="area"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AreaChart;
