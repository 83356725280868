import React, { Component } from "react";
import Chatpop from "../../../common/chatpop";

class TrackChat extends Component {
  render() {
    return (
      <React.Fragment>
        <Chatpop chatpopSizeClass="chatpop--sm"/>
      </React.Fragment>
    );
  }
}

export default TrackChat;
