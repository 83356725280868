import React, { useState } from "react";
import {
  IconColumn,
  IconFilter,
  IconPen,
  IconTrash,
  IconPlus,
  IconCalendar,
} from "../../../common/icons";
import Pagination from "../../../common/pagination";
import EquipmentTab from "../EquipmentTabs";
import ChassisLoadPage from "./chassisLoadPage";
import CustomSelect from "../../../common/CustomSelect";
import { Modal } from "react-bootstrap";
import ChassisLoadSubHeader from "./chassisLoadSubHeader";
import UploadDocument from "../../../common/uploadDocument";
const cardItems = [
  {
    id: 1,
    count: 540,
    title: "Available",
  },
  {
    id: 2,
    count: 703,
    title: "Dispatched",
  },
  {
    id: 3,
    count: 740,
    title: "Loaded",
  },
  {
    id: 4,
    count: 994,
    title: "Empty",
  },
];

const tableHeaderData = [
  "Chassis#",
  "Load #",
  "Address",
  "Chassis Owner",
  "Chassis Size",
  "Chassis Type",
  "License",
  "VIN",
  "Registration",
  "Inspection",
  "Insurance",
];
const tableData = [];
for (let i = 0; i < 20; i++) {
  tableData.push({
    id: i,
    chasis: `ABCD1234565` + i,
    load: "POP2_M10052",
    address: "2715 Ash Dr. San Jose, South Dakota 83475",
    chassisOwner: "DB Trucks",
    chassisSize: "40'",
    chassisType: "Tri - Axle",
    lisence: "NJ452891",
    lisenceCode: "New Jersey",
    vinNumber: "12478723471471478",
    registration: "06/11",
    inspectionDate: "06/11/21",
    inspectionTime: "8:50PM",
    insurance: "06/11/21",
  });
}

const Chassis = () => {
  const options = [
    { value: "Cameron Williamson", label: "Cameron Williamson " },
    { value: "John Doe", label: "John Doe" },
    { value: "Alex Mendes", label: "Alex Mendes" },
  ];
  const [isCardActive, setCardActive] = useState(1);

  const [ChassisLoad, setChassisLoad] = useState(false);
  const handleShowChassisLoad = (event) => {
    setChassisLoad(true);
  };
  const handleHideChassisLoad = (event) => {
    setChassisLoad(false);
  };

  const [showAddModal, setShowAddModal] = useState(false);
  const handleShowAddModal = (event) => {
    setShowAddModal(true);
  };
  const handleHideAddModal = (event) => {
    setShowAddModal(false);
  };

  return (
    <div className="position-relative subheader-wrapper">
      {!ChassisLoad ? (
        <React.Fragment>
          <div className="subheader">
            <div className="subheader__inner d-flex align-items-center py-0">
              <EquipmentTab
                activeTab={2}
                button={
                  <div className="d-flex">
                    <button
                      className="ml-auto btn btn-success"
                      onClick={handleShowAddModal}
                    >
                      <IconPlus className="mr-2" />
                      Add New Chassis
                    </button>
                  </div>
                }
              />
            </div>
          </div>
          <div className="page-fluid">
            <div className="top-page">
              <div className="pb-15 d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <h4 className="font-20 mb-0">Chassis</h4>
                </div>
                {/* Pagination  */}
                <Pagination className="ml-auto" />
              </div>
              {/* Cards Row */}
              <div className={`form-row ${isCardActive ? "hasactive" : ""}`}>
                {cardItems.map((cardItem) => (
                  <div className="col-md my-2">
                    <div
                      className={`ncard d-flex flex-column justify-content-between p-10 ${
                        isCardActive === cardItem.id ? "active" : ""
                      }`}
                      onClick={() => setCardActive(cardItem.id)}
                    >
                      <div className="d-flex align-items-center">
                        <div className="mr-1 sbox sbox--default px-2 h-100">
                          {cardItem.count}
                        </div>
                        <div>{cardItem.title}</div>
                        <div className="ml-auto">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={
                                isCardActive === cardItem.id ? "true " : null
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Filter Row */}
              <div className="filter-row d-flex align-items-center">
                <div className="app-search header-search">
                  <span className="search-icon"> </span>
                  <div className="position-relative">
                    <input
                      type="search"
                      className="form-control input-search-left"
                      placeholder="Search..."
                      value=""
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check--gray pr-30">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="Available"
                    />
                    <label className="form-check-label" for="Available">
                      Enabled
                      <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                        47
                      </span>
                    </label>
                  </div>
                  <div className="form-check form-check--gray pr-30">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="Pending"
                    />
                    <label className="form-check-label" for="Pending">
                      Disabled
                      <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                        6
                      </span>
                    </label>
                  </div>
                </div>
                <div className="ml-auto d-flex">
                  <div className="position-relative">
                    <button className="btn btn-outline-light position-relative">
                      <IconColumn />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Table */}
            <div
              className="table-responsive"
              style={{ height: "calc(100vh - 325px)" }}
            >
              <table className="table table-card table-card--ls">
                <thead>
                  <tr>
                    <th>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="sr_no"
                      />
                    </th>
                    {tableHeaderData.map((headetItem) => {
                      return (
                        <th scope="col" key={headetItem}>
                          {headetItem}
                        </th>
                      );
                    })}
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((tdata) => {
                    return (
                      <tr key={tdata.id}>
                        <td>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="sr_no"
                          />
                        </td>
                        <td>
                          <a
                            href="#"
                            className="text-primary"
                            onClick={handleShowChassisLoad}
                          >
                            {tdata.chasis}
                          </a>
                        </td>
                        <td className="text-primary">{tdata.load}</td>
                        <td>{tdata.address}</td>
                        <td>{tdata.chassisOwner}</td>
                        <td>{tdata.chassisSize}</td>
                        <td>{tdata.chassisType}</td>
                        <td>
                          <div>{tdata.lisence}</div>
                          <div className="text-muted">{tdata.lisenceCode}</div>
                        </td>
                        <td>
                          <div>{tdata.vinNumber}</div>
                        </td>
                        <td>{tdata.registration}</td>
                        <td>{tdata.inspectionDate}</td>
                        <td>{tdata.insurance}</td>
                        <td className="text-center">
                          <button className="btn btn-link">
                            <IconPen />
                          </button>
                        </td>
                        <td className="text-center">
                          <button className="btn btn-link">
                            <IconTrash />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <Modal show={showAddModal} centered animation={false} size="xl">
            <Modal.Header>
              <Modal.Title>Add New Chassis</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-wrapper">
                <form>
                  <div className="row mb-10">
                    <div className="form-group col-md-4">
                      <label className="col-form-label">
                        <span className="text-danger">*</span> Chassis #
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Chassis Number.."
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label className="col-form-label">
                        <span className="text-danger">*</span> Year
                      </label>
                      <CustomSelect
                        name={"name"}
                        options={options}
                        value={1}
                        isMulti={true}
                        isClearable={true}
                        size="small"
                        placement="right"
                        placeholder="Select Year"
                        className="w-150"
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label className="col-form-label">
                        <span className="text-danger">*</span> Registration Date
                      </label>
                      <div className="input-wrapper w-150">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Select Date"
                        />
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      <label className="col-form-label">
                        <span className="text-danger">*</span> Chassis Owner
                      </label>
                      <div className="input-wrapper">
                        <CustomSelect
                          name={"name"}
                          options={options}
                          value={1}
                          isMulti={true}
                          isClearable={true}
                          size="small"
                          placement="right"
                          placeholder="Select Owner"
                          className="w-100"
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      <label className="col-form-label">
                        <span className="text-danger">*</span> AID
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter AID"
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label className="col-form-label">
                        <span className="text-danger">*</span> ITD
                      </label>
                      <div className="input-wrapper">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter ITD"
                        />
                      </div>
                    </div>

                    <div className="form-group col-md-4">
                      <label className="col-form-label">
                        <span className="text-danger">*</span> Chassis Type
                      </label>
                      <CustomSelect
                        name={"name"}
                        options={options}
                        value={1}
                        isMulti={true}
                        isClearable={true}
                        size="small"
                        placement="right"
                        placeholder="Select Type"
                        className="w-100"
                      />
                    </div>

                    <div className="form-group col-md-4">
                      <label className="col-form-label">
                        <span className="text-danger">*</span> License Plate #
                      </label>
                      <div className="input-wrapper">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter License Plate #"
                        />
                      </div>
                    </div>

                    <div className="form-group col-md-4">
                      <label className="col-form-label">
                        <span className="text-danger">*</span> VIN
                      </label>
                      <div className="input-wrapper">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter VIN"
                        />
                      </div>
                    </div>

                    <div className="form-group col-md-4">
                      <label className="col-form-label">
                        <span className="text-danger">*</span> Chassis Size
                      </label>
                      <CustomSelect
                        name={"name"}
                        options={options}
                        value={1}
                        isMulti={true}
                        isClearable={true}
                        size="small"
                        placement="right"
                        placeholder="Select Size"
                        className="w-100"
                      />
                    </div>

                    <div className="form-group col-md-4">
                      <label className="col-form-label">
                        <span className="text-danger">*</span> License State
                      </label>
                      <div className="input-wrapper">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter VIN"
                        />
                      </div>
                    </div>

                    <div className="form-group col-md-4">
                      <label className="col-form-label">
                        <span className="text-danger">*</span> Insurance
                      </label>
                      <CustomSelect
                        name={"name"}
                        options={options}
                        value={1}
                        isMulti={true}
                        isClearable={true}
                        size="small"
                        placement="right"
                        placeholder="Select Insurance"
                        className="w-150"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label className="col-form-label">
                        <span className="text-danger">*</span> Model
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Model"
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label className="col-form-label">
                        <span className="text-danger">*</span> Inspection Date
                      </label>
                      <CustomSelect
                        name={"name"}
                        options={options}
                        value={1}
                        isMulti={true}
                        isClearable={true}
                        size="small"
                        placement="right"
                        placeholder="Select Date"
                        className="w-150"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-4">
                      <label className="col-form-label">
                        <span className="text-danger">*</span> GPS #
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter GPS"
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label className="col-form-label">
                        <span className="text-danger">*</span> Preventative
                        Maintenance
                      </label>
                      <CustomSelect
                        name={"name"}
                        options={options}
                        value={1}
                        isMulti={true}
                        isClearable={true}
                        size="small"
                        placement="right"
                        placeholder="Select Date"
                        className="w-150"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Upload Document</label>
                    <UploadDocument />
                  </div>
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-close" onClick={handleHideAddModal}>
                Cancel
              </button>
              <button className="btn btn-success" onClick={handleHideAddModal}>
                Add New Chassis
              </button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ChassisLoadSubHeader closeChassisLoad={handleHideChassisLoad} />
          <ChassisLoadPage />
        </React.Fragment>
      )}
    </div>
  );
};

export default Chassis;
