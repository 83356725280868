const Images = {
  org_logo1:
    require('../../../assets/assets/images/organizationLogo/org-logo-1.png')
      .default,
  org_logo2:
    require('../../../assets/assets/images/organizationLogo/org-log-2.png')
      .default,
  org_logo3:
    require('../../../assets/assets/images/organizationLogo/org-logo-3.png')
      .default,
  org_logo4:
    require('../../../assets/assets/images/organizationLogo/org-logo-4.png')
      .default,
  org_logo5:
    require('../../../assets/assets/images/organizationLogo/org-logo-5.png')
      .default,
  org_logo6:
    require('../../../assets/assets/images/organizationLogo/org-logo-6.png')
      .default,
  org_logo7:
    require('../../../assets/assets/images/organizationLogo/org-logo-7.png')
      .default,
  org_logo8:
    require('../../../assets/assets/images/organizationLogo/org-logo-8.png')
      .default,
  org_logo9:
    require('../../../assets/assets/images/organizationLogo/org-logo-9.png')
      .default,
  org_logo10:
    require('../../../assets/assets/images/organizationLogo/org-logo-10.png')
      .default,
};

export default Images;
