import React, { useState } from "react";

const Notes = (props) => {
  const [isNoteActive, setIsNoteActive] = useState(false);
  const handleNoteActive = (i) => {
    setIsNoteActive(isNoteActive === i ? null : i);
  };

  return (
    <div className="tab p-20">
      <div
        className={`card ${isNoteActive === 1 ? "card--shadow-5" : ""}`}
        onClick={() => handleNoteActive(1)}
      >
        <div className={isNoteActive === 1 ? "px-30 py-20" : null }>
          <div className="d-flex align-items-center mb-20">
            <h5 className="font-16 mb-0">Notes</h5>
          </div>
          <div className="form-group">
            <textarea
              rows="8"
              className="form-control"
              placeholder="Enter notes"
            ></textarea>
          </div>
        </div>
        {isNoteActive === 1 && (
          <div className="card-footer">
            <button className="btn btn-close">Cancel</button>
            <button className="btn btn-primary">Add Note</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notes;
