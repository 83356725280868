import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomSelect from "../../../../common/CustomSelect";
import CustomCreatableSelect from "../../../../common/CustomSelect/creatable";
import { IconClock } from "../../../../common/icons";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const AddNewReport = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;
  const [checked, setChecked] = useState(false);
  const [checkedSpecificDate, setCheckedSpecificDate] = useState(false);

  const handleSendEmail = () => {
    setChecked(!checked);
  };

  const handleSpecificDate = () => {
    setCheckedSpecificDate(!checkedSpecificDate);
  };

  return (
    <Modal show={showModal} centered animation={false} size="sm">
      <Modal.Header>
        <Modal.Title>Add New Report</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="form-wrapper">
          <form>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  {" "}
                  <span className="text-danger">*</span>Charge name
                </label>
                <input
                  placeholder="Enter Report Name"
                  className="form-control"
                />
              </div>
            </div>
            <hr className="mt-0" />
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Type
                </label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select report type"
                  className="w-100"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Fields
                </label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select fields"
                  className="w-100"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Duration
                </label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select duration"
                  className="w-100"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Customers
                </label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select"
                  className="w-100"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 form-group">
                <div className="form-check pl-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="sendEmail"
                    onClick={handleSendEmail}
                    checked={checked}
                  />
                  <label className="form-check-label" for="">
                    Send Email
                  </label>
                </div>
              </div>
            </div>

            {/* Send Email */}
            {checked && (
              <>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="col-form-label">
                      <span className="text-danger">*</span> Email
                    </label>
                    <CustomCreatableSelect
                      name={"name"}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder="Enter email address.."
                      className="w-100"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Select Time"
                    />
                    <span className="input-icon">
                      <IconClock />
                    </span>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-check w-100 mb-10">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="daily"
                    />
                    <label className="form-check-label" for="daily">
                      Daily
                    </label>
                  </div>
                  <div className="form-check w-100 mb-10">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="weekly"
                    />
                    <label className="form-check-label" for="weekly">
                      Weekly
                    </label>
                  </div>
                  <div className="form-check w-100 mb-10">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="every2weeks"
                    />
                    <label className="form-check-label" for="every2weeks">
                      Every 2 weeks
                    </label>
                  </div>
                  <div className="form-check w-100 mb-10">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="monthly"
                    />
                    <label className="form-check-label" for="monthly">
                      Monthly
                    </label>
                  </div>
                  <div className="form-check w-100 mb-10">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="daily"
                      onClick={handleSpecificDate}
                      checked={checkedSpecificDate}
                    />
                    <label className="form-check-label" for="Daily">
                      Specific Date of each month
                    </label>
                  </div>
                </div>

                {checkedSpecificDate && (
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <CustomSelect
                        name={"name"}
                        options={options}
                        value={1}
                        isMulti={true}
                        isClearable={true}
                        size="small"
                        placement="right"
                        placeholder="Select Day"
                        className="w-100 ml-3"
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-success" onClick={onSubmit}>
          Add Report
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewReport;
