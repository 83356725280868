import React, { Component, KeyboardEventHandler } from 'react';

import CreatableSelect from 'react-select/creatable';
import { ActionMeta, OnChangeValue } from 'react-select';

const components = {
    DropdownIndicator: null,
};
const smallSelectStyle = {
    control: (base, state) => ({
        ...base,
        minHeight: 30,
        borderColor: '#BFCCDA',
        borderRadius: '3px',
    }),
    menu: (base) => ({
        ...base,
        innerHeight: 30
    }),
    menuList: (base) => ({
        ...base,
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: 30,
        color: '#394E66'
    }),
    indicatorSeparator: (base) => ({
        ...base,
        margin: "0",
        backgroundColor: '#BFCCDA',
    }),
    multiValue: (base) => ({
        ...base,
        backgroundColor: '#687D96',
        color: '#ffffff',
    }),
    multiValueLabel: (base) => ({
        ...base,
        color: '#ffffff',
    })
};
const mediumSelectStyle = {
    control: (base, state) => ({
        ...base,
        minHeight: 40,
        borderColor: '#BFCCDA',
        borderRadius: '3px',
    }),
    menu: (base) => ({
        ...base,
        innerHeight: 40
    }),
    menuList: (base) => ({
        ...base,
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: 40,
        color: '#394E66'
    }),
    indicatorSeparator: (base) => ({
        ...base,
        margin: "0",
        backgroundColor: '#BFCCDA',
    }),
    multiValue: (base) => ({
        ...base,
        backgroundColor: '#687D96',
        color: '#ffffff',
    }),
    multiValueLabel: (base) => ({
        ...base,
        color: '#ffffff',
    })
};
// React Select Input
const CustomCreatableSelect = (props) => {
    const {
        name,
        value,
        options,
        isMulti,
        isClearable,
        size,
        placement,
        placeholder,
        className
    } = props;

    return (
        <CreatableSelect
            components={components}
            className={className}
            value={value}
            styles={size === 'small' ? smallSelectStyle : size === 'medium' ? mediumSelectStyle : mediumSelectStyle}
            isMulti={isMulti}
            isClearable={isClearable}
            menuPlacement={placement === 'top' ? 'top' : placement === 'bottom' ? 'bottom' : 'auto'}
            placeholder={placeholder}

        />
    );
};

export default CustomCreatableSelect;