import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import {
  IconColumn,
  IconFilter,
  IconPen,
  IconTrash,
} from '../../../../common/icons';

const columns = [
  'Company name',
  'Address',
  'City',
  'State',
  'Country',
  'Zip',
  'Main Contact name',
  'Secondary Contact name',
  'Mobile',
  'Email',
];

const data = [];
for (let i = 0; i < 20; i++) {
  data.push({
    id: i,
    companyName: 'Salam Pacific Indonesia Lines',
    address: '6391 Elgin St. Celina, Delaware 10299',
    city: 'New Jersey',
    state: 'NJ',
    country: 'USA',
    zip: '90670',
    mainContactName: 'Leslie Alexander',
    secondaryContactName: 'Kristin Watson',
    mobile: '+1 300-555-0171',
    email: 'jessica.hanson@example.com',
  });
}

const StaticTableContent = () => {
  return (
    <React.Fragment>
      {/* Search Filter */}
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="form-check form-check--gray pr-30">
            <input
              className="form-check-input"
              type="checkbox"
              id="Available"
              checked="true"
            />
            <label className="form-check-label" for="Available">
              Enabled
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                47
              </span>
            </label>
          </div>
          <div className="form-check form-check--gray pr-30">
            <input
              className="form-check-input"
              type="checkbox"
              id="Pending"
              checked="true"
            />
            <label className="form-check-label" for="Pending">
              Disabled
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                6
              </span>
            </label>
          </div>
        </div>
      </div>
      <div
        className="table-responsive"
        style={{ height: 'calc(100vh - 255px)' }}
      >
        <table className="table table-card">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((datum) => {
              return (
                <tr key={datum.id}>
                  <td>{datum.companyName}</td>
                  <td>{datum.address}</td>
                  <td>{datum.city}</td>
                  <td>{datum.state}</td>
                  <td>{datum.country}</td>
                  <td>{datum.zip}</td>
                  <td>{datum.mainContactName}</td>
                  <td>{datum.secondaryContactName}</td>
                  <td>{datum.mobile}</td>
                  <td>{datum.email}</td>
                  <td>
                    <button className="btn btn-link">
                      <IconPen className="" />
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-link">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
