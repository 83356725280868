import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { IconPlay, IconPending, IconCheck } from "../../../../common/icons";
import GeneralInfo from "../modalTabComponent/GeneralInfo";
import Drivers from "../modalTabComponent/Drivers";
import Accessorials from "../modalTabComponent/Accessorials";
import GroupType from "../modalTabComponent/GroupType";

const CreateGroupModal = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;
  const [isLoadTabActive, setisLoadTabActive] = useState(1);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const handleLoadTabActive = (i) => {
    console.log("Clicked");
    setisLoadTabActive(isLoadTabActive === i ? null : i);
  };
  const showConfirmationModal = (event) => {
    setConfirmationModal(true);
  };
  const hideConfirmationModal = (event) => {
    setConfirmationModal(false);
  };
  return (
    <Modal show={showModal} centered animation={false}>
      <Modal.Header className="py-20 px-30">
        <Modal.Title>Create Group</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 nav-border-bottom">
        <div className="card mb-0">
          {/* Driver Pay Rates Tabs */}
          <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom">
            <li className="nav-item">
              <a
                className={`nav-link ${isLoadTabActive === 1 ? "active" : ""}`}
                href="javascript:void(0)"
                onClick={() => handleLoadTabActive(1)}
              >
                <div className="nav-link__text">
                  <div className="d-flex-align-item-center">
                    {isLoadTabActive === 2 ||
                    isLoadTabActive === 3 ||
                    isLoadTabActive === 4 ? (
                      <IconCheck className="mr-1" />
                    ) : (
                      <IconPlay className="mr-1" />
                    )}
                    General Info
                  </div>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${isLoadTabActive === 2 ? "active" : ""}`}
                href="javascript:void(0)"
                onClick={() => handleLoadTabActive(2)}
              >
                <div className="nav-link__text">
                  <div className="d-flex-align-item-center">
                    {isLoadTabActive === 2 ? (
                      <IconPlay className="mr-2" />
                    ) : isLoadTabActive === 1 ? (
                      <IconPending className="mr-1" />
                    ) : (
                      <IconCheck className="mr-1" />
                    )}
                    Driver
                  </div>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${isLoadTabActive === 3 ? "active" : ""}`}
                href="javascript:void(0)"
                onClick={() => handleLoadTabActive(3)}
              >
                <div className="nav-link__text">
                  <div className="d-flex-align-item-center">
                    {isLoadTabActive === 3 ? (
                      <IconPlay className="mr-2" />
                    ) : isLoadTabActive === 1 || isLoadTabActive === 2 ? (
                      <IconPending className="mr-1" />
                    ) : (
                      <IconCheck className="mr-1" />
                    )}
                    Accessorials
                  </div>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${isLoadTabActive === 4 ? "active" : ""}`}
                href="javascript:void(0)"
                onClick={() => handleLoadTabActive(4)}
              >
                <div className="nav-link__text">
                  <div className="d-flex-align-item-center">
                    {isLoadTabActive === 4 ? (
                      <IconPlay className="mr-2" />
                    ) : isLoadTabActive === 1 ||
                      isLoadTabActive === 2 ||
                      isLoadTabActive === 3 ? (
                      <IconPending className="mr-1" />
                    ) : (
                      <IconPending className="mr-1" />
                    )}
                    Group Type
                  </div>
                </div>
              </a>
            </li>
          </ul>
          {/* Tab Contents */}
          {isLoadTabActive === 1 && <GeneralInfo />}
          {isLoadTabActive === 2 && <Drivers />}
          {isLoadTabActive === 3 && <Accessorials />}
          {isLoadTabActive === 4 && <GroupType />}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {isLoadTabActive === 1 && (
          <div className="">
            <button className="btn btn-close mr-30" onClick={onCancel}>
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => handleLoadTabActive(2)}
            >
              Next
            </button>
          </div>
        )}

        {isLoadTabActive === 2 && (
          <div className="">
            <button className="btn btn-close mr-30" onClick={onCancel}>
              Cancel
            </button>
            <button className="btn btn-outline-light mr-10" onClick={onCancel}>
              Back
            </button>
            <button
              className="btn btn-primary"
              onClick={() => handleLoadTabActive(3)}
            >
              Next
            </button>
          </div>
        )}

        {isLoadTabActive === 3 && (
          <div className="">
            <button className="btn btn-close mr-30" onClick={onCancel}>
              Cancel
            </button>
            <button className="btn btn-outline-light mr-10" onClick={onCancel}>
              Back
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleLoadTabActive(4)}
            >
              Next
            </button>
          </div>
        )}

        {isLoadTabActive === 4 && (
          <div className="">
            <button className="btn btn-close mr-30" onClick={onCancel}>
              Cancel
            </button>
            <button className="btn btn-outline-light mr-10" onClick={onCancel}>
              Back
            </button>
            <button className="btn btn-success" onClick={onCancel}>
              Complete
            </button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CreateGroupModal;
