import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";



const ModalBody = ({content}) => {
 return (
  <Modal.Body className="p-0 pt-4 px-5">
   <center>
    <p id='title'>Company Info {content}</p>
    <span id='subTitle'>Fill out required company info</span>
   </center>
   <div className="text-dark my-3 mt-5">
    <span>Company name</span><br />
    <input className="form-control my-1" type="text" placeholder='e.g BigTruckCompany' id="companyName" /><br />
    <span>Company Phone</span>
    <input className="form-control my-1" type="tel" placeholder='Enter' id="companyPhone" /><br />
    <span>What market do you operate in?</span>
    <select className="form-control my-1" id="marketName">
     <option>Market A</option>
     <option>Market B</option>
     <option>Market C</option>
     <option>Market D</option>
    </select>
    <br />
    <span>How many drivers do you have?</span>
    <input className="form-control my-1" type="number" placeholder='Enter' id="driverCount" /><br />
   </div>
  </Modal.Body>
 )
}


const Index = ({ show, setShowCompanyInfoModal }) => {
 const [activeDot, setActiveDot] = useState('dot1')
 // instance of navigator
 const navigate = useHistory();
 const continueBtnStyle = { backgroundColor: '#EBF1FE', color: '#879AAF' }

 return (
  <Modal
   show={show}
   centered
   size="sm"
   backdrop="static"
   style={{
    boxShadow: "0px 15px 35px rgba(20, 28, 52, 0.2)",
    fontFamily: 'Inter',
    lineHeight: "15px"
   }}>
   <ModalBody content={activeDot} />
   <Modal.Footer>
    <div className="w-100 d-flex justify-content-between align-items-center">
     <div className="dots d-flex">
      <div className={"rounded-circle bg-" + (activeDot === "dot1" ? "primary" : "light")} id="dot1" onClick={() => setActiveDot('dot1')}></div>
      <div className={"mx-1 rounded-circle bg-" + (activeDot === "dot2" ? "primary" : "light")} id="dot2" onClick={() => setActiveDot('dot2')}></div>
      <div className={"rounded-circle bg-" + (activeDot === "dot3" ? "primary" : "light")} id="dot3" onClick={() => setActiveDot('dot3')}></div>
     </div>
     <div>
      <button
       className='btn p-2 px-3 mx-2'
       onClick={() => setShowCompanyInfoModal(false)}
       style={{ border: "1px solid #DBE2EB" }}
      >Back</button>
      <button
       className='btn btn-whitesmoke p-2 px-3'
       onClick={() => {
        setShowCompanyInfoModal(false)
        navigate.push("/checkout")
       }}
       style={continueBtnStyle}
      >Continue</button>
     </div>
    </div>
   </Modal.Footer>
  </Modal>
 )
}

export default Index
