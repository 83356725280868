import React from 'react';


const VideoComp = ({title, src, thumbnail}) => {
  return (
    <div className='rounded-1' style={{ width:'fit-content'}}>
      <video
        className="rounded-5"
        poster={thumbnail}
        src={src}
        controls
        autoPlay={false}
        height='250'
        width='370'
         />
      <h4 className='my-2'>{title}</h4>
    </div>
  )
}

const TabContent1 = () => {
  return (
   <div className="text-dark my-3 mt-5">
      <div className="d-flex justify-content-around mb-3">
        <VideoComp title='1st annual Portfest' src="https://www.portpro.io/assets/videos/PortPro_Introduces_drayOS.mp4" thumbnail={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyhAOXjDm0p7IxFC69I8eLYTlDuTxbVywTYg&usqp=CAU'} />
        <VideoComp title='1st annual Portfest' src="https://www.portpro.io/assets/videos/PortPro_Introduces_drayOS.mp4" thumbnail={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-eVNpHCiKhOt0FnIDWXFBZnNyuLuKb8DbZw&usqp=CAU'} />
        <VideoComp title='1st annual Portfest' src="https://www.portpro.io/assets/videos/PortPro_Introduces_drayOS.mp4" thumbnail={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFYv0h7ysX3Guv03S-yuw2fmn41gk9PwVHvw&usqp=CAU'} />
      </div>
      <div className="d-flex justify-content-around">
        <VideoComp title='1st annual Portfest' src="https://www.portpro.io/assets/videos/PortPro_Introduces_drayOS.mp4" thumbnail={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4gRhH060wk7TQVjsxOxm9LtD9LiL8uWe5ag&usqp=CAU'} />
        <VideoComp title='1st annual Portfest' src="https://www.portpro.io/assets/videos/PortPro_Introduces_drayOS.mp4" thumbnail={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQySY4BQ2JR2QDMRWaX3EmUdn4abCDAfXb9HA&usqp=CAU'} />
        <VideoComp title='1st annual Portfest' src="https://www.portpro.io/assets/videos/PortPro_Introduces_drayOS.mp4" thumbnail={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMSsAls4w51yOMtjB_K7-0OGGgDmviaEHE0Q&usqp=CAU'} />
      </div>
   </div>
  )
}

export default TabContent1
