import React, { useState, useEffect } from "react";
import { IconAngleArrowLeft, IconDownload } from "../../../common/icons";
import ReportsTabs from "../common-elements/ReportsTabs";
import SearchFilter from "./elements/searchFilter";
import StaticTableContent from "./elements/StaticTableContent";
import TopPage from "./elements/toppage";

const tableData = [];
for (let i = 0; i < 20; i++) {
  tableData.push({
    id: i,
    company: "Salam Pacific Indonesia Lines",
    customer: "Jenny Wilson",
  });
}

const DriverPayReport = () => {
  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <ReportsTabs activeTab={10} />
        <div className="page-fluid">
          <TopPage />
          <SearchFilter />
          <StaticTableContent />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DriverPayReport;
