import React, { useState } from 'react';
import EmptyReturnTab from '../Components/EmptyReturnTab';
import TopEmptyCard from './Components/TopEmptyCard';
import SearchFilter from './Components/searchFilter';
import EmptyTable from './Components/EmptyTable';
import TableHeader from './Components/TableHeader';
import {
  IconDownload,
  IconPlus,
  IconSendEmail,
} from '../../../../common/icons';

const ssls = [
  {
    code: 1,
    title: 'CHV',
  },
  {
    code: 2,
    title: 'BAL',
  },
  {
    code: 3,
    title: 'MAERSK',
  },
  {
    code: 4,
    title: 'MSC',
  },
  {
    code: 5,
    title: 'COSCO',
  },
  {
    code: 6,
    title: 'CMA CGM',
  },
];

const EmptyHistory = () => {
  return (
    <div className="position-relative subheader-wrapper">
      <EmptyReturnTab activeTab={3} />
      <div className="page-fluid">
        <div className="top-page">
          <div className=" p-0 mb-15 d-flex flex-row align-items-center justify-content-between">
            <ul className="nav nav-compact border-0" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="PerDiemRecords"
                  role="tab"
                >
                  Per Diem Records
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link rounded-3 bg-light ml-2"
                  data-bs-toggle="tab"
                  href="#TerminalScreengrabs"
                  role="tab"
                >
                  Terminal Screengrabs
                </a>
              </li>
            </ul>
            <button className="ml-auto btn btn-success">
              <IconPlus className="mr-2" />
              Add Payment
            </button>
          </div>
          <SearchFilter />
          <TopEmptyCard />
          <table className="table-card table-card--borderless w-100">
            <TableHeader />
            {ssls.map((ssl) => (
              <EmptyTable data={ssl} />
            ))}
          </table>
        </div>
      </div>
    </div>
  );
};

export default EmptyHistory;
