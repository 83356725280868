import React from 'react';
import { Modal } from 'react-bootstrap';
import CustomSelect from '../../../common/CustomSelect';

const options = [
  { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
  { value: 'John Doe', label: 'John Doe' },
  { value: 'Alex Mendes', label: 'Alex Mendes' },
];

const DeductionDurationModal = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;
  return (
    <Modal show={showModal} centered animation={false} size="sm">
      <Modal.Header>
        <Modal.Title>Deductions Duration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-wrapper">
          <form>
            <div className="form-row">
              <div className="form-group col-md-9">
                <label className="col-form-label">Start Day</label>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Reoccur Every week"
                  className=""
                />
              </div>
              <div className="form-group col-md-3">
                <label className="col-form-label">Duration</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter "
                />
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={onSubmit}>
          Apply Duration Settings
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeductionDurationModal;
