import React from 'react';
import { Icondots, IconTruck, IconCart } from '../../../common/icons';

const AccessorialsList = () => {
  return (
    <>
      <div className="card mb-20 p-20" style={{ height: '350px' }}>
        <div className="d-flex justify-content-between align-items-center pb-25">
          <div className="d-flex flex-column">
            <div className="font-16 font-weight-500 mb-1">Top Accessorials</div>
            <div className="d-flex flex-row ">
              <div className=" badge badge-sm badge-accent-100 px-1 mr-1 rounded-10">
                +5.8%
              </div>
              <div className="text-muted">Since past week</div>
            </div>
          </div>
          <div className="ml-auto">
            <Icondots className="text-muted" />
          </div>
        </div>
        <div className="d-flex justify-content-between pb-20">
          <div className="d-flex">
            <div className="mr-1 align-self-center">
              <div className="bg-gray-50 p-1 mb-1 mr-10 rounded-5">
                <IconTruck className="text-gray-500" />
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="font-12 align-self-center font-weight-500">
                Trucks 500
              </div>
              <div className="text-muted">New Jersey</div>
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="text-gray-900 mr-1">$202.87</div>
            <div className=" badge badge-sm badge-accent-100 px-1 mr-1 rounded-10">
              +5.8%
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between pb-20">
          <div className="d-flex">
            <div className="mr-1 align-self-center">
              <div className="bg-gray-50 p-1 mb-1 mr-10 rounded-5">
                <IconTruck className="text-gray-500" />
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="font-12 align-self-center font-weight-500">
                Trucks 500
              </div>
              <div className="text-muted">New Jersey</div>
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="text-gray-900 mr-1">$202.87</div>
            <div className=" badge badge-sm badge-accent-100 px-1 mr-1 rounded-10">
              +5.8%
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between pb-20">
          <div className="d-flex">
            <div className="mr-1 align-self-center">
              <div className="bg-gray-50 p-1 mb-1 mr-10 rounded-5">
                <IconCart className="text-gray-500" />
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="font-12 align-self-center font-weight-500">
                Trucks 500
              </div>
              <div className="text-muted">New Jersey</div>
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="text-gray-900 mr-1">$202.87</div>
            <div className=" badge badge-sm badge-accent-100 px-1 mr-1 rounded-10">
              +5.8%
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between pb-20">
          <div className="d-flex">
            <div className="mr-1 align-self-center">
              <div className="bg-gray-50 p-1 mb-1 mr-10 rounded-5">
                <IconCart className="text-gray-500" />
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="font-12 align-self-center font-weight-500">
                Trucks 500
              </div>
              <div className="text-muted">New Jersey</div>
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="text-gray-900 mr-1">$202.87</div>
            <div className=" badge badge-sm badge-accent-100 px-1 mr-1 rounded-10">
              +5.8%
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between pb-10">
          <div className="d-flex">
            <div className="mr-1 align-self-center">
              <div className="bg-gray-50 p-1 mb-1 mr-10 rounded-5">
                <IconCart className="text-gray-500" />
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="font-12 align-self-center font-weight-500">
                Trucks 500
              </div>
              <div className="text-muted">New Jersey</div>
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="text-gray-900 mr-1">$202.87</div>
            <div className=" badge badge-sm badge-accent-100 px-1 mr-1 rounded-10">
              +5.8%
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessorialsList;
