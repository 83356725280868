import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IconPen, IconPlus } from '../icons';

const messagelist = [
  {
    id: 1,
    img: '',
    name: 'Day Drivers',
    initials: 'DD',
    message: 'Accepted..',
    sent: '10:10 am',
    isOnline: false,
  },
  {
    id: 2,
    img: '',
    name: 'Accounts Receivable Team',
    initials: 'AR',
    message: 'ok, gotcha',
    sent: '9:45 am',
    isOnline: false,
  },
];

const groups = [
  {
    id: 1,
    groupImage:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg',
    groupName: 'Brooklyn Simmons',
  },
  {
    id: 2,
    groupImage:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    groupName: 'Darrell Steward',
  },
  {
    id: 3,
    groupImage:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-1.jpg',
    groupName: 'Guy Hawkins',
  },
  {
    id: 4,
    groupImage:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    groupName: 'Leslie Alexander',
  },
  {
    id: 5,
    groupImage:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg',
    groupName: 'Esther Howard',
  },
  {
    id: 6,
    groupImage:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-4.jpg',
    groupName: 'Albert Flores',
  },
  {
    id: 7,
    groupImage:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-5.jpg',
    groupName: 'Albert Flores',
  },
  {
    id: 8,
    groupImage:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-6.jpg',
    groupName: 'Kristin Watson',
  },
  {
    id: 9,
    groupImage:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg',
    groupName: 'Eleanor Pena',
  },
  {
    id: 10,
    groupImage:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-8.jpg',
    groupName: 'Ronald Richards',
  },
  {
    id: 11,
    groupImage:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-6.jpg',
    groupName: 'Wade Warren',
  },
  {
    id: 12,
    groupImage:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg',
    groupName: 'Leslie Alexander',
  },
];

const GroupMsgList = (props) => {
  const { messageClicked = () => {} } = props;
  const [isCreateGroupModal, setIsCreateGroupModal] = useState(false);
  const [isEditGroupModal, setIsEditGroupModal] = useState(false);
  const [isAdded, setIsAdded] = useState(null);

  const showCreateGroupModal = () => {
    setIsCreateGroupModal(true);
  };

  const hideCreateGroupModal = () => {
    setIsCreateGroupModal(false);
  };

  const showEditGroupModal = () => {
    setIsEditGroupModal(true);
  };

  const hideEditGroupModal = () => {
    setIsEditGroupModal(false);
  };

  return (
    <React.Fragment>
      <div className="p-20">
        <div className="d-flex align-items-center justify-content-between mb-10">
          {/* No Groups yet UI */}
          <span className="d-none text-muted font-14">
            You don’t have any groups yet
          </span>
          <button className="btn btn-primary" onClick={showCreateGroupModal}>
            <IconPlus className="mr-3" />
            Create Group
          </button>
        </div>
        <div className="clist" style={{ height: 'calc(100vh - 172px)' }}>
          {messagelist.map((message) => (
            <div
              className="clist__card clist__card--outline mb-10"
              key={message.id}
            >
              <div className="d-flex align-items-start p-10">
                <div className="d-flex w-100" onClick={messageClicked}>
                  <div className="flex-shrink-0 mr-10 align-self-center">
                    {message.img == '' ? (
                      <div class="avatar-circle avatar bg-light">
                        <div class="avatar-circle__initial ">
                          <span className="text-dark">{message.initials}</span>
                        </div>
                      </div>
                    ) : (
                      <img
                        src={message.img}
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    )}
                  </div>
                  <div className="flex-grow-1">
                    <div className="d-flex align-items-center mb-1">
                      <a href="#" className="font-14 font-weight-500 text-dark">
                        {message.name}
                      </a>
                      {message.isOnline == true && (
                        <span className="show-online bg-primary d-inline-block ml-2"></span>
                      )}
                    </div>
                    {message.category === 'customer' && (
                      <div className="d-none badge badge-gray-300 mb-2">
                        {message.company}
                      </div>
                    )}
                    <p className="text-muted mb-0">{message.message}</p>
                  </div>
                </div>
                <div class="flex-shrink-0 ml-auto">
                  <div className="d-flex flex-column align-items-end">
                    <div className="text-muted">{message.sent}</div>
                    <button
                      className="btn btn-none p-0"
                      onClick={() => showEditGroupModal(message.id)}
                    >
                      <IconPen className="text-muted" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Create Group Modal */}
      {isCreateGroupModal === true && (
        <Modal show={showCreateGroupModal} centered animation={false} size="sm">
          <Modal.Header>
            <Modal.Title>Create Group</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <form action="">
              <div className="form-group">
                <label className="col-form-label">Group name</label>
                <input
                  className="form-control"
                  placeholder="Enter Group Name"
                />
              </div>
              <div className="form-group mb-0">
                <label className="col-form-label">Members</label>
                {/* Search Member Groups */}
                <div className="app-search bg-gray-50 rounded-lg mb-10">
                  <input
                    type="text"
                    className="input-search-left form-control py-2"
                    placeholder="Search..."
                  />
                  <span className="search-icon ml-1"></span>
                </div>
                {/* Members Group List */}
                <div className="members-list mt-20">
                  {groups.map((group) => (
                    <div class="d-flex align-items-center mb-10" key={group.id}>
                      <div class="flex-shrink-0 mr-2 align-self-center">
                        <img
                          src={group.groupImage}
                          class="avatar rounded-circle"
                          width="30"
                          alt=""
                        />
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="font-14 mb-0">{group.groupName}</h5>
                      </div>
                      <div class="flex-shrink-0">
                        {isAdded === group.id ? (
                          <button class="btn btn-sm btn-outline-light">
                            Added
                          </button>
                        ) : (
                          <button
                            class="btn btn-sm btn-primary"
                            onClick={() => setIsAdded(group.id)}
                          >
                            <IconPlus className="mr-10" />
                            Add
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-close" onClick={hideCreateGroupModal}>
              Cancel
            </button>
            <button className="btn btn-primary">Create Group</button>
          </Modal.Footer>
        </Modal>
      )}
      {/* Edit Group Modal */}
      {isEditGroupModal === true && (
        <Modal show={showEditGroupModal} centered animation={false} size="sm">
          <Modal.Header>
            <Modal.Title>Edit Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form action="">
              <div className="form-group">
                <label className="col-form-label">Group name</label>
                <input className="form-control" placeholder="Day Drivers" />
              </div>
              <div className="form-group mb-0">
                <label className="col-form-label">Members</label>
                {/* Search Member Groups */}
                <div className="app-search bg-gray-50 rounded-lg mb-10">
                  <input
                    type="text"
                    className="input-search-left form-control py-2"
                    placeholder="Search..."
                  />
                  <span className="search-icon ml-1"></span>
                </div>
                {/* Members Group List */}
                <div className="members-list mt-20">
                  {groups.map((group) => (
                    <div class="d-flex align-items-center mb-10" key={group.id}>
                      <div class="flex-shrink-0 mr-2 align-self-center">
                        <img
                          src={group.groupImage}
                          class="avatar rounded-circle"
                          width="30"
                          alt=""
                        />
                      </div>
                      <div class="flex-grow-1">
                        <h5 class="font-14 mb-0">{group.groupName}</h5>
                      </div>
                      <div class="flex-shrink-0">
                        <button class="btn btn-sm btn-outline-light">
                          Added
                        </button>
                      </div>
                    </div>
                  ))}
                  <hr />
                  <div class="d-flex align-items-center mb-10">
                    <div class="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                        class="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="font-14 mb-0">Wade Warren</h5>
                    </div>
                    <div class="flex-shrink-0">
                      {isAdded === 1 ? (
                        <button class="btn btn-sm btn-outline-light">
                          Added
                        </button>
                      ) : (
                        <button
                          class="btn btn-sm btn-primary"
                          onClick={() => setIsAdded(1)}
                        >
                          <IconPlus className="mr-10" />
                          Add
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-close" onClick={hideEditGroupModal}>
              Cancel
            </button>
            <button className="btn btn-primary">Save Edits</button>
          </Modal.Footer>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default GroupMsgList;
