import React, { useState } from "react";
import { IconCalendar } from "../../../../common/icons/index";
import CustomSelect from "../../../../common/CustomSelect";
import Pagination from "../../../../common/pagination";
import Cards from "../../../customers/common-elements/Cards";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const cardItems = [
  {
    id: 1,
    data: "28.84",
    title: "Avg. Revenue per Driver per Day",
  },
  {
    id: 2,
    data: "1.35",
    title: "Avg. Loads per Day",
  },
  {
    id: 3,
    data: "4,002.08",
    title: "Avg. Revenue per Hours",
  },
  {
    id: 4,
    data: "00:01",
    title: "Avg. Hours per Driver per Day",
  },
  {
    id: 5,
    data: "02:47",
    title: "Avg. Stand by Time per Day",
  },
];

const TopPage = () => {
  const [isCardActive, setCardActive] = useState(1);

  return (
    <div className="top-page">
      <div className="d-flex align-items-center">
        <h3 className="font-20 mb-0">54 Driver Revenues</h3>
        <Pagination className="ml-auto" />
      </div>
      {/* Cards Row */}
      <div className={`form-row ${isCardActive ? "hasactive" : ""}`}>
        {cardItems.map((cardItem) => (
          <div className="col-md my-15">
            <div
              className={`ncard d-flex flex-column justify-content-between p-10 ${
                isCardActive === cardItem.id ? "active" : ""
              }`}
              onClick={() => setCardActive(cardItem.id)}
            >
              <div className="d-flex align-items-center">
                <div className="font-medium">{cardItem.title}</div>
              </div>
              <div className="font-medium font-20 mt-10">{cardItem.data}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="filter-row d-flex align-items-center">
        <div className="form-group mb-0 input-wrapper mr-2">
          <input type="text" className="form-control w-150" placeholder="From" />
          <div className="input-icon">
            <IconCalendar />
          </div>
        </div>
        <div className="form-group mb-0 input-wrapper mr-2">
          <input type="text" className="form-control w-150" placeholder="To" />
          <div className="input-icon">
            <IconCalendar />
          </div>
        </div>
        <CustomSelect
          placeholder="All Customer"
          className="w-150 mr-2"
          size="small"
          options={options}
        />
        <CustomSelect
          placeholder="All Sales Agents"
          className="w-150"
          size="small"
          options={options}
        />
      </div>
    </div>
  );
};

export default TopPage;
