import React, { useState } from 'react';
import {
  IconAngleArrowLeft,
  IconAngleArrowRight,
  IconCalendar,
  IconCaretDown,
  IconPrinter,
  IconClone,
  IconTrash,
  IconSendEmail,
  IconLoads,
  IconDownload,
} from '../../../../common/icons';

function EditReport() {
  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <div className="subheader">
          <div className="subheader__inner d-flex">
            <div className="d-flex flex-row align-items-center">
              <button
                className="btn btn-outline-light"
                onClick={() => this.hideLoad()}
              >
                <IconAngleArrowLeft />
              </button>
              <div className="d-flex ml-3 align-items-center">
                <h5 className="mb-0 mr-15">Load Report</h5>
                <div>
                  <IconLoads className="mr-2" />
                  <span className="text-muted font-12">Loads</span>
                </div>
              </div>
            </div>
            <div className="ml-auto position-relative d-flex">
              <button className="btn btn-outline-light mr-1">
                <IconDownload />
              </button>
              <button className="btn btn-outline-light mr-20">
                <IconTrash />
              </button>
              <button className="btn btn-outline-light mr-2">
                <IconAngleArrowLeft />
              </button>
              <button className="btn btn-outline-light">
                <IconAngleArrowRight />
              </button>
            </div>
          </div>
        </div>
        <div className="page-fluid">
          <div className="form-row">
            <div className="col">
              <div className="ncard px-10 py-15 d-flex flex-column justify-content-center">
                <div className="font-12 font-weight-500 mb-1">
                  Send Each Week
                </div>
                <div className="d-flex font-16 font-weight-500">
                  <div className="mr-1">09:00am,</div>
                  <div className="mr-1">11:00am,</div>
                  <span>02:00pm</span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="ncard px-10 py-15 d-flex flex-column justify-content-center">
                <div className="d-flex align-items-center mb-10">
                  <div className="mr-1 sbox sbox--default px-2 h-100">7</div>
                  <div className="ml-2">
                    <h6 className="font-weight-500 mb-0">Receiver</h6>
                  </div>
                </div>
                <div className="d-flex font-12 font-normal">
                  <div className="mr-1">felicia.reid@example.com</div>
                  <div className="mr-1">felicia.reid@example.com</div>
                  <div className="mr-1">felicia.reid@example.com</div>
                  <div className="mr-1">felicia.reid@example.com</div>
                  <div className="">felicia.reid@example.com</div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="table-responsive"
            style={{ height: 'calc(100vh - 255px)' }}
          >
            <table className="table table-card">
              <thead>
                <tr>
                  <th>My Customer</th>
                  <th>Chassis</th>
                  <th>Chassis</th>
                  <th>Chassis</th>
                  <th>Chassis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td>Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td>Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td>Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td>Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td>Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td>Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td>Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td>Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td>Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>

                <tr>
                  <td>Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td>Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td>Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td>Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td>Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td>Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td>Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td>Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditReport;
