import React, { useState } from 'react';
import CustomSelect from '../../../../common/CustomSelect';
import {
  IconBilling,
  IconLoads,
  IconSteering,
  IconTruck,
  IconCart,
  IconLoadCartRight,
  IconLoadCaretLeftRight,
} from '../../../../common/icons';
import LoadsReport from '../ReportTabComponent/LoadsReportModal';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const BaseReport = () => {
  const [loadReportModal, setLoadReportModal] = useState(false);

  const handleLoadReportShowModal = (event) => {
    setLoadReportModal(true);
  };

  const handleLoadReportHideModal = (event) => {
    setLoadReportModal(false);
  };

  return (
    <>
      <div className="title font-20 font-weight-500 text-dark d-flex justify-content-center align-items-center mt-60 mb-50">
        What type of data are you to base your report on?
      </div>
      <div className="d-flex align-items-center justify-content-center px-30 pb-30">
        <button
          className="mr-2 btn btn-primary px-15 py-10"
          onClick={handleLoadReportShowModal}
        >
          <IconLoads className="mr-2" />
          Loads
        </button>
        <button className="mr-2 btn btn-primary px-15 py-10">
          <IconSteering className="mr-2" />
          Driver
        </button>
        <button className="mr-2 btn btn-primary px-15 py-10">
          <IconBilling className="mr-2" />
          Billing
        </button>
        <button className=" mr-2 btn btn-primary px-15 py-10">
          <IconTruck className="mr-2" />
          Truck
        </button>
        <button className=" mr-2 btn btn-primary px-15 py-10">
          <IconLoadCartRight className="mr-2" />
          Chassis
        </button>
        <button className=" btn btn-primary px-15 py-10">
          <IconCart className="mr-2" />
          Trailers
        </button>
      </div>
      {loadReportModal && (
        <LoadsReport
          onCancel={handleLoadReportHideModal}
          onSubmit={handleLoadReportHideModal}
        />
      )}
    </>
  );
};

export default BaseReport;
