import React from 'react';
import { IconBillingCheck } from '../../../common/icons';

const cardItems = [
  {
    id: 1,
    title: 'Demurrage',
    count: 780,
    percent: '5.8%',
  },
  {
    id: 2,
    title: 'Per Diem',
    count: 780,
    percent: '5.8%',
  },
  {
    id: 3,
    title: 'Missed Pick Ups',
    count: 780,
    percent: '5.8%',
  },
  {
    id: 4,
    title: 'Missed Deliveries',
    count: 780,
    percent: '5.8%',
  },
  {
    id: 5,
    title: 'Average Moves per Driver',
    count: 2.67,
    percent: '5.8%',
  },
];

const TopCard = () => {
  return (
    <>
      {/* Cards Row */}
      <div className="row">
        {cardItems.map((cardItem) => (
          <div className="col">
            <div className="card rounded-5 p-15 pb-10 mb-20">
              <div className="d-flex flex-column justify-content-center align-items-start">
                <div className="bg-primary p-2 mb-1 rounded-10">
                  <IconBillingCheck className="text-white" />
                </div>
                <div className="font-12 font-weight-500 mt-2 mb-2">
                  {cardItem.title}
                </div>
                <div className="d-flex align-items-end">
                  <div className="font-30 font-weight-500 mr-1">
                    {cardItem.count}
                  </div>
                  <div className="badge badge-sm badge-accent-100 px-2 rounded-10">
                    {cardItem.percent}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default TopCard;
