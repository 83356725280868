import React, { useState } from "react";

import { IconDownload, IconSendEmail } from "../../../../common/icons/index";

const columns = ["Customer", "", ""];

const tableData = [];
for (let i = 0; i < 6; i++) {
  tableData.push({
    id: i,
    customerCompany: "Salam Pacific Indonesia Lines",
    customerName: "Jenny Wilson",
  });
}

const StaticTableContent = (props) => {
  const { showCustomerDetails } = props;
  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 260px)" }}
      >
        <table className="table table-card table-card--ls">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableData.map((tdata) => {
              return (
                <tr key={tdata.id}>
                  <td >
                    <a href="#!" className="text-primary" onClick={showCustomerDetails}>
                    {tdata.customerCompany}
                    </a>
                    </td>
                  <td>{tdata.customerName}</td>
                  <td className="text-center" width="40">
                    <IconDownload className="text-muted" />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default StaticTableContent;
