import React from "react";
import {
  IconCalendar,
  IconColumn,
  IconFilter,
} from "../../../../common/icons/index";
import CustomSelect from "../../../../common/CustomSelect";
import Pagination from "../../../../common/pagination";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const SearchFilter = () => {
  return (
    <div className="top-page">
      <div className="d-flex align-items-center mb-15">
        <h3 className="font-20 mb-0">53 Customer</h3>
        <Pagination className="ml-auto" />
      </div>
      <div className="filter-row d-flex align-items-center">
        {/* Select Customer */}
        <div className="d-flex">
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select Customer"
            className="w-150 mr-1"
          />
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="All Sales Agent"
            className="w-150"
          />
        </div>
      </div>
    </div>
  );
};

export default SearchFilter;
