import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import { LogoPortProColor } from "../../../common/customIcons";
import { IconCheckCircle } from "../../../common/icons";

const features = [
  {
    title: "Seamless Implementation",
    desc: `Our TMS implementation process is as painless as possible. We
    spend as much time as we need to properly train all employees and
    drivers on the system.`,
  },
  {
    title: "Optimize Operations",
    desc: `Our easy-to-use TMS has proven to dramatically improve the operations of all container drayage carriers that use the system.`,
  },
  {
    title: "Premium Support",
    desc: `We view ourselves as the “tech arm” to your team. After proper implementation and training, we are here to support you all the way.`,
  },
];

const testimonials: { id: number }[] = [];
for (let i = 1; i < 5; i++) {
  testimonials.push({
    id: i,
  });
}

const SignInScreen = () => {
  const responsive = {
    sliderItems: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
      arrows: "false",
    },
  };
  return (
    <div className="signup-wrapper--left bg-white d-flex flex-column vh-100 p-40">
      <div className="logo-wrapper">
        <a href="javascript:void(0)">
          <LogoPortProColor />
        </a>
      </div>
      <p className="mt-25 mb-0 text-gray-500">
        The most intuitive transportation management system for container
        drayage carriers & a customer support team that goes the extra mile.
      </p>
      <div className="mt-70">
        <ul className="list-style-none">
          {features.map((feature, index) => (
            <li className="border-0 mb-20" key={index}>
              <h5>
                <IconCheckCircle className="text-primary mr-10" />
                {feature.title}
              </h5>
              <p className="text-gray-500 mt-10">{feature.desc}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-auto">
        <Carousel
          containerClass="custom-card-carousel"
          responsive={responsive}
          infinite={true}
          arrows={false}
          showDots={true}
          autoPlay={true}
          dotListClass="custom-dot"
        >
          {testimonials.map((testimonial) => {
            return (
              <div className="slider-item  h-100" key={testimonial.id}>
                <p>
                  “Amazing service! Claire helped me to reduce the shipping
                  price a little and shipped it immediately after purchasing.
                  The amethyst cave I got is a beauty and I c...”
                </p>
                <div className="mt-20 text-muted">
                  <div>Body Czeladka </div>
                  <div className="mt-1 font-weight-500">
                    Director at BigPort
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default SignInScreen;
