import React, { useState } from "react";
import {
  IconCalendar,
  IconTimesThin,
  IconReset,
  IconFilter,
  IconInfoFocus,
} from "../../../../common/icons";
import CustomSelect from "../../../../common/CustomSelect";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const FilterSettings = (props) => {
  const {
    onClose = () => console.error("You passed no action to the component"),
  } = props;

  const [isTabActive, setIsTabActive] = useState(1);
  const [openPopup, setOpenPopup] = useState(false);

  const handleShowPopup = (event) => {
    document.body.classList.add("bdpopup-open");
    setOpenPopup(true);
  };

  const handleHidePopup = (event) => {
    document.body.classList.add("bdpopup-open");
    setOpenPopup(false);
  };
  const handleTabActive = (i) => {
    setIsTabActive(i);
  };

  return (
    <React.Fragment>
      <div className="bdpopup-backdrop"></div>
      <div className="bdpopup">
        <div className="bdpopup__content">
          <button className="btn btn-none bdpopup__close" onClick={onClose}>
            <IconTimesThin className="close-icon" />
          </button>
          <div className="d-flex page-fluid align-items-center justify-content-between pt-20 mt-0">
            <p className="font-20 mb-0">Filters & Settings</p>
            <button
              className="btn btn-outline-light btn-sm mr-2"
              onClick={openPopup}
            >
              <IconReset className="mr-2" />
              Reset
            </button>
          </div>
          <ul
            className="nav nav-tabs nav-tabs-custom nav-justified nav-tabs-bottom-border mt-10"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className={`nav-link ${isTabActive === 1 ? "active" : ""}`}
                href="javascript:void(0)"
                onClick={() => handleTabActive(1)}
              >
                <IconFilter className="mr-1" />
                Filters
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${isTabActive === 2 ? "active" : ""}`}
                href="javascript:void(0)"
                onClick={() => handleTabActive(2)}
              >
                <IconInfoFocus className="mr-2" />
                Table Columns
              </a>
            </li>
          </ul>

          {isTabActive === 1 && (
            <React.Fragment>
              <div
                className="page-fluid bdpopup__content__scroll mt-4"
                style={{ height: "calc(100% - 182px)" }}
              >
                <React.Fragment>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">
                      Billing From
                    </label>
                    <div className="col-md-7">
                      <div className="input-wrapper">
                        <input className="form-control" placeholder="All" />
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">
                      Billing To
                    </label>
                    <div className="col-md-7">
                      <div className="input-wrapper">
                        <input className="form-control" placeholder="All" />
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
                <div className="hr-light"></div>
                <React.Fragment>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Customer</label>
                    <div className="col-md-7">
                      <CustomSelect
                        name={"name"}
                        options={options}
                        value={1}
                        isMulti={true}
                        isClearable={true}
                        size="small"
                        placement="right"
                        placeholder="Select Sub Customer"
                        className="w-100"
                      />
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">
                      Sub Customer
                    </label>
                    <div className="col-md-7">
                      <CustomSelect
                        name={"name"}
                        options={options}
                        value={1}
                        isMulti={true}
                        isClearable={true}
                        size="small"
                        placement="right"
                        placeholder="Select Sub Customer"
                        className="w-100"
                      />
                    </div>
                  </div>
                </React.Fragment>
                <div className="hr-light"></div>
                <div className="row">
                  <div className="col-12">
                    <h5 className="text-dark">Is All Sub customers</h5>
                  </div>
                  <div className="col-md-6 d-flex align-items-center mt-10">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="yes"
                        checked="true"
                      />
                      <label className="form-check-label" for="yes">
                        Yes
                      </label>
                    </div>
                    <div className="ml-auto font-medium">5</div>
                  </div>
                  <div className="col-md-6 d-flex align-items-center mt-10">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="No"
                        checked="true"
                      />
                      <label className="form-check-label" for="No">
                        No
                      </label>
                    </div>
                    <div className="ml-auto font-medium">3</div>
                  </div>
                </div>
                <div className="hr-light"></div>
                <div className="row">
                  <div className="col-12">
                    <h5 className="text-dark">Is Email sent</h5>
                  </div>
                  <div className="col-md-6 d-flex align-items-center mt-10">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="yes"
                        checked="true"
                      />
                      <label className="form-check-label" for="yes">
                        Yes
                      </label>
                    </div>
                    <div className="ml-auto font-medium">5</div>
                  </div>
                  <div className="col-md-6 d-flex align-items-center mt-10">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="No"
                        checked="true"
                      />
                      <label className="form-check-label" for="No">
                        No
                      </label>
                    </div>
                    <div className="ml-auto font-medium">3</div>
                  </div>
                </div>
              </div>

              <div className="bdpopup__footer page-fluid">
                <button className="btn btn-primary">Apply</button>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FilterSettings;
