import React, { useState } from 'react';
import ColumnFilter from '../../../common/columnFilter';
import FilterPop from '../../../common/filterPop';
import { IconColumn, IconFilter } from '../../../common/icons';

const DispatcherFilterRow = (props) => {
  //Column
  const [ShowPopDrop, setShowPopDrop] = useState(false);
  const handlePopDrop = (event) => {
    setShowPopDrop(!ShowPopDrop);
  };

  //Filter
  const [ShowFilter, setShowFilter] = useState(false);
  const handleShowFilter = (event) => {
    setShowFilter(true);
  };
  const handleHideFilter = (event) => {
    setShowFilter(false);
  };

  return (
    <React.Fragment>
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="form-check form-check--gray pr-30">
            <input
              className="form-check-input"
              type="checkbox"
              id="Available"
            />
            <label className="form-check-label" for="Available">
              Available
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                47
              </span>
            </label>
          </div>
          <div className="form-check form-check--gray pr-30">
            <input className="form-check-input" type="checkbox" id="Pending" />
            <label className="form-check-label" for="Pending">
              Pending
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                6
              </span>
            </label>
          </div>
          <div className="form-check form-check--gray pr-30">
            <input className="form-check-input" type="checkbox" id="pre_bull" />
            <label className="form-check-label" for="pre_bull">
              Pre Bull
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                8
              </span>
            </label>
          </div>
          <div className="form-check form-check--gray pr-30">
            <input className="form-check-input" type="checkbox" id="pick_run" />
            <label className="form-check-label" for="pick_run">
              Pick & Run
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                9
              </span>
            </label>
          </div>
        </div>
        <div className="ml-auto d-flex">
          <button
            className="btn btn-outline-light mr-2"
            onClick={handleShowFilter}
          >
            <IconFilter className="mr-2" />
            Filter
          </button>
          <div className="position-relative">
            <button
              className="btn btn-outline-light position-relative"
              onClick={handlePopDrop}
            >
              <IconColumn />
            </button>
          </div>
        </div>
      </div>
      {/* Filter Popup */}
      {ShowFilter && <FilterPop hideFilter={handleHideFilter} />}
    </React.Fragment>
  );
};

export default DispatcherFilterRow;
