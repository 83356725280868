import React, { useState } from "react";

import DriverDetails from "./driverDetails";
import TrackChat from "./chat";
import {
  IconAngleArrowLeft,
  IconChat,
  IconClockSnooze,
  IconDotSeperator,
  IconTimes,
  IconTimesThin,
  IconTruck,
} from "../../../common/icons";

const ShipOsTracking = ({}) => {
  const [openCard, _setOpenCard] = useState(false);
  const [sendMessage, _setSendMessage] = useState(false);
  const [assignLoad, _setAssignLoad] = useState(false);

  const onClickSendMessage = () => _setSendMessage(!sendMessage);
  const onClickTrackCard = () => _setOpenCard(!openCard);
  const closeTrackCard = () => {
    _setOpenCard(!openCard);
    _setSendMessage(!sendMessage);
  };
  const handleAssignLoad = () => _setAssignLoad(!assignLoad);

  const repData = [];
  for (let i = 1; i < 20; i++) {
    repData.push({
      id: i,
    });
  }
  return (
    <div
      className={`wrapper home position-relative ${
        openCard && "subheader-wrapper"
      }`}
    >
      {openCard && (
        <div className="subheader">
          <div className="subheader__inner d-flex">
            <div className="d-flex flex-row align-items-center">
              <button
                className="btn btn-outline-light mr-3"
                onClick={closeTrackCard}
              >
                <IconAngleArrowLeft />
              </button>
              <div className="d-flex">
                <div className="flex-shrink-0 mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    className="avatar-xs rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <div className="flex-grow-1">
                  <h5 className="mb-0">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                  <p className="text-muted mb-0">(702) 555-0122</p>
                </div>
                <div class="flex-shrink-0">
                  <span class="badge badge-light font-12">(702) 555-0122</span>
                </div>
              </div>
              <div className="d-flex ml-5 align-items-center">
                <IconTruck className="text-muted" />
                <h5 className="mb-0 ml-3">Truck #: UJSK8223432</h5>
              </div>
            </div>
            <div className="ml-auto">
              <button
                className="btn btn-outline-light"
                onClick={onClickSendMessage}
              >
                {sendMessage ? (
                  <React.Fragment>
                    <IconTimesThin className="mr-2" />
                    Close
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <IconChat className="mr-2" />
                    Send Message
                  </React.Fragment>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className={`position-relative track-page ${openCard && ""}`}>
        {/* START TrackBar Design */}
        {!openCard ? (
          <div className="mapbar" style={{ height: "calc(100vh - 120px)" }}>
            <div className="card p-2 mb-1">
              <div className="app-search mb-2">
                <input
                  type="text"
                  className="input-search-left form-control"
                  placeholder="Search.."
                />
                <span className="search-icon"></span>
              </div>
              <ul className="nav nav-compact nav-justified" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href="#all-drivers"
                    role="tab"
                  >
                    Rep(31)
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#dispatched"
                    role="tab"
                  >
                    Assigned(23)
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#not-assigned"
                    role="tab"
                  >
                    Not Assigned(8)
                  </a>
                </li>
              </ul>
            </div>
            <div className="dlist" style={{ height: "calc(100% - 55px)" }}>
              {repData.map((datum) => {
                return (
                  <div className="dlist__card mb-1" onClick={onClickTrackCard}>
                    <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                      <div className="flex-shrink-0 mr-2 align-self-center">
                        <img
                          src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                          className="avatar-xs rounded-circle"
                          width="30"
                          alt=""
                        />
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="#" className="text-dark">
                            Robert Fox
                          </a>
                        </h5>
                        <div className="text-muted mb-0">
                        Order#:M07982233
                        </div>
                        <div className="text-muted mb-0">
                        Vessel#:UJSK8223432
                        </div>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-light font-12">
                          (702) 555-0122
                        </span>
                      </div>
                    </div>
                    <div className="dlist__load">
                      <div className="dlist__load-current">
                        <div className="d-flex align-items-start p-2">
                          <div className="flex-grow-1">
                            <h5 className="font-14 mb-1">
                              <a href="#" className="text-dark">
                                Accem - Hook
                              </a>
                            </h5>
                            <p className="text-muted mb-0">Springville, AR</p>
                            <span className="badge badge-secondary font-12 mt-2">
                              +2 more orders
                            </span>
                          </div>
                          <div className="flex-shrink-0">
                            <span className="badge badge-info badge-sm">
                              Q-Request
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <DriverDetails />
        )}
        {/* END TrackBar Design */}
        <div
          id="Trackmap"
          style={{
            height: `calc(100vh - ${openCard ? "112px" : "60px"})`,
            backgroundImage: `url("https://i.imgur.com/ELNbBFS.png")`,
          }}
        >
          <div
            className="btn-group shadow-1"
            style={{ right: 20, top: 20, position: "absolute" }}
          >
            <button className="btn btn-white">Street</button>
            <button className="btn btn-gray-100">Satellite</button>
          </div>
        </div>
        {/* <div id="Trackmap" style={{ height: 'calc(100vh - 112px)' }} ></div> */}
        {sendMessage && <TrackChat />}
      </div>
    </div>
  );
};

export default ShipOsTracking;
