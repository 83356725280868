import React, { useState } from "react";

import { IconDownload, IconSendEmail } from "../../../../common/icons/index";

const columns = [
  "Load Status",
  "Driver",
  "Load #",
  "Move",
  "ETA",
  "LFD",
  "Cut",
  "Pick Up",
  "APT",
  "Customer",
  "Container",
  "Size",
  "Type",
  "Chassis",
  "SSL",
  "BOL/BKG",
  "Port",
  "Consignee",
  "Location",
  "RTN",
];

const tableData = [];
for (let i = 0; i < 20; i++) {
  tableData.push({
    loadStatus: "Available",
    driver: "Floyd Miles",
    loadNo: "POP2_M1048" + i,
  });
}

const options = [
  { value: "jessica.hanson@example.com", label: "jessica.hanson@example.com" },
  { value: "john.doe@example.com", label: "john.doe@example.com" },
  { value: "alex@example.com", label: "alex@example.com" },
];

const StaticTableContent = () => {
  return (
    <React.Fragment>
      <h3 className="font-20">{tableData.length} LFD Reports</h3>
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 260px)" }}
      >
        <table className="table table-card table-card--ls">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableData.map((tdata) => {
              return (
                <tr key={tdata.id}>
                  <td>{tdata.loadStatus}</td>
                  <td>{tdata.driver}</td>
                  <td>{tdata.loadNo}</td>
                  <td>Import</td>
                  <td>
                    <div>06/11</div>
                    <div className="text-muted">08:50pm</div>
                  </td>
                  <td>28/10/2012</td>
                  <td>28/10/2012</td>
                  <td>28/10/2012</td>
                  <td>28/10/2012</td>
                  <td>The Walt Disney Company</td>
                  <td>M0AUSDFFFF</td>
                  <td>40'</td>
                  <td>Standard</td>
                  <td>ODBC990</td>
                  <td>ODB Logistics</td>
                  <td>w2322983069</td>
                  <td>GCT BAYONNE</td>
                  <td>All Star Westco</td>
                  <td>San, Bernardino, CA</td>
                  <td>San, Bernardino, CA</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default StaticTableContent;
