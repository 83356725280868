import React, { useState } from "react";
import CustomSelect from "../../../../common/CustomSelect";
import {
  IconCalendar,
  IconCheck,
  IconPlus,
  IconSortDown,
  IconTrash,
} from "../../../../common/icons";
import { Modal } from "react-bootstrap";
import AddDeduction from "./AddDeductionModal";

const columns = ["Load", "From", "To", "Status", "Date & Time", "Payment"];

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const Deduction = () => {
  const [showAddDeductionModal, setshowAddDeductionModal] = useState(false);
  const [inputValue, setInputValue] = useState({
    description: "",
    amount: "",
  });

  function handleInput(evt) {
    const value = evt.target.value;
    setInputValue({
      ...setInputValue,
      [evt.target.name]: value,
    });
  }

  const handleshowAddDeductionModal = (event) => {
    setshowAddDeductionModal(true);
  };

  const handleHideAddModal = (event) => {
    setshowAddDeductionModal(false);
  };

  return (
    <>
      <div className="d-flex flex-row align-items-center justify-content-between my-10 mx-15">
        <h5 className="mb-0">1 Deduction </h5>
        <button
          className="btn btn-outline-light font-weight-500"
          onClick={handleshowAddDeductionModal}
        >
          <IconPlus className="mr-2" />
          Add Deduction
        </button>
      </div>
      <div className="table-responsive bg-gray-50">
        <table className="table table-card table-card--ls">
          <thead>
            <tr>
              <th>
                Type
                <span>
                  <IconSortDown className="ml-1 text-gray-700" />
                </span>
              </th>
              <th style={{ width: "30%" }}>Description</th>
              <th>Unit of measure</th>
              <th>Math</th>
              <th style={{ width: "15%" }}>Amount</th>
              <th style={{ width: "2%" }}></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select..."
                  className="w-200"
                />
              </td>
              <td>
                <div className="input-wrapper w-100">
                  <input
                    className="form-control"
                    placeholder="Description"
                    name="description"
                    value={inputValue.description}
                    onChange={handleInput}
                  />
                  {inputValue.description && (
                    <div className="input-icon mr-n1">
                      <button className="btn btn-primary btn-sm rounded-3 px-1">
                        <IconCheck />
                      </button>
                    </div>
                  )}
                </div>
              </td>
              <td>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Flat Amount"
                  className="w-150"
                />
              </td>
              <td>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Subtract"
                  className="w-150"
                />
              </td>
              <td>
                <div className="input-wrapper w-100">
                  <input
                    className="form-control"
                    placeholder="$50.00"
                    name="amount"
                    value={inputValue.amount}
                    onChange={handleInput}
                  />
                  {inputValue.amount && (
                    <div className="input-icon mr-n1">
                      <button className="btn btn-primary btn-sm rounded-3 px-1">
                        <IconCheck />
                      </button>
                    </div>
                  )}
                </div>
              </td>
              <td>
                <button className="btn btn-none">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select..."
                  className="w-200"
                />
              </td>
              <td>
                <div className="input-wrapper w-100">
                  <input
                    className="form-control"
                    placeholder="Description"
                    name="description"
                    value={inputValue.description}
                    onChange={handleInput}
                  />
                  {inputValue.description && (
                    <div className="input-icon mr-n1">
                      <button className="btn btn-primary btn-sm rounded-3 px-1">
                        <IconCheck />
                      </button>
                    </div>
                  )}
                </div>
              </td>
              <td>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Flat Amount"
                  className="w-150"
                />
              </td>
              <td>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Subtract"
                  className="w-150"
                />
              </td>
              <td>
                <div className="input-wrapper w-100">
                  <input
                    className="form-control"
                    placeholder="$50.00"
                    name="amount"
                    value={inputValue.amount}
                    onChange={handleInput}
                  />
                  {inputValue.amount && (
                    <div className="input-icon mr-n1">
                      <button className="btn btn-primary btn-sm rounded-3 px-1">
                        <IconCheck />
                      </button>
                    </div>
                  )}
                </div>
              </td>
              <td>
                <button className="btn btn-none">
                  <IconTrash />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Add deduction Modal */}
      {showAddDeductionModal && <AddDeduction onCancel={handleHideAddModal} />}
    </>
  );
};

export default Deduction;
