import React from "react";
import DispatcherTabs from "../common-elements/dispatcherTabs";
import Pagination from "../../../common/pagination";
import ProblemContainerFilterRow from "./elements/ProblemContainerFilterRow";
import StaticTableContent from "./elements/StaticTableContent";

const ProblemContainer = () => {
  return (
    <div className="position-relative subheader-wrapper">
      <DispatcherTabs activeTab={5} />
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-15 d-flex align-items-center">
            <h4 className="mb-0">Problem Containers</h4>
            <Pagination className="ml-auto" />
          </div>
          {/* Filter Row */}
          <ProblemContainerFilterRow />
        </div>
        {/* Problem Container Table */}
        <div
          className="table-responsive"
          style={{ height: "calc(100vh - 254px)" }}
        >
          <StaticTableContent />
        </div>
      </div>
    </div>
  );
};

export default ProblemContainer;
