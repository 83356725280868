import React, { Component } from 'react';
import {
  IconDocument,
  IconSearchData,
  IconNotes,
  IconInfoCircle,
  IconLoadCartRight,
  IconClock9,
} from '../../../common/icons';
import TabAudit from './tabContent/audit';
import TabChassis from './tabContent/chassis';
import TabDocument from './tabContent/documents';
import TabHistory from './tabContent/history';
import TabNotes from './tabContent/notes';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];
class ChassisLoadPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadTabActive: false,
      showUploadModal: false,
    };
  }
  handleShowUploadModal = (event) => {
    this.setState({ showUploadModal: true });
  };
  handleHideUploadModal = (event) => {
    this.setState({ showUploadModal: false });
  };
  handleLoadTabActive = (i) => {
    this.setState({
      isLoadTabActive: this.state.isLoadTabActive === i ? null : i,
    });
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-fluid">
          <div className="form-row-md">
            <div className="col-md" style={{ maxWidth: 270 }}>
              <div className="card p-15 mb-1">
                <h5>About</h5>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Chassis #</span>
                  <span>TSXZ454781</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Chassis Type</span>
                  <span>Standard</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Chassis size 40’</span>
                  <span>SSF223788</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Chassis Owner</span>
                  <span>Castillo's Chassis INC.</span>
                </div>
              </div>
            </div>
            <div className="col-md">
              <div className="card mb-0">
                <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        this.state.isLoadTabActive === 1 ? 'active' : ''
                      }`}
                      href="javascript:void(0)"
                      onClick={() => this.handleLoadTabActive(1)}
                    >
                      <IconLoadCartRight />
                      <div className="nav-link__text">Chassis</div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        this.state.isLoadTabActive === 2 ? 'active' : ''
                      }`}
                      href="javascript:void(0)"
                      onClick={() => this.handleLoadTabActive(2)}
                    >
                      <IconDocument />
                      <div className="nav-link__text">Documents</div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        this.state.isLoadTabActive === 3 ? 'active' : ''
                      }`}
                      href="javascript:void(0)"
                      onClick={() => this.handleLoadTabActive(3)}
                    >
                      <IconClock9 />
                      <div className="nav-link__text">History</div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        this.state.isLoadTabActive === 4 ? 'active' : ''
                      }`}
                      href="javascript:void(0)"
                      onClick={() => this.handleLoadTabActive(4)}
                    >
                      <IconSearchData />
                      <div className="nav-link__text">Audit</div>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        this.state.isLoadTabActive === 5 ? 'active' : ''
                      }`}
                      href="javascript:void(0)"
                      onClick={() => this.handleLoadTabActive(5)}
                    >
                      <IconNotes />
                      <div className="nav-link__text">Notes</div>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="tab-loads">
                {this.state.isLoadTabActive === 1 && <TabChassis />}
                {this.state.isLoadTabActive === 2 && <TabDocument />}
                {this.state.isLoadTabActive === 3 && <TabHistory />}
                {this.state.isLoadTabActive === 4 && <TabAudit />}
                {this.state.isLoadTabActive === 5 && <TabNotes />}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ChassisLoadPage;
