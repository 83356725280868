const CreditCardForm = () => {
    return (
        <div className="form-wrapper">
            <form>
                <div className="form-row">
                    <div className="col-6 form-group mb-20">
                        <label className="mb-10">
                            Credit Card Number
                        </label>
                        <input
                            className="form-control py-10"
                            placeholder="XXXX - XXXX - XXXX - XXXX"
                        />
                    </div>
                    <div className="col-3 form-group mb-20">
                        <label className="mb-10">
                            CVC
                        </label>
                        <input
                            className="form-control py-10"
                            placeholder="XXX"
                        />
                    </div>
                    <div className="col-3 form-group mb-20">
                        <label className="mb-10">
                            MM / YY
                        </label>
                        <input
                            className="form-control py-10"
                            placeholder="MM / YY"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
}

export default CreditCardForm;