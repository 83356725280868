import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  IconAppointment,
  IconBookings,
  IconEmpty,
  IconFileCheck,
  IconMap,
  IconUserAlt,
} from "../icons";
import { LogoPortPro, LogoPortProSmall } from "../customIcons";

class ShipOsSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuHovered: false,
      isMenuClicked: null,
      isMenuOpen: false,
    };
    this.handleMenuHover = this.handleMenuHover.bind(this);
  }
  handleMenuHover() {
    this.setState((prevState) => ({
      isMenuHovered: !prevState.isMenuHovered,
      isMenuClicked:
        prevState.isMenuHovered && prevState.isMenuClicked
          ? null
          : prevState.isMenuClicked,
    }));
  }
  handleMenuClick = (i) => {
    this.setState({
      isMenuClicked: this.state.isMenuClicked === i ? null : i,
    });
  };

  handleSmartRoute(getRoute) {
    localStorage.setItem("role", getRoute);
    window.location.replace("/");
  }
  render() {
    return (
      <aside
        className={`left-sidebar ${
          this.state.isMenuHovered ? "ishovered" : ""
        }`}
        onMouseEnter={this.handleMenuHover}
        onMouseLeave={this.handleMenuHover}
      >
        {/* Sidebar scroll*/}
        {/* Sidebar navigation*/}
        <nav className="sidebar-nav">
          <div className="d-flex align-items-center justify-content-between">
            <a className="navbar-brand" href="javascript:void(0)">
              {/* Logo Expand */}
              <LogoPortPro className="navbar-brand__logo-big" />
              {/* Logo Small */}
              <LogoPortProSmall className="navbar-brand__logo-small" />
            </a>
          </div>
          <div id="sidebarnav" className="sidebar-content">
            <Link
              className="sidebar-item"
              to="/shipos/tracking"
              activeClassName={"active"}
            >
              <div className="sidebar-link">
                <IconMap />
                <span className="hide-menu">Tracking</span>
              </div>
            </Link>
            {/* <Link
              className="sidebar-item"
              to="/trackos/live-share"
              activeClassName={"active"}
            >
              <div className="sidebar-link">
                <IconMap />
                <span className="hide-menu">Live Share</span>
              </div>
            </Link> */}
            <Link
              className="sidebar-item"
              to="/shipos/quotes"
              activeClassName={"active"}
            >
              <div className="sidebar-link">
                <IconFileCheck />
                <span className="hide-menu">Quotes</span>
              </div>
            </Link>
            <Link
              className="sidebar-item"
              to="/trackos/orders"
              activeClassName={"active"}
            >
              <div className="sidebar-link">
                <IconBookings />
                <span className="hide-menu">Orders</span>
              </div>
            </Link>
            <Link
              className="sidebar-item"
              to="/trackos/customers"
              activeClassName={"active"}
            >
              <div className="sidebar-link">
                <IconEmpty />
                <span className="hide-menu">Customers</span>
              </div>
            </Link>
           
            <div
              className={`sidebar-item ${
                this.state.selected == "goto" ? "active" : ""
              } ${this.state.isMenuClicked === "goto" ? "isclicked" : ""}`}
              onClick={() => this.handleMenuClick("goto")}
            >
              <div className="sidebar-link has-arrow">
                <i className="uil uil-files-landscapes-alt"></i>
                <span className="hide-menu">Go To</span>
              </div>
              <div
                aria-expanded="false"
                className={`first-level ${
                  this.state.isMenuOpen ? "collapse in" : "collapse"
                } `}
              >
                <Link
                  className="first-level__link"
                  onClick={() => this.handleSmartRoute("drayos")}
                  activeClassName={"active"}
                  to="/"

                >
                  DrayOs
                </Link>
                <Link
                  className="first-level__link"
                  onClick={() => this.handleSmartRoute("trackos")}
                  activeClassName={"active"}
                  to="/"

                >
                  TrackOs
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </aside>
    );
  }
}

export default ShipOsSidebar;
