import React, { useState } from 'react';
import { IconDownload, IconPlus } from '../../../common/icons';
import Pagination from '../../../common/pagination/index';
import SubHeader from '../common-elements/SubHeader';
import AddCommodityProfile from './elements/AddCommodityProfile';
import StaticTableContent from './elements/StaticTableContent';
import SettingsTab from '../common-elements/SettingsTabs';

const TerminalSettings = () => {
  const [showAddModal, setShowAddModal] = useState(false);

  const handleShowAddModal = (event) => {
    setShowAddModal(true);
  };

  const handleHideAddModal = (event) => {
    setShowAddModal(false);
  };

  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <SettingsTab
            activeTab={8}
            button={
              <div className="d-flex">
                <button className="mr-2 btn btn-outline-light ">
                  <IconDownload className="mr-2" />
                  Downlaod CVS
                </button>
                <button
                  className=" btn btn-success "
                  onClick={handleShowAddModal}
                >
                  <IconPlus className="mr-2" />
                  Add Commodity
                </button>
              </div>
            }
          />
        </div>
      </div>
      <div className="page-fluid">
        <div className="top-page">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h4 className="font-20 mb-0">6 Profiles</h4>
          </div>
        </div>

        {/* Static Table Content */}
        <StaticTableContent />

        {/* Add New Truck */}
        {showAddModal && (
          <AddCommodityProfile
            onCancel={handleHideAddModal}
            onSubmit={handleHideAddModal}
          />
        )}
      </div>
    </div>
  );
};

export default TerminalSettings;
