import React, { useState } from "react";
import FilterPop from "../../../common/filterPop";
import { IconColumn, IconFilter } from "../../../common/icons";
import TrackOsImportAvailabilityFilterPop from "./FilterPop";

const TrackOsImportAvailabilityFilterRow = ({}) => {
  const [showFilter, setShowFilter] = useState(false);

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  
  return (
    <React.Fragment>
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>

        <div className="ml-auto d-flex">
          <button
            className="btn btn-outline-light mr-2"
            onClick={handleShowFilter}
          >
            <IconFilter className="mr-2" />
            Filter
          </button>
          <div className="position-relative">
            <button className="btn btn-outline-light position-relative">
              <IconColumn />
            </button>
          </div>
        </div>
      </div>
      {showFilter ? <TrackOsImportAvailabilityFilterPop hideFilter={() => setShowFilter(false)} /> : ""}
    </React.Fragment>
  );
};

export default TrackOsImportAvailabilityFilterRow;
