import { Modal } from "react-bootstrap";
import AnnualSwitch from "./AnnualSwitch";
import PaymentOption from "./PaymentOption";
import PaymentType from "./PaymentType";
import CreditCardForm from "./CreditCardForm";

const PaymentModal = (props) => {
    const { showModal, hideModal } = props;
    return (
        <Modal show={showModal} centered animation={false}>
            <Modal.Header className="d-block text-center">
                <Modal.Title>Payment Options</Modal.Title>
                <div className="text-muted mt-1">
                    How do you prefer to make payments?
                </div>
            </Modal.Header>
            <Modal.Body className="px-60">
                {/* Annual Switch */}
                <AnnualSwitch />
                {/* Subscription Type */}
                <div className="mt-20 mb-20">
                    <PaymentOption />
                </div>
                {/* Payment Type */}
                <div className="mt-30 mb-30">
                    <PaymentType />
                </div>
                {/* Credit Card Form */}
                <div className="mt-30">
                    <CreditCardForm />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-light mr-10 py-10 px-15" onClick={hideModal}>
                    Back
                </button>
                <button className="btn btn-light py-10 px-15" onClick={showModal}>Continue</button>
            </Modal.Footer>
        </Modal>
    )
}

export default PaymentModal;