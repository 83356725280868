import React, { useState } from "react";
import { IconAngleArrowDown, IconAngleArrowRight } from "../../../common/icons";

const DispatcherCards = (props) => {
  const [isCardActive, setisCardActive] = useState(false);
  const [isCardSubItemActive, setisCardSubItemActive] = useState(false);

  const handleCardActive = (e, i) => {
    e.stopPropagation();
    setisCardActive(isCardActive === i ? null : i);
    setisCardSubItemActive(false);
  };

  const handleCardSubItemActive = (e, i) => {
    e.stopPropagation();
    setisCardSubItemActive(isCardSubItemActive === i ? null : i);
    setisCardActive(false);
  };

  return (
    <React.Fragment>
      <div className={`form-row ${isCardActive ? "hasactive" : ""}`}>
        <div className="col-xl col-md-3 col-sm-6 col-6 my-2">
          <div
            className={`ncard d-flex flex-column justify-content-between ${
              isCardActive === 1 ? "active" : ""
            }`}
            href="javascript:void(0)"
            onClick={(e) => handleCardActive(e, 1)}
          >
            <div className="d-flex align-items-center mb-10">
              <div
                className={`mr-1 sbox sbox--default ${
                  isCardActive === 1 ? "sbox--active" : ""
                }`}
              >
                54
              </div>
              <div className="font-medium">Containers on Vessel</div>
              <div className="ml-auto">
                <IconAngleArrowRight />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between form-row">
              <div className="col-6">
                <div
                  className={`bbox bbox--default ${
                    isCardSubItemActive === 1 ? "bbox--active" : ""
                  } `}
                  onClick={(e) => handleCardSubItemActive(e, 1)}
                >
                  <div className="bbox__title">On Hold</div>
                  <div className="bbox__number">43</div>
                </div>
              </div>
              <div className="col-6">
                <div
                  className={`bbox bbox--default ${
                    isCardSubItemActive === 2 ? "bbox--active" : ""
                  } `}
                  onClick={(e) => handleCardSubItemActive(e, 2)}
                >
                  <div className="bbox__title">On Release</div>
                  <div className="bbox__number">49</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl col-md-3 col-sm-6 col-6 my-2">
          <div
            className={`ncard d-flex flex-column justify-content-between ${
              isCardActive === 'containers-at-port' ? "active" : ""
            }`}
            href="javascript:void(0)"
            onClick={(e) => handleCardActive(e, 'containers-at-port')}
          >
            <div className="d-flex align-items-center mb-10">
              <div
                className={`mr-1 sbox sbox--default ${
                  isCardActive === 'containers-at-port' ? "sbox--active" : ""
                }`}
              >
                54
              </div>
              <div className="font-medium">Containers at Port</div>
              <div className="ml-auto">
                <IconAngleArrowRight />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between form-row">
              <div className="col-6">
                <div
                  className={`bbox bbox--default ${
                    isCardSubItemActive === 'containers-at-lastfreedays' ? "bbox--active" : ""
                  } `}
                  onClick={(e) => handleCardSubItemActive(e, 'containers-at-lastfreedays')}
                >
                  <div className="bbox__title">Last Free Days</div>
                  <div className="bbox__number">43</div>
                </div>
              </div>
              <div className="col-6">
                <div
                  className={`bbox bbox--default ${
                    isCardSubItemActive === 'containers-at-customer' ? "bbox--active" : ""
                  } `}
                  onClick={(e) => handleCardSubItemActive(e, 'containers-at-customer')}
                >
                  <div className="bbox__title">At Customer</div>
                  <div className="bbox__number">49</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl col-md-3 col-sm-6 col-6 my-2">
          <div
            className={`ncard d-flex flex-column justify-content-between ${
              isCardActive === 2 ? "active" : ""
            }`}
            href="javascript:void(0)"
            onClick={(e) => handleCardActive(e, 2)}
          >
            <div className="d-flex align-items-center mb-10">
              <div
                className={`mr-1 sbox sbox--default ${
                  isCardActive === 2 ? "sbox--active" : ""
                }`}
              >
                72
              </div>
              <div className="font-medium">Deliveries Scheduled</div>
              <div className="ml-auto">
                <IconAngleArrowRight />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between form-row">
              <div className="col-6">
                <div
                  className={`bbox bbox--default ${
                    isCardSubItemActive === 3 ? "bbox--active" : ""
                  } `}
                  onClick={(e) => handleCardSubItemActive(e, 3)}
                >
                  <div className="bbox__title">In Port</div>
                  <div className="bbox__number">53</div>
                </div>
              </div>
              <div className="col-6">
                <div
                  className={`bbox bbox--default ${
                    isCardSubItemActive === 4 ? "bbox--active" : ""
                  } `}
                  onClick={(e) => handleCardSubItemActive(e, 4)}
                >
                  <div className="bbox__title">In Yard</div>
                  <div className="bbox__number">19</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl col-md-3 col-sm-6 col-6 my-2">
          <div
            className={`ncard d-flex flex-column justify-content-between ${
              isCardActive === 3 ? "active" : ""
            }`}
            href="javascript:void(0)"
            onClick={(e) => handleCardActive(e, 3)}
          >
            <div className="d-flex align-items-center mb-10">
              <div
                className={`mr-1 sbox sbox--default ${
                  isCardActive === 3 ? "sbox--active" : ""
                }`}
              >
                69
              </div>
              <div className="font-medium">Container to Return</div>
              <div className="ml-auto">
                <IconAngleArrowRight />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between form-row">
              <div className="col-6">
                <div
                  className={`bbox bbox--default ${
                    isCardSubItemActive === 5 ? "bbox--active" : ""
                  } `}
                  onClick={(e) => handleCardSubItemActive(e, 5)}
                >
                  <div className="bbox__title">Ready to Return</div>
                  <div className="bbox__number">28</div>
                </div>
              </div>
              <div className="col-6">
                <div
                  className={`bbox bbox--default ${
                    isCardSubItemActive === 6 ? "bbox--active" : ""
                  } `}
                  onClick={(e) => handleCardSubItemActive(e, 6)}
                >
                  <div className="bbox__title">Not Ready</div>
                  <div className="bbox__number">39</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl col-md-3 col-sm-6 col-6 my-2">
          <div
            className={`ncard d-flex flex-column justify-content-between ${
              isCardActive === 4 ? "active" : ""
            }`}
            href="javascript:void(0)"
            onClick={(e) => handleCardActive(e, 4)}
          >
            <div className="d-flex align-items-center mb-10">
              <div
                className={`mr-1 sbox sbox--default ${
                  isCardActive === 4 ? "sbox--active" : ""
                }`}
              >
                75
              </div>
              <div className="font-medium">Dropped</div>
              <div className="ml-auto">
                <IconAngleArrowRight />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between form-row">
              <div className="col-6">
                <div
                  className={`bbox bbox--default ${
                    isCardSubItemActive === 7 ? "bbox--active" : ""
                  } `}
                  onClick={(e) => handleCardSubItemActive(e, 7)}
                >
                  <div className="bbox__title">In Yard</div>
                  <div className="bbox__number">42</div>
                </div>
              </div>
              <div className="col-6">
                <div
                  className={`bbox bbox--default ${
                    isCardSubItemActive === 8 ? "bbox--active" : ""
                  } `}
                  onClick={(e) => handleCardSubItemActive(e, 8)}
                >
                  <div className="bbox__title">At Customer</div>
                  <div className="bbox__number">33</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl col-md-3 col-sm-6 col-6 my-2">
          <div
            className={`ncard d-flex flex-column justify-content-between ${
              isCardActive === 5 ? "active" : ""
            }`}
            href="javascript:void(0)"
            onClick={() => handleCardActive(5)}
          >
            <div
              className={`bbox bbox--default mb-1 d-flex flex-row justify-content-start align-items-center ${
                isCardSubItemActive === 9 ? "bbox--active" : ""
              } `}
              onClick={(e) => handleCardSubItemActive(e, 9)}
            >
              <div className="bbox__number mr-15">42</div>
              <div className="bbox__title bbox__title--lg">Dispatched</div>
            </div>
            <div
              className={`bbox bbox--default mb-1 d-flex flex-row justify-content-start align-items-center ${
                isCardSubItemActive === 10 ? "bbox--active" : ""
              } `}
              onClick={(e) => handleCardSubItemActive(e, 10)}
            >
              <div className="bbox__number mr-15">14</div>
              <div className="bbox__title bbox__title--lg">Completed</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DispatcherCards;
