import {useState} from 'react';
import {
  IconDargUpload,
  IconSteering,
  IconTruck,
  IconUserAlt,
  IconChasis,
  IconRates,
  IconDriverRates,
  IconCheckCircle,
  IconDelete
} from "../../../../common/icons/index";
import Dropzone from "react-dropzone";

const DropzoneBox = ({ type, id, uploadedFiles, setUploadedFiles }) => {
  const fileUploader = (type,data) => {
    if (data) {
      let shallow = uploadedFiles;
      try {
        data.forEach((file) => shallow[type.replace(/\s+/g, '')].push(file))
        setUploadedFiles(shallow)
      } catch (error) {
        console.log(error)
      }
    }
  };

  const UploadedFileComponent = ({fileName}) => {
    return (
      <div className='my-1 d-flex justify-content-between align-items-center px-4 py-2' style={{ width: '96%', border: '1px solid #dbe2eb', borderRadius: '8px' }}>
        <div>
          <IconCheckCircle className='text-primary' />
          <span className='mx-2' style={{ fontWeight:'500'}}>{fileName}</span>
        </div>
        <IconDelete className='text-secondary' />
      </div>
    )
  }

  return (
    <div className="w-100" id={id}>
      <div className='my-1 d-flex align-items-center'>
        {
          type == 'Customers' && <IconUserAlt className='text-secondary' />
        }
        {
          type == 'Drivers' && <IconSteering className='text-secondary' />
        }
        {
          type == 'Trucks' && <IconTruck className='text-secondary' />
        }
        {
          type == 'Chassis' && <IconChasis className='text-secondary' />
        }
        {
          type == 'Rates' && <IconRates className='text-secondary' />
        }
        {
          type == 'Driver Rates' && <IconDriverRates className='text-secondary' />
        }
        <span className='mx-2 font-weight-bold'>{type}</span>
      </div>
      <UploadedFileComponent fileName='choosen1-file.xml' />
      <Dropzone
        className="border-0"
        onDrop={(acceptedFiles) => {
          fileUploader(type, acceptedFiles);
        }}
        disabled={false}
      >
        {({ getRootProps, getInputProps }) => (
          <section style={{ width: '96%' }}>
            <div className="dropzone" {...getRootProps()} style={{ height: '140px' }}>
              <div className="dz-message needsclick" tabIndex="0">
                <input {...getInputProps()} style={{ display: "none" }} />
                <div className="d-flex align-items-center justify-content-center">
                  <IconDargUpload className="dropzone__uploadimage" />
                  <div className="ml-30">
                    <div className="text-muted font-12 mb-10">
                      Drag & Drop your {type} list here or
                    </div>
                    <button className="btn btn-primary btn-sm" type="button">
                      Choose from your device...
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  )
}


const TabContent1 = () => {
  const [uploadedFiles, setUploadedFiles] = useState({
    Customers: [],
    Drivers: [],
    Trucks: [],
    Chassis: [],
    Rates: [],
    DriverRates: [],
  }); 
  
  
  const uploadChoosedFiles = () => {
    console.log(uploadedFiles)
    
    //api call
  }
  
  return (
    <div className="text-dark my-3 mt-5">
      <div className='w-100 px-4'>
        <p style={{ color: '#687D96' }}>Before that meeting, please share across files you may have with equipment information, driver details, customer and delivery location information, as well as your customer rates and driver pay rates.</p>
        <p style={{ color: '#687D96' }}>You can provide this information in any format, <b>excel, pdf, email, </b> you name it! With those files we will automatically set up your account so you don't have to spend anytime doing it!</p>
        <div className="w-100 d-flex justify-content-center my-3">
          <DropzoneBox type='Customers' id='zone1' uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} />
          <DropzoneBox type='Drivers' id='zone2' uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} />
        </div>
        <div className="w-100 d-flex justify-content-center my-3">
          <DropzoneBox type='Trucks' id='zone3' uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} />
          <DropzoneBox type='Chassis' id='zone4' uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} />
        </div>
        <div className="w-100 d-flex justify-content-center my-3">
          <DropzoneBox type='Rates' id='zone5' uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} />
          <DropzoneBox type='Driver Rates' id='zone6' uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} />
        </div><br/>
        <center>
          <button className='btn btn-primary px-5' onClick={uploadChoosedFiles}>Save</button>
        </center>
      </div>
    </div>
  )
}

export default TabContent1
