import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IconDocument, IconNotes, IconSteering } from '../../../common/icons';
import Documents from './tabComponents/Document';
import Driver from './tabComponents/Driver';
import Notes from './tabComponents/Notes';

const tabItems = [
  {
    id: 1,
    title: 'Driver',
    icon: IconSteering,
  },
  {
    id: 2,
    title: 'Documents',
    icon: IconDocument,
  },
  {
    id: 3,
    title: 'Notes',
    icon: IconNotes,
  },
];

const AddDriver = (props) => {
  const { showModal = false, onCancel = () => {}, onSubmit = () => {} } = props;
  const [isLoadTabActive, setIsLoadTabActive] = useState('Driver');

  const handleLoadTabActive = (i) => {
    setIsLoadTabActive(isLoadTabActive === i ? null : i);
  };
  return (
    <React.Fragment>
      <Modal show={showModal} size="xl" centered scrollable>
        <Modal.Header className="py-20">
          <Modal.Title>Add New Driver</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 h-100">
          <div className="card mb-0">
            <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom">
              {tabItems.map((item) => (
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      isLoadTabActive === item.title ? 'active' : ''
                    }`}
                    href="javascript:void(0)"
                    onClick={() => handleLoadTabActive(item.title)}
                  >
                    <item.icon
                      className={`wh-24px ${
                        isLoadTabActive === item.title ? 'text-primary' : ''
                      }`}
                    />
                    <div className="nav-link__text">{item.title}</div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="tab-content">
            {isLoadTabActive === 'Driver' && <Driver />}
            {isLoadTabActive === 'Documents' && <Documents />}
            {isLoadTabActive === 'Notes' && <Notes />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" onClick={onCancel}>
            Cancel
          </button>
          <button className="btn btn-success" onClick={onSubmit}>
            Add Driver
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default AddDriver;
