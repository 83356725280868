import React from 'react';
import { Icondots, IconTruck, IconCart } from '../../../common/icons';

const driver = [
  {
    id: 1,
    name: 'Jane Cooper',
    avatarName: 'JC',
    time: '08:00 AM - 5 November',
    price: '$202.87',
    status: 'Earned',
  },
  {
    id: 2,
    name: 'Jerome Bell',
    avatarName: 'JB',
    time: '08:00 AM - 5 November',
    price: '$475.22',
    status: 'Earned',
  },
  {
    id: 3,
    name: 'Ronald Richards',
    avatarName: 'RR',
    time: '08:00 AM - 5 November',
    price: '$767.50',
    status: 'Earned',
  },
  {
    id: 4,
    name: 'Robert Fox',
    avatarName: 'RF',
    time: '08:00 AM - 5 November',
    price: '$328.85',
    status: 'Earned',
  },
  {
    id: 5,
    name: 'Robert Fox',
    avatarName: 'RF',
    time: '08:00 AM - 5 November',
    price: '$328.85',
    status: 'Earned',
  },
  {
    id: 6,
    name: 'Robert Fox',
    avatarName: 'RF',
    time: '08:00 AM - 5 November',
    price: '$328.85',
    status: 'Earned',
  },
  {
    id: 7,
    name: 'Robert Fox',
    avatarName: 'RF',
    time: '08:00 AM - 5 November',
    price: '$328.85',
    status: 'Earned',
  },
  {
    id: 8,
    name: 'Robert Fox',
    avatarName: 'RF',
    time: '08:00 AM - 5 November',
    price: '$328.85',
    status: 'Earned',
  },
  {
    id: 9,
    name: 'Robert Fox',
    avatarName: 'RF',
    time: '08:00 AM - 5 November',
    price: '$328.85',
    status: 'Earned',
  },
  {
    id: 10,
    name: 'Robert Fox',
    avatarName: 'RF',
    time: '08:00 AM - 5 November',
    price: '$328.85',
    status: 'Earned',
  },
  {
    id: 11,
    name: 'Robert Fox',
    avatarName: 'RF',
    time: '08:00 AM - 5 November',
    price: '$328.85',
    status: 'Earned',
  },
  {
    id: 12,
    name: 'Robert Fox',
    avatarName: 'RF',
    time: '08:00 AM - 5 November',
    price: '$328.85',
    status: 'Earned',
  },
];

const DriverList = () => {
  return (
    <>
      <div className="card mb-20 p-20" style={{ height: '726px' }}>
        <div className="d-flex justify-content-between align-items-top pb-15">
          <div className="d-flex flex-column">
            <div className="font-16 font-weight-500 mb-1">
              Least Productive Drivers
            </div>
            <div className="d-flex flex-row ">
              <div className="text-muted">Based on Revenue</div>
            </div>
          </div>
          <div className="ml-auto">
              <Icondots className="text-muted" />
          </div>
        </div>
        {driver.map((drivers) => (
          <div className="d-flex justify-content-between align-items-center pb-20">
            <div className="d-flex">
              <div className="mr-10 align-self-center">
                <div className="avatar-circle ">
                  <div className="font-12"> {drivers.avatarName}</div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="font-12 mb-1 font-weight-500">
                  {drivers.name}
                </div>
                <div className="text-muted">{drivers.time}</div>
              </div>
            </div>
            <div className="d-flex flex-column align-items-end">
              <div className="text-accent-500 font-weight-500 text-accent-500">
                {' '}
                {drivers.price}
              </div>
              <div className="text-gray-500"> {drivers.status}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default DriverList;
