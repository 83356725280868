import React, { useState } from 'react';
import { IconDownload, IconPlus } from '../../../common/icons';
import Pagination from './common-elements/Pagination';
import CreateGroupModal from './ReportTabComponent/AddReportModal';
import StaticTableContent from './common-elements/StaticTableContent';
import ReportsTabs from '../common-elements/ReportsTabs';

const CustomMasterReport = () => {
  const [CustomReportModal, setCustomReportModal] = useState(false);

  const handleCustomReportShowModal = (event) => {
    setCustomReportModal(true);
  };

  const handleCustomReportHideModal = (event) => {
    setCustomReportModal(false);
  };

  return (
    <div className="position-relative subheader-wrapper">
      <ReportsTabs
        activeTab={9}
        buttons={
          <button
            className=" btn btn-success ml-2"
            onClick={handleCustomReportShowModal}
          >
            <IconPlus className="mr-2" />
            Add Custom Report
          </button>
        }
      />
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-15 d-flex align-items-center">
            <div className="d-flex align-items-center"></div>
            {/* Pagination  */}
            <Pagination className="ml-auto" />
          </div>
        </div>
        {/* Static Table Content */}
        <StaticTableContent />
        {CustomReportModal && (
          <CreateGroupModal
            onCancel={handleCustomReportHideModal}
            onSubmit={handleCustomReportHideModal}
          />
        )}
      </div>
    </div>
  );
};

export default CustomMasterReport;
