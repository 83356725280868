import React, { useState } from 'react';
import CustomSelect from '../../../../common/CustomSelect/index';

import {
  IconColumn,
  IconDownload,
  IconFilter,
  IconPen,
  IconPlus,
  IconTrash,
} from '../../../../common/icons/index';

const options = [
  { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
  { value: 'John Doe', label: 'John Doe' },
  { value: 'Alex Mendes', label: 'Alex Mendes' },
];

const columns = [
  'Charge name',
  'Unit of measure',
  'Description of Charge',
  'App price on demand',
  '$ per unit',
  'Min. total price',
  'Free Units',
];

const data = [];
for (let i = 0; i < 13; i++) {
  data.push({
    id: i,
    chargeName: `Detention`,
    unitOfMeasure: 'Per Hour',
    desc: 'TS LinesTS LinesTS LinesTS Lines',
    appPrice: 'Yes',
    perUnit: 0.25,
    minPrice: 150.0,
    freeUnits: 44500,
  });
}

const StaticTableContent = () => {
  const [isPopDrop, setIsPopDrop] = useState(false);

  const handlePopDrop = (event) => {
    setIsPopDrop(!isPopDrop);
  };
  return (
    <React.Fragment>
      {/* Search Filter */}
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>
        <div className="d-flex">
          <CustomSelect
            name={'name'}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Change name"
            className="w-150 z-2 mr-2"
          />
        </div>
        <div className="ml-auto d-flex">
          <button className="btn btn-outline-light mr-2">
            <IconFilter className="mr-2" />
            Filter
          </button>
          <div className="position-relative">
            <button className="btn btn-outline-light position-relative">
              <IconColumn />
            </button>
          </div>
        </div>
      </div>
      {/* Table Content */}
      <div
        className="table-responsive"
        style={{ height: 'calc(100vh - 248px)' }}
      >
        <table className="table table-card">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
              <th style={{ width: '2%' }}></th>
              <th style={{ width: '2%' }}></th>
              <th style={{ width: '2%' }}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((datum) => {
              return (
                <tr key={datum.id}>
                  <td>{datum.chargeName}</td>
                  <td>{datum.unitOfMeasure}</td>
                  <td>{datum.desc}</td>
                  <td>{datum.appPrice}</td>
                  <td className="text-right">${datum.perUnit}</td>
                  <td className="text-right">${datum.minPrice}</td>
                  <td className="text-right">{datum.freeUnits}</td>
                  <td>
                    <button className="btn btn-link">
                      <IconPen className="" />
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-link">
                      <IconDownload />
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-link">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
