import React, { useState } from 'react';

import {
  IconCancelCircle,
  IconCheckCircle,
  IconMinus,
  IconPen,
  IconPlus,
  IconTrash,
} from '../../../../../common/icons/index';
import EditFreeDay from './EditFreeDay';

const columns = [
  'Customer',
  'Owner',
  'Type',
  'Tier #1',
  'Tier #2',
  'Tier #3',
  'Holiday',
  'First Weekend',
  'Day',
];

const data = [
  {
    id: 1,
    customer: 'All',
    owner: 'Ocean Network Express',
    type: 'Open Sides',
    tiers: [
      {
        id: 1,
        days: '1-3 days',
        amount: 120.0,
      },
      {
        id: 2,
        days: '4-5 days',
        amount: 560.0,
      },
      {
        id: 3,
        days: 'From 6 days',
        amount: 1000.0,
      },
    ],
    holiday: 'Yes',
    firstWeekend: 'No',
  },
  {
    id: 2,
    customer: 'All',
    owner: 'Maersk Line',
    type: 'Maersk Line',
    tiers: [
      {
        id: 1,
        days: '1-3 days',
        amount: 120.0,
      },
      {
        id: 2,
        days: '4-5 days',
        amount: 560.0,
      },
      {
        id: 3,
        days: '',
        amount: '',
      },
    ],
    holiday: 'No',
    firstWeekend: 'Yes',
  },
  {
    id: 3,
    customer: 'All',
    owner: 'Salam Pacific Indonesia Lines',
    type: 'OT',
    tiers: [
      {
        id: 1,
        days: '1-3 days',
        amount: 120.0,
      },
      {
        id: 2,
        days: '4-5 days',
        amount: 560.0,
      },
      {
        id: 3,
        days: 'From 6 days',
        amount: 1000.0,
      },
    ],
    holiday: 'Yes',
    firstWeekend: 'No',
  },
  {
    id: 4,
    customer: 'All',
    owner: 'Korea Marine Transport Corporation',
    type: 'RF',
    tiers: [
      {
        id: 1,
        days: '1-3 days',
        amount: 120.0,
      },
      {
        id: 2,
        days: '4-5 days',
        amount: 560.0,
      },
      {
        id: 3,
        days: 'From 6 days',
        amount: 1000.0,
      },
    ],
    holiday: 'Yes',
    firstWeekend: 'No',
  },
  {
    id: 5,
    customer: 'All',
    owner: 'Sea Lead Shipping',
    type: 'HC',
    tiers: [
      {
        id: 1,
        days: '1-3 days',
        amount: 120.0,
      },
      {
        id: 2,
        days: '4-5 days',
        amount: 560.0,
      },
      {
        id: 3,
        days: 'From 6 days',
        amount: 1000.0,
      },
    ],
    holiday: 'Yes',
    firstWeekend: 'No',
  },
  {
    id: 6,
    customer: 'All',
    owner: 'SM Line',
    type: 'ST',
    tiers: [
      {
        id: 1,
        days: '1-3 days',
        amount: 120.0,
      },
      {
        id: 2,
        days: '4-5 days',
        amount: 560.0,
      },
      {
        id: 3,
        days: 'From 6 days',
        amount: 1000.0,
      },
    ],
    holiday: 'No',
    firstWeekend: 'Yes',
  },
  {
    id: 7,
    customer: 'All',
    owner: 'COSCO',
    type: 'Open Sides',
    tiers: [
      {
        id: 1,
        days: '1-3 days',
        amount: 120.0,
      },
      {
        id: 2,
        days: '4-5 days',
        amount: 560.0,
      },
      {
        id: 3,
        days: 'From 6 days',
        amount: 1000.0,
      },
    ],
    holiday: 'No',
    firstWeekend: 'No',
  },
  {
    id: 8,
    customer: 'All',
    owner: 'Global Feeder Shipping LLC',
    type: 'Tank of Cisterna',
    tiers: [
      {
        id: 1,
        days: '1-3 days',
        amount: 120.0,
      },
      {
        id: 2,
        days: '4-5 days',
        amount: 560.0,
      },
      {
        id: 3,
        days: 'From 6 days',
        amount: 1000.0,
      },
    ],
    holiday: 'No',
    firstWeekend: 'No',
  },
  {
    id: 9,
    customer: 'All',
    owner: 'Zhonggu Logistics Corporation',
    type: 'OT',
    tiers: [
      {
        id: 1,
        days: '1-3 days',
        amount: 120.0,
      },
      {
        id: 2,
        days: '4-5 days',
        amount: 560.0,
      },
      {
        id: 3,
        days: 'From 6 days',
        amount: 1000.0,
      },
    ],
    holiday: 'No',
    firstWeekend: 'No',
  },
  {
    id: 10,
    customer: 'All',
    owner: 'Mediterranean Shipping Company',
    type: 'RF',
    tiers: [
      {
        id: 1,
        days: '1-3 days',
        amount: 120.0,
      },
      {
        id: 2,
        days: '4-5 days',
        amount: 560.0,
      },
      {
        id: 3,
        days: 'From 6 days',
        amount: 1000.0,
      },
    ],
    holiday: 'Yes',
    firstWeekend: 'No',
  },
  {
    id: 11,
    customer: 'All',
    owner: 'Sinokor Merchant Marine',
    type: 'HC',
    tiers: [
      {
        id: 1,
        days: '1-3 days',
        amount: 120.0,
      },
      {
        id: 2,
        days: '4-5 days',
        amount: 560.0,
      },
      {
        id: 3,
        days: 'From 6 days',
        amount: 1000.0,
      },
    ],
    holiday: 'Yes',
    firstWeekend: 'No',
  },
  {
    id: 12,
    customer: 'All',
    owner: 'Shandong International Transportation Corporation',
    type: 'ST',
    tiers: [
      {
        id: 1,
        days: '1-3 days',
        amount: 120.0,
      },
      {
        id: 2,
        days: '4-5 days',
        amount: 560.0,
      },
      {
        id: 3,
        days: 'From 6 days',
        amount: 1000.0,
      },
    ],
    holiday: 'Yes',
    firstWeekend: 'No',
  },
  {
    id: 13,
    customer: 'All',
    owner: 'Ocean Network Express',
    type: 'Open Sides',
    tiers: [
      {
        id: 1,
        days: '1-3 days',
        amount: 120.0,
      },
      {
        id: 2,
        days: '4-5 days',
        amount: 560.0,
      },
      {
        id: 3,
        days: 'From 6 days',
        amount: 1000.0,
      },
    ],
    holiday: 'Yes',
    firstWeekend: 'No',
  },
  {
    id: 14,
    customer: 'All',
    owner: 'Ocean Network Express',
    type: 'Open Sides',
    tiers: [
      {
        id: 1,
        days: '1-3 days',
        amount: 120.0,
      },
      {
        id: 2,
        days: '4-5 days',
        amount: 560.0,
      },
      {
        id: 3,
        days: 'From 6 days',
        amount: 1000.0,
      },
    ],
    holiday: 'Yes',
    firstWeekend: 'No',
  },
];

const StaticTableContent = () => {
  const [editModal, setEditModal] = useState(false);

  const handleShowEditModal = (event) => {
    setEditModal(true);
  };

  const handleHideEditModal = (event) => {
    setEditModal(false);
  };

  return (
    <React.Fragment>
      {/* Table Content */}
      <div
        className="table-responsive"
        style={{ height: 'calc(100vh - 204px)' }}
      >
        <table className="table table-card table-card--ls">
          <thead>
            <tr>
              <th>Customer</th>
              <th style={{ width: '20%' }}>Owner</th>
              <th>Type</th>
              <th className="text-right">Tier #1</th>
              <th className="text-right">Tier #2</th>
              <th className="text-right">Tier #3</th>
              <th>Holiday</th>
              <th>First Weekend</th>
              <th>Day</th>
              <th style={{ width: '2%' }}></th>
              <th style={{ width: '2%' }}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((datum) => {
              return (
                <tr key={datum.id}>
                  <td>{datum.customer}</td>
                  <td>{datum.owner}</td>
                  <td>{datum.type}</td>
                  {datum.tiers.map((tier) => (
                    <td className="text-right" key={tier.id}>
                      {tier.days === '' && tier.amount === '' ? (
                        <div className="text-muted">N/A</div>
                      ) : (
                        <>
                          <div className="text-muted">{tier.days}</div>
                          <div className="font-weight-500 font-14 mt-1">
                            $ {tier.amount}
                          </div>
                        </>
                      )}
                    </td>
                  ))}
                  {datum.holiday === 'Yes' ? (
                    <td style={{ width: '6%' }} className="text-center">
                      <IconCheckCircle className="text-success mr-2" />
                      {datum.holiday}
                    </td>
                  ) : (
                    <td style={{ width: '6%' }} className="text-center">
                      <IconCancelCircle className="text-gray-50 mr-2" />
                      {datum.holiday}
                    </td>
                  )}
                  {datum.firstWeekend === 'Yes' ? (
                    <td style={{ width: '6%' }} className="text-center">
                      <IconCheckCircle className="text-success mr-2" />
                      {datum.firstWeekend}
                    </td>
                  ) : (
                    <td style={{ width: '6%' }} className="text-center">
                      <IconCancelCircle className="text-gray-50 mr-2" />
                      {datum.firstWeekend}
                    </td>
                  )}
                  <td style={{ width: '12%' }} className="text-center">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <button type="button" className="btn btn-outline-light">
                          <IconMinus />
                        </button>
                      </div>
                      <input
                        type="number"
                        className="form-control text-center"
                        readOnly
                        placeholder="0"
                      />
                      <div className="input-group-append">
                        <button type="button" className="btn btn-outline-light">
                          <IconPlus />
                        </button>
                      </div>
                    </div>
                  </td>
                  <td>
                    <button
                      className="btn btn-link"
                      onClick={handleShowEditModal}
                    >
                      <IconPen className="text-muted" />
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-link">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* Edit Modal */}
      {editModal && (
        <EditFreeDay
          onCancel={handleHideEditModal}
          onSubmit={handleHideEditModal}
        />
      )}
    </React.Fragment>
  );
};
export default StaticTableContent;
