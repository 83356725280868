import React from 'react';
import { Modal } from 'react-bootstrap';
import {
  IconDownload,
  IconDrag,
  IconPen,
  IconPlus,
  IconInfoCircleFilled,
  IconWarningCircle,
  IconTrash,
} from '../../../common/icons';

const AddUploadModal = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;
  return (
    <Modal show={showModal} centered animation={false} size="md">
      <Modal.Header>
        <Modal.Title>
          <h6 className="font-20 mb-0">Match Fields</h6>
          <span className="text-muted font-12 font-normal">
            We automatically match some column titles. You can reorder them.
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="ml-auto mb-15">
          <IconWarningCircle className="mr-10 text-danger" />
          <span className="text-danger font-weight-500 font-14 ">
            Unrecognized column titles
          </span>
        </div>
        <div className="d-flex mb-1">
          <button className="mr-1 btn btn-primary ">
            <IconDrag className="mr-2" />
            Column Title 2
          </button>
          <button className="mr-1 btn btn-primary">
            <IconDrag className="mr-2" />
            Column Title 2
          </button>
          <button className=" btn btn-primary">
            <IconDrag className="mr-2" />
            Column Title 2
          </button>
        </div>
        <div className="d-flex mb-25">
          <button className="mr-1 btn btn-primary ">
            <IconDrag className="mr-2" />
            Column Title 2
          </button>
          <button className="mr-1 btn btn-primary">
            <IconDrag className="mr-2" />
            Column Title 2
          </button>
          <button className="mr-1 btn btn-primary">
            <IconDrag className="mr-2" />
            Column Title 2
          </button>
          <button className="mr-2 btn btn-primary">
            <IconDrag className="mr-2" />
            Column Title 2
          </button>
        </div>
        <div className="hr-light mb-25"></div>
        <form>
          <div className="form-group mb-1 form-row">
            <label className="col-md-5 col-form-label d-flex align-items-center">
              Container
            </label>
            <div className="col-md-7">
              <div className="d-flex ">
                <div className="dropzone dropzone--sm dropzone--blue p-1 align-items-center justify-content-center mr-10">
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="d-flex align-items-center">
                      <button className=" btn btn-primary ">
                        <IconDrag className="mr-2" />
                        Column Title 2
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <IconTrash className="wh-20" />
                </div>
              </div>
            </div>
          </div>
          <div className="form-group mb-1 form-row">
            <label className="col-md-5 col-form-label d-flex align-items-center">
              Chassis #
            </label>
            <div className="col-md-7">
              <div className="dropzone dropzone--sm dropzone--blue align-items-center justify-content-center">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="d-flex align-items-center">
                    <IconPlus className="text-primary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group mb-1 form-row">
            <label className="col-md-5 col-form-label d-flex align-items-center">
              Completed Date
            </label>
            <div className="col-md-7">
              <div className="dropzone dropzone--sm dropzone--blue align-items-center justify-content-center">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="d-flex align-items-center">
                    <IconPlus className="text-primary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group mb-1 form-row">
            <label className="col-md-5 col-form-label d-flex align-items-center">
              $ Per Day
            </label>
            <div className="col-md-7">
              <div className="dropzone dropzone--sm dropzone--blue align-items-center justify-content-center">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="d-flex align-items-center">
                    <IconPlus className="text-primary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group mb-1 form-row">
            <label className="col-md-5 col-form-label d-flex align-items-center">
              Load Status
            </label>
            <div className="col-md-7">
              <div className="dropzone dropzone--sm dropzone--blue align-items-center justify-content-center">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="d-flex align-items-center">
                    <IconPlus className="text-primary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group mb-1 form-row">
            <label className="col-md-5 col-form-label d-flex align-items-center">
              Arrived Pickup Date
            </label>
            <div className="col-md-7">
              <div className="dropzone dropzone--sm dropzone--blue align-items-center justify-content-center">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="d-flex align-items-center">
                    <IconPlus className="text-primary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group mb-1 form-row">
            <label className="col-md-5 col-form-label d-flex align-items-center">
              Used Days
            </label>
            <div className="col-md-7">
              <div className="dropzone dropzone--sm dropzone--blue align-items-center justify-content-center">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="d-flex align-items-center">
                    <IconPlus className="text-primary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group mb-1 form-row">
            <label className="col-md-5 col-form-label d-flex align-items-center">
              $ Total
            </label>
            <div className="col-md-7">
              <div className="dropzone dropzone--sm dropzone--blue align-items-center justify-content-center">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="d-flex align-items-center">
                    <IconPlus className="text-primary" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-success" onClick={onSubmit}>
          <a href="/audit-document" className="text-white">
            Confirm
          </a>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddUploadModal;
