import React, { useState } from "react";
import TabComponent from "../../../common/tabComponent";

const tabItems = [
  {
    id: 1,
    title: "Driver Pay",
    path: "/accountpayable/driver-pay",
  },
  {
    id: 2,
    title: "Driver Settlements",
    path: "/accountpayable/driver-settlements",
  },
  {
    id: 3,
    title: "Dynamic Expenses",
    path: "/accountpayable/dynamic-pricing",
  },
];

const AccountPayableTabs = ({ activeTab, button }) => {
  const [tabActive, setTabActive] = useState(activeTab);
  return (
    <div className="d-flex flex-row align-items-center justify-content-between w-100">
      {/*  Sub Header Tabs*/}
      <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
        {tabItems.map(({ id, title, path }) => (
          <TabComponent
            key={title}
            title={title}
            path={path}
            onItemClicked={() => setTabActive(id)}
            isActive={tabActive === id}
          />
        ))}
      </ul>

      {/* CTA BUTTON */}
      {button}
    </div>
  );
};

export default AccountPayableTabs;
