import React, { useState } from "react";
import {
  IconBilling,
  IconCalendar,
  IconCheckCircleDash,
  IconClock9,
  IconClone,
  IconDeliveryClockWise,
  IconHot,
  IconInfoFocus,
  IconMapMarkerLoad,
  IconNoteClip,
  IconPrinter,
  IconReturnCheck,
} from "../icons";

const BatchActionLoads = (props) => {
  const [showPopOver, setshowPopOver] = useState(false);
  const handlePopOver = (i, e) => {
    e.preventDefault();
    setshowPopOver(showPopOver === i ? null : i);
  };
  return (
    <React.Fragment>
      <div className="batch-menu mx-auto d-flex justify-content-between px-15">
        <div className="d-flex align-items-center">
          <div className="avatar-circle avatar-sm bg-white mr-10">5</div>
          <div className="text-gray-200">Selected</div>
        </div>
        <div className="d-flex">
          <div className="position-relative">
            <div
              className={`batch-menu__item ${showPopOver === 1 ? "active" : ''}`}
              onClick={(e) => handlePopOver(1, e)}
            >
              <IconCheckCircleDash className="batch-menu__itemicon" />
              <div className="batch-menu__itemtext">Ready For Return</div>
            </div>
          </div>
          <div className="position-relative">
            <div
              className={`batch-menu__item ${showPopOver === 2 ? "active" : ''}`}
              onClick={(e) => handlePopOver(2, e)}
            >
              <IconInfoFocus className="batch-menu__itemicon" />
              <div className="batch-menu__itemtext">Load Info</div>
            </div>
          </div>
          <div className="position-relative">
            <div
              className={`batch-menu__item ${showPopOver === 3 ? "active" : ''}`}
              onClick={(e) => handlePopOver(3, e)}
            >
              <IconClock9 className="batch-menu__itemicon" />
              <div className="batch-menu__itemtext">Load Time</div>
            </div>
          </div>
          <div className="position-relative">
            <div
              className={`batch-menu__item ${showPopOver === 4 ? "active" : ''}`}
              onClick={(e) => handlePopOver(4, e)}
            >
              <IconDeliveryClockWise className="batch-menu__itemicon" />
              <div className="batch-menu__itemtext">Delivery</div>
            </div>
          </div>
          <div className="position-relative">
            <div
              className={`batch-menu__item ${showPopOver === 5 ? "active" : ''}`}
              onClick={(e) => handlePopOver(5, e)}
            >
              <IconMapMarkerLoad className="batch-menu__itemicon" />
              <div className="batch-menu__itemtext">Pickup</div>
            </div>
            {showPopOver === 5 && (
              <div className="card card--shadow-5 p-15 popdrop popdrop--hcenter popdrop--top mb-10 w-200">
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="From"
                  />
                  <span className="input-icon">
                    <IconCalendar />
                  </span>
                </div>
                <div className="form-group mb-15">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="To"
                  />
                  <span className="input-icon">
                    <IconCalendar />
                  </span>
                </div>
                <button className="btn btn-primary btn-block">
                  Add Return Times
                </button>
              </div>
            )}
          </div>
          <div className="position-relative">
            <div
              className={`batch-menu__item ${showPopOver === 6 ? "active" : ''}`}
              onClick={(e) => handlePopOver(6, e)}
            >
              <IconReturnCheck className="batch-menu__itemicon" />
              <div className="batch-menu__itemtext">Return</div>
            </div>
          </div>
          <div className="position-relative">
            <div
              className={`batch-menu__item ${showPopOver === 7 ? "active" : ''}`}
              onClick={(e) => handlePopOver(7, e)}
            >
              <IconNoteClip className="batch-menu__itemicon" />
              <div className="batch-menu__itemtext">Reference</div>
            </div>
          </div>
          <div className="position-relative">
            <div
              className={`batch-menu__item ${showPopOver === 8 ? "active" : ''}`}
              onClick={(e) => handlePopOver(8, e)}
            >
              <IconHot className="batch-menu__itemicon" />
              <div className="batch-menu__itemtext">Haz Hot</div>
            </div>
          </div>
          <div className="position-relative">
            <div
              className={`batch-menu__item ${showPopOver === 9 ? "active" : ''}`}
              onClick={(e) => handlePopOver(9, e)}
            >
              <IconClone className="batch-menu__itemicon" />
              <div className="batch-menu__itemtext">Copy Container</div>
            </div>
          </div>
          <div className="position-relative">
            <div
              className={`batch-menu__item ${showPopOver === 10 ? "active" : ''}`}
              onClick={(e) => handlePopOver(10, e)}
            >
              <IconPrinter className="batch-menu__itemicon" />
              <div className="batch-menu__itemtext">Print</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BatchActionLoads;
