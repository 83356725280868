import { useState } from 'react';
import { IconPlus, IconDrayos } from '../../common/icons';
import SettingsTab from '../settings/common-elements/SettingsTabs';
import ContainerTrackingtTable from './ContainerTrackingtTable';

const ContainerTracking = () => {
  return (
    <>
      <div className="position-relative subheader-wrapper">
        <div className="subheader">
          <div className="subheader__inner d-flex align-items-center py-0">
            <SettingsTab
              activeTab={15}
              button={
                <div className="d-flex">
                  <button className=" btn btn-success ">
                    <IconPlus className="mr-2" />
                    Add Terminal
                  </button>
                </div>
              }
            />
          </div>
        </div>
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-2 d-flex align-items-center ">
              <h4 className="font-20 mb-0 ">
                1 Port Enabled for Container Tracking
              </h4>
            </div>
          </div>
          {/* //table  part here first table here  */}
          <ContainerTrackingtTable />
          <div className="mt-90 rounded-5 bg-primary ">
            <div className="d-flex justify-content-center align-items-center">
              <div className="d-flex justify-content-between align-items-center py-10">
                <IconDrayos />
                <div className="ml-20">
                  <div className="font-16 font-weight-500 text-white">
                    Upgrade to drayOS Track for a better Trucking Experience{' '}
                  </div>
                  <div className="font-12 mt-1 font-weight-400 text-white">
                    You can add{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContainerTracking;
