import React, { useState } from "react";
import { IconCalendar } from "../../../../common/icons/index";
import CustomSelect from "../../../../common/CustomSelect";
import Pagination from "../../../../common/pagination";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const TopPage = () => {
  return (
    <div className="top-page">
      <div className="d-flex align-items-center mb-15">
        <h3 className="font-20 mb-0">53 Reports</h3>
        <Pagination className="ml-auto" />
      </div>
      <div className="filter-row d-flex align-items-center">
        <div className="form-group mb-0 input-wrapper mr-2">
          <input type="text" className="form-control" placeholder="From - To" />
          <div className="input-icon">
            <IconCalendar />
          </div>
        </div>
        <CustomSelect
          placeholder="Select Driver.."
          className="w-150"
          size="small"
          options={options}
        />
      </div>
    </div>
  );
};

export default TopPage;
