import React, { useState } from 'react';
import { IconPlus } from '../../../common/icons';
import BillingTabs from '../common-elements/BillingTabs';
import SearchFilter from './elements/SearchFilter';
import PaymentModal from './elements/PaymentModal';
import StaticTableContent from './elements/StaticTableContent';
import TopPage from './elements/TopPage';

const BatchApplyPayments = () => {
  const [tabActive, setTabActive] = useState(1);
  const [showModal, setShowModal] = useState(false);

  const handleTabActive = (i) => {
    setTabActive(i);
  };

  const handleShowModal = (event) => {
    setShowModal(true);
  };

  const handleHideModal = (event) => {
    setShowModal(false);
  };

  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <BillingTabs activeTab={2} />
        </div>
      </div>
      <div className="page-fluid">
        <TopPage />
        <SearchFilter />
        <StaticTableContent />
      </div>

      {/* App Payment Modal */}
      {showModal && (
        <PaymentModal onCancel={handleHideModal} onSubmit={handleHideModal} />
      )}
    </div>
  );
};

export default BatchApplyPayments;
