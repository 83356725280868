import React, { useState } from 'react';
import {
  IconFilter,
  IconFireCircle,
  IconMapMarkerCircle,
  IconReset,
  IconSnowFlake,
  IconSnowFlakeCircle,
  IconTags,
  IconTimesThin,
  IconTwoBarsCircle,
  IconWarningCircle,
  IconWeightToolCircle,
} from '../../../common/icons';
import CustomSelect from '../../../common/CustomSelect';

const FilterPop = (props) => {
  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];
  const { hideFilter } = props;
  const [showFilterTabs, setshowFilterTabs] = useState(1);
  const [subCardActive, setsubCardActive] = useState(false);

  const handleSubCardActive = (i) => {
    setsubCardActive(!subCardActive);
  };

  const handleFilterTabs = (i) => {
    setshowFilterTabs(showFilterTabs === i ? null : i);
  };
  return (
    <React.Fragment>
      <div className="bdpopup-backdrop"></div>
      <div className="bdpopup">
        <div className="bdpopup__content">
          <button
            className="btn btn-none bdpopup__close text-white"
            onClick={hideFilter}
          >
            <IconTimesThin className="close-icon" />
          </button>
          <div className="d-flex px-30 pt-20 pb-20 align-items-center justify-content-between pt-20">
            <p className="font-20 mb-0">Filters</p>
            <button className="btn btn-outline-light btn-sm mr-2">
              <IconReset className="mr-2" />
              Reset
            </button>
          </div>
          <React.Fragment>
            <div
              className="px-30 bdpopup__content__scroll"
              style={{ height: 'calc(100% - 182px)' }}
            >
              <React.Fragment>
                <div className="form-group form-row mt-10">
                  <label className="col-md-5 col-form-label">Customer</label>
                  <div className="col-md-7">
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder=" select..."
                    />
                  </div>
                </div>
                <div className="form-group form-row mt-10">
                  <label className="col-md-5 col-form-label">Port</label>
                  <div className="col-md-7">
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder=" select..."
                    />
                  </div>
                </div>
                <div className="form-group form-row mt-10">
                  <label className="col-md-5 col-form-label">Zip</label>
                  <div className="col-md-7">
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder=" select..."
                    />
                  </div>
                </div>
                <div className="form-group form-row mt-10">
                  <label className="col-md-5 col-form-label">Created by</label>
                  <div className="col-md-7">
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder=" select..."
                    />
                  </div>
                </div>
                <div className="form-group form-row mt-10">
                  <label className="col-md-5 col-form-label">Status</label>
                  <div className="col-md-7">
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder=" select..."
                    />
                  </div>
                </div>
              </React.Fragment>
              <div className="hr-light"></div>
              <React.Fragment>
                <div className="form-group form-row mt-10">
                  <label className="col-md-5 col-form-label">
                    Container Type
                  </label>
                  <div className="col-md-7">
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder=" select..."
                    />
                  </div>
                </div>
                <div className="form-group form-row mt-10">
                  <label className="col-md-5 col-form-label">
                    Container Size
                  </label>
                  <div className="col-md-7">
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder=" select..."
                    />
                  </div>
                </div>
              </React.Fragment>
              <div className="hr-light"></div>
              <React.Fragment>
                <div className="form-group form-row mt-10">
                  <label className="col-md-5 col-form-label">Sale Agent</label>
                  <div className="col-md-7">
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder=" select..."
                    />
                  </div>
                </div>
              </React.Fragment>
            </div>
          </React.Fragment>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FilterPop;
