import React from 'react';



const Dot = ({ active }) => {
  const style = { height: '25px', width: '25px', border:'3px solid #ed8162', top:'7px', position:'relative' }
  return (
    <div className='dot rounded-circle' style={active ? style : { ...style, border:'3px solid #ccd6e1'}}></div>
  )
}

const Index = () => {
  return (
    <div class="pt-4 px-4 d-flex flex-column justify-content-center align-items-center">
      <div className='steps w-100 d-flex justify-content-center align-items-center'>
        <div className='d-flex flex-column align-items-end' id='node1'>
          <Dot active={true} />
          <span id='span1'>CHOOSE TIME</span>
        </div>
        <div id='line'></div>
        <div id='node2'>
          <Dot active={false} />
          <span id='span2'>YOUR INFO</span>
        </div>
      </div><br/><br/>
      <span id='calendar_desc'>Wondering why certain dates and times are not available to book?
        <span style={{ color:'#1f7998'}}> Troubleshoot your calendar.</span><br/>
        <center>Don't worry, your prospects won't see this message.</center>
      </span>
      <iframe
        className='border-0 calendar'
        src='https://meetings.hubspot.com/toni-careccio/drayos-demo?embed=true'
      ></iframe>
   </div>
  )
}

export default Index
