import React from "react";

const TopPage = () => {
  return (
    <div className="top-page">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h4 className="font-20 mb-0">32 Customers</h4>
      </div>
    </div>
  );
};

export default TopPage;
