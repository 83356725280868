import React, { useState } from "react";
import { IconDownload, IconPlus } from "../../../common/icons";
import Pagination from "../../../common/pagination/index";
import CounterCards from "../common-elements/CounterCards";
import SubHeader from "../common-elements/SubHeader";
import StaticTableContent from "./elements/StaticTableContent";

const cardItems = [
  {
    id: 1,
    count: 540,
    title: "Line Rates",
  },
  {
    id: 2,
    count: 703,
    title: "ZIP code rates",
  },
];

const CustomerRatesSettings = () => {
  const [isCardActive, setCardActive] = useState(1);
  const [showAddModal, setShowAddModal] = useState(false);

  const handleShowAddModal = (event) => {
    setShowAddModal(true);
  };

  const handleHideAddModal = (event) => {
    setShowAddModal(false);
  };

  return (
    <div className="position-relative subheader-wrapper">
      <SubHeader
        mainTitle="Customer Rates"
        button={
          <div className="d-flex">
            <button
              className="ml-auto btn btn-success"
              onClick={handleShowAddModal}
            >
              <IconPlus className="mr-2" />
              Add New Customer Rate
            </button>
          </div>
        }
      />
      <div className="page-fluid">
        <div className="top-page">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="font-20 mb-0">23 Rates</h4>
            <Pagination />
          </div>

          {/* Cards Row */}
          <div className={`form-row ${isCardActive ? "hasactive" : ""}`}>
            {cardItems.map(({ id, title, count }) => (
              <CounterCards
                title={title}
                count={count}
                onCardClicked={() => setCardActive(id)}
                isCardActive={isCardActive === id}
              />
            ))}
          </div>
        </div>

        {/* Static Table Content */}
        <StaticTableContent />

      </div>
    </div>
  );
};

export default CustomerRatesSettings;
