import React from 'react';
import CustomSelect from '../../../../common/CustomSelect';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const Filter = () => {
  return (
    <>
      <div className="title d-flex justify-content-center align-items-center  pt-60 pb-50 font-20 font-weight-500 text-dark">
        I want to see all results based on
      </div>
      <div className="form-wrapper px-30 pb-30 ">
        <form>
          <div className="form-row ">
            <div className="form-group col-md">
              <CustomSelect
                name={'name'}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="select the filter"
              />
            </div>
          </div>
          <div className="text-primary ">+ Add Filter</div>
        </form>
      </div>
    </>
  );
};

export default Filter;
