import React from "react";
import { IconCalendar } from "../../../../common/icons";
import Pagination from "../../../../common/pagination/index";

const tableData = [];
for (let i = 0; i < 20; i++) {
  tableData.push({
    id: i,
    status: "Pending",
    lastFreeDays: "18/09/2016",
    load: "POP2_M101658",
    container: "ONEU1025845",
    purchaseOrder: "-",
    pickupBy: "16/08/2013",
    dayTotal: 2,
    weekTotal: 2,
    deliveryLocation: "2972 Westheimer Rd. Santa Ana, Illinois 85486 ",
    deliveryAppt: "16/08/2013",
    hook: "16/08/2013",
    returned: "16/08/2013",
  });
}
const LoadDetails = () => {
  return (
    <div className="page-fluid">
      <div className="filter-row d-flex align-items-center mt-2">
        <div className="d-flex mr-15">
          <div className="input-wrapper mr-1">
            <input
              className="form-control w-150"
              type="text"
              placeholder="From"
            />
            <span className="input-icon">
              <IconCalendar />
            </span>
          </div>
          <div className="input-wrapper">
            <input
              className="form-control w-150"
              type="text"
              placeholder="To"
            />
            <span className="input-icon">
              <IconCalendar />
            </span>
          </div>
        </div>
        <div className="ml-auto">
          <Pagination />
        </div>
      </div>
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 212px)" }}
      >
        <table className="table table-card table-card--ls">
          <thead>
            <tr>
              <th>Status</th>
              <th className="text-center">Last Free Days</th>
              <th>Load #</th>
              <th>Container</th>
              <th>PO#</th>
              <th>Pick Up By</th>
              <th>Day Total</th>
              <th>Week Total</th>
              <th>Delivery Location</th>
              <th className="text-center">Delivery Appt</th>
              <th className="text-center">Hook to Container</th>
              <th className="text-center">Returned</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((tdata) => {
              return (
                <tr key={tdata.id}>
                  <td>{tdata.status}</td>
                  <td className="text-center">{tdata.lastFreeDays}</td>
                  <td>{tdata.load}</td>
                  <td>{tdata.container}</td>
                  <td>{tdata.purchaseOrder}</td>
                  <td>{tdata.pickupBy}</td>
                  <td>{tdata.dayTotal}</td>
                  <td>{tdata.weekTotal}</td>
                  <td>{tdata.deliveryLocation}</td>
                  <td className="text-center">{tdata.deliveryAppt}</td>
                  <td className="text-center">{tdata.hook}</td>
                  <td className="text-center">{tdata.returned}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LoadDetails;
