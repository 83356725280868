import React, { useState } from 'react';
import SubHeader from '../subheader';
import Pagination from '../common-elements/Pagination';
import StaticTableContent from './StaticTableContent';
import DeductionDurationModal from './DeductionDurationModal';

const DriverDeductionRates = () => {
  const [DeductionshowModal, setDeductionShowModal] = useState(false);

  const handleDeductionShowModal = (event) => {
    setDeductionShowModal(true);
  };

  const handleDeductionHideModal = (event) => {
    setDeductionShowModal(false);
  };
  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <div className="subheader">
          <div className="subheader__inner d-flex align-items-center py-0">
            <SubHeader
              activeTab={5}
              button={
                <div className="d-flex">
                  <button
                    className="ml-auto btn btn-outline-primary"
                    onClick={handleDeductionShowModal}
                  >
                    Edit Duration Settings
                  </button>
                </div>
              }
            />
          </div>
        </div>
        <div className="page-fluid">
          <div className="pb-15 d-flex align-items-center justify-content-between">
            <div className="">
              <h4 className="font-20 mb-0">55 Driver Deductions</h4>
              <div className="text-muted font-12">Duration: 14 Days</div>
            </div>
            <Pagination />
          </div>
          <StaticTableContent />
        </div>
        {DeductionshowModal && (
          <DeductionDurationModal
            onCancel={handleDeductionHideModal}
            onSubmit={handleDeductionHideModal}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default DriverDeductionRates;
