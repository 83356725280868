import { Modal } from "react-bootstrap";
import { IconCheck, IconReward, IconTimes } from "../../../../common/icons";

const plans = [
    {
        title: "Track income # expenses",
        isAvailable: true
    },
    {
        title: "Invoice # Accept Payments",
        isAvailable: true
    },
    {
        title: "Maximaze Tax Deuctions",
        isAvailable: true
    },
    {
        title: "Track income # expenses",
        isAvailable: true
    },
    {
        title: "Track income # expenses",
        isAvailable: true
    },
    {
        title: "Invoice # Accept Payments",
        isAvailable: true
    },
    {
        title: "Maximaze Tax Deuctions",
        isAvailable: true
    },
    {
        title: "Track income # expenses",
        isAvailable: true
    },
    {
        title: "Invoice # Accept Payments",
        isAvailable: false
    },

    {
        title: "Maximaze Tax Deuctions",
        isAvailable: false
    },
    {
        title: "Track income # expenses",
        isAvailable: false
    },
    {
        title: "Invoice # Accept Payments",
        isAvailable: false
    },


]
const PlanModal = (props) => {
    const { showModal, hideModal } = props;
    return (
        <Modal show={showModal} centered animation={false} size="xl">
            <Modal.Header className="d-block text-center bg-gray-50">
                <Modal.Title>Select Plan</Modal.Title>
                <div className="text-muted mt-1">
                    Select Plan that fits your needs
                </div>
            </Modal.Header>
            <Modal.Body className="px-60 bg-gray-50">
                {/* Subscription Tab */}
                <div className="d-flex flex-row align-items-center justify-content-center">
                    <ul className="nav nav-compact" role="tablist">
                        <li className="nav-item">
                            <a
                                className="nav-link active"
                                data-bs-toggle="tab"
                                href="#monthly"
                                role="tab"
                            >
                                Monthly
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#annually"
                                role="tab"
                            >
                                Annually
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="d-flex flex-column align-items-center mt-30">
                    <div className="w-30">
                        <div className="bg-primary h-100 p-20 rounded-top-6 text-white">
                            <div className="font-20 font-weight-500 mb-10">
                                Premium
                            </div>
                            <h4 className="font-16 text-white mb-10">499.00
                                <span className="text-brand-100 font-12"> / month</span>
                            </h4>
                            <button className="btn btn-lg btn-white w-100 justify-content-center">Get Professional plan</button>
                            <div className="d-flex align-items-baseline mt-10">
                                <span className="mr-15">
                                    <IconReward className="text-brand-100" />
                                </span>
                                <p className="text-brand-100">Over 50 customers chose this plan in 2021!</p>
                            </div>
                        </div>
                        {/* Plans List */}
                        <div className="bg-white p-25 rounded-bottom-6">
                            {plans.map((plan, i) =>
                                <div className={`d-flex mb-15 ${!plan.isAvailable ? "o-20" : null}`} key={i}>
                                    {
                                        plan.isAvailable ?
                                            <IconCheck className="text-primary mr-15" />
                                            :
                                            <IconTimes className="text-gray-900 mr-15" />
                                    }
                                    {plan.title}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-light mr-10 py-10 px-15" onClick={hideModal}>
                    Back
                </button>
                <a href="/trackos/subscription">
                    <button className="btn btn-primary py-10 px-15">Continue</button>
                </a>
            </Modal.Footer>
        </Modal>
    )
}

export default PlanModal;