import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomSelect from "../../../../common/CustomSelect";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const AddNewRates = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;
  const [tabActive, setTabActive] = useState(2);

  const handleTabActive = (i) => {
    setTabActive(i);
  };

  return (
    <Modal show={showModal} centered animation={false}>
      <Modal.Header>
        <Modal.Title>Add New Rates</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="form-wrapper">
          <form>
            <div className="form-row">
              <h5 className="mb-15 col-md-12">
                Set default route for this rate
              </h5>
              <div className="col-md-12">
                <div className="form-check form-group">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="prepull-drop-hook"
                    name="pre-order-status"
                  />
                  <label className="form-check-label" for="prepull-drop-hook">
                    Prepull + Drop & Hook
                  </label>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-check form-group">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="prepull-live-unload"
                    name="pre-order-status"
                  />
                  <label className="form-check-label" for="prepull-live-unload">
                    Prepull + Live Unload
                  </label>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-check form-group">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="pickrun-drop-hook"
                    name="pre-order-status"
                  />
                  <label className="form-check-label" for="pickrun-drop-hook">
                    Pick and Run + Drop & Hook
                  </label>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-check form-group">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="one-way-move"
                    name="pre-order-status"
                  />
                  <label className="form-check-label" for="one-way-move">
                    One Way Move
                  </label>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-check form-group">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="prepull-drop-hook"
                    name=""
                  />
                  <label className="form-check-label" for="">
                    Pick and Run + Live Unload
                  </label>
                </div>
              </div>
            </div>
            {/* Tabs */}
            <div className="d-flex flex-row align-items-center justify-content-between my-2">
              <ul className="nav nav-compact bg-gray-50 w-100" role="tablist">
                <li className="nav-item w-50 text-center">
                  <a
                    className={`nav-link ${tabActive === 1 ? "active" : ""}`}
                    data-bs-toggle="tab"
                    href="#Loads"
                    role="tab"
                    onClick={() => handleTabActive(1)}
                  >
                    Lane
                  </a>
                </li>
                <li className="nav-item w-50 text-center">
                  <a
                    className={`nav-link ${tabActive === 2 ? "active w-100" : ""}`}
                    data-bs-toggle="tab"
                    href="#payments"
                    role="tab"
                    onClick={() => handleTabActive(2)}
                  >
                    ZIP Code
                  </a>
                </li>
              </ul>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label"> <span className="text-danger">*</span>Customer</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select Customer"
                  className="w-100"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label"> <span className="text-danger">*</span>Port/Shipper</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select Port/Shipper"
                  className="w-100"
                />
                <div className="text-muted mt-2">Multiselection</div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label className="col-form-label"> <span className="text-danger">*</span>City</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select City"
                  className="w-100"
                />
              </div>
              <div className="form-group col-md-3">
                <label className="col-form-label"> <span className="text-danger">*</span>State</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select State"
                  className="w-100"
                />
              </div>
              <div className="form-group col-md-3">
                <label className="col-form-label"> <span className="text-danger">*</span>ZIP</label>
                <input className="form-control" placeholder="Enter Zip" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label"> <span className="text-danger">*</span>Customer</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select Customer"
                  className="w-100"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label"> <span className="text-danger">*</span>Port/Shipper</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select Port/Shipper"
                  className="w-100"
                />
                <div className="text-muted mt-2">Multiselection</div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label className="col-form-label"> <span className="text-danger">*</span>City</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select City"
                  className="w-100"
                />
              </div>
              <div className="form-group col-md-3">
                <label className="col-form-label"> <span className="text-danger">*</span>State</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select State"
                  className="w-100"
                />
              </div>
              <div className="form-group col-md-3">
                <label className="col-form-label"> <span className="text-danger">*</span>ZIP</label>
                <input className="form-control" placeholder="Enter Zip" />
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-success" onClick={onSubmit}>
            Add Rate
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewRates;
