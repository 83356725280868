import React from "react";
import { IconCalendar } from "../../../../common/icons/index";
import CustomSelect from "../../../../common/CustomSelect";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const SearchFilter = () => {
  return (
    <div className="filter-row d-flex align-items-center mt-2">
      <div className="d-flex">
        <div className="input-wrapper w-200">
          <input className="form-control" placeholder="From - To" />
          <div className="input-icon">
            <IconCalendar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchFilter;
