import React from 'react';
import { Modal } from 'react-bootstrap';
import CustomSelect from '../../../../../common/CustomSelect';

const options = [
  { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
  { value: 'John Doe', label: 'John Doe' },
  { value: 'Alex Mendes', label: 'Alex Mendes' },
];

const AddCustomerModal = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;
  return (
    <Modal show={showModal} centered animation={false} size="md">
      <Modal.Header>
        <Modal.Title>Add New Customer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex mb-4">
          <div className="form-check form-check-primary pr-30 ml-1">
            <input className="form-check-input" type="checkbox" id="customer" />
            <label className="form-check-label" for="customer">
              Customer
            </label>
          </div>
          <div className="form-check form-check-primary pr-30">
            <input className="form-check-input" type="checkbox" id="port" />
            <label className="form-check-label" for="Port">
              Port/Shipper
            </label>
          </div>
          <div className="form-check form-check-primary pr-30">
            <input
              className="form-check-input"
              type="checkbox"
              id="consignee"
            />
            <label className="form-check-label" for="consignee">
              Consignee
            </label>
          </div>
          <div className="form-check form-check-primary pr-30">
            <input className="form-check-input" type="checkbox" id="chassis" />
            <label className="form-check-label" for="chassis">
              Chassis
            </label>
          </div>
        </div>
        <div className="form-wrapper">
          <form>
            <div className="font-size-regular">
              <h4 className="font-size-regular">Contact Information</h4>
            </div>
            <div className="row mb-10">
              <div className="form-group col-md-4">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Company name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Chassis #"
                />
              </div>
              <div className="form-group col-md-8">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Address"
                />
              </div>
              <div className="form-group col-md-4">
                <label className="col-form-label">
                  <span className="text-danger">*</span> City
                </label>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select Owner"
                  className="w-200"
                />
              </div>
              <div className="form-group col-md-4">
                <label className="col-form-label">
                  <span className="text-danger">*</span> State
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter AID"
                />
              </div>
              <div className="form-group col-md-4">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Country
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter ADI"
                />
              </div>
            </div>
            <div className="font-size-regular">
              <h4 className="font-size-regular">Email Information</h4>
            </div>
            <div className="row">
              <div className="form-group col-md-4">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter email"
                />
              </div>
              <div className="form-group col-md-4">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Billing Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Billing Email"
                />
              </div>
              <div className="form-group col-md-4">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Quick Book Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter QuickBooks Email"
                />
              </div>
              <div className="form-group col-md-4">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Receiver Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Receiver Email"
                />
              </div>
              <div className="form-group col-md-4">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Password
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter AID"
                />
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-success" onClick={onSubmit}>
          Add New Customer
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCustomerModal;
