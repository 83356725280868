import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { IconUpload } from "../../../../common/icons";
import UploadDocument from "../../../../common/uploadDocument";
import UploadedDocuments from "../../../../common/uploadedDocuments";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
const Documents = () => {
  const [showUploadModal, setShowUploadModal] = useState(false);

  const handleShowUploadModal = (event) => {
    setShowUploadModal(true);
  };

  const handleHideUploadModal = (event) => {
    setShowUploadModal(false);
  };
  return (
    <React.Fragment>
      <div className="bg-gray-50 p-20">
        <button
          className="ml-auto btn btn-primary mb-15"
          onClick={handleShowUploadModal}
        >
          <IconUpload className="mr-2" />
          Upload Document
        </button>

        <UploadedDocuments />

        <Modal show={showUploadModal} centered animation={false}>
          <Modal.Header>
            <Modal.Title>Upload Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UploadDocument />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-close" onClick={handleHideUploadModal}>
              Close
            </button>
            <button
              className="btn btn-primary"
              disabled
              onClick={handleHideUploadModal}
            >
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Documents;
