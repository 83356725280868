import React, { useState } from "react";
import {
  IconBookMark,
  IconCart,
  IconHomeCheck,
  IconLoadCaretLeftRight,
  IconLoadCartRight,
  IconLoads,
  IconNoteClip,
  IconSteering,
  IconTruck,
  IconUsers,
} from "../icons";

const SearchPopDrop = () => {
  const [isLoadTabActive, setisLoadTabActive] = useState(1);
  const handleLoadTabActive = (i) => {
    setisLoadTabActive(isLoadTabActive === i ? null : i);
  };
  return (
    <div className="card card--shadow-5 popdrop popdrop--center popdrop--bottom-no-space w-max rounded-0">
      {/* Nav Tabs */}
      <ul className="nav nav-tabs nav-tabs-custom nav-tabs-custom--sm nav-justified align-items-center nav-border-bottom">
        <li className="nav-item">
          <a
            className={`nav-link ${isLoadTabActive === 1 ? "active" : ""}`}
            href="javascript:void(0)"
            onClick={() => handleLoadTabActive(1)}
          >
            <div className=" wh-20px">All</div>
          </a>
        </li>
        <li className="nav-item tooltip-label hover-gray-50">
          <a
            className={`nav-link position-relative ${
              isLoadTabActive === 2 ? "active" : ""
            }`}
            href="javascript:void(0)"
            onClick={() => handleLoadTabActive(2)}
          >
            <div className="">
              <IconLoads className="wh-20px" />
            </div>
            <div
              className="tooltip bs-tooltip-top bg-dark text-white w-max rounded-10 px-3  py-2"
              data-placement="top-center"
            >
              <div className="arrow"></div>
              Loads
            </div>
          </a>
        </li>
        <li className="nav-item tooltip-label hover-gray-50">
          <a
            className={`nav-link position-relative ${
              isLoadTabActive === 3 ? "active" : ""
            }`}
            href="javascript:void(0)"
            onClick={() => handleLoadTabActive(3)}
          >
            <div className="">
              <IconBookMark className="wh-20px" />
            </div>
            <div
              className="tooltip bs-tooltip-top bg-dark text-white w-max rounded-10 px-3  py-2"
              data-placement="top-center"
            >
              <div className="arrow"></div>
              Container Owner
            </div>
          </a>
        </li>
        <li className="nav-item position-relative tooltip-label hover-gray-50">
          <a
            className={`nav-link ${isLoadTabActive === 4 ? "active" : ""}`}
            href="javascript:void(0)"
            onClick={() => handleLoadTabActive(4)}
          >
            <div className="">
              <IconUsers className="wh-20px" />
            </div>
          </a>
          <div
            className="tooltip bs-tooltip-top bg-dark text-white w-max rounded-10 px-3  py-2"
            data-placement="top-center"
          >
            <div className="arrow"></div>
            Customers
          </div>
        </li>
        <li className="nav-item position-relative tooltip-label hover-gray-50">
          <a
            className={`nav-link ${isLoadTabActive === 5 ? "active" : ""}`}
            href="javascript:void(0)"
            onClick={() => handleLoadTabActive(5)}
          >
            <div className="">
              <IconNoteClip className="wh-20px" />
            </div>
          </a>
          <div
            className="tooltip bs-tooltip-top bg-dark text-white w-max rounded-10 px-3  py-2"
            data-placement="top-center"
          >
            <div className="arrow"></div>
            Container Type
          </div>
        </li>
        <li className="nav-item position-relative tooltip-label hover-gray-50">
          <a
            className={`nav-link ${isLoadTabActive === 6 ? "active" : ""}`}
            href="javascript:void(0)"
            onClick={() => handleLoadTabActive(6)}
          >
            <div className="">
              <IconHomeCheck className="wh-20px" />
            </div>
          </a>
          <div
            className="tooltip bs-tooltip-top bg-dark text-white w-max rounded-10 px-3  py-2"
            data-placement="top-center"
          >
            <div className="arrow"></div>
            Container Owner
          </div>
        </li>
        <li className="nav-item position-relative tooltip-label hover-gray-50">
          <a
            className={`nav-link ${isLoadTabActive === 7 ? "active" : ""}`}
            href="javascript:void(0)"
            onClick={() => handleLoadTabActive(7)}
          >
            <div className="">
              <IconLoadCaretLeftRight className="wh-20px" />
            </div>
          </a>
          <div
            className="tooltip bs-tooltip-top bg-dark text-white w-max rounded-10 px-3  py-2"
            data-placement="top-center"
          >
            <div className="arrow"></div>
            Chassis Type
          </div>
        </li>
        <li className="nav-item position-relative tooltip-label hover-gray-50">
          <a
            className={`nav-link ${isLoadTabActive === 8 ? "active" : ""}`}
            href="javascript:void(0)"
            onClick={() => handleLoadTabActive(8)}
          >
            <div className="">
              <IconSteering className="wh-20px" />
            </div>
          </a>
          <div
            className="tooltip bs-tooltip-top bg-dark text-white w-max rounded-10 px-3  py-2"
            data-placement="top-center"
          >
            <div className="arrow"></div>
            Drivers
          </div>
        </li>
        <li className="nav-item position-relative tooltip-label hover-gray-50">
          <a
            className={`nav-link ${isLoadTabActive === 9 ? "active" : ""}`}
            href="javascript:void(0)"
            onClick={() => handleLoadTabActive(9)}
          >
            <div className="">
              <IconTruck className="wh-20px" />
            </div>
          </a>
          <div
            className="tooltip bs-tooltip-top bg-dark text-white w-max rounded-10 px-3  py-2"
            data-placement="top-center"
          >
            <div className="arrow"></div>
            Trucks
          </div>
        </li>
        <li className="nav-item position-relative tooltip-label hover-gray-50">
          <a
            className={`nav-link ${isLoadTabActive === 10 ? "active" : ""}`}
            href="javascript:void(0)"
            onClick={() => handleLoadTabActive(10)}
          >
            <div className="">
              <IconCart className="wh-20px" />
            </div>
          </a>
          <div
            className="tooltip bs-tooltip-top bg-dark text-white w-max rounded-10 px-3  py-2"
            data-placement="top-center"
          >
            <div className="arrow"></div>
            Trailers
          </div>
        </li>
      </ul>

      {/* Nav Tab Content */}
      <div
        className="nav-tab-content overflow-auto"
        style={{ height: "calc(100vh - 125px)" }}
      >
        <div className="p-15">
          <h5 className="title text-muted mb-2">
            <IconLoads className="mr-2" />
            Loads
          </h5>
          <div className="d-flex justify-content-between border-bottom hover-gray-50 py-15">
            <div className="mr-2">
              <div className="text-muted">Loads #</div>
              <div className="mt-1">
                POP2_<span className="text-primary">M108</span>001
              </div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Bol #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Container #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Booking #</div>
              <div className="mt-1">-</div>
            </div>
          </div>
          <div className="d-flex justify-content-between border-bottom py-15">
            <div className="mr-2">
              <div className="text-muted">Loads #</div>
              <div className="mt-1">
                POP2_<span className="text-primary">M108</span>001
              </div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Bol #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Container #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Booking #</div>
              <div className="mt-1">-</div>
            </div>
          </div>
          <div className="d-flex justify-content-between py-15">
            <div className="mr-2">
              <div className="text-muted">Loads #</div>
              <div className="mt-1">
                POP2_<span className="text-primary">M108</span>001
              </div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Bol #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Container #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Booking #</div>
              <div className="mt-1">-</div>
            </div>
          </div>
        </div>
        <hr className="border-2 border-light m-0" />
        <div className="p-15">
          <h5 className="title text-muted mb-2">
            <IconBookMark className="mr-2" />
            Container Owner
          </h5>
          <div className="d-flex justify-content-between border-bottom py-15">
            <div className="mr-2">
              <div className="text-muted">Loads #</div>
              <div className="mt-1">
                POP2_<span className="text-primary">M108</span>001
              </div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Bol #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Container #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Booking #</div>
              <div className="mt-1">-</div>
            </div>
          </div>
          <div className="d-flex justify-content-between py-15">
            <div className="mr-2">
              <div className="text-muted">Loads #</div>
              <div className="mt-1">
                POP2_<span className="text-primary">M108</span>001
              </div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Bol #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Container #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Booking #</div>
              <div className="mt-1">-</div>
            </div>
          </div>
        </div>
        <hr className="border-2 border-light m-0" />
        <div className="p-15">
          <h5 className="title text-muted mb-2">
            <IconUsers className="mr-2" />
            Customers
          </h5>
          <div className="d-flex justify-content-between py-15">
            <div className="mr-2">
              <div className="text-muted">Loads #</div>
              <div className="mt-1">
                POP2_<span className="text-primary">M108</span>001
              </div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Bol #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Container #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Booking #</div>
              <div className="mt-1">-</div>
            </div>
          </div>
        </div>
        <hr className="border-2 border-light m-0" />
        <div className="p-15">
          <h5 className="title text-muted mb-2">
            <IconNoteClip className="mr-2" />
            Container Type
          </h5>
          <div className="d-flex justify-content-between py-15">
            <div className="mr-2">
              <div className="text-muted">Loads #</div>
              <div className="mt-1">
                POP2_<span className="text-primary">M108</span>001
              </div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Bol #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Container #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Booking #</div>
              <div className="mt-1">-</div>
            </div>
          </div>
        </div>
        <hr className="border-2 border-light m-0" />
        <div className="p-15">
          <h5 className="title text-muted mb-2">
            <IconHomeCheck className="mr-2" />
            Container Owner
          </h5>
          <div className="d-flex justify-content-between py-15">
            <div className="mr-2">
              <div className="text-muted">Loads #</div>
              <div className="mt-1">
                POP2_<span className="text-primary">M108</span>001
              </div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Bol #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Container #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Booking #</div>
              <div className="mt-1">-</div>
            </div>
          </div>
        </div>
        <hr className="border-2 border-light m-0" />
        <div className="p-15">
          <h5 className="title text-muted mb-2">
            <IconLoadCaretLeftRight className="mr-2" />
            Chassis Type
          </h5>
          <div className="d-flex justify-content-between py-15">
            <div className="mr-2">
              <div className="text-muted">Loads #</div>
              <div className="mt-1">
                POP2_<span className="text-primary">M108</span>001
              </div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Bol #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Container #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Booking #</div>
              <div className="mt-1">-</div>
            </div>
          </div>
        </div>
        <hr className="border-2 border-light m-0" />
        <div className="p-15">
          <h5 className="title text-muted mb-2">
            <IconSteering className="mr-2" />
            Drivers
          </h5>
          <div className="d-flex justify-content-between py-15">
            <div className="mr-2">
              <div className="text-muted">Loads #</div>
              <div className="mt-1">
                POP2_<span className="text-primary">M108</span>001
              </div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Bol #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Container #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Booking #</div>
              <div className="mt-1">-</div>
            </div>
          </div>
        </div>
        <hr className="border-2 border-light m-0" />
        <div className="p-15">
          <h5 className="title text-muted mb-2">
            <IconTruck className="mr-2" />
            Trucks
          </h5>
          <div className="d-flex justify-content-between py-15">
            <div className="mr-2">
              <div className="text-muted">Loads #</div>
              <div className="mt-1">
                POP2_<span className="text-primary">M108</span>001
              </div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Bol #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Container #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Booking #</div>
              <div className="mt-1">-</div>
            </div>
          </div>
        </div>
        <hr className="border-2 border-light m-0" />
        <div className="p-15">
          <h5 className="title text-muted mb-2">
            <IconCart className="mr-2" />
            Trailers
          </h5>
          <div className="d-flex justify-content-between py-15">
            <div className="mr-2">
              <div className="text-muted">Loads #</div>
              <div className="mt-1">
                POP2_<span className="text-primary">M108</span>001
              </div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Bol #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Container #</div>
              <div className="mt-1">-</div>
            </div>
            <div className="mr-2">
              <div className="text-muted">Booking #</div>
              <div className="mt-1">-</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchPopDrop;
