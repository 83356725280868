import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomSelect from "../../../../common/CustomSelect";
import CustomCreatableSelect from "../../../../common/CustomSelect/creatable";
import { IconInfoCircleFilled, IconMinus, IconPlus } from "../../../../common/icons";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const AddNewPricing = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;
  const [isTooltipShown, setIsTooltipShown] = useState(false);

  return (
    <Modal show={showModal} centered animation={false}>
      <Modal.Header>
        <Modal.Title>Add New Pricing</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="form-wrapper">
          <form>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label"> <span className="text-danger">*</span>Charge name</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select Charge name"
                  className="w-100"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label"> <span className="text-danger">*</span>Unit of Measure</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select Unit"
                  className="w-100"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label">Description of Charge</label>
                <textarea class="form-control" placeholder="Description of Charge"></textarea>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label className="col-form-label"> <span className="text-danger">*</span>$ per Unit</label>
                <input className="form-control" placeholder="$0.00" />
              </div>
              <div className="form-group col-md-4">
                <label className="col-form-label"> <span className="text-danger">*</span>Min. total price, $</label>
                <input className="form-control" placeholder="$0.00" />
              </div>
              <div className="form-group col-md-4">
                <label className="col-form-label"> <span className="text-danger">*</span>Free Units</label>
                <input className="form-control" placeholder="$0.00" />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 form-group">
                <div className="form-check pl-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id=""
                  />
                  <label className="form-check-label" for="">
                    On demand add this price
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-success" onClick={onSubmit}>
          Add Pricing
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewPricing;
