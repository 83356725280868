import React, { useState } from "react";

import {
  IconAngleArrowLeft,
  IconChat,
  IconClockSnooze,
  IconDotSeperator,
  IconShip,
  IconShipAnchor,
  IconTimes,
  IconTimesThin,
  IconTruck,
} from "../../../common/icons/index";

const TrackOsTracking = ({}) => {
  const [openCard, setOpenCard] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [assignLoad, setAssignedLoad] = useState(false);
  const [activeTab, setActiveTab] = useState("vessels");
  const [activeCard, setActiveCard] = useState(false);

  const handleActiveTab = (getTabName) => {
    setActiveTab(getTabName);
  };

  const handleActiveCard = (i) => {
    setActiveCard(activeCard === i ? null : i);
    // setActiveCard(!isMoreOptions)
  };

  const onClickSendMessage = () => setSendMessage(!sendMessage);
  const onClickTrackCard = () => setOpenCard(!openCard);
  const closeTrackCard = () => {
    setOpenCard(!openCard);
    setSendMessage(!sendMessage);
  };
  const handleAssignLoad = () => setAssignedLoad(!assignLoad);

  const data = [];
  for (let i = 1; i < 20; i++) {
    data.push({
      id: i,
    });
  }

  return (
    <div
      className={`wrapper home position-relative ${
        openCard && "subheader-wrapper"
      }`}
    >
      {openCard && (
        <div className="subheader">
          <div className="subheader__inner d-flex">
            <div className="d-flex flex-row align-items-center">
              <button
                className="btn btn-outline-light mr-3"
                onClick={closeTrackCard}
              >
                <IconAngleArrowLeft />
              </button>
              <div className="d-flex">
                <div className="flex-shrink-0 mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    className="avatar-xs rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <div className="flex-grow-1">
                  <h5 className="mb-0">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                  <p className="text-muted mb-0">(702) 555-0122</p>
                </div>
                <div className="flex-shrink-0">
                  <span className="badge badge-light font-12">
                    (702) 555-0122
                  </span>
                </div>
              </div>
              <div className="d-flex ml-5 align-items-center">
                <IconTruck className="text-muted" />
                <h5 className="mb-0 ml-3">Truck #: UJSK8223432</h5>
              </div>
            </div>
            <div className="ml-auto">
              <button
                className="btn btn-outline-light"
                onClick={onClickSendMessage}
              >
                {sendMessage ? (
                  <React.Fragment>
                    <IconTimesThin className="mr-2" />
                    Close
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <IconChat className="mr-2" />
                    Send Message
                  </React.Fragment>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className={`position-relative track-page ${openCard && ""}`}>
        {/* START TrackBar Design */}
        {!openCard ? (
          <div className="mapbar" style={{ height: "calc(100vh - 120px)" }}>
            <div className="card p-2 mb-1">
              <div className="app-search mb-2">
                <input
                  type="text"
                  className="input-search-left form-control"
                  placeholder="Search.."
                />
                <span className="search-icon"></span>
              </div>
              <ul className="nav nav-compact nav-justified" role="tablist">
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "vessels" && "active"
                    }`}
                    onClick={() => handleActiveTab("vessels")}
                  >
                    Vessels
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "terminals" && "active"
                    }`}
                    onClick={() => handleActiveTab("terminals")}
                  >
                    Terminals
                  </a>
                </li>
              </ul>
            </div>
            <div
              className={`dlist ${activeCard > 0 ? "dlist--cardselected" : ""}`}
              style={{ height: "calc(100% - 55px)" }}
            >
              {activeTab === "vessels" ? (
                <React.Fragment>
                  {data.map((datum) => {
                    return (
                      <div
                        className={`dlist__card ${
                          activeCard === datum.id
                            ? "dlist__card--selected mb-10"
                            : "mb-1"
                        }`}
                        onClick={() => handleActiveCard(datum.id)}
                        key={datum.id}
                      >
                        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                          <div className="flex-shrink-0 mr-2 align-self-center">
                            <div className="btn btn-outline-light btn-circle">
                              <IconShip />
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-14 mb-1">
                              <a href="#" className="text-dark">
                                Robert Fox
                              </a>
                            </h5>
                            <div className="text-muted mb-0">MAERSK</div>
                          </div>
                          <div className="flex-shrink-0 text-muted">
                            ETA: 01/23/2022
                          </div>
                        </div>
                        <div className="dlist__load p-10">
                          <span className="p-1 bg-gray-50 border-radius rounded-5 d-inline-block">
                            <span className="badge badge-info mr-1 font-12 badge-rounded">
                              {65 + Math.floor(Math.random() * datum.id * 12)}
                            </span>
                            Contianer
                          </span>
                        </div>
                        {activeCard === datum.id && (
                          <div className="dlist__distance p-10">
                            <div className="dlist__distance--row d-flex">
                              <div className="dlist__timeframe">
                                <div className="dlist__timeframe--icon">
                                  <i className="uil uil-check"></i>
                                </div>
                              </div>
                              <div className="dlist__distance--info w-100">
                                <div className="d-flex align-items-start pb-2">
                                  <div className="flex-grow-1">
                                    <h5 className="font-14 mb-1">
                                      <a href="#" className="text-dark">
                                        VG - Van Group
                                      </a>
                                    </h5>
                                    <p className="text-muted mb-0">
                                      <span>Springville, AR</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="dlist__distance--row d-flex">
                              <div className="dlist__timeframe">
                                <div className="dlist__timeframe--icon">
                                  <i className="uil uil-check"></i>
                                </div>
                              </div>
                              <div className="dlist__distance--info w-100">
                                <div className="d-flex align-items-start pb-2">
                                  <div className="flex-grow-1">
                                    <h5 className="font-14 mb-1">
                                      <a href="#" className="text-dark">
                                        VG - Van Group
                                      </a>
                                    </h5>
                                    <p className="text-muted mb-0">
                                      <span>Springville, AR</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {data.map((datum) => {
                    return (
                      <div
                        className={`dlist__card mb-1 ${
                          activeCard === datum.id ? "active" : ""
                        }`}
                        onClick={() => handleActiveCard(datum.id)}
                        key={datum.id}
                      >
                        <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                          <div className="flex-shrink-0 mr-2 align-self-center">
                            <div className="btn btn-outline-light btn-circle">
                              <IconShipAnchor />
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="font-14 mb-1">
                              <a href="#" className="text-dark">
                                Antong Holdings (QASC)
                              </a>
                            </h5>
                            <div className="text-muted mb-0">
                              Newark, New Jersey{" "}
                            </div>
                          </div>
                        </div>
                        <div className="dlist__load p-10 form-row">
                          <div className="col-6">
                            <div className="p-1 bg-gray-50 border-radius rounded-5">
                              <span className="badge badge-primary mr-1 font-12 badge-rounded">
                                {10 + Math.floor(Math.random() * datum.id * 12)}
                              </span>
                              Available
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="p-1 bg-gray-50 border-radius rounded-5">
                              <span className="badge bg-gray-700 text-white mr-1 font-12 badge-rounded">
                                {5 + Math.floor(Math.random() * datum.id * 12)}
                              </span>
                              Not Available
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </React.Fragment>
              )}
            </div>
          </div>
        ) : (
          "Driver Details"
        )}
        {/* END TrackBar Design */}
        <div
          id="Trackmap"
          style={{
            height: `calc(100vh - ${openCard ? "112px" : "60px"})`,
            backgroundImage: `url("https://i.imgur.com/ELNbBFS.png")`,
          }}
        >
          {activeCard && (
            <div
              className={`dlist__card`}
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translateY(-50%)",
                width: 300,
              }}
            >
              <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                <div className="flex-shrink-0 mr-2 align-self-center">
                  <div className="btn btn-outline-light btn-circle">
                    <IconShip />
                  </div>
                </div>
                <div className="flex-grow-1">
                  <h5 className="font-14 mb-1">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                  <div className="text-muted mb-0">MAERSK</div>
                </div>
                <div className="flex-shrink-0 text-muted">ETA: 01/23/2022</div>
              </div>
              <div className="dlist__load p-10">
                <span className="p-1 bg-gray-50 border-radius rounded-5 d-inline-block">
                  <span className="badge badge-info mr-1 font-12 badge-rounded">
                    65
                  </span>
                  Contianer
                </span>
              </div>
              <div className="dlist__distance p-10">
                <div className="dlist__distance--row d-flex">
                  <div className="dlist__timeframe">
                    <div className="dlist__timeframe--icon">
                      <i className="uil uil-check"></i>
                    </div>
                  </div>
                  <div className="dlist__distance--info w-100">
                    <div className="d-flex align-items-start pb-2">
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="#" className="text-dark">
                            VG - Van Group
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>Springville, AR</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dlist__distance--row d-flex">
                  <div className="dlist__timeframe">
                    <div className="dlist__timeframe--icon">
                      <i className="uil uil-check"></i>
                    </div>
                  </div>
                  <div className="dlist__distance--info w-100">
                    <div className="d-flex align-items-start pb-2">
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="#" className="text-dark">
                            VG - Van Group
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>Springville, AR</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className="btn-group shadow-1"
            style={{ right: 20, top: 20, position: "absolute" }}
          >
            <button className="btn btn-white">Street</button>
            <button className="btn btn-gray-100">Satellite</button>
          </div>
        </div>
        {/* <div id="Trackmap" style={{ height: 'calc(100vh - 112px)' }} ></div> */}
      </div>
    </div>
  );
};

export default TrackOsTracking;
