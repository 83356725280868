import { SetStateAction, useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  IconAngleArrowRight,
  IconCheck,
  IconEye,
  IconEyeClosed,
  IconAngleArrowDown,
  IconInfo,
  IconPen,
} from '../../../../common/icons';
export interface ModalPropsI {
  showModal: any;
  hideModal: any;
  showNextModal: any;
}
const PortLogin = (props: ModalPropsI) => {
  const [isCardActive, setCardActive] = useState(1);
  const { showModal, hideModal, showNextModal } = props;
  const cardItems = [
    {
      id: 1,
      port: 'Ports of Boston',
      message: 'Credentials are missing',
      status: 'Connected',
      count: '0',
    },
    {
      id: 2,
      port: 'Ports of Virginia',
      message: 'Credentials are missing',
      status: 'Error',
    },
    {
      id: 3,
      port: 'Ports of Miami',
      message: 'Credentials are missing',
      status: 'Missed',
    },
  ];

  return (
    <Modal show={showModal} centered animation={false} size="xl">
      <Modal.Header className="justify-content-center p-40">
        <Modal.Title>Enter your Port Login Credentials</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className={`${isCardActive ? '' : ''}`}>
          {cardItems.map((cardItem) => (
            <div
              className={`ncard p-15 border rounded-5 border-gray-100 mb-10 ${
                isCardActive === cardItem.id ? 'active' : ''
              }`}
              onClick={() => setCardActive(cardItem.id)}
            >
              <div className="d-flex justify-content-between">
                <div className="d-flex justify-content-between">
                  <button className="btn btn-primary mr-15">
                    <IconPen />
                  </button>
                  <div className="">
                    <div className="font-16 font-weight-500 text-gray-900">
                      {cardItem.port}
                    </div>
                    <div className="font-12, font-weight-normal text-warning-500">
                      {cardItem.message}
                    </div>
                  </div>
                </div>
                <div className="d-flex ">
                  <div className="d-flex flex-column mr-15 ">
                    <div className="text-gray-500 font-12 font-weight-normal pb-2">
                      Connected
                    </div>
                    <div className="font-20 text-gray-100 font-weight-500">
                      0
                    </div>
                  </div>
                  <div className="d-flex flex-column mr-15 ">
                    <div className="text-gray-500 font-12 font-weight-normal pb-2">
                      Error
                    </div>
                    <div className="font-20 text-gray-100 font-weight-500">
                      0
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="text-warning-500 font-12 font-weight-normal pb-2">
                      Missed
                    </div>
                    <div className="text-warning-500 font-20 font-weight-500">
                      3
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className=" btn btn-close mr-20 px-15 py-10"
          onClick={showModal}
        >
          Back
        </button>
        <button
          className="btn btn-outline-primary px-15 py-10  mr-10"
          onClick={hideModal}
        >
          Do it Later
        </button>
        <button className="btn btn-primary px-15 py-10" onClick={showNextModal}>
          Complete!
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default PortLogin;
