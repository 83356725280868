import React, { useState } from 'react';

const TabContent1 = () => {
  const [companyInfo, setCompanyInfo] = useState({
    name: '',
    address: '',
    timezone: '',
    phone: '',
    market: '',
    driverCount: 0
  });


  const saveCompanyInfo = async () => {
    console.log(companyInfo)
    // api hit
  }


  return (
    <div className="text-dark my-3 mt-5 d-flex justify-content-center ">
      <div>
        <span>Company name</span><br />
        <input
          className="form-control my-1"
          type="text"
          placeholder='BigTruckCompany'
          id="companyName"
          value={companyInfo.name}
          onChange={(e) => setCompanyInfo({ ...companyInfo, name: e.target.value })}
        /><br />
        <span>Company address</span>
        <input
          className="form-control my-1"
          type="text"
          placeholder='e.g. 689 street'
          id="companyName"
          onChange={(e) => setCompanyInfo({ ...companyInfo, address: e.target.value })}
        /><br />
        <span>Timezone</span>
        <select className="form-control my-1" id="marketName" onChange={(e) => setCompanyInfo({ ...companyInfo, timezone: e.target.value })}>
          <option>Select</option>
          <option>Timezone A</option>
          <option>Timezone B</option>
          <option>Timezone C</option>
          <option>Timezone D</option>
        </select>
        <br />
        <span>Company Phone</span>
        <input
          className="form-control my-1"
          type="text"
          placeholder='Enter'
          id="companyPhone"
          onChange={(e) => setCompanyInfo({ ...companyInfo, phone: e.target.value })}
        /><br />
        <span>What market do you operate in?</span>
        <select className="form-control my-1" id="marketName" onChange={(e) => setCompanyInfo({ ...companyInfo, market: e.target.value })}>
          <option>Market A</option>
          <option>Market B</option>
          <option>Market C</option>
          <option>Market D</option>
        </select>
        <br />
        <span>How many drivers do you have?</span>
        <input
          className="form-control my-1"
          type="number"
          placeholder='Enter'
          id="driverCount"
          onChange={(e) => setCompanyInfo({ ...companyInfo, driverCount: e.target.value })}
        /><br /><br />
        <button className='btn btn-primary px-5' onClick={saveCompanyInfo}>Save</button>
      </div>
    </div>
  )
}

export default TabContent1
