import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { IconDownload, IconPrinter } from "../../../common/icons";

const TrackOsProfileTable = ({}) => {
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = (i) => {
    setShowPassword(showPassword[i] === i ? null : i);
  };

  const handleHidePassword = (i) => {
    setShowPassword(showPassword[i] === i ? null : false);
  };

  const handleShowBookingModal = () => {
    setShowBookingModal(true);
  };
  const handleHideAddModal = () => {
    setShowBookingModal(false);
  };

  //Table Datas
  const tableDatas = [];
  for (let i = 1; i < 20; i++) {
    tableDatas.push({
      id: i,
    });
  }

  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 249px)" }}
      >
        <table className="table table-card table-card--wrap">
          <thead>
            <tr>
              <th width="40">
                <div className="from-check form-check--gray">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="sr_no"
                  />
                </div>
              </th>
              <th>HardCode to Tracking</th>
              <th width="150">Company Address</th>
              <th>City</th>
              <th>State</th>
              <th>Zip</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Username</th>
              <th>Password</th>
              <th>Status </th>
            </tr>
          </thead>
          <tbody>
            {tableDatas.map((tabledata) => {
              return (
                <tr key={tabledata.id}>
                  <td>
                    <div className="from-check form-check--gray">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="sr_no"
                      />
                    </div>
                  </td>
                  <td>X-Press Feeders</td>
                  <td style={{ maxWidth: 100 }}>
                    2118 Thornridge Cir. Syracuse, Connecticut 35624
                  </td>
                  <td>New Jersey</td>
                  <td>CA</td>
                  <td>98731</td>
                  <td>(252) 555-0126</td>
                  <td>felicia.reid@example.com</td>
                  <td>armholegetaway</td>
                  <td>
                    {showPassword === tabledata.id ? (
                      <div onClick={() => handleHidePassword(tabledata.id)}>
                        PasswordKKLLK
                      </div>
                    ) : (
                      <button
                        className="btn btn-outline-light btn-sm"
                        onClick={() => handleShowPassword(tabledata.id)}
                      >
                        Show Password
                      </button>
                    )}
                  </td>
                  <td>
                    {tabledata.id < 4 ? (
                      <span className="badge badge-danger">Error</span>
                    ) : (
                      <span className="badge badge-success">Tracking</span>
                    )}
                  </td>
                </tr>
              );
            })}
            
          </tbody>
        </table>
      </div>
      <Modal show={showBookingModal} centered animation={false} size="xl">
        <Modal.Header className="d-flex align-items-start modal-header--gray modal-header">
          <div className="d-flex flex-column">
            <Modal.Title>APM Terminals</Modal.Title>
            <div className="text-muted mb-1">25/08/2021 • 11:44</div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <img
            width="100%"
            className="img-fluid"
            src="https://i.imgur.com/QD7URsK.png"
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-light"
            onClick={handleHideAddModal}
          >
            <IconDownload className="mr-2" />
            Download
          </button>
          <button
            className="btn btn-outline-light"
            onClick={handleHideAddModal}
          >
            <IconPrinter className="mr-2" />
            Print
          </button>
          <button className="btn btn-primary" onClick={handleHideAddModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default TrackOsProfileTable;
