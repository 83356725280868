import React, { useState } from 'react';
import CustomSelect from '../../../../common/CustomSelect/index';

import {
  IconColumn,
  IconDownload,
  IconFilter,
  IconPen,
  IconTrash,
} from '../../../../common/icons/index';

const columns = [
  'Role name',
  'Tracking',
  'Dispatcher',
  'Customers',
  'Dashboard',
  'Billings',
  'Safeties',
  'Others',
  'Container',
  'Chassis',
  'Profile',
  'Reports',
  'Accounts',
  'Settings',
];

const data = [
  {
    id: 1,
    roleName: 'Greg',
    tracking: 'Hawkins',
    dispatcher: '+1-202-555-0161',
    customers: ['admin', 'Mechanics'],
    dashboard: '+1-202-555-0161',
    billings: '+1-202-555-0161',
    safeties: '+1-202-555-0161',
    others: '+1-202-555-0161',
    container: '+1-202-555-0161',
    chassis: '+1-202-555-0161',
    profile: '+1-202-555-0161',
    report: '+1-202-555-0161',
    accounts: '+1-202-555-0161',
    settings: '+1-202-555-0161',
  },
  {
    id: 2,
    roleName: 'Greg',
    tracking: 'Hawkins',
    dispatcher: '+1-202-555-0161',
    customers: ['admin', 'Mechanics'],
    dashboard: '+1-202-555-0161',
    billings: '+1-202-555-0161',
    safeties: '+1-202-555-0161',
    others: '+1-202-555-0161',
    container: '+1-202-555-0161',
    chassis: '+1-202-555-0161',
    profile: '+1-202-555-0161',
    report: '+1-202-555-0161',
    accounts: '+1-202-555-0161',
    settings: '+1-202-555-0161',
  },
  {
    id: 3,
    roleName: 'Greg',
    tracking: 'Hawkins',
    dispatcher: '+1-202-555-0161',
    customers: ['admin', 'Mechanics'],
    dashboard: '+1-202-555-0161',
    billings: '+1-202-555-0161',
    safeties: '+1-202-555-0161',
    others: '+1-202-555-0161',
    container: '+1-202-555-0161',
    chassis: '+1-202-555-0161',
    profile: '+1-202-555-0161',
    report: '+1-202-555-0161',
    accounts: '+1-202-555-0161',
    settings: '+1-202-555-0161',
  },
  {
    id: 4,
    roleName: 'Greg',
    tracking: 'Hawkins',
    dispatcher: '+1-202-555-0161',
    customers: ['admin', 'Mechanics'],
    dashboard: '+1-202-555-0161',
    billings: '+1-202-555-0161',
    safeties: '+1-202-555-0161',
    others: '+1-202-555-0161',
    container: '+1-202-555-0161',
    chassis: '+1-202-555-0161',
    profile: '+1-202-555-0161',
    report: '+1-202-555-0161',
    accounts: '+1-202-555-0161',
    settings: '+1-202-555-0161',
  },
  {
    id: 5,
    roleName: 'Greg',
    tracking: 'Hawkins',
    dispatcher: '+1-202-555-0161',
    customers: ['admin', 'Mechanics'],
    dashboard: '+1-202-555-0161',
    billings: '+1-202-555-0161',
    safeties: '+1-202-555-0161',
    others: '+1-202-555-0161',
    container: '+1-202-555-0161',
    chassis: '+1-202-555-0161',
    profile: '+1-202-555-0161',
    report: '+1-202-555-0161',
    accounts: '+1-202-555-0161',
    settings: '+1-202-555-0161',
  },
  {
    id: 6,
    roleName: 'Greg',
    tracking: 'Hawkins',
    dispatcher: '+1-202-555-0161',
    customers: ['admin', 'Mechanics'],
    dashboard: '+1-202-555-0161',
    billings: '+1-202-555-0161',
    safeties: '+1-202-555-0161',
    others: '+1-202-555-0161',
    container: '+1-202-555-0161',
    chassis: '+1-202-555-0161',
    profile: '+1-202-555-0161',
    report: '+1-202-555-0161',
    accounts: '+1-202-555-0161',
    settings: '+1-202-555-0161',
  },
  {
    id: 7,
    roleName: 'Greg',
    tracking: 'Hawkins',
    dispatcher: '+1-202-555-0161',
    customers: ['admin', 'Mechanics'],
    dashboard: '+1-202-555-0161',
    billings: '+1-202-555-0161',
    safeties: '+1-202-555-0161',
    others: '+1-202-555-0161',
    container: '+1-202-555-0161',
    chassis: '+1-202-555-0161',
    profile: '+1-202-555-0161',
    report: '+1-202-555-0161',
    accounts: '+1-202-555-0161',
    settings: '+1-202-555-0161',
  },
  {
    id: 8,
    roleName: 'Greg',
    tracking: 'Hawkins',
    dispatcher: '+1-202-555-0161',
    customers: ['admin', 'Mechanics'],
    dashboard: '+1-202-555-0161',
    billings: '+1-202-555-0161',
    safeties: '+1-202-555-0161',
    others: '+1-202-555-0161',
    container: '+1-202-555-0161',
    chassis: '+1-202-555-0161',
    profile: '+1-202-555-0161',
    report: '+1-202-555-0161',
    accounts: '+1-202-555-0161',
    settings: '+1-202-555-0161',
  },
  {
    id: 9,
    roleName: 'Greg',
    tracking: 'Hawkins',
    dispatcher: '+1-202-555-0161',
    customers: ['admin', 'Mechanics'],
    dashboard: '+1-202-555-0161',
    billings: '+1-202-555-0161',
    safeties: '+1-202-555-0161',
    others: '+1-202-555-0161',
    container: '+1-202-555-0161',
    chassis: '+1-202-555-0161',
    profile: '+1-202-555-0161',
    report: '+1-202-555-0161',
    accounts: '+1-202-555-0161',
    settings: '+1-202-555-0161',
  },
  {
    id: 10,
    roleName: 'Greg',
    tracking: 'Hawkins',
    dispatcher: '+1-202-555-0161',
    customers: ['admin', 'Mechanics'],
    dashboard: '+1-202-555-0161',
    billings: '+1-202-555-0161',
    safeties: '+1-202-555-0161',
    others: '+1-202-555-0161',
    container: '+1-202-555-0161',
    chassis: '+1-202-555-0161',
    profile: '+1-202-555-0161',
    report: '+1-202-555-0161',
    accounts: '+1-202-555-0161',
    settings: '+1-202-555-0161',
  },
  {
    id: 11,
    roleName: 'Greg',
    tracking: 'Hawkins',
    dispatcher: '+1-202-555-0161',
    customers: ['admin', 'Mechanics'],
    dashboard: '+1-202-555-0161',
    billings: '+1-202-555-0161',
    safeties: '+1-202-555-0161',
    others: '+1-202-555-0161',
    container: '+1-202-555-0161',
    chassis: '+1-202-555-0161',
    profile: '+1-202-555-0161',
    report: '+1-202-555-0161',
    accounts: '+1-202-555-0161',
    settings: '+1-202-555-0161',
  },
  {
    id: 12,
    roleName: 'Greg',
    tracking: 'Hawkins',
    dispatcher: '+1-202-555-0161',
    customers: ['admin', 'Mechanics'],
    dashboard: '+1-202-555-0161',
    billings: '+1-202-555-0161',
    safeties: '+1-202-555-0161',
    others: '+1-202-555-0161',
    container: '+1-202-555-0161',
    chassis: '+1-202-555-0161',
    profile: '+1-202-555-0161',
    report: '+1-202-555-0161',
    accounts: '+1-202-555-0161',
    settings: '+1-202-555-0161',
  },
  {
    id: 13,
    roleName: 'Greg',
    tracking: 'Hawkins',
    dispatcher: '+1-202-555-0161',
    customers: ['admin', 'Mechanics'],
    dashboard: '+1-202-555-0161',
    billings: '+1-202-555-0161',
    safeties: '+1-202-555-0161',
    others: '+1-202-555-0161',
    container: '+1-202-555-0161',
    chassis: '+1-202-555-0161',
    profile: '+1-202-555-0161',
    report: '+1-202-555-0161',
    accounts: '+1-202-555-0161',
    settings: '+1-202-555-0161',
  },
  {
    id: 14,
    roleName: 'Greg',
    tracking: 'Hawkins',
    dispatcher: '+1-202-555-0161',
    customers: ['admin', 'Mechanics'],
    dashboard: '+1-202-555-0161',
    billings: '+1-202-555-0161',
    safeties: '+1-202-555-0161',
    others: '+1-202-555-0161',
    container: '+1-202-555-0161',
    chassis: '+1-202-555-0161',
    profile: '+1-202-555-0161',
    report: '+1-202-555-0161',
    accounts: '+1-202-555-0161',
    settings: '+1-202-555-0161',
  },
  {
    id: 15,
    roleName: 'Greg',
    tracking: 'Hawkins',
    dispatcher: '+1-202-555-0161',
    customers: ['admin', 'Mechanics'],
    dashboard: '+1-202-555-0161',
    billings: '+1-202-555-0161',
    safeties: '+1-202-555-0161',
    others: '+1-202-555-0161',
    container: '+1-202-555-0161',
    chassis: '+1-202-555-0161',
    profile: '+1-202-555-0161',
    report: '+1-202-555-0161',
    accounts: '+1-202-555-0161',
    settings: '+1-202-555-0161',
  },
  {
    id: 16,
    roleName: 'Greg',
    tracking: 'Hawkins',
    dispatcher: '+1-202-555-0161',
    customers: ['admin', 'Mechanics'],
    dashboard: '+1-202-555-0161',
    billings: '+1-202-555-0161',
    safeties: '+1-202-555-0161',
    others: '+1-202-555-0161',
    container: '+1-202-555-0161',
    chassis: '+1-202-555-0161',
    profile: '+1-202-555-0161',
    report: '+1-202-555-0161',
    accounts: '+1-202-555-0161',
    settings: '+1-202-555-0161',
  },
  {
    id: 17,
    roleName: 'Greg',
    tracking: 'Hawkins',
    dispatcher: '+1-202-555-0161',
    customers: ['admin', 'Mechanics'],
    dashboard: '+1-202-555-0161',
    billings: '+1-202-555-0161',
    safeties: '+1-202-555-0161',
    others: '+1-202-555-0161',
    container: '+1-202-555-0161',
    chassis: '+1-202-555-0161',
    profile: '+1-202-555-0161',
    report: '+1-202-555-0161',
    accounts: '+1-202-555-0161',
    settings: '+1-202-555-0161',
  },
  {
    id: 18,
    roleName: 'Greg',
    tracking: 'Hawkins',
    dispatcher: '+1-202-555-0161',
    customers: ['admin', 'Mechanics'],
    dashboard: '+1-202-555-0161',
    billings: '+1-202-555-0161',
    safeties: '+1-202-555-0161',
    others: '+1-202-555-0161',
    container: '+1-202-555-0161',
    chassis: '+1-202-555-0161',
    profile: '+1-202-555-0161',
    report: '+1-202-555-0161',
    accounts: '+1-202-555-0161',
    settings: '+1-202-555-0161',
  },
];

const StaticTableContent = () => {
  return (
    <React.Fragment>
      {/* Search Filter */}
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>
      </div>
      {/* Table Content */}
      <div
        className="table-responsive"
        style={{ height: 'calc(100vh - 252px)' }}
      >
        <table className="table table-card">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
              <th style={{ width: '2%' }}></th>
              <th style={{ width: '2%' }}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((datum) => {
              return (
                <tr key={datum.id}>
                  <td>{datum.roleName}</td>
                  <td>{datum.tracking}</td>
                  <td>{datum.dispatcher}</td>
                  <td>
                    <div className="d-flex">
                      <span className="badge badge-light rounded mr-2">
                        {datum.customers[0]}
                      </span>
                      <span className="badge badge-light rounded">
                        {datum.customers[1]}
                      </span>
                    </div>
                  </td>
                  <td>{datum.dashboard}</td>
                  <td>{datum.billings}</td>
                  <td>{datum.safeties}</td>
                  <td>{datum.others}</td>
                  <td>{datum.container}</td>
                  <td>{datum.chassis}</td>
                  <td>{datum.profile}</td>
                  <td>{datum.report}</td>
                  <td>{datum.accounts}</td>
                  <td>{datum.settings}</td>
                  <td>
                    <button className="btn btn-link">
                      <IconPen className="text-primary" />
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-link">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
