import React from 'react';
import Images from './organizationlogoImage';
const Organizationlogo = () => {
  return (
    <React.Fragment>
      <div className="c-list__grid">
        <div className="d-flex justify-content-center align-items-center">
          <div className="px-20 py-10">
            <img
              src={Images.org_logo1}
              alt="organization_logo"
              className="w-40"
            />
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="px-20 py-10">
            <img
              src={Images.org_logo2}
              alt="organization_logo"
              className="w-40"
            />
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="px-20 py-10">
            <img
              src={Images.org_logo3}
              alt="organization_logo"
              className="w-40"
            />
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="px-20 py-10">
            <img
              src={Images.org_logo4}
              alt="organization_logo"
              className="w-40"
            />
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="px-20 py-10">
            <img
              src={Images.org_logo5}
              alt="organization_logo"
              className="w-40"
            />
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="px-20 py-10">
            <img
              src={Images.org_logo6}
              alt="organization_logo"
              className="w-40"
            />
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="px-20 py-10">
            <img
              src={Images.org_logo7}
              alt="organization_logo"
              className="w-40"
            />
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="px-20 py-10">
            <img
              src={Images.org_logo8}
              alt="organization_logo"
              className="w-40"
            />
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="px-20 py-10">
            <img
              src={Images.org_logo9}
              alt="organization_logo"
              className="w-40"
            />
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="px-20 py-10">
            <img
              src={Images.org_logo10}
              alt="organization_logo"
              className="w-40"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Organizationlogo;
