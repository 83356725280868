import React, { useState } from 'react';
import PortLogin from './Portlogin';

const ContainerTrackingtTable = () => {
  const [showPortLoginModal, setShowPortLoginModal] = useState(false);

  const ShowLoginModal = () => {
    setShowPortLoginModal(true);
  };
  const HideLoginModal = () => {
    setShowPortLoginModal(false);
  };
  return (
    <>
      <div
        className="table-responsive"
        // style={{ height: "calc(100vh - 196px)" }}
      >
        <table className=" table table-card">
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Status</th>
              <th>Connected</th>
              <th>Error</th>
              <th>Missing</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Port of NJ/NY</td>
              <td>
                <div className="badge badge-light text-primary">Connected</div>
              </td>
              <td>3</td>
              <td>0</td>
              <td>0</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="pb-2 mt-50 d-flex align-items-center mt-10 ">
        <h4 className="font-20 ">11 Port disabled for Container Tracking</h4>
      </div>
      {/* //second table */}
      <div
        className="table-responsive"
        // style={{ height: "calc(100vh - 196px)" }}
      >
        <table className=" table table-card">
          <tbody className="">
            <tr className="bg-red-soft">
              <td>Port of Miami</td>
              <td>
                <div
                  className="badge badge-danger-light"
                  onClick={ShowLoginModal}
                >
                  Click here to enable
                </div>
              </td>
              <td>3</td>
              <td className="text-danger">1</td>
              <td>0</td>
            </tr>
            <tr className="bg-red-soft">
              <td>Port of LA/LB</td>
              <td>
                <div className="badge badge-danger-light">
                  Click here to enable
                </div>
              </td>
              <td>3</td>
              <td className="text-danger">1</td>
              <td>0</td>
            </tr>
            <tr className="bg-red-soft">
              <td>Port of Miami</td>
              <td>
                <div className="badge badge-danger-light">
                  Click here to enable
                </div>
              </td>
              <td>3</td>
              <td className="text-danger">1</td>
              <td>0</td>
            </tr>
            <tr className="bg-red-soft">
              <td>Port of LA/LB</td>
              <td>
                <div className="badge badge-danger-light">
                  Click here to enable
                </div>
              </td>
              <td>3</td>
              <td className="text-danger">1</td>
              <td>0</td>
            </tr>
          </tbody>
        </table>
        {}
      </div>
      {showPortLoginModal && (
        <PortLogin showModal={ShowLoginModal} hideModal={HideLoginModal} />
      )}
    </>
  );
};

export default ContainerTrackingtTable;
