import React, { useState } from 'react';
import SettingsTab from '../common-elements/SettingsTabs';
import { IconDownload, IconPlus } from '../../../common/icons';

const options = [
  { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
  { value: 'John Doe', label: 'John Doe' },
  { value: 'Alex Mendes', label: 'Alex Mendes' },
];

const LoadStatusColorSettings = () => {
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <SettingsTab
            activeTab={8}
            button={
              <div className="d-flex">
                <button className="mr-2 btn btn-outline-light ">
                  <IconDownload className="mr-2" />
                  Downlaod CVS
                </button>
                <button className=" btn btn-success ">
                  <IconPlus className="mr-2" />
                  Add Commodity
                </button>
              </div>
            }
          />
        </div>
      </div>
      <div className="page-fluid">Load Color Settings</div>
    </div>
  );
};

export default LoadStatusColorSettings;
