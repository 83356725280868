import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import CustomSelect from '../../../../common/CustomSelect';

const options = [
  { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
  { value: 'John Doe', label: 'John Doe' },
  { value: 'Alex Mendes', label: 'Alex Mendes' },
];

const AddNewRole = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;

  return (
    <Modal show={showModal} centered animation={false} size="sm">
      <Modal.Header>
        <Modal.Title>Add Role</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="form-wrapper">
          <form>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> First name
                </label>
                <input
                  className="form-control"
                  placeholder="Enter first name"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Last name
                </label>
                <input className="form-control" placeholder="Enter last name" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Phone
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Phone number"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Email
                </label>
                <input
                  className="form-control"
                  placeholder="Enter email address"
                />
              </div>
            </div>
            <div class="border mb-2"></div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">* </span> Password
                </label>
                <input className="form-control" placeholder="Enter Password" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">* </span> Confirm Password
                </label>
                <input
                  className="form-control"
                  placeholder="Repeated Password"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-check form-check-primary pr-30 ml-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="customer"
                />
                <label className="form-check-label" for="customer">
                  Admin
                </label>
              </div>
            </div>
            <div className="form-row mt-3">
              <div className="form-check form-check-primary pr-30 ml-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="customer"
                />
                <label className="form-check-label" for="customer">
                  Mechanics
                </label>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-success" onClick={onSubmit}>
          Add Role
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewRole;
