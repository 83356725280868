import React, { useState } from "react";
import { IconAttachment, IconSendMessage } from "../icons";

const Chatpop = ({ className, chatBoxOpen }) => {
  return (
    <div className={`chatpop shadow-none ${className}`}>
      <div className="chatpop__conversation">
        <ul className="list-unstyled mb-0">
          <li className="chat-day-title">
            <div className="title">June 21, 2021</div>
          </li>
          <li className="chatpop__conversation__list">
            <div className="chatpop-image">
              <img
                src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                className="avatar rounded-circle"
                width="30"
                alt=""
              />
            </div>
            <div className="chatpop__wrap">
              <div>
                <div className="chatpop__bubble">
                  May I request you to verify your current residence address?
                </div>
              </div>

              <div className="chatpop__time">June 21 • 00:57 AM</div>
            </div>
          </li>
          <li className="chatpop__conversation__list right">
            <div className="chatpop-image">
              <img
                src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                className="avatar rounded-circle"
                width="30"
                alt=""
              />
            </div>
            <div className="chat__wrap">
              <div>
                <div className="chatpop__bubble">
                  Hi, Sure! Just a moment pls
                </div>
              </div>

              <div className="chatpop__time">June 21 • 00:57 AM</div>
            </div>
          </li>
          <li className="chatpop__conversation__list">
            <div className="chatpop-image">
              <img
                src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                className="avatar rounded-circle"
                width="30"
                alt=""
              />
            </div>
            <div className="chatpop__wrap">
              <div>
                <div className="chatpop__bubble">
                  We need some additional information prior to proceeding.
                  Please share the required information?
                </div>
              </div>

              <div className="chatpop__time">June 21 • 00:57 AM</div>
            </div>
          </li>
          <li className="chat-day-title">
            <div className="title">Today</div>
          </li>
          <li className="chatpop__conversation__list">
            <div className="chatpop-image">
              <img
                src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                className="avatar rounded-circle"
                width="30"
                alt=""
              />
            </div>
            <div className="chatpop__wrap">
              <div>
                <div className="chatpop__bubble">
                  May I request you to verify your current residence address?
                </div>
              </div>

              <div className="chatpop__time">Today • 00:50 AM</div>
            </div>
          </li>
          <li className="chatpop__conversation__list right">
            <div className="chatpop-image">
              <img
                src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                className="avatar rounded-circle"
                width="30"
                alt=""
              />
            </div>
            <div className="chatpop__wrap">
              <div>
                <div className="chatpop__bubble">oh sorry, I forgot</div>
              </div>

              <div>
                <div className="chatpop__bubble">Hi</div>
              </div>
              <div>
                <div className="chatpop__bubble">How are you?</div>
              </div>

              <div className="chatpop__time">June 21 • 00:57 AM</div>
            </div>
          </li>
          <li className="chat-day-title">
            <div className="title">Today</div>
          </li>
        </ul>
      </div>
      <div
        className={`chatpop__input ${chatBoxOpen == true ? "p-15 h-auto" : ""}`}
      >
        <div className="form-row">
          <div className="col d-flex">
            {chatBoxOpen == true ? null : (
              <button type="file" className="btn btn-outline-light btn-lg mr-2">
                <IconAttachment />
              </button>
            )}
            {chatBoxOpen == true ? (
              <div className="input-wrapper w-100">
                <input
                  type="text"
                  className="form-control h-100 pl-40 pt-10 pr-10"
                  placeholder="Your Message..."
                />
                <span className="input-icon input-icon--prepend">
                  <IconAttachment />
                </span>
              </div>
            ) : (
              <input
                type="text"
                className="form-control"
                placeholder="Notes about Drivers"
              />
            )}
          </div>
          <div className="col-auto">
            {chatBoxOpen == true ? (
              <button type="submit" className="btn btn-primary p-10">
                <IconSendMessage className="text-white" />
              </button>
            ) : (
              <button type="submit" className="btn btn-primary btn-lg">
                Send Message
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chatpop;
