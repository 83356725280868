import React, { useState, Component } from "react";
import StaticTableContent from "../StaticTableContent";
import {
  IconFilter,
  IconPlus,
  IconTruckDriver,
  IconColumn,
  IconAngleArrowLeft,
  IconAngleArrowRight,
  IconCalendar,
  IconCaretDown,
  IconPrinter,
  IconClone,
  IconTrash,
  IconSendEmail,
} from "../../../common/icons/index";
import LoadPage from "../LoadPage";
import { Modal } from "react-bootstrap";
import { WithContext as ReactTags } from "react-tag-input";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { CustomIconSaveOutline } from "../../../common/customIcons";

class MainCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTabActive: false,
      isCardActive: false,
      isPopupOpen: false,
      openPopup: false,
      hidePopup: false,
      issubCardItem: false,
      openLoad: false,
      isPopDrop: false,
      isDriverList: false,
      isTrackCard: false,
      isSaveOptions: false,
      showSendEmailModal: false,

      tags: [
        { id: "1", text: "felicia.reid@example.com" },
        { id: "2", text: "dolores.chambers@example.com" },
      ],

      editorState: EditorState.createEmpty(),
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }

  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  }

  handleAddition(tag) {
    this.setState((state) => ({ tags: [...state.tags, tag] }));
  }

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  }

  handleTrackCard = () => {
    this.setState({ isTrackCard: !this.state.isTrackCard });
  };
  handleShowDriverList = (event) => {
    document.body.classList.add("bdpopup-open");
    this.setState({ isDriverList: true });
  };

  handleHideDriverList = (event) => {
    document.body.classList.remove("bdpopup-open");
    this.setState({ isDriverList: false });
  };
  handleIsPopDrop = (event) => {
    this.setState({ isPopDrop: !this.state.isPopDrop });
  };
  openLoad = (event) => {
    this.setState({ openLoad: true });
  };

  hideLoad = (event) => {
    this.setState({ openLoad: false });
  };

  openPopup = (event) => {
    document.body.classList.add("bdpopup-open");
    this.setState({ isPopupOpen: true });
  };

  hidePopup = (event) => {
    document.body.classList.remove("bdpopup-open");
    this.setState({ isPopupOpen: false });
  };

  handleTabActive = (i) => {
    this.setState({
      isTabActive: this.state.isTabActive === i ? null : i,
    });
  };
  handleCardActive = (i) => {
    this.setState({
      isCardActive: this.state.isCardActive === i ? null : i,
    });
  };
  handleSubCardItem() {
    this.setState({ isSubCardItem: !this.state.isSubCardItem });
  }
  handleIsSaveOptions = (event) => {
    this.setState({ isSaveOptions: !this.state.isSaveOptions });
  };
  handleShowSendEmailModal = (event) => {
    this.setState({ showSendEmailModal: true });
  };
  handleHideSendEmailModal = (event) => {
    this.setState({ showSendEmailModal: false });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  render() {
    const { tags } = this.state;
    const { editorState } = this.state;

    return (
      <React.Fragment>
        <div className="position-relative subheader-wrapper">
          <div className="subheader">
            {this.state.openLoad == true ? (
              <div className="subheader__inner d-flex">
                <div className="d-flex flex-row align-items-center">
                  <button
                    className="btn btn-outline-light"
                    onClick={() => this.hideLoad()}
                  >
                    <IconAngleArrowLeft />
                  </button>
                  <div className="d-flex ml-3 align-items-center">
                    <h5 className="mb-0">Truck #: UJSK8223432</h5>
                    <span className="badge badge-info ml-3">
                      Enroute to Delivery load
                    </span>
                  </div>
                </div>
                <div className="ml-auto position-relative">
                  <button className="btn btn-link mr-1">
                    <IconPrinter />
                  </button>
                  <button className="btn btn-link mr-1">
                    <IconClone />
                  </button>
                  <button className="btn btn-link mr-1">
                    <IconTrash />
                  </button>
                  <button
                    className="btn btn-outline-light mr-1"
                    onClick={() => this.hideLoad()}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-success mr-4"
                    onClick={this.handleIsSaveOptions}
                  >
                    Save Changes
                    <IconCaretDown />
                  </button>
                  {this.state.isSaveOptions && (
                    <div className="card card--shadow-5 save-options popdrop popdrop--right mb-0 p-3 animated">
                      <a
                        href=""
                        className="text-gray-900 rounded-3 d-flex align-items-center py-2 px-3 animated"
                      >
                        <CustomIconSaveOutline className="mr-3" />
                        Save & Add to Customer Rates
                      </a>
                      <hr className="m-2" />
                      <a
                        href="javascript:void(0)"
                        className="text-gray-900 rounded-3 d-flex align-items-center py-2 px-3 animated"
                        onClick={this.handleShowSendEmailModal}
                      >
                        <IconSendEmail className="mr-3" />
                        Save & Send Rate Confirmation
                      </a>
                    </div>
                  )}
                  <button className="btn btn-outline-light mr-2">
                    <IconAngleArrowLeft />
                  </button>
                  <button className="btn btn-outline-light">
                    <IconAngleArrowRight />
                  </button>
                </div>
              </div>
            ) : (
              <div className="subheader__inner d-flex py-0 d-flex align-items-center">
                <div className="d-flex flex-row align-items-center">
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          this.state.isTabActive === 1 ? "active" : ""
                        }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleTabActive(1)}
                      >
                        Dispatcher
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          this.state.isTabActive === 2 ? "active" : ""
                        }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleTabActive(2)}
                      >
                        Driver Itinerary
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          this.state.isTabActive === 3 ? "active" : ""
                        }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleTabActive(3)}
                      >
                        Double Moves
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          this.state.isTabActive === 4 ? "active" : ""
                        }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleTabActive(4)}
                      >
                        Problem Containers
                        <span className="badge badge-warning badge-pill font-12 ml-1">
                          12
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="ml-auto">
                  <button
                    className="btn btn-outline-light mr-2"
                    onClick={this.handleShowDriverList}
                  >
                    <IconTruckDriver className="mr-2" />
                    Drivers
                  </button>
                  <button className="btn btn-success">
                    <IconPlus className="mr-2" />
                    Add New Load
                  </button>
                </div>
              </div>
            )}
          </div>

          {this.state.openLoad == false ? (
            <div className="page-fluid">
              <div className="top-page">
                <div className="pb-15 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <button className="btn btn-white mr-3">
                      <IconAngleArrowLeft />
                    </button>
                    <div className="d-flex align-items-center mr-3">
                      <h4 className="font-20 mb-0">Today</h4>
                      <IconCaretDown />
                    </div>
                    <button className="btn btn-white mr-3">
                      <IconAngleArrowRight />
                    </button>
                    <button className="btn btn-white">
                      <IconCalendar />
                    </button>
                  </div>
                  <ul className="pagination ml-auto mb-0">
                    <li className="page-item disabled">
                      <a
                        className="page-link"
                        href="#"
                        aria-label="Go to prev page"
                      >
                        Prev
                      </a>
                    </li>
                    <li className="page-item active">
                      <a
                        className="page-link"
                        href="#"
                        aria-label="Go to page number 1"
                      >
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        aria-label="Go to page number 2"
                      >
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        aria-label="Go to page number 3"
                      >
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        aria-label="Go to page number 4"
                      >
                        4
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        aria-label="Go to page number 5"
                      >
                        5
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        aria-label="Go to page number ..."
                      >
                        ...
                      </a>
                    </li>
                    <li className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        aria-label="Go to next page"
                      >
                        Next
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className={`form-row ${
                    this.state.isCardActive ? "hasactive" : ""
                  }`}
                >
                  <div className="col-xl col-md-3 col-sm-6 col-6 my-2">
                    <div
                      className={`ncard d-flex flex-column justify-content-between ${
                        this.state.isCardActive === 1 ? "active" : ""
                      }`}
                      href="javascript:void(0)"
                      onClick={() => this.handleCardActive(1)}
                    >
                      <div className="d-flex align-items-center mb-10">
                        <div className="mr-1 sbox sbox--default">54s</div>
                        <div className="font-medium">Containers at port</div>
                        <div className="ml-auto">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.032 8.30354L7.34899 10.9866L6.40039 10.038L8.60913 7.82924L6.40039 5.6205L7.34899 4.67191L10.032 7.35494C10.294 7.61689 10.294 8.04159 10.032 8.30354Z"
                              fill="#879AAF"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between form-row">
                        <div className="col-6">
                          <div className="bbox bbox--default">
                            <div className="bbox__title">Last Free Days</div>
                            <div className="bbox__number">43</div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="bbox bbox--default">
                            <div className="bbox__title">Pick Up Appts</div>
                            <div className="bbox__number">49</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl col-md-3 col-sm-6 col-6 my-2">
                    <div
                      className={`ncard d-flex flex-column justify-content-between ${
                        this.state.isCardActive === 2 ? "active" : ""
                      }`}
                      href="javascript:void(0)"
                      onClick={() => this.handleCardActive(2)}
                    >
                      <div className="d-flex align-items-center mb-10">
                        <div className="mr-1 sbox sbox--default">72</div>
                        <div className="font-medium">Deliveries Scheduled</div>
                        <div className="ml-auto">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.032 8.30354L7.34899 10.9866L6.40039 10.038L8.60913 7.82924L6.40039 5.6205L7.34899 4.67191L10.032 7.35494C10.294 7.61689 10.294 8.04159 10.032 8.30354Z"
                              fill="#879AAF"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between form-row">
                        <div className="col-6">
                          <div className="bbox bbox--default">
                            <div className="bbox__title">In Port</div>
                            <div className="bbox__number">53</div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="bbox bbox--default">
                            <div className="bbox__title">In Yard</div>
                            <div className="bbox__number">19</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl col-md-3 col-sm-6 col-6 my-2">
                    <div
                      className={`ncard d-flex flex-column justify-content-between ${
                        this.state.isCardActive === 3 ? "active" : ""
                      }`}
                      href="javascript:void(0)"
                      onClick={() => this.handleCardActive(3)}
                    >
                      <div className="d-flex align-items-center mb-10">
                        <div className="mr-1 sbox sbox--default">69</div>
                        <div className="font-medium">Container to Return</div>
                        <div className="ml-auto">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.032 8.30354L7.34899 10.9866L6.40039 10.038L8.60913 7.82924L6.40039 5.6205L7.34899 4.67191L10.032 7.35494C10.294 7.61689 10.294 8.04159 10.032 8.30354Z"
                              fill="#879AAF"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between form-row">
                        <div className="col-6">
                          <div className="bbox bbox--default">
                            <div className="bbox__title">Ready to Return</div>
                            <div className="bbox__number">28</div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="bbox bbox--default">
                            <div className="bbox__title">Not Ready</div>
                            <div className="bbox__number">39</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl col-md-3 col-sm-6 col-6 my-2">
                    <div
                      className={`ncard d-flex flex-column justify-content-between ${
                        this.state.isCardActive === 4 ? "active" : ""
                      }`}
                      href="javascript:void(0)"
                      onClick={() => this.handleCardActive(4)}
                    >
                      <div className="d-flex align-items-center mb-10">
                        <div className="mr-1 sbox sbox--default">75</div>
                        <div className="font-medium">Dropped</div>
                        <div className="ml-auto">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.032 8.30354L7.34899 10.9866L6.40039 10.038L8.60913 7.82924L6.40039 5.6205L7.34899 4.67191L10.032 7.35494C10.294 7.61689 10.294 8.04159 10.032 8.30354Z"
                              fill="#879AAF"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between form-row">
                        <div className="col-6">
                          <div className="bbox bbox--default">
                            <div className="bbox__title">In Yard</div>
                            <div className="bbox__number">42</div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="bbox bbox--default">
                            <div className="bbox__title">At Customer</div>
                            <div className="bbox__number">33</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl col-md-3 col-sm-6 col-6 my-2">
                    <div
                      className={`ncard d-flex flex-column justify-content-between ${
                        this.state.isCardActive === 5 ? "active" : ""
                      }`}
                      href="javascript:void(0)"
                      onClick={() => this.handleCardActive(5)}
                    >
                      <div className="d-flex align-items-center mb-10">
                        <div className="font-medium">Dispatched</div>
                        <div className="ml-auto">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.032 8.30354L7.34899 10.9866L6.40039 10.038L8.60913 7.82924L6.40039 5.6205L7.34899 4.67191L10.032 7.35494C10.294 7.61689 10.294 8.04159 10.032 8.30354Z"
                              fill="#879AAF"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="bbox bbox--tborder">
                        <div className="bbox__number">46</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl col-md-3 col-sm-6 col-6 my-2">
                    <div
                      className={`ncard d-flex flex-column justify-content-between ${
                        this.state.isCardActive === 6 ? "active" : ""
                      }`}
                      href="javascript:void(0)"
                      onClick={() => this.handleCardActive(6)}
                    >
                      <div className="d-flex align-items-center mb-10">
                        <div className="font-medium">Completed</div>
                        <div className="ml-auto">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.032 8.30354L7.34899 10.9866L6.40039 10.038L8.60913 7.82924L6.40039 5.6205L7.34899 4.67191L10.032 7.35494C10.294 7.61689 10.294 8.04159 10.032 8.30354Z"
                              fill="#879AAF"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="bbox bbox--tborder">
                        <div className="bbox__number">14</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-row d-flex align-items-center">
                  <div className="app-search header-search">
                    <span className="search-icon"> </span>
                    <div className="position-relative">
                      <input
                        type="search"
                        className="form-control input-search-left"
                        placeholder="Search..."
                        value=""
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check--gray pr-30">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="Available"
                      />
                      <label className="form-check-label" for="Available">
                        Available
                        <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                          47
                        </span>
                      </label>
                    </div>
                    <div className="form-check form-check--gray pr-30">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="Pending"
                      />
                      <label className="form-check-label" for="Pending">
                        Pending
                        <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                          6
                        </span>
                      </label>
                    </div>
                    <div className="form-check form-check--gray pr-30">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="pre_bull"
                      />
                      <label className="form-check-label" for="pre_bull">
                        Pre Bull
                        <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                          8
                        </span>
                      </label>
                    </div>
                    <div className="form-check form-check--gray pr-30">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="pick_run"
                      />
                      <label className="form-check-label" for="pick_run">
                        Pick & Run
                        <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                          9
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="ml-auto d-flex">
                    <button
                      className="btn btn-outline-light mr-2"
                      onClick={() => this.openPopup()}
                    >
                      <IconFilter className="mr-2" />
                      Filter
                    </button>
                    <div className="position-relative">
                      <button
                        className="btn btn-outline-light position-relative"
                        onClick={this.handleIsPopDrop}
                      >
                        <IconColumn />
                      </button>
                      {this.state.isPopDrop && (
                        <div className="card card--shadow-5 px-20 pt-20 pb-10 popdrop popdrop--right popdrop--bottom mb-0   ">
                          <h5 className="font-16 mb-15">Table columns</h5>
                          <div className="form-row">
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="load-status"
                                />
                                <label
                                  className="form-check-label"
                                  for="load-status"
                                >
                                  Load Status
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="type"
                                />
                                <label className="form-check-label" for="type">
                                  Type
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="move"
                                />
                                <label className="form-check-label" for="move">
                                  Move
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="ssl"
                                />
                                <label className="form-check-label" for="ssl">
                                  SSL
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="driver"
                                />
                                <label
                                  className="form-check-label"
                                  for="driver"
                                >
                                  Driver
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="bol-bkg"
                                />
                                <label
                                  className="form-check-label"
                                  for="bol-bkg"
                                >
                                  BOL/BKG
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="next-address"
                                />
                                <label
                                  className="form-check-label"
                                  for="next-address"
                                >
                                  Next Address
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="port"
                                />
                                <label className="form-check-label" for="port">
                                  Port
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="load"
                                />
                                <label className="form-check-label" for="load">
                                  Load
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="consignee"
                                />
                                <label
                                  className="form-check-label"
                                  for="consignee"
                                >
                                  Consignee
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="eta"
                                />
                                <label className="form-check-label" for="eta">
                                  ETA
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="location"
                                />
                                <label
                                  className="form-check-label"
                                  for="location"
                                >
                                  Location
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="lfd"
                                />
                                <label className="form-check-label" for="lfd">
                                  LFD
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="rtn"
                                />
                                <label className="form-check-label" for="rtn">
                                  RTN
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="cut"
                                />
                                <label className="form-check-label" for="cut">
                                  Cut
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="distance"
                                />
                                <label
                                  className="form-check-label"
                                  for="distance"
                                >
                                  Distance
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="pickup"
                                />
                                <label
                                  className="form-check-label"
                                  for="pickup"
                                >
                                  Pickup
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="bobtail"
                                />
                                <label
                                  className="form-check-label"
                                  for="bobtail"
                                >
                                  Bobtail
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="apt"
                                />
                                <label className="form-check-label" for="apt">
                                  APT
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="vessel-name"
                                />
                                <label
                                  className="form-check-label"
                                  for="vessel-name"
                                >
                                  Vessel Name
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="customer"
                                />
                                <label
                                  className="form-check-label"
                                  for="customer"
                                >
                                  Customer
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="return"
                                />
                                <label
                                  className="form-check-label"
                                  for="return"
                                >
                                  Return
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="container"
                                />
                                <label
                                  className="form-check-label"
                                  for="container"
                                >
                                  Container
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="sealno"
                                />
                                <label
                                  className="form-check-label"
                                  for="sealno"
                                >
                                  Seal No
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="chassis"
                                />
                                <label
                                  className="form-check-label"
                                  for="chassis"
                                >
                                  Chassis
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="pickupn"
                                />
                                <label
                                  className="form-check-label"
                                  for="pickupn"
                                >
                                  Pickup
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="size"
                                />
                                <label className="form-check-label" for="size">
                                  Size
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="business-hour"
                                />
                                <label
                                  className="form-check-label"
                                  for="business-hour"
                                >
                                  Business Hour
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="reset"
                                />
                                <label className="form-check-label" for="reset">
                                  Reset
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="change-status"
                                />
                                <label
                                  className="form-check-label"
                                  for="change-status"
                                >
                                  Change Status
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <StaticTableContent
                handleLoadOpen={() => {
                  this.setState({ openLoad: true });
                }}
              ></StaticTableContent>
            </div>
          ) : (
            <LoadPage />
          )}
        </div>

        {this.state.isPopupOpen && (
          <React.Fragment>
            <div className="bdpopup-backdrop"></div>
            <div className="bdpopup">
              <div className="bdpopup__content">
                <button
                  className="btn btn-none bdpopup__close"
                  onClick={() => this.hidePopup()}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.00023 6.87872L3.00023 0.878723L0.878906 3.00004L6.87891 9.00004L0.878906 15L3.00023 17.1214L9.00023 11.1214L15.0002 17.1214L17.1215 15L11.1215 9.00004L17.1215 3.00004L15.0002 0.878723L9.00023 6.87872Z"
                      fill="white"
                    />
                  </svg>
                </button>
                <div className="d-flex page-fluid align-items-center justify-content-between pt-20">
                  <p className="font-20 mb-0">Filters & Settings</p>
                  <button
                    className="btn btn-outline-light btn-sm mr-2"
                    onClick={() => this.openPopup()}
                  >
                    <svg
                      className="mr-2"
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.18219 1.63468C7.48984 1.43442 6.75803 1.41281 6.05507 1.57186C5.3521 1.73092 4.70088 2.06545 4.16218 2.54426C3.62347 3.02307 3.21483 3.63054 2.9744 4.30999C2.87614 4.58767 2.57138 4.73311 2.2937 4.63485C2.01602 4.53659 1.87058 4.23183 1.96884 3.95415C2.26938 3.10484 2.78017 2.3455 3.45356 1.74699C4.12694 1.14849 4.94096 0.730312 5.81967 0.531493C6.69838 0.332674 7.61313 0.359691 8.47857 0.610021C9.34127 0.859556 10.1269 1.32288 10.7628 1.95697L12.3329 3.37164V1.4654C12.3329 1.17085 12.5717 0.932065 12.8663 0.932065C13.1608 0.932065 13.3996 1.17085 13.3996 1.4654V4.6654C13.3996 4.95995 13.1608 5.19873 12.8663 5.19873H9.66628C9.37173 5.19873 9.13295 4.95995 9.13295 4.6654C9.13295 4.37084 9.37173 4.13206 9.66628 4.13206H11.5838L10.0264 2.72874C10.0223 2.72492 10.0183 2.72104 10.0144 2.7171C9.50489 2.20734 8.87454 1.83495 8.18219 1.63468Z"
                        fill="#172A41"
                      />
                      <path
                        d="M2.50375 7.33206H4.33294C4.62749 7.33206 4.86627 7.09328 4.86627 6.79873C4.86627 6.50418 4.62749 6.26539 4.33294 6.26539H1.13294C0.838391 6.26539 0.599609 6.50418 0.599609 6.79873V9.99872C0.599609 10.2933 0.838391 10.5321 1.13294 10.5321C1.42749 10.5321 1.66628 10.2933 1.66628 9.99872V8.00717L3.23647 9.50716C3.87229 10.1413 4.65796 10.6046 5.52065 10.8541C6.3861 11.1044 7.30085 11.1315 8.17956 10.9326C9.05827 10.7338 9.87229 10.3157 10.5457 9.71714C11.2191 9.11863 11.7298 8.3593 12.0304 7.50999C12.1287 7.23231 11.9832 6.92755 11.7055 6.82929C11.4279 6.73103 11.1231 6.87647 11.0248 7.15415C10.7844 7.8336 10.3758 8.44107 9.83705 8.91988C9.29835 9.39868 8.64713 9.73322 7.94416 9.89228C7.2412 10.0513 6.50939 10.0297 5.81704 9.82946C5.12468 9.62919 4.49434 9.2568 3.98483 8.74704C3.98089 8.7431 3.97689 8.73922 3.97283 8.7354L2.50375 7.33206Z"
                        fill="#172A41"
                      />
                    </svg>
                    Reset
                  </button>
                </div>
                <ul
                  className="nav nav-tabs nav-tabs-custom nav-justified nav-tabs-bottom-border mt-10"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        this.state.isTabActive === 5 ? "active" : ""
                      }`}
                      href="javascript:void(0)"
                      onClick={() => this.handleTabActive(5)}
                    >
                      <svg
                        className="mr-1"
                        width="21"
                        height="17"
                        viewBox="0 0 21 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.28738 15.6699C7.92747 16.0303 7.43907 16.233 6.92969 16.2333C6.4203 16.2335 5.93169 16.0314 5.5714 15.6713L1.46308 11.5652C1.10281 11.2051 0.900391 10.7166 0.900391 10.2072C0.900391 9.69781 1.10281 9.20931 1.46308 8.84921L8.39106 1.92444C8.79112 1.52459 9.33359 1.3 9.89921 1.3H13.7003C14.8785 1.3 15.8337 2.25512 15.8337 3.43333V7.22903C15.8337 7.79424 15.6094 8.33635 15.21 8.73633L8.28738 15.6699Z"
                          stroke="#879AAF"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M20.1001 4.49999V8.55054C20.1001 9.11633 19.8753 9.65895 19.4752 10.059L13.4355 16.0987"
                          stroke="#879AAF"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Subacards
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        this.state.isTabActive === 6 ? "active" : ""
                      }`}
                      href="javascript:void(0)"
                      onClick={() => this.handleTabActive(6)}
                    >
                      <svg
                        className="mr-1"
                        width="18"
                        height="16"
                        viewBox="0 0 18 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.3996 0.3H0.599609L7.31961 8.2464V13.74L10.6796 15.42V8.2464L17.3996 0.3Z"
                          fill="#172A41"
                        />
                      </svg>
                      Filters
                    </a>
                  </li>
                </ul>

                {this.state.isTabActive === 5 && (
                  <div
                    className="page-fluid bdpopup__content__scroll"
                    style={{ height: "calc(100% - 108px)" }}
                  >
                    <div className="mt-20">
                      <h5>Customer</h5>
                      <div className="form-row-sm">
                        <div className="col-md-6 mb-1">
                          <button
                            className={`subcard-item w-100 d-flex align-items-center ${
                              this.state.isSubCardItem && "subcard-item--active"
                            }`}
                            onClick={() => this.handleSubCardItem()}
                          >
                            ABC Distibution
                            <span className="ml-auto">42</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            APM Consi
                            <span className="ml-auto">55</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            Home Depot
                            <span className="ml-auto">42</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            Interline
                            <span className="ml-auto">32</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            National Retail Store
                            <span className="ml-auto">39</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            Noatum logistics
                            <span className="ml-auto">60</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            OEC Group
                            <span className="ml-auto">46</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            Tom Details
                            <span className="ml-auto">76</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-20">
                      <h5>Port</h5>
                      <div className="form-row-sm">
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            APM
                            <span className="ml-auto">17</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            National Retail Store
                            <span className="ml-auto">56</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            Terminal
                            <span className="ml-auto">30</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            Everport
                            <span className="ml-auto">28</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            GCT Bayonne
                            <span className="ml-auto">80</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            WBCT
                            <span className="ml-auto">15</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            Maher Terminal
                            <span className="ml-auto">48</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            ITS - Pier G<span className="ml-auto">42</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-20">
                      <h5>Return</h5>
                      <div className="form-row-sm">
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            APM
                            <span className="ml-auto">70</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            SSA - PIER A<span className="ml-auto">55</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            Everport
                            <span className="ml-auto">22</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            Terminal
                            <span className="ml-auto">41</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            GCT Bayonne
                            <span className="ml-auto">23</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            WBCT
                            <span className="ml-auto">53</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            PNCT
                            <span className="ml-auto">32</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-20">
                      <h5>Readiness for Pick Up</h5>
                      <div className="form-row-sm">
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            Ready
                            <span className="ml-auto">48</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            Not Ready
                            <span className="ml-auto">50</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-20">
                      <h5>Consignee</h5>
                      <div className="form-row-sm">
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            ABC Distibution
                            <span className="ml-auto">53</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            Accem Warehouse
                            <span className="ml-auto">45</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            Home Depot
                            <span className="ml-auto">74</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            Amalgamed Textiles
                            <span className="ml-auto">45</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            Interline
                            <span className="ml-auto">23</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            National Retail Store
                            <span className="ml-auto">33</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            Perfect Christmas Trees
                            <span className="ml-auto">65</span>
                          </button>
                        </div>
                        <div className="col-md-6 mb-1">
                          <button className="subcard-item w-100 d-flex align-items-center">
                            NRS Warehouse
                            <span className="ml-auto">50</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.isTabActive === 6 && (
                  <React.Fragment>
                    <div
                      className="page-fluid bdpopup__content__scroll"
                      style={{ height: "calc(100% - 182px)" }}
                    >
                      <div className="row">
                        <div className="col-md-6 d-flex align-items-center mt-20">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Available2"
                            />
                            <label
                              className="form-check-label"
                              for="Available2"
                            >
                              Available
                            </label>
                          </div>
                          <div className="ml-auto font-medium">47</div>
                        </div>
                        <div className="col-md-6 d-flex align-items-center mt-20">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Pending2"
                            />
                            <label className="form-check-label" for="Pending2">
                              Pending
                            </label>
                          </div>
                          <div className="ml-auto font-medium">3</div>
                        </div>
                        <div className="col-md-6 d-flex align-items-center mt-20">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="pre-bull-2"
                            />
                            <label
                              className="form-check-label"
                              for="pre-bull-2"
                            >
                              Pre Bull
                            </label>
                          </div>
                          <div className="ml-auto font-medium">3</div>
                        </div>
                        <div className="col-md-6 d-flex align-items-center mt-20">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="pick-run-2"
                            />
                            <label
                              className="form-check-label"
                              for="pick-run-2"
                            >
                              Pick & Run
                            </label>
                          </div>
                          <div className="ml-auto font-medium">3</div>
                        </div>
                      </div>
                      <div className="hr-light"></div>
                      <React.Fragment>
                        <div className="form-group form-row">
                          <label className="col-md-5 col-form-label">
                            APT From
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select APT from date"
                            ></input>
                          </div>
                        </div>
                        <div className="form-group form-row">
                          <label className="col-md-5 col-form-label">
                            APT To
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select APT from date"
                            ></input>
                          </div>
                        </div>
                      </React.Fragment>
                      <div className="hr-light"></div>
                      <React.Fragment>
                        <div className="form-group form-row">
                          <label className="col-md-5 col-form-label">
                            Pickup APT From
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select Pickup APT from date"
                            ></input>
                          </div>
                        </div>
                        <div className="form-group form-row">
                          <label className="col-md-5 col-form-label">
                            Pickup APT From
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select Pickup APT from date"
                            ></input>
                          </div>
                        </div>
                      </React.Fragment>
                      <div className="hr-light"></div>
                      <React.Fragment>
                        <div className="form-group form-row">
                          <label className="col-md-5 col-form-label">
                            Load Type
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select..."
                            ></input>
                          </div>
                        </div>
                        <div className="form-group form-row">
                          <label className="col-md-5 col-form-label">
                            Customer
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select..."
                            ></input>
                          </div>
                        </div>
                        <div className="form-group form-row">
                          <label className="col-md-5 col-form-label">
                            Port
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select..."
                            ></input>
                          </div>
                        </div>
                        <div className="form-group form-row">
                          <label className="col-md-5 col-form-label">
                            Consignee
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select..."
                            ></input>
                          </div>
                        </div>
                        <div className="form-group form-row">
                          <label className="col-md-5 col-form-label">
                            Return
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select..."
                            ></input>
                          </div>
                        </div>
                        <div className="form-group form-row">
                          <label className="col-md-5 col-form-label">
                            Driver
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select..."
                            ></input>
                          </div>
                        </div>
                        <div className="form-group form-row">
                          <label className="col-md-5 col-form-label">
                            Created by
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select..."
                            ></input>
                          </div>
                        </div>
                        <div className="form-group form-row">
                          <label className="col-md-5 col-form-label">
                            Status
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select..."
                            ></input>
                          </div>
                        </div>
                      </React.Fragment>
                      <div className="hr-light"></div>
                      <React.Fragment>
                        <div className="form-group form-row">
                          <label className="col-md-5 col-form-label">
                            Container Type
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select"
                            ></input>
                          </div>
                        </div>
                        <div className="form-group form-row">
                          <label className="col-md-5 col-form-label">
                            Container Size
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select"
                            ></input>
                          </div>
                        </div>
                        <div className="form-group form-row">
                          <label className="col-md-5 col-form-label">SSL</label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select"
                            ></input>
                          </div>
                        </div>
                        <div className="form-group form-row">
                          <label className="col-md-5 col-form-label">
                            BOL/BKG
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select"
                            ></input>
                          </div>
                        </div>
                        <div className="form-group form-row">
                          <label className="col-md-5 col-form-label">
                            Vessel Name
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select"
                            ></input>
                          </div>
                        </div>
                        <div className="form-group form-row">
                          <label className="col-md-5 col-form-label">
                            Return
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select"
                            ></input>
                          </div>
                        </div>
                        <div className="form-group form-row">
                          <label className="col-md-5 col-form-label">
                            Seal No
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select"
                            ></input>
                          </div>
                        </div>
                        <div className="form-group form-row">
                          <label className="col-md-5 col-form-label">
                            Pickup #
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Select"
                            ></input>
                          </div>
                        </div>
                      </React.Fragment>
                    </div>
                    <div className="bdpopup__footer page-fluid">
                      <button className="btn btn-primary">Apply</button>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
        {this.state.isDriverList && (
          <React.Fragment>
            <div className="bdpopup-backdrop"></div>
            <div className="bdpopup">
              <div className="bdpopup__content">
                <button
                  className="btn btn-none bdpopup__close"
                  onClick={() => this.handleHideDriverList()}
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.00023 6.87872L3.00023 0.878723L0.878906 3.00004L6.87891 9.00004L0.878906 15L3.00023 17.1214L9.00023 11.1214L15.0002 17.1214L17.1215 15L11.1215 9.00004L17.1215 3.00004L15.0002 0.878723L9.00023 6.87872Z"
                      fill="white"
                    />
                  </svg>
                </button>
                <div className="d-flex page-fluid align-items-center justify-content-between pt-20">
                  <p className="font-20 mb-0">Driver List</p>
                  <button className="btn btn-outline-light btn-sm mr-2">
                    <svg
                      className="mr-2"
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.18219 1.63468C7.48984 1.43442 6.75803 1.41281 6.05507 1.57186C5.3521 1.73092 4.70088 2.06545 4.16218 2.54426C3.62347 3.02307 3.21483 3.63054 2.9744 4.30999C2.87614 4.58767 2.57138 4.73311 2.2937 4.63485C2.01602 4.53659 1.87058 4.23183 1.96884 3.95415C2.26938 3.10484 2.78017 2.3455 3.45356 1.74699C4.12694 1.14849 4.94096 0.730312 5.81967 0.531493C6.69838 0.332674 7.61313 0.359691 8.47857 0.610021C9.34127 0.859556 10.1269 1.32288 10.7628 1.95697L12.3329 3.37164V1.4654C12.3329 1.17085 12.5717 0.932065 12.8663 0.932065C13.1608 0.932065 13.3996 1.17085 13.3996 1.4654V4.6654C13.3996 4.95995 13.1608 5.19873 12.8663 5.19873H9.66628C9.37173 5.19873 9.13295 4.95995 9.13295 4.6654C9.13295 4.37084 9.37173 4.13206 9.66628 4.13206H11.5838L10.0264 2.72874C10.0223 2.72492 10.0183 2.72104 10.0144 2.7171C9.50489 2.20734 8.87454 1.83495 8.18219 1.63468Z"
                        fill="#172A41"
                      />
                      <path
                        d="M2.50375 7.33206H4.33294C4.62749 7.33206 4.86627 7.09328 4.86627 6.79873C4.86627 6.50418 4.62749 6.26539 4.33294 6.26539H1.13294C0.838391 6.26539 0.599609 6.50418 0.599609 6.79873V9.99872C0.599609 10.2933 0.838391 10.5321 1.13294 10.5321C1.42749 10.5321 1.66628 10.2933 1.66628 9.99872V8.00717L3.23647 9.50716C3.87229 10.1413 4.65796 10.6046 5.52065 10.8541C6.3861 11.1044 7.30085 11.1315 8.17956 10.9326C9.05827 10.7338 9.87229 10.3157 10.5457 9.71714C11.2191 9.11863 11.7298 8.3593 12.0304 7.50999C12.1287 7.23231 11.9832 6.92755 11.7055 6.82929C11.4279 6.73103 11.1231 6.87647 11.0248 7.15415C10.7844 7.8336 10.3758 8.44107 9.83705 8.91988C9.29835 9.39868 8.64713 9.73322 7.94416 9.89228C7.2412 10.0513 6.50939 10.0297 5.81704 9.82946C5.12468 9.62919 4.49434 9.2568 3.98483 8.74704C3.98089 8.7431 3.97689 8.73922 3.97283 8.7354L2.50375 7.33206Z"
                        fill="#172A41"
                      />
                    </svg>
                    Reset
                  </button>
                </div>
                <div className="px-30 py-20">
                  <div className="app-search">
                    <input
                      type="text"
                      className="input-search-left form-control"
                      placeholder="Search.."
                    />
                    <span className="search-icon"></span>
                  </div>
                </div>
                <div
                  className="dlist px-30"
                  style={{ height: "calc(100% - 116px)" }}
                >
                  <div
                    className="dlist__card dlist__card--outline mb-1"
                    onClick={this.handleTrackCard}
                  >
                    <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                      <div className="flex-shrink-0 mr-2 align-self-center">
                        <img
                          src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                          className="avatar-xs rounded-circle"
                          width="30"
                          alt=""
                        />
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="#" className="text-dark">
                            Robert Fox
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>Albany</span>
                          <svg
                            className="mx-2"
                            width="4"
                            height="5"
                            viewBox="0 0 4 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2 4.39773C3.03551 4.39773 3.875 3.55824 3.875 2.52273C3.875 1.48722 3.03551 0.647727 2 0.647727C0.964489 0.647727 0.125 1.48722 0.125 2.52273C0.125 3.55824 0.964489 4.39773 2 4.39773Z"
                              fill="#687D96"
                            />
                          </svg>
                          <span>12 days ago</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-light font-12">
                          (702) 555-0122
                        </span>
                      </div>
                    </div>
                    <div className="dlist__load">
                      <div className="dlist__load-current">
                        <div className="d-flex align-items-start p-2">
                          <div className="flex-grow-1">
                            <h5 className="font-14 mb-1">
                              <a href="#" className="text-dark">
                                Accem - Hook
                              </a>
                            </h5>
                            <p className="text-muted mb-0">Springville, AR</p>
                            <span className="badge badge-secondary font-12 mt-2">
                              +2 more loads
                            </span>
                          </div>
                          <div className="flex-shrink-0">
                            <span className="badge badge-soft-brown">
                              Departed
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dlist__card dlist__card--outline mb-1">
                    <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                      <div className="flex-shrink-0 mr-2 align-self-center">
                        <img
                          src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
                          className="avatar-xs rounded-circle"
                          width="30"
                          alt=""
                        />
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="#" className="text-dark">
                            Ronald Richards
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>Albany</span>
                          <svg
                            className="mx-2"
                            width="4"
                            height="5"
                            viewBox="0 0 4 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2 4.39773C3.03551 4.39773 3.875 3.55824 3.875 2.52273C3.875 1.48722 3.03551 0.647727 2 0.647727C0.964489 0.647727 0.125 1.48722 0.125 2.52273C0.125 3.55824 0.964489 4.39773 2 4.39773Z"
                              fill="#687D96"
                            />
                          </svg>
                          <span>12 days ago</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-light font-12">
                          (702) 555-0122
                        </span>
                      </div>
                    </div>
                    <div className="dlist__load">
                      <div className="dlist__load-current">
                        <div className="d-flex align-items-start p-2">
                          <div className="flex-grow-1">
                            <h5 className="font-14 mb-1">
                              <a href="#" className="text-dark">
                                Accem - Hook
                              </a>
                            </h5>
                            <p className="text-muted mb-0">Springville, AR</p>
                            <span className="badge badge-secondary font-12 mt-2">
                              +2 more loads
                            </span>
                          </div>
                          <div className="flex-shrink-0">
                            <span className="badge badge-soft-info">
                              Enroute to Delivery load
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dlist__card dlist__card--outline mb-1">
                    <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                      <div className="flex-shrink-0 mr-2 align-self-center">
                        <img
                          src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                          className="avatar-xs rounded-circle"
                          width="30"
                          alt=""
                        />
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="#" className="text-dark">
                            Cody Fisher
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>Sacramento, CA</span>
                          <svg
                            className="mx-2"
                            width="4"
                            height="5"
                            viewBox="0 0 4 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2 4.39773C3.03551 4.39773 3.875 3.55824 3.875 2.52273C3.875 1.48722 3.03551 0.647727 2 0.647727C0.964489 0.647727 0.125 1.48722 0.125 2.52273C0.125 3.55824 0.964489 4.39773 2 4.39773Z"
                              fill="#687D96"
                            />
                          </svg>
                          <span>2 days ago</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-light font-12">
                          (702) 555-0122
                        </span>
                      </div>
                    </div>
                    <div className="dlist__load">
                      <div className="dlist__load-current">
                        <div className="d-flex align-items-start p-2">
                          <div className="flex-grow-1">
                            <h5 className="font-14 mb-1">
                              <a href="#" className="text-dark">
                                Accem - Hook
                              </a>
                            </h5>
                            <p className="text-muted mb-0">Springville, AR</p>
                          </div>
                          <div className="flex-shrink-0">
                            <span className="badge badge-soft-success">
                              Dropped
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dlist__card dlist__card--outline mb-1">
                    <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                      <div className="flex-shrink-0 mr-2 align-self-center">
                        <img
                          src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                          className="avatar-xs rounded-circle"
                          width="30"
                          alt=""
                        />
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="#" className="text-dark">
                            Jerome Bell
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>San Diego, CA</span>
                          <svg
                            className="mx-2"
                            width="4"
                            height="5"
                            viewBox="0 0 4 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2 4.39773C3.03551 4.39773 3.875 3.55824 3.875 2.52273C3.875 1.48722 3.03551 0.647727 2 0.647727C0.964489 0.647727 0.125 1.48722 0.125 2.52273C0.125 3.55824 0.964489 4.39773 2 4.39773Z"
                              fill="#687D96"
                            />
                          </svg>
                          <span>a week ago</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-light font-12">
                          (702) 555-0122
                        </span>
                      </div>
                    </div>
                    <div className="dlist__load">
                      <div className="dlist__load-current">
                        <div className="d-flex align-items-start p-2">
                          <div className="flex-grow-1">
                            <h5 className="font-14 my-1 text-muted">
                              <svg
                                width="17"
                                height="18"
                                viewBox="0 0 17 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.3418 6.04004V9.95648L11.4204 11.8334"
                                  stroke="#BFCCDA"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.2626 2.03911C8.78229 1.69975 7.18826 1.79491 5.67928 2.42056C1.81336 4.02217 -0.0223439 8.45396 1.57842 12.3199C3.17919 16.1858 7.61182 18.0215 11.4777 16.4207C14.9437 14.985 16.7693 11.2749 15.9575 7.73652"
                                  stroke="#BFCCDA"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M13.6309 1H16.1571L13.6309 4.36826H16.1571"
                                  stroke="#BFCCDA"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <span className="ml-2">
                                Not any assigned loads
                              </span>
                            </h5>
                          </div>
                          <div className="flex-shrink-0">
                            <button className="btn btn-sm btn-primary">
                              <i className="uil uil-plus mr-2"></i>
                              Assign Load
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dlist__card dlist__card--outline mb-1">
                    <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                      <div className="flex-shrink-0 mr-2 align-self-center">
                        <img
                          src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                          className="avatar-xs rounded-circle"
                          width="30"
                          alt=""
                        />
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="#" className="text-dark">
                            Jerome Bell
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>San Diego, CA</span>
                          <svg
                            className="mx-2"
                            width="4"
                            height="5"
                            viewBox="0 0 4 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2 4.39773C3.03551 4.39773 3.875 3.55824 3.875 2.52273C3.875 1.48722 3.03551 0.647727 2 0.647727C0.964489 0.647727 0.125 1.48722 0.125 2.52273C0.125 3.55824 0.964489 4.39773 2 4.39773Z"
                              fill="#687D96"
                            />
                          </svg>
                          <span>a week ago</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-light font-12">
                          (702) 555-0122
                        </span>
                      </div>
                    </div>
                    <div className="dlist__load">
                      <div className="dlist__load-current">
                        <div className="d-flex align-items-start p-2">
                          <div className="flex-grow-1">
                            <h5 className="font-14 my-1 text-muted">
                              <svg
                                width="17"
                                height="18"
                                viewBox="0 0 17 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.3418 6.04004V9.95648L11.4204 11.8334"
                                  stroke="#BFCCDA"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.2626 2.03911C8.78229 1.69975 7.18826 1.79491 5.67928 2.42056C1.81336 4.02217 -0.0223439 8.45396 1.57842 12.3199C3.17919 16.1858 7.61182 18.0215 11.4777 16.4207C14.9437 14.985 16.7693 11.2749 15.9575 7.73652"
                                  stroke="#BFCCDA"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M13.6309 1H16.1571L13.6309 4.36826H16.1571"
                                  stroke="#BFCCDA"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <span className="ml-2">
                                Not any assigned loads
                              </span>
                            </h5>
                          </div>
                          <div className="flex-shrink-0">
                            <button className="btn btn-sm btn-primary">
                              <i className="uil uil-plus mr-2"></i>
                              Assign Load
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dlist__card dlist__card--outline mb-1">
                    <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                      <div className="flex-shrink-0 mr-2 align-self-center">
                        <img
                          src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                          className="avatar-xs rounded-circle"
                          width="30"
                          alt=""
                        />
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="#" className="text-dark">
                            Jerome Bell
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>San Diego, CA</span>
                          <svg
                            className="mx-2"
                            width="4"
                            height="5"
                            viewBox="0 0 4 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2 4.39773C3.03551 4.39773 3.875 3.55824 3.875 2.52273C3.875 1.48722 3.03551 0.647727 2 0.647727C0.964489 0.647727 0.125 1.48722 0.125 2.52273C0.125 3.55824 0.964489 4.39773 2 4.39773Z"
                              fill="#687D96"
                            />
                          </svg>
                          <span>a week ago</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-light font-12">
                          (702) 555-0122
                        </span>
                      </div>
                    </div>
                    <div className="dlist__load">
                      <div className="dlist__load-current">
                        <div className="d-flex align-items-start p-2">
                          <div className="flex-grow-1">
                            <h5 className="font-14 my-1 text-muted">
                              <svg
                                width="17"
                                height="18"
                                viewBox="0 0 17 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.3418 6.04004V9.95648L11.4204 11.8334"
                                  stroke="#BFCCDA"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.2626 2.03911C8.78229 1.69975 7.18826 1.79491 5.67928 2.42056C1.81336 4.02217 -0.0223439 8.45396 1.57842 12.3199C3.17919 16.1858 7.61182 18.0215 11.4777 16.4207C14.9437 14.985 16.7693 11.2749 15.9575 7.73652"
                                  stroke="#BFCCDA"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M13.6309 1H16.1571L13.6309 4.36826H16.1571"
                                  stroke="#BFCCDA"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <span className="ml-2">
                                Not any assigned loads
                              </span>
                            </h5>
                          </div>
                          <div className="flex-shrink-0">
                            <button className="btn btn-sm btn-primary">
                              <i className="uil uil-plus mr-2"></i>
                              Assign Load
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dlist__card dlist__card--outline mb-1">
                    <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                      <div className="flex-shrink-0 mr-2 align-self-center">
                        <img
                          src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                          className="avatar-xs rounded-circle"
                          width="30"
                          alt=""
                        />
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="#" className="text-dark">
                            Jerome Bell
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>San Diego, CA</span>
                          <svg
                            className="mx-2"
                            width="4"
                            height="5"
                            viewBox="0 0 4 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2 4.39773C3.03551 4.39773 3.875 3.55824 3.875 2.52273C3.875 1.48722 3.03551 0.647727 2 0.647727C0.964489 0.647727 0.125 1.48722 0.125 2.52273C0.125 3.55824 0.964489 4.39773 2 4.39773Z"
                              fill="#687D96"
                            />
                          </svg>
                          <span>a week ago</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-light font-12">
                          (702) 555-0122
                        </span>
                      </div>
                    </div>
                    <div className="dlist__load">
                      <div className="dlist__load-current">
                        <div className="d-flex align-items-start p-2">
                          <div className="flex-grow-1">
                            <h5 className="font-14 my-1 text-muted">
                              <svg
                                width="17"
                                height="18"
                                viewBox="0 0 17 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.3418 6.04004V9.95648L11.4204 11.8334"
                                  stroke="#BFCCDA"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.2626 2.03911C8.78229 1.69975 7.18826 1.79491 5.67928 2.42056C1.81336 4.02217 -0.0223439 8.45396 1.57842 12.3199C3.17919 16.1858 7.61182 18.0215 11.4777 16.4207C14.9437 14.985 16.7693 11.2749 15.9575 7.73652"
                                  stroke="#BFCCDA"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M13.6309 1H16.1571L13.6309 4.36826H16.1571"
                                  stroke="#BFCCDA"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <span className="ml-2">
                                Not any assigned loads
                              </span>
                            </h5>
                          </div>
                          <div className="flex-shrink-0">
                            <button className="btn btn-sm btn-primary">
                              <i className="uil uil-plus mr-2"></i>
                              Assign Load
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dlist__card dlist__card--outline mb-1">
                    <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                      <div className="flex-shrink-0 mr-2 align-self-center">
                        <img
                          src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                          className="avatar-xs rounded-circle"
                          width="30"
                          alt=""
                        />
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="#" className="text-dark">
                            Jerome Bell
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>San Diego, CA</span>
                          <svg
                            className="mx-2"
                            width="4"
                            height="5"
                            viewBox="0 0 4 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2 4.39773C3.03551 4.39773 3.875 3.55824 3.875 2.52273C3.875 1.48722 3.03551 0.647727 2 0.647727C0.964489 0.647727 0.125 1.48722 0.125 2.52273C0.125 3.55824 0.964489 4.39773 2 4.39773Z"
                              fill="#687D96"
                            />
                          </svg>
                          <span>a week ago</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-light font-12">
                          (702) 555-0122
                        </span>
                      </div>
                    </div>
                    <div className="dlist__load">
                      <div className="dlist__load-current">
                        <div className="d-flex align-items-start p-2">
                          <div className="flex-grow-1">
                            <h5 className="font-14 my-1 text-muted">
                              <svg
                                width="17"
                                height="18"
                                viewBox="0 0 17 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.3418 6.04004V9.95648L11.4204 11.8334"
                                  stroke="#BFCCDA"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.2626 2.03911C8.78229 1.69975 7.18826 1.79491 5.67928 2.42056C1.81336 4.02217 -0.0223439 8.45396 1.57842 12.3199C3.17919 16.1858 7.61182 18.0215 11.4777 16.4207C14.9437 14.985 16.7693 11.2749 15.9575 7.73652"
                                  stroke="#BFCCDA"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M13.6309 1H16.1571L13.6309 4.36826H16.1571"
                                  stroke="#BFCCDA"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <span className="ml-2">
                                Not any assigned loads
                              </span>
                            </h5>
                          </div>
                          <div className="flex-shrink-0">
                            <button className="btn btn-sm btn-primary">
                              <i className="uil uil-plus mr-2"></i>
                              Assign Load
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dlist__card dlist__card--outline mb-1">
                    <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                      <div className="flex-shrink-0 mr-2 align-self-center">
                        <img
                          src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                          className="avatar-xs rounded-circle"
                          width="30"
                          alt=""
                        />
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="#" className="text-dark">
                            Jerome Bell
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>San Diego, CA</span>
                          <svg
                            className="mx-2"
                            width="4"
                            height="5"
                            viewBox="0 0 4 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2 4.39773C3.03551 4.39773 3.875 3.55824 3.875 2.52273C3.875 1.48722 3.03551 0.647727 2 0.647727C0.964489 0.647727 0.125 1.48722 0.125 2.52273C0.125 3.55824 0.964489 4.39773 2 4.39773Z"
                              fill="#687D96"
                            />
                          </svg>
                          <span>a week ago</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-light font-12">
                          (702) 555-0122
                        </span>
                      </div>
                    </div>
                    <div className="dlist__load">
                      <div className="dlist__load-current">
                        <div className="d-flex align-items-start p-2">
                          <div className="flex-grow-1">
                            <h5 className="font-14 my-1 text-muted">
                              <svg
                                width="17"
                                height="18"
                                viewBox="0 0 17 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.3418 6.04004V9.95648L11.4204 11.8334"
                                  stroke="#BFCCDA"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10.2626 2.03911C8.78229 1.69975 7.18826 1.79491 5.67928 2.42056C1.81336 4.02217 -0.0223439 8.45396 1.57842 12.3199C3.17919 16.1858 7.61182 18.0215 11.4777 16.4207C14.9437 14.985 16.7693 11.2749 15.9575 7.73652"
                                  stroke="#BFCCDA"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M13.6309 1H16.1571L13.6309 4.36826H16.1571"
                                  stroke="#BFCCDA"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <span className="ml-2">
                                Not any assigned loads
                              </span>
                            </h5>
                          </div>
                          <div className="flex-shrink-0">
                            <button className="btn btn-sm btn-primary">
                              <i className="uil uil-plus mr-2"></i>
                              Assign Load
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        {/* Send Email Modal */}
        <Modal show={this.state.showSendEmailModal} centered animation={false}>
          <Modal.Header>
            <Modal.Title>Send Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-wrapper">
              <form>
                <div className="form-group">
                  <label className="col-form-label">To</label>
                  <ReactTags
                    tags={tags}
                    inline={true}
                    inputFieldPosition="inline"
                    handleDelete={this.handleDelete}
                    handleAddition={this.handleAddition}
                    handleDrag={this.handleDrag}
                    handleTagClick={this.handleTagClick}
                    placeholder="curtis.weaver@example.com"
                  />

                  <span className="text-gray-400 d-block mt-1">
                    You can add multiple email addresses
                  </span>
                </div>
                <div className="form-group">
                  <label className="col-form-label">CC/BCC</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter CC Email"
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label">Subject</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Subject"
                  />
                </div>
                <div className="editor">
                  <label className="col-form-label">Message</label>
                  <Editor
                    editorState={editorState}
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onEditorStateChange}
                    placeholder="Enter Messsage"
                  />
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={this.handleHideSendEmailModal}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={this.handleHideSendEmailModal}
            >
              Send Email
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default MainCustomer;
