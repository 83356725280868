import React from 'react';
import ReactApexChart from 'react-apexcharts';

const series = [25, 25, 25, 25];

const options = {
  labels: ['Yamaha', 'Keuhn Nag', 'All Others', 'Macys'],
  colors: ['#C4E1C9', '#1E2478', '#3D71B3', '#6DB8D1'],
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
      donut: {
        size: '85%',
        tooltip: {
          show: false,
          enabled: false,
        },
        legend: {
          position: 'top',
        },
        labels: {
          show: true,
          total: {
            show: true,
            showAlways: true,
            fontSize: '24px',
            color: '#2787AB',
          },
        },
      },
    },
  },
  tooltip: {
    enabled: false,
  },

  onItemClick: {
    toggleDataSeries: false,
  },
  onItemHover: {
    highlightDataSeries: false,
  },
  legend: {
    show: false,
    showForSingleSeries: true,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: 'bottom',
    horizontalAlign: 'center',
    floating: false,
    fontSize: '16px',
    fontFamily: 'Inter',
    fontWeight: 500,
    // formatter: function (seriesName, opts) {
    //   // return [seriesName, ' - ', opts.w.globals.series[opts.seriesIndex]];
    //   return (
    //     '<div class="d-flex justify-content-between">' +
    //     '<div class="mb-20">' +
    //     'Revenue' +
    //     '</div>' +
    //     '<div class="pl-90 mb-20">' +
    //     '30%' +
    //     '</div>' +
    //     '</div>'
    //   );
    // },
    inverseOrder: false,
    width: undefined,
    height: undefined,
    offsetX: 0,
    offsetY: 0,
    labels: {
      colors: undefined,
      useSeriesColors: false,
    },
    markers: {
      width: 8,
      height: 4,
      strokeWidth: 0,
      strokeColor: '#fff',
      fillColors: undefined,
      radius: 0,
      customHTML: undefined,
      onClick: undefined,
      offsetX: 0,
      offsetY: 0,
    },
    // itemMargin: {
    //   horizontal: 5,
    //   vertical: 20,
    // },
    onItemClick: {
      toggleDataSeries: false,
    },
    onItemHover: {
      highlightDataSeries: false,
    },
  },
};

const RadialChart = (props) => {
  return (
    <>
      <div className="card" style={{ height: '726px' }}>
        <div className="font-16 px-20 pt-20 pb-15 font-weight-500">
          Customer Revenue % of Sales
        </div>
        <div className="p-20 d-flex justify-content-center align-items-center">
          <div className="chart-wrap py-55">
            <div id="donutchart">
              <ReactApexChart
                options={options}
                series={series}
                type="donut"
                height={340}
                width={340}
              />
            </div>
          </div>
        </div>
        <div className="legend p-20">
          <div className="d-flex justify-content-between pb-20">
            <div className="d-flex">
              <div
                className="marker mt-1 mr-1"
                style={{ height: '4px', width: '8px', background: '#1E2478' }}
              ></div>
              <div className="font-weight-500 font-16">Yamaha</div>
            </div>
            <div className="font-weight-500 font-16">15%</div>
          </div>
          <div className="d-flex justify-content-between pb-20">
            <div className="d-flex">
              <div
                className="marker mt-1 mr-1"
                style={{ height: '4px', width: '8px', background: '#3D71B3' }}
              ></div>
              <div className="font-weight-500 font-16">Keuhn Nag</div>
            </div>
            <div className="font-weight-500 font-16">48%</div>
          </div>
          <div className="d-flex justify-content-between pb-20">
            <div className="d-flex">
              <div
                className="marker mt-1 mr-1"
                style={{ height: '4px', width: '8px', background: '#C4E1C9' }}
              ></div>
              <div className="font-weight-500 font-16">All Others</div>
            </div>
            <div className="font-weight-500 font-16">25%</div>
          </div>
          <div className="d-flex justify-content-between pb-20">
            <div className="d-flex">
              <div
                className="marker mt-1 mr-1"
                style={{ height: '4px', width: '8px', background: '#C4E1C9' }}
              ></div>
              <div className="font-weight-500 font-16">Macys</div>
            </div>
            <div className="font-weight-500 font-16">23%</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RadialChart;
