import React, { useState } from 'react';
import {
  IconColumn,
  IconFilter,
  IconCalendar,
} from '../../../../../common/icons/index';
import CustomSelect from '../../../../../common/CustomSelect';
import FilterPop from './filterPop';

const options = [
  { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
  { value: 'John Doe', label: 'John Doe' },
  { value: 'Alex Mendes', label: 'Alex Mendes' },
];

const SearchFilter = () => {
  //Filter
  const [ShowFilter, setShowFilter] = useState(false);
  const handleShowFilter = (event) => {
    setShowFilter(true);
  };
  const handleHideFilter = (event) => {
    setShowFilter(false);
  };
  const [isPopDrop, setIsPopDrop] = useState(false);
  const handlePopDrop = (event) => {
    setIsPopDrop(!isPopDrop);
  };
  return (
    <React.Fragment>
      <div className="filter-row d-flex align-items-center mb-10">
        <div className="form-group mb-0 mr-30">
          <input
            type="text"
            className="form-control"
            placeholder="Select Date & Time"
          />
          <div className="input-icon">
            <IconCalendar />
          </div>
        </div>
        {/* Select Customer */}
        <CustomSelect
          name={'name'}
          options={options}
          value={1}
          isMulti={true}
          isClearable={true}
          size="small"
          placement="right"
          placeholder="Select SSL"
          className="w-150 mr-1"
        />
        <CustomSelect
          name={'name'}
          options={options}
          value={1}
          isMulti={true}
          isClearable={true}
          size="small"
          placement="right"
          placeholder="Select Container Type"
          className="w-200 mr-30"
        />
        <div className="">
          <button className=" btn btn-primary">Print Record</button>
        </div>
      </div>
      {/* Filter Popup */}
      {ShowFilter && <FilterPop hideFilter={handleHideFilter} />}
    </React.Fragment>
  );
};

export default SearchFilter;
