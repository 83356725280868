import React, {useState} from 'react';
import { Modal } from "react-bootstrap";
import TabContent1 from './TabContent1.js';
import TabContent2 from './TabContent2.js';
import TabContent3 from './TabContent3.js';

const Index = ({ show }) => {
  const [activeTab, setActiveTab] = useState('tab1')
  
  return (
    <Modal
      show={show}
      dialogClassName="modal-width"
      aria-labelledby="example-custom-modal-styling-title"
      >
      <Modal.Body className='py-5 px-5'>
        <div className='tab_topbar d-flex justify-content-between'>
          <span id='tab1' className={"w-100 text-center pb-2 border-bottom-1 border-" + (activeTab === "tab1" ? "primary" : "whitesmoke")} onClick={() => setActiveTab('tab1')}>Company Info</span>
          <span id='tab2' className={"w-100 text-center mx-2 border-bottom-1 border-" + (activeTab === "tab2" ? "primary" : "whitesmoke")} onClick={() => setActiveTab('tab2')}>Account Setup</span>
          <span id='tab3' className={"w-100 text-center border-bottom-1 border-" + (activeTab === "tab3" ? "primary" : "whitesmoke")} onClick={() => setActiveTab('tab3')}>How to use drayOS</span>
        </div>
        {
          activeTab === 'tab1' && <TabContent1 />
        }
        {
          activeTab === 'tab2' && <TabContent2 />
        }
        {
          activeTab === 'tab3' && <TabContent3 />
        }
      </Modal.Body>
    </Modal>
  )
}

export default Index
