import React, { useState } from "react";
import ReportsTabs from "../common-elements/ReportsTabs";
import SearchFilter from "./elements/searchFilter";
import StaticTableContent from "./elements/StaticTableContent";
import TopPage from "./elements/toppage";

const AgingReport = () => {
  
  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <ReportsTabs activeTab={15} />
        <div className="page-fluid">
          <TopPage />
          <SearchFilter />
          <StaticTableContent />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AgingReport;
