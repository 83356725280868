import React from 'react';

const Audit = () => {
  return (
    <React.Fragment>
      <div className="tab bg-gray-50 p-20">
        <div className="table">
          <table className="table table-card table-card--ls mb-10">
            <thead>
              <tr>
                <th>User</th>
                <th>Type</th>
                <th>Time</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="avatar-circle avatar-xs mr-1">A</span>
                    James Doe
                  </div>
                </td>
                <td>
                  <span className="badge badge-sm badge-gray-100">
                    Load Status Update
                  </span>
                </td>
                <td>
                  <div>02 July, 2021</div>
                  <div className="text-muted">08:50pm</div>
                </td>
                <td>
                  Load Status{' '}
                  <span className="badge badge-sm badge-soft-green">
                    Dropped
                  </span>{' '}
                  removed
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="avatar-circle avatar-xs mr-1">A</span>
                    Frank Lampard
                  </div>
                </td>
                <td>
                  <span className="badge badge-sm badge-gray-100">
                    Load Status Update
                  </span>
                </td>
                <td>
                  <div>02 July, 2021</div>
                  <div className="text-muted">08:50pm</div>
                </td>
                <td>
                  Load Status{' '}
                  <span className="badge badge-sm badge-soft-green">
                    Dropped
                  </span>{' '}
                  changed to{' '}
                  <span className="badge badge-sm badge-soft-warning">
                    Pending
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="avatar-circle avatar-xs mr-1">K</span>
                    Kristin Waston
                  </div>
                </td>
                <td>
                  <span className="badge badge-sm badge-gray-100">
                    Load Status Update
                  </span>
                </td>
                <td>
                  <div>02 July, 2021</div>
                  <div className="text-muted">08:50pm</div>
                </td>
                <td>
                  Customer name <span className="font-bold">"emptyOrigin"</span>{' '}
                  changed to <span className="font-bold">"WBCT"</span>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="avatar-circle avatar-xs mr-1">K</span>
                    Jane Cooper
                  </div>
                </td>
                <td>
                  <span className="badge badge-sm badge-gray-100">
                    Load Status Update
                  </span>
                </td>
                <td>
                  <div>02 July, 2021</div>
                  <div className="text-muted">08:50pm</div>
                </td>
                <td>
                  Load Status{' '}
                  <span className="badge badge-sm badge-soft-brown">
                    Departed
                  </span>{' '}
                  removed
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Audit;
