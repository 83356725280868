import React from 'react';
import StaticTableContent from './StaticTableContent';

const History = () => {
  return (
    <React.Fragment>
      <StaticTableContent />
    </React.Fragment>
  );
};
export default History;
