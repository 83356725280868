import React from 'react';
import { IconColumn, IconFilter } from '../../../../../common/icons';
import CustomSelect from '../../../../../common/CustomSelect';

const options = [
  { value: 'Active', label: 'Active ' },
  { value: 'InActive', label: 'InActive ' },
  { value: 'Active', label: 'InActive ' },
];

const SearchFilter = () => {
  return (
    <div className="filter-row d-flex align-items-center">
      {/* Search Bar */}
      <div className="app-search header-search">
        <span className="search-icon"> </span>
        <div className="position-relative">
          <input
            type="search"
            className="form-control input-search-left"
            placeholder="Search..."
            value=""
          />
        </div>
      </div>
      {/* Select Customer */}
      <div>
        <CustomSelect
          name={'name'}
          options={options}
          value={1}
          isMulti={true}
          isClearable={true}
          size="small"
          placement="right"
          placeholder="Active"
          className="w-150"
        />
      </div>
    </div>
  );
};

export default SearchFilter;
