import React, { Component } from 'react';
import {
  IconFire,
  IconMapMarker,
  IconSnowFlake,
  IconWarning,
  IconWeightTool,
  IconFile,
} from '../../common/icons';

class StaticTableContent extends Component {
  render() {
    return (
      <div
        className="table-responsive"
        style={{ height: 'calc(100vh - 355.8px)' }}
      >
        <table className="table table-card">
          <thead>
            <tr>
              <th>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </th>
              <th>Load #</th>
              <th>Status</th>
              <th>Driver</th>
              <th>Container</th>
              <th>Chassis #</th>
              <th>Seal #</th>
              <th>Customer</th>
              <th>Cut</th>
              <th>Days</th>
              <th>Address</th>
              <th>Type</th>
              <th>Size</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.handleLoadOpen()}
                >
                  POP2_M100520
                </a>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-gray-50 mb-1">
                    Basic
                  </span>
                </div>
                <div>GPM bonita</div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="mr-2 align-self-center">
                    <img
                      src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                      className="avatar-xs rounded-circle"
                      width="30"
                      alt=""
                    />
                  </div>
                  <h5 className="mb-0 align-self-center">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                </div>
              </td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>OEC Group</td>
              <td>06/11</td>
              <td>0</td>
              <td>Port City WHSE</td>
              <td>HC</td>
              <td>45’</td>
            </tr>

            <tr>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.handleLoadOpen()}
                >
                  POP2_M100520
                </a>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-gray-50 mb-1">
                    Basic
                  </span>
                </div>
                <div>GPM bonita</div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="mr-2 align-self-center">
                    <img
                      src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                      className="avatar-xs rounded-circle"
                      width="30"
                      alt=""
                    />
                  </div>
                  <h5 className="mb-0 align-self-center">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                </div>
              </td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>OEC Group</td>
              <td>06/11</td>
              <td>0</td>
              <td>Port City WHSE</td>
              <td>HC</td>
              <td>45’</td>
            </tr>
            <tr>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.handleLoadOpen()}
                >
                  POP2_M100520
                </a>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-gray-50 mb-1">
                    Basic
                  </span>
                </div>
                <div>GPM bonita</div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="mr-2 align-self-center">
                    <img
                      src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                      className="avatar-xs rounded-circle"
                      width="30"
                      alt=""
                    />
                  </div>
                  <h5 className="mb-0 align-self-center">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                </div>
              </td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>OEC Group</td>
              <td>06/11</td>
              <td>0</td>
              <td>Port City WHSE</td>
              <td>HC</td>
              <td>45’</td>
            </tr>
            <tr>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.handleLoadOpen()}
                >
                  POP2_M100520
                </a>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-gray-50 mb-1">
                    Basic
                  </span>
                </div>
                <div>GPM bonita</div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="mr-2 align-self-center">
                    <img
                      src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                      className="avatar-xs rounded-circle"
                      width="30"
                      alt=""
                    />
                  </div>
                  <h5 className="mb-0 align-self-center">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                </div>
              </td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>OEC Group</td>
              <td>06/11</td>
              <td>0</td>
              <td>Port City WHSE</td>
              <td>HC</td>
              <td>45’</td>
            </tr>
            <tr>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.handleLoadOpen()}
                >
                  POP2_M100520
                </a>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-gray-50 mb-1">
                    Basic
                  </span>
                </div>
                <div>GPM bonita</div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="mr-2 align-self-center">
                    <img
                      src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                      className="avatar-xs rounded-circle"
                      width="30"
                      alt=""
                    />
                  </div>
                  <h5 className="mb-0 align-self-center">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                </div>
              </td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>OEC Group</td>
              <td>06/11</td>
              <td>0</td>
              <td>Port City WHSE</td>
              <td>HC</td>
              <td>45’</td>
            </tr>
            <tr>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.handleLoadOpen()}
                >
                  POP2_M100520
                </a>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-gray-50 mb-1">
                    Basic
                  </span>
                </div>
                <div>GPM bonita</div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="mr-2 align-self-center">
                    <img
                      src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                      className="avatar-xs rounded-circle"
                      width="30"
                      alt=""
                    />
                  </div>
                  <h5 className="mb-0 align-self-center">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                </div>
              </td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>OEC Group</td>
              <td>06/11</td>
              <td>0</td>
              <td>Port City WHSE</td>
              <td>HC</td>
              <td>45’</td>
            </tr>
            <tr>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.handleLoadOpen()}
                >
                  POP2_M100520
                </a>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-gray-50 mb-1">
                    Basic
                  </span>
                </div>
                <div>GPM bonita</div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="mr-2 align-self-center">
                    <img
                      src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                      className="avatar-xs rounded-circle"
                      width="30"
                      alt=""
                    />
                  </div>
                  <h5 className="mb-0 align-self-center">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                </div>
              </td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>OEC Group</td>
              <td>06/11</td>
              <td>0</td>
              <td>Port City WHSE</td>
              <td>HC</td>
              <td>45’</td>
            </tr>
            <tr>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.handleLoadOpen()}
                >
                  POP2_M100520
                </a>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-gray-50 mb-1">
                    Basic
                  </span>
                </div>
                <div>GPM bonita</div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="mr-2 align-self-center">
                    <img
                      src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                      className="avatar-xs rounded-circle"
                      width="30"
                      alt=""
                    />
                  </div>
                  <h5 className="mb-0 align-self-center">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                </div>
              </td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>OEC Group</td>
              <td>06/11</td>
              <td>0</td>
              <td>Port City WHSE</td>
              <td>HC</td>
              <td>45’</td>
            </tr>
            <tr>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.handleLoadOpen()}
                >
                  POP2_M100520
                </a>
              </td>
              <td>
                <div className="">
                  <span className="badgeb badge-sm badge-gray-50 mb-1">
                    Basic
                  </span>
                </div>
                <div>GPM bonita</div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="mr-2 align-self-center">
                    <img
                      src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                      className="avatar-xs rounded-circle"
                      width="30"
                      alt=""
                    />
                  </div>
                  <h5 className="mb-0 align-self-center">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                </div>
              </td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>OEC Group</td>
              <td>06/11</td>
              <td>0</td>
              <td>Port City WHSE</td>
              <td>HC</td>
              <td>45’</td>
            </tr>
            <tr>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.handleLoadOpen()}
                >
                  POP2_M100520
                </a>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-gray-50 mb-1">
                    Basic
                  </span>
                </div>
                <div>GPM bonita</div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="mr-2 align-self-center">
                    <img
                      src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                      className="avatar-xs rounded-circle"
                      width="30"
                      alt=""
                    />
                  </div>
                  <h5 className="mb-0 align-self-center">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                </div>
              </td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>OEC Group</td>
              <td>06/11</td>
              <td>0</td>
              <td>Port City WHSE</td>
              <td>HC</td>
              <td>45’</td>
            </tr>
            <tr>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.handleLoadOpen()}
                >
                  POP2_M100520
                </a>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-gray-50 mb-1">
                    Basic
                  </span>
                </div>
                <div>GPM bonita</div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="mr-2 align-self-center">
                    <img
                      src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                      className="avatar-xs rounded-circle"
                      width="30"
                      alt=""
                    />
                  </div>
                  <h5 className="mb-0 align-self-center">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                </div>
              </td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>OEC Group</td>
              <td>06/11</td>
              <td>0</td>
              <td>Port City WHSE</td>
              <td>HC</td>
              <td>45’</td>
            </tr>
            <tr>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.handleLoadOpen()}
                >
                  POP2_M100520
                </a>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-gray-50 mb-1">
                    Basic
                  </span>
                </div>
                <div>GPM bonita</div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="mr-2 align-self-center">
                    <img
                      src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                      className="avatar-xs rounded-circle"
                      width="30"
                      alt=""
                    />
                  </div>
                  <h5 className="mb-0 align-self-center">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                </div>
              </td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>OEC Group</td>
              <td>06/11</td>
              <td>0</td>
              <td>Port City WHSE</td>
              <td>HC</td>
              <td>45’</td>
            </tr>
            <tr>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.handleLoadOpen()}
                >
                  POP2_M100520
                </a>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-gray-50 mb-1">
                    Basic
                  </span>
                </div>
                <div>GPM bonita</div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="mr-2 align-self-center">
                    <img
                      src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                      className="avatar-xs rounded-circle"
                      width="30"
                      alt=""
                    />
                  </div>
                  <h5 className="mb-0 align-self-center">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                </div>
              </td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>OEC Group</td>
              <td>06/11</td>
              <td>0</td>
              <td>Port City WHSE</td>
              <td>HC</td>
              <td>45’</td>
            </tr>
            <tr>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.handleLoadOpen()}
                >
                  POP2_M100520
                </a>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-gray-50 mb-1">
                    Basic
                  </span>
                </div>
                <div>GPM bonita</div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="mr-2 align-self-center">
                    <img
                      src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                      className="avatar-xs rounded-circle"
                      width="30"
                      alt=""
                    />
                  </div>
                  <h5 className="mb-0 align-self-center">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                </div>
              </td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>OEC Group</td>
              <td>06/11</td>
              <td>0</td>
              <td>Port City WHSE</td>
              <td>HC</td>
              <td>45’</td>
            </tr>
            <tr>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.handleLoadOpen()}
                >
                  POP2_M100520
                </a>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-gray-50 mb-1">
                    Basic
                  </span>
                </div>
                <div>GPM bonita</div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="mr-2 align-self-center">
                    <img
                      src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                      className="avatar-xs rounded-circle"
                      width="30"
                      alt=""
                    />
                  </div>
                  <h5 className="mb-0 align-self-center">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                </div>
              </td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>OEC Group</td>
              <td>06/11</td>
              <td>0</td>
              <td>Port City WHSE</td>
              <td>HC</td>
              <td>45’</td>
            </tr>
            <tr>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.handleLoadOpen()}
                >
                  POP2_M100520
                </a>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-gray-50 mb-1">
                    Basic
                  </span>
                </div>
                <div>GPM bonita</div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="mr-2 align-self-center">
                    <img
                      src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                      className="avatar-xs rounded-circle"
                      width="30"
                      alt=""
                    />
                  </div>
                  <h5 className="mb-0 align-self-center">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                </div>
              </td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>OEC Group</td>
              <td>06/11</td>
              <td>0</td>
              <td>Port City WHSE</td>
              <td>HC</td>
              <td>45’</td>
            </tr>
            <tr>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.handleLoadOpen()}
                >
                  POP2_M100520
                </a>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-gray-50 mb-1">
                    Basic
                  </span>
                </div>
                <div>GPM bonita</div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="mr-2 align-self-center">
                    <img
                      src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                      className="avatar-xs rounded-circle"
                      width="30"
                      alt=""
                    />
                  </div>
                  <h5 className="mb-0 align-self-center">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                </div>
              </td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>OEC Group</td>
              <td>06/11</td>
              <td>0</td>
              <td>Port City WHSE</td>
              <td>HC</td>
              <td>45’</td>
            </tr>
            <tr>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.handleLoadOpen()}
                >
                  POP2_M100520
                </a>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-gray-50 mb-1">
                    Basic
                  </span>
                </div>
                <div>GPM bonita</div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="mr-2 align-self-center">
                    <img
                      src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                      className="avatar-xs rounded-circle"
                      width="30"
                      alt=""
                    />
                  </div>
                  <h5 className="mb-0 align-self-center">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                </div>
              </td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>OEC Group</td>
              <td>06/11</td>
              <td>0</td>
              <td>Port City WHSE</td>
              <td>HC</td>
              <td>45’</td>
            </tr>
            <tr>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.handleLoadOpen()}
                >
                  POP2_M100520
                </a>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-gray-50 mb-1">
                    Basic
                  </span>
                </div>
                <div>GPM bonita</div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="mr-2 align-self-center">
                    <img
                      src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                      className="avatar-xs rounded-circle"
                      width="30"
                      alt=""
                    />
                  </div>
                  <h5 className="mb-0 align-self-center">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                </div>
              </td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>OEC Group</td>
              <td>06/11</td>
              <td>0</td>
              <td>Port City WHSE</td>
              <td>HC</td>
              <td>45’</td>
            </tr>
            <tr>
              <td>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  onClick={() => this.props.handleLoadOpen()}
                >
                  POP2_M100520
                </a>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-gray-50 mb-1">
                    Basic
                  </span>
                </div>
                <div>GPM bonita</div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="mr-2 align-self-center">
                    <img
                      src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                      className="avatar-xs rounded-circle"
                      width="30"
                      alt=""
                    />
                  </div>
                  <h5 className="mb-0 align-self-center">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                </div>
              </td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>SDEE4125554</td>
              <td>OEC Group</td>
              <td>06/11</td>
              <td>0</td>
              <td>Port City WHSE</td>
              <td>HC</td>
              <td>45’</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default StaticTableContent;
