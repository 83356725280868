import React, { useState } from 'react';
import OtherTabs from '../common-elements/OtherTabs';
import { IconPlus, IconDownload } from '../../../common/icons/index';
import StaticTableContent from './elements/StaticTableContent';
import AddNewPricing from './elements/AddNewPricing';
import ProfileTabs from '../common-elements/ProfileTabs';
import Pagination from '../common-elements/Pagination';

const CustomerDynamicPricing = () => {
  const [showNewPricingModal, setShowNewPricingModal] = useState(false);

  const handleShowNewPricingModal = (event) => {
    setShowNewPricingModal(true);
  };

  const handleHideNewPricingModal = (event) => {
    setShowNewPricingModal(false);
  };

  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <OtherTabs
            activeTab={2}
            button={
              <div className="d-flex">
                <button className="mr-2 btn btn-outline-light ">
                  <IconDownload className="mr-2" />
                  Downlaod CVS
                </button>
                <button
                  className="ml-auto btn btn-success"
                  onClick={handleShowNewPricingModal}
                >
                  <IconPlus className="mr-2" />
                  Add Pricing
                </button>
              </div>
            }
          />
        </div>
      </div>
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-15 d-flex align-items-center justify-content-between">
            <ProfileTabs activeTab={'Dynamic Pricing'} />
            <Pagination />
          </div>
        </div>

        {/* Static Table Content */}
        <StaticTableContent />
      </div>
      {/* Add New Pricing */}
      {showNewPricingModal && (
        <AddNewPricing
          onCancel={handleHideNewPricingModal}
          onSubmit={handleHideNewPricingModal}
        />
      )}
    </div>
  );
};

export default CustomerDynamicPricing;
