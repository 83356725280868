import React from "react";
import { IconCalendar } from "../../../../common/icons/index";
import CustomSelect from "../../../../common/CustomSelect";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const SearchFilter = () => {
  return (
    <div className="filter-row d-flex align-items-center mt-2 mb-20">
      <div className="form-group mb-0 mr-1">
        <input type="text" className="form-control" placeholder="From" />
        <div className="input-icon">
          <IconCalendar />
        </div>
      </div>
      <div className="form-group mb-0">
        <input type="text" className="form-control" placeholder="To" />
        <div className="input-icon">
          <IconCalendar />
        </div>
      </div>
    </div>
  );
};

export default SearchFilter;
