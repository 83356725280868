import React, { useState } from 'react';
import { IconAngleArrowDown, IconAngleArrowTop, IconPen, IconPlus, IconSortUp, IconTrash } from '../../../common/icons';
import SettingsTab from '../common-elements/SettingsTabs';
import AddPermissionModal from './elements/AddPermissionModal';

const Permission = () => {
  //Add Contact Modal
  const [PermissionshowModal, setPermissionShowModal] = useState(false);
  const [showPermissionRoles, setShowPermissionRoles] = useState(1);
  const [isChecked, setIsChecked] = useState(true);

  const handleShowPermissionRoles = (i) => {
    setShowPermissionRoles(showPermissionRoles === i ? null : i);
  };

  const handlePermissionShowModal = (event) => {
    setPermissionShowModal(true);
  };

  const handlePermissionHideModal = (event) => {
    setPermissionShowModal(false);
  };

  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <div className="subheader">
          <div className="subheader__inner d-flex align-items-center py-0">
            <SettingsTab
              activeTab={2}
              button={
                <button
                  className="ml-auto btn btn-success"
                  onClick={handlePermissionShowModal}
                >
                  <IconPlus className="mr-2" />
                  Add New User
                </button>
              }
            />
          </div>
        </div>
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-2 d-flex align-items-center ">
              <h4 className="font-20 "> Permission </h4>
            </div>
          </div>
          <div className="table-responsive"
          // style={{ height: "calc(100vh - 196px)" }}
          >
            <table className=" table table-card">
              <thead>
                <tr>
                  <th width="2%"></th>
                  <th width="20%">
                    Name
                    <IconSortUp />
                  </th>
                  <th>Role</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th width="2%"></th>
                  <th width="2%"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <button
                      className="btn btn-none"
                      onClick={() => handleShowPermissionRoles(1)}
                    >
                      {showPermissionRoles === 1 ? (
                        <IconAngleArrowTop />
                      ) : (
                        <IconAngleArrowDown />
                      )}
                    </button>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div class="avatar-circle avatar bg-light mr-2">
                        <div class="avatar-circle__initial ">
                          <span className="text-dark">JW</span>
                        </div>
                      </div>
                      Jenny Wilson
                    </div>
                  </td>
                  <td>
                    <div className="badge badge-light">
                      Mechanics
                    </div>
                  </td>
                  <td>
                    kenzi.lawson@example.com
                  </td>
                  <td>
                    (209) 555-0104
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconPen />
                    </div>
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconTrash />
                    </div>
                  </td>
                </tr>
                {showPermissionRoles === 1 && (
                  <tr>
                    <td colspan="7">
                      <div className="p-10">
                        <div className="form-row">
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10">
                              Tracking
                            </h6>
                            <div className="form-check" onClick={() => setIsChecked(!isChecked)}>
                              <input
                                className="form-check-input form-check-input--radio-check rounded-circle"
                                type="checkbox"
                                id="isAll"
                                checked={isChecked === true ? true : null}
                              />
                              <label className="form-check-label" for="isAll">
                                Is All
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Customers
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="dashboard"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="dashboard">
                                  Dashboard
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="container"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="container">
                                  Container
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="calender"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="calender">
                                  Calender
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="upload"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="upload">
                                  Upload
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="customerMessaging"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="customerMessaging"
                                >
                                  Customer Messaging
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driveMessaging"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="driveMessaging"
                                >
                                  Drive Messaging
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="load"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="load">
                                  Load
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="quotes"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="quotes">
                                  Quotes
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Dashboard
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="info"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="info">
                                  Info
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="billing"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="billing">
                                  Billing
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="documents"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="documents">
                                  Documents
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="payments"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="payment">
                                  Payments
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="status"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="status">
                                  Status
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="pay"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="pay">
                                  Pay
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="tracking"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="tracking">
                                  Tracking
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="serviceMessaging"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label"
                                  for="serviceMessaging"
                                >
                                  ServiceMessaging
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="audit"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="audit">
                                  Audit
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Billings
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="pay"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="pay">
                                  Pay
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="settlement"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="settlement">
                                  Settlement
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="setting"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="setting">
                                  Setting
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="accountReceivable"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="accountReceivable"
                                >
                                  Account Receivable
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="subaccountReceivable"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="subaccountReceivable"
                                >
                                  Subaccount Receivable
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="payment"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="payment">
                                  Payment
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Safeties
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="chassis"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="chassis">
                                  Chassis
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="truck"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="truck">
                                  Truck
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="trailer"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="trailer">
                                  Trailer
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driver"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="driver">
                                  Driver
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Others
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="permission"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="permission">
                                  Permission
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="truck"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="truck">
                                  Truck
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="trailer"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="trailer">
                                  Trailer
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driver"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="driver">
                                  Driver
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Container
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="type"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="type">
                                  Type
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="size"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="size">
                                  Size
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="owner"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="owner">
                                  Owner
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="day"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="day">
                                  Day
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Chassis
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="type"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="type">
                                  Type
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="size"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="size">
                                  Size
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="owner"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="owner">
                                  Owner
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Profile
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="customer"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="customer">
                                  Customer
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="creditLimit"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="creditLimit">
                                  credit Limit
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="paymentTerms"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="paymentTerms"
                                >
                                  Payment Terms
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="chassis"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="chassis">
                                  Chassis
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="truck"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="truck">
                                  Truck
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="trailer"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="trailer">
                                  Trailer
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driver"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="driver">
                                  Driver
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Reports
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="revenue"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="revenue">
                                  Revenue
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="aging"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="aging">
                                  Aging
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="revenue"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="revenue">
                                  Revenue
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driver"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="driver">
                                  Driver
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="report"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="report">
                                  Report
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Settings
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="setting"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="setting">
                                  Setting
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Dispatcher
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="dispatcher"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="dispatcher">
                                  Dispatcher
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Accounts
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="account"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="account">
                                  Accounts
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <button
                      className="btn btn-none"
                      onClick={() => handleShowPermissionRoles(2)}
                    >
                      {showPermissionRoles === 2 ? (
                        <IconAngleArrowTop />
                      ) : (
                        <IconAngleArrowDown />
                      )}
                    </button>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div class="avatar-circle avatar bg-light mr-2">
                        <div class="avatar-circle__initial ">
                          <span className="text-dark">RE</span>
                        </div>
                      </div>
                      Ralph Edwardsn
                    </div>
                  </td>
                  <td>
                    <div className="badge badge-light">
                      Mechanics
                    </div>
                  </td>
                  <td>
                    georgia.young@example.com
                  </td>
                  <td>
                    (209) 555-0104
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconPen />
                    </div>
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconTrash />
                    </div>
                  </td>
                </tr>
                {showPermissionRoles === 2 && (
                  <tr>
                    <td colspan="7">
                      <div className="p-10">
                        <div className="form-row">
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10">
                              Tracking
                            </h6>
                            <div className="form-check">
                              <input
                                className="form-check-input form-check-input--radio-check rounded-circle"
                                type="checkbox"
                                id="isAll"
                                checked={isChecked === true ? true : null}
                              />
                              <label className="form-check-label" for="isAll">
                                Is All
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Customers
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="dashboard"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="dashboard">
                                  Dashboard
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="container"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="container">
                                  Container
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="calender"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="calender">
                                  Calender
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="upload"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="upload">
                                  Upload
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="customerMessaging"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="customerMessaging"
                                >
                                  Customer Messaging
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driveMessaging"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="driveMessaging"
                                >
                                  Drive Messaging
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="load"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="load">
                                  Load
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="quotes"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="quotes">
                                  Quotes
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Dashboard
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="info"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="info">
                                  Info
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="billing"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="billing">
                                  Billing
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="documents"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="documents">
                                  Documents
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="payments"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="payment">
                                  Payments
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="status"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="status">
                                  Status
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="pay"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="pay">
                                  Pay
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="tracking"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="tracking">
                                  Tracking
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="serviceMessaging"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label"
                                  for="serviceMessaging"
                                >
                                  ServiceMessaging
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="audit"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="audit">
                                  Audit
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Billings
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="pay"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="pay">
                                  Pay
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="settlement"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="settlement">
                                  Settlement
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="setting"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="setting">
                                  Setting
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="accountReceivable"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="accountReceivable"
                                >
                                  Account Receivable
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="subaccountReceivable"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="subaccountReceivable"
                                >
                                  Subaccount Receivable
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="payment"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="payment">
                                  Payment
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Safeties
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="chassis"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="chassis">
                                  Chassis
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="truck"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="truck">
                                  Truck
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="trailer"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="trailer">
                                  Trailer
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driver"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="driver">
                                  Driver
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Others
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="permission"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="permission">
                                  Permission
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="truck"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="truck">
                                  Truck
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="trailer"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="trailer">
                                  Trailer
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driver"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="driver">
                                  Driver
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Container
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="type"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="type">
                                  Type
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="size"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="size">
                                  Size
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="owner"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="owner">
                                  Owner
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="day"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="day">
                                  Day
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Chassis
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="type"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="type">
                                  Type
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="size"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="size">
                                  Size
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="owner"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="owner">
                                  Owner
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Profile
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="customer"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="customer">
                                  Customer
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="creditLimit"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="creditLimit">
                                  credit Limit
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="paymentTerms"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="paymentTerms"
                                >
                                  Payment Terms
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="chassis"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="chassis">
                                  Chassis
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="truck"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="truck">
                                  Truck
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="trailer"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="trailer">
                                  Trailer
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driver"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="driver">
                                  Driver
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Reports
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="revenue"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="revenue">
                                  Revenue
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="aging"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="aging">
                                  Aging
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="revenue"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="revenue">
                                  Revenue
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driver"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="driver">
                                  Driver
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="report"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="report">
                                  Report
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Settings
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="setting"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="setting">
                                  Setting
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Dispatcher
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="dispatcher"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="dispatcher">
                                  Dispatcher
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Accounts
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="account"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="account">
                                  Accounts
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <button
                      className="btn btn-none"
                      onClick={() => handleShowPermissionRoles(3)}
                    >
                      {showPermissionRoles === 3 ? (
                        <IconAngleArrowTop />
                      ) : (
                        <IconAngleArrowDown />
                      )}
                    </button>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div class="avatar-circle avatar bg-light mr-2">
                        <div class="avatar-circle__initial ">
                          <span className="text-dark">EP</span>
                        </div>
                      </div>
                      Eleanor Pena
                    </div>
                  </td>
                  <td>
                    <div className="badge badge-light mr-2">
                      Sales Agent
                    </div>
                    <div className="badge badge-light">
                      Mechanics
                    </div>
                  </td>
                  <td>
                    kenzi.lawson@example.com
                  </td>
                  <td>
                    (209) 555-0104
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconPen />
                    </div>
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconTrash />
                    </div>
                  </td>
                </tr>
                {showPermissionRoles === 3 && (
                  <tr>
                    <td colspan="7">
                      <div className="p-10">
                        <div className="form-row">
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10">
                              Tracking
                            </h6>
                            <div className="form-check">
                              <input
                                className="form-check-input form-check-input--radio-check rounded-circle"
                                type="checkbox"
                                id="isAll"
                                checked={isChecked === true ? true : null}
                              />
                              <label className="form-check-label" for="isAll">
                                Is All
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Customers
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="dashboard"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="dashboard">
                                  Dashboard
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="container"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="container">
                                  Container
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="calender"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="calender">
                                  Calender
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="upload"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="upload">
                                  Upload
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="customerMessaging"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="customerMessaging"
                                >
                                  Customer Messaging
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driveMessaging"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="driveMessaging"
                                >
                                  Drive Messaging
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="load"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="load">
                                  Load
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="quotes"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="quotes">
                                  Quotes
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Dashboard
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="info"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="info">
                                  Info
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="billing"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="billing">
                                  Billing
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="documents"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="documents">
                                  Documents
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="payments"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="payment">
                                  Payments
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="status"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="status">
                                  Status
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="pay"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="pay">
                                  Pay
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="tracking"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="tracking">
                                  Tracking
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="serviceMessaging"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label"
                                  for="serviceMessaging"
                                >
                                  ServiceMessaging
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="audit"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="audit">
                                  Audit
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Billings
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="pay"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="pay">
                                  Pay
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="settlement"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="settlement">
                                  Settlement
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="setting"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="setting">
                                  Setting
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="accountReceivable"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="accountReceivable"
                                >
                                  Account Receivable
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="subaccountReceivable"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="subaccountReceivable"
                                >
                                  Subaccount Receivable
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="payment"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="payment">
                                  Payment
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Safeties
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="chassis"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="chassis">
                                  Chassis
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="truck"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="truck">
                                  Truck
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="trailer"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="trailer">
                                  Trailer
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driver"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="driver">
                                  Driver
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Others
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="permission"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="permission">
                                  Permission
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="truck"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="truck">
                                  Truck
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="trailer"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="trailer">
                                  Trailer
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driver"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="driver">
                                  Driver
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Container
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="type"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="type">
                                  Type
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="size"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="size">
                                  Size
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="owner"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="owner">
                                  Owner
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="day"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="day">
                                  Day
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Chassis
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="type"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="type">
                                  Type
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="size"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="size">
                                  Size
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="owner"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="owner">
                                  Owner
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Profile
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="customer"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="customer">
                                  Customer
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="creditLimit"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="creditLimit">
                                  credit Limit
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="paymentTerms"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="paymentTerms"
                                >
                                  Payment Terms
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="chassis"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="chassis">
                                  Chassis
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="truck"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="truck">
                                  Truck
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="trailer"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="trailer">
                                  Trailer
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driver"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="driver">
                                  Driver
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Reports
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="revenue"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="revenue">
                                  Revenue
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="aging"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="aging">
                                  Aging
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="revenue"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="revenue">
                                  Revenue
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driver"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="driver">
                                  Driver
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="report"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="report">
                                  Report
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Settings
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="setting"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="setting">
                                  Setting
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Dispatcher
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="dispatcher"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="dispatcher">
                                  Dispatcher
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Accounts
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="account"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="account">
                                  Accounts
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <button
                      className="btn btn-none"
                      onClick={() => handleShowPermissionRoles(4)}
                    >
                      {showPermissionRoles === 1 ? (
                        <IconAngleArrowTop />
                      ) : (
                        <IconAngleArrowDown />
                      )}
                    </button>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div class="avatar-circle avatar bg-light mr-2">
                        <div class="avatar-circle__initial ">
                          <span className="text-dark">RF</span>
                        </div>
                      </div>
                      Robert Fox
                    </div>
                  </td>
                  <td>
                    <div className="badge badge-light mr-2">
                      Sales Agent
                    </div>
                    <div className="badge badge-light">
                      Mechanics
                    </div>
                  </td>
                  <td>
                    deanna.curtis@example.com
                  </td>
                  <td>
                    (209) 555-0104
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconPen />
                    </div>
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconTrash />
                    </div>
                  </td>
                </tr>
                {showPermissionRoles === 4 && (
                  <tr>
                    <td colspan="7">
                      <div className="p-10">
                        <div className="form-row">
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10">
                              Tracking
                            </h6>
                            <div className="form-check">
                              <input
                                className="form-check-input form-check-input--radio-check rounded-circle"
                                type="checkbox"
                                id="isAll"
                                checked={isChecked === true ? true : null}
                              />
                              <label className="form-check-label" for="isAll">
                                Is All
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Customers
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="dashboard"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="dashboard">
                                  Dashboard
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="container"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="container">
                                  Container
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="calender"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="calender">
                                  Calender
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="upload"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="upload">
                                  Upload
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="customerMessaging"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="customerMessaging"
                                >
                                  Customer Messaging
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driveMessaging"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="driveMessaging"
                                >
                                  Drive Messaging
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="load"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="load">
                                  Load
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="quotes"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="quotes">
                                  Quotes
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Dashboard
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="info"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="info">
                                  Info
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="billing"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="billing">
                                  Billing
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="documents"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="documents">
                                  Documents
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="payments"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="payment">
                                  Payments
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="status"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="status">
                                  Status
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="pay"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="pay">
                                  Pay
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="tracking"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="tracking">
                                  Tracking
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="serviceMessaging"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label"
                                  for="serviceMessaging"
                                >
                                  ServiceMessaging
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="audit"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="audit">
                                  Audit
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Billings
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="pay"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="pay">
                                  Pay
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="settlement"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="settlement">
                                  Settlement
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="setting"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="setting">
                                  Setting
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="accountReceivable"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="accountReceivable"
                                >
                                  Account Receivable
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="subaccountReceivable"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="subaccountReceivable"
                                >
                                  Subaccount Receivable
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="payment"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="payment">
                                  Payment
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Safeties
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="chassis"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="chassis">
                                  Chassis
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="truck"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="truck">
                                  Truck
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="trailer"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="trailer">
                                  Trailer
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driver"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="driver">
                                  Driver
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Others
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="permission"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="permission">
                                  Permission
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="truck"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="truck">
                                  Truck
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="trailer"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="trailer">
                                  Trailer
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driver"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="driver">
                                  Driver
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Container
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="type"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="type">
                                  Type
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="size"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="size">
                                  Size
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="owner"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="owner">
                                  Owner
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="day"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="day">
                                  Day
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Chassis
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="type"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="type">
                                  Type
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="size"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="size">
                                  Size
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="owner"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="owner">
                                  Owner
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Profile
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="customer"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="customer">
                                  Customer
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="creditLimit"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="creditLimit">
                                  credit Limit
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="paymentTerms"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="paymentTerms"
                                >
                                  Payment Terms
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="chassis"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="chassis">
                                  Chassis
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="truck"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="truck">
                                  Truck
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="trailer"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="trailer">
                                  Trailer
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driver"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="driver">
                                  Driver
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Reports
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="revenue"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="revenue">
                                  Revenue
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="aging"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="aging">
                                  Aging
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="revenue"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="revenue">
                                  Revenue
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driver"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="driver">
                                  Driver
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="report"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="report">
                                  Report
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Settings
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="setting"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="setting">
                                  Setting
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Dispatcher
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="dispatcher"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="dispatcher">
                                  Dispatcher
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Accounts
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="account"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="account">
                                  Accounts
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <button
                      className="btn btn-none"
                      onClick={() => handleShowPermissionRoles(5)}
                    >
                      {showPermissionRoles === 5 ? (
                        <IconAngleArrowTop />
                      ) : (
                        <IconAngleArrowDown />
                      )}
                    </button>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div class="avatar-circle avatar bg-light mr-2">
                        <div class="avatar-circle__initial ">
                          <span className="text-dark">JW</span>
                        </div>
                      </div>
                      Jenny Wilson
                    </div>
                  </td>
                  <td>
                    <div className="badge badge-light">
                      Mechanics
                    </div>
                  </td>
                  <td>
                    kenzi.lawson@example.com
                  </td>
                  <td>
                    (209) 555-0104
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconPen />
                    </div>
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconTrash />
                    </div>
                  </td>
                </tr>
                {showPermissionRoles === 5 && (
                  <tr>
                    <td colspan="7">
                      <div className="p-10">
                        <div className="form-row">
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10">
                              Tracking
                            </h6>
                            <div className="form-check">
                              <input
                                className="form-check-input form-check-input--radio-check rounded-circle"
                                type="checkbox"
                                id="isAll"
                                checked={isChecked === true ? true : null}
                              />
                              <label className="form-check-label" for="isAll">
                                Is All
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Customers
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="dashboard"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="dashboard">
                                  Dashboard
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="container"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="container">
                                  Container
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="calender"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="calender">
                                  Calender
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="upload"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="upload">
                                  Upload
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="customerMessaging"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="customerMessaging"
                                >
                                  Customer Messaging
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driveMessaging"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="driveMessaging"
                                >
                                  Drive Messaging
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="load"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="load">
                                  Load
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="quotes"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="quotes">
                                  Quotes
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Dashboard
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="info"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="info">
                                  Info
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="billing"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="billing">
                                  Billing
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="documents"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="documents">
                                  Documents
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="payments"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="payment">
                                  Payments
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="status"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="status">
                                  Status
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="pay"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="pay">
                                  Pay
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="tracking"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="tracking">
                                  Tracking
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="serviceMessaging"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label"
                                  for="serviceMessaging"
                                >
                                  ServiceMessaging
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="audit"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="audit">
                                  Audit
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Billings
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="pay"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="pay">
                                  Pay
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="settlement"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="settlement">
                                  Settlement
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="setting"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="setting">
                                  Setting
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="accountReceivable"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="accountReceivable"
                                >
                                  Account Receivable
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="subaccountReceivable"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="subaccountReceivable"
                                >
                                  Subaccount Receivable
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="payment"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="payment">
                                  Payment
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Safeties
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="chassis"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="chassis">
                                  Chassis
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="truck"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="truck">
                                  Truck
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="trailer"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="trailer">
                                  Trailer
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driver"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="driver">
                                  Driver
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Others
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="permission"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="permission">
                                  Permission
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="truck"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="truck">
                                  Truck
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="trailer"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="trailer">
                                  Trailer
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driver"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="driver">
                                  Driver
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Container
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="type"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="type">
                                  Type
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="size"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="size">
                                  Size
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="owner"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="owner">
                                  Owner
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="day"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="day">
                                  Day
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Chassis
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="type"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="type">
                                  Type
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="size"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="size">
                                  Size
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="owner"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="owner">
                                  Owner
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Profile
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="customer"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="customer">
                                  Customer
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="creditLimit"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="creditLimit">
                                  credit Limit
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="paymentTerms"
                                  checked={isChecked === true ? true : null}
                                />
                                <label
                                  className="form-check-label w-25"
                                  for="paymentTerms"
                                >
                                  Payment Terms
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="chassis"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="chassis">
                                  Chassis
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="truck"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="truck">
                                  Truck
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="trailer"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="trailer">
                                  Trailer
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driver"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="driver">
                                  Driver
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Reports
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="isAll"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="isAll">
                                  Is All
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="revenue"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="revenue">
                                  Revenue
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="aging"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="aging">
                                  Aging
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="revenue"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="revenue">
                                  Revenue
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="driver"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="driver">
                                  Driver
                                </label>
                              </div>
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="report"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="report">
                                  Report
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Settings
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="setting"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="setting">
                                  Setting
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Dispatcher
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="dispatcher"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="dispatcher">
                                  Dispatcher
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <h6 className="font-size-regular text-gray-500 mb-10 ">
                              Accounts
                            </h6>
                            <div className="d-flex flex-column">
                              <div className="form-check mb-1">
                                <input
                                  className="form-check-input form-check-input--radio-check rounded-circle"
                                  type="checkbox"
                                  id="account"
                                  checked={isChecked === true ? true : null}
                                />
                                <label className="form-check-label" for="account">
                                  Accounts
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <button
                      className="btn btn-none"
                    >
                      <IconAngleArrowDown />
                    </button>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div class="avatar-circle avatar bg-light mr-2">
                        <div class="avatar-circle__initial ">
                          <span className="text-dark">EP</span>
                        </div>
                      </div>
                      Eleanor Pena
                    </div>
                  </td>
                  <td>
                    <div className="badge badge-light mr-2">
                      Sales Agent
                    </div>
                    <div className="badge badge-light">
                      Mechanics
                    </div>
                  </td>
                  <td>
                    kenzi.lawson@example.com
                  </td>
                  <td>
                    (209) 555-0104
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconPen />
                    </div>
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconTrash />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      className="btn btn-none"
                    >
                      <IconAngleArrowDown />
                    </button>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div class="avatar-circle avatar bg-light mr-2">
                        <div class="avatar-circle__initial ">
                          <span className="text-dark">EP</span>
                        </div>
                      </div>
                      Eleanor Pena
                    </div>
                  </td>
                  <td>
                    <div className="badge badge-light mr-2">
                      Sales Agent
                    </div>
                    <div className="badge badge-light">
                      Mechanics
                    </div>
                  </td>
                  <td>
                    kenzi.lawson@example.com
                  </td>
                  <td>
                    (209) 555-0104
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconPen />
                    </div>
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconTrash />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      className="btn btn-none"
                    >
                      <IconAngleArrowDown />
                    </button>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div class="avatar-circle avatar bg-light mr-2">
                        <div class="avatar-circle__initial ">
                          <span className="text-dark">EP</span>
                        </div>
                      </div>
                      Eleanor Pena
                    </div>
                  </td>
                  <td>
                    <div className="badge badge-light mr-2">
                      Sales Agent
                    </div>
                    <div className="badge badge-light">
                      Mechanics
                    </div>
                  </td>
                  <td>
                    kenzi.lawson@example.com
                  </td>
                  <td>
                    (209) 555-0104
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconPen />
                    </div>
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconTrash />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      className="btn btn-none"
                    >
                      <IconAngleArrowDown />
                    </button>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div class="avatar-circle avatar bg-light mr-2">
                        <div class="avatar-circle__initial ">
                          <span className="text-dark">EP</span>
                        </div>
                      </div>
                      Eleanor Pena
                    </div>
                  </td>
                  <td>
                    <div className="badge badge-light mr-2">
                      Sales Agent
                    </div>
                    <div className="badge badge-light">
                      Mechanics
                    </div>
                  </td>
                  <td>
                    kenzi.lawson@example.com
                  </td>
                  <td>
                    (209) 555-0104
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconPen />
                    </div>
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconTrash />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      className="btn btn-none"
                    >
                      <IconAngleArrowDown />
                    </button>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div class="avatar-circle avatar bg-light mr-2">
                        <div class="avatar-circle__initial ">
                          <span className="text-dark">EP</span>
                        </div>
                      </div>
                      Eleanor Pena
                    </div>
                  </td>
                  <td>
                    <div className="badge badge-light mr-2">
                      Sales Agent
                    </div>
                    <div className="badge badge-light">
                      Mechanics
                    </div>
                  </td>
                  <td>
                    kenzi.lawson@example.com
                  </td>
                  <td>
                    (209) 555-0104
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconPen />
                    </div>
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconTrash />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      className="btn btn-none"
                    >
                      <IconAngleArrowDown />
                    </button>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div class="avatar-circle avatar bg-light mr-2">
                        <div class="avatar-circle__initial ">
                          <span className="text-dark">EP</span>
                        </div>
                      </div>
                      Eleanor Pena
                    </div>
                  </td>
                  <td>
                    <div className="badge badge-light mr-2">
                      Sales Agent
                    </div>
                    <div className="badge badge-light">
                      Mechanics
                    </div>
                  </td>
                  <td>
                    kenzi.lawson@example.com
                  </td>
                  <td>
                    (209) 555-0104
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconPen />
                    </div>
                  </td>
                  <td>
                    <div className="btn btn-link">
                      <IconTrash />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {PermissionshowModal && (
        <AddPermissionModal
          onCancel={handlePermissionHideModal}
          onBack={handlePermissionHideModal}
          onSubmit={handlePermissionHideModal}
        />
      )}
    </React.Fragment>
  );
};

export default Permission;
