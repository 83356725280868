import React, { useState } from "react";
import Pagination from "../../../../common/pagination";
import { IconCalendar, IconDownload } from "../../../../common/icons/index";

const columns = [
  "Order #",
  "Container #",
  "Status",
  "ETA",
  "Last Free Days",
  "Port of Discharge",
  "Deliver to",
  "Vessel",
];

const tableData = [];
for (let i = 0; i < 6; i++) {
  tableData.push({
    id: i,
    orderNo: "POP2_M10165" + i,
    containerNo: "ONEU1025845" + i,
    status: "Pending",
    eta: "12/06/2020",
    lastFreeDay: "18/09/2021",
    pickupDate: "18/06/2021",
    portOfDischarge: "Zhongu Logistics Corporation",
    deliverTo: "SM Line",
    Vessel: "La Charmais",
  });
}

const CompanyDetailsPage = () => {
  return (
    <React.Fragment>
      <div className="top-page">
        <div className="filter-row d-flex align-items-center">
          {/* Select Customer */}
          <div className="d-flex">
            <div className="form-group mb-0 mr-1">
              <input type="text" placeholder="From" className="form-control" />
              <div className="input-icon">
                <IconCalendar />
              </div>
            </div>
            <div className="form-group mb-0">
              <input type="text" placeholder="To" className="form-control" />
              <div className="input-icon">
                <IconCalendar />
              </div>
            </div>
          </div>
          <Pagination className="ml-auto" />
        </div>
      </div>
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 260px)" }}
      >
        <table className="table table-card table-card--ls">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableData.map((tdata) => {
              return (
                <tr key={tdata.id}>
                  <td>{tdata.orderNo}</td>
                  <td>{tdata.containerNo}</td>
                  <td>{tdata.status}</td>
                  <td>{tdata.eta}</td>
                  <td>{tdata.lastFreeDay}</td>
                  <td>{tdata.pickupDate}</td>
                  <td>{tdata.portOfDischarge}</td>
                  <td>{tdata.deliverTo}</td>
                  <td>{tdata.Vessel}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default CompanyDetailsPage;
