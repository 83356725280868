import React, { useState } from 'react';
import OtherTabs from '../common-elements/OtherTabs';
import Pagination from '../common-elements/Pagination';
import StaticTableContent from './elements/StaticTableContent';
import { IconPlus } from '../../../common/icons';
import AddNewDiscount from './elements/AddNewDiscount';
import ProfileTabs from '../common-elements/ProfileTabs';

const CustomerDiscount = () => {
  const [showNewDiscountModal, setshowNewDiscountModal] = useState(false);

  const handleShowNewDiscountModal = (event) => {
    setshowNewDiscountModal(true);
  };

  const handleHideNewDiscountModal = (event) => {
    setshowNewDiscountModal(false);
  };
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <OtherTabs
            activeTab={2}
            button={
              <button
                className="ml-auto btn btn-success"
                onClick={handleShowNewDiscountModal}
              >
                <IconPlus className="mr-2" />
                Add New Discounts
              </button>
            }
          />
        </div>
      </div>
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-15 d-flex align-items-center justify-content-between">
            <ProfileTabs activeTab={'Customer Discounts'} />
          </div>
          <div className="pb-15 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <h4 className="font-20 mb-0">7 Discounts</h4>
            </div>
            <Pagination />
          </div>
        </div>
        {/* Static Table and Search Filter */}
        <StaticTableContent />
      </div>

      {showNewDiscountModal && (
        <AddNewDiscount
          onCancel={handleHideNewDiscountModal}
          onSubmit={handleHideNewDiscountModal}
        />
      )}
    </div>
  );
};

export default CustomerDiscount;
