import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomSelect from "../../../../common/CustomSelect";
import CustomCreatableSelect from "../../../../common/CustomSelect/creatable";
import {
  IconCalendar,
  IconInfoCircleFilled,
  IconMinus,
  IconPlus,
} from "../../../../common/icons";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const AddNewPricing = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;
  const [isTooltipShown, setIsTooltipShown] = useState(false);

  return (
    <Modal show={showModal} centered animation={false} size="sm">
      <Modal.Header>
        <Modal.Title>Add New Pricing</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="form-wrapper">
          <form>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  {" "}
                  <span className="text-danger">*</span> Name of Expense
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Name of Expense"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  {" "}
                  <span className="text-danger">*</span> Recurrenct Pattern
                </label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select"
                  className="w-100"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label className="col-form-label">
                  {" "}
                  <span className="text-danger">*</span> Start Date
                </label>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Date"
                  />
                  <span className="input-icon">
                    <IconCalendar />
                  </span>
                </div>
              </div>
              <div className="form-group col-md-6">
                <label className="col-form-label">
                  {" "}
                  <span className="text-danger">*</span> End Date
                </label>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Date"
                  />
                  <span className="input-icon">
                    <IconCalendar />
                  </span>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Amount
                </label>
                <input
                  className="form-control"
                  placeholder="$ 0.00"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 form-group">
                <div className="form-check form-switch form-switch-md">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customSwitchsizelg"
                  />
                  <label className="mb-0 ml-2">Deactived</label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-success" onClick={onSubmit}>
          Add Price
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewPricing;
