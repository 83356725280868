import React, { useState } from 'react';
import { IconCalendar, IconClock } from '../../../../common/icons/index';
import CustomSelect from '../../../../common/CustomSelect';
import UploadDocumentSmall from '../../../../common/uploadDocumentSmall';
import Pagination from '../../common-elements/Pagination';

const options = [
  { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
  { value: 'John Doe', label: 'John Doe' },
  { value: 'Alex Mendes', label: 'Alex Mendes' },
];

const TopPage = () => {
  return (
    <div className="top-page">
      <div className="pb-15 d-flex align-items-center">
        <h4 className="mb-0">Apply Payments</h4>
        <Pagination />
      </div>
      <div className="card p-20">
        <div className="form-wrapper">
          <form>
            <div className="form-row mb-1">
              <div className="form-group col-md-3">
                <label className="col-form-label">Customer</label>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select Customer"
                  className="w-100"
                />
              </div>
              <div className="form-group col-md-3">
                <label className="col-form-label">Date</label>
                <div className="input-wrapper">
                  <input className="form-control" placeholder="MM/DD/YY" />
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                </div>
              </div>
              <div className="form-group col-md-3">
                <label className="col-form-label">Check #</label>
                <input className="form-control" placeholder="e.g. 00000000" />
              </div>
              <div className="form-group col-md-3">
                <label className="col-form-label">Payment method</label>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select..."
                  className="w-100"
                />
              </div>
              <div className="form-group col-md-3">
                <label className="col-form-label">Account to deposit</label>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select Account to Deposit"
                  className="w-100"
                />
              </div>
              <div className="form-group col-md-3">
                <label className="col-form-label">Deposit Type</label>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select Deposit Type"
                  className="w-100"
                />
              </div>
              <div className="form-group col">
                <label className="col-form-label">Note</label>
                <input className="form-control" placeholder="placeholder" />
              </div>
            </div>
            <div className="form-group mb-0">
              <div className="d-flex align-items-center justify-content-between w-100">
                <div className="">
                  <UploadDocumentSmall />
                </div>
                <div className="">
                  <span className="font-12 mr-10 font-weight-500 ">
                    Total Amount
                  </span>
                  <button className="btn btn-light mr-10">Cancel</button>
                  <button className="btn btn-success ">Apply Payment</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TopPage;
