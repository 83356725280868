import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomSelect from "../../../../common/CustomSelect";
import CustomCreatableSelect from "../../../../common/CustomSelect/creatable";
import {
  IconPlus,
  IconPen,
  IconTrash,
  IconEye,
} from "../../../../common/icons";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const columns = [
  "First name",
  "Last Name",
  "Phone ",
  "Email",
  "Email Notification",
  "Permission",
];

const data = [
  {
    id: 1,
    firstName: "Kathryn ",
    lastName: "Murphy",
    phone: "(302) 555-0107",
    email: "debbie.baker@example.com",
    emailNotification: "alma.lawson@example.com",
    settings: 4,
    permission: "Account Payable",
  },
  {
    id: 2,
    firstName: "Kathryn ",
    lastName: "Murphy",
    phone: "(302) 555-0107",
    email: "debbie.baker@example.com",
    emailNotification: "alma.lawson@example.com",
    settings: 4,
    permission: "Account Payable",
  },
  {
    id: 3,
    firstName: "Kathryn ",
    lastName: "Murphy",
    phone: "(302) 555-0107",
    email: "debbie.baker@example.com",
    emailNotification: "alma.lawson@example.com",
    settings: 4,
    permission: "Account Payable",
  },
  {
    id: 4,
    firstName: "Kathryn ",
    lastName: "Murphy",
    phone: "(302) 555-0107",
    email: "debbie.baker@example.com",
    emailNotification: "alma.lawson@example.com",
    settings: 4,
    permission: "Account Payable",
  },
  {
    id: 5,
    firstName: "Kathryn ",
    lastName: "Murphy",
    phone: "(302) 555-0107",
    email: "debbie.baker@example.com",
    emailNotification: "alma.lawson@example.com",
    settings: 4,
    permission: "Account Payable",
  },
];

const Employee = () => {
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handleSendEmail = () => {
    setChecked(!checked);
  };

  const handleShowAddEmployeeModal = (event) => {
    setShowAddEmployeeModal(true);
  };

  const handleHideAddEmployeeModal = (event) => {
    setShowAddEmployeeModal(false);
  };
  return (
    <React.Fragment>
      <div className="bg-gray-50 p-20">
        <button
          className="ml-auto btn btn-primary mb-15"
          onClick={handleShowAddEmployeeModal}
        >
          <IconPlus className="mr-2" />
          Add Employee
        </button>
        <div className="table-responsive">
          <table className="table table-card table-card--ls">
            <thead>
              <tr>
                {columns.map((column) => {
                  return (
                    <th scope="col" key={column}>
                      {column}
                    </th>
                  );
                })}
                <th style={{ width: "2%" }}></th>
                <th style={{ width: "2%" }}></th>
              </tr>
            </thead>
            <tbody>
              {data.map((datum) => {
                return (
                  <tr key={datum.id}>
                    <td>
                      <a
                        href="javascript:void(0)"
                        className="text-primary font-weight-500"
                      >
                        {datum.firstName}
                      </a>
                    </td>
                    <td>{datum.lastName}</td>
                    <td>{datum.phone}</td>
                    <td>{datum.email}</td>
                    <td>
                      <div>{datum.emailNotification}</div>
                      <div className="text-muted mt-1">
                        {datum.settings} Settings
                      </div>
                    </td>
                    <td>{datum.permission}</td>
                    <td>
                      <button className="btn btn-link">
                        <IconPen />
                      </button>
                    </td>
                    <td>
                      <button className="btn btn-link text-muted">
                        <IconTrash />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <Modal show={showAddEmployeeModal} centered animation={false}>
          <Modal.Header>
            <Modal.Title>Add Employee</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-wrapper">
              <form>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label className="col-form-label">
                      <span className="text-danger">*</span> First Name
                    </label>
                    <input
                      placeholder="Enter First Name"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label className="col-form-label">
                      <span className="text-danger">*</span> Last Name
                    </label>
                    <input
                      placeholder="Enter Last Name"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label className="col-form-label">
                      <span className="text-danger">*</span> Phone
                    </label>
                    <input
                      placeholder="Enter Phone "
                      className="form-control"
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="col-form-label">
                      <span className="text-danger">*</span> Email
                    </label>
                    <input
                      placeholder="Enter Email "
                      className="form-control"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="col-form-label">
                      <span className="text-danger">*</span> Password
                    </label>
                    <div className="input-wrapper">
                      <input
                        type={passwordShown ? "text" : "password"}
                        placeholder="Enter Password "
                        className="form-control"
                      />
                      <div
                        className="input-icon"
                        onClick={togglePasswordVisiblity}
                      >
                        <IconEye />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="col-form-label">
                      <span className="text-danger">*</span> Repeat Password
                    </label>
                    <div className="input-wrapper">
                      <input
                        type={passwordShown ? "text" : "password"}
                        placeholder="Enter Repeat Password "
                        className="form-control"
                      />
                      <div
                        className="input-icon"
                        onClick={togglePasswordVisiblity}
                      >
                        <IconEye />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <label className="col-form-label">
                      <span className="text-danger">*</span> Billing Email
                    </label>
                    <input
                      placeholder="Enter Billing Email "
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12 mb-0">
                    <label className="col-form-label">
                      <span className="text-danger">*</span> Permission
                    </label>
                    <CustomCreatableSelect
                      name={"name"}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder="Enter Permission"
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="hr-light my-20"></div>
                <div className="form-row align-items-center">
                  <div className="col-md-4 form-group">
                    <div className="form-check pl-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="sendEmail"
                        onClick={handleSendEmail}
                        checked={checked}
                      />
                      <label className="form-check-label" for="">
                        Send Email Notification
                      </label>
                    </div>
                  </div>
                  {checked && (
                    <div className="form-group col-md-8">
                      <input
                        placeholder="Enter Email Notification Address"
                        className="form-control"
                      />
                    </div>
                  )}
                </div>

                {/* Send Email Notification */}
                {checked && (
                  <div className="form-row mt-10">
                    <div className="form-group col-md-6">
                      <div className="form-check pl-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="sendEmail"
                        />
                        <label className="form-check-label" for="">
                          Email Delivery Appointment Changed
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="form-check pl-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="sendEmail"
                        />
                        <label className="form-check-label" for="">
                          Email Pricing Detail
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="form-check pl-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="sendEmail"
                        />
                        <label className="form-check-label" for="">
                          Email Container Available
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="form-check pl-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="sendEmail"
                        />
                        <label className="form-check-label" for="">
                          Email Container Add
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="form-check pl-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="sendEmail"
                        />
                        <label className="form-check-label" for="">
                          Email Load Accepted
                        </label>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="form-check pl-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="sendEmail"
                        />
                        <label className="form-check-label" for="">
                          Email Container Ready For Pickup
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={handleHideAddEmployeeModal}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={handleHideAddEmployeeModal}
            >
              Add Employee
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Employee;
