import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  IconColumn,
  IconFilter,
  IconPen,
  IconTrash,
} from '../../../common/icons';

const StaticTableContent = () => {
  return (
    <React.Fragment>
      {/* Search Filter */}
      <div className="filter-row d-flex mb-15 align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>
      </div>
      <table className="table table-card">
        <thead>
          <tr>
            <th width="38%">Group name</th>
            <th width="38%">Payment Type</th>
            <th width="10%">Drivers</th>
            <th width="10%">Value</th>
            <th width="2%"></th>
            <th width="2%"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="d-flex">
                <div className="mr-1 font-weight-500 text-primary">
                  Percentage
                </div>
                <span className=" badge badge-sm badge-light">
                  5 Accessorials
                </span>
              </div>
            </td>
            <td>Move</td>
            <td>
              <div className="group d-flex align-items-center mt-1">
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-4.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-5.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-6.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <div className="avatar-xs avatar-circle bg-light text-dark">
                    +3
                  </div>
                </div>
              </div>
            </td>
            <td>$180.00</td>
            <td>
              <button className="btn btn-link">
                <IconPen className="" />
              </button>
            </td>
            <td>
              <button className="btn btn-link">
                <IconTrash />
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <div className="d-flex">
                <div className="mr-1 font-weight-500 text-primary">Hourly</div>
              </div>
            </td>
            <td>Invoice</td>
            <td>
              <div className="group d-flex align-items-center mt-1">
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-4.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-5.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-6.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <div className="avatar-xs avatar-circle bg-light text-dark">
                    +3
                  </div>
                </div>
              </div>
            </td>
            <td>$40/hr</td>
            <td>
              <button className="btn btn-link">
                <IconPen className="" />
              </button>
            </td>
            <td>
              <button className="btn btn-link">
                <IconTrash />
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <div className="d-flex">
                <span className="mr-1 font-weight-500 text-primary">
                  Profile Group 1
                </span>
                <span className="  badge badge-sm badge-light">
                  2 Accessorials
                </span>
              </div>
            </td>
            <td>Move by Zipcode</td>
            <td>
              <div className="group d-flex align-items-center mt-1">
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-4.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-5.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-6.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <div className="avatar-xs avatar-circle bg-light text-dark">
                    +3
                  </div>
                </div>
              </div>
            </td>
            <td>$180.00</td>
            <td>
              <button className="btn btn-link">
                <IconPen className="" />
              </button>
            </td>
            <td>
              <button className="btn btn-link">
                <IconTrash />
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <div className="d-flex">
                <div className="mr-1 font-weight-500 text-primary">75%</div>
              </div>
            </td>
            <td>Miles</td>
            <td>
              <div className="group d-flex align-items-center mt-1">
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-4.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-5.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-6.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <div className="avatar-xs avatar-circle bg-light text-dark">
                    +3
                  </div>
                </div>
              </div>
            </td>
            <td>$180.00</td>
            <td>
              <button className="btn btn-link">
                <IconPen className="" />
              </button>
            </td>
            <td>
              <button className="btn btn-link">
                <IconTrash />
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <div className="d-flex">
                <div className="mr-1 font-weight-500 text-primary">Miles</div>
              </div>
            </td>
            <td>Hours</td>
            <td>
              <div className="group d-flex align-items-center mt-1">
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-4.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-5.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-6.jpg"
                    alt=""
                    className="avatar-xs avatar-circle"
                  />
                </div>
                <div className="group__avatar">
                  <div className="avatar-xs avatar-circle bg-light text-dark">
                    +3
                  </div>
                </div>
              </div>
            </td>
            <td>$180.00</td>
            <td>
              <button className="btn btn-link">
                <IconPen className="" />
              </button>
            </td>
            <td>
              <button className="btn btn-link">
                <IconTrash />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};
export default StaticTableContent;
