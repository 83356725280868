import React, { useState } from 'react';
import { IconAngleArrowLeft, IconPlus } from '../../../common/icons';

const tabItems = [
  {
    id: 1,
    title: 'Chassis',
    path: '/safety/chassis',
  },
  {
    id: 2,
    title: 'Trucks',
    path: '/safety/trucks',
  },
  {
    id: 3,
    title: 'Trailers',
    path: '/safety/trailers',
  },
  {
    id: 4,
    title: 'Drivers',
    path: '/safety/drivers',
  },
];
const options = [
  { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
  { value: 'John Doe', label: 'John Doe' },
  { value: 'Alex Mendes', label: 'Alex Mendes' },
];
const ChassisLoadSubHeader = ({ closeChassisLoad }) => {
  return (
    <React.Fragment>
      <div className="subheader">
        <div className="subheader__inner d-flex">
          <div className="d-flex flex-row align-items-center">
            <button
              className="btn btn-outline-light"
              onClick={closeChassisLoad}
            >
              <IconAngleArrowLeft />
            </button>
            <div className="d-flex ml-3 align-items-center">
              <h5 className="mb-0">12312312</h5>
            </div>
          </div>
          <div className="ml-auto position-relative">
            <button
              className="btn btn-outline-light mr-1"
              onClick={closeChassisLoad}
            >
              Cancel
            </button>
            <button className="btn btn-success mr-4">Save Changes</button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChassisLoadSubHeader;
