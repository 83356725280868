import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

import CustomSelect from '../../../common/CustomSelect';
import { IconPlus, IconTrash, IconPen } from '../../../common/icons';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];
class DriverPay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowDriverPayModal: false,
    };
  }
  handleShowDriverPayModal = (event) => {
    this.setState({ ShowDriverPayModal: true });
  };
  handleHidePaymentModal = (event) => {
    this.setState({ ShowDriverPayModal: false });
  };
  render() {
    return (
      <React.Fragment>
        <div className="tab">
          <div className="card p-20 mb-10 d-flex flex-row align-items-center justify-content-between">
            <h4 className="font-16">Driver Pay</h4>
            <button
              className="ml-auto btn btn-primary"
              onClick={this.handleShowDriverPayModal}
            >
              <IconPlus className="mr-2" />
              Add Payment
            </button>
          </div>

          <div className="table mb-20">
            <table className="table table-card table-card--ls mb-10">
              <thead>
                <tr>
                  <th>Load</th>
                  <th>Time</th>
                  <th>Driver</th>
                  <th>Truck</th>
                  <th>Owner</th>
                  <th>Amount</th>
                  <th>Load Status</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>LTLE_TER_M100096</td>
                  <td>
                    <div>02 July, 2021</div>
                    <div className="text-muted">08:50pm</div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-circle avatar-xs mr-1">
                        <div className="avatar-title">A</div>
                      </div>
                      Robert FC
                    </div>
                  </td>
                  <td>Truck</td>
                  <td>TMX</td>
                  <td>$1000</td>
                  <td>Dispatched</td>
                  <td>Connecticut 35624</td>
                  <td>New Jersey</td>
                  <td>
                    <span className="badge badge-sm badge-light">Basic</span>
                  </td>
                  <td className="text-right">
                    <div className="d-flex justify-content-end ">
                      <button className="btn btn-link text-primary">
                        <IconPen />
                      </button>
                      <button className="btn btn-link text-muted">
                        <IconTrash />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>LTLE_TER_M100096</td>
                  <td>
                    <div>02 July, 2021</div>
                    <div className="text-muted">08:50pm</div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-circle avatar-xs mr-1">
                        <div className="avatar-title">A</div>
                      </div>
                      Robert FC
                    </div>
                  </td>
                  <td>Truck</td>
                  <td>TMX</td>
                  <td>$1000</td>
                  <td>Dispatched</td>
                  <td>Connecticut 35624</td>
                  <td>New Jersey</td>
                  <td>
                    <span className="badge badge-sm badge-light">Basic</span>
                  </td>
                  <td className="text-right">
                    <div className="d-flex justify-content-end ">
                      <button className="btn btn-link text-primary">
                        <IconPen />
                      </button>
                      <button className="btn btn-link text-muted">
                        <IconTrash />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>LTLE_TER_M100096</td>
                  <td>
                    <div>02 July, 2021</div>
                    <div className="text-muted">08:50pm</div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-circle avatar-xs mr-1">
                        <div className="avatar-title">A</div>
                      </div>
                      Robert FC
                    </div>
                  </td>
                  <td>Truck</td>
                  <td>TMX</td>
                  <td>$1000</td>
                  <td>Dispatched</td>
                  <td>Connecticut 35624</td>
                  <td>New Jersey</td>
                  <td>
                    <span className="badge badge-sm badge-light">Basic</span>
                  </td>
                  <td className="text-right">
                    <div className="d-flex justify-content-end ">
                      <button className="btn btn-link text-primary">
                        <IconPen />
                      </button>
                      <button className="btn btn-link text-muted">
                        <IconTrash />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>LTLE_TER_M100096</td>
                  <td>
                    <div>02 July, 2021</div>
                    <div className="text-muted">08:50pm</div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="avatar-circle avatar-xs mr-1">
                        <div className="avatar-title">A</div>
                      </div>
                      Robert FC
                    </div>
                  </td>
                  <td>Truck</td>
                  <td>TMX</td>
                  <td>$1000</td>
                  <td>Dispatched</td>
                  <td>Connecticut 35624</td>
                  <td>New Jersey</td>
                  <td>
                    <span className="badge badge-sm badge-light">Basic</span>
                  </td>
                  <td className="text-right">
                    <div className="d-flex justify-content-end ">
                      <button className="btn btn-link text-primary">
                        <IconPen />
                      </button>
                      <button className="btn btn-link text-muted">
                        <IconTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          show={this.state.ShowDriverPayModal}
          centered
          animation={false}
          size="sm"
        >
          <Modal.Header>
            <Modal.Title>Add Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className=" form-group">
              <label>
                <span className="text-danger">*</span> Container
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="e.g. AA0000000"
              />
            </div>
            <div className="form-group">
              <label>
                <span className="text-danger">*</span> Description
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Description Placeholder"
              />
            </div>
            <div className="form-group">
              <label>
                <span className="text-danger">*</span> From
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="From Date"
              />
            </div>
            <div className="form-group">
              <label>
                <span className="text-danger">*</span> To
              </label>
              <input type="text" className="form-control" placeholder="To" />
            </div>
            <div className="form-group">
              <label>
                <span className="text-danger">*</span> Driver
              </label>
              <CustomSelect
                name={'name'}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Select Driver"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={this.handleHidePaymentModal}
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              disabled
              onClick={this.handleHidePaymentModal}
            >
              Add Payment
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default DriverPay;
