import React, { Component } from 'react';
import Chatpop from '../../../common/chatpop';
import {
  IconAttachment,
  IconPlus,
  Icondots,
  IconPen,
} from '../../../common/icons';

class TrackChat extends Component {
  render() {
    return (
      <div className="col" style={{ height: 'calc(100vh - 205px)' }}>
        <Chatpop className="chatpop--full" />
      </div>
    );
  }
}

export default TrackChat;
