import React, { useState} from 'react';
import StaticTableContent from './StaticTableContent';
import CustomerTabs from '../common-elements/CustomerTabs';
import Pagination from '../../customerService/common-elements/Pagination';
import DispatcherFilterRow from './FilterRow';

const Quotes = () => {
  const [QuotesShowModal, setQuotesShowModal] = useState(false);
  const handleQuotesShowModal = (event) => {
    setQuotesShowModal(true);
  };

  const handleQuotesHideModal = (event) => {
    setQuotesShowModal(false);
  };
  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <CustomerTabs activeTab={3} />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <div className="d-flex align-items-center">
                <h4 className="font-20 mb-0">Quotes</h4>
              </div>
              <Pagination className="ml-auto" />
            </div>

            {/* Filter Row */}
            <DispatcherFilterRow />
          </div>
          <div
            className="table-responsive"
            style={{ height: 'calc(100vh - 420px)' }}
          >
            <StaticTableContent />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
  
}

export default Quotes

