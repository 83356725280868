import React, { useState } from 'react';
import { IconPlus } from '../../../common/icons';

const tabItems = [
  {
    id: 1,
    title: 'Rates',
    path: '/customers/customer-rates',
  },
  {
    id: 2,
    title: 'Dynamic Pricing',
    path: '/customers/customer-dynamic-pricing',
  },
  {
    id: 3,
    title: 'Customer Discounts',
    path: '/customer/customer-discount',
  },
];

const ProfileTabs = ({ activeTab, button }) => {
  const [isTabActive, setIsTabActive] = useState(activeTab);

  return (
    <div className="d-flex flex-row align-items-center justify-content-between w-100">
      {/*  Sub Header Tabs*/}
      <ul className="nav nav-compact border-0 p-0" role="tablist">
        {tabItems.map((tabItem) => (
          <li className="nav-item" key={tabItem.id}>
            <a
              href={tabItem.path}
              className={`nav-link ml-1 ${
                isTabActive === tabItem.title ? ' active' : 'rounded-3 bg-light'
              }`}
              onClick={() => setIsTabActive(tabItem.title)}
            >
              {tabItem.title}
            </a>
          </li>
        ))}
      </ul>

      {/* CTA BUTTON */}
      {button}
    </div>
  );
};

export default ProfileTabs;
