import React, { useState } from "react";
import CustomSelect from "../../../common/CustomSelect";
import { IconMinus } from "../../../common/icons";
import UploadDocument from "../../../common/uploadDocument";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import SettingsTab from "../common-elements/SettingsTabs";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const SAdocumentConfiguration = () => {
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <SettingsTab
            activeTab={13}
            button={
              <div className="d-flex">
                <button className="mr-2 btn btn-outline-light ">Cancle</button>
                <button className=" btn btn-success ">Save Changes</button>
              </div>
            }
          />
        </div>
      </div>
      <div className="page-fluid">
        <div className="card p-20 mb-20">
          <h3 className="mb-15 font-16">Company Logo</h3>
          <UploadDocument />
          <div className="hr-light"></div>
          <div className="row">
            <div className="col-md-7">
              <div className="mb-20">
                <h3 className="font-16 mb-15">Address</h3>
                <Editor
                  editorClassName="editorClassName"
                  placeholder="Enter Messsage"
                />
              </div>
              <div className="mb-20">
                <h3 className="font-16 mb-15">Invoice Notes</h3>
                <Editor
                  editorClassName="editorClassName"
                  placeholder="Enter Messsage"
                />
              </div>
              <div className="mb-20">
                <h3 className="font-16 mb-15">POD Notes</h3>
                <Editor
                  editorClassName="editorClassName"
                  placeholder="Enter Messsage"
                />
              </div>
              <div className="mb-20">
                <h3 className="font-16 mb-15">Rate Con Notes</h3>
                <Editor
                  editorClassName="editorClassName"
                  placeholder="Enter Messsage"
                />
              </div>
              <div className="mb-20">
                <h3 className="font-16 mb-15">Email Notification Notes</h3>
                <Editor
                  editorClassName="editorClassName"
                  placeholder="Enter Messsage"
                />
              </div>
              <div className="d-flex">
                  <div className="form-check pr-30">
                      <input type="checkbox" className="form-check-input" id="delivery-order"/>
                      <label className="form-check-label">
                          Delivery Order
                      </label>
                  </div>
                  <div className="form-check">
                      <input type="checkbox" className="form-check-input" id="delivery-order"/>
                      <label className="form-check-label">
                          Delivery Order
                      </label>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SAdocumentConfiguration;
