import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import {
  IconBell,
  IconMessage,
  IconPrinter,
  IconSendMessage,
  IconTags,
  IconTrash,
} from "../../common/icons";

const tableData = [];
for (let i = 0; i < 20; i++) {
  tableData.push({
    id: i,
    customer: "Peter",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    date: `Mar ${i}`,
  });
}

const EmailPage = ({}) => {
  const [isShowComposeModal, setShowComposeModal] = useState(false);
  const showComposeModal = () => {
    setShowComposeModal(true);
  };
  const hideComposeModal = () => {
    setShowComposeModal(false);
  };
  return (
    <React.Fragment>
      <div className="wrapper home position-relative">
        <div className="page-fluid mt-20">
          <div className="font-16 font-medium mb-15">Email Page</div>
          <div className="form-row">
            <div className="col-lg" style={{ maxWidth: 270 }}>
              <div className="card p-20 mb-0">
                <button className="btn btn-primary text-center d-block mb-10" onClick={showComposeModal}>
                  Compose
                </button>
                <div className="mail-list">
                  <div className="pointer text-primary my-15 d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <IconMessage className="mr-2" />
                      Inbox
                    </div>
                    <span className="badge badge-primary">18</span>
                  </div>
                  <div className="pointer text-muted my-15 d-flex align-items-center">
                    <IconTags className="mr-2" />
                    Draft
                  </div>
                  <div className="pointer text-muted my-15 d-flex align-items-center">
                    <IconSendMessage className="mr-2" />
                    Sent
                  </div>
                  <div className="pointer text-muted my-15 d-flex align-items-center">
                    <IconTrash className="mr-2" />
                    Trash
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg">
              <div className="card p-20 mb-10">
                <div className="btn-toolbar" role="toolbar">
                  <div className="btn-group mr-2 mb-2 mb-sm-0">
                    <button type="button" className="btn btn-primary">
                      <IconBell />
                    </button>
                    <button type="button" className="btn btn-primary">
                      <IconPrinter />
                    </button>
                    <button type="button" className="btn btn-primary">
                      <IconTrash />
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="table-responsive"
                style={{ maxHeight: "calc(100vh - 202px)" }}
              >
                <table className="table table-card">
                  <tbody>
                    <tr>
                      <td width="40">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" />
                        </div>
                      </td>
                      <td>Peter</td>
                      <td>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </td>
                      <td>Mar 6</td>
                    </tr>
                    {tableData.map((tdata) => {
                      return (
                        <tr key={tdata.id}>
                          <td width="40">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                              />
                            </div>
                          </td>
                          <td>{tdata.customer}</td>
                          <td>{tdata.description}</td>
                          <td>{tdata.date}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={isShowComposeModal} centered animation={false}>
        <Modal.Header>
          <Modal.Title>Compose Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
              <div className="form-group">
                    <label>To</label>
                  <input className="form-control" placeholder="Enter Email.." />
              </div>
              <div className="form-group">
                    <label>Subject</label>
                  <input className="form-control" placeholder="Enter Subject.." />
              </div>
              <div className="form-group">
                    <label>Message</label>
                  <textarea rows="8" className="form-control" placeholder="Enter Message.." />
              </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" onClick={hideComposeModal}>Cancel</button>
          <button className="btn btn-success">Send Email</button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default EmailPage;
