import React from 'react';
import { IconUpload } from '../../../../common/icons';
import UploadedDocuments from '../../../../common/uploadedDocuments';

const Documents = () => {
  return (
    <React.Fragment>
      <div className="document-wrapper p-20 bg-gray-50">
        <div className="  pb-15 d-flex flex-row align-items-center ">
          <button className=" btn btn-primary">
            <IconUpload className="mr-2" />
            Upload Document
          </button>
        </div>
        <UploadedDocuments />
      </div>
    </React.Fragment>
  );
};

export default Documents;
