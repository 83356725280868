import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { countryName } from '../constant.js';
import { CustomCreditCard, IconApple, IconVisa, IconAmericanExpress, IconMasterCard } from '../../customIcons/index';
import SeparatorLine from './Separator';
import PaymentTypeBox from './PaymentTypeBox';
import { useCreditCardValidator, images } from 'react-creditcard-validator';

// card date validator
const expDateValidate = (month, year) => {
  if (Number(year) > 2035) {
    return 'Expiry Date Year cannot be greater than 2035';
  }
  return;
}

const Index = () => {
  const [paymentDetails, setPaymentDetails] = useState({
    email: '',
    method: '',
    cardInfo: {
      number: '',
      date: '',
      cvc: ''
    },
    nameOnCard: '',
    country: '',
    zipCode: '',
    canStore: false,
    totalCost: ''
  });
  const [ischeckedMarked, setCheckMarked] = useState(false);
  const navigate = useHistory();

  const {
    getCardNumberProps,
    getCardImageProps,
    getCVCProps,
    getExpiryDateProps,
    meta: { erroredInputs }
  } = useCreditCardValidator({ expiryDateValidator: expDateValidate });

  // *************************** METHODS ************************
  // subscribe
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(paymentDetails)
  }

  return (
    <div className="col-md-4 col2 pr-5 py-5 px-5 d-flex flex-column justify-content-between">
      <div className='col2__body'>
        <button className='btn py-2 w-100 d-flex justify-content-center text-light' id='payButton'>
          <IconApple />
          <span className='mx-1' id='appleIconTitle'>Pay</span>
        </button><br />
        <SeparatorLine type='horizontal' middleText='Or pay another way' /><br />
        <form onSubmit={handleSubmit}>
          <p>Email</p>
          <input
            type='email'
            id='email'
            className={`form-control px-3 w-100 inputField shadow-0`}
            value={paymentDetails.email}
            onChange={(e) => setPaymentDetails({ ...paymentDetails, email: e.target.value })} 
            required
          /><br />
          <p>Payment method</p>
          <div className='d-flex justify-content-between'>
            <PaymentTypeBox name='Card' Icon={<CustomCreditCard />} paymentDetails={paymentDetails} setPaymentDetails={setPaymentDetails} />
            <PaymentTypeBox name='US bank account' Icon={<CustomCreditCard />} paymentDetails={paymentDetails}  setPaymentDetails={setPaymentDetails} />
          </div><br />
          <p>Card information</p>
          <div className='col2__body__cardInfo w-100 rounded-5 border border-light'>
            <div className='d-flex px-2 align-items-center' id='cardInfo'>
              <input
                type='number'
                className='form-control border-0 shadow-none'
                placeholder='1234 1234 1234 1234'
                id='cardInfo--number'
                value={paymentDetails.cardInfo.number}
                {...getCardNumberProps()}
                onChange={(e) => setPaymentDetails((paymentDetails) => {
                  return {
                    ...paymentDetails,
                    cardInfo: {
                      ...paymentDetails.cardInfo,
                      number: e.target.value.trim()
                    }
                  }
                })}
                required />
              {
                { ...getCardImageProps({ images }).children.type[0] }[0] === 's' ?
                  <>
                    <IconVisa />
                    <IconMasterCard />
                    <IconAmericanExpress />
                  </> : <svg style={{ fontSize: '20px' }} {...getCardImageProps({ images })} />
              }
            </div>
            <div className='d-flex'>
              <input
                id='cardInfo__input1'
                className={`form-control px-3 w-100 inputField shadow-0`}
                onChange={(e) => setPaymentDetails((paymentDetails) => {
                  return {
                    ...paymentDetails,
                    cardInfo: {
                      ...paymentDetails.cardInfo,
                      date: e.target.value
                    }
                  }
                })}
                {...getExpiryDateProps()}
                required
              />
              <input
                type='number'
                id='cardInfo__input2'
                className={`form-control px-3 w-100 inputField shadow-0`}
                onChange={(e) => setPaymentDetails((paymentDetails) => {
                  return {
                    ...paymentDetails,
                    cardInfo: {
                      ...paymentDetails.cardInfo,
                      cvc: e.target.value
                    }
                  }
                })}
                {...getCVCProps()}
                required
              />
            </div>
            {erroredInputs.cardNumber && <span className='errorText text-danger mx-2'>{erroredInputs.cardNumber}</span>}
            {erroredInputs.cvc && <span className='errorText text-danger'>{erroredInputs.cvc}</span>}
          </div>
          <br />
          <p>Name on card</p>
          <input
            type='text'
            id='cardName'
            className={`form-control px-3 w-100 inputField shadow-0`}
            onChange={(e) => setPaymentDetails({ ...paymentDetails, nameOnCard: e.target.value })}
            required
          /><br />
          <p>Country or region</p>
          <div className='d-flex flex-column'>
            <select
              className='form-control border-0 shadow-none'
              id='col2__body--countrySelect'
              onChange={(e) => setPaymentDetails({ ...paymentDetails, country: e.target.value })}
            >
              {
                countryName.map((country, index) => {
                  return <option value={country.value} key={index}>{country.label}</option>
                })
              }
            </select>
            <input
              type='text'
              id='col2__body--zip'
              className={`form-control px-3 w-100 inputField shadow-0`}
              onChange={(e) => setPaymentDetails({ ...paymentDetails, zipCode: e.target.value })}
              required
            /><br />
          </div><br />
          <div className='col2__body__verifyBox py-3 px-2 d-flex rounded-5'>
            <input
              type='checkbox'
              ischecked='false'
              className='form-control'
              id='col2__body__verifyBox--checkbox'
              onChange={(e) => {
                setCheckMarked(() => !ischeckedMarked)
                setPaymentDetails({ ...paymentDetails, canStore: e.target.value })
              }}
            />
            <div className='d-flex pl-2 flex-column'>
              <span className='font-weight-bold'>Store my info for secure 1-click checkout</span>
              <span className='text-secondary mt-1'>Pay faster on Portpro Technologies Inc and thousands of sites</span>
            </div>
          </div><br />
          <button
            className='py-2 btn w-100 d-flex justify-content-center rounded-5'
            id='col2__body__verifyBox--subscribeBtn'
            onClick={() => {
               navigate.push('/schedule')
            }}
          >Subscribe</button><br />
        </form>
        <p className='text-center text-secondary'>By confirming your subscription, you allow PortPro Technologies Inc to charge your card
          for this payment and future payments in accordance with their terms. You can always cnacel your subscription.</p>
      </div>
    </div>
  )
}


export default Index;