import React, { useState } from "react";
import AddLoad from "../../../common/addLoad";
import DriverList from "../../../common/driverList";
import {
  IconClockSnooze,
  IconDotSeperator,
  IconDownload,
  IconPlus,
  IconPrinter,
  IconReset,
  IconSteering,
  IconTimes,
  IconTimesThin,
  IconTruckDriver,
} from "../../../common/icons";
import TabComponent from "../../../common/tabComponent";

const tabItems = [
  {
    id: 1,
    title: "Dispatcher",
    path: "/dispatcher",
  },
  {
    id: 2,
    title: "Driver Itinerary",
    path: "/driver-itinerary",
  },
  {
    id: 3,
    title: "Double Moves",
    path: "/double-moves",
  },
  {
    id: 4,
    title: "Street Turns",
    path: "/street-turns",
  },
  {
    id: 5,
    title: "Problems Container",
    path: "/problem-container",
  },
];

const DispatcherTabs = ({ activeTab }) => {
  const [tabActive, setTabActive] = useState(activeTab);

  const [isDriverList, setisDriverList] = useState(false);
  const [isTrackCard, setisTrackCard] = useState(false);

  const handleTrackCard = () => {
    setisTrackCard(!isTrackCard);
  };

  const handleShowDriverList = (event) => {
    document.body.classList.add("bdpopup-open");
    setisDriverList(true);
  };

  const handleHideDriverList = (event) => {
    document.body.classList.remove("bdpopup-open");
    setisDriverList(false);
  };

  //For Add Load Modal
  const [showAddLoadModal, setShowAddLoadModal] = useState(false);
  const handleShowAddLoadModal = (event) => {
    setShowAddLoadModal(true);
  };
  const handleHideAddLoadModal = (event) => {
    setShowAddLoadModal(false);
  };
  return (
    <React.Fragment>
      {/*  Sub Header Tabs*/}
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <div className="d-flex flex-row align-items-center">
            <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
              {tabItems.map(({ id, title, path }) => (
                <TabComponent
                  key={title}
                  title={title}
                  path={path}
                  onItemClicked={() => setTabActive(id)}
                  isActive={tabActive === id}
                />
              ))}
            </ul>
          </div>
          <div className="ml-auto">
            <button
              className="btn btn-outline-light mr-2"
              onClick={handleShowDriverList}
            >
              <IconSteering className="mr-2" />
              Drivers
            </button>
            <button
              className="btn btn-success"
              onClick={handleShowAddLoadModal}
            >
              <IconPlus className="mr-2" />
              Add New Load
            </button>
          </div>
        </div>
      </div>
      {isDriverList && <DriverList hideDriverList={handleHideDriverList} />}
      {showAddLoadModal && (
        <AddLoad
          hideAddModal={handleHideAddLoadModal}
          showAddModal={handleShowAddLoadModal}
        />
      )}
    </React.Fragment>
  );
};

export default DispatcherTabs;
