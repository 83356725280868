import React, { useState } from "react";

import { IconDownload, IconSendEmail } from "../../../../common/icons/index";

const columns = [
  "Load #",
  "Driver",
  "Track ID",
  "Customer ID",
  "Customer Name",
  "Customer Address",
  "Customer City, State",
  "Customer Zip",
  "Container #",
  "Leg ID",
  "Leg #",
  "Update Date & Time",
  "Update Type",
  "Leg Type",
  "Pickup Location Code",
  "PU Address",
  "PU City & State",
  "PU ZIP",
  "Scheduled PU Date",
  "Scheduled PU Time From",
  "Scheduled PU Time To",
  "Actual pick up arrived date",
  "Actual pick up In time",
  "Actual pick up Out time",
  "Delivery Lodaction Code",
  "Delivery Lodaction Name",
  "DL Address",
  "DL City & State",
  "DL ZIP",
  "Scheduled DL Date",
  "Scheduled DL Time From",
  "Scheduled DL Time To",
  "Actual Delivery in Date",
  "Actual Delivery in Time",
  "Actual Delivery out Date",
  "Actual Delivery out Time",
  "Size",
  "CTR Type",
];

const tableData = [];
for (let i = 0; i < 20; i++) {
  tableData.push({
    loadNo: "POP2_M10166" + i,
    customerName: "Eleanor Pena",
  });
}

const options = [
  { value: "jessica.hanson@example.com", label: "jessica.hanson@example.com" },
  { value: "john.doe@example.com", label: "john.doe@example.com" },
  { value: "alex@example.com", label: "alex@example.com" },
];

const StaticTableContent = () => {
  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 260px)" }}
      >
        <table className="table table-card table-card--ls">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableData.map((tdata) => {
              return (
                <tr key={tdata.id}>
                  <td>{tdata.loadNo}</td>
                  <td>
                    <div class="d-flex">
                      <div class="mr-2 align-self-center">
                        <div class="avatar-circle avatar bg-gray-100">
                          <div class="avatar-circle__initial ">EP</div>
                        </div>
                      </div>
                      <h5 class="mb-0 align-self-center">
                        <a href="#" class="text-dark">
                          {tdata.driver}
                        </a>
                      </h5>
                    </div>
                  </td>
                  <td>1236568</td>
                  <td>AHHIO99875545</td>
                  <td>Declan Rice</td>
                  <td>2118 Thornridge Cir. Syracuse, Connecticut 35624</td>
                  <td>New York, NY</td>
                  <td>110922</td>
                  <td>89874444001</td>
                  <td>110923222</td>
                  <td>Pull Container</td>
                  <td>5/19/12 - 03:48 am</td>
                  <td>Pull Container</td>
                  <td>Leg Type</td>
                  <td>GCT BAYONNE</td>
                  <td>302 Port Jersey Blvd, Jersey City, NJ 07305, USA</td>
                  <td>Jersey City, NJ</td>
                  <td>44600</td>
                  <td>5/19/12 - 03:48 am</td>
                  <td>5/19/12 - 03:48 am</td>
                  <td>5/19/12 - 03:48 am</td>
                  <td>5/19/12</td>
                  <td>5/19/12 </td>
                  <td>5/19/12</td>
                  <td>SHHAD2312312312</td>
                  <td>GCT Bayonne</td>
                  <td>2464 Royal Ln. Mesa, New Jersey 45463</td>
                  <td>New Jersey. NJ</td>
                  <td>35705</td>
                  <td>9/4/12</td>
                  <td>6/21/19 </td>
                  <td>6/21/19 </td>
                  <td>6/21/19 </td>
                  <td>6/21/19 </td>
                  <td>6/21/19 </td>
                  <td>6/21/19 </td>
                  <td>40'</td>
                  <td>CTR Type</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default StaticTableContent;
