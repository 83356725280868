import React, { useState } from 'react';
import CustomSelect from '../../../../common/CustomSelect';
import CustomCreatableSelect from '../../../../common/CustomSelect/creatable';
import {
  IconPlus,
  IconMinus,
  IconCalendar,
  IconClock,
} from '../../../../common/icons';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const Schedule = () => {
  const [showRule, setShowRule] = useState(false);
  const handleShowRule = (event) => {
    setShowRule(true);
  };

  const handleHideRule = (event) => {
    setShowRule(false);
  };

  return (
    <>
      <div className="title d-flex justify-content-center align-items-center pt-60 pb-50 font-20 font-weight-500 text-dark">
        Schedule Your New Report
      </div>
      <div className="form-wrapper px-30 pb-30">
        <form>
          <div className="form-row ">
            <div className="form-group col-md-12">
              <label className="col-form-label">
                Who should receive the report?
              </label>
              <input type="text" className="form-control" placeholder="0%" />
            </div>
            <div className="form-group col-md-12">
              <label className="col-form-label">
                When do you want it sent?
              </label>
              <CustomSelect
                name={'name'}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Select person(s)"
              />
            </div>
            <div className="form-group col-md-12">
              <label className="col-form-label">
                When do you want it sent?
              </label>
              <CustomSelect
                name={'name'}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="select"
              />
            </div>
            <div className="form-group">
              <label>What time of the day should we send it?</label>
              <div className="input-wrapper">
                <input className="form-control" placeholder="MM/DD/YY" />
                <div className="input-icon">
                  <IconClock />
                </div>
              </div>
            </div>
          </div>
          <div className="text-primary ">+ Add Time</div>
        </form>
      </div>
    </>
  );
};

export default Schedule;
