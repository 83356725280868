import React, { useState, useEffect } from "react";
import { IconAngleArrowLeft, IconDownload } from "../../../common/icons";
import ReportsTabs from "../common-elements/ReportsTabs";
import LoadDetails from "./elements/LoadDetails";
import SearchFilter from "./elements/searchFilter";
import StaticTableContent from "./elements/StaticTableContent";
import TopPage from "./elements/toppage";

const Loads = () => {
  const [loadDetails, setLoadDetails] = useState(false);

  const openLoadDetails = (event) => {
    document.body.classList.add("has-subheader");
    setLoadDetails(true);
  };

  const hideLoadDetails = (event) => {
    document.body.classList.remove("has-subheader");
    setLoadDetails(false);
  };

  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        {loadDetails === true ? (
          <div className="subheader">
            <div className="subheader__inner d-flex">
              <div className="d-flex flex-row align-items-center">
                <button
                  className="btn btn-outline-light"
                  onClick={hideLoadDetails}
                >
                  <IconAngleArrowLeft />
                </button>
                <div className="d-flex ml-3 align-items-center">
                  <h5 className="mb-0">Salam Pacific Indonesia Lines</h5>
                </div>
              </div>
              <div className="ml-auto position-relative d-flex">
                <button className="btn btn-outline-light mr-2">
                  <IconDownload className="mr-2" />
                  Download
                </button>
              </div>
            </div>
          </div>
        ) : (
          <ReportsTabs activeTab={3} />
        )}

        {loadDetails === false ? (
          <div className="page-fluid">
            <TopPage />
            <SearchFilter />
            <StaticTableContent showCustomerDetails={openLoadDetails} />
          </div>
        ) : (
          <LoadDetails />
        )}
      </div>
    </React.Fragment>
  );
};

const LoadsNewFunction = () => {
  useEffect(() => {
    document.body.className = "has-subheader";
    return () => {
      document.body.className = "";
    };
  });

  return <Loads />;
};

export default LoadsNewFunction;
