import React, { Component } from "react";
import { IconPen, IconTrash } from "../../../../../common/icons";

class StaticTableContent extends Component {
  render() {
    return (
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 301.8px)" }}
      >
        <table className="table table-card ">
          <thead>
            <tr>
              <th width="32%">Email</th>
              <th width="30%">Name</th>
              <th width="30%">Customer Name</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>Cosco</td>
              <td>+1-202-555-0119</td>
              <td>Dianne Russell</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default StaticTableContent;
