import React, { useState } from "react";
import { IconPlus } from "../../../../common/icons";
import Cards from "../../common-elements/Cards";
import OtherTabs from "../../common-elements/OtherTabs";
import ProfileTabs from "../profileTabs";
import Pagination from "../../../../common/pagination";
import StaticTableContent from "./elements/StaticTableContent";

const cardItems = [
  {
    id: 1,
    count: 164,
    title: "Customers ",
  },
  {
    id: 2,
    count: 79,
    title: "Shipper/Port",
  },
  {
    id: 3,
    count: 163,
    title: "Consignees",
  },
  {
    id: 4,
    count: 29,
    title: "Chassis",
  },
];

const ProfileTruck = () => {
  const [CardActive, setCardActive] = useState(1);
  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <div className="subheader">
          <div className="subheader__inner d-flex align-items-center py-0">
            <OtherTabs activeTab={1} />
          </div>
        </div>
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center justify-content-between">
              <ProfileTabs
                activeTab={"Truck"}
                button={
                  <button className=" btn btn-success shadow">
                    <IconPlus className="mr-2" />
                    Add Truck
                  </button>
                }
              />
            </div>
          </div>
          {/* Cards Row */}
          <div className={`mb-15 form-row ${CardActive ? "hasactive" : ""}`}>
            {cardItems.map(({ id, title, count }) => (
              <Cards
                title={title}
                count={count}
                onCardClicked={() => setCardActive(id)}
                isCardActive={CardActive === id}
              />
            ))}
          </div>

          <div className="pb-15 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <h4 className="font-20 mb-0">435 Trucks</h4>
            </div>
            {/* Pagination  */}
            <Pagination className="ml-auto" />
          </div>
          {/* Static Table and Search Filter */}
          <StaticTableContent />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProfileTruck;
