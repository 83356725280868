import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Images from './organizationlogoImage';
import SubscriptionPlan from './SubscriptionPlan';

const AddSubscription = (props) => {
  const { showAddModal = true, onCancel = () => {}, onNext = () => {} } = props;

  return (
    <Modal show={showAddModal} centered animation={false}>
      <Modal.Header className="d-flex p-40 justify-content-center">
        <Modal.Title>Choose a subscription</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-60 pt-0 pb-50">
        <SubscriptionPlan />
      </Modal.Body>
      <Modal.Footer>
        <button className=" btn btn-outline-light" onClick={onCancel}>
          Back
        </button>
        <button className=" btn btn-outline-light" onClick={onNext}>
          Continue
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSubscription;
