import React, { useState } from "react";
import { IconPlus } from "../../../../common/icons";
import Cards from "../../common-elements/Cards";
import OtherTabs from "../../common-elements/OtherTabs";
import ProfileTabs from "../profileTabs";
import Pagination from "../../../../common/pagination";
import StaticTableContent from "./elements/StaticTableContent";

const cardItems = [
  {
    id: 1,
    count: 164,
    title: "Available",
  },
  {
    id: 2,
    count: 79,
    title: "Dispatched",
  },
  {
    id: 3,
    count: 163,
    title: "Loaded",
  },
  {
    id: 4,
    count: 29,
    title: "Empty",
  },
];

function ProfileTrailer() {
  const [CardActive, setCardActive] = useState(1);
  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <div className="subheader">
          <div className="subheader__inner d-flex align-items-center py-0">
            <OtherTabs activeTab={1} />
          </div>
        </div>
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center justify-content-between">
              <ProfileTabs
                activeTab={"Trailer"}
                button={
                  <button className=" btn btn-success shadow">
                    <IconPlus className="mr-2" />
                    Add Trailer
                  </button>
                }
              />
            </div>
          </div>
          <div className={`pb-15 form-row ${CardActive ? "hasactive" : ""}`}>
            {cardItems.map(({ id, title, count }) => (
              <Cards
                title={title}
                count={count}
                onCardClicked={() => setCardActive(id)}
                isCardActive={CardActive === id}
              />
            ))}
          </div>
          <div className="pb-15 d-flex align-items-center justify-content-between">
            <h4 className="font-20 mb-0">435 Trailer</h4>
            <Pagination />
          </div>
          <StaticTableContent />
        </div>
      </div>
      {/* Cards Row */}
    </React.Fragment>
  );
}

export default ProfileTrailer;
