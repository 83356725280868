import React, { useState } from 'react';
import BatchActionLoads from '../../../common/batchActionLoads';
import { CustomIconAddUser } from '../../../common/customIcons';
import {
  IconSendEmail,
  IconSortDown,
  IconPen,
  IconTrash,
} from '../../../common/icons';
import AddQuotesModal from './AddquotesModal';

const StaticTableContent = (props) => {
  const { handleLoadOpen } = props;
  const [isBatchAction, setisBatchAction] = useState(false);
  const [isSelectDriver, setisSelectDriver] = useState(false);
  const handleSelectDriver = (event) => {
    setisSelectDriver(!isSelectDriver);
  };

  const handleBatchAction = () => {
    setisBatchAction(!isBatchAction);
  };

  const [QuotesShowModal, setQuotesShowModal] = useState(false);
  const handleQuotesShowModal = (event) => {
    setQuotesShowModal(true);
  };

  const handleQuotesHideModal = (event) => {
    setQuotesShowModal(false);
  };

  return (
    <React.Fragment>
      {isBatchAction && <BatchActionLoads />}
      <table className=" table table-card">
        <thead>
          <tr>
            <th width="5%">Status</th>
            <th className="text-dark">
              Customer
              <IconSortDown />
            </th>
            <th>Port/Shipper</th>
            <th>Sales Agent</th>
            <th>Quote #</th>
            <th># of Loads</th>
            <th>Valid Upto</th>
            <th>Base Price</th>
            <th>Xtra Price</th>
            <th>Total Price</th>
            <th></th>
            <th width="2%"></th>
            <th width="2%"></th>
            <th width="2%"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-soft-green">Active</span>
              </div>
            </td>
            <td>Sinokor Merchant Marine</td>
            <td>WBCT</td>
            <td>Darlene Robertson</td>
            <td>POP20000005</td>
            <td>3</td>
            <td>06/11/2021</td>
            <td>$0.00</td>
            <td>$0.00</td>
            <td>$0.00</td>
            <td>
              <button
                className="btn btn-success btn-circle btn-xs bg-sucess"
                onClick={handleQuotesShowModal}
              >
                +
              </button>
            </td>
            <td>
              <button className="btn btn-link">
                <IconSendEmail />
              </button>
            </td>
            <td>
              <button className="btn btn-link">
                <IconPen className="" />
              </button>
            </td>
            <td>
              <button className="btn btn-link">
                <IconTrash />
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-soft-green">Active</span>
              </div>
            </td>
            <td>Sinokor Merchant Marine</td>
            <td>WBCT</td>
            <td>Darlene Robertson</td>
            <td>POP20000005</td>
            <td>3</td>
            <td>06/11/2021</td>
            <td>$0.00</td>
            <td>$0.00</td>
            <td>$0.00</td>
            <td>
              <button
                className="btn btn-success btn-circle btn-xs bg-sucess"
                onClick={handleQuotesShowModal}
              >
                +
              </button>
            </td>
            <td>
              <button className="btn btn-link">
                <IconSendEmail />
              </button>
            </td>
            <td>
              <button className="btn btn-link">
                <IconPen className="" />
              </button>
            </td>
            <td>
              <button className="btn btn-link">
                <IconTrash />
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">Expired</span>
              </div>
            </td>
            <td>Sinokor Merchant Marine</td>
            <td>WBCT</td>
            <td>Darlene Robertson</td>
            <td>POP20000005</td>
            <td>3</td>
            <td>06/11/2021</td>
            <td>$0.00</td>
            <td>$0.00</td>
            <td>$0.00</td>
            <td>
              <button
                className="btn btn-success btn-circle btn-xs bg-sucess"
                onClick={handleQuotesShowModal}
              >
                +
              </button>
            </td>
            <td>
              <button className="btn btn-link">
                <IconSendEmail />
              </button>
            </td>
            <td>
              <button className="btn btn-link">
                <IconPen className="" />
              </button>
            </td>
            <td>
              <button className="btn btn-link">
                <IconTrash />
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">Expired</span>
              </div>
            </td>
            <td>Sinokor Merchant Marine</td>
            <td>WBCT</td>
            <td>Darlene Robertson</td>
            <td>POP20000005</td>
            <td>3</td>
            <td>06/11/2021</td>
            <td>$0.00</td>
            <td>$0.00</td>
            <td>$0.00</td>
            <td>
              <button
                className="btn btn-success btn-circle btn-xs bg-sucess"
                onClick={handleQuotesShowModal}
              >
                +
              </button>
            </td>
            <td>
              <button className="btn btn-link">
                <IconSendEmail />
              </button>
            </td>
            <td>
              <button className="btn btn-link">
                <IconPen className="" />
              </button>
            </td>
            <td>
              <button className="btn btn-link">
                <IconTrash />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      {QuotesShowModal && (
        <AddQuotesModal
          onCancel={handleQuotesHideModal}
          onSubmit={handleQuotesHideModal}
        />
      )}
    </React.Fragment>
  );
};

export default StaticTableContent;
