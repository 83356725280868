import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IconSortDown, IconTrash } from '../../../common/icons';
import CustomSelect from '../../../common/CustomSelect';

const options = [
  { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
  { value: 'John Doe', label: 'John Doe' },
  { value: 'Alex Mendes', label: 'Alex Mendes' },
];

const formatOptionLabel = ({ label }) => (
  <div className="d-flex align-items-center ">
    <div>{label}</div>
    <div className="ml-auto">
      <div className="text-muted">
        <IconTrash />
      </div>
    </div>
  </div>
);

const DeductionRatesModal = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;
  return (
    <>
      <Modal show={showModal} centered size="xl">
        <Modal.Header className="">
          <Modal.Title>Guy Hawkins</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="table-responsive ml-0 mr-0 w-100 bg-gray-50">
            <table className="table table-card table-card--ls">
              <thead>
                <tr>
                  <th width="30%">
                    Deduction Type
                    <span>
                      <IconSortDown className="ml-1 text-gray-700" />
                    </span>
                  </th>
                  <th width="">Month</th>
                  <th width="">Week</th>
                  <th width="">Unit of measure</th>
                  <th width="">Math</th>
                  <th width="">Status</th>
                  <th>Amount</th>
                  <th>Limit</th>
                  <th width="2%"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <CustomSelect
                      options={options}
                      formatOptionLabel={formatOptionLabel}
                      placeholder="Select.."
                      size="small"
                      className="w-200"
                    />
                  </td>
                  <td>
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder="Select..."
                      className="w-150"
                    />
                  </td>
                  <td>
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder="Reoccur Every week"
                      className="w-200"
                    />
                  </td>
                  <td>
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder="Flat Amount"
                      className="w-150"
                    />
                  </td>
                  <td>
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder="Subtract"
                      className="w-150"
                    />
                  </td>
                  <td>
                    <span class=" badge badge-light">Disabled</span>
                  </td>
                  <td>
                    <input
                      className="form-control"
                      placeholder="$0.00"
                      style={{ width: '5rem' }}
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      placeholder="$0.00"
                      style={{ width: '5rem' }}
                    />
                  </td>
                  <td>
                    <button className="btn btn-none">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <CustomSelect
                      options={options}
                      formatOptionLabel={formatOptionLabel}
                      placeholder="Select.."
                      size="small"
                      className="w-200"
                    />
                  </td>
                  <td>
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder="Select..."
                      className="w-150"
                    />
                  </td>
                  <td>
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder="Reoccur Every week"
                      className="w-200"
                    />
                  </td>
                  <td>
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder="Flat Amount"
                      className="w-150"
                    />
                  </td>
                  <td>
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder="Subtract"
                      className="w-150"
                    />
                  </td>
                  <td>
                    <span class=" badge badge-light">Disabled</span>
                  </td>
                  <td>
                    <input
                      className="form-control"
                      placeholder="$0.00"
                      style={{ width: '5rem' }}
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      placeholder="$0.00"
                      style={{ width: '5rem' }}
                    />
                  </td>
                  <td>
                    <button className="btn btn-none">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <CustomSelect
                      options={options}
                      formatOptionLabel={formatOptionLabel}
                      placeholder="Select.."
                      size="small"
                      className="w-200"
                    />
                  </td>
                  <td>
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder="Select..."
                      className="w-150"
                    />
                  </td>
                  <td>
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder="Reoccur Every week"
                      className="w-200"
                    />
                  </td>
                  <td>
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder="Flat Amount"
                      className="w-150"
                    />
                  </td>
                  <td>
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder="Subtract"
                      className="w-150"
                    />
                  </td>
                  <td>
                    <span class=" badge badge-light">Disabled</span>
                  </td>
                  <td>
                    <input
                      className="form-control"
                      placeholder="$0.00"
                      style={{ width: '5rem' }}
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      placeholder="$0.00"
                      style={{ width: '5rem' }}
                    />
                  </td>
                  <td>
                    <button className="btn btn-none">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <CustomSelect
                      options={options}
                      formatOptionLabel={formatOptionLabel}
                      placeholder="Select.."
                      size="small"
                      className="w-200"
                    />
                  </td>
                  <td>
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder="Select..."
                      className="w-150"
                    />
                  </td>
                  <td>
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder="Reoccur Every week"
                      className="w-200"
                    />
                  </td>
                  <td>
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder="Flat Amount"
                      className="w-150"
                    />
                  </td>
                  <td>
                    <CustomSelect
                      name={'name'}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder="Subtract"
                      className="w-150"
                    />
                  </td>
                  <td>
                    <span class=" badge badge-light">Disabled</span>
                  </td>
                  <td>
                    <input
                      className="form-control"
                      placeholder="$0.00"
                      style={{ width: '5rem' }}
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      placeholder="$0.00"
                      style={{ width: '5rem' }}
                    />
                  </td>
                  <td>
                    <button className="btn btn-none">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
                <tr></tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" onClick={onCancel}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={onSubmit}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeductionRatesModal;
