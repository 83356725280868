import React from 'react';
import CustomSelect from '../../../../common/CustomSelect';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const ReportName = () => {
  return (
    <>
      <div className="title d-flex justify-content-center align-items-center pt-60 pb-50 font-20 font-weight-500 text-dark">
        Name your report
      </div>
      <div className="form-wrapper px-30 pb-30">
        <form>
          <div className="form-row ">
            <div className="form-group col-md-12">
              <input
                type="text"
                className="form-control"
                placeholder="Enter "
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ReportName;
