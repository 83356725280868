import React, { useState } from 'react';
import CustomSelect from '../../../../common/CustomSelect/index';

import {
  IconColumn,
  IconFilter,
  IconPen,
  IconCheckCircle,
  IconTrash,
  IconCancelCircle,
} from '../../../../common/icons/index';

const columns = ['Isrestricted', 'Name'];

const data = [
  {
    id: 1,
    isRestricted: 'Yes',
    name: `Perishables`,
  },
  {
    id: 2,
    isRestricted: '',
    name: `General Cargo`,
  },
  {
    id: 3,
    isRestricted: '',
    name: `HAZMAT CLASS 1	`,
  },
  {
    id: 4,
    isRestricted: '',
    name: `HAZMAT CLASS 4.3	`,
  },
];

const StaticTableContent = () => {
  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{ height: 'calc(100vh - 248px)' }}
      >
        <table className="table table-card">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
              <th style={{ width: '2%' }}></th>
              <th style={{ width: '2%' }}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((datum) => {
              return (
                <tr key={datum.id}>
                  <td style={{ width: '8%' }}>
                    <div className="badge badge-sm badge-light">
                      {datum.isRestricted}
                    </div>
                  </td>
                  <td>{datum.name}</td>
                  <td>
                    <button className="btn btn-link">
                      <IconPen className="text-muted" />
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-link">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
