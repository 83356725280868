import React, { useState } from "react";
import {
  IconFilter,
  IconFireCircle,
  IconMapMarkerCircle,
  IconReset,
  IconSnowFlake,
  IconSnowFlakeCircle,
  IconTags,
  IconTimesThin,
  IconTwoBarsCircle,
  IconWarningCircle,
  IconWeightToolCircle,
} from "../icons";

const FilterPop = (props) => {
  const { hideFilter } = props;
  const [showFilterTabs, setshowFilterTabs] = useState(1);
  const [subCardActive, setsubCardActive] = useState(false);
  
  const handleSubCardActive = (i) => {
    setsubCardActive(!subCardActive);
  };

  const handleFilterTabs = (i) => {
    setshowFilterTabs(showFilterTabs === i ? null : i);
  };
  return (
    <React.Fragment>
      <div className="bdpopup-backdrop"></div>
      <div className="bdpopup">
        <div className="bdpopup__content">
          <button
            className="btn btn-none bdpopup__close text-white"
            onClick={hideFilter}
          >
            <IconTimesThin className="close-icon" />
          </button>
          <div className="d-flex px-30 align-items-center justify-content-between pt-20">
            <p className="font-20 mb-0">Filters & Settings</p>
            <button className="btn btn-outline-light btn-sm mr-2">
              <IconReset className="mr-2" />
              Reset
            </button>
          </div>
          <ul
            className="nav nav-tabs nav-tabs-custom nav-justified nav-tabs-bottom-border mt-10"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className={`nav-link ${showFilterTabs === 1 ? "active" : ""}`}
                href="javascript:void(0)"
                onClick={() => handleFilterTabs(1)}
              >
                <IconTags className="mr-1" />
                Subacards
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${showFilterTabs === 2 ? "active" : ""}`}
                href="javascript:void(0)"
                onClick={() => handleFilterTabs(2)}
              >
                <IconFilter className="mr-1" />
                Filters
              </a>
            </li>
          </ul>

          {showFilterTabs === 1 && (
            <div
              className="px-30 bdpopup__content__scroll"
              style={{ height: "calc(100% - 108px)" }}
            >
              <div className="mt-20">
                <h5>Customer</h5>
                <div className="form-row-sm">
                  <div className="col-md-6 mb-1">
                    <button
                      className={`subcard-item w-100 d-flex align-items-center ${
                        subCardActive && "subcard-item--active"
                      }`}
                      onClick={handleSubCardActive}
                    >
                      ABC Distibution
                      <span className="ml-auto">42</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      APM Consi
                      <span className="ml-auto">55</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      Home Depot
                      <span className="ml-auto">42</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      Interline
                      <span className="ml-auto">32</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      National Retail Store
                      <span className="ml-auto">39</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      Noatum logistics
                      <span className="ml-auto">60</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      OEC Group
                      <span className="ml-auto">46</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      Tom Details
                      <span className="ml-auto">76</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-20">
                <h5>Port</h5>
                <div className="form-row-sm">
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      APM
                      <span className="ml-auto">17</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      National Retail Store
                      <span className="ml-auto">56</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      Terminal
                      <span className="ml-auto">30</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      Everport
                      <span className="ml-auto">28</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      GCT Bayonne
                      <span className="ml-auto">80</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      WBCT
                      <span className="ml-auto">15</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      Maher Terminal
                      <span className="ml-auto">48</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      ITS - Pier G<span className="ml-auto">42</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-20">
                <h5>Return</h5>
                <div className="form-row-sm">
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      APM
                      <span className="ml-auto">70</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      SSA - PIER A<span className="ml-auto">55</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      Everport
                      <span className="ml-auto">22</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      Terminal
                      <span className="ml-auto">41</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      GCT Bayonne
                      <span className="ml-auto">23</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      WBCT
                      <span className="ml-auto">53</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      PNCT
                      <span className="ml-auto">32</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-20">
                <h5>Readiness for Pick Up</h5>
                <div className="form-row-sm">
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      Ready
                      <span className="ml-auto">48</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      Not Ready
                      <span className="ml-auto">50</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-20">
                <h5>Consignee</h5>
                <div className="form-row-sm">
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      ABC Distibution
                      <span className="ml-auto">53</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      Accem Warehouse
                      <span className="ml-auto">45</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      Home Depot
                      <span className="ml-auto">74</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      Amalgamed Textiles
                      <span className="ml-auto">45</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      Interline
                      <span className="ml-auto">23</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      National Retail Store
                      <span className="ml-auto">33</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      Perfect Christmas Trees
                      <span className="ml-auto">65</span>
                    </button>
                  </div>
                  <div className="col-md-6 mb-1">
                    <button className="subcard-item w-100 d-flex align-items-center">
                      NRS Warehouse
                      <span className="ml-auto">50</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showFilterTabs === 2 && (
            <React.Fragment>
              <div
                className="px-30 bdpopup__content__scroll"
                style={{ height: "calc(100% - 182px)" }}
              >
                <div className="row">
                  <div className="col-md-6 d-flex align-items-center mt-20">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="Caution"
                      />
                      <label
                        className="form-check-label d-flex align-items-center"
                        for="Caution"
                      >
                        <IconWarningCircle className="text-warning-500 mr-10" />
                        Caution
                      </label>
                    </div>
                    <div className="ml-auto font-medium">47</div>
                  </div>
                  <div className="col-md-6 d-flex align-items-center mt-20">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="Local"
                      />
                      <label
                        className="form-check-label d-flex align-items-center"
                        for="Local"
                      >
                        <IconMapMarkerCircle className="text-gray-700 mr-10" />
                        Local
                      </label>
                    </div>
                    <div className="ml-auto font-medium">3</div>
                  </div>
                  <div className="col-md-6 d-flex align-items-center mt-20">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="scale"
                      />
                      <label
                        className="form-check-label d-flex align-items-center"
                        for="scale"
                      >
                        <IconWeightToolCircle className="text-gray-700 mr-10" />
                        Scalce
                      </label>
                    </div>
                    <div className="ml-auto font-medium">3</div>
                  </div>
                  <div className="col-md-6 d-flex align-items-center mt-20">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="notes"
                      />
                      <label
                        className="form-check-label d-flex align-items-center"
                        for="notes"
                      >
                        <IconTwoBarsCircle className="text-gray-700 mr-10" />
                        Notes
                      </label>
                    </div>
                    <div className="ml-auto font-medium">3</div>
                  </div>
                  <div className="col-md-6 d-flex align-items-center mt-20">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="Reefer"
                      />
                      <label
                        className="form-check-label d-flex align-items-center"
                        for="Reefer"
                      >
                        <IconSnowFlakeCircle className="text-gray-700 mr-10" />
                        Reefer
                      </label>
                    </div>
                    <div className="ml-auto font-medium">3</div>
                  </div>
                  <div className="col-md-6 d-flex align-items-center mt-20">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="hot"
                      />
                      <label
                        className="form-check-label d-flex align-items-center"
                        for="hot"
                      >
                        <IconFireCircle className="text-error-500 mr-10" />
                        Hot
                      </label>
                    </div>
                    <div className="ml-auto font-medium">3</div>
                  </div>
                </div>
                <div className="hr-light"></div>
                <React.Fragment>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">APT From</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select APT from date"
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">APT To</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select APT from date"
                      ></input>
                    </div>
                  </div>
                </React.Fragment>
                <div className="hr-light"></div>
                <React.Fragment>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">
                      Pickup APT From
                    </label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select Pickup APT from date"
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">
                      Pickup APT From
                    </label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select Pickup APT from date"
                      ></input>
                    </div>
                  </div>
                </React.Fragment>
                <div className="hr-light"></div>
                <React.Fragment>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Load Type</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select..."
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Customer</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select..."
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Port</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select..."
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Consignee</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select..."
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Return</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select..."
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Driver</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select..."
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">
                      Created by
                    </label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select..."
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Status</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select..."
                      ></input>
                    </div>
                  </div>
                </React.Fragment>
                <div className="hr-light"></div>
                <React.Fragment>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">
                      Container Type
                    </label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select"
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">
                      Container Size
                    </label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select"
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">SSL</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select"
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">BOL/BKG</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select"
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">
                      Vessel Name
                    </label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select"
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Return</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select"
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Seal No</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select"
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Pickup #</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select"
                      ></input>
                    </div>
                  </div>
                </React.Fragment>
              </div>
              <div className="bdpopup__footer page-fluid">
                <button className="btn btn-primary">Apply</button>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FilterPop;
