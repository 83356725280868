import React, { Component } from "react";
import UploadDocument from "../../../../common/uploadDocument";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

class TabHistory extends Component {
  render() {
    return (
      <div className="tab" id="tab-document">
        <div className="table-responsive">
          <table className="table table-card table-card--ls mb-10">
            <thead>
              <tr>
                <th>Equipment</th>
                <th>Load</th>
                <th>Container #</th>
                <th>Driver</th>
                <th>Status</th>
                <th>Date</th>
                <th>From</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ABCD123456</td>
                <td>POP2_M100791</td>
                <td>MNBU9153236</td>
                <td>
                  <div className="d-flex">
                    <div className="mr-2 align-self-center">
                      <div className="avatar-circle avatar-xs">
                        <div className="avatar-circle__initial ">K</div>
                      </div>
                    </div>
                    <h5 className="mb-0 align-self-center">
                      <a href="#" className="text-dark">
                        Kathryn Murphy
                      </a>
                    </h5>
                  </div>
                </td>
                <td>
                  <span className="badge badge-green">Dropped</span>
                </td>
                <td>
                  <div>06/11/21</div>
                  <div className="text-muted">8:50PM</div>
                </td>
                <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
              </tr>
              <tr>
                <td>ABCD123456</td>
                <td>POP2_M100791</td>
                <td>MNBU9153236</td>
                <td>
                  <div className="d-flex">
                    <div className="mr-2 align-self-center">
                      <div className="avatar-circle avatar-xs">
                        <div className="avatar-circle__initial ">K</div>
                      </div>
                    </div>
                    <h5 className="mb-0 align-self-center">
                      <a href="#" className="text-dark">
                        Kathryn Murphy
                      </a>
                    </h5>
                  </div>
                </td>
                <td>
                  <span className="badge badge-green">Dropped</span>
                </td>
                <td>
                  <div>06/11/21</div>
                  <div className="text-muted">8:50PM</div>
                </td>
                <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
              </tr>
              <tr>
                <td>ABCD123456</td>
                <td>POP2_M100791</td>
                <td>MNBU9153236</td>
                <td>
                  <div className="d-flex">
                    <div className="mr-2 align-self-center">
                      <div className="avatar-circle avatar-xs">
                        <div className="avatar-circle__initial ">K</div>
                      </div>
                    </div>
                    <h5 className="mb-0 align-self-center">
                      <a href="#" className="text-dark">
                        Kathryn Murphy
                      </a>
                    </h5>
                  </div>
                </td>
                <td>
                  <span className="badge badge-green">Dropped</span>
                </td>
                <td>
                  <div>06/11/21</div>
                  <div className="text-muted">8:50PM</div>
                </td>
                <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
              </tr>
              <tr>
                <td>ABCD123456</td>
                <td>POP2_M100791</td>
                <td>MNBU9153236</td>
                <td>
                  <div className="d-flex">
                    <div className="mr-2 align-self-center">
                      <div className="avatar-circle avatar-xs">
                        <div className="avatar-circle__initial ">K</div>
                      </div>
                    </div>
                    <h5 className="mb-0 align-self-center">
                      <a href="#" className="text-dark">
                        Kathryn Murphy
                      </a>
                    </h5>
                  </div>
                </td>
                <td>
                  <span className="badge badge-green">Dropped</span>
                </td>
                <td>
                  <div>06/11/21</div>
                  <div className="text-muted">8:50PM</div>
                </td>
                <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
              </tr>
              <tr>
                <td>ABCD123456</td>
                <td>POP2_M100791</td>
                <td>MNBU9153236</td>
                <td>
                  <div className="d-flex">
                    <div className="mr-2 align-self-center">
                      <div className="avatar-circle avatar-xs">
                        <div className="avatar-circle__initial ">K</div>
                      </div>
                    </div>
                    <h5 className="mb-0 align-self-center">
                      <a href="#" className="text-dark">
                        Kathryn Murphy
                      </a>
                    </h5>
                  </div>
                </td>
                <td>
                  <span className="badge badge-green">Dropped</span>
                </td>
                <td>
                  <div>06/11/21</div>
                  <div className="text-muted">8:50PM</div>
                </td>
                <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
              </tr>
              <tr>
                <td>ABCD123456</td>
                <td>POP2_M100791</td>
                <td>MNBU9153236</td>
                <td>
                  <div className="d-flex">
                    <div className="mr-2 align-self-center">
                      <div className="avatar-circle avatar-xs">
                        <div className="avatar-circle__initial ">K</div>
                      </div>
                    </div>
                    <h5 className="mb-0 align-self-center">
                      <a href="#" className="text-dark">
                        Kathryn Murphy
                      </a>
                    </h5>
                  </div>
                </td>
                <td>
                  <span className="badge badge-green">Dropped</span>
                </td>
                <td>
                  <div>06/11/21</div>
                  <div className="text-muted">8:50PM</div>
                </td>
                <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default TabHistory;
