import React, { useEffect, Component } from "react";
import  {getAllThemes} from "./mock";

const THEME_DATA = "themeData";
const PRIMARY_COLOR = "#367bf6";


class ChangeColor extends Component {
  constructor(props) {
    super(props);
    this.setState({
      colorOptions: null,
    });
    this.fetchAllThemeOptions();
    const themeColor = this.getThemeDataFromDatabase();
    if(themeColor) {
      this.changeTheme(themeColor);
    } else {
      this.changeTheme(PRIMARY_COLOR);
    }
  }

  async fetchAllThemeOptions() {
    try {
      const response = await getAllThemes();
      if(response) {
        this.setState({
          colorOptions: response.themes,
        })
      }
    } catch(e) {
      console.log("Exception: ", e);
    }
  }

  async saveThemeToDatabase(theme) {
    // TODO: save theme data to database;
    return await this.saveThemeToLocalStore(theme);
  } 
  
  getThemeDataFromDatabase (userId) {
    // TODO: get theme data from database;
    // const themeFromDataBase = "NET WORK CALL WILL HAPPEN HERE";
    // SAVE THEME FROM NETWORK TO LOCALSTORAGE;
    // await this.saveThemeToLocalStore(themeFromDataBase);
    const themeData = this.getThemeFromLocalStore();
    return themeData;
  }
  
  async saveThemeToLocalStore(themeColor) {
    const value = await localStorage.setItem(THEME_DATA, themeColor);
    return value;
  }

  getThemeFromLocalStore() {
    const value = localStorage.getItem(THEME_DATA);
    return value;
  }

  async updateTheme(themeData) {
    await this.saveThemeToDatabase(themeData);
    this.changeTheme(themeData);
  }

  changeTheme(themeData) {
    //this.setState({ color: e.target.value });
    const scssVariables = Object.keys(themeData);
    console.log("Scss Variables: ", scssVariables)
    scssVariables.forEach((variable) => {
      document.documentElement.style.setProperty(variable,themeData[variable]);
    });
    // document.documentElement.style.setProperty("--color-primary-l",colorChoosen);
  }

  render() {
    return (
      <div className="position-relative subheader-wrapper">
        <div className="page-fluid">
          <div className="top-page">
            <h4 class="font-20">Choose your primary color</h4>
            <button className="btn btn-primary mb-15">Primary Color Button</button>
            <div className="form-row">
              {this.state && this.state.colorOptions && this.state.colorOptions.map((colorOption) => (
                <div className="col-lg-2 pointer" onClick={async () => this.updateTheme(colorOption.themeData)}>
                  <div className={`card color-box p-10`}>
                    <div
                      className="color-box__color"
                      style={{
                        height: "150px",
                        backgroundColor: colorOption.themeData["--color-primary"],
                      }}
                    ></div>
                    <div className="color-box__label mt-10 text-center font-medium">{colorOption.name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangeColor;
