import React, { useState } from 'react';
import { IconPlus } from '../../../common/icons';
import OtherTabs from '../common-elements/OtherTabs';
import ContainerTabs from '../container/ContainerTabs';
import Pagination from '../common-elements/Pagination';
import StaticTableContent from './elements/StaticTableContent';

const RoleManagement = () => {
  const [showNewRoleModal, setShowNewRoleModal] = useState(false);

  const handleShowNewRoleModal = (event) => {
    setShowNewRoleModal(true);
  };

  const handleHideNewRoleModal = (event) => {
    setShowNewRoleModal(false);
  };
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <OtherTabs
            activeTab={11}
            button={
              <button className="ml-auto btn btn-success">
                <IconPlus className="mr-2" />
                Add New Role
              </button>
            }
          />
        </div>
      </div>
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-15 d-flex align-items-center justify-content-between">
            <h4 className="font-20 mb-0">23 Assigned Roles</h4>
            <Pagination />
          </div>
        </div>
        {/* Static Table Content */}
        <StaticTableContent />
      </div>
    </div>
  );
};

export default RoleManagement;
