import React, { useState } from "react";
import SearchFilter from "./elements/SearchFilter";
import StaticTableContent from "./elements/StaticTableContent";
import TopPage from "../common-elements/TopPage";
import AccountPayableTabs from "../common-elements/AccountPayableTabs";
import { IconDownload, IconPlus } from "../../../common/icons";
import UploadDeduction from "../../../common/uploadDeduction";
import { Modal } from "react-bootstrap";
import CustomSelect from "../../../common/CustomSelect";

const DriverSettlements = () => {
  const [showUploadDeduction, setshowUploadDeduction] = useState(false);
  const [deductionStep, setDeductionStep] = useState("first");

  const handleShowUploadDeduction = () => {
    setshowUploadDeduction(true);
    console.log("hellomodalshow");
  };
  const handleHideUploadDeduction = () => {
    setshowUploadDeduction(false);
  };
  const handleDeductionSteps = (getStep) => {
    setDeductionStep(getStep);
  };
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <AccountPayableTabs
            activeTab={2}
            button={
              <>
                <button
                  className="ml-auto btn btn-outline-light mr-2"
                  onClick={handleShowUploadDeduction}
                >
                  <IconDownload className="mr-2" />
                  Upload Deduction
                </button>
                <button className="btn btn-outline-light mr-2">
                  <IconDownload className="mr-2" />
                  Download CSV
                </button>

                <button className="btn btn-success font-weight-500">
                  <IconPlus className="mr-2" />
                  Add Settlements
                </button>
              </>
            }
          />
        </div>
      </div>
      <div className="page-fluid">
        <TopPage />
        <SearchFilter />
        <StaticTableContent />
        <Modal
          show={showUploadDeduction}
          centered
          animation={false}
          dialogClassName="modal-dialog-centered modal-dialog-scrollable"
        >
          <Modal.Header>
            <Modal.Title>Add Deduction</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-0">
            <div className="form-group px-30 ">
              <label>Document Type</label>
              <CustomSelect
                name={"name"}
                options={("PDF", "CSV")}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            {deductionStep === "first" ? (
              <div className="px-30">
                <UploadDeduction />
              </div>
            ) : (
              <table className="table table-card table-card--ls mt-20">
                <thead>
                  <tr>
                    <th width="40">
                      <div className="from-check form-check--gray">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="sr_no"
                        />
                      </div>
                    </th>
                    <th>Description</th>
                    <th>Apply Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="from-check form-check--gray">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="sr_no"
                        />
                      </div>
                    </td>
                    <td>This is a test description</td>
                    <td>3/22/2022</td>
                  </tr>
                </tbody>
              </table>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={handleHideUploadDeduction}
            >
              Cancel
            </button>
            {deductionStep === "second" && (
              <button
                className="btn btn-outline-light"
                onClick={() => handleDeductionSteps("first")}
              >
                Rematch Again
              </button>
            )}
            <button
              className="btn btn-primary"
              onClick={() => handleDeductionSteps("second")}
            >
              {deductionStep === "second" ? "Add" : "Next"}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default DriverSettlements;
