import React, { useState } from "react";
import CustomSelect from "../../../../common/CustomSelect/index";

import {
  IconColumn,
  IconFilter,
  IconPen,
  IconCheckCircle,
  IconTrash,
  IconCancelCircle,
} from "../../../../common/icons/index";

const columns = ["Name", "Type", "Fields", "Duration", "Customer", "Frequency"];

const data = [
  {
    id: 1,
    name: `AB Supply`,
    type: "Charge Report",
    fields: "Toll, Chassis, Fuel, Bill date, +4",
    duration: "Current Day",
    customer: "Unifeeder",
    frequency: "Daily",
    freqTime: "02:02 am",
  },
  {
    id: 2,
    name: `AB Supply`,
    type: "Loads Report",
    fields: "Toll, Chassis, Fuel, Bill date, +4",
    duration: "Last 7 Days",
    customer: "Wan Hai Lines",
    frequency: "Weekly (Mon)",
    freqTime: "02:02 am",
  },
  {
    id: 3,
    name: `AB Supply`,
    type: "Loads Report",
    fields: "Toll, Chassis, Fuel, Bill date, +4",
    duration: "Last 14 Days",
    customer: "HMM Co. Ltd.",
    frequency: "Every 2 weeks",
    freqTime: "02:02 am",
  },
  {
    id: 4,
    name: `AB Supply`,
    type: "Vessel Report",
    fields: "Toll, Chassis, Fuel, Bill date, +4",
    duration: "Last 30 Days",
    customer: "Transworld Group",
    frequency: "Monthly",
    freqTime: "02:02 am",
  },
  {
    id: 5,
    name: `AB Supply`,
    type: "Equipment Report",
    fields: "Toll, Chassis, Fuel, Bill date, +4",
    duration: "Last 3 Months",
    customer: "Yang Ming Marine Transport Corporation",
    frequency: "Specific Day (4)",
    freqTime: "02:02 am",
  },
  {
    id: 6,
    name: `AB Supply`,
    type: "Revenue Report",
    fields: "Toll, Chassis, Fuel, Bill date, +4",
    duration: "Last 6 Months",
    customer: "Antong Holdings (QASC)",
    frequency: "Every 2 weeks (Tue)",
    freqTime: "02:02 am",
  },
  {
    id: 7,
    name: `AB Supply`,
    type: "Driver Pay Report",
    fields: "Toll, Chassis, Fuel, Bill date, +4",
    duration: "Last 12 Months",
    customer: "Evergreen Marine Corporation",
    frequency: "Every 2 weeks (Wed)",
    freqTime: "02:02 am",
  },
  {
    id: 8,
    name: `AB Supply`,
    type: "LFD Report",
    fields: "Toll, Chassis, Fuel, Bill date, +4",
    duration: "Last 7 Days",
    customer: "COSCO",
    frequency: "Every 2 weeks (Fri)",
    freqTime: "02:02 am",
  },
];

const StaticTableContent = () => {
  const [isPopDrop, setIsPopDrop] = useState(false);

  const handlePopDrop = (event) => {
    setIsPopDrop(!isPopDrop);
  };
  return (
    <React.Fragment>
      {/* Filter Row */}
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="form-check form-check--gray pr-30">
            <input
              className="form-check-input"
              type="checkbox"
              id="Available"
            />
            <label className="form-check-label" for="Available">
              Enabled
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                47
              </span>
            </label>
          </div>
          <div className="form-check form-check--gray pr-30">
            <input className="form-check-input" type="checkbox" id="Pending" />
            <label className="form-check-label" for="Pending">
              Disabled
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                6
              </span>
            </label>
          </div>
        </div>
        <div className="ml-auto d-flex">
          <button className="btn btn-outline-light mr-2">
            <IconFilter className="mr-2" />
            Filter
          </button>
          <div className="position-relative">
            <button className="btn btn-outline-light position-relative">
              <IconColumn />
            </button>
          </div>
        </div>
      </div>
      {/* Table Content */}
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 248px)" }}
      >
        <table className="table table-card">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
              <th style={{ width: "2%" }}></th>
              <th style={{ width: "2%" }}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((datum) => {
              return (
                <tr key={datum.id}>
                  <td>{datum.name}</td>
                  <td>
                    <div className="badge badge-light">{datum.type}</div>
                  </td>
                  <td>{datum.fields}</td>
                  <td>{datum.duration}</td>
                  <td>{datum.customer}</td>
                  <td>
                    <div>{datum.frequency}</div>
                    <div className="text-muted">{datum.freqTime}</div>
                  </td>
                  <td>{datum.fields}</td>

                  <td>
                    <button className="btn btn-link">
                      <IconPen className="text-primary" />
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-link">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
