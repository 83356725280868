import React from "react";
import { IconPen, IconTrash } from "../../../../../common/icons";

const StaticTableContent = () => {
  return (
    <React.Fragment>
      {/* Search Filter */}
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="form-check form-check--gray pr-30">
            <input
              className="form-check-input"
              type="checkbox"
              id="Available"
              checked="true"
            />
            <label className="form-check-label" for="Available">
              Enabled
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                47
              </span>
            </label>
          </div>
          <div className="form-check form-check--gray pr-30">
            <input
              className="form-check-input"
              type="checkbox"
              id="Pending"
              checked="true"
            />
            <label className="form-check-label" for="Pending">
              Disabled
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                6
              </span>
            </label>
          </div>
        </div>
      </div>
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 368px)" }}
      >
        <table className="table table-card ">
          <thead>
            <tr>
              <th width="">Chassis #</th>
              <th>Load #</th>
              <th>Address</th>
              <th>Chassis Owner</th>
              <th>Chassis Size</th>
              <th>Chassis Type</th>
              <th>License State</th>
              <th>License Plate #</th>
              <th>VIN</th>
              <th>Bobtail emission</th>
              <th>Diesel emission</th>
              <th>IFTA</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td className="bg-warning-500">18/09/2016</td>
              <td className="bg-warning-200">18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td className="bg-warning-500">18/09/2016</td>
              <td className="bg-warning-200">18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td>18/09/2016</td>
              <td className="bg-warning-200">18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td className="bg-warning-50">18/09/2016</td>
              <td className="bg-warning-200">18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td>18/09/2016</td>
              <td className="bg-warning-200">18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td className="bg-warning-50">18/09/2016</td>
              <td className="bg-warning-200">18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td className="bg-warning-500">18/09/2016</td>
              <td className="bg-warning-200">18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td className="bg-warning-500">18/09/2016</td>
              <td className="bg-warning-200">18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td>18/09/2016</td>
              <td className="bg-warning-200">18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td className="bg-warning-50">18/09/2016</td>
              <td className="bg-warning-200">18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td>18/09/2016</td>
              <td className="bg-warning-200">18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td className="bg-warning-50">18/09/2016</td>
              <td className="bg-warning-200">18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td className="bg-warning-500">18/09/2016</td>
              <td className="bg-warning-200">18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td className="bg-warning-500">18/09/2016</td>
              <td className="bg-warning-200">18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td>18/09/2016</td>
              <td className="bg-warning-200">18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td className="bg-warning-50">18/09/2016</td>
              <td className="bg-warning-200">18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td>18/09/2016</td>
              <td className="bg-warning-200">18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td className="bg-warning-50">18/09/2016</td>
              <td className="bg-warning-200">18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>PLOK234567</td>
              <td>13123121412</td>
              <td>6391 Elgin St. Celina, Delaware 10299</td>
              <td>Yang Ming Marine Transport Corporation</td>
              <td>40'</td>
              <td>Standard</td>
              <td>NJ</td>
              <td>AB-1234567</td>
              <td>441234567</td>
              <td className="bg-warning-500">18/09/2016</td>
              <td className="bg-warning-200">18/09/2016</td>
              <td>18/09/2016</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default StaticTableContent;
