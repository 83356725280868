import React, { useState, Component } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CustomerTabs from '../common-elements/CustomerTabs';
import {
  IconFilter,
  IconColumn,
  IconAngleArrowLeft,
  IconAngleArrowRight,
  IconCalendar,
  IconCaretDown,
} from '../../../common/icons';
import FilterSettings from '../FilterSettings';
import LoadPage from '../LoadPage';
import StaticTableContent from '../StaticTableContent';
import SubHeader from '../subheader';
import Pagination from '../../../common/pagination';

class DroppedContainers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTabActive: false,
      isCardActive: false,
      isPopupOpen: false,
      openPopup: false,
      hidePopup: false,
      issubCardItem: false,
      openLoad: false,
    };
  }

  openLoad = (event) => {
    this.setState({ openLoad: true });
  };

  hideLoad = (event) => {
    this.setState({ openLoad: false });
  };

  openPopup = (event) => {
    document.body.classList.add('bdpopup-open');
    this.setState({ isPopupOpen: true });
  };

  hidePopup = (event) => {
    document.body.classList.remove('bdpopup-open');
    this.setState({ isPopupOpen: false });
  };

  handleTabActive = (i) => {
    this.setState({
      isTabActive: this.state.isTabActive === i ? null : i,
    });
  };
  handleCardActive = (i) => {
    this.setState({
      isCardActive: this.state.isCardActive === i ? null : i,
    });
  };
  handleSubCardItem() {
    this.setState({ isSubCardItem: !this.state.isSubCardItem });
  }

  render() {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 12,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 8,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 6,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
      },
    };

    const CustomRightArrow = ({ onClick, ...rest }) => {
      const {
        onMove,
        carouselState: { currentSlide, deviceType },
      } = rest;
      return (
        <button
          class="react-multiple-carousel__arrow react-multiple-carousel__arrow--right custom-arrow"
          onClick={() => onClick()}
        >
          <IconAngleArrowRight className="text-white" />
        </button>
      );
    };

    const CustomLeftArrow = ({ onClick, ...rest }) => {
      const {
        onMove,
        carouselState: { currentSlide, deviceType },
      } = rest;
      return (
        <button
          class="react-multiple-carousel__arrow react-multiple-carousel__arrow--left custom-arrow"
          onClick={() => onClick()}
        >
          <IconAngleArrowLeft className="text-white" />
        </button>
      );
    };

    return (
      <React.Fragment>
        <div className="position-relative subheader-wrapper">
          <CustomerTabs activeTab={4} />
          {this.state.openLoad == false ? (
            <div className="page-fluid">
              <div className="top-page">
                <div className="pb-15 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <button className="btn btn-white mr-3">
                      <IconAngleArrowLeft />
                    </button>
                    <div className="d-flex align-items-center mr-3">
                      <h4 className="font-20 mb-0">Today</h4>
                      <IconCaretDown />
                    </div>
                    <button className="btn btn-white mr-3">
                      <IconAngleArrowRight />
                    </button>
                    <button className="btn btn-white">
                      <IconCalendar />
                    </button>
                  </div>
                  <Pagination className="ml-auto" />
                </div>

                <div
                  className={`form-carousel my-2 ${
                    this.state.isCardActive ? 'hasactive' : ''
                  }`}
                >
                  <Carousel
                    containerClass="carousel-container w-100"
                    responsive={responsive}
                    infinite={true}
                    className="custom-card-carousel"
                    customRightArrow={<CustomRightArrow />}
                    customLeftArrow={<CustomLeftArrow />}
                  >
                    <div className="slider-item  h-100 ">
                      <div
                        className={`ncard d-flex flex-column justify-content-between ${
                          this.state.isCardActive === 1 ? 'active' : ''
                        }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleCardActive(1)}
                      >
                        <div className="d-flex mb-10">
                          <div className="font-medium">LB Yard</div>
                          <div className="ml-auto">
                            <IconAngleArrowRight />
                          </div>
                        </div>
                        <div className="mr-1 mt-2 bbox__number">38</div>
                      </div>
                    </div>
                    <div className="slider-item  h-100">
                      <div
                        className={`ncard d-flex flex-column justify-content-between ${
                          this.state.isCardActive === 2 ? 'active' : ''
                        }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleCardActive(2)}
                      >
                        <div className="d-flex mb-10">
                          <div className="font-medium">
                            Amalgamated Textiles Inc
                          </div>
                          <div className="ml-auto">
                            <IconAngleArrowRight />
                          </div>
                        </div>
                        <div className="mr-1 mt-2 bbox__number">38</div>
                      </div>
                    </div>
                    <div className="slider-item  h-100">
                      <div
                        className={`ncard d-flex flex-column justify-content-between ${
                          this.state.isCardActive === 3 ? 'active' : ''
                        }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleCardActive(3)}
                      >
                        <div className="d-flex mb-10">
                          <div className="font-medium">Container Storage</div>
                          <div className="ml-auto">
                            <IconAngleArrowRight />
                          </div>
                        </div>
                        <div className="mr-1 mt-2 bbox__number">38</div>
                      </div>
                    </div>
                    <div className="slider-item  h-100">
                      <div
                        className={`ncard d-flex flex-column justify-content-between ${
                          this.state.isCardActive === 4 ? 'active' : ''
                        }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleCardActive(4)}
                      >
                        <div className="d-flex mb-10">
                          <div className="font-medium">
                            National Retail Storage
                          </div>
                          <div className="ml-auto">
                            <IconAngleArrowRight />
                          </div>
                        </div>
                        <div className="mr-1 mt-2 bbox__number">38</div>
                      </div>
                    </div>
                    <div className="slider-item  h-100">
                      <div
                        className={`ncard d-flex flex-column justify-content-between ${
                          this.state.isCardActive === 5 ? 'active' : ''
                        }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleCardActive(5)}
                      >
                        <div className="d-flex mb-10">
                          <div className="font-medium">Accem Warehouse</div>
                          <div className="ml-auto">
                            <IconAngleArrowRight />
                          </div>
                        </div>
                        <div className="mr-1 mt-2 bbox__number">38</div>
                      </div>
                    </div>
                    <div className="slider-item  h-100">
                      <div
                        className={`ncard d-flex flex-column justify-content-between ${
                          this.state.isCardActive === 6 ? 'active' : ''
                        }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleCardActive(6)}
                      >
                        <div className="d-flex mb-10">
                          <div className="font-medium">
                            Perfect Christmas Trees
                          </div>
                          <div className="ml-auto">
                            <IconAngleArrowRight />
                          </div>
                        </div>
                        <div className="mr-1 mt-2 bbox__number">38</div>
                      </div>
                    </div>
                    <div className="slider-item  h-100">
                      <div
                        className={`ncard d-flex flex-column justify-content-between ${
                          this.state.isCardActive === 7 ? 'active' : ''
                        }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleCardActive(7)}
                      >
                        <div className="d-flex mb-10">
                          <div className="font-medium">NRS Warehouse</div>
                          <div className="ml-auto">
                            <IconAngleArrowRight />
                          </div>
                        </div>
                        <div className="mr-1 mt-2 bbox__number">38</div>
                      </div>
                    </div>
                    <div className="slider-item  h-100">
                      <div
                        className={`ncard d-flex flex-column justify-content-between ${
                          this.state.isCardActive === 8 ? 'active' : ''
                        }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleCardActive(8)}
                      >
                        <div className="d-flex mb-10">
                          <div className="font-medium">Interline</div>
                          <div className="ml-auto">
                            <IconAngleArrowRight />
                          </div>
                        </div>
                        <div className="mr-1 mt-2 bbox__number">38</div>
                      </div>
                    </div>
                    <div className="slider-item  h-100">
                      <div
                        className={`ncard d-flex flex-column justify-content-between ${
                          this.state.isCardActive === 9 ? 'active' : ''
                        }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleCardActive(9)}
                      >
                        <div className="d-flex mb-10">
                          <div className="font-medium">Seattle DC 75</div>
                          <div className="ml-auto">
                            <IconAngleArrowRight />
                          </div>
                        </div>
                        <div className="mr-1 mt-2 bbox__number">38</div>
                      </div>
                    </div>
                  </Carousel>
                </div>
                <div className="filter-row d-flex align-items-center mt-2">
                  <div className="app-search header-search">
                    <span className="search-icon"> </span>
                    <div className="position-relative">
                      <input
                        type="search"
                        className="form-control input-search-left"
                        placeholder="Search..."
                        value=""
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check--gray pr-30">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="Ready for pickup"
                      />
                      <label className="form-check-label" for="Available">
                        Ready for pickup
                        <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                          47
                        </span>
                      </label>
                    </div>
                    <div className="form-check form-check--gray pr-30">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="Not ready for pickup"
                      />
                      <label className="form-check-label" for="Pending">
                        Not ready for pickup
                        <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                          6
                        </span>
                      </label>
                    </div>
                  </div>
                  <div className="ml-auto">
                    <button
                      className="btn btn-outline-light mr-2"
                      onClick={() => this.openPopup()}
                    >
                      <IconFilter className="mr-2" />
                      Filter
                    </button>
                    <button className="btn btn-outline-light">
                      <IconColumn />
                    </button>
                  </div>
                </div>
              </div>

              <StaticTableContent
                handleLoadOpen={() => {
                  this.setState({ openLoad: true });
                }}
              ></StaticTableContent>
            </div>
          ) : (
            <LoadPage />
          )}
        </div>
        <FilterSettings />
      </React.Fragment>
    );
  }
}

export default DroppedContainers;
