import React, { useState } from "react";

import CustomSelect from "../../common/CustomSelect";
import {
  IconPlus,
  IconCalendar,
  IconPhone,
  IconAt,
  IconInfoFocus,
  IconDocument,
  IconBilling,
  IconPayment,
  IconStatus,
  IconTruck,
  IconTrack,
  IconMessage,
  IconSearchData,
  IconNotes,
} from "../../common/icons";
import Audit from "./tabContent/audit";
import Billing from "./tabContent/billing";
import Documents from "./tabContent/documents";
import DriverPay from "./tabContent/driverpay";
import LoadInfoVertical from "./tabContent/loadInfoVertical";
import Messaging from "./tabContent/messaging";
import Notes from "./tabContent/notes";
import Payments from "./tabContent/payment";
import Tracking from "./tabContent/tracking";
import Routing from "./tabContent/routing";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const LoadPage = (props) => {
  const [isLoadTabActive, setisLoadTabActive] = useState(1);
  const handleLoadTabActive = (i) => {
    setisLoadTabActive(isLoadTabActive === i ? null : i);
  };

  return (
    <div className="page-fluid">
      <div className="form-row-md">
        <div className="col-md" style={{ maxWidth: 270 }}>
          <div className="card p-15 mb-1">
            <h5>Customer</h5>
            <div>ABC DISTRIBUTION</div>
            <div className="mb-10">
              Main St, Boston, MA 02129, dominican republic
            </div>

            <div className="mb-10">
              <IconPhone className="mr-2" />
              <span>+7 (903) 880-91-85</span>
            </div>
            <div>
              <IconAt className="mr-2" />
              <span>michelle.rivera@example.com</span>
            </div>
          </div>
          <div className="card p-15 mb-1">
            <h5>Port</h5>
            <div className="font-medium">Maher Terminal</div>
            <div>1210 CORBIN ST, ELIZABETH, NJ 07201, USA</div>
          </div>
          <div className="card p-15 mb-1">
            <h5>Consignee</h5>
            <div className="mb-10">
              <div className="font-medium">Perfect Christmas Trees</div>
              <div>999 US-22, PHILLIPSBURG, NJ 08865</div>
            </div>
            <div className="mb-10">
              <div className="font-medium">Perfect Christmas Trees</div>
              <div>999 US-22, PHILLIPSBURG, NJ 08865</div>
            </div>
            <div className="mb-10">
              <div className="font-medium">Perfect Christmas Trees</div>
              <div>999 US-22, PHILLIPSBURG, NJ 08865</div>
            </div>
          </div>
          <div className="card p-15 mb-1">
            <h5>Return</h5>
            <div className="font-medium">Maher Terminal</div>
            <div>1210 CORBIN ST, ELIZABETH, NJ 07201, USA</div>
          </div>
          <div className="card p-15 mb-1">
            <h5>Summary</h5>
            <div className="d-flex align-items-center justify-content-between mb-10">
              <span className="text-muted">Created by</span>
              <span>Cameron Williamson</span>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-10">
              <span className="text-muted">Container #</span>
              <span>SSF223788</span>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-10">
              <span className="text-muted">BOL #</span>
              <span>33422716</span>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-10">
              <span className="text-muted">Chassis #</span>
              <span>ODBC956</span>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-10">
              <span className="text-muted">SSL</span>
              <span>OOCL</span>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-10">
              <span className="text-muted">Size</span>
              <span>20'</span>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-10">
              <span className="text-muted">Type</span>
              <span>ST</span>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-10">
              <span className="text-muted">Reference</span>
              <span>2340009</span>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-10">
              <span className="text-muted">Total Miles</span>
              <span>2804.33</span>
            </div>
          </div>
        </div>
        <div className="col-md">
          <div className="card mb-0">
            <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom">
              
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    isLoadTabActive === 1 ? "active" : ""
                  }`}
                  href="javascript:void(0)"
                  onClick={() => handleLoadTabActive(1)}
                >
                  <IconInfoFocus />
                  <div className="nav-link__text">Load Info</div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    isLoadTabActive === 2 ? "active" : ""
                  }`}
                  href="javascript:void(0)"
                  onClick={() => handleLoadTabActive(2)}
                >
                  <IconBilling />
                  <div className="nav-link__text">Billing</div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    isLoadTabActive === 3 ? "active" : ""
                  }`}
                  href="javascript:void(0)"
                  onClick={() => handleLoadTabActive(3)}
                >
                  <IconDocument />
                  <div className="nav-link__text">Documents</div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    isLoadTabActive === 4 ? "active" : ""
                  }`}
                  href="javascript:void(0)"
                  onClick={() => handleLoadTabActive(4)}
                >
                  <IconPayment />
                  <div className="nav-link__text">Payment</div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    isLoadTabActive === 5 ? "active" : ""
                  }`}
                  href="javascript:void(0)"
                  onClick={() => handleLoadTabActive(5)}
                >
                  <IconStatus />
                  <div className="nav-link__text">Routing</div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    isLoadTabActive === 6 ? "active" : ""
                  }`}
                  href="javascript:void(0)"
                  onClick={() => handleLoadTabActive(6)}
                >
                  <IconTruck />
                  <div className="nav-link__text">Driver Pay</div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    isLoadTabActive === 7 ? "active" : ""
                  }`}
                  href="javascript:void(0)"
                  onClick={() => handleLoadTabActive(7)}
                >
                  <IconTrack />
                  <div className="nav-link__text">Tracking</div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    isLoadTabActive === 8 ? "active" : ""
                  }`}
                  href="javascript:void(0)"
                  onClick={() => handleLoadTabActive(8)}
                >
                  <IconMessage />
                  <div className="nav-link__text">Messaging</div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    isLoadTabActive === 9 ? "active" : ""
                  }`}
                  href="javascript:void(0)"
                  onClick={() => handleLoadTabActive(9)}
                >
                  <IconSearchData />
                  <div className="nav-link__text">Audit</div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    isLoadTabActive === 10 ? "active" : ""
                  }`}
                  href="javascript:void(0)"
                  onClick={() => handleLoadTabActive(10)}
                >
                  <IconNotes />
                  <div className="nav-link__text">Notes</div>
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-loads">
            {isLoadTabActive === 1 && <LoadInfoVertical />}
            {isLoadTabActive === 2 && <Billing />}
            {isLoadTabActive === 3 && <Documents />}
            {isLoadTabActive === 4 && <Payments />}
            {isLoadTabActive === 5 && <Routing />}
            {isLoadTabActive === 6 && <DriverPay />}
            {isLoadTabActive === 7 && <Tracking />}
            {isLoadTabActive === 8 && <Messaging />}
            {isLoadTabActive === 9 && <Audit />}
            {isLoadTabActive === 10 && <Notes />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadPage;
