import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomSelect from "../../../../../common/CustomSelect";

import { IconMinus, IconPlus } from "../../../../../common/icons";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const EditFreeDay = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;

  return (
    <Modal size="sm" show={showModal} centered animation={false}>
      <Modal.Header>
        <Modal.Title>Edit Free Day</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="form-wrapper">
          <form>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label text-muted">Owner</label>
                <div className="font-14 font-weight-500">Shandong International Transportation Corporation</div>
              </div>
              <div className="form-group col-md-12">
                <label className="col-form-label text-muted">Type</label>
                <div className="font-14 font-weight-500">Open Sides</div>
              </div>
            </div>
            <hr className="my-2" />
            <div className="form-row">
              <div className="form-group col-md-11">
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label className="col-form-label"> <span className="text-danger">*</span>From Day</label>
                    <input className="form-control" placeholder="4" />
                  </div>
                  <div className="form-group col-md-4">
                    <label className="col-form-label"> To Day</label>
                    <input className="form-control" placeholder="5" />
                  </div>
                  <div className="form-group col-md-4">
                    <label className="col-form-label"> <span className="text-danger">*</span>Amount</label>
                    <input className="form-control" placeholder="$ 560.00" />
                  </div>
                </div>
              </div>
              <div className="form-group col-md-1 d-flex align-items-center justify-content-center">
                  <div className="btn-wrapper mt-1">
                    <button className="btn btn-xs btn-circle bg-soft-danger mt-2">
                        <IconMinus className="text-danger" />
                    </button>
                  </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-11">
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label className="col-form-label"> <span className="text-danger">*</span>From Day</label>
                    <input className="form-control" placeholder="6" />
                  </div>
                  <div className="form-group col-md-4">
                    <label className="col-form-label"> To Day</label>
                    <input className="form-control" placeholder="-" />
                  </div>
                  <div className="form-group col-md-4">
                    <label className="col-form-label"> <span className="text-danger">*</span>Amount</label>
                    <input className="form-control" placeholder="$ 1000.00" />
                  </div>
                </div>
              </div>
              <div className="form-group col-md-1 d-flex align-items-center justify-content-center">
                  <div className="btn-wrapper mt-1">
                    <button className="btn btn-xs btn-circle bg-soft-danger mt-2">
                        <IconMinus className="text-danger" />
                    </button>
                  </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-11">
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label className="col-form-label"> <span className="text-danger">*</span>From Day</label>
                    <input className="form-control" placeholder="-" />
                  </div>
                  <div className="form-group col-md-4">
                    <label className="col-form-label"> To Day</label>
                    <input className="form-control" placeholder="-" />
                  </div>
                  <div className="form-group col-md-4">
                    <label className="col-form-label"> <span className="text-danger">*</span>Amount</label>
                    <input className="form-control" placeholder="$ 0.00" />
                  </div>
                </div>
              </div>
              <div className="form-group col-md-1 d-flex align-items-center justify-content-center">
                  <div className="btn-wrapper mt-1">
                    <button className="btn btn-xs btn-circle bg-soft-danger mt-2">
                        <IconMinus className="text-danger" />
                    </button>
                  </div>
              </div>
            </div>
            <div>
              <a href="javasript:void(0)" className="d-flex align-items-center">
                <IconPlus className="mr-2" /> Add Tier
              </a>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={onSubmit}>
          Save Changes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditFreeDay;
