import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { IconUpload } from '../../../common/icons';
import UploadDocument from '../../../common/uploadDocument';
import UploadedDocuments from '../../../common/uploadedDocuments';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];
class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadTabActive: false,
      showUploadModal: false,
      isMoreOptions: false,
    };
  }
  handleIsMoreOptions = (event) => {
    this.setState({ isMoreOptions: !this.state.isMoreOptions });
  };
  handleShowUploadModal = (event) => {
    this.setState({ showUploadModal: true });
  };
  handleHideUploadModal = (event) => {
    this.setState({ showUploadModal: false });
  };
  handleLoadTabActive = (i) => {
    this.setState({
      isLoadTabActive: this.state.isLoadTabActive === i ? null : i,
    });
  };
  render() {
    return (
      <React.Fragment>
        <div className="tab">
          <div className="card p-15 mb-10 d-flex flex-row align-items-center justify-content-between">
            <ul className="nav nav-compact" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="#all-drivers"
                  role="tab"
                >
                  Main Customer
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#dispatched"
                  role="tab"
                >
                  Sub Customer
                </a>
              </li>
            </ul>
            <button
              className="ml-auto btn btn-primary"
              onClick={this.handleShowUploadModal}
            >
              <IconUpload className="mr-2" />
              Upload Document
            </button>
          </div>

          <UploadedDocuments />
        </div>
        <Modal show={this.state.showUploadModal} centered animation={false}>
          <Modal.Header>
            <Modal.Title>Upload Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UploadDocument />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={this.handleHideUploadModal}
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              disabled
              onClick={this.handleHideUploadModal}
            >
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Documents;
