import React from 'react';
import { Icondots } from '../../../common/icons';

const customerLists = [
  {
    id: 1,
    companyName: 'Sinotrans',
    avatarName: 'S',
    ownerName: 'Bessie Cooper',
    contact: '(229) 555-0109',
    price: '$202.87',
  },
  {
    id: 2,
    companyName: 'Yang Ming Marine Transport Corporation',
    avatarName: 'Y',
    ownerName: 'Courtney Henry',
    contact: '(229) 555-0109',
    price: '$202.87',
  },
  {
    id: 3,
    companyName: 'IRISL Group',
    avatarName: 'I',
    ownerName: 'Courtney Henry',
    contact: '(229) 555-0109',
    price: '$202.87',
  },
  {
    id: 4,
    companyName: 'Maersk Line',
    avatarName: 'M',
    ownerName: 'Courtney Henry',
    contact: '(229) 555-0109',
    price: '$202.87',
  },
  {
    id: 5,
    companyName: 'Regional Container Lines',
    avatarName: 'R',
    ownerName: 'Courtney Henry',
    contact: '(229) 555-0109',
    price: '$202.87',
  },
];

const CustomerList = () => {
  return (
    <div className="card mb-20 p-20">
      <div className="d-flex justify-content-between align-items-center pb-15">
        <div className="d-flex flex-column">
          <div className="font-16 font-weight-500 mb-1">Top 5 Customers</div>
          <div className="d-flex flex-row ">
            <div className=" badge badge-sm badge-accent-100 px-1 mr-1 rounded-10">
              +5.8%
            </div>
            <div className="text-muted">Since past week</div>
          </div>
        </div>
      </div>
      {customerLists.map((customerList) => (
        <div className="d-flex justify-content-between align-items-center pb-20">
          <div className="d-flex">
            <div className="mr-10 align-self-center">
              <div className="avatar-circle ">
                <div className="font-12"> {customerList.avatarName}</div>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="font-12 font-weight-500">
                {customerList.companyName}
              </div>
              <div className="text-muted">{customerList.ownerName} <span className="mr-10 ml-10">•</span> <span>(229) 555-0109</span></div>
            </div>
          </div>
          <div className="d-flex ">
            <div className="text-gray-900 font-weight-500 font-14 mr-2">
              {customerList.price}
            </div>
            <div className="ml-auto">
              <Icondots className="text-muted" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CustomerList;
