import React from 'react';
import Organizationlogo from './OrganizationLogo';
import PlansList from './PlansList';
import Testimonial from './Testimonial';
const SubscriptionPlan = () => {
  return (
    <div className="subscription-plan">
      <PlansList />
      <div className="title font-14 mb-20 font-weight-500 d-flex justify-content-center align-items-center">
        Loved by 100+ organizations
      </div>
      <Organizationlogo />
      {/*//uncomment testimonial component for testimonial text*/}
      {/* <Testimonial /> */}
    </div>
  );
};
export default SubscriptionPlan;
