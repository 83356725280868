import React from "react";
import CustomSelect from "../../../common/CustomSelect";
import { IconFilter } from "../../../common/icons";

const TrackOsEmptyReturnFilterRow = ({}) => {
  return (
    <React.Fragment>
      <div className="filter-row d-flex align-items-center">
        <div className="d-flex align-items-center">
          <div className="app-search header-search">
            <span className="search-icon"> </span>
            <div className="position-relative">
              <input
                type="search"
                className="form-control input-search-left"
                placeholder="Search..."
                value=""
              />
            </div>
          </div>
          <CustomSelect
            name={"name"}
            className="w-150 mr-1"
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select Date.."
          />
          <CustomSelect
            name={"name"}
            className="w-150 mr-1"
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select Terminal.."
          />
        </div>

        <div className="ml-auto d-flex">
          <button className="btn btn-outline-light ">
            <IconFilter className="mr-2" />
            Filter
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TrackOsEmptyReturnFilterRow;
