import React, { useState, useEffect } from "react";
import Group from "../../components/customerService/driverMessaging/GroupMessageList";
import QuickAdd from "../../components/quickAdd";
import ChatBox from "../chatComponent/ChatBox";
import CustomerMsgList from "../chatComponent/CustomerMsgList";
import GroupMsgList from "../chatComponent/GroupMsgList";
import MessageList from "../chatComponent/MessageList";
import Chatpop from "../chatpop";
import {
  IconAngleArrowLeft,
  IconBell,
  IconCart,
  IconChatFill,
  IconHomeCheck,
  IconPlus,
  IconSteering,
  IconTruck,
  IconUsers,
} from "../icons";
import SearchPopDrop from "../search";
import { useRouteMatch } from "react-router-dom";

const Header = () => {
  const [searchValue, setSearchValue] = useState("");
  const [quickAddOptions, setQuickAddOptions] = useState(false);
  const [isLoadTabActive, setisLoadTabActive] = useState(1);
  const [isChatPopDrop, setIsChatPopDrop] = useState(false);
  const [isChatBoxOpen, setIsChatBoxOpen] = useState(0);

  const displayChatBox = (i) => {
    // setIsChatBoxOpen(true);
    setIsChatBoxOpen(isChatBoxOpen === i ? null : i);
    setIsChatPopDrop(false);
  };

  const hideChatBox = () => {
    setIsChatBoxOpen(false);
  };

  const handleLoadTabActive = (i) => {
    setisLoadTabActive(isLoadTabActive === i ? null : i);
  };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const showQuickAddOptions = () => {
    setQuickAddOptions(!quickAddOptions);
  };

  const showChatPopdrop = () => {
    setIsChatPopDrop(!isChatPopDrop);
  };
  useEffect(() => {
    const classExists = document.querySelector(".subheader") !== null;
    if (!classExists) return;
    document.body.className = "has-subheader";
  });

  let match = useRouteMatch("/trackos/live-share");

  return (
    // <header className= {`topbar ${match ? "d-none" : ""} `}>
      <header className="topbar">
        <nav className="navbar top-navbar navbar-expand-md navbar-light">
          <div className="d-flex align-items-center">
            <a
              href="javascript:void();"
              className="font-18 ml-3 d-lg-none"
              style={{ color: "#555b6d" }}
              href="javascript:void(0)"
              data-sidebartype="mini-sidebar"
            >
              <i className="uil uil-bars"></i>
            </a>
            {/* Search Input*/}
            <div
              className={`app-search header-search position-relative ${
                searchValue ? "bg-gray-50 py-15" : ""
              }`}
            >
              <span className={`search-icon ${searchValue ? "ml-2" : ""}`}></span>
              <div className="position-relative">
                <input
                  type={"search"}
                  className="form-control input-search-left"
                  placeholder="Search PortPro.."
                  value={searchValue}
                  onChange={handleSearch}
                  style={{ width: "454px" }}
                />
              </div>
            </div>
            {/* Search PopDrop */}
            {searchValue && <SearchPopDrop />}
          </div>

          <div
            className="navbar-collapse collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav float-right d-flex align-items-center">
              {/* Quick Add Options */}
              <li
                data-tip
                data-for="QuickAdd"
                className="nav-item position-relative"
              >
                <a
                  href="javascript:void(0)"
                  className="nav-link d-flex align-items-center"
                  onClick={showQuickAddOptions}
                >
                  <button className="btn btn-success btn-circle btn-sm">
                    <IconPlus />
                  </button>
                </a>
                {quickAddOptions === true && <QuickAdd />}
              </li>

              {/* Chat */}
              <li
                className={`nav-item ${
                  isChatPopDrop === true ? "card--shadow-5" : ""
                }`}
              >
                <a
                  data-tip
                  data-for="Chat"
                  href="javascript:void(0)"
                  id="floatingChatsSideBarHandler"
                  className={`nav-link position-relative ${
                    isChatPopDrop === true ? "text-primary" : "text-dark"
                  } `}
                  onClick={showChatPopdrop}
                >
                  <IconChatFill />
                  <span className="notification-icon-header">2</span>
                </a>
              </li>

              {/* Chat Dropdown */}
              {isChatPopDrop === true && (
                <div
                  className="card card--shadow-5 popdrop popdrop--right popdrop--bottom-no-space rounded-0 animated"
                  style={{ width: "400px" }}
                >
                  {/* Chat Tabs */}
                  <ul className="nav nav-tabs nav-tabs-custom nav-justified align-items-center nav-border-bottom">
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isLoadTabActive === 1 ? "active" : ""
                        }`}
                        href="javascript:void(0)"
                        onClick={() => handleLoadTabActive(1)}
                      >
                        Driver
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isLoadTabActive === 2 ? "active" : ""
                        }`}
                        href="javascript:void(0)"
                        onClick={() => handleLoadTabActive(2)}
                      >
                        Employee
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isLoadTabActive === 3 ? "active" : ""
                        }`}
                        href="javascript:void(0)"
                        onClick={() => handleLoadTabActive(3)}
                      >
                        Group
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          isLoadTabActive === 4 ? "active" : ""
                        }`}
                        href="javascript:void(0)"
                        onClick={() => handleLoadTabActive(4)}
                      >
                        Customer
                      </a>
                    </li>
                  </ul>

                  <div className="nav-tab-content">
                    {/* Driver Message List */}
                    {isLoadTabActive == 1 && (
                      <div className="p-10">
                        <div className="app-search bg-gray-50 rounded-lg mb-10">
                          <input
                            type="text"
                            className="input-search-left form-control py-2"
                            placeholder="Search Driver.."
                          />
                          <span className="search-icon ml-1"></span>
                        </div>
                        <MessageList messageClicked={() => displayChatBox(1)} />
                      </div>
                    )}
                    {/* Employee Message List */}
                    {isLoadTabActive == 2 && (
                      <div className="p-10">
                        <div className="app-search bg-gray-50 rounded-lg mb-10">
                          <input
                            type="text"
                            className="input-search-left form-control py-2"
                            placeholder="Search Employee.."
                          />
                          <span className="search-icon ml-1"></span>
                        </div>
                        <MessageList messageClicked={() => displayChatBox(1)} />
                      </div>
                    )}
                    {/* Group Message List */}
                    {isLoadTabActive == 3 && (
                      <GroupMsgList messageClicked={() => displayChatBox(3)} />
                    )}
                    {/* Driver Message List */}
                    {isLoadTabActive == 4 && (
                      <div className="p-10">
                        <div className="app-search bg-gray-50 rounded-lg mb-10">
                          <input
                            type="text"
                            className="input-search-left form-control py-2"
                            placeholder="Search Customer.."
                          />
                          <span className="search-icon ml-1"></span>
                        </div>
                        <CustomerMsgList
                          messageClicked={() => displayChatBox(4)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* Chatbox - Drivers and Employee */}
              {isChatBoxOpen == 1 && <ChatBox closeChatBox={hideChatBox} />}

              {/* Chatbox - Group */}
              {isChatBoxOpen == 3 && (
                <ChatBox category="group" closeChatBox={hideChatBox} />
              )}

              {/* Chatbox - Customers */}
              {isChatBoxOpen == 4 && (
                <ChatBox category="customer" closeChatBox={hideChatBox} />
              )}

              <li className="nav-item">
                <a
                  href="javascript:void(0)"
                  data-tip
                  data-for="Email"
                  to="/emails"
                  id="floatingChatsSideBarHandler"
                  className="nav-link text-gray-300"
                  style={{ position: "relative" }}
                >
                  <IconBell />
                </a>
              </li>

              <li className="nav-item nav-item-image">
                <a className="d-flex align-items-center nav-link" to="/profile">
                  <div className="avatar-circle">
                    <img
                      src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                      className="avatar-circle__image rounded-circle"
                      alt=""
                      width="30"
                    />
                  </div>
                  <span className="ml-2">
                    Hi,
                    <span className="ml-1 font-medium text-dark">
                      Alex Mendes
                    </span>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
  );
};

export default Header;
