import React, { useState } from 'react';
import Cards from '../../common-elements/Cards';

import StaticTableContent from '../customer/elements/StaticTableContent';
import SearchFilter from '../customer/elements/SearchFilter';
import Pagination from '../../common-elements/Pagination';
import OtherTabs from '../../common-elements/OtherTabs';
import {
  IconQuickBook,
  IconDownload,
  IconPlus,
} from '../../../../common/icons';
import AddCustomerModal from './elements/AddCustomerModal';
const cardItems = [
  {
    id: 1,
    count: 164,
    title: 'Customers ',
  },
  {
    id: 2,
    count: 79,
    title: 'Shipper/Port',
  },
  {
    id: 3,
    count: 163,
    title: 'Consignees',
  },
  {
    id: 4,
    count: 29,
    title: 'Chassis',
  },
];
const ProfileGroupCustomer = () => {
  const [CardActive, setCardActive] = useState(1);
  //Add Customer Modal
  const [CustomershowModal, setCustomerShowModal] = useState(false);
  const handleCustomerShowModal = (event) => {
    setCustomerShowModal(true);
  };

  const handleCustomerHideModal = (event) => {
    setCustomerShowModal(false);
  };
  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <div className="subheader">
          <div className="subheader__inner d-flex align-items-center py-0">
            <OtherTabs
              activeTab={3}
              button={
                <div className="d-flex">
                  <button className="mr-2 btn btn-outline-light ">
                    <IconDownload className="mr-2" />
                    Downlaod CVS
                  </button>
                  <button
                    className=" btn btn-success shadow"
                    onClick={handleCustomerShowModal}
                  >
                    <IconPlus className="mr-2" />
                    Add Group Customers
                  </button>
                </div>
              }
            />
          </div>
        </div>
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center justify-content-between">
              <h4 className="font-20 mb-0">435 Customers</h4>
              <Pagination />
            </div>
            {/* Cards Row */}
            <div className={`mb-15 form-row ${CardActive ? 'hasactive' : ''}`}>
              {cardItems.map(({ id, title, count }) => (
                <Cards
                  title={title}
                  count={count}
                  onCardClicked={() => setCardActive(id)}
                  isCardActive={CardActive === id}
                />
              ))}
            </div>
            <SearchFilter />
            <StaticTableContent />
          </div>
        </div>
      </div>
      {CustomershowModal && (
        <AddCustomerModal
          onCancel={handleCustomerHideModal}
          onSubmit={handleCustomerHideModal}
        />
      )}
    </React.Fragment>
  );
};

export default ProfileGroupCustomer;
