import React, { useState } from "react";
import SearchFilter from "./elements/SearchFilter";
import TopPage from "../common-elements/TopPage";
import StaticTableContent from "./elements/StaticTableContent";
import AccountPayableTabs from "../common-elements/AccountPayableTabs";
import { IconDownload, IconPlus } from "../../../common/icons";
import AddNewDriverPay from "./elements/AddNewDriverPay";

const DriverPay = () => {
  const [showAddModal, setShowAddModal] = useState(false);

  const handleShowAddModal = (event) => {
    setShowAddModal(true);
  };

  const handleHideAddModal = (event) => {
    setShowAddModal(false);
  };

  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <AccountPayableTabs
            activeTab={1}
            button={
              <>
                <button className="ml-auto btn btn-outline-light mr-2">
                  <IconDownload className="mr-3" />
                  Download CSV
                </button>

                <button
                  className="btn btn-success font-weight-500"
                  onClick={handleShowAddModal}
                >
                  <IconPlus className="mr-2" />
                  Add New Driver Pay
                </button>
              </>
            }
          />
        </div>
      </div>
      <div className="page-fluid">
        <TopPage />
        <SearchFilter />
        <StaticTableContent />
      </div>

      {showAddModal && (
        <AddNewDriverPay
          onCancel={handleHideAddModal}
          onSubmit={handleHideAddModal}
        />
      )}
    </div>
  );
};

export default DriverPay;
