import React, { useState } from "react";
import TabComponent from "../../../../common/tabComponent";

const tabItems = [
  {
    id: 1,
    title: 'Active',
    path: '/trackos/empty-return',
  },
  {
    id: 2,
    title: 'Archived',
    path: '/trackos/empty-return',
  },
  {
    id: 3,
    title: 'Empty History',
    path: '/trackos/empty-return-history',
  },
];

const EmptyReturnTab = ({ activeTab, buttons }) => {
    const [tabActive, setTabActive] = useState(activeTab);
    return (
        <div className="subheader">
            <div className="subheader__inner d-flex align-items-center py-0">
                <div className="d-flex flex-row align-items-center justify-content-between w-100">
                    <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                        {tabItems.map(({ id, title, path }) =>
                            <TabComponent
                                key={title}
                                title={title}
                                path={path}
                                onItemClicked={() => setTabActive(id)}
                                isActive={tabActive === id}
                            />
                        )}
                    </ul>
                    <div className="ml-auto">
                        {buttons}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmptyReturnTab;
