import React, { Component } from "react";
import Chatpop from "../../../common/chatpop";


const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
class Messaging extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelectCard: false,
    };
  }
  handleSelectCard = (i) => {
    this.setState({
      isSelectCard: this.state.isSelectCard === i ? null : i,
    });
  };
  render() {
    return (
      <React.Fragment>
        <Chatpop className="chatpop--full" />
      </React.Fragment>
    );
  }
}

export default Messaging;
