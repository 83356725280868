import React, { useState } from 'react';
import CustomSelect from '../../../../common/CustomSelect/index';

import {
  IconColumn,
  IconFilter,
  IconPen,
  IconCheckCircle,
  IconTrash,
  IconCancelCircle,
} from '../../../../common/icons/index';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const columns = [
  'Name of Expense',
  'Amount',
  'Recurrence Pattern',
  'Start date',
  'End Date',
  'Active/Deactive',
];

const data = [
  {
    id: 1,
    nameOfExpense: 'Base Price',
    amount: '$854.08',
    recurrencePattern: 'Monthly',
    startDate: 'October 30, 2017',
    endDate: 'March 23, 2013',
    active: 'Deactive',
  },
  {
    id: 2,
    nameOfExpense: 'Base Price',
    amount: '$854.08',
    recurrencePattern: 'Monthly',
    startDate: 'October 30, 2017',
    endDate: 'March 23, 2013',
    active: 'Enabled',
  },
  {
    id: 3,
    nameOfExpense: 'Base Price',
    amount: '$854.08',
    recurrencePattern: 'Monthly',
    startDate: 'October 30, 2017',
    endDate: 'March 23, 2013',
    active: 'Enabled',
  },
  {
    id: 4,
    nameOfExpense: 'Base Price',
    amount: '$854.08',
    recurrencePattern: 'Monthly',
    startDate: 'October 30, 2017',
    endDate: 'March 23, 2013',
    active: 'Enabled',
  },
  {
    id: 5,
    nameOfExpense: 'Base Price',
    amount: '$854.08',
    recurrencePattern: 'Monthly',
    startDate: 'October 30, 2017',
    endDate: 'March 23, 2013',
    active: 'Enabled',
  },
  {
    id: 6,
    nameOfExpense: 'Base Price',
    amount: '$854.08',
    recurrencePattern: 'Monthly',
    startDate: 'October 30, 2017',
    endDate: 'March 23, 2013',
    active: 'Enabled',
  },
];

const StaticTableContent = () => {
  const [isPopDrop, setIsPopDrop] = useState(false);

  const handlePopDrop = (event) => {
    setIsPopDrop(!isPopDrop);
  };
  return (
    <React.Fragment>
      {/* Filter Row */}
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>
        <div className="d-flex">
          <CustomSelect
            name={'name'}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Name of Charge"
            className="w-150"
          />
        </div>
        <div className="ml-auto d-flex">
          <button className="btn btn-outline-light mr-2">
            <IconFilter className="mr-2" />
            Filter
          </button>
          <div className="position-relative">
            <button className="btn btn-outline-light position-relative">
              <IconColumn />
            </button>
          </div>
        </div>
      </div>
      {/* Table Content */}
      <div
        className="table-responsive"
        style={{ height: 'calc(100vh - 248px)' }}
      >
        <table className="table table-card">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
              <th style={{ width: '2%' }}></th>
              <th style={{ width: '2%' }}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((datum) => {
              return (
                <tr key={datum.id}>
                  <td style={{ width: '40%' }}>{datum.nameOfExpense}</td>
                  <td>{datum.amount}</td>
                  <td>{datum.recurrencePattern}</td>
                  <td className="text-right">{datum.startDate}</td>
                  <td className="text-right">{datum.endDate}</td>
                  <td>
                    <div className="form-check form-switch form-switch-md">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitchsizelg"
                      />
                      <label className="mb-0 ml-2">Deactive</label>
                    </div>
                  </td>
                  <td>
                    <button className="btn btn-link">
                      <IconPen className="text-muted" />
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-link">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
