import React, { useState } from 'react';
import AddLoad from '../../../common/addLoad';
import DriverList from '../../../common/driverList';
import TabComponent from '../../../common/tabComponent';
import { CustomIconSaveOutline } from '../../../common/customIcons';
import {
  IconAngleArrowLeft,
  IconAngleArrowRight,
  IconCaretDown,
  IconPrinter,
  IconClone,
  IconTrash,
  IconSendEmail,
  IconPlus,
  IconSteering,
} from '../../../common/icons';

const tabItems = [
  {
    id: 1,
    title: 'Dashboard',
    path: '/customer-dashboard',
  },
  {
    id: 2,
    title: 'Loads',
    path: '/customerServices/loads',
  },
  {
    id: 3,
    title: 'Quotes',
    path: '/customerServices/quotes',
  },
  {
    id: 4,
    title: 'Yard Check',
    path: '/customerService/dropped-containers',
  },
  {
    id: 5,
    title: 'Tendered loads',
    path: '/customerServices/tender',
  },
  {
    id: 6,
    title: 'Load Calendar',
    path: '/customerService/calendar',
  },
  {
    id: 7,
    title: 'Bulk Uploads',
    path: '/customerService/bulk-uploads',
  },
  {
    id: 8,
    title: 'Messaging',
    path: '/customerService/customer-messaging',
  },
  {
    id: 9,
    title: 'Deleted Loads',
    path: '/customerService/deleted-loads',
  },
];

const CustomerTabs = ({ activeTab }) => {
  const [isSaveOptions, setisSaveOptions] = useState(false);

  const handleIsSaveOptions = (event) => {
    setisSaveOptions({ isSaveOptions });
  };

  const [tabActive, setTabActive] = useState(activeTab);

  const [isDriverList, setisDriverList] = useState(false);
  const [isTrackCard, setisTrackCard] = useState(false);

  const handleTrackCard = () => {
    setisTrackCard(!isTrackCard);
  };

  const handleShowDriverList = (event) => {
    document.body.classList.add('bdpopup-open');
    setisDriverList(true);
  };

  const handleHideDriverList = (event) => {
    document.body.classList.remove('bdpopup-open');
    setisDriverList(false);
  };

  //For Add Load Modal
  const [showAddLoadModal, setShowAddLoadModal] = useState(false);
  const handleShowAddLoadModal = (event) => {
    setShowAddLoadModal(true);
  };
  const handleHideAddLoadModal = (event) => {
    setShowAddLoadModal(false);
  };
  return (
    <React.Fragment>
      {/*  Sub Header Tabs*/}
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <div className="d-flex flex-row align-items-center">
            <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
              {tabItems.map(({ id, title, path }) => (
                <TabComponent
                  key={title}
                  title={title}
                  path={path}
                  onItemClicked={() => setTabActive(id)}
                  isActive={tabActive === id}
                />
              ))}
            </ul>
          </div>
          {tabActive == '2' && (
            <div className="ml-auto position-relative d-flex">
              <button className="btn btn-link mr-1">
                <IconPrinter />
              </button>
              <button className="btn btn-link mr-1">
                <IconClone />
              </button>
              <button className="btn btn-link mr-1">
                <IconTrash />
              </button>
              <button
                className="btn btn-outline-light mr-1"
                onClick={() => this.hideLoad()}
              >
                Cancel
              </button>
              <div className="position-relative mr-4">
                <button
                  className="btn btn-success"
                  onClick={handleIsSaveOptions}
                >
                  Save Changes
                  <IconCaretDown />
                </button>
                {isSaveOptions && (
                  <div className="card card--shadow-5 popdrop popdrop--right mb-0 p-3 animated">
                    <a
                      href=""
                      className="text-gray-900 rounded-3 d-flex align-items-center py-2 px-3 animated hover-bg-success-100"
                    >
                      <CustomIconSaveOutline className="mr-3" />
                      Save & Add to Customer Rates
                    </a>
                    <hr className="m-2" />
                    <a
                      href="javascript:void(0)"
                      className="text-gray-900 rounded-3 d-flex align-items-center py-2 px-3 animated hover-bg-success-100"
                    >
                      <IconSendEmail className="mr-3" />
                      Save & Send Rate Confirmation
                    </a>
                  </div>
                )}
              </div>
              <button className="btn btn-outline-light mr-2">
                <IconAngleArrowLeft />
              </button>
              <button className="btn btn-outline-light">
                <IconAngleArrowRight />
              </button>
            </div>
          )}
          {/* {[3].includes(tabActive) && (
            <button className="btn btn-success font-weight-500">
              <IconPlus className="mr-2" />
              Add New Load
            </button>
          )} */}

          {/* {tabActive === 2 ? (
            <div className="ml-auto">
              <button
                className="btn btn-outline-light mr-2"
                onClick={handleShowDriverList}
              >
                <IconSteering className="mr-2" />
                Hello
              </button>
              <button
                className="btn btn-success"
                onClick={handleShowAddLoadModal}
              >
                <IconPlus className="mr-2" />
                Add New Load
              </button>
            </div>
          ) : (
            <div className="ml-auto">
              <button
                className="btn btn-outline-light mr-2"
                onClick={handleShowDriverList}
              >
                <IconSteering className="mr-2" />
                Drivers
              </button>
            </div>
          )} */}
        </div>
      </div>
      {isDriverList && <DriverList hideDriverList={handleHideDriverList} />}
      {showAddLoadModal && (
        <AddLoad
          hideAddModal={handleHideAddLoadModal}
          showAddModal={handleShowAddLoadModal}
        />
      )}
    </React.Fragment>
  );
};

export default CustomerTabs;
