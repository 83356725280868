import React, { useState } from 'react';
import ColumnFilter from '../../../../common/columnFilter';
import FilterPop from '../../../../common/filterPop';
import { IconColumn, IconFilter } from '../../../../common/icons';

const StreetTurnsFilterRow = (props) => {
  //Column
  const [ShowPopDrop, setShowPopDrop] = useState(false);
  const handlePopDrop = (event) => {
    setShowPopDrop(!ShowPopDrop);
  };

  //Filter
  const [ShowFilter, setShowFilter] = useState(false);
  const handleShowFilter = (event) => {
    setShowFilter(true);
  };
  const handleHideFilter = (event) => {
    setShowFilter(false);
  };

  return (
    <React.Fragment>
      <div className=" d-flex align-items-center justify-content-between ">
        <div className="filter-row d-flex align-items-center mb-15">
          <div className="app-search header-search">
            <span className="search-icon"> </span>
            <div className="position-relative">
              <input
                type="search"
                className="form-control input-search-left"
                placeholder="Search..."
                value=""
                style={{ width: '210px' }}
              />
            </div>
          </div>
        </div>
        <div className="ml-auto">
          <button className="btn btn-white mr-2">
            <IconFilter className="mr-2" />
            Filter
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StreetTurnsFilterRow;
