import React, { useEffect, Component } from 'react';
import StaticTableContent from '../../customerService/customerDashboard/StaticTableContent';
import {
  IconAngleArrowLeft,
  IconAngleArrowRight,
  IconAngleArrowDown,
  IconCalendar,
  IconCaretDown,
  IconPrinter,
  IconClone,
  IconTrash,
  IconSendEmail,
  IconFilter,
} from '../../../common/icons';
import LoadPage from '../LoadPage';
import CustomerTabs from '../common-elements/CustomerTabs';
import Carousel from 'react-multi-carousel';
import Pagination from '../../../common/pagination';
import DispatcherFilterRow from '../common-elements/dispatcherFilterRow';
import { CustomIconSaveOutline } from '../../../common/customIcons';
// import DispatcherCards from "./common-elements/dispatcherCards";
import PickUpTable from './PickUpTable';

const tabItems = [
  {
    id: 1,
    title: 'APM: 1',
  },
  {
    id: 2,
    title: 'APM: 2',
  },
  {
    id: 3,
    title: 'APM: 3',
  },
  {
    id: 4,
    title: 'Everport: 1',
  },
  {
    id: 5,
    title: 'Everport: 2',
  },
  {
    id: 6,
    title: 'Everport: 3',
  },
];

class CustomerDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTabActive: false,
      isCardActive: false,
      isPopupOpen: false,
      openPopup: false,
      hidePopup: false,
      issubCardItem: false,
      openLoad: false,
      isPopDrop: false,
      isTrackCard: false,
      isSaveOptions: false,
      isFilterTabActive: false,
      isCardSubItemActive: false,
    };
  }

  handleIsCardSubItemActive = (e, i) => {
    e.stopPropagation();
    this.setState({
      isCardSubItemActive: this.state.isCardSubItemActive === i ? null : i,
    });
  };

  handleIsFilterTab = (i) => {
    this.setState({
      isFilterTabActive: this.state.isFilterTabActive === i ? null : i,
    });
  };

  handleIsPopDrop = (event) => {
    this.setState({ isPopDrop: !this.state.isPopDrop });
  };
  openLoad = (event) => {
    document.body.classList.add('has-subheader');
    this.setState({ openLoad: true });
  };

  hideLoad = (event) => {
    document.body.classList.remove('has-subheader');
    this.setState({ openLoad: false });
  };

  openPopup = (event) => {
    document.body.classList.add('bdpopup-open');
    this.setState({ isPopupOpen: true });
  };

  hidePopup = (event) => {
    document.body.classList.remove('bdpopup-open');
    this.setState({ isPopupOpen: false });
  };

  handleTabActive = (e, i) => {
    this.setState({
      isTabActive: this.state.isTabActive === i ? null : i,
    });
  };
  handleCardActive = (i) => {
    this.setState({
      isCardActive: this.state.isCardActive === i ? null : i,
    });
  };
  // handleCardActive = (e, i) => {
  //   e.stopPropagation();
  //   this.setState({
  //     isCardActive: this.state.isCardActive === i ? null : i,
  //   });
  // };
  handleSubCardItem() {
    this.setState({ isSubCardItem: !this.state.isSubCardItem });
  }
  handleIsSaveOptions = (event) => {
    this.setState({ isSaveOptions: !this.state.isSaveOptions });
  };

  render() {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 12,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 8,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 6,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
      },
    };

    const CustomRightArrow = ({ onClick, ...rest }) => {
      const {
        onMove,
        carouselState: { currentSlide, deviceType },
      } = rest;
      return (
        <button
          class="react-multiple-carousel__arrow react-multiple-carousel__arrow--right custom-arrow"
          onClick={() => onClick()}
        >
          <IconAngleArrowRight className="text-white" />
        </button>
      );
    };

    const CustomLeftArrow = ({ onClick, ...rest }) => {
      const {
        onMove,
        carouselState: { currentSlide, deviceType },
      } = rest;
      return (
        <button
          class="react-multiple-carousel__arrow react-multiple-carousel__arrow--left custom-arrow"
          onClick={() => onClick()}
        >
          <IconAngleArrowLeft className="text-white" />
        </button>
      );
    };
    return (
      <React.Fragment>
        <div className="position-relative subheader-wrapper">
          {this.state.openLoad == true ? (
            <div className="subheader">
              <div className="subheader__inner d-flex">
                <div className="d-flex flex-row align-items-center">
                  <button
                    className="btn btn-outline-light"
                    onClick={() => this.hideLoad()}
                  >
                    <IconAngleArrowLeft />
                  </button>
                  <div className="d-flex ml-3 align-items-center">
                    <h5 className="mb-0">Truck #: UJSK8223432</h5>
                    <span className="badge badge-info ml-3">
                      Enroute to Delivery load
                    </span>
                  </div>
                </div>
                <div className="ml-auto position-relative d-flex">
                  <button className="btn btn-link mr-1">
                    <IconPrinter />
                  </button>
                  <button className="btn btn-link mr-1">
                    <IconClone />
                  </button>
                  <button className="btn btn-link mr-1">
                    <IconTrash />
                  </button>
                  <button
                    className="btn btn-outline-light mr-1"
                    onClick={() => this.hideLoad()}
                  >
                    Cancel
                  </button>
                  <div className="position-relative mr-4">
                    <button
                      className="btn btn-success"
                      onClick={this.handleIsSaveOptions}
                    >
                      Save Changes
                      <IconCaretDown />
                    </button>
                    {this.state.isSaveOptions && (
                      <div className="card card--shadow-5 popdrop popdrop--right mb-0 p-3 animated">
                        <a
                          href=""
                          className="text-gray-900 rounded-3 d-flex align-items-center py-2 px-3 animated hover-bg-success-100"
                        >
                          <CustomIconSaveOutline className="mr-3" />
                          Save & Add to Customer Rates
                        </a>
                        <hr className="m-2" />
                        <a
                          href="javascript:void(0)"
                          className="text-gray-900 rounded-3 d-flex align-items-center py-2 px-3 animated hover-bg-success-100"
                        >
                          <IconSendEmail className="mr-3" />
                          Save & Send Rate Confirmation
                        </a>
                      </div>
                    )}
                  </div>
                  <button className="btn btn-outline-light mr-2">
                    <IconAngleArrowLeft />
                  </button>
                  <button className="btn btn-outline-light">
                    <IconAngleArrowRight />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <CustomerTabs activeTab={1} />
          )}

          {this.state.openLoad == false ? (
            <div className="page-fluid">
              <div className="top-page">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-column">
                      <h4 className="font-20 mb-0">Pick Up Apts</h4>
                      <p className="text-muted">Prev. Data</p>
                    </div>
                  </div>
                  <div className="filter">
                    <button className="btn btn-white ">
                      <IconFilter className="ml-auto mr-10" />
                      Filter
                    </button>
                  </div>
                </div>

                <div
                  className={`form-carousel my-2 ${
                    this.state.isCardActive ? 'hasactive' : ''
                  }`}
                >
                  <Carousel
                    containerClass="carousel-container w-100"
                    responsive={responsive}
                    infinite={true}
                    className="custom-card-carousel"
                    customRightArrow={<CustomRightArrow />}
                    customLeftArrow={<CustomLeftArrow />}
                  >
                    <div className="slider-item  h-100 ">
                      <div
                        className={`ncard d-flex flex-column justify-content-between ${
                          this.state.isCardActive === 1 ? 'active' : ''
                        }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleCardActive(1)}
                      >
                        <div className="d-flex align-items-center mb-10">
                          <div
                            className={`mr-1 sbox sbox--default px-2 h-100 ${
                              this.state.isCardActive === 1
                                ? 'sbox--active'
                                : ''
                            }`}
                          >
                            54
                          </div>
                          <div className="ml-2">
                            <h6 className=" mb-0">Unifeeder</h6>
                          </div>
                          <div className="ml-auto">
                            <IconAngleArrowDown />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slider-item  h-100">
                      <div
                        className={`ncard d-flex flex-column justify-content-between ${
                          this.state.isCardActive === 2 ? 'active' : ''
                        }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleCardActive(2)}
                      >
                        <div className="d-flex align-items-center mb-10">
                          <div
                            className={`mr-1 sbox sbox--default px-2 h-100 ${
                              this.state.isCardActive === 2
                                ? 'sbox--active'
                                : ''
                            }`}
                          >
                            35
                          </div>
                          <div className="ml-2">
                            <h6 className=" mb-0">Arkas Container Transport</h6>
                          </div>
                          <div className="ml-auto">
                            <IconAngleArrowDown />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slider-item  h-100">
                      <div
                        className={`ncard d-flex flex-column justify-content-between ${
                          this.state.isCardActive === 3 ? 'active' : ''
                        }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleCardActive(3)}
                      >
                        <div className="d-flex align-items-center mb-10">
                          <div
                            className={`mr-1 sbox sbox--default px-2 h-100 ${
                              this.state.isCardActive === 3
                                ? 'sbox--active'
                                : ''
                            }`}
                          >
                            34
                          </div>
                          <div className="ml-2">
                            <h6 className=" mb-0">Maersk Line</h6>
                          </div>
                          <div className="ml-auto">
                            <IconAngleArrowDown />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slider-item  h-100">
                      <div
                        className={`ncard d-flex flex-column justify-content-between ${
                          this.state.isCardActive === 4 ? 'active' : ''
                        }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleCardActive(4)}
                      >
                        <div className="d-flex align-items-center mb-10">
                          <div
                            className={`mr-1 sbox sbox--default px-2 h-100 ${
                              this.state.isCardActive === 4
                                ? 'sbox--active'
                                : ''
                            }`}
                          >
                            32
                          </div>
                          <div className="ml-2">
                            <h6 className=" mb-0">COSCO</h6>
                          </div>
                          <div className="ml-auto">
                            <IconAngleArrowDown />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slider-item  h-100">
                      <div
                        className={`ncard d-flex flex-column justify-content-between ${
                          this.state.isCardActive === 5 ? 'active' : ''
                        }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleCardActive(5)}
                      >
                        <div className="d-flex align-items-center mb-10">
                          <div
                            className={`mr-1 sbox sbox--default px-2 h-100 ${
                              this.state.isCardActive === 5
                                ? 'sbox--active'
                                : ''
                            }`}
                          >
                            32
                          </div>
                          <div className="ml-2">
                            <h6 className=" mb-0">Regional Container Lines</h6>
                          </div>
                          <div className="ml-auto">
                            <IconAngleArrowDown />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slider-item  h-100">
                      <div
                        className={`ncard d-flex flex-column justify-content-between ${
                          this.state.isCardActive === 6 ? 'active' : ''
                        }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleCardActive(6)}
                      >
                        <div className="d-flex align-items-center mb-10">
                          <div
                            className={`mr-1 sbox sbox--default px-2 h-100 ${
                              this.state.isCardActive === 6
                                ? 'sbox--active'
                                : ''
                            }`}
                          >
                            32
                          </div>
                          <div className="ml-2">
                            <h6 className=" mb-0">Regional Container Lines</h6>
                          </div>
                          <div className="ml-auto">
                            <IconAngleArrowDown />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slider-item  h-100">
                      <div
                        className={`ncard d-flex flex-column justify-content-between ${
                          this.state.isCardActive === 7 ? 'active' : ''
                        }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleCardActive(7)}
                      >
                        <div className="d-flex align-items-center mb-10">
                          <div
                            className={`mr-1 sbox sbox--default px-2 h-100 ${
                              this.state.isCardActive === 7
                                ? 'sbox--active'
                                : ''
                            }`}
                          >
                            31
                          </div>
                          <div className="ml-2">
                            <h6 className=" mb-0">Regional Container Lines</h6>
                          </div>
                          <div className="ml-auto">
                            <IconAngleArrowDown />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slider-item  h-100">
                      <div
                        className={`ncard d-flex flex-column justify-content-between ${
                          this.state.isCardActive === 8 ? 'active' : ''
                        }`}
                        href="javascript:void(0)"
                        onClick={() => this.handleCardActive(8)}
                      >
                        <div className="d-flex align-items-center mb-10">
                          <div
                            className={`mr-1 sbox sbox--default px-2 h-100 ${
                              this.state.isCardActive === 8
                                ? 'sbox--active'
                                : ''
                            }`}
                          >
                            54
                          </div>
                          <div className="ml-2">
                            <h6 className=" mb-0">Regional Container Lines</h6>
                          </div>
                          <div className="ml-auto">
                            <IconAngleArrowDown />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel>
                </div>
                <PickUpTable />
                {/* Filter Row */}
                <DispatcherFilterRow />
              </div>
              <div
                className="table-responsive"
                style={{ height: 'calc(100vh - 244px)' }}
              >
                <StaticTableContent
                  handleLoadOpen={() => {
                    this.setState({ openLoad: true });
                  }}
                />
              </div>
            </div>
          ) : (
            <LoadPage />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const DispatcherNewFunction = () => {
  useEffect(() => {
    document.body.className = 'has-subheader';
    return () => {
      document.body.className = '';
    };
  });

  return <CustomerDashboard />;
};

export default DispatcherNewFunction;
