import React, { useState } from "react";
import {
  IconCalendar,
} from "../../../../common/icons/index";
import { Modal } from "react-bootstrap";
import CustomSelect from "../../../../common/CustomSelect";
import UploadDocument from "../../../../common/uploadDocument";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const AddNewChassis = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;

  return (
    <React.Fragment>
      <Modal show={showModal} centered animation={false} size="xl">
        <Modal.Header>
          <Modal.Title>Add New Chassis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-wrapper">
            <form>
              <div className="row mb-10">
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Chassis #
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Equipment ID"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Chassis type
                  </label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select"
                    className="w-100"
                  />
                </div>

                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Chassis Size
                  </label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select"
                    className="w-100"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Chassis owner
                  </label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select"
                    className="w-100"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Year
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter AID"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Make
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Make"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Model
                  </label>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Model"
                    />
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> AID
                  </label>
                  <div className="input-wrapper w-150">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Select Date"
                    />
                    <div className="input-icon">
                      <IconCalendar />
                    </div>
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> ITD
                  </label>
                  <div className="input-wrapper w-150">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Select Date"
                    />
                    <div className="input-icon">
                      <IconCalendar />
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> License Plate number
                  </label>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter License Plate #"
                    />
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> License plate #
                  </label>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter License Plate #"
                    />
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> VIN
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter VIN"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Registration
                    expiration
                  </label>
                  <div className="input-wrapper w-150">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Select Date"
                    />
                    <div className="input-icon">
                      <IconCalendar />
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Inspection
                  </label>
                  <div className="input-wrapper w-150">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Select Date"
                    />
                    <div className="input-icon">
                      <IconCalendar />
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Insurance
                  </label>
                  <div className="input-wrapper w-150">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Select Date"
                    />
                    <div className="input-icon">
                      <IconCalendar />
                    </div>
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> GPS #
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter GPS #"
                  />
                </div>

                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Preventative
                    maintenance
                  </label>
                  <div className="input-wrapper w-150">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Select Date"
                    />
                    <div className="input-icon">
                      <IconCalendar />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="col-form-label">Upload Document</label>
                <UploadDocument />
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" onClick={onCancel}>
            Cancel
          </button>
          <button className="btn btn-success" onClick={onSubmit}>
            Add Chassis
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default AddNewChassis;
