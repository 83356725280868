import React, { useState } from 'react';
import {
  IconInvoiceAlt,
  IconDelivery,
  IconGate,
  IconBundle,
  IconPrinter,
  IconDoumentAlt,
} from '../../../../common/icons/index';
import { Modal } from 'react-bootstrap';

const StaticTableContent = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (event) => {
    setShowModal(true);
  };

  const handleHideModal = (event) => {
    setShowModal(false);
  };

  return (
    <div
      className="table-responsive"
      style={{ height: 'calc(100vh - 148.8px)' }}
    >
      <table className=" table table-card table-card--ls">
        <thead>
          <tr>
            <th>#</th>
            <th>Load #</th>
            <th>Status</th>
            <th>Driver</th>
            <th>Move</th>
            <th>LFD</th>
            <th>Cut</th>
            <th>Customer</th>
            <th>Container</th>
            <th>Chassis</th>
            <th>Size</th>
            <th>Type</th>
            <th>SSL</th>
            <th>BOL/BKG</th>
            <th>PORT</th>
            <th>Consignee</th>
            <th style={{ width: 50 }}>Location</th>
            <th>RTN</th>
            <th className="col">APT</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge  badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm  badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm  badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm  badge-soft-green">
                  Pending
                </span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm  badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm  badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm  badge-soft-green">
                  Pending
                </span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className=" badge badge-sm  badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm  badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm  badge-soft-green">
                  Pending
                </span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm  badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm  badge-soft-green">
                  Pending
                </span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm  badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm  badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm  badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td width="200">Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td>1</td>
            <td className="text-primary font-weight-500">POP2_M100520</td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-soft-green">Pending</span>
              </div>
            </td>
            <td>Leslie Alexander</td>
            <td>
              <div className="">
                <span className="move badge badge-sm badge-light">Road</span>
              </div>
            </td>
            <td>-</td>
            <td>06/11</td>
            <td>ABC Distribution</td>
            <td>LABD58695869</td>
            <td>ODBC956</td>
            <td>40'</td>
            <td>
              <div className="">
                <span className=" badge badge-sm badge-light">ST</span>
              </div>
            </td>
            <td>Yang Ming</td>
            <td>LABD1231421515</td>
            <td>The New York Inquirer</td>
            <td>Sterling Cooper</td>
            <td>Clarksvillw, TN, United States</td>
            <td>The New York Inquirer</td>
            <td className="">-</td>
            <td className="">
              <div className="d-flex justify-content-end">
                <button className="btn btn-link" onClick={handleShowModal}>
                  <IconPrinter className="font-weight-500 text-dark" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {/* Print Modal */}
      <Modal show={showModal} centered animation={false} size="md">
        <Modal.Header>
          <Modal.Title>Choose doc type for Print</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-30 px-30">
          <div className="print-wrapper">
            <div className="form-row-sm">
              <div className="col">
                <div className="border border-gray p-20 ncard">
                  <div className="d-flex h-100 flex-column justify-content-center align-items-center">
                    <div className="">
                      <IconInvoiceAlt className="text-gray-700" />
                    </div>
                    <div className="font-weight-500 mt-2  text-dark text-center font-size-regular ">
                      Invoice
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="border border-gray p-20 ncard">
                  <div className="d-flex h-100 flex-column justify-content-center align-items-center">
                    <div className="">
                      <IconDelivery className="text-gray-700" />
                    </div>
                    <div className="font-weight-500 mt-2  text-dark text-center font-size-regular ">
                      Delivery
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="border border-gray p-20 ncard">
                  <div className="d-flex h-100 flex-column justify-content-center align-items-center">
                    <div className="">
                      <IconGate className="text-gray-700" />
                    </div>
                    <div className="font-weight-500 mt-2  text-dark text-center font-size-regular ">
                      In/Out Gate Receipt
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="border border-gray p-20 ncard">
                  <div className="d-flex h-100 flex-column justify-content-center align-items-center">
                    <div className="">
                      <IconDoumentAlt className="text-gray-700" />
                    </div>
                    <div className="font-weight-500 mt-2  text-dark text-center font-size-regular ">
                      Documents
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="border border-gray p-20 ncard">
                  <div className="d-flex h-100 flex-column justify-content-center align-items-center">
                    <div className="">
                      <IconBundle className="text-gray-700" />
                    </div>
                    <div className="font-weight-500 mt-2  text-dark text-center font-size-regular ">
                      Bundle
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleHideModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StaticTableContent;
