import React, { useState } from "react";

const tabItems = [
  {
    id: 1,
    title: "Customer",
    path: "/other/profile/customer",
  },
  {
    id: 2,
    title: "Group Customer",
    path: "/other/profile/group-customer",
  },
  {
    id: 3,
    title: "Contact",
    path: "/other/profile/contact",
  },
  {
    id: 4,
    title: "Driver",
    path: "/other/profile/driver",
  },
  {
    id: 5,
    title: "Truck",
    path: "/other/profile/truck",
  },
  {
    id: 6,
    title: "Chassis",
    path: "/other/profile/chassis",
  },
  {
    id: 7,
    title: "Trailer",
    path: "/other/profile/trailer",
  },
  {
    id: 8,
    title: "Fleet Owner",
    path: "/other/profile/fleet-owner",
  },
];

const ProfileTabs = ({ activeTab, button }) => {
  const [isTabActive, setIsTabActive] = useState(activeTab);

  return (
    <div className="d-flex flex-row align-items-center justify-content-between w-100">
      {/*  Sub Header Tabs*/}
      <ul className="nav nav-compact border-0 p-0" role="tablist">
        {tabItems.map((tabItem) => (
          <li className="nav-item" key={tabItem.id}>
            <a
              href={tabItem.path}
              className={`nav-link ml-1 ${
                isTabActive === tabItem.title ? " active" : "rounded-3 bg-light"
              }`}
              onClick={() => setIsTabActive(tabItem.title)}
            >
              {tabItem.title}
            </a>
          </li>
        ))}
      </ul>

      {/* CTA BUTTON */}
      {button}
    </div>
  );
};

export default ProfileTabs;
