import React, { useState } from "react";
import {
  CustomIconBilling,
  CustomIconBuilding,
  CustomIconMapMarker,
  CustomIconTruck,
} from "../../../common/customIcons";

const Notes = (props) => {
  const [isNoteActive, setIsNoteActive] = useState(false);
  const handleNoteActive = (i) => {
    setIsNoteActive(isNoteActive === i ? null : i);
  };

  return (
    <div className="tab">
      <div
        className={`card my-10 ${
          isNoteActive === 1 ? "card--shadow-5" : ""
        }`}
        onClick={() => handleNoteActive(1)}
      >
        <div className="px-30 py-20">
          <div className="d-flex align-items-center mb-20">
            <CustomIconTruck className="mr-10" />
            <h5 className="font-16 mb-0">Driver</h5>
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              placeholder="Notes for driver"
            ></textarea>
          </div>
        </div>
        {isNoteActive === 1 && (
          <div className="card-footer">
            <button className="btn btn-close">Cancel</button>
            <button className="btn btn-primary">Add Note</button>
          </div>
        )}
      </div>
      <div
        className={`card my-10 ${
          isNoteActive === 2 ? "card--shadow-5" : ""
        }`}
        onClick={() => handleNoteActive(2)}
      >
        <div className="px-30 py-20">
          <div className="d-flex align-items-center mb-20">
            <CustomIconBilling className="mr-10" />
            <h5 className="font-16 mb-0">Billing</h5>
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              placeholder="Notes for billing"
            ></textarea>
          </div>
        </div>
        {isNoteActive === 2 && (
          <div className="card-footer">
            <button className="btn btn-close">Cancel</button>
            <button className="btn btn-primary">Add Note</button>
          </div>
        )}
      </div>
      <div
        className={`card my-10 ${
          isNoteActive === 3 ? "card--shadow-5" : ""
        }`}
        onClick={() => handleNoteActive(3)}
      >
        <div className="px-30 py-20">
          <div className="d-flex align-items-center mb-20">
            <CustomIconMapMarker className="mr-10" />
            <h5 className="font-16 mb-0">Yard</h5>
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              placeholder="Notes for yard"
            ></textarea>
          </div>
        </div>
        {isNoteActive === 3 && (
          <div className="card-footer">
            <button className="btn btn-close">Cancel</button>
            <button className="btn btn-primary">Add Note</button>
          </div>
        )}
      </div>
      <div
        className={`card my-10 ${
          isNoteActive === 4 ? "card--shadow-5" : ""
        }`}
        onClick={() => handleNoteActive(4)}
      >
        <div className="px-30 py-20">
          <div className="d-flex align-items-center mb-20">
            <CustomIconBuilding className="mr-10" />
            <h5 className="font-16 mb-0">Office</h5>
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              placeholder="Notes for office"
            ></textarea>
          </div>
        </div>
        {isNoteActive === 4 && (
          <div className="card-footer">
            <button className="btn btn-close">Cancel</button>
            <button className="btn btn-primary">Add Note</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notes;
