import { useState } from "react";
import { IconAngleArrowRight, IconAngleArrowTop, IconCheck, IconTimes } from "../../../../../common/icons";

const EmptyTable = (props) => {
    const { data } = props;
    const [emptyReturnsInfo, setEmptyReturnsInfo] = useState(1);

    const showEmptyReturnsInfo = (i) => {
        setEmptyReturnsInfo(emptyReturnsInfo === i ? null : i);
    };

    return (
        <>
            <tbody>
                <tr>
                    <td colSpan={100}>
                        <div className="d-flex align-items-center">
                            <span className="font-14 text-dark font-weight-500">{data.title}</span>
                            <button className="btn btn-link" onClick={() => showEmptyReturnsInfo(data.code)}>
                                {emptyReturnsInfo === data.code ? <IconAngleArrowTop /> : <IconAngleArrowRight />}
                            </button>
                        </div>
                    </td>
                </tr>
                {emptyReturnsInfo === data.code &&
                    <>
                        <tr>
                            <td width="100">20 ST</td>
                            <td className="text-center"><IconTimes className="text-error-500" /></td>
                            <td className="text-center"><IconTimes className="text-error-500" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                        </tr>
                        <tr>
                            <td width="100">40 ST</td>
                            <td className="text-center"><IconTimes className="text-error-500" /></td>
                            <td className="text-center"><IconTimes className="text-error-500" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                        </tr>
                        <tr>
                            <td width="100">40 HC</td>
                            <td className="text-center"><IconTimes className="text-error-500" /></td>
                            <td className="text-center"><IconTimes className="text-error-500" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                        </tr>
                        <tr>
                            <td width="100">45 HC</td>
                            <td className="text-center"><IconTimes className="text-error-500" /></td>
                            <td className="text-center"><IconTimes className="text-error-500" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                        </tr>
                        <tr>
                            <td width="100">20 Reefer</td>
                            <td className="text-center"><IconTimes className="text-error-500" /></td>
                            <td className="text-center"><IconTimes className="text-error-500" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                        </tr>
                        <tr>
                            <td width="100">40 Reefer</td>
                            <td className="text-center"><IconTimes className="text-error-500" /></td>
                            <td className="text-center"><IconTimes className="text-error-500" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                        </tr>
                        <tr>
                            <td width="100">20 Flat Racks</td>
                            <td className="text-center"><IconTimes className="text-error-500" /></td>
                            <td className="text-center"><IconTimes className="text-error-500" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                        </tr>
                        <tr>
                            <td width="100">40 Flat Racks</td>
                            <td className="text-center"><IconTimes className="text-error-500" /></td>
                            <td className="text-center"><IconTimes className="text-error-500" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                        </tr>
                        <tr>
                            <td width="100">Special Eq</td>
                            <td className="text-center"><IconTimes className="text-error-500" /></td>
                            <td className="text-center"><IconTimes className="text-error-500" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                            <td className="text-center"><IconCheck className="text-primary" /></td>
                        </tr>
                    </>

                }
            </tbody>
        </>
    );



}

export default EmptyTable;