import React from "react";
import CustomSelect from "../../../../common/CustomSelect";
import CustomCreatableSelect from "../../../../common/CustomSelect/creatable";
import { IconCalendar } from "../../../../common/icons";
const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const Customer = () => {
  return (
    <div className="form-wrapper p-20">
      {/* Customer Type */}
      <div className="font-14 mb-10 font-medium">Customer Type</div>
      <div className="form-row w-50">
        <div className="col-md">
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              id="Customer"
              name="Customer"
            />
            <label className=" form-check-label" for="Customer">
              Customer
            </label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              id="Port/Shipper"
              name="Port/Shipper"
            />
            <label className=" form-check-label" for="Port/Shipper">
              Port/Shipper
            </label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              id="Consignee"
              name="Consignee"
            />
            <label className=" form-check-label" for="Consignee">
              Consignee
            </label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              id="Chassis"
              name="Chassis"
            />
            <label className=" form-check-label" for="Chassis">
              Chassis
            </label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              id="All"
              name="All"
            />
            <label className=" form-check-label" for="All">
              All
            </label>
          </div>
        </div>
      </div>
      <div class="hr-light my-20"></div>
      {/* Contact Information*/}
      <div className="font-14 font-medium mb-15">Company</div>
      <div className="form-row">
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Company name
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Chassis #"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Address
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Address"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> City
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter City"
          />
        </div>
        <div className="form-group col-md-3">
          <label className="col-form-label">
            <span className="text-danger">*</span> State
          </label>
          <input type="text" className="form-control" placeholder="Enter AID" />
        </div>
        <div className="form-group col-md-1">
          <label className="col-form-label">
            <span className="text-danger">*</span> Zip
          </label>
          <input type="text" className="form-control" placeholder="Enter ZIP" />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Country
          </label>
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select Country"
            className="w-100"
          />
        </div>
      </div>
      <div class="hr-light my-20"></div>
      {/* Email Information */}
      <div className="font-14 font-medium mb-15">Email </div>
      <div className="form-row">
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Email
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter email"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Password
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Password"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Receiver Email
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Receiver Email"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Billing Email
          </label>
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select Billing Email"
            className="w-100"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Quickbooks Email
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter QuickBooks Email"
          />
        </div>
      </div>
      <div class="hr-light my-20"></div>
      {/* Payment Information */}
      <div className="font-14 font-medium mb-15">Payment </div>
      <div className="form-row">
        <div className="form-group col-lg-2">
          <label className="col-form-label">
            <span className="text-danger">*</span> Credit Limit ($0 Remaining)
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Credit Limit"
          />
        </div>
        <div className="form-group col-lg-2">
          <label className="col-form-label">
            <span className="text-danger">*</span> Payment Terms
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Payment Terms"
          />
        </div>

        <div className="form-group col-lg-2">
          <label className="col-form-label">
            <span className="text-danger">*</span> Account Hold
          </label>
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select Account Hold"
            className="w-100"
          />
        </div>
        <div className="form-group col-lg-2">
          <label className="col-form-label">
            <span className="text-danger">*</span> Currency
          </label>
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select Currency"
            className="w-100"
          />
        </div>
        <div className="form-group col-lg-2">
          <label className="col-form-label">
            <span className="text-danger">*</span> Payment Type
          </label>
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select Currency"
            className="w-100"
          />
        </div>
      </div>

      <div class="hr-light my-20"></div>
      {/* Contact Information */}
      <div className="font-14 font-medium mb-15">Contact</div>
      <div className="form-row">
        <div className="form-group col-md-2">
          <label className="col-form-label">
            <span className="text-danger">*</span> Office Hours Start
          </label>
          <div className="input-wrapper">
            <input className="form-control" placeholder="Select Hours Start" />
            <div className="input-icon">
              <IconCalendar />
            </div>
          </div>
        </div>
        <div className="form-group col-md-2">
          <label className="col-form-label">
            <span className="text-danger">*</span> Office Hours End
          </label>
          <div className="input-wrapper">
            <input className="form-control" placeholder="Select Hours End" />
            <div className="input-icon">
              <IconCalendar />
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Main Contact Name
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Main Contact Name"
          />
        </div>
        <div className="form-group col-md-2">
          <label className="col-form-label">
            Main Phone
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Phone"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            Secondary Contact Name
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Secondary Contact Name"
          />
        </div>
        <div className="form-group col-md-2">
          <label className="col-form-label">
           Secondary Phone
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Secondary Phone"
          />
        </div>
      </div>
      
      <div class="hr-light my-20"></div>
      <div className="font-14 font-medium mb-15">Preferences</div>

      {/* Fleet Customer */}
      <div className="form-row">
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Fleet Customer
          </label>
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="top"
            placeholder="Select Fleet Customer"
            className="w-100"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Sub Customer
          </label>
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="top"
            placeholder="Select Sub Customer"
            className="w-100"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Pay Type
          </label>
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="top"
            placeholder="Select Pay Type"
            className="w-100"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Customer Tags
          </label>
          <CustomCreatableSelect
            name={"name"}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="top"
            placeholder="Enter Customer tags"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Can Edit Load
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Can Edit Load"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Tir Optioanl
          </label>
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="top"
            placeholder="Select Tir Optioanl"
            className="w-100"
          />
        </div>
      </div>
    </div>
  );
};

export default Customer;
