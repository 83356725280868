import React from 'react';
import { IconCalendar } from '../../../../common/icons';
import CustomSelect from '../../../../common/CustomSelect';

const Chassis = () => {
  const options = [
    { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
    { value: 'John Doe', label: 'John Doe' },
    { value: 'Alex Mendes', label: 'Alex Mendes' },
  ];
  return (
    <div className="form-wrapper p-20">
      <form>
        <div className="form-row">
          <div className="form-group col-md-4 ">
            <label className="col-form-label">
              <span className="text-danger">*</span> Chassis #
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Chassis #"
            />
          </div>
          <div className="form-group col-md-4">
            <label className="col-form-label">
              <span className="text-danger">*</span> Chassis Type
            </label>
            <CustomSelect
              name={'name'}
              options={options}
              value={1}
              isMulti={true}
              isClearable={true}
              size="small"
              placement="right"
              placeholder="Select Chassis Type"
              className=""
            />
          </div>
          <div className="form-group col-md-4">
            <label className="col-form-label">
              <span className="text-danger">*</span> Chassis Size
            </label>
            <CustomSelect
              name={'name'}
              options={options}
              value={1}
              isMulti={true}
              isClearable={true}
              size="small"
              placement="right"
              placeholder="Select Chassis Type"
              className=""
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-4">
            <div className="form-group ">
              <label className="col-form-label">
                <span className="text-danger">*</span> Chassis Owner
              </label>
              <CustomSelect
                name={'name'}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Select Chassis Owner"
                className=""
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-row">
              <div className="form-group col-md">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Year
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter "
                />
              </div>
              <div className="form-group col-md">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Make
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter "
                />
              </div>
              <div className="form-group col-md">
                <label className="col-form-label">
                  <span className="text-danger">*</span> AID
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter "
                />
              </div>
              <div className="form-group col-md">
                <label className="col-form-label">
                  <span className="text-danger">*</span> ITD
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter "
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-row">
              <div className="form-group col-md">
                <label className="col-form-label">
                  <span className="text-danger">*</span> License Plate
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter "
                />
              </div>
              <div className="form-group col-md">
                <label className="col-form-label">
                  <span className="text-danger">*</span> License Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter "
                />
              </div>
              <div className="form-group col-md">
                <label className="col-form-label">
                  <span className="text-danger">*</span> VIN
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter "
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md">
            <label className="col-form-label">
              <span className="text-danger">*</span> VIN
            </label>
            <input type="text" className="form-control" placeholder="Enter " />
          </div>
          <div className="form-group col-md">
            <label className="col-form-label">
              <span className="text-danger">*</span> GPS
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter GPS "
            />
          </div>
          <div className="form-group col-md">
            <label className="col-form-label">
              <span className="text-danger">*</span> Inspection
            </label>
            <div className="input-wrapper">
              <input
                type="text"
                className="form-control"
                placeholder="Select date"
              />
              <div className="input-icon">
                <IconCalendar />
              </div>
            </div>
          </div>
          <div className="form-group col-md">
            <label className="col-form-label">
              <span className="text-danger">*</span> Insurance
            </label>
            <div className="input-wrapper">
              <input
                type="text"
                className="form-control"
                placeholder="Select date"
              />
              <div className="input-icon">
                <IconCalendar />
              </div>
            </div>
          </div>
          <div className="form-group col-md">
            <label className="col-form-label">
              <span className="text-danger">*</span> Registration
            </label>
            <div className="input-wrapper">
              <input
                type="text"
                className="form-control"
                placeholder="Select date"
              />
              <div className="input-icon">
                <IconCalendar />
              </div>
            </div>
          </div>
          <div className="form-group col-md">
            <label className="col-form-label">
              <span className="text-danger">*</span> Preventative Maintenance
            </label>
            <div className="input-wrapper">
              <input
                type="text"
                className="form-control"
                placeholder="Select date"
              />
              <div className="input-icon">
                <IconCalendar />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Chassis;
