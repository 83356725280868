import React from "react";
import {
  IconCalendar,
  IconCheck,
  IconDotSeperator,
  IconMapMarkerLoad,
  IconPending,
  IconPlay,
  IconPlus,
  IconReturn,
  IconUpload,
  IconUploadAlt,
} from "../../../../common/icons";
const tableHeaderData = [
  "Load #",
  "Type",
  "Status",
  "Load Assigned Date",
  "Company",
  "Address",
  "Distance",
  "Time",
  "Enroute",
  "Arrived",
  "Size",
];
const tableDate = [];
for (let i = 0; i < 10; i++) {
  tableDate.push({
    id: i,
    load: "POP2_M10052" + i,
  });
}

const DriverItineraryTable = (props) => {
  return (
    <React.Fragment>
      <div className="table-responsive" style={{height: 'calc(100vh - 198px)'}}>
        <table className="table table-card">
          <thead>
            <tr>
              {tableHeaderData.map((headetItem) => {
                return (
                  <th scope="col" key={headetItem}>
                    {headetItem}
                  </th>
                );
              })}
            </tr>
          </thead>
          {tableDate.map((tdata) => {
            return (
              <React.Fragment>
                <tbody>
                  <tr>
                    <td>
                      <a href="#!" className="text-primary font-medium">
                        {tdata.load}
                      </a>
                    </td>
                    <td>
                      <IconUploadAlt className="mr-1 wh-20px" />
                      Hook Container
                    </td>
                    <td>
                      <span className="badge badge-gray-400">
                        <IconCheck className="mr-1" />
                        Finished
                      </span>
                    </td>
                    <td>
                      <div className="form-group mb-0 w-150">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Select Date.."
                          />
                          <span className="input-icon">
                            <IconCalendar />
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>TS Lines</td>
                    <td>Long Beach, CA</td>
                    <td>0 km</td>
                    <td>00:00</td>
                    <td>
                      <div>May 12, 2019</div>
                      <div className="text-muted">04:02 am</div>
                    </td>
                    <td>
                      <div>May 12, 2019</div>
                      <div className="text-muted">04:02 am</div>
                    </td>
                    <td>45'</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <IconMapMarkerLoad className="mr-1 wh-20px" />
                      Deliver Load
                    </td>
                    <td>
                      <span className="badge badge-brand-300">
                        <IconPlay className="mr-1" />
                        In Process
                      </span>
                    </td>
                    <td>
                      <div className="form-group mb-0 w-150">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Select Date.."
                          />
                          <span className="input-icon">
                            <IconCalendar />
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>TS Lines</td>
                    <td>Long Beach, CA</td>
                    <td>0 km</td>
                    <td>00:00</td>
                    <td>
                      <div>May 12, 2019</div>
                      <div className="text-muted">04:02 am</div>
                    </td>
                    <td>
                      <div>May 12, 2019</div>
                      <div className="text-muted">04:02 am</div>
                    </td>
                    <td>45'</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <IconReturn className="mr-1 wh-20px" />
                      Return Container
                    </td>
                    <td>
                      <span className="badge badge-outline-brand-300">
                        <IconPending className="mr-1" />
                        Upcoming
                      </span>
                    </td>
                    <td>
                      <div className="form-group mb-0 w-150">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Select Date.."
                          />
                          <span className="input-icon">
                            <IconCalendar />
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>TS Lines</td>
                    <td>Long Beach, CA</td>
                    <td>0 km</td>
                    <td>00:00</td>
                    <td>
                      <div>May 12, 2019</div>
                      <div className="text-muted">04:02 am</div>
                    </td>
                    <td>
                      <div>May 12, 2019</div>
                      <div className="text-muted">04:02 am</div>
                    </td>
                    <td>45'</td>
                  </tr>
                </tbody>
                <tbody>
                  <th colSpan="11">
                    <a href="#!" className="text-muted">
                      <IconPlus className="mr-2"></IconPlus>
                      Add Bobtail
                    </a>
                  </th>
                </tbody>
              </React.Fragment>
            );
          })}
        </table>
      </div>
    </React.Fragment>
  );
};

export default DriverItineraryTable;
