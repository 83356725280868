import React, { useState } from "react";
import { IconDownload, IconPlus } from "../../../common/icons";
import StaticTableContent from "./elements/StaticTableContent";
import AddNewPricing from "./elements/AddNewPricing";
import AccountPayableTabs from "../common-elements/AccountPayableTabs";

const DynamicPricing = () => {
  const [showNewPricingModal, setShowNewPricingModal] = useState(false);

  const handleShowNewPricingModal = (event) => {
    setShowNewPricingModal(true);
  };

  const handleHideNewPricingModal = (event) => {
    setShowNewPricingModal(false);
  };

  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <AccountPayableTabs
            activeTab={3}
            button={
              <>
                <button className="ml-auto btn btn-outline-light mr-2">
                  <IconDownload className="mr-3" />
                  Download CSV
                </button>

                <button
                  className="btn btn-success font-weight-500"
                  onClick={handleShowNewPricingModal}
                >
                  <IconPlus className="mr-2" />
                  Add New Pricing
                </button>
              </>
            }
          />
        </div>
      </div>
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-15 d-flex align-items-center">
            <h4 className="font-20 mb-0">5 Prices</h4>
          </div>
        </div>

        {/* Static Table Content */}
        <StaticTableContent />
      </div>
      {/* Add New Pricing */}
      {showNewPricingModal && (
        <AddNewPricing
          onCancel={handleHideNewPricingModal}
          onSubmit={handleHideNewPricingModal}
        />
      )}
    </div>
  );
};

export default DynamicPricing;
