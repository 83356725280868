import React from 'react';
import ReactApexChart from 'react-apexcharts';

const series = [
  {
    name: 'series1',
    data: [31, 40, 28, 51, 42, 109, 100],
  },
];

const options = {
  colors: ['#fff'],
  chart: {
    toolbar: {
      show: false,
    },
    height: 195,
    type: 'line',
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'straight',
  },
  markers: {
    fillColor: '#ddd',
    strokeColor: '#fff',
    size: 4,
    shape: 'circle',
  },
  yaxis: {
    labels: {
      show: false,
    },
    tooltip: {
      show: false,
    },
  },
  xaxis: {
    categories: [''],
    tooltip: {
      show: false,
    },
  },
  tooltip: {
    enabled: false,
  },
};

const LineChart = (props) => {
  return (
    <>
      <div className="card rounded-5" style={{ height: '350px', background: '#152F62' }}>
        <div className="pt-20 pl-20 pr-20 pb-10">
          <div className="font-16 text-white font-weight-500">
            Accounts Receivable
          </div>
          <div className="font-30 text-white mt-10 font-weight-500">
            $2,000,000
            <span className="font-14 font-weight-500">.00</span>
          </div>
        </div>
        <div className="chart-graph  p-10">
          <div className="card mb-0  rounded-10 bg-primary">
            <div id="chart">
              <ReactApexChart
                options={options}
                series={series}
                type="line"
                height="205"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LineChart;
