import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomCreatableSelect from "../../../../../common/CustomSelect/creatable";
import CustomSelect from "../../../../../common/CustomSelect/index";

import {
  IconColumn,
  IconFilter,
  IconPen,
  IconTrash,
} from "../../../../../common/icons/index";

const columns = [
  "Truck #",
  "Fleet Owner",
  "License plate",
  "License plate st.",
  "VIN",
  "Address",
  "AID",
  "ITD",
  "Registration",
  "Inspection",
  "HUT",
  "Annual insp.",
  "Bobtail emission",
  "Diesel emission",
  "IFTA",
];

const data = [];
for (let i = 0; i < 20; i++) {
  data.push({
    id: i,
    truck: `DMC123`,
    fleetOwner: "Wade Warren",
    lisence: "NY231312",
    lisceneState: "NY",
    vinNumber: "123123121",
    address: "6391 Elgin St. Celina, Delaware 10299",
    aid: "18/09/2016",
    itd: "18/09/2016",
    registration: "18/09/2016",
    inspection: "18/09/2016",
    hut: "18/09/2016",
    annual: "18/09/2016",
    diesel: "18/09/2016",
    bobtail: "18/09/2016",
    ifta: "18/09/2016",
  });
}

const StaticTableContent = () => {
  return (
    <React.Fragment>
      {/* Search Filter */}
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="form-check form-check--gray pr-30">
            <input
              className="form-check-input"
              type="checkbox"
              id="Available"
              checked="true"
            />
            <label className="form-check-label" for="Available">
              Enabled
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                47
              </span>
            </label>
          </div>
          <div className="form-check form-check--gray pr-30">
            <input className="form-check-input" type="checkbox" id="Pending" />
            <label className="form-check-label" for="Pending">
              Disabled
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                6
              </span>
            </label>
          </div>
        </div>
        <div className="ml-auto d-flex">
          <button className="btn btn-outline-light mr-2">
            <IconFilter className="mr-2" />
            Filter
          </button>
          <div className="position-relative">
            <button className="btn btn-outline-light position-relative">
              <IconColumn />
            </button>
          </div>
        </div>
      </div>
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 370px)" }}
      >
        <table className="table table-card">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((datum) => {
              return (
                <tr key={datum.id}>
                  <td>{datum.truck}</td>
                  <td>{datum.fleetOwner}</td>
                  <td>{datum.lisence}</td>
                  <td>{datum.lisceneState}</td>
                  <td>{datum.vinNumber}</td>
                  <td>{datum.address}</td>
                  <td className="bg-warning-200">${datum.aid}</td>
                  <td>{datum.itd}</td>
                  <td>{datum.registration}</td>
                  <td>{datum.inspection}</td>
                  <td>{datum.hut}</td>
                  <td>{datum.annual}</td>
                  <td>{datum.bobtail}</td>
                  <td>{datum.diesel}</td>
                  <td>{datum.ifta}</td>
                  <td>
                    <button className="btn btn-link">
                      <IconPen className="text-primary" />
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-link">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
