import React, { useState } from "react";

import { IconDownload, IconSendEmail } from "../../../../common/icons/index";

const columns = ["Customer", "", " "];

const tableData = [];
for (let i = 0; i < 20; i++) {
  tableData.push({
    customerCompany: "Salam Pacific Indonesia Lines",
    customerName: "Jenny Wilson",
  });
}

const options = [
  { value: "jessica.hanson@example.com", label: "jessica.hanson@example.com" },
  { value: "john.doe@example.com", label: "john.doe@example.com" },
  { value: "alex@example.com", label: "alex@example.com" },
];

const StaticTableContent = () => {
  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 260px)" }}
      >
        <table className="table table-card table-card--ls">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableData.map((tdata) => {
              return (
                <tr key={tdata.id}>
                  <td>{tdata.customerCompany}</td>
                  <td>{tdata.customerName}</td>
                  <td width="50">
                    <button className="btn btn-link">
                      <IconDownload />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default StaticTableContent;
