import React, { Component } from 'react';

class SubHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTabActive: false,
        };
    }
    handleTabActive = (i) => {
        this.setState({
            isTabActive: this.state.isTabActive === i ? null : i,
        });
    };
    render() {
        return (
            <React.Fragment>
                <div className="subheader">
                    <div className="subheader__inner d-flex align-items-center py-0">
                        <div className="d-flex flex-row align-items-center">
                            <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${this.state.isTabActive === 1 ? "active" : ""
                                            }`}
                                        href="/customerService/dropped-containers"
                                        onClick={() => this.handleTabActive(1)}
                                    >
                                        Dropped Container
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${this.state.isTabActive === 2 ? "active" : ""
                                            }`}
                                        href="/customerService/calendar"
                                        onClick={() => this.handleTabActive(2)}
                                    >
                                        Load Calendar
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${this.state.isTabActive === 3 ? "active" : ""
                                            }`}
                                        href="/customerService/bulk-uploads"
                                        onClick={() => this.handleTabActive(3)}
                                    >
                                        Bulk Uploads
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${this.state.isTabActive === 4 ? "active" : ""
                                            }`}
                                        href="/customerService/chassis-validation"
                                        onClick={() => this.handleTabActive(4)}
                                    >
                                        Chassis Validation
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${this.state.isTabActive === 5 ? "active" : ""
                                            }`}
                                        href="/customerService/customer-messaging"
                                        onClick={() => this.handleTabActive(5)}
                                    >
                                        Customer Messaging
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${this.state.isTabActive === 6 ? "active" : ""
                                            }`}
                                        href="/customerService/driver-messaging"
                                        onClick={() => this.handleTabActive(6)}
                                    >
                                        Driver Messaging
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className={`nav-link ${this.state.isTabActive === 7 ? "active" : ""
                                            }`}
                                        href="/customerService/deleted-loads"
                                        onClick={() => this.handleTabActive(7)}
                                    >
                                        Deleted Loads
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default SubHeader;