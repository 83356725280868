import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Deduction from "../tabs/Deduction";
import DriverPaySettlement from "../tabs/DriverPaySettlement";
import Notes from "../tabs/Notes";
import ConfirmationModal from "../../common-elements/ConfirmationModal";

const SettlementModal = (props) => {
  const { showSettlementModal = true, onClose = () => {} } = props;
  const [isLoadTabActive, setisLoadTabActive] = useState(1);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const handleLoadTabActive = (i) => {
    setisLoadTabActive(isLoadTabActive === i ? null : i);
  };

  const showConfirmationModal = (event) => {
    setConfirmationModal(true);
  };

  const hideConfirmationModal = (event) => {
    setConfirmationModal(false);
  };

  return (
    <>
      <Modal show={showSettlementModal} centered size="xl">
        <Modal.Header className="pb-10">
          <Modal.Title>Robert Fox #359</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="card mb-0">
            {/* Settlement Tabs */}
            <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom">
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    isLoadTabActive === 1 ? "active" : ""
                  }`}
                  href="javascript:void(0)"
                  onClick={() => handleLoadTabActive(1)}
                >
                  <div className="nav-link__text">
                    Driver Pay
                    <span className="badge badge-orange font-12 ml-2 badge-pill">
                      2
                    </span>
                  </div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    isLoadTabActive === 2 ? "active" : ""
                  }`}
                  href="javascript:void(0)"
                  onClick={() => handleLoadTabActive(2)}
                >
                  <div className="nav-link__text">
                    Deduction
                    <span className="badge badge-orange font-12 ml-2 badge-pill">
                      1
                    </span>
                  </div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    isLoadTabActive === 3 ? "active" : ""
                  }`}
                  href="javascript:void(0)"
                  onClick={() => handleLoadTabActive(3)}
                >
                  <div className="nav-link__text">
                    Notes
                    <span className="badge badge-primary font-12 ml-2 badge-pill">
                      1
                    </span>
                  </div>
                </a>
              </li>
            </ul>

            {/* Tab Contents */}
            {isLoadTabActive === 1 && <DriverPaySettlement />}
            {isLoadTabActive === 2 && <Deduction />}
            {isLoadTabActive === 3 && <Notes />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isLoadTabActive === 1 ? (
            <button
              className="btn btn-outline-primary"
              onClick={showConfirmationModal}
            >
              Mark Settlement as reviewed
            </button>
          ) : (
            <button className="btn btn-outline-primary">
              Mark All as reviewed
            </button>
          )}
          <button className="btn btn-primary" onClick={onClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/* Confirmation Modal */}
      {confirmationModal && (
        <ConfirmationModal
          title={
            <h4 className="font-20 px-30">
              Are you sure that you want to mark this settlement as reviewed?
            </h4>
          }
          buttons={
            <div className="d-flex">
              <button
                className="btn btn-outline-light mr-10 px-20"
                onClick={hideConfirmationModal}
              >
                No
              </button>
              <button
                className="btn btn-primary"
                onClick={hideConfirmationModal}
              >
                Yes, Reviewed
              </button>
            </div>
          }
        />
      )}
    </>
  );
};

export default SettlementModal;
