import React, { useState } from 'react';
import {
  IconColumn,
  IconFilter,
  IconPen,
  IconTrash,
  IconPlus,
} from '../../../common/icons';
import Pagination from '../../../common/pagination';
import EquipmentTab from '../EquipmentTabs';
import TrailersLoadPage from './trailersLoadPage';
import TrailersLoadSubHeader from './trailersLoadSubHeader';
const cardItems = [
  {
    id: 1,
    count: 540,
    title: 'Available',
  },
  {
    id: 2,
    count: 703,
    title: 'Dispatched',
  },
  {
    id: 3,
    count: 740,
    title: 'Loaded',
  },
  {
    id: 4,
    count: 994,
    title: 'Empty',
  },
];

const tableHeaderData = [
  'Trailer #',
  'License',
  'VIN',
  'Address',
  'AID',
  'ITD',
  'Registration',
  'Inspection',
  'HUT',
];
const tableData = [];
for (let i = 0; i < 20; i++) {
  tableData.push({
    id: i,
    load: `13e1232131` + i,
    lisence: 'FL523652365',
    lisenceState: 'FL',
    vin: 'FL523652365',
    address: '2715 Ash Dr. San Jose, South Dakota 83475',
    chassisSize: "40'",
    chassisType: 'Standard',
    registration: '12478723471471478',
    inspectionDate: '06/11/21',
    inspectionTime: '8:50PM',
    insurance: '06/11/21',
  });
}

const Trailers = () => {
  const [isCardActive, setCardActive] = useState(1);
  const [ChassisLoad, setChassisLoad] = useState(false);
  const handleShowChassisLoad = (event) => {
    setChassisLoad(true);
  };

  const handleHideChassisLoad = (event) => {
    setChassisLoad(false);
  };

  return (
    <div className="position-relative subheader-wrapper">
      {!ChassisLoad ? (
        <React.Fragment>
          <div className="subheader">
            <div className="subheader__inner d-flex align-items-center py-0">
              <EquipmentTab
                activeTab={3}
                button={
                  <div className="d-flex">
                    <button className="ml-auto btn btn-success">
                      <IconPlus className="mr-2" />
                      Add New Truck
                    </button>
                  </div>
                }
              />
            </div>
          </div>
          <div className="page-fluid">
            <div className="top-page">
              <div className="pb-15 d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <h4 className="font-20 mb-0">Trailers</h4>
                </div>
                {/* Pagination  */}
                <Pagination className="ml-auto" />
              </div>
              {/* Cards Row */}
              <div className={`form-row ${isCardActive ? 'hasactive' : ''}`}>
                {cardItems.map((cardItem) => (
                  <div className="col-md my-2">
                    <div
                      className={`ncard d-flex flex-column justify-content-between p-10 ${
                        isCardActive === cardItem.id ? 'active' : ''
                      }`}
                      onClick={() => setCardActive(cardItem.id)}
                    >
                      <div className="d-flex align-items-center">
                        <div className="mr-1 sbox sbox--default px-2 h-100">
                          {cardItem.count}
                        </div>
                        <div>{cardItem.title}</div>
                        <div className="ml-auto">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={
                                isCardActive === cardItem.id ? 'true ' : null
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Filter Row */}
              <div className="filter-row d-flex align-items-center">
                <div className="app-search header-search">
                  <span className="search-icon"> </span>
                  <div className="position-relative">
                    <input
                      type="search"
                      className="form-control input-search-left"
                      placeholder="Search..."
                      value=""
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check--gray pr-30">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="Available"
                    />
                    <label className="form-check-label" for="Available">
                      Enabled
                      <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                        47
                      </span>
                    </label>
                  </div>
                  <div className="form-check form-check--gray pr-30">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="Pending"
                    />
                    <label className="form-check-label" for="Pending">
                      Disabled
                      <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                        6
                      </span>
                    </label>
                  </div>
                </div>
                <div className="ml-auto d-flex">
                  <button className="btn btn-outline-light mr-2">
                    <IconFilter className="mr-2" />
                    Filter
                  </button>
                  <div className="position-relative">
                    <button className="btn btn-outline-light position-relative">
                      <IconColumn />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Table */}
            <div
              className="table-responsive"
              style={{ height: 'calc(100vh - 325px)' }}
            >
              <table className="table table-card table-card--ls">
                <thead>
                  <tr>
                    <th>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="sr_no"
                      />
                    </th>
                    {tableHeaderData.map((headetItem) => {
                      return (
                        <th scope="col" key={headetItem}>
                          {headetItem}
                        </th>
                      );
                    })}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((tdata) => {
                    return (
                      <tr key={tdata.id}>
                        <td>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="sr_no"
                          />
                        </td>
                        <td>
                          <a
                            href="#"
                            className="text-primary"
                            onClick={handleShowChassisLoad}
                          >
                            {tdata.load}
                          </a>
                        </td>

                        <td>
                          <div>{tdata.lisence}</div>
                          <div className="text-muted">{tdata.lisenceState}</div>
                        </td>
                        <td>{tdata.vin}</td>
                        <td>{tdata.address}</td>
                        <td className="py-0">
                          <div className="bg-warning-500 h-100 d-flex align-items-center justify-content-center px-1">
                            06/11/21
                          </div>
                        </td>
                        <td className="py-0">
                          <div className="bg-warning-500 h-100 d-flex align-items-center justify-content-center px-1">
                            06/11/21
                          </div>
                        </td>
                        <td className="py-0">
                          <div className="bg-warning-50 h-100 d-flex align-items-center justify-content-center px-1">
                            06/11/21
                          </div>
                        </td>
                        <td className="py-0">
                          <div className="bg-warning-50 h-100 d-flex align-items-center justify-content-center px-1">
                            06/11/21
                          </div>
                        </td>
                        <td className="py-0">
                          <div className="bg-warning-50 h-100 d-flex align-items-center justify-content-center px-1">
                            06/11/21
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <button className="btn btn-link">
                              <IconPen />
                            </button>
                            <button className="btn btn-link">
                              <IconTrash />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <TrailersLoadSubHeader closeChassisLoad={handleHideChassisLoad} />
          <TrailersLoadPage />
        </React.Fragment>
      )}
    </div>
  );
};

export default Trailers;
