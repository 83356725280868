import React from "react";
import { useDropzone } from "react-dropzone";
import CustomSelect from "../CustomSelect";
import {
  IconUpload,
  IconTrash,
  IconTimes,
  IconInfo,
  IconCheck,
} from "../icons";

import { CustomIconFileUpload } from "../customIcons";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
const UploadDocument = (props) => {
  const { className } = props;
  //For DROPZONE
  const { acceptedFiles, fileRejections, getRootProps, getInputProps, open } =
    useDropzone({
      accept: "image/jpeg, image/png",
      noClick: true,
      noKeyboard: true,
      multiple: true,
    });
  const acceptedFileItems = acceptedFiles.map((file) => (
    <div
      className="dropzone-fileupload dropzone-fileupload--uploading d-flex"
      key={file.path}
    >
      <IconUpload className="mr-20" />
      <div className="w-100">
        <div className="font-medium d-flex justify-content-between w-100 mb-1">
          <div>
            {file.path} - {file.size} bytes
          </div>
          <div className="ml-auto">25%</div>
        </div>
        <div className="progress">
          <div
            className="progress-bar bg-primary"
            role="progressbar"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: "25%" }}
          ></div>
        </div>
      </div>
      <button className="btn btn-white btn-circle btn-xs ml-3 shadow-none">
        <IconTimes />
      </button>
    </div>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));
  return (
    <div className={`${className}`}>
      <div {...getRootProps({ className: "dropzone" })}>
        <div className="dz-message needsclick" tabindex="0">
          <input
            multiple=""
            type="file"
            autocomplete="off"
            tabindex="-1"
            {...getInputProps()}
          />
          <div className="d-flex align-items-center justify-content-center">
            <CustomIconFileUpload />
            <div className="ml-30">
              <div className="title mb-10">
                Drop files here or click to upload.
              </div>
              <button className="btn btn-primary btn-sm" onClick={open}>
                Choose from your device...
              </button>
            </div>
          </div>
        </div>
      </div>

      {acceptedFileItems}

      {/* After File is Uploaded sue the code block below */}
      <div className="dropzone-fileupload d-flex">
        <button className="btn btn-danger btn-circle btn-xs mr-15">
          <IconInfo />
        </button>
        <div className="document-preview document-preview--sm mr-3">
          <img
            className="document-preview__image"
            src="https://images.pexels.com/photos/590037/pexels-photo-590037.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
          />
        </div>
        <div className="w-100 d-flex">
          <div>
            <div className="font-medium ">PortDocsScan003.pdf</div>
            <div className="text-muted">256 kb</div>
          </div>
          <div className="w-150 ml-auto">
            <CustomSelect
              name={"name"}
              options={options}
              value={1}
              isMulti={true}
              isClearable={true}
              size="small"
              placement="right"
              placeholder="Select doc type"
            />
          </div>
        </div>
        <button className="btn btn-link btn-circle btn-xs ml-3">
          <IconTrash />
        </button>
      </div>
      <div className="dropzone-fileupload d-flex">
        <button className="btn btn-primary btn-circle btn-xs mr-15">
          <IconCheck />
        </button>
        <div className="document-preview document-preview--sm mr-3">
          <img
            className="document-preview__image"
            src="https://images.pexels.com/photos/590037/pexels-photo-590037.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
          />
        </div>
        <div className="w-100 d-flex">
          <div>
            <div className="font-medium ">PortDocsScan003.pdf</div>
            <div className="text-muted">256 kb</div>
          </div>
          <div className="w-150 ml-auto">
            <CustomSelect
              name={"name"}
              options={options}
              value={1}
              isMulti={true}
              isClearable={true}
              size="small"
              placement="right"
              placeholder="Select doc type"
            />
          </div>
        </div>
        <button className="btn btn-link btn-circle btn-xs ml-3">
          <IconTrash />
        </button>
      </div>
    </div>
  );
};

export default UploadDocument;
