import React, { useState, Component } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { IconFilter, IconColumn, IconPlus } from '../../../common/icons';

import BulkTable from './BulkTable';
import FilterSettings from '../../customerService/FilterSettings';
import LoadPage from '../../customerService/LoadPage';
import CustomerTabs from '../common-elements/CustomerTabs';

import SubHeader from '../subheader';

class BulkUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTabActive: false,
      isCardActive: false,
      isPopupOpen: false,
      openPopup: false,
      hidePopup: false,
      issubCardItem: false,
      openLoad: false,
    };
  }

  openLoad = (event) => {
    this.setState({ openLoad: true });
  };

  hideLoad = (event) => {
    this.setState({ openLoad: false });
  };

  openPopup = (event) => {
    document.body.classList.add('bdpopup-open');
    this.setState({ isPopupOpen: true });
  };

  hidePopup = (event) => {
    document.body.classList.remove('bdpopup-open');
    this.setState({ isPopupOpen: false });
  };

  handleTabActive = (i) => {
    this.setState({
      isTabActive: this.state.isTabActive === i ? null : i,
    });
  };
  handleCardActive = (i) => {
    this.setState({
      isCardActive: this.state.isCardActive === i ? null : i,
    });
  };
  handleSubCardItem() {
    this.setState({ isSubCardItem: !this.state.isSubCardItem });
  }

  render() {
    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 12,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 8,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 6,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3,
      },
    };

    return (
      <React.Fragment>
        <div className="position-relative subheader-wrapper">
          <CustomerTabs activeTab={7} />
          {this.state.openLoad == false ? (
            <div className="page-fluid">
              <div className="tab">
                <div className=" p-0 mb-10 d-flex flex-row align-items-center justify-content-between">
                  <ul className="nav nav-compact border-0" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#all-drivers"
                        role="tab"
                      >
                        Customer
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link rounded-3 bg-light ml-2"
                        data-bs-toggle="tab"
                        href="#dispatched"
                        role="tab"
                      >
                        Driver
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link rounded-3 bg-light ml-2"
                        data-bs-toggle="tab"
                        href="#dispatched"
                        role="tab"
                      >
                        Load
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link rounded-3 bg-light ml-2"
                        data-bs-toggle="tab"
                        href="#dispatched"
                        role="tab"
                      >
                        Chasis
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link rounded-3 bg-light ml-2"
                        data-bs-toggle="tab"
                        href="#dispatched"
                        role="tab"
                      >
                        Trailer
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link rounded-3 bg-light ml-2"
                        data-bs-toggle="tab"
                        href="#dispatched"
                        role="tab"
                      >
                        Truck
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link rounded-3 bg-light ml-2 "
                        data-bs-toggle="tab"
                        href="#dispatched"
                        role="tab"
                      >
                        Drive pay Rates
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link rounded-3 bg-light ml-2"
                        data-bs-toggle="tab"
                        href="#dispatched"
                        role="tab"
                      >
                        Customer Employees
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link rounded-3 bg-light ml-2"
                        data-bs-toggle="tab"
                        href="#dispatched"
                        role="tab"
                      >
                        Load Pricing Settings
                      </a>
                    </li>
                  </ul>
                  <button className="ml-auto btn btn-success">
                    <IconPlus className="mr-2" />
                    Add Payment
                  </button>
                </div>
                <div className="pagination-area">
                  <div className="pb-1 d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center mr-3">
                        <h4 className="font-20 mb-0">10 Fleet Owners</h4>
                      </div>
                    </div>
                    <ul className="pagination ml-auto mb-0">
                      <li className="page-item disabled">
                        <a
                          className="page-link"
                          href="#"
                          aria-label="Go to prev page"
                        >
                          Prev
                        </a>
                      </li>
                      <li className="page-item active">
                        <a
                          className="page-link"
                          href="#"
                          aria-label="Go to page number 1"
                        >
                          1
                        </a>
                      </li>
                      <li className="page-item">
                        <a
                          className="page-link"
                          href="#"
                          aria-label="Go to page number 2"
                        >
                          2
                        </a>
                      </li>

                      <li className="page-item">
                        <a
                          className="page-link"
                          href="#"
                          aria-label="Go to next page"
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="filter-area">
                  <div className="filter-row d-flex align-items-center my-2">
                    <div className="app-search header-search">
                      <span className="search-icon"> </span>
                      <div className="position-relative">
                        <input
                          type="search"
                          className="form-control input-search-left"
                          placeholder="Search..."
                          value=""
                        />
                      </div>
                    </div>

                    <div className="ml-auto">
                      <button
                        className="btn btn-outline-light mr-2"
                        onClick={() => this.openPopup()}
                      >
                        <IconFilter className="mr-2" />
                        Filter
                      </button>
                      <button className="btn btn-outline-light">
                        <IconColumn />
                      </button>
                      {this.state.isPopDrop && (
                        <div className="card card--shadow-5 px-20 pt-20 pb-10 popdrop popdrop--right popdrop--bottom mb-0   ">
                          <h5 className="font-16 mb-15">Table columns</h5>
                          <div className="form-row">
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="load-status"
                                />
                                <label
                                  className="form-check-label"
                                  for="load-status"
                                >
                                  Load Status
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="type"
                                />
                                <label className="form-check-label" for="type">
                                  Type
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="move"
                                />
                                <label className="form-check-label" for="move">
                                  Move
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="ssl"
                                />
                                <label className="form-check-label" for="ssl">
                                  SSL
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="driver"
                                />
                                <label
                                  className="form-check-label"
                                  for="driver"
                                >
                                  Driver
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="bol-bkg"
                                />
                                <label
                                  className="form-check-label"
                                  for="bol-bkg"
                                >
                                  BOL/BKG
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="next-address"
                                />
                                <label
                                  className="form-check-label"
                                  for="next-address"
                                >
                                  Next Address
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="port"
                                />
                                <label className="form-check-label" for="port">
                                  Port
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="load"
                                />
                                <label className="form-check-label" for="load">
                                  Load
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="consignee"
                                />
                                <label
                                  className="form-check-label"
                                  for="consignee"
                                >
                                  Consignee
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="eta"
                                />
                                <label className="form-check-label" for="eta">
                                  ETA
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="location"
                                />
                                <label
                                  className="form-check-label"
                                  for="location"
                                >
                                  Location
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="lfd"
                                />
                                <label className="form-check-label" for="lfd">
                                  LFD
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="rtn"
                                />
                                <label className="form-check-label" for="rtn">
                                  RTN
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="cut"
                                />
                                <label className="form-check-label" for="cut">
                                  Cut
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="distance"
                                />
                                <label
                                  className="form-check-label"
                                  for="distance"
                                >
                                  Distance
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="pickup"
                                />
                                <label
                                  className="form-check-label"
                                  for="pickup"
                                >
                                  Pickup
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="bobtail"
                                />
                                <label
                                  className="form-check-label"
                                  for="bobtail"
                                >
                                  Bobtail
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="apt"
                                />
                                <label className="form-check-label" for="apt">
                                  APT
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="vessel-name"
                                />
                                <label
                                  className="form-check-label"
                                  for="vessel-name"
                                >
                                  Vessel Name
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="customer"
                                />
                                <label
                                  className="form-check-label"
                                  for="customer"
                                >
                                  Customer
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="return"
                                />
                                <label
                                  className="form-check-label"
                                  for="return"
                                >
                                  Return
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="container"
                                />
                                <label
                                  className="form-check-label"
                                  for="container"
                                >
                                  Container
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="sealno"
                                />
                                <label
                                  className="form-check-label"
                                  for="sealno"
                                >
                                  Seal No
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="chassis"
                                />
                                <label
                                  className="form-check-label"
                                  for="chassis"
                                >
                                  Chassis
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="pickupn"
                                />
                                <label
                                  className="form-check-label"
                                  for="pickupn"
                                >
                                  Pickup
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="size"
                                />
                                <label className="form-check-label" for="size">
                                  Size
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="business-hour"
                                />
                                <label
                                  className="form-check-label"
                                  for="business-hour"
                                >
                                  Business Hour
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="reset"
                                />
                                <label className="form-check-label" for="reset">
                                  Reset
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check mb-10">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="change-status"
                                />
                                <label
                                  className="form-check-label"
                                  for="change-status"
                                >
                                  Change Status
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="table-area">
                  <BulkTable
                    handleLoadOpen={() => {
                      this.setState({ openLoad: true });
                    }}
                  ></BulkTable>
                </div>
              </div>
            </div>
          ) : (
            <LoadPage />
          )}
        </div>
        <FilterSettings />
      </React.Fragment>
    );
  }
}

export default BulkUpload;
