import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomSelect from "../../../../common/CustomSelect";
import CustomCreatableSelect from "../../../../common/CustomSelect/creatable";
import { IconInfoCircleFilled, IconMinus, IconPlus } from "../../../../common/icons";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const AddNewPricing = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;
  const [isTooltipShown, setIsTooltipShown] = useState(false);

  return (
    <Modal show={showModal} centered animation={false}>
      <Modal.Header>
        <Modal.Title>Add New Pricing</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="form-wrapper">
          <form>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label"> <span className="text-danger">*</span>Charge name</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select Charge name"
                  className="w-100"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label"> <span className="text-danger">*</span>Unit of Measure</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select Unit"
                  className="w-100"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label">Description of Charge</label>
                <textarea class="form-control" placeholder="Description of Charge"></textarea>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label className="col-form-label"> <span className="text-danger">*</span>$ per Unit</label>
                <input className="form-control" placeholder="$0.00" />
              </div>
              <div className="form-group col-md-4">
                <label className="col-form-label"> <span className="text-danger">*</span>Min. total price, $</label>
                <input className="form-control" placeholder="$0.00" />
              </div>
              <div className="form-group col-md-4">
                <label className="col-form-label"> <span className="text-danger">*</span>Free Units</label>
                <input className="form-control" placeholder="$0.00" />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12 form-group">
                <div className="form-check pl-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id=""
                  />
                  <label className="form-check-label" for="">
                    On demand add this price
                  </label>
                </div>
              </div>
            </div>
            <hr className="mt-1 mb-2" />
            {/* Rules */}
            <div className="mt-3">
              <h6 className="font-16 d-inline-flex align-items-center mb-2">
                Rules
                <div className="position-relative tooltip-label">
                  <a className="position-relative">
                    <IconInfoCircleFilled className="ml-2 text-muted" /> 
                  </a>
                  <div className="tooltip bg-dark text-white rounded-10 px-3  py-2" style={{width: "380px"}} data-placement="top-right">
                    <p>
                      Select From Status and TO Status. This will allow PortPro to calculate what dates or time you want to measure between.
                    </p>
                    <p>
                      Example: Chassis Per Day, The From status is when per day calculation would be begin and the TO status is where the per day calculation would end.
                    </p>
                  </div>
                </div> 
              </h6>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label className="col-form-label"> <span className="text-danger">*</span>From</label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select From"
                    className="w-100"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="col-form-label"> <span className="text-danger">*</span>To</label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select To"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
            {/* Conditions */}
            <div className="mt-2">
              <h6 className="d-inline-flex align-items-center mb-3">
                Condition #1
                <div className="position-relative tooltip-label">
                  <a className="position-relative">
                    <IconInfoCircleFilled className="ml-2 text-muted" /> 
                  </a>
                  <div className="tooltip bg-dark text-white rounded-10 px-3  py-2" style={{width: "380px"}} data-placement="top-right">
                    <p>
                      Select From Status and TO Status. This will allow PortPro to calculate what dates or time you want to measure between.
                    </p>
                    <p className="mb-0">
                      Example: Chassis Per Day, The From status is when per day calculation would be begin and the TO status is where the per day calculation would end.
                    </p>
                  </div>
                </div> 
              </h6>
              <div className="form-row">
                <div className="form-check form-group ml-2">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="not"
                    name="condition"
                  />
                  <label className="form-check-label" for="byEmail">
                    Not 
                  </label>
                </div>
                <div className="form-check form-group ml-4">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="and"
                    name="condition"
                  />
                  <label className="form-check-label" for="byEmail">
                    And
                  </label>
                </div>
                <div className="form-check form-group ml-4">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="or"
                    name="condition"
                  />
                  <label className="form-check-label" for="byEmail">
                    Or
                  </label>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label className="col-form-label"> <span className="text-danger">*</span>From</label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select From"
                    className="w-100"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label className="col-form-label"> <span className="text-danger">*</span>To</label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select To"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <CustomCreatableSelect
                  name={"name"}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Enter email address.."
                />
              </div>
            </div>
            <div className="mt-1">
              <a href="javascript:void()" className="d-flex align-items-center">
                <IconPlus className="mr-2" /> Add Condition
              </a>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-success" onClick={onSubmit}>
          Add Pricing
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewPricing;
