import React, { useState } from "react";

import { IconDownload, IconSendEmail } from "../../../../common/icons/index";

const columns = [
  "Customer",
  "Lane 1 Location",
  "Lane 1 Price",
  "Lane 2 Location",
  "Lane 2 Price",
  "Lane 3 Location",
  "Lane 3 Price",
  "Lane 4 Location",
  "Lane 4 Price",
  "Total",
];

const tableData = [];
for (let i = 0; i < 20; i++) {
  tableData.push({
    id: i,
    customer: "IRISL Group",
    lane1locaiton: "6391 Elgin St. Celina, Delaware 10299",
    lane1price: 63 + 10 * i,
    lane2location: "4517 Washington Ave. Manchester, Kentucky 39495",
    lane2price: 74 + 10 * i,
    lane3location: "4517 Washington Ave. Manchester, Kentucky 39495",
    lane3price: 74 + 10 * i,
    lane4location: "4517 Washington Ave. Manchester, Kentucky 39495",
    lane4price: 74 + 10 * i,
  });
}

const options = [
  { value: "jessica.hanson@example.com", label: "jessica.hanson@example.com" },
  { value: "john.doe@example.com", label: "john.doe@example.com" },
  { value: "alex@example.com", label: "alex@example.com" },
];

const StaticTableContent = () => {
  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 252px )" }}
      >
        <table className="table table-card table-card--ls">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableData.map((tdata) => {
              return (
                <tr key={tdata.id}>
                  <td>{tdata.customer}</td>
                  <td>{tdata.lane1locaiton}</td>
                  <td className="text-right">${tdata.lane1price}</td>
                  <td>{tdata.lane2location}</td>
                  <td className="text-right">${tdata.lane2price}</td>
                  <td>{tdata.lane3location}</td>
                  <td className="text-right">${tdata.lane3price}</td>
                  <td>{tdata.lane4location}</td>
                  <td className="text-right">${tdata.lane4price}</td>
                  <td className="font-weight-500 text-right">
                    $
                    {tdata.lane1price +
                      tdata.lane2price +
                      tdata.lane3price +
                      tdata.lane4price}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default StaticTableContent;
