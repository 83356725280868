import React, { useState } from "react";
import { IconClockSnooze, IconDotSeperator, IconFilter, IconPlus, IconReset, IconTags, IconTimesThin } from "../icons";

const DriverList = (props) => {
  const { hideDriverList } = props;
 
  return (
    <React.Fragment>
      <div className="bdpopup-backdrop"></div>
      <div className="bdpopup">
        <div className="bdpopup__content">
          <button
            className="btn btn-none bdpopup__close text-white"
            onClick={hideDriverList}
          >
            <IconTimesThin className="close-icon" />
          </button>
          <div className="d-flex px-30 align-items-center justify-content-between pt-20">
            <p className="font-20 mb-0">Driver List</p>
            <button className="btn btn-outline-light btn-sm mr-2">
              <IconReset className="mr-2" />
              Reset
            </button>
          </div>
          <div className="px-30 py-20">
            <div className="app-search">
              <input
                type="text"
                className="input-search-left form-control"
                placeholder="Search.."
              />
              <span className="search-icon"></span>
            </div>
          </div>
          <div className="dlist px-30" style={{ height: "calc(100% - 116px)" }}>
            <div
              className="dlist__card dlist__card--outline mb-1"
            >
              <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                <div className="flex-shrink-0 mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    className="avatar-xs rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <div className="flex-grow-1">
                  <h5 className="font-14 mb-1">
                    <a href="#" className="text-dark">
                      Robert Fox
                    </a>
                  </h5>
                  <p className="text-muted mb-0">
                    <span>Albany</span>
                    <IconDotSeperator className="mx-2" />
                    <span>12 days ago</span>
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <span className="badge badge-light font-12">
                    (702) 555-0122
                  </span>
                </div>
              </div>
              <div className="dlist__load">
                <div className="dlist__load-current">
                  <div className="d-flex align-items-start p-2">
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Accem - Hook
                        </a>
                      </h5>
                      <p className="text-muted mb-0">Springville, AR</p>
                      <span className="badge badge-secondary font-12 mt-2">
                        +2 more loads
                      </span>
                    </div>
                    <div className="flex-shrink-0">
                      <span className="badge badge-soft-brown">Departed</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dlist__card dlist__card--outline mb-1">
              <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                <div className="flex-shrink-0 mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
                    className="avatar-xs rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <div className="flex-grow-1">
                  <h5 className="font-14 mb-1">
                    <a href="#" className="text-dark">
                      Ronald Richards
                    </a>
                  </h5>
                  <p className="text-muted mb-0">
                    <span>Albany</span>
                    <IconDotSeperator className="mx-2" />
                    <span>12 days ago</span>
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <span className="badge badge-light font-12">
                    (702) 555-0122
                  </span>
                </div>
              </div>
              <div className="dlist__load">
                <div className="dlist__load-current">
                  <div className="d-flex align-items-start p-2">
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Accem - Hook
                        </a>
                      </h5>
                      <p className="text-muted mb-0">Springville, AR</p>
                      <span className="badge badge-secondary font-12 mt-2">
                        +2 more loads
                      </span>
                    </div>
                    <div className="flex-shrink-0">
                      <span className="badge badge-soft-info">
                        Enroute to Delivery load
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dlist__card dlist__card--outline mb-1">
              <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                <div className="flex-shrink-0 mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                    className="avatar-xs rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <div className="flex-grow-1">
                  <h5 className="font-14 mb-1">
                    <a href="#" className="text-dark">
                      Cody Fisher
                    </a>
                  </h5>
                  <p className="text-muted mb-0">
                    <span>Sacramento, CA</span>
                    <IconDotSeperator className="mx-2" />
                    <span>2 days ago</span>
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <span className="badge badge-light font-12">
                    (702) 555-0122
                  </span>
                </div>
              </div>
              <div className="dlist__load">
                <div className="dlist__load-current">
                  <div className="d-flex align-items-start p-2">
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Accem - Hook
                        </a>
                      </h5>
                      <p className="text-muted mb-0">Springville, AR</p>
                    </div>
                    <div className="flex-shrink-0">
                      <span className="badge badge-soft-success">Dropped</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dlist__card dlist__card--outline mb-1">
              <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                <div className="flex-shrink-0 mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                    className="avatar-xs rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <div className="flex-grow-1">
                  <h5 className="font-14 mb-1">
                    <a href="#" className="text-dark">
                      Jerome Bell
                    </a>
                  </h5>
                  <p className="text-muted mb-0">
                    <span>San Diego, CA</span>
                    <IconDotSeperator className="mx-2" />
                    <span>a week ago</span>
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <span className="badge badge-light font-12">
                    (702) 555-0122
                  </span>
                </div>
              </div>
              <div className="dlist__load">
                <div className="dlist__load-current">
                  <div className="d-flex align-items-start p-2">
                    <div className="flex-grow-1">
                      <h5 className="font-14 my-1 text-muted">
                        <IconClockSnooze />
                        <span className="ml-2">Not any assigned loads</span>
                      </h5>
                    </div>
                    <div className="flex-shrink-0">
                      <button className="btn btn-sm btn-primary">
                        <IconPlus className="mr-2" />
                        Assign Load
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dlist__card dlist__card--outline mb-1">
              <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                <div className="flex-shrink-0 mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                    className="avatar-xs rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <div className="flex-grow-1">
                  <h5 className="font-14 mb-1">
                    <a href="#" className="text-dark">
                      Jerome Bell
                    </a>
                  </h5>
                  <p className="text-muted mb-0">
                    <span>San Diego, CA</span>
                    <IconDotSeperator className="mx-2" />
                    <span>a week ago</span>
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <span className="badge badge-light font-12">
                    (702) 555-0122
                  </span>
                </div>
              </div>
              <div className="dlist__load">
                <div className="dlist__load-current">
                  <div className="d-flex align-items-start p-2">
                    <div className="flex-grow-1">
                      <h5 className="font-14 my-1 text-muted">
                        <IconClockSnooze />
                        <span className="ml-2">Not any assigned loads</span>
                      </h5>
                    </div>
                    <div className="flex-shrink-0">
                      <button className="btn btn-sm btn-primary">
                        <IconPlus className="mr-2" />
                        Assign Load
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dlist__card dlist__card--outline mb-1">
              <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                <div className="flex-shrink-0 mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                    className="avatar-xs rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <div className="flex-grow-1">
                  <h5 className="font-14 mb-1">
                    <a href="#" className="text-dark">
                      Jerome Bell
                    </a>
                  </h5>
                  <p className="text-muted mb-0">
                    <span>San Diego, CA</span>
                    <IconDotSeperator className="mx-2" />
                    <span>a week ago</span>
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <span className="badge badge-light font-12">
                    (702) 555-0122
                  </span>
                </div>
              </div>
              <div className="dlist__load">
                <div className="dlist__load-current">
                  <div className="d-flex align-items-start p-2">
                    <div className="flex-grow-1">
                      <h5 className="font-14 my-1 text-muted">
                        <IconClockSnooze />
                        <span className="ml-2">Not any assigned loads</span>
                      </h5>
                    </div>
                    <div className="flex-shrink-0">
                      <button className="btn btn-sm btn-primary">
                        <IconPlus className="mr-2" />
                        Assign Load
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dlist__card dlist__card--outline mb-1">
              <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                <div className="flex-shrink-0 mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                    className="avatar-xs rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <div className="flex-grow-1">
                  <h5 className="font-14 mb-1">
                    <a href="#" className="text-dark">
                      Jerome Bell
                    </a>
                  </h5>
                  <p className="text-muted mb-0">
                    <span>San Diego, CA</span>
                    <IconDotSeperator className="mx-2" />
                    <span>a week ago</span>
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <span className="badge badge-light font-12">
                    (702) 555-0122
                  </span>
                </div>
              </div>
              <div className="dlist__load">
                <div className="dlist__load-current">
                  <div className="d-flex align-items-start p-2">
                    <div className="flex-grow-1">
                      <h5 className="font-14 my-1 text-muted">
                        <IconClockSnooze />
                        <span className="ml-2">Not any assigned loads</span>
                      </h5>
                    </div>
                    <div className="flex-shrink-0">
                      <button className="btn btn-sm btn-primary">
                        <IconPlus className="mr-2" />
                        Assign Load
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dlist__card dlist__card--outline mb-1">
              <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                <div className="flex-shrink-0 mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                    className="avatar-xs rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <div className="flex-grow-1">
                  <h5 className="font-14 mb-1">
                    <a href="#" className="text-dark">
                      Jerome Bell
                    </a>
                  </h5>
                  <p className="text-muted mb-0">
                    <span>San Diego, CA</span>
                    <IconDotSeperator className="mx-2" />
                    <span>a week ago</span>
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <span className="badge badge-light font-12">
                    (702) 555-0122
                  </span>
                </div>
              </div>
              <div className="dlist__load">
                <div className="dlist__load-current">
                  <div className="d-flex align-items-start p-2">
                    <div className="flex-grow-1">
                      <h5 className="font-14 my-1 text-muted">
                        <IconClockSnooze />
                        <span className="ml-2">Not any assigned loads</span>
                      </h5>
                    </div>
                    <div className="flex-shrink-0">
                      <button className="btn btn-sm btn-primary">
                        <IconPlus className="mr-2" />
                        Assign Load
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dlist__card dlist__card--outline mb-1">
              <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                <div className="flex-shrink-0 mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                    className="avatar-xs rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <div className="flex-grow-1">
                  <h5 className="font-14 mb-1">
                    <a href="#" className="text-dark">
                      Jerome Bell
                    </a>
                  </h5>
                  <p className="text-muted mb-0">
                    <span>San Diego, CA</span>
                    <IconDotSeperator className="mx-2" />
                    <span>a week ago</span>
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <span className="badge badge-light font-12">
                    (702) 555-0122
                  </span>
                </div>
              </div>
              <div className="dlist__load">
                <div className="dlist__load-current">
                  <div className="d-flex align-items-start p-2">
                    <div className="flex-grow-1">
                      <h5 className="font-14 my-1 text-muted">
                        <IconClockSnooze />
                        <span className="ml-2">Not any assigned loads</span>
                      </h5>
                    </div>
                    <div className="flex-shrink-0">
                      <button className="btn btn-sm btn-primary">
                        <IconPlus className="mr-2" />
                        Assign Load
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DriverList;
