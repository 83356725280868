import React, { memo, useState, useEffect } from "react";

const RangeSlider = ({className}) => {
  const [range, setRange] = React.useState(0);
  const [step, setStep] = React.useState(0);
  const ref = React.useRef(null);

  const getRange = (ev) => {
    setRange(ev.target.value);
  };

  React.useEffect(() => {
    const rangeLinePadding = 16;
    const calcStep =
      (ref.current.offsetWidth - rangeLinePadding) / ref.current.max;
    setStep(calcStep);
  }, []);

  return (
    <React.Fragment>
      <div className={`range-slider ${className}`}>
        <input
          type="range"
          id="range"
          min="0"
          max="100"
          value={range}
          onChange={getRange}
          ref={ref}
        />
        <label
          htmlFor="range"
          style={{
            transform: `translateX(${(range * step)}px)`,
            position: 'absolute',
            top: 5
          }}
        >
          <span>{range}</span>
        </label>
      </div>
    </React.Fragment>
  );
};

export default RangeSlider;
