import React, { useState } from "react";
import ReportsTabs from "../common-elements/ReportsTabs";
import SearchFilter from "./elements/searchFilter";
import CustomerCompanySubHeader from "./elements/CustomerCompanySubHeader";
import StaticTableContent from "./elements/StaticTableContent";
import CompanyDetailsPage from "./elements/CompanyDetailsPage";


const tableData = [];
for (let i = 0; i < 6; i++) {
  tableData.push({
    id: i,
    customerCompany: "Salam Pacific Indonesia Lines",
    customerName: "Jenny Wilson",
  });
}
const Vessel = () => {
  const [isCustomerActive, setIsCustomerActive] = useState(false);

  const showCustomerDetails = () => {
    setIsCustomerActive(true);
  };
  const hideCustomerDetails = () => {
    setIsCustomerActive(false);
  };
  return (
    <div className="position-relative subheader-wrapper">
      {!isCustomerActive == true ? (
        <React.Fragment>
          <ReportsTabs activeTab={5} />
          <div className="page-fluid">
            <SearchFilter />
            <StaticTableContent showCustomerDetails={showCustomerDetails} />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <CustomerCompanySubHeader hideCustomerDetails={hideCustomerDetails} />
          <div className="page-fluid">
              <CompanyDetailsPage />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Vessel;
