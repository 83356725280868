import React from 'react';
import ReactApexChart from 'react-apexcharts';

const series = [70, 20];

const options = {
  labels: ['Total Driver', 'Total Truck'],
  colors: ['#394E66', '#367BF6'],
  legend: {
    show: false,
    position: 'bottom',
    maxWidth: 20,
    floating: false,
    fontSize: '14px',
    fontWeight: 500,
    inverseOrder: true,
    fontFamily: 'Inter',
    marker: {
      width: 30,
      height: 30,
    },
  },

  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
      donut: {
        size: '85%',
        tooltip: {
          show: false,
          enabled: false,
        },
        labels: {
          show: true,
          total: {
            show: true,
            showAlways: true,
            fontSize: '24px',
            color: '#2787AB',
          },
        },
      },
    },
  },
  tooltip: {
    enabled: false,
  },
  itemMargin: {
    horizontal: 500,
    vertical: 30,
  },
  onItemClick: {
    toggleDataSeries: false,
  },
  onItemHover: {
    highlightDataSeries: false,
  },
};

const RadialChart = (props) => {
  return (
    <>
      <div className="card" style={{ height: '350px' }}>
        <div className="font-16 px-20 pt-20 pb-15 font-weight-500">
          Fleet Usage
        </div>
        <div className="p-20 d-flex justify-content-center align-items-center">
          <div id="radialchart" className="piechart">
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              height={200}
            />
          </div>
        </div>
        <div className="p-20 pt-0 d-flex justify-content-around">
          <div className="text-center">
            <span className="bg-primary rounded-lg text-white mb-1 p-2 pl-3 pr-3 d-inline-block">
              36
            </span>
            <div className="font-16 font-weight-500 mt-1">Total Drivers</div>
          </div>
          <div className="text-center">
            <div
              className=" rounded-lg text-white mb-1 p-2 pl-3 pr-3 d-inline-block"
              style={{ background: '#394E66' }}
            >
              76
            </div>
            <div className="font-16 font-weight-500 mt-1">Total Trucks</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RadialChart;
