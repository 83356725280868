import { SetStateAction, useState } from "react";
import { Modal } from "react-bootstrap";
import { IconCheck } from "../../../../common/icons";

export interface ModalPropsI {
  showModal: any;
  hideModal: any;
}
const ports = [
  {
    name: "Port of LA/LB",
  },
  {
    name: "Port of Houston",
  },
  {
    name: "Port of Charleston",
  },
  {
    name: "Port of NJ/NY",
  },
  {
    name: "Port of Norfolk",
  },
  {
    name: "Port of Savannah",
  },
  {
    name: "Port of Jacksonville",
  },
  {
    name: "Port of Atlanta",
  },
  {
    name: "Port of Virginia",
  },
  {
    name: "Port of Miami",
  },
  {
    name: "Port of Philadelphia",
  },
  {
    name: "Port of New Orleans",
  },
  {
    name: "Port of Calgary",
  },
  {
    name: "Port of Edmundton",
  },
  {
    name: "Port of Toronto",
  },
  {
    name: "Port of Boston",
  },
  {
    name: "Port of Seattle/Tacoma",
  },
  {
    name: "Chicago Rail Yards",
  },
];
const SelectPortMarkets = (props: ModalPropsI) => {
  const { showModal, hideModal } = props;
  const [selectedPort, setSelectedPort] = useState(0);

  const handleSelectedPort = (i: any) => {
    setSelectedPort(selectedPort === i ? null : i);
  };

  return (
    <Modal show={showModal} centered animation={false} size="xl">
      <Modal.Header className="justify-content-center">
        <Modal.Title>Select Port Markets</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-center">
          <div className="app-search bg-gray-50 w-50 rounded-lg mr-20">
            <input
              type="text"
              className="input-search-left form-control py-2"
              placeholder="Search Terminal..."
            />
            <span className="search-icon ml-1"></span>
          </div>
          <button className="btn btn-outline-light">Select All</button>
        </div>
        <div className="c-list__grid mt-40">
          {ports.map((port, i) => (
            <div
              className={`clist__card clist__card--outline text-center py-15 px-10 ${
                selectedPort === i ? 'active' : ''
              }`}
              key={i}
              onClick={() => handleSelectedPort(i)}
            >
              {port.name}
              <div className={selectedPort === i ? 'checked' : 'unchecked'}>
                <IconCheck className="text-white" />
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={showModal}>
          Next
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectPortMarkets;
