import React from 'react';
import { IconBillingCheck } from '../../../common/icons';
const cardItems = [
  {
    id: 1,
    title: 'Orders Tendered',
    count: 780,
    percent: '5.8%',
  },
  {
    id: 2,
    title: 'Average Price',
    count: 853,
    percent: '5.8%',
  },
  {
    id: 3,
    title: 'Average Profit',
    count: '20%',
    percent: '5.8%',
  },
];

const BillingCard = () => {
  return (
    <>
      {/* Cards Row */}
      <div className="row">
        {cardItems.map((cardItem) => (
          <div className="col-lg-4">
            <div
              className="card rounded-5 p-20 mb-30"
              style={{ height: '175px' }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="bg-primary p-2 rounded-10">
                  <IconBillingCheck className="text-white" />
                </div>
                <div className="font-12 font-weight-500 mt-10">
                  {cardItem.title}
                </div>
                <div className=" d-flex ">
                  {cardItem.title === 'Average Price' ? (
                    <div className="font-weight-500 font-30 mt-10 mb-10">
                      $ {cardItem.count}
                      <span className="font-16 font-weight-500">.00</span>
                    </div>
                  ) : (
                    <div className="font-weight-500 font-30 mt-10 mb-10">
                      {cardItem.count}
                    </div>
                  )}
                </div>
                <div className=" d-flex justify-content-center align-items-center badge badge-sm badge-accent-100 px-2 rounded-10">
                  {cardItem.percent}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default BillingCard;
