import React from "react";

const Pagination = () => {
  return (
    <React.Fragment>
      <ul className="pagination ml-auto mb-0">
        <li className="page-item disabled">
          <a className="page-link" href="#" aria-label="Go to prev page">
            Prev
          </a>
        </li>
        <li className="page-item active">
          <a className="page-link" href="#" aria-label="Go to page number 1">
            1
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#" aria-label="Go to page number 2">
            2
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#" aria-label="Go to page number 3">
            3
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#" aria-label="Go to page number 4">
            4
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#" aria-label="Go to page number 5">
            5
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#" aria-label="Go to page number ...">
            ...
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#" aria-label="Go to next page">
            Next
          </a>
        </li>
      </ul>
    </React.Fragment>
  );
};

export default Pagination;
