import React, { Component } from 'react';
import Chatpop from '../../../common/chatpop';
import { IconAttachment } from '../../../common/icons';

class DriverChat extends Component {
  render() {
    return (
      <div className="col" style={{ height: 'calc(100vh - 150px)' }}>
        <Chatpop className="chatpop--full" />
      </div>
    );
  }
}

export default DriverChat;
