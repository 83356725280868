import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import CustomCreatableSelect from '../../../../common/CustomSelect/creatable';
import CustomSelect from '../../../../common/CustomSelect/index';

import { IconDownload, IconSendEmail } from '../../../../common/icons/index';

const columns = [
  'Load #',
  'Status',
  'Driver',
  'ETA',
  'LFD',
  'Cut',
  'PickUp',
  'Customer',
  'Container',
  'Chasis #',
  'Type',
  'Size',
  'SSL',
  'Seal #',
  'BOL/BKG',
  'Port',
  'Consignee',
  'Location',
  'RTN',
  'Return',
];

const data = [
  {
    id: 1,
    load: 'POP2_M100520',
    status: 'Full Paid',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    eta: '-',
    lfd: '06/11',
    cut: '06/11',
    pickupCode: 12312,
    pickupDate: '06/11/21',
    pickupTime: '07:00am',
    customer: 'OEC Group',
    container: 'SDEE4125554',
    chasis: 'SDEE4125554',
    type: 'HC',
    size: '45 ’',
    ssl: '-',
    seal: '-',
    bol: 'SDEE4125554',
    port: 'Garden City Terminal',
    portFullAddress: '3517 W. Gray St. Utica, Pennsylvania 57867',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    payment: 0,
    return: 'Port City WHSE',
  },
  {
    id: 2,
    load: 'POP2_M100520',
    status: 'Full Paid',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    eta: '-',
    lfd: '06/11',
    cut: '06/11',
    pickupCode: 12312,
    pickupDate: '06/11/21',
    pickupTime: '07:00am',
    customer: 'OEC Group',
    container: 'SDEE4125554',
    chasis: 'SDEE4125554',
    type: 'HC',
    size: '45 ’',
    ssl: '-',
    seal: '-',
    bol: 'SDEE4125554',
    port: 'Garden City Terminal',
    portFullAddress: '3517 W. Gray St. Utica, Pennsylvania 57867',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    payment: 0,
    return: 'Port City WHSE',
  },
  {
    id: 3,
    load: 'POP2_M100520',
    status: 'Part Paid',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    eta: '-',
    lfd: '06/11',
    cut: '06/11',
    pickupCode: 12312,
    pickupDate: '06/11/21',
    pickupTime: '07:00am',
    customer: 'OEC Group',
    container: 'SDEE4125554',
    chasis: 'SDEE4125554',
    type: 'HC',
    size: '45 ’',
    ssl: '-',
    seal: '-',
    bol: 'SDEE4125554',
    port: 'Garden City Terminal',
    portFullAddress: '3517 W. Gray St. Utica, Pennsylvania 57867',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    payment: 0,
    return: 'Port City WHSE',
  },
  {
    id: 4,
    load: 'POP2_M100520',
    status: 'Full Paid',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    eta: '-',
    lfd: '06/11',
    cut: '06/11',
    pickupCode: 12312,
    pickupDate: '06/11/21',
    pickupTime: '07:00am',
    customer: 'OEC Group',
    container: 'SDEE4125554',
    chasis: 'SDEE4125554',
    type: 'HC',
    size: '45 ’',
    ssl: '-',
    seal: '-',
    bol: 'SDEE4125554',
    port: 'Garden City Terminal',
    portFullAddress: '3517 W. Gray St. Utica, Pennsylvania 57867',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    payment: 0,
    return: 'Port City WHSE',
  },
  {
    id: 5,
    load: 'POP2_M100520',
    status: 'Part Paid',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    eta: '-',
    lfd: '06/11',
    cut: '06/11',
    pickupCode: 12312,
    pickupDate: '06/11/21',
    pickupTime: '07:00am',
    customer: 'OEC Group',
    container: 'SDEE4125554',
    chasis: 'SDEE4125554',
    type: 'HC',
    size: '45 ’',
    ssl: '-',
    seal: '-',
    bol: 'SDEE4125554',
    port: 'Garden City Terminal',
    portFullAddress: '3517 W. Gray St. Utica, Pennsylvania 57867',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    payment: 0,
    return: 'Port City WHSE',
  },
  {
    id: 6,
    load: 'POP2_M100520',
    status: 'Billing',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    eta: '-',
    lfd: '06/11',
    cut: '06/11',
    pickupCode: 12312,
    pickupDate: '06/11/21',
    pickupTime: '07:00am',
    customer: 'OEC Group',
    container: 'SDEE4125554',
    chasis: 'SDEE4125554',
    type: 'HC',
    size: '45 ’',
    ssl: '-',
    seal: '-',
    bol: 'SDEE4125554',
    port: 'Garden City Terminal',
    portFullAddress: '3517 W. Gray St. Utica, Pennsylvania 57867',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    payment: 0,
    return: 'Port City WHSE',
  },
  {
    id: 7,
    load: 'POP2_M100520',
    status: 'Billing',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    eta: '-',
    lfd: '06/11',
    cut: '06/11',
    pickupCode: 12312,
    pickupDate: '06/11/21',
    pickupTime: '07:00am',
    customer: 'OEC Group',
    container: 'SDEE4125554',
    chasis: 'SDEE4125554',
    type: 'HC',
    size: '45 ’',
    ssl: '-',
    seal: '-',
    bol: 'SDEE4125554',
    port: 'Garden City Terminal',
    portFullAddress: '3517 W. Gray St. Utica, Pennsylvania 57867',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    payment: 0,
    return: 'Port City WHSE',
  },
  {
    id: 8,
    load: 'POP2_M100520',
    status: 'Billing',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    eta: '-',
    lfd: '06/11',
    cut: '06/11',
    pickupCode: 12312,
    pickupDate: '06/11/21',
    pickupTime: '07:00am',
    customer: 'OEC Group',
    container: 'SDEE4125554',
    chasis: 'SDEE4125554',
    type: 'HC',
    size: '45 ’',
    ssl: '-',
    seal: '-',
    bol: 'SDEE4125554',
    port: 'Garden City Terminal',
    portFullAddress: '3517 W. Gray St. Utica, Pennsylvania 57867',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    payment: 0,
    return: 'Port City WHSE',
  },
  {
    id: 9,
    load: 'POP2_M100520',
    status: 'Full Paid',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    eta: '-',
    lfd: '06/11',
    cut: '06/11',
    pickupCode: 12312,
    pickupDate: '06/11/21',
    pickupTime: '07:00am',
    customer: 'OEC Group',
    container: 'SDEE4125554',
    chasis: 'SDEE4125554',
    type: 'HC',
    size: '45 ’',
    ssl: '-',
    seal: '-',
    bol: 'SDEE4125554',
    port: 'Garden City Terminal',
    portFullAddress: '3517 W. Gray St. Utica, Pennsylvania 57867',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    payment: 0,
    return: 'Port City WHSE',
  },
  {
    id: 10,
    load: 'POP2_M100520',
    status: 'Billing',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    eta: '-',
    lfd: '06/11',
    cut: '06/11',
    pickupCode: 12312,
    pickupDate: '06/11/21',
    pickupTime: '07:00am',
    customer: 'OEC Group',
    container: 'SDEE4125554',
    chasis: 'SDEE4125554',
    type: 'HC',
    size: '45 ’',
    ssl: '-',
    seal: '-',
    bol: 'SDEE4125554',
    port: 'Garden City Terminal',
    portFullAddress: '3517 W. Gray St. Utica, Pennsylvania 57867',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    payment: 0,
    return: 'Port City WHSE',
  },
  {
    id: 11,
    load: 'POP2_M100520',
    status: 'Full Paid',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    eta: '-',
    lfd: '06/11',
    cut: '06/11',
    pickupCode: 12312,
    pickupDate: '06/11/21',
    pickupTime: '07:00am',
    customer: 'OEC Group',
    container: 'SDEE4125554',
    chasis: 'SDEE4125554',
    type: 'HC',
    size: '45 ’',
    ssl: '-',
    seal: '-',
    bol: 'SDEE4125554',
    port: 'Garden City Terminal',
    portFullAddress: '3517 W. Gray St. Utica, Pennsylvania 57867',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    payment: 0,
    return: 'Port City WHSE',
  },
  {
    id: 12,
    load: 'POP2_M100520',
    status: 'Billing',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    eta: '-',
    lfd: '06/11',
    cut: '06/11',
    pickupCode: 12312,
    pickupDate: '06/11/21',
    pickupTime: '07:00am',
    customer: 'OEC Group',
    container: 'SDEE4125554',
    chasis: 'SDEE4125554',
    type: 'HC',
    size: '45 ’',
    ssl: '-',
    seal: '-',
    bol: 'SDEE4125554',
    port: 'Garden City Terminal',
    portFullAddress: '3517 W. Gray St. Utica, Pennsylvania 57867',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    payment: 0,
    return: 'Port City WHSE',
  },
  {
    id: 13,
    load: 'POP2_M100520',
    status: 'Full Paid',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    eta: '-',
    lfd: '06/11',
    cut: '06/11',
    pickupCode: 12312,
    pickupDate: '06/11/21',
    pickupTime: '07:00am',
    customer: 'OEC Group',
    container: 'SDEE4125554',
    chasis: 'SDEE4125554',
    type: 'HC',
    size: '45 ’',
    ssl: '-',
    seal: '-',
    bol: 'SDEE4125554',
    port: 'Garden City Terminal',
    portFullAddress: '3517 W. Gray St. Utica, Pennsylvania 57867',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    payment: 0,
    return: 'Port City WHSE',
  },
  {
    id: 14,
    load: 'POP2_M100520',
    status: 'Part Paid',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    eta: '-',
    lfd: '06/11',
    cut: '06/11',
    pickupCode: 12312,
    pickupDate: '06/11/21',
    pickupTime: '07:00am',
    customer: 'OEC Group',
    container: 'SDEE4125554',
    chasis: 'SDEE4125554',
    type: 'HC',
    size: '45 ’',
    ssl: '-',
    seal: '-',
    bol: 'SDEE4125554',
    port: 'Garden City Terminal',
    portFullAddress: '3517 W. Gray St. Utica, Pennsylvania 57867',
    consignee: 'Amalgamated Textiles Inc',
    Location: '4517 Washington Ave. Manchester, Kentucky 39495',
    payment: 0,
    return: 'Port City WHSE',
  },
];

const options = [
  { value: 'jessica.hanson@example.com', label: 'jessica.hanson@example.com' },
  { value: 'john.doe@example.com', label: 'john.doe@example.com' },
  { value: 'alex@example.com', label: 'alex@example.com' },
];

const StaticTableContent = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (event) => {
    setShowModal(true);
  };

  const handleHideModal = (event) => {
    setShowModal(false);
  };

  return (
    <div className="table-responsive" style={{ height: 'calc(100vh - 272px)' }}>
      <table className="table table-card table-card--ls">
        <thead>
          <tr>
            <th>
              <input
                className="form-check-input rounded-3 border-secondary"
                type="checkbox"
                id="sr_no"
              />
            </th>
            {columns.map((column) => {
              return (
                <th scope="col" key={column}>
                  {column}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((datum) => {
            return (
              <tr key={datum.id}>
                <td>
                  <input
                    className="form-check-input rounded-3 border-secondary"
                    type="checkbox"
                    id="sr_no"
                  />
                </td>
                <td>
                  <a
                    href="javascript:void(0)"
                    className="text-primary font-weight-500"
                  >
                    {datum.load}
                  </a>
                </td>
                <td>
                  <div className="">
                    {datum.status == 'Full Paid' && (
                      <span className="badge badge-sm badge-soft-green">
                        {datum.status}
                      </span>
                    )}
                    {datum.status == 'Part Paid' && (
                      <span className="badge badge-sm badge-soft-purple">
                        {datum.status}
                      </span>
                    )}
                    {datum.status == 'Billing' && (
                      <span className="badge badge-sm badge-dark-purple">
                        {datum.status}
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className="d-flex">
                    <div className="mr-2 align-self-center">
                      <img
                        src={datum.img}
                        className="avatar-xs rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <h5 className="mb-0 align-self-center">
                      <a href="#" className="text-dark">
                        {datum.driver}
                      </a>
                    </h5>
                  </div>
                </td>
                <td>{datum.eta}</td>
                <td>{datum.lfd}</td>
                <td className="bg-warning-200">${datum.cut}</td>
                <td>
                  <div className="pickup-code">{datum.pickupCode}</div>
                  <div className="date text-muted mt-1">{datum.pickupDate}</div>
                  <div className="time text-muted mt-1">{datum.pickupTime}</div>
                </td>
                <td>{datum.customer}</td>
                <td>{datum.container}</td>
                <td>{datum.chasis}</td>
                <td>{datum.type}</td>
                <td>{datum.size}</td>
                <td>{datum.ssl}</td>
                <td>{datum.seal}</td>
                <td>{datum.bol}</td>
                <td>
                  <div className="pickup-code">{datum.port}</div>
                  <div className="date text-muted mt-1">
                    {datum.portFullAddress}
                  </div>
                </td>
                <td>{datum.consignee}</td>
                <td>{datum.Location}</td>
                <td>{datum.payment}</td>
                <td>{datum.return}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default StaticTableContent;
