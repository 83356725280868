import React, { Component } from 'react';
import {
  IconPhone,
  IconAt,
  IconInfoFocus,
  IconDocument,
  IconBilling,
  IconPayment,
  IconStatus,
  IconTruck,
  IconTrack,
  IconMessage,
  IconSearchData,
  IconNotes,
} from '../../common/icons';
import Audit from './tabContent/audit';
import Billing from './tabContent/billing';
import Documents from './tabContent/documents';
import DriverPay from './tabContent/driverpay';
import LoadInfo from './tabContent/loadInfo';
import Messaging from './tabContent/messaging';
import Notes from './tabContent/notes';
import OrderStatus from './tabContent/orderStatus';
import Payments from './tabContent/payment';
import Tracking from './tabContent/tracking';
import LoadInfoVertical from './tabContent/loadInfoVertical';
import Map from '../../assets/assets/images/Map.png';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];
class LoadPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadTabActive: false,
      showUploadModal: false,
    };
  }
  handleShowUploadModal = (event) => {
    this.setState({ showUploadModal: true });
  };
  handleHideUploadModal = (event) => {
    this.setState({ showUploadModal: false });
  };
  handleLoadTabActive = (i) => {
    this.setState({
      isLoadTabActive: this.state.isLoadTabActive === i ? null : i,
    });
  };
  render() {
    return (
      <div className="page-fluid">
        <div className="form-row-md">
          <div className="col-md" style={{ maxWidth: 270 }}>
            <div className="card p-15 mb-1">
              <div className="d-flex justify-content-center flex-column">
                <h5 className="mb-1">#POP2_M100583</h5>
              </div>
              <div className="justify-content-center align-items-center">
                <span className="badge badge-info ">
                  Enroute to Delivery load
                </span>
              </div>
            </div>
            <div className="card p-15 mb-1">
              <h5>Customer</h5>
              <div>ABC DISTRIBUTION</div>
              <div className="mb-10">
                Main St, Boston, MA 02129, dominican republic
              </div>

              <div className="mb-10">
                <IconPhone className="mr-2" />
                <span>+7 (903) 880-91-85</span>
              </div>
              <div>
                <IconAt className="mr-2" />
                <span>michelle.rivera@example.com</span>
              </div>
            </div>
            <div className="card p-15 mb-1">
              <h5>Port</h5>
              <div className="font-medium">Maher Terminal</div>
              <div>1210 CORBIN ST, ELIZABETH, NJ 07201, USA</div>
            </div>
            <div className="card p-15 mb-1">
              <h5>Consignee</h5>
              <div className="mb-10">
                <div className="font-medium">Perfect Christmas Trees</div>
                <div>999 US-22, PHILLIPSBURG, NJ 08865</div>
              </div>
              <div className="mb-10">
                <div className="font-medium">Perfect Christmas Trees</div>
                <div>999 US-22, PHILLIPSBURG, NJ 08865</div>
              </div>
              <div className="mb-10">
                <div className="font-medium">Perfect Christmas Trees</div>
                <div>999 US-22, PHILLIPSBURG, NJ 08865</div>
              </div>
            </div>
            <div className="card p-15 mb-1">
              <h5>Return</h5>
              <div className="font-medium">Maher Terminal</div>
              <div>1210 CORBIN ST, ELIZABETH, NJ 07201, USA</div>
            </div>
            <div className="card p-15 mb-1">
              <h5>Summary</h5>
              <div className="d-flex align-items-center justify-content-between mb-10">
                <span className="text-muted">Created by</span>
                <span>Cameron Williamson</span>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-10">
                <span className="text-muted">Container #</span>
                <span>SSF223788</span>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-10">
                <span className="text-muted">BOL #</span>
                <span>33422716</span>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-10">
                <span className="text-muted">Chassis #</span>
                <span>ODBC956</span>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-10">
                <span className="text-muted">SSL</span>
                <span>OOCL</span>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-10">
                <span className="text-muted">Size</span>
                <span>20'</span>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-10">
                <span className="text-muted">Type</span>
                <span>ST</span>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-10">
                <span className="text-muted">Reference</span>
                <span>2340009</span>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-10">
                <span className="text-muted">Total Miles</span>
                <span>2804.33</span>
              </div>
              <div className="">
                <img src={Map} />
              </div>
            </div>
          </div>
          <div className="col-md">
            <div className="card mb-0">
              <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom">
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.state.isLoadTabActive === 1 ? 'active' : ''
                    }`}
                    href="javascript:void(0)"
                    onClick={() => this.handleLoadTabActive(1)}
                  >
                    <IconInfoFocus />
                    <div className="nav-link__text">Load Info</div>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.state.isLoadTabActive === 2 ? 'active' : ''
                    }`}
                    href="javascript:void(0)"
                    onClick={() => this.handleLoadTabActive(2)}
                  >
                    <IconBilling />
                    <div className="nav-link__text">Billing</div>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.state.isLoadTabActive === 3 ? 'active' : ''
                    }`}
                    href="javascript:void(0)"
                    onClick={() => this.handleLoadTabActive(3)}
                  >
                    <IconDocument />
                    <div className="nav-link__text">Documents</div>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.state.isLoadTabActive === 4 ? 'active' : ''
                    }`}
                    href="javascript:void(0)"
                    onClick={() => this.handleLoadTabActive(4)}
                  >
                    <IconPayment />
                    <div className="nav-link__text">Payment</div>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.state.isLoadTabActive === 5 ? 'active' : ''
                    }`}
                    href="javascript:void(0)"
                    onClick={() => this.handleLoadTabActive(5)}
                  >
                    <IconStatus />
                    <div className="nav-link__text">Order Status</div>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.state.isLoadTabActive === 6 ? 'active' : ''
                    }`}
                    href="javascript:void(0)"
                    onClick={() => this.handleLoadTabActive(6)}
                  >
                    <IconTruck />
                    <div className="nav-link__text">Driver Pay</div>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.state.isLoadTabActive === 7 ? 'active' : ''
                    }`}
                    href="javascript:void(0)"
                    onClick={() => this.handleLoadTabActive(7)}
                  >
                    <IconTrack />
                    <div className="nav-link__text">Tracking</div>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.state.isLoadTabActive === 8 ? 'active' : ''
                    }`}
                    href="javascript:void(0)"
                    onClick={() => this.handleLoadTabActive(8)}
                  >
                    <IconMessage />
                    <div className="nav-link__text">Messaging</div>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.state.isLoadTabActive === 9 ? 'active' : ''
                    }`}
                    href="javascript:void(0)"
                    onClick={() => this.handleLoadTabActive(9)}
                  >
                    <IconSearchData />
                    <div className="nav-link__text">Audit</div>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.state.isLoadTabActive === 10 ? 'active' : ''
                    }`}
                    href="javascript:void(0)"
                    onClick={() => this.handleLoadTabActive(10)}
                  >
                    <IconNotes />
                    <div className="nav-link__text">Notes</div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-loads">
              {this.state.isLoadTabActive === 1 && <LoadInfoVertical />}
              {this.state.isLoadTabActive === 2 && <Billing />}
              {this.state.isLoadTabActive === 3 && <Documents />}
              {this.state.isLoadTabActive === 4 && <Payments />}
              {this.state.isLoadTabActive === 5 && <OrderStatus />}
              {this.state.isLoadTabActive === 6 && <DriverPay />}
              {this.state.isLoadTabActive === 7 && <Tracking />}
              {this.state.isLoadTabActive === 8 && <Messaging />}
              {this.state.isLoadTabActive === 9 && <Audit />}
              {this.state.isLoadTabActive === 10 && <Notes />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoadPage;
