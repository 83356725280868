import React from "react";
import { IconCaretDown } from "../../../common/icons";

const SubHeader = (props) => {
  const { mainTitle, button } = props;
  return (
    <div className="subheader">
      <div className="subheader__inner d-flex justify-content-between align-items-center h-100">
        <h4 className="mb-0 d-flex align-items-center">
          {mainTitle} <IconCaretDown />
        </h4>
        {button}
      </div>
    </div>
  );
};

export default SubHeader;
