import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  IconDocument,
  IconMapWithMarker,
  IconNotes,
  IconSearchData,
  IconUsers,
  IconUsersGroup,
} from "../../../common/icons";
import Audit from "./tabComponents/Audit";
import Customer from "./tabComponents/Customer";
import Documents from "./tabComponents/Document";
import Employee from "./tabComponents/Employee";
import GeoFence from "./tabComponents/GeoFence";
import Notes from "./tabComponents/Notes";

const tabItems = [
  {
    id: 1,
    title: "Customer",
    icon: IconUsers,
  },
  {
    id: 2,
    title: "Documents",
    icon: IconDocument,
  },
  {
    id: 3,
    title: "Employee",
    icon: IconUsersGroup,
  },
  {
    id: 4,
    title: "Geofence",
    icon: IconMapWithMarker,
  },
  {
    id: 5,
    title: "Audit",
    icon: IconSearchData,
  },
  {
    id: 6,
    title: "Notes",
    icon: IconNotes,
  },
];

const AddCustomer = (props) => {
  const { showModal = false, onCancel = () => {}, onSubmit = () => {} } = props;
  const [isLoadTabActive, setIsLoadTabActive] = useState("Customer");

  const handleLoadTabActive = (i) => {
    setIsLoadTabActive(isLoadTabActive === i ? null : i);
  };
  return (
    <React.Fragment>
      <Modal show={showModal} size="xl" centered scrollable>
        <Modal.Header className="py-20">
          <Modal.Title>Add New Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 h-100">
          <div className="card mb-0">
            <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom">
              {tabItems.map((item) => (
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      isLoadTabActive === item.title ? "active" : ""
                    }`}
                    href="javascript:void(0)"
                    onClick={() => handleLoadTabActive(item.title)}
                  >
                    <item.icon
                      className={`wh-24px ${
                        isLoadTabActive === item.title ? "text-primary" : ""
                      }`}
                    />

                    <div className="nav-link__text">{item.title}</div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="tab-content">
            {isLoadTabActive === "Customer" && <Customer />}
            {isLoadTabActive === "Documents" && <Documents />}
            {isLoadTabActive === "Employee" && <Employee />}
            {isLoadTabActive === "Geofence" && <GeoFence />}
            {isLoadTabActive === "Audit" && <Audit />}
            {isLoadTabActive === "Notes" && <Notes />}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" onClick={onCancel}>
            Cancel
          </button>
          <button className="btn btn-success" onClick={onSubmit}>
            Add Customer
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default AddCustomer;
