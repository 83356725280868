import React, { useState } from 'react';
import BatchActionLoads from '../../../common/batchActionLoads';
import { CustomIconAddUser } from '../../../common/customIcons';
import {
  IconFire,
  IconMapMarker,
  IconSnowFlake,
  IconWarning,
  IconWeightTool,
  IconFile,
  IconCheck,
  IconTimes,
  IconSortDown,
  IconSortUp,
  IconSort,
} from '../../../common/icons';

const PickUpTable = (props) => {
  const { handleLoadOpen } = props;
  const [isBatchAction, setisBatchAction] = useState(false);
  const [isSelectDriver, setisSelectDriver] = useState(false);
  const handleSelectDriver = (event) => {
    setisSelectDriver(!isSelectDriver);
  };

  const handleBatchAction = () => {
    setisBatchAction(!isBatchAction);
  };

  return (
    <React.Fragment>
      {isBatchAction && <BatchActionLoads />}
      <table className=" table table-card table-card table--style1">
        <thead>
          <tr>
            <th></th>
            <th>Prev.Data</th>
            <th>10/07/2021</th>
            <th>10/08/2021</th>
            <th>10/09/2021</th>
            <th>10/10/2021</th>
            <th>10/11/2021</th>
            <th>10/12/2021</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Container Vessel ETA</td>
            <td>3</td>
            <td>5</td>
            <td>0</td>
            <td>4</td>
            <td>2</td>
            <td>4</td>
            <td>5</td>
          </tr>
          <tr>
            <td>Total Pick Ups At Port</td>
            <td>3</td>
            <td>5</td>
            <td>0</td>
            <td>4</td>
            <td>2</td>
            <td>4</td>
            <td>5</td>
          </tr>
          <tr>
            <td>LFD</td>
            <td>3</td>
            <td>5</td>
            <td>0</td>
            <td>4</td>
            <td>2</td>
            <td>4</td>
            <td>5</td>
          </tr>
          <tr>
            <td>Containers with LFD and No pick Up APT</td>
            <td>3</td>
            <td>5</td>
            <td>0</td>
            <td>4</td>
            <td>2</td>
            <td>4</td>
            <td>5</td>
          </tr>
          <tr>
            <td>Pick Up Apts</td>
            <td>3</td>
            <td>5</td>
            <td>0</td>
            <td>4</td>
            <td>2</td>
            <td>4</td>
            <td>5</td>
          </tr>
          <tr>
            <td>Total Delivery Apts</td>
            <td>3</td>
            <td>5</td>
            <td>0</td>
            <td>4</td>
            <td>2</td>
            <td>4</td>
            <td>5</td>
          </tr>
          <tr>
            <td>Delivery Apts At Port</td>
            <td>3</td>
            <td>5</td>
            <td>0</td>
            <td>4</td>
            <td>2</td>
            <td>4</td>
            <td>5</td>
          </tr>
          <tr>
            <td>Delivery Apts At Yard</td>
            <td>3</td>
            <td>5</td>
            <td>0</td>
            <td>4</td>
            <td>2</td>
            <td>4</td>
            <td>5</td>
          </tr>
          <tr>
            <td>Delivery Apts At Yard</td>
            <td>3</td>
            <td>5</td>
            <td>0</td>
            <td>4</td>
            <td>2</td>
            <td>4</td>
            <td>5</td>
          </tr>
          <tr>
            <td>Total Containers To Return</td>
            <td>3</td>
            <td>5</td>
            <td>0</td>
            <td>4</td>
            <td>2</td>
            <td>4</td>
            <td>5</td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default PickUpTable;
