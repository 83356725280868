import React from "react";
import { IconCalendar } from "../../../../common/icons";
import Pagination from "../../../../common/pagination/index";

const columns = [
  "Bill Date",
  "Bill #",
  "Reference #",
  "Container",
  "Purchase Order ",
  "Base Price",
  "Port Congestion Fee",
  "Detention",
  "Fuel",
  "Over Weight",
  "Drop Charge",
  "Total Charges ",
  "Days ",
];

const tableData = [];
for (let i = 0; i < 20; i++) {
  tableData.push({
    id: i,
    billDate: "12/10/2020",
    bill: "M1122245",
    reference: "MI002-20009569",
    container: "MOEU14075274",
    purchaseOrder: 7000,
    basePrice: 700.0,
    portCongestionFee: "81,736.67",
    detention: 175.0,
    fuel: 175.0,
    overweight: 175.0,
    dropCharge: 175.0,
    totalCharge: 175.0,
    days: 17,
  });
}
const CustomerDetails = () => {
  return (
    <div className="page-fluid">
      <div className="filter-row d-flex align-items-center mt-2">
        <div className="d-flex mr-15">
          <div className="input-wrapper mr-1">
            <input
              className="form-control w-150"
              type="text"
              placeholder="From"
            />
            <span className="input-icon">
              <IconCalendar />
            </span>
          </div>
          <div className="input-wrapper">
            <input
              className="form-control w-150"
              type="text"
              placeholder="To"
            />
            <span className="input-icon">
              <IconCalendar />
            </span>
          </div>
        </div>
        <div className="ml-auto">
          <Pagination />
        </div>
      </div>
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 212px)" }}
      >
        <table className="table table-card table-card--ls">
          <thead>
            <tr>
              <th>Bill Date</th>
              <th>Bill #</th>
              <th>Reference #</th>
              <th>Container</th>
              <th>Purchase Order</th>
              <th className="text-right">Base Price</th>
              <th className="text-right">Port Congestion Fee</th>
              <th className="text-right">Detention</th>
              <th className="text-right">Fuel</th>
              <th className="text-right">Over Weight</th>
              <th className="text-right">Drop Charge</th>
              <th className="text-right">Total Charges</th>
              <th>Days</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((tdata) => {
              return (
                <tr key={tdata.id}>
                  <td>{tdata.billDate}</td>
                  <td>{tdata.bill}</td>
                  <td>{tdata.reference}</td>
                  <td>{tdata.container}</td>
                  <td>{tdata.purchaseOrder}</td>
                  <td className="text-right">${tdata.basePrice}</td>
                  <td className="text-right">${tdata.portCongestionFee}</td>
                  <td className="text-right">${tdata.detention}</td>
                  <td className="text-right">${tdata.fuel}</td>
                  <td className="text-right">${tdata.overweight}</td>
                  <td className="text-right">${tdata.dropCharge}</td>
                  <td className="text-right">${tdata.totalCharge}</td>
                  <td>{tdata.days}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomerDetails;
