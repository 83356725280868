import React from "react";


const ColumnFilter = (props) => {

  return (
    <React.Fragment>
      <div className="card card--shadow-5 px-20 pt-20 pb-10 popdrop popdrop--right popdrop--bottom mb-0   ">
        <h5 className="font-16 mb-15">Table columns</h5>
        <div className="form-row">
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input
                className="form-check-input"
                type="checkbox"
                id="load-status"
              />
              <label className="form-check-label" for="load-status">
                Load Status
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input className="form-check-input" type="checkbox" id="type" />
              <label className="form-check-label" for="type">
                Type
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input className="form-check-input" type="checkbox" id="move" />
              <label className="form-check-label" for="move">
                Move
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input className="form-check-input" type="checkbox" id="ssl" />
              <label className="form-check-label" for="ssl">
                SSL
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input className="form-check-input" type="checkbox" id="driver" />
              <label className="form-check-label" for="driver">
                Driver
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input
                className="form-check-input"
                type="checkbox"
                id="bol-bkg"
              />
              <label className="form-check-label" for="bol-bkg">
                BOL/BKG
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input
                className="form-check-input"
                type="checkbox"
                id="next-address"
              />
              <label className="form-check-label" for="next-address">
                Next Address
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input className="form-check-input" type="checkbox" id="port" />
              <label className="form-check-label" for="port">
                Port
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input className="form-check-input" type="checkbox" id="load" />
              <label className="form-check-label" for="load">
                Load
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input
                className="form-check-input"
                type="checkbox"
                id="consignee"
              />
              <label className="form-check-label" for="consignee">
                Consignee
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input className="form-check-input" type="checkbox" id="eta" />
              <label className="form-check-label" for="eta">
                ETA
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input
                className="form-check-input"
                type="checkbox"
                id="location"
              />
              <label className="form-check-label" for="location">
                Location
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input className="form-check-input" type="checkbox" id="lfd" />
              <label className="form-check-label" for="lfd">
                LFD
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input className="form-check-input" type="checkbox" id="rtn" />
              <label className="form-check-label" for="rtn">
                RTN
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input className="form-check-input" type="checkbox" id="cut" />
              <label className="form-check-label" for="cut">
                Cut
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input
                className="form-check-input"
                type="checkbox"
                id="distance"
              />
              <label className="form-check-label" for="distance">
                Distance
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input className="form-check-input" type="checkbox" id="pickup" />
              <label className="form-check-label" for="pickup">
                Pickup
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input
                className="form-check-input"
                type="checkbox"
                id="bobtail"
              />
              <label className="form-check-label" for="bobtail">
                Bobtail
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input className="form-check-input" type="checkbox" id="apt" />
              <label className="form-check-label" for="apt">
                APT
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input
                className="form-check-input"
                type="checkbox"
                id="vessel-name"
              />
              <label className="form-check-label" for="vessel-name">
                Vessel Name
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input
                className="form-check-input"
                type="checkbox"
                id="customer"
              />
              <label className="form-check-label" for="customer">
                Customer
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input className="form-check-input" type="checkbox" id="return" />
              <label className="form-check-label" for="return">
                Return
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input
                className="form-check-input"
                type="checkbox"
                id="container"
              />
              <label className="form-check-label" for="container">
                Container
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input className="form-check-input" type="checkbox" id="sealno" />
              <label className="form-check-label" for="sealno">
                Seal No
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input
                className="form-check-input"
                type="checkbox"
                id="chassis"
              />
              <label className="form-check-label" for="chassis">
                Chassis
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input
                className="form-check-input"
                type="checkbox"
                id="pickupn"
              />
              <label className="form-check-label" for="pickupn">
                Pickup
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input className="form-check-input" type="checkbox" id="size" />
              <label className="form-check-label" for="size">
                Size
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input
                className="form-check-input"
                type="checkbox"
                id="business-hour"
              />
              <label className="form-check-label" for="business-hour">
                Business Hour
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input className="form-check-input" type="checkbox" id="reset" />
              <label className="form-check-label" for="reset">
                Reset
              </label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-check mb-10">
              <input
                className="form-check-input"
                type="checkbox"
                id="change-status"
              />
              <label className="form-check-label" for="change-status">
                Change Status
              </label>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ColumnFilter;
