import React from "react";
import { IconColumn, IconFilter } from "../../../../common/icons/index";
import CustomSelect from "../../../../common/CustomSelect";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const SearchFilter = () => {
  return (
    <div className="filter-row d-flex align-items-center mt-2 mb-20">
      {/* Search Bar */}
      <div className="app-search header-search mr-20">
        <span className="search-icon"> </span>
        <div className="position-relative">
          <input
            type="search"
            className="form-control input-search-left"
            placeholder="Search..."
            value=""
            style={{ width: "150px" }}
          />
        </div>
      </div>
      {/* Select Customer */}
      <CustomSelect
        name={"name"}
        options={options}
        value={1}
        isMulti={true}
        isClearable={true}
        size="small"
        placement="right"
        placeholder="Select Customer"
        className="w-150"
      />
      {/* Filter Settings */}
      <div className="ml-auto">
        <button className="btn btn-outline-light mr-2">
          <IconFilter className="mr-2" />
          Filter
        </button>
        <button className="btn btn-outline-light">
          <IconColumn />
        </button>
      </div>
    </div>
  );
};

export default SearchFilter;
