import React, { useState } from 'react';
import CustomSelect from '../../../../common/CustomSelect/index';

import {
  IconColumn,
  IconDownload,
  IconFilter,
  IconPen,
  IconTrash,
} from '../../../../common/icons/index';

const columns = ['First name', 'Last name', 'Phone', 'Role'];

const data = [
  {
    id: 1,
    firstName: 'Greg',
    lastName: 'Hawkins',
    phone: '+1-202-555-0161',
    role: ['admin', 'Mechanics'],
  },
  {
    id: 2,
    firstName: 'Greg',
    lastName: 'Hawkins',
    phone: '+1-202-555-0161',
    role: ['admin', 'Mechanics'],
  },
  {
    id: 3,
    firstName: 'Greg',
    lastName: 'Hawkins',
    phone: '+1-202-555-0161',
    role: ['Mechanics'],
  },
  {
    id: 4,
    firstName: 'Greg',
    lastName: 'Hawkins',
    phone: '+1-202-555-0161',
    role: ['Mechanics'],
  },
  {
    id: 5,
    firstName: 'Greg',
    lastName: 'Hawkins',
    phone: '+1-202-555-0161',
    role: ['Mechanics'],
  },
  {
    id: 6,
    firstName: 'Greg',
    lastName: 'Hawkins',
    phone: '+1-202-555-0161',
    role: ['Mechanics'],
  },
  {
    id: 7,
    firstName: 'Greg',
    lastName: 'Hawkins',
    phone: '+1-202-555-0161',
    role: ['Mechanics'],
  },
  {
    id: 8,
    firstName: 'Greg',
    lastName: 'Hawkins',
    phone: '+1-202-555-0161',
    role: ['Mechanics'],
  },
  {
    id: 9,
    firstName: 'Greg',
    lastName: 'Hawkins',
    phone: '+1-202-555-0161',
    role: ['Mechanics'],
  },
  {
    id: 10,
    firstName: 'Greg',
    lastName: 'Hawkins',
    phone: '+1-202-555-0161',
    role: ['Mechanics'],
  },
  {
    id: 11,
    firstName: 'Greg',
    lastName: 'Hawkins',
    phone: '+1-202-555-0161',
    role: ['admin'],
  },
  {
    id: 12,
    firstName: 'Greg',
    lastName: 'Hawkins',
    phone: '+1-202-555-0161',
    role: ['admin'],
  },
  {
    id: 13,
    firstName: 'Greg',
    lastName: 'Hawkins',
    phone: '+1-202-555-0161',
    role: ['admin'],
  },
  {
    id: 14,
    firstName: 'Greg',
    lastName: 'Hawkins',
    phone: '+1-202-555-0161',
    role: ['admin'],
  },
  {
    id: 15,
    firstName: 'Greg',
    lastName: 'Hawkins',
    phone: '+1-202-555-0161',
    role: ['admin'],
  },
  {
    id: 16,
    firstName: 'Greg',
    lastName: 'Hawkins',
    phone: '+1-202-555-0161',
    role: ['Mechanics'],
  },
  {
    id: 17,
    firstName: 'Greg',
    lastName: 'Hawkins',
    phone: '+1-202-555-0161',
    role: ['Mechanics'],
  },
  {
    id: 18,
    firstName: 'Greg',
    lastName: 'Hawkins',
    phone: '+1-202-555-0161',
    role: ['Mechanics'],
  },
];

const StaticTableContent = () => {
  return (
    <React.Fragment>
      {/* Search Filter */}
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>
      </div>
      {/* Table Content */}
      <div
        className="table-responsive"
        style={{ height: 'calc(100vh - 248px)' }}
      >
        <table className="table table-card">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
              <th style={{ width: '2%' }}></th>
              <th style={{ width: '2%' }}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((datum) => {
              return (
                <tr key={datum.id}>
                  <td>{datum.firstName}</td>
                  <td>{datum.lastName}</td>
                  <td>{datum.phone}</td>
                  <td>
                    <div className="d-flex">
                      <span className="badge badge-light rounded mr-2">
                        {datum.role[0]}
                      </span>
                      <span className="badge badge-light rounded">
                        {datum.role[1]}
                      </span>
                    </div>
                  </td>
                  <td>
                    <button className="btn btn-link">
                      <IconPen className="text-primary" />
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-link">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
