import React, { useState, useEffect } from 'react';
import { IconAngleArrowLeft, IconDownload } from '../../../common/icons';
import ReportsTabs from '../common-elements/ReportsTabs';
import CustomerDetails from './elements/CustomerDetails';
import SearchFilter from './elements/searchFilter';
import StaticTableContent from './elements/StaticTableContent';
import TopPage from './elements/toppage';

const tableData = [];
for (let i = 0; i < 20; i++) {
  tableData.push({
    id: i,
    company: 'Salam Pacific Indonesia Lines',
    customer: 'Jenny Wilson',
  });
}

const Charge = () => {
  const [customerDetails, setCustomerDetails] = useState(false);

  const openCustomerDetails = (event) => {
    document.body.classList.add('has-subheader');
    setCustomerDetails(true);
  };

  const hideCustomerDetails = (event) => {
    document.body.classList.remove('has-subheader');
    setCustomerDetails(false);
  };

  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        {customerDetails === true ? (
          <div className="subheader">
            <div className="subheader__inner d-flex">
              <div className="d-flex flex-row align-items-center">
                <button
                  className="btn btn-outline-light"
                  onClick={hideCustomerDetails}
                >
                  <IconAngleArrowLeft />
                </button>
                <div className="d-flex ml-3 align-items-center">
                  <h5 className="mb-0">Salam Pacific Indonesia Lines</h5>
                </div>
              </div>
              <div className="ml-auto position-relative d-flex">
                <button className="btn btn-outline-light mr-2">
                  <IconDownload className="mr-2" />
                  Download
                </button>
              </div>
            </div>
          </div>
        ) : (
          <ReportsTabs activeTab={1} />
        )}

        {customerDetails === false ? (
          <div className="page-fluid">
            <TopPage />
            <SearchFilter />
            <StaticTableContent showCustomerDetails={openCustomerDetails} />
          </div>
        ) : (
          <CustomerDetails />
        )}
      </div>
    </React.Fragment>
  );
};

const ChargeNewFunction = () => {
  useEffect(() => {
    document.body.className = 'has-subheader';
    return () => {
      document.body.className = '';
    };
  });

  return <Charge />;
};

export default ChargeNewFunction;
