import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  IconPen,
  IconTrash,
  IconSortDown,
  IconFilter,
  IconColumn,
} from '../../../../common/icons';
import CustomSelect from '../../../../common/CustomSelect/index';
import EditReport from '../ReportTabComponent/EditReport';

const options = [
  { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
  { value: 'John Doe', label: 'John Doe' },
  { value: 'Alex Mendes', label: 'Alex Mendes' },
];

const StaticTableContent = () => {
  const [isReportShowModal, setIsReportShowModal] = useState(false);

  const showisReport = (event) => {
    setIsReportShowModal(true);
  };

  const hideisReport = (event) => {
    setIsReportShowModal(false);
  };
  return (
    <React.Fragment>
      {/* Search Filter */}
      <div className="filter-row d-flex align-items-center">
        <div className="d-flex">
          <CustomSelect
            name={'name'}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder=" Select "
            className="w-150 z-2 mr-1"
          />
          <CustomSelect
            name={'name'}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select Agent"
            className="w-150 z-2 "
          />
        </div>
        <div className="ml-auto d-flex">
          <button className="btn btn-outline-light">
            <IconFilter className="mr-2" />
            Filter
          </button>
        </div>
      </div>
      {/* Table*/}
      <div
        className="table-responsive"
        style={{ height: 'calc(100vh - 255px)' }}
      >
        <table className="table table-card">
          <thead>
            <tr>
              <th className="text-dark">
                Report name
                <IconSortDown />
              </th>
              <th>Report Base</th>
              <th>Date Created </th>
              <th>Created by</th>
              <th>Shared with </th>
              <th>Schedule</th>
              <th width="2%"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <td class="text-primary font-weight-500">Billing Report</td>
              </td>
              <td>Loads</td>
              <td className="date">3/9/2021</td>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle ">
                      <div class="font-12">AB</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Bessie Cooper
                    </a>
                  </h5>
                </div>
              </td>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle ">
                      <div class="font-12">AB</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Bessie Cooper
                    </a>
                  </h5>
                </div>
              </td>
              <td>
                <div>Specific day of each month</div>
                <div class="text-muted">09:00am</div>
              </td>
              <td>
                <button className="btn btn-link">
                  <a href="/report/edit-report" className="text-gray-400">
                    <IconPen className="" />
                  </a>
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <td class="text-primary font-weight-500">Billing Report</td>
              </td>
              <td>Loads</td>
              <td className="date">3/9/2021</td>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle ">
                      <div class="font-12">AB</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Bessie Cooper
                    </a>
                  </h5>
                </div>
              </td>
              <td>Kristin Watson, Jenny Wilson, Albert Flores +3</td>
              <td>
                <div>Specific day of each month</div>
                <div class="text-muted">09:00am</div>
              </td>
              <td>
                <button className="btn btn-link">
                  <a href="/report/edit-report" className="text-gray-400">
                    <IconPen className="" />
                  </a>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        {isReportShowModal && (
          <EditReport onCancel={hideisReport} onSubmit={hideisReport} />
        )}
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
