import React from 'react';
import './../documentation.scss';

import DocumentationTabs from '../common-elements/subheader';
import Loader from '../../../common/loader';

const DocumentationLoader = () => {
  const CopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };
  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <div className="subheader">
          <div className="subheader__inner d-flex align-items-center py-0">
            <DocumentationTabs activeTab={2} />
          </div>
        </div>
        <div className="page-fluid">
          <div className="row">
            <div className="col-md-4">
              <div className="card p-20 ">
                <div
                  className="position-relative w-100"
                  style={{ height: 200 }}
                >
                  <Loader />
                </div>

                <div class="d-flex align-items-center justify-content-center mt-10">
                  <div class="card--icon__text badge badge-gray-100 mr-10 font-14">
                    <div>&lt;Loader /&gt;</div>
                  </div>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => CopyToClipboard('<Loader />')}
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card p-20 ">
                <div
                  className="position-relative w-100"
                  style={{ height: 200 }}
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>

                <div class="d-flex align-items-center justify-content-center mt-10">
                  <div class="card--icon__text badge badge-gray-100 mr-10 font-14">
                    <div>&lt;Loader /&gt;</div>
                  </div>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() =>
                      CopyToClipboard(
                        '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>'
                      )
                    }
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DocumentationLoader;
