import React, { useState } from "react";

const TabComponent = (props) => {
  const {
    title = "",
    path = "",
    icon = "",
    isActive = true,
    onItemClicked = () =>
      console.error("You passed no action to the component"),
  } = props;
  return (
    <React.Fragment>
      <li className="nav-item">
        <a
          href={path}
          className={`nav-link ${isActive ? "active" : ""}`}
          onClick={onItemClicked}
        >
          {icon}
          <div className="">{title}</div>
        </a>
      </li>
    </React.Fragment>
  );
};

export default TabComponent;
