import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import { IconCheck, IconTrash } from "../../../common/icons";
import TrackOsImportAvailabilityBatchActions from "./BatchActions";

const TrackOsImportAvailabilityTable = ({}) => {
  const [isBatchAction, setisBatchAction] = useState(false);
  const [isDetailModal, setisDetailModal] = useState(false);

  const handleOpenDetailModal = () => {
    setisDetailModal(true);
  };
  const handleHideDetailModal = () => {
    setisDetailModal(false);
  };
  const handleBatchAction = () => {
    setisBatchAction(!isBatchAction);
  };
  const caseStatus = (i) => {
    switch (i) {
      case 1:
        return <span className="badge badge-secondary">Gate In</span>;
      case 2:
        return <span className="badge badge-brand-300">In Yard</span>;
      case 3:
        return <span className="badge badge-brand-300">Grounded</span>;
      case 3:
        return <span className="badge badge-brown">On Vessel</span>;
      case 4:
        return <span className="badge text-gray-500">N/A</span>;
      case 5:
        return (
          <span className="badge badge-light-brown">Unload From Vessel</span>
        );
      case 6:
        return <span className="badge badge-gray-700">Gate Out</span>;
      case 7:
        return <span className="badge badge-success">Available</span>;
      default:
        return <span className="badge badge-warning">Pending</span>;
    }
  };
  const caseHolds = (i) => {
    switch (i) {
      case 1:
      case 2:
      case 3:
      case 4:
        return <span className="badge badge-secondary">Custom Released</span>;
      case 5:
      case 6:
      case 7:
      case 8:
        return <span className="badge badge-gray-700">Custom Hold</span>;
      default:
        return <span className="badge badge-danger">TMF Hold</span>;
    }
  };
  const tableDatas = [];
  for (let i = 1; i < 20; i++) {
    tableDatas.push({
      id: i,
    });
  }

  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 385px)" }}
      >
        {isBatchAction && <TrackOsImportAvailabilityBatchActions />}
        <table className="table table-card">
          <thead>
            <tr>
              <th>
                <div className="from-check form-check--gray">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="sr_no"
                  />
                </div>
              </th>
              <th>Container #</th>
              <th>Trade Type</th>
              <th>Status</th>
              <th>Holds</th>
              <th>Reservation Ticket #</th>
              <th>Origin</th>
              <th>Destination</th>
              <th>Current Loc.</th>
              <th>Line</th>
              <th>Vessel Name</th>
              <th>Vessel Code</th>
              <th>Voyage</th>
              <th>Size/Type</th>
              <th>Fees</th>
              <th>Port LFD</th>
              <th>Line LFD</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tableDatas.map((tabledata) => {
              return (
                <tr key={tabledata.id}>
                  <td>
                    <div className="from-check form-check--gray">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="sr_no"
                        onClick={handleBatchAction}
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      onClick={handleOpenDetailModal}
                      className="pointer text-primary"
                    >{`TGCU${515650 + tabledata.id}`}</div>
                  </td>
                  <td>Domestic</td>
                  <td>{caseStatus(tabledata.id)}</td>
                  <td>{caseHolds(tabledata.id)}</td>
                  <td>************</td>
                  <td>Turkey</td>
                  <td>Port of NY</td>
                  <td>Port of NY</td>
                  <td>EGLV</td>
                  <td>SITC HAIPHONG</td>
                  <td>2341234</td>
                  <td>NJU-NU150E</td>
                  <td>40DH</td>
                  <td>
                    <button className="btn btn-sm btn-primary">Pay</button>
                  </td>
                  <td>12/28/2021</td>
                  <td>12/28/2021</td>
                  <td>
                    <button className="btn btn-link">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
            ;
          </tbody>
        </table>
      </div>
      <Modal show={isDetailModal} centered animation={false} size="xl">
        <Modal.Header className="d-flex flex-column align-items-start">
          <Modal.Title>CMAU1234567</Modal.Title>
          <div className="text-muted">01/31/2021 • 4:30AM</div>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-20">
            <div className="col-md">
              <div className="text-muted">Shipping Line</div>
              <div className="font-medium">CMA-CGM</div>
            </div>
            <div className="col-md">
              <div className="text-muted">Vessel name</div>
              <div className="font-medium">SS Alexey</div>
            </div>
            <div className="col-md">
              <div className="text-muted">Terminal (Pier)</div>
              <div className="font-medium">Fenix (Pier 300)</div>
            </div>
            <div className="col-md">
              <div className="text-muted">Carrier name</div>
              <div className="font-medium">JFX Transport</div>
            </div>
            <div className="col-md">
              <div className="text-muted">Contact</div>
              <div className="font-medium">818-555-1111</div>
            </div>
          </div>
          <div className="row mb-30">
            <div className="col-md">
              <div className="text-muted">Container Line</div>
              <div className="font-medium">40HC</div>
            </div>
            <div className="col-md">
              <div className="text-muted">Vessel Voyage</div>
              <div className="font-medium">055W</div>
            </div>
            <div className="col-md">
              <div className="text-muted">Port</div>
              <div className="font-medium">Port of LA/LB</div>
            </div>
            <div className="col-md">
              <div className="text-muted">SCAC Code</div>
              <div className="font-medium">JFXT</div>
            </div>
            <div className="col-md"></div>
          </div>

          <div className="form-row d-flex">
            <div className="col-md-2">
              <div className="outline-card py-10 px-15 h-100">
                <div className="d-flex">
                  <div className="font-16 font-medium mb-1">On Vessel</div>
                  <div className="btn btn-xs btn-circle btn-primary ml-auto">
                    <IconCheck />
                  </div>
                </div>
                <div className="badge badge-sm badge-primary mb-1">Docked</div>
                <div className="font-medium" style={{ marginBottom: 2 }}>
                  CMA-CGM Jules Verne
                </div>
                <div className="text-muted mb-1">01/31/2021 • 4:30AM</div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="outline-card py-10 px-15 h-100">
                <div className="d-flex">
                  <div className="font-16 font-medium mb-1">Unloaded</div>
                  <div className="btn btn-xs btn-circle btn-primary ml-auto">
                    <IconCheck />
                  </div>
                </div>
                <div className="badge badge-sm badge-primary mb-1">
                  Discharged
                </div>
                <div className="font-medium" style={{ marginBottom: 2 }}>
                  CMA-CGM Jules Verne
                </div>
                <div className="text-muted mb-1">01/31/2021 • 4:30AM</div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="outline-card py-10 px-15 h-100">
                <div className="d-flex">
                  <div className="font-16 font-medium mb-1">In Yard</div>
                  <div className="btn btn-xs btn-circle btn-primary ml-auto">
                    <IconCheck />
                  </div>
                </div>
                <div className="badge badge-sm badge-primary mb-1">
                  Available for pickup
                </div>
                <div className="font-medium" style={{ marginBottom: 2 }}>
                  CMA-CGM Jules Verne
                </div>
                <div className="text-muted mb-1">01/31/2021 • 4:30AM</div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="bg-primary rounded-5 py-10 px-15 text-white shadow-3 h-100">
                <div className="d-flex">
                  <div className="font-16 font-medium mb-1">Out-Gated</div>
                </div>
                <div className="badge badge-sm badge-success mb-1">
                  Appointment Booked
                </div>
                <div className="font-medium" style={{ marginBottom: 2 }}>
                  CMA-CGM Jules Verne
                </div>
                <div className="mb-1">01/31/2021 • 4:30AM</div>
                <button className="btn btn-white btn-block btn-sm">
                  Pay Link
                </button>
              </div>
            </div>
            <div className="col-md-2">
              <div className="rounded-5 bg-brand-50 py-10 px-15 h-100 text-gray-500">
                <div className="d-flex">
                  <div className="font-16 font-medium mb-1">Off-Dock</div>
                </div>
                <div className="badge badge-sm badge-gray-100 mb-1 text-gray-500">
                  Pending..
                </div>
                <div className="font-medium" style={{ marginBottom: 2 }}>
                  CMA-CG M Jules Verne
                </div>
                <div className="text-muted mb-1">01/31/2021 • 4:30AM</div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="rounded-5 bg-brand-50 py-10 px-15 h-100 text-gray-500">
                <div className="d-flex">
                  <div className="font-16 font-medium mb-1">Terminated</div>
                </div>
                <div className="badge badge-sm badge-gray-100 mb-1 text-gray-500">
                  Pending..
                </div>
                <div className="font-medium" style={{ marginBottom: 2 }}>
                  CMA-CGM Jules Verne
                </div>
                <div className="text-muted mb-1">01/31/2021 • 4:30AM</div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" onClick={handleHideDetailModal}>
            Cancel
          </button>
          <button className="btn btn-primary">Apply Cancle</button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default TrackOsImportAvailabilityTable;
