import React, { useState } from 'react';
import { IconDownload, IconPlus } from '../../../common/icons';
import SubHeader from '../common-elements/SubHeader';
import AddCarrierGroup from './elements/AddCarrierGroup';
import StaticTableContent from './elements/StaticTableContent';
import SettingsTab from '../common-elements/SettingsTabs';

const CarrierGroupSettings = () => {
  const [showAddModal, setShowAddModal] = useState(false);

  const handleShowAddModal = (event) => {
    setShowAddModal(true);
  };

  const handleHideAddModal = (event) => {
    setShowAddModal(false);
  };

  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <SettingsTab
            activeTab={10}
            button={
              <div className="d-flex">
                <button
                  className=" btn btn-success "
                  onClick={handleShowAddModal}
                >
                  <IconPlus className="mr-2" />
                  Add New Carrier Group
                </button>
              </div>
            }
          />
        </div>
      </div>
      <div className="page-fluid">
        <div className="top-page">
          <div className="d-f lex align-items-center justify-content-between mb-3">
            <h4 className="font-20 mb-0">6 Groups</h4>
          </div>
        </div>

        {/* Static Table Content */}
        <StaticTableContent />

        {/* Add New Truck */}
        {showAddModal && (
          <AddCarrierGroup
            onCancel={handleHideAddModal}
            onSubmit={handleHideAddModal}
          />
        )}
      </div>
    </div>
  );
};

export default CarrierGroupSettings;
