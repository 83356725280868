
import Column1 from './sub-component/Column1';
import Column2 from './sub-component/Column2';

const Index = () => {
  return (
    <div className="paymentContainer">
      <div className='row d-flex justify-content-center'>
        <Column1 />
        <Column2 />
      </div>
    </div>
  )
}

export default Index;
