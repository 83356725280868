import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  IconPlus,
  IconCalendar,
  IconDownload,
} from '../../../common/icons/index';
import CustomSelect from '../../../common/CustomSelect';
import TabComponent from '../../../common/tabComponent';

const tabItems = [
  {
    id: 1,
    title: 'Profile',
    path: '/other/profile/customer',
  },
  {
    id: 2,
    title: 'Assign Truck',
    path: '/other/assign-truck',
  },
  {
    id: 3,
    title: 'Customer Rates',
    path: '/other/customer-rates',
  },
  {
    id: 4,
    title: 'Customer Discount',
    path: '/other/customer-discount',
  },
  {
    id: 5,
    title: 'Dynamic pricing',
    path: '/other/dynamic-pricing',
  },
  {
    id: 6,
    title: 'Dynamic Expense',
    path: '/other/dynamic-expense',
  },
  {
    id: 7,
    title: 'Driver Pay Rates',
  },
  {
    id: 8,
    title: 'Container',
    path: '/other/container/owner',
  },
  {
    id: 9,
    title: "Chassis",
    path: "/other/chassis/owner",
  },
  {
    id: 10,
    title: 'Role Assign',
    path: '/other/assign-permission-role',
  },
  {
    id: 11,
    title: 'Role Management',
    path: '/other/assign-role-management',
  },
  {
    id: 12,
    title: 'Permission',
  },
];

const options = [
  { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
  { value: 'John Doe', label: 'John Doe' },
  { value: 'Alex Mendes', label: 'Alex Mendes' },
];

const OtherTabs = ({ activeTab, button }) => {
  const [tabActive, setTabActive] = useState(activeTab);
  const [showAddModal, setShowAddModal] = useState(false);

  const handleShowAddModal = (event) => {
    setShowAddModal(true);
  };

  const handleHideAddModal = (event) => {
    setShowAddModal(false);
  };

  return (
    <div className="d-flex flex-row align-items-center justify-content-between w-100">
      {/*  Sub Header Tabs*/}
      <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
        {tabItems.map(({ id, title, path }) => (
          <TabComponent
            key={title}
            title={title}
            path={path}
            onItemClicked={() => setTabActive(id)}
            isActive={tabActive === id}
          />
        ))}
      </ul>

      {tabActive == '2' && (
        <button className="ml-auto btn btn-outline-light mr-2">
          <IconDownload className="mr-3" />
          Download CSV
        </button>
      )}

      {[1, 2].includes(tabActive) && (
        <button
          className="btn btn-success font-weight-500"
          onClick={handleShowAddModal}
        >
          <IconPlus className="mr-2" />
          Add New Load
        </button>
      )}

      {/* CTA BUTTON */}
      {button}

      <Modal show={showAddModal} centered animation={false}>
        <Modal.Header>
          <Modal.Title>Add Driver Pay</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-wrapper">
            <form>
              <div className="form-row mb-10">
                <div className="form-group col-md-12">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Driver
                  </label>
                  <CustomSelect
                    name={'name'}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="- Select Driver"
                    className="w-100"
                  />
                </div>
              </div>
              <div className="form-row-md mb-10">
                <div className="form-group col-md-6">
                  <label>
                    <span className="text-danger">*</span> From
                  </label>
                  <div className="input-wrapper">
                    <input
                      className="form-control"
                      placeholder="-  Select Port"
                    />
                    <div className="input-icon">
                      <IconCalendar />
                    </div>
                  </div>
                </div>
                <div className="form-group col-md-6">
                  <label>
                    <span className="text-danger">*</span> To
                  </label>
                  <div className="input-wrapper">
                    <input
                      className="form-control"
                      placeholder="-  Select Port"
                    />
                    <div className="input-icon">
                      <IconCalendar />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row mb-10">
                <div className="form-group col-md-12">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Amount
                  </label>
                  <input className="form-control" placeholder="$0.00" />
                </div>
              </div>
              <div className="form-row mb-10">
                <div className="form-group col-md-12">
                  <label className="col-form-label">Description</label>
                  <textarea
                    className="form-control"
                    placeholder="-  Select consignee"
                    style={{ minHeight: '8rem' }}
                  ></textarea>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" onClick={handleHideAddModal}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleHideAddModal}>
            Add Driver Pay
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OtherTabs;
