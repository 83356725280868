import React, { useState } from 'react';
import CustomSelect from '../../../../common/CustomSelect';
import CustomCreatableSelect from '../../../../common/CustomSelect/creatable';
import { IconPlus, IconMinus } from '../../../../common/icons';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const GroupType = () => {
  const [showRule, setShowRule] = useState(false);
  const handleShowRule = (event) => {
    setShowRule(true);
  };

  const handleHideRule = (event) => {
    setShowRule(false);
  };

  return (
    <div className="form-wrapper p-30">
      <form>
        <div className="form-row ">
          <div className="form-group col-md-6">
            <label className="col-form-label">
              <span className="text-danger">*</span> Payment Type
            </label>
            <CustomSelect
              name={'name'}
              options={options}
              value={1}
              isMulti={true}
              isClearable={true}
              size="small"
              placement="right"
              placeholder="Move or Move by zip code"
            />
          </div>
          <div className="form-group col-md-3">
            <label className="col-form-label">
              <span className="text-danger">*</span> Pull Container
            </label>
            <input type="text" className="form-control" placeholder="$ 0.00" />
          </div>
          <div className="form-group col-md-3">
            <label className="col-form-label">
              <span className="text-danger">*</span> Return Container
            </label>
            <input type="text" className="form-control" placeholder="$ 0.00" />
          </div>
        </div>
        <hr class="mt-2 mb-0"></hr>
        <div className="mt-20">
          <a
            href="javascript:void(0)"
            onClick={handleShowRule}
            className="d-flex align-items-center font-weight-500 text-primary"
          >
            <IconPlus className="mr-2" /> Add Rule
          </a>
        </div>

        {/* Rules */}
        {showRule && (
          <div className="">
            <div className="mt-3">
              <h6 className="font-16 d-inline-flex align-items-center mb-2">
                Rules
              </h6>
            </div>
            <div className="form-row">
              <div className="form-group col-md-11">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Select Port
                </label>
                <CustomCreatableSelect
                  name={'name'}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Enter email address.."
                />
              </div>
              <div class="form-group col-md-1 d-flex align-items-center justify-content-center">
                <div class="btn-wrapper mt-1">
                  <button class="btn btn-xs btn-circle bg-soft-danger mt-4 ">
                    <IconMinus />
                  </button>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-3">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Pull Container
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="$ 0.00"
                />
              </div>
              <div className="form-group col-md-3">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Return Container
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="$ 0.00"
                />
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default GroupType;
