import React from "react";
import { IconColumn, IconFilter } from "../../../../common/icons/index";
import CustomSelect from "../../../../common/CustomSelect";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const SearchFilter = () => {
  return (
    <div className="filter-row d-flex align-items-center mt-2">
      <CustomSelect
        name={"name"}
        options={options}
        value={1}
        isMulti={true}
        isClearable={true}
        size="small"
        placement="right"
        placeholder="Select Customer... "
        className="w-200"
      />
    </div>
  );
};

export default SearchFilter;
