import { useState, useEffect, useRef } from "react";
import { IconReset } from "../../../../../common/icons";
import PortImages from "./PortImages";

const ports = [
    {
        code: 1,
        title: "FENIX",
        isOpen: true,
        time: "5 min"
    },
    {
        code: 2,
        title: "APM",
        isOpen: true,
        time: "5 min"
    },
    {
        code: 3,
        title: "TRAPAC",
        isOpen: true,
        time: "5 min"
    },
    {
        code: 4,
        title: "WBCT",
        isOpen: true,
        time: "5 min"
    },
    {
        code: 5,
        title: "EVERGREEN",
        isOpen: true,
        time: "5 min"
    },
    {
        code: 6,
        title: "COSCO",
        isOpen: true,
        time: "5 min"
    },
    {
        code: 7,
        title: "GCT",
        isOpen: false,
        time: "5 min"
    },
    {
        code: 8,
        title: "BOLUDA",
        isOpen: false,
        time: "5 min"
    },
    {
        code: 9,
        title: "EVERPORT",
        isOpen: false,
    }
]

const TableHeader = () => {
  const [small, setSmall] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () =>
        setSmall(window.pageYOffset > 50)
      );
    }
  }, []);
  return (
    <thead className="bg-white text-center">
      <tr>
        <td rowSpan={4} className="bg-gray-100 align-bottom p-2">
          <div className="d-flex flex-column align-items-center align-items-center">
            <div className="img-wrapper mb-40">
              <img src={PortImages.PortLogo} alt="" />
            </div>
            <button className="btn btn-white w-100">
              <IconReset className="mr-10" />
              Refresh
            </button>
          </div>
        </td>
      </tr>

      <tr>
        {ports.map((port) => (
          <td className={small ? 'sticky' : ''}>
            <div className="d-flex flex-column align-items-center px-35">
              <div className="img-wrapper">
                <img src={PortImages[port.title]} alt={port.title} />
              </div>
              <h6 className="text-primary mt-2">{port.title.toUpperCase()}</h6>
            </div>
          </td>
        ))}
      </tr>
      <tr>
        {ports.map((port) => (
          <td>
            {port.isOpen ? (
              <span className="badge badge-light-green">OPEN</span>
            ) : (
              <span className="badge badge-gray-50">CLOSED</span>
            )}
          </td>
        ))}
      </tr>
      <tr>
        {ports.map((port) => (
          <td>
            <span className="font-12">
              {port.time ? port.time + ' ago' : 'N/A'}
            </span>
          </td>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;