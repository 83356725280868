import React, { useState } from "react";

const PaymentOption = () => {
    const [selectedType, setSelectedType] = useState(0);

    const handleSelectedType = (i) => {
        setSelectedType(selectedType === i ? null : i);
    }
    return (
        <div className="row justify-content-center no-gutters">
            <div className="col-4" onClick={() => handleSelectedType(1)}>
                <div className={`clist__card clist__card--outline d-flex align-items-center justify-content-center h-100 py-10 px-25 mr-10 ${selectedType === 1 ? "border-primary bg-brand-50" : ""}`}>
                    <span className={`font-weight-500 ${selectedType === 1 ? "text-brand-500" : ""}`}>
                        Free for 7 Days
                    </span>
                </div>
            </div>
            <div className="col-4" onClick={() => handleSelectedType(2)}>
                <div className={`clist__card clist__card--outline text-center py-10 px-25 mr-10 ${selectedType === 2 ? "border-primary bg-brand-50" : ""}`}>
                    <h4 className={`mb-1 ${selectedType === 2 ? "text-brand-500" : ""}`}>$49.98</h4>
                    <p className="text-muted mb-0">/month</p>
                </div>
            </div>
            <div className="col-4" onClick={() => handleSelectedType(3)}>
                <div className={`clist__card clist__card--outline text-center py-10 px-25 mr-10 ${selectedType === 3 ? "border-primary bg-brand-50" : ""}`}>
                    <h4 className={`mb-1 ${selectedType === 3 ? "text-brand-500" : ""}`}>$499.98</h4>
                    <p className="text-muted mb-0">/year</p>
                </div>
            </div>
        </div>
    )
}

export default PaymentOption;