const PortImages = {
    PortLogo:
        require('../../../../../assets/assets/images/ports/ic.svg').default,
    FENIX:
        require('../../../../../assets/assets/images/ports/FENIX.svg').default,
    APM:
        require('../../../../../assets/assets/images/ports/APM.svg').default,
    BOLUDA:
        require('../../../../../assets/assets/images/ports/BOLUDA.png').default,
    COSCO:
        require('../../../../../assets/assets/images/ports/COSCO.png').default,
    EVERGREEN:
        require('../../../../../assets/assets/images/ports/EVERGREEN.png').default,
    EVERPORT:
        require('../../../../../assets/assets/images/ports/EVERPORT.png').default,
    GCT:
        require('../../../../../assets/assets/images/ports/GCT.png').default,
    TRAPAC:
        require('../../../../../assets/assets/images/ports/TRAPAC.svg').default,
    WBCT:
        require('../../../../../assets/assets/images/ports/GCT.png').default

}

export default PortImages;