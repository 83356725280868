import React from "react";
import CustomSelect from "../../../../common/CustomSelect";
import CustomCreatableSelect from "../../../../common/CustomSelect/creatable";
import { IconCalendar } from "../../../../common/icons";
const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const Driver = () => {
  return (
    <div className="form-wrapper p-20">
      <div className="row">
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Select Profile Type
          </label>
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select..."
            className="w-100"
          />
        </div>

        <div className="form-group col-md-4">
          <label className="col-form-label">Driver Shift</label>
          <div className="d-flex mt-2">
            <div className="form-check mr-20">
              <input
                type="radio"
                className="form-check-input"
                id="Day"
                name="Day"
              />
              <label className="form-check-label" for="Day">
                Day
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                className="form-check-input"
                id="Night"
                name="Night"
              />
              <label className="form-check-label" for="All">
                Night
              </label>
            </div>
          </div>
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Password
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Password"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> First Name
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter First Name"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Last Name
          </label>
          <input type="text" className="form-control" placeholder="Last Name" />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Email
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Email"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Phone
          </label>
          <input
            type="text"
            className="form-control w-150"
            placeholder="Enter Phone"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> License Location
          </label>
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select License Location.."
            className="w-100"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> License Number
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter License Number.."
          />
        </div>
      </div>
      <div class="hr-light mt-10 mb-20"></div>
      <div className="row">
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Home Terminal Time Zone
          </label>
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select.."
            className="w-100"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Carrier Name
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Carrier Name"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Main Office Address
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Main Office Address"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Sealink Number
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Sealink Number "
          />
        </div>

        <div className="form-group col-md-4">
          <div className="row">
            <div className="col-md form-group">
              <label className="col-form-label">
                <span className="text-danger">*</span> DL EXP
              </label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-control"
                  placeholder="-  Select Date"
                />
                <div className="input-icon">
                  <IconCalendar />
                </div>
              </div>
            </div>
            <div className="col-md form-group">
              <label className="col-form-label">
                <span className="text-danger">*</span> Terminal Date
              </label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-control"
                  placeholder="- Select Port"
                />
                <div className="input-icon">
                  <IconCalendar />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group col-md-4">
          <div className="row">
            <div className="col-md form-group">
              <label className="col-form-label">
                <span className="text-danger">*</span> Date of Birth
              </label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-control"
                  placeholder="-  Select Date"
                />
                <div className="input-icon">
                  <IconCalendar />
                </div>
              </div>
            </div>
            <div className="col-md form-group">
              <label className="col-form-label">
                <span className="text-danger">*</span> Date of Hire (DOH)
              </label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-control"
                  placeholder="- Select Port"
                />
                <div className="input-icon">
                  <IconCalendar />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group col-md-4">
          <div className="row">
            <div className="col-md form-group">
              <label className="col-form-label">
                <span className="text-danger">*</span> Medical Exp
              </label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-control"
                  placeholder="-  Select Date"
                />
                <div className="input-icon">
                  <IconCalendar />
                </div>
              </div>
            </div>
            <div className="col-md form-group">
              <label className="col-form-label">
                <span className="text-danger">*</span> Twice Exp
              </label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-control"
                  placeholder="- Select Port"
                />
                <div className="input-icon">
                  <IconCalendar />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group col-md-4">
          <div className="row">
            <div className="col-md form-group">
              <label className="col-form-label">
                <span className="text-danger">*</span> Sea Link Exp
              </label>
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-control"
                  placeholder="-  Select Date"
                />
                <div className="input-icon">
                  <IconCalendar />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Billing Email
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Billing Email"
          />
        </div>
      </div>
      <div class="hr-light mt-10 mb-20"></div>
      <div className="row">
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Emergency Contact Name
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Emergency Contact Name"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Emergency Relation
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Emergency Relation"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Emergency/Home Phone
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Emergency/Home Phone "
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Register Business name
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Register Business name"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> HST #
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter HST #"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> HHST Percentage
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter HHST Percentage"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Preferred States
          </label>
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select.."
            className="w-100"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Preferred Load Types
          </label>
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select.."
            className="w-100"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Preferred Distance
          </label>
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select.."
            className="w-100"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Hazmat
          </label>
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select.."
            className="w-100"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Liquor
          </label>
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select.."
            className="w-100"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Over Weight
          </label>
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select.."
            className="w-100"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Overweight States
          </label>
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select.."
            className="w-100"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Reefer
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter HST #"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Social Security
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Social Security"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Tablet #
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter HST #"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> ELD #
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Social Security "
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Fuel Card
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Social Security"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> EZ Pass
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter EZ Pass"
          />
        </div>

        <div className="form-group col-md-4">
          <label className="col-form-label">Driver</label>
          <div className="d-flex mt-2">
            <div className="form-check mr-20">
              <input
                type="radio"
                className="form-check-input"
                id="No"
                name="No"
              />
              <label className="form-check-label" for="No">
                No
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                className="form-check-input"
                id="Yes"
                name="Yes"
              />
              <label className="form-check-label" for="Yes">
                Yes
              </label>
            </div>
          </div>
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Bank Account #
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Bank Account #"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> Routing #
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Routing #"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">
            <span className="text-danger">*</span> T-Shirt Size
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter T-Shirt Size"
          />
        </div>
        <div className="form-group col-md-4">
          <label className="col-form-label">Disable Driver Pay</label>
          <div className="d-flex mt-2">
            <div className="form-check mr-20">
              <input
                type="radio"
                className="form-check-input"
                id="No"
                name="No"
              />
              <label className="form-check-label" for="No">
                No
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                className="form-check-input"
                id="Yes"
                name="Yes"
              />
              <label className="form-check-label" for="Yes">
                Yes
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Driver;
