import React, { useEffect, Component } from "react";
import StaticTableContent from "./StaticTableContent";
import {
  IconAngleArrowLeft,
  IconAngleArrowRight,
  IconCalendar,
  IconCaretDown,
  IconPrinter,
  IconClone,
  IconTrash,
  IconSendEmail,
} from "../../common/icons/index";
import LoadPage from "./LoadPage";
import DispatcherTabs from "./common-elements/dispatcherTabs";
import Pagination from "../../common/pagination";
import DispatcherFilterRow from "./common-elements/dispatcherFilterRow";
import { CustomIconSaveOutline } from "../../common/customIcons";
import DispatcherCards from "./common-elements/dispatcherCards";

const tabItems = [
  {
    id: 1,
    title: "APM: 1",
  },
  {
    id: 2,
    title: "APM: 2",
  },
  {
    id: 3,
    title: "APM: 3",
  },
  {
    id: 4,
    title: "Everport: 1",
  },
  {
    id: 5,
    title: "Everport: 2",
  },
  {
    id: 6,
    title: "Everport: 3",
  },
];

class DispatcherNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTabActive: false,
      isCardActive: false,
      isPopupOpen: false,
      openPopup: false,
      hidePopup: false,
      issubCardItem: false,
      openLoad: false,
      isPopDrop: false,
      isTrackCard: false,
      isSaveOptions: false,
      isFilterTabActive: false,
      isCardSubItemActive: false,
    };
  }

  handleIsCardSubItemActive = (e, i) => {
    e.stopPropagation();
    this.setState({
      isCardSubItemActive: this.state.isCardSubItemActive === i ? null : i,
    });
  };

  handleIsFilterTab = (i) => {
    this.setState({
      isFilterTabActive: this.state.isFilterTabActive === i ? null : i,
    });
  };

  handleIsPopDrop = (event) => {
    this.setState({ isPopDrop: !this.state.isPopDrop });
  };
  openLoad = (event) => {
    document.body.classList.add("has-subheader");
    this.setState({ openLoad: true });
  };

  hideLoad = (event) => {
    document.body.classList.remove("has-subheader");
    this.setState({ openLoad: false });
  };

  openPopup = (event) => {
    document.body.classList.add("bdpopup-open");
    this.setState({ isPopupOpen: true });
  };

  hidePopup = (event) => {
    document.body.classList.remove("bdpopup-open");
    this.setState({ isPopupOpen: false });
  };

  handleTabActive = (e,i) => {
    this.setState({
      isTabActive: this.state.isTabActive === i ? null : i,
    });
  };
  handleCardActive = (e,i) => {
    e.stopPropagation();
    this.setState({
      isCardActive: this.state.isCardActive === i ? null : i,
    });
  };
  handleSubCardItem() {
    this.setState({ isSubCardItem: !this.state.isSubCardItem });
  }
  handleIsSaveOptions = (event) => {
    this.setState({ isSaveOptions: !this.state.isSaveOptions });
  };

  render() {
    return (
      <React.Fragment>
        <div className="position-relative subheader-wrapper">
          {this.state.openLoad == true ? (
            <div className="subheader">
              <div className="subheader__inner d-flex">
                <div className="d-flex flex-row align-items-center">
                  <button
                    className="btn btn-outline-light"
                    onClick={() => this.hideLoad()}
                  >
                    <IconAngleArrowLeft />
                  </button>
                  <div className="d-flex ml-3 align-items-center">
                    <h5 className="mb-0">Truck #: UJSK8223432</h5>
                    <span className="badge badge-info ml-3">
                      Enroute to Delivery load
                    </span>
                  </div>
                </div>
                <div className="ml-auto position-relative d-flex">
                  <button className="btn btn-link mr-1">
                    <IconPrinter />
                  </button>
                  <button className="btn btn-link mr-1">
                    <IconClone />
                  </button>
                  <button className="btn btn-link mr-1">
                    <IconTrash />
                  </button>
                  <button
                    className="btn btn-outline-light mr-1"
                    onClick={() => this.hideLoad()}
                  >
                    Cancel
                  </button>
                  <div className="position-relative mr-4">
                    <button
                      className="btn btn-success"
                      onClick={this.handleIsSaveOptions}
                    >
                      Save Changes
                      <IconCaretDown />
                    </button>
                    {this.state.isSaveOptions && (
                      <div className="card card--shadow-5 popdrop popdrop--right mb-0 p-3 animated">
                        <a
                          href=""
                          className="text-gray-900 rounded-3 d-flex align-items-center py-2 px-3 animated hover-bg-success-100"
                        >
                          <CustomIconSaveOutline className="mr-3" />
                          Save & Add to Customer Rates
                        </a>
                        <hr className="m-2" />
                        <a
                          href="javascript:void(0)"
                          className="text-gray-900 rounded-3 d-flex align-items-center py-2 px-3 animated hover-bg-success-100"
                        >
                          <IconSendEmail className="mr-3" />
                          Save & Send Rate Confirmation
                        </a>
                      </div>
                    )}
                  </div>
                  <button className="btn btn-outline-light mr-2">
                    <IconAngleArrowLeft />
                  </button>
                  <button className="btn btn-outline-light">
                    <IconAngleArrowRight />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <DispatcherTabs activeTab={1} />
          )}

          {this.state.openLoad == false ? (
            <div className="page-fluid">
              <div className="top-page">
                <div className="pb-15 d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <button className="btn btn-white mr-3">
                      <IconAngleArrowLeft />
                    </button>
                    <div className="d-flex align-items-center mr-3">
                      <h4 className="font-20 mb-0">Today</h4>
                      <IconCaretDown />
                    </div>
                    <button className="btn btn-white mr-3">
                      <IconAngleArrowRight />
                    </button>
                    <button className="btn btn-white">
                      <IconCalendar />
                    </button>
                  </div>
                  <Pagination className="ml-auto" />
                </div>
                {/* Cards Row */}
                <DispatcherCards />

                {/* Filter Tabs */}
                {/*  Sub Header Tabs*/}
                <ul
                  className="nav nav-compact border-0 p-0 mb-10"
                  role="tablist"
                >
                  {tabItems.map((tabItem) => (
                    <li className="nav-item" key={tabItem.id}>
                      <a
                        href="#!"
                        className={`nav-link mr-1 ${
                          this.state.isFilterTabActive === tabItem.id
                            ? "active"
                            : "rounded-3 bg-light"
                        }`}
                        onClick={() => this.handleIsFilterTab(tabItem.id)}
                      >
                        {tabItem.title}
                      </a>
                    </li>
                  ))}
                </ul>

                {/* Filter Row */}
                <DispatcherFilterRow />
              </div>
              <div
                className="table-responsive"
                style={{ height: "calc(100vh - 420px)" }}
              >
                <StaticTableContent
                  handleLoadOpen={() => {
                    this.setState({ openLoad: true });
                  }}
                />
              </div>
            </div>
          ) : (
            <LoadPage />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const DispatcherNewFunction = () => {
  useEffect(() => {
    document.body.className = "has-subheader";
    return () => {
      document.body.className = "";
    };
  });

  return <DispatcherNew />;
};

export default DispatcherNewFunction;
