import React from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import CustomSelect from "../../../../common/CustomSelect";
import { IconTrash } from "../../../../common/icons";

const options = [
  { value: "Child Support", label: "Child Support " },
  { value: "ELD", label: "ELD" },
  { value: "Loan", label: "Loan" },
  { value: "Insuranec", label: "Insuranec" },
  { value: "Parking Lot", label: "Parking Lot" },
  { value: "2 Week Advance", label: "2 Week Advance" },
];

const smallSelectStyle = {
  control: (base, state) => ({
    ...base,
    minHeight: 30,
    borderColor: "#BFCCDA",
    borderRadius: "3px",
  }),
  menu: (base) => ({
    ...base,
    innerHeight: 30,
    zIndex: 2,
  }),
  menuList: (base) => ({
    ...base,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 30,
    color: "#394E66",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    margin: "0",
    backgroundColor: "#BFCCDA",
  }),
};

const formatOptionLabel = ({ label }) => (
  <div className="d-flex align-items-center ">
    <div>{label}</div>
    <div className="ml-auto">
        <div className="text-muted">
          <IconTrash />
        </div>
      </div>
  </div>
);

const AddDeduction = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;

  return (
    <Modal show={showModal} centered size="sm">
      <Modal.Header>
        <Modal.Title>Add Deduction</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <div className="form-wrapper">
          <form>
            <div className="form-row mb-10">
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Title
                </label>
                {/* <Select
                  options={options}
                  formatOptionLabel={formatOptionLabel}

                  styles={smallSelectStyle}
                  isClearable
                  placeholder="Select"
                /> */}
                <CustomSelect
                  options={options}
                  formatOptionLabel={formatOptionLabel}
                  placeholder='Select..'
                  size="small"
                />
              </div>
              <div className="form-group col-md-12">
                <label>
                  <span className="text-danger">*</span> Description
                </label>
                <input
                  className="form-control"
                  placeholder="Enter Description"
                />
              </div>
              <div className="form-group col-md-6">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Unit of measure
                </label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select"
                  className="w-100"
                />
              </div>
              <div className="form-group col-md-6">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Math
                </label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select"
                  className="w-100"
                />
              </div>
              <div className="form-group col-md-6">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Amount
                </label>
                <input className="form-control w-150" placeholder="$0.00" />
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={onSubmit}>
          Add Deduction
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddDeduction;
