import '../../assets/SCSS/style/style.scss'
import Header from '../../common/header';
import Sidebar from '../../common/sidebar';

function Home(props) {
    return (

        <div className="position-relative">
            <div className="page-fluid mt-20">
                <button className='btn btn-primary mr-2'>
                    DrayOs
                </button>
                <button className='btn btn-primary'>
                    TrackOs
                </button>
            </div>
        </div>

    );
}

export default Home;
