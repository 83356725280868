import React, { useState } from "react";
import CustomSelect from "../../../../common/CustomSelect/index";

import {
  IconColumn,
  IconFilter,
  IconPen,
  IconPlus,
  IconTrash,
} from "../../../../common/icons/index";

const tableHeaderData = [
  "Company name",
  "Phone",
  "Main Contact name",
  "Address",
  "City",
  "State",
];
const tableData = [];
for (let i = 0; i < 20; i++) {
  tableData.push({
    id: i,
    company: "AB Supply",
    phone: "(308) 555-0121",
    mainContactName: "Darlene Robertson",
    address: "8502 Preston Rd. Inglewood, Maine 98380",
    city: "Fairfield",
    state: "Virginia",
  });
}

const StaticTableContent = () => {
  const [isPopDrop, setIsPopDrop] = useState(false);

  const handlePopDrop = (event) => {
    setIsPopDrop(!isPopDrop);
  };
  return (
    <React.Fragment>
      {/* Filter Row */}
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="form-check form-check--gray pr-30">
            <input
              className="form-check-input"
              type="checkbox"
              id="Available"
            />
            <label className="form-check-label" for="Available">
              Enabled
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                47
              </span>
            </label>
          </div>
          <div className="form-check form-check--gray pr-30">
            <input className="form-check-input" type="checkbox" id="Pending" />
            <label className="form-check-label" for="Pending">
              Disabled
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                6
              </span>
            </label>
          </div>
        </div>
        <div className="ml-auto d-flex">
          <button className="btn btn-outline-light mr-2">
            <IconFilter className="mr-2" />
            Filter
          </button>
          <div className="position-relative">
            <button className="btn btn-outline-light position-relative">
              <IconColumn />
            </button>
          </div>
        </div>
      </div>
      {/* Table */}
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 325px)" }}
      >
        <table className="table table-card table-card--ls">
          <thead>
            <tr>
              {tableHeaderData.map((headetItem) => {
                return (
                  <th scope="col" key={headetItem}>
                    {headetItem}
                  </th>
                )
              })}
              <th style={{width: "5%"}}></th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((tdata) => {
              return (
                <tr key={tdata.id}>
                  <td>
                    <a href="#" className="text-primary">
                      {tdata.company}
                    </a>
                  </td>
                  <td>{tdata.phone}</td>
                  <td>{tdata.mainContactName}</td>
                  <td>{tdata.address}</td>
                  <td>{tdata.city}</td>
                  <td>{tdata.state}</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <button className="btn btn-link text-primary">
                        <IconPen />
                      </button>
                      <button className="btn btn-link">
                        <IconTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
