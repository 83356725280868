import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import CustomSelect from '../../../../common/CustomSelect';
import { IconCheck, IconDotSeperator, IconPending, IconPlay } from '../../../../common/icons';
import GeneralInfo from './GeneralInfo';
import Permissions from './Permissions';

const options = [
  { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
  { value: 'John Doe', label: 'John Doe' },
  { value: 'Alex Mendes', label: 'Alex Mendes' },
];

const AddPermissionModal = (props) => {
  const {
    showModal = true,
    onCancel = () => { },
    onBack = () => { },
    onSubmit = () => { },
  } = props;
  const [isLoadTabActive, setisLoadTabActive] = useState(1);
  const handleLoadTabActive = (i) => {
    console.log("Clicked");
    setisLoadTabActive(isLoadTabActive === i ? null : i);
  };
  return (
    <Modal show={showModal} centered animation={false} size={isLoadTabActive === 1 ? "sm" : "fullpage"}>
      <Modal.Header className="pb-10">
        <Modal.Title>Add User</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom">
          <li className="nav-item">
            <a
              className={`nav-link ${isLoadTabActive === 1 ? "active" : ""}`}
              href="javascript:void(0)"
              onClick={() => handleLoadTabActive(1)}
            >
              <div className="nav-link__text">
                <div className="d-flex-align-item-center">
                  {isLoadTabActive === 2 ||
                    isLoadTabActive === 3 ||
                    isLoadTabActive === 4 ? (
                    <IconCheck className="mr-1" />
                  ) : (
                    <IconPlay className="mr-1" />
                  )}
                  General Info
                </div>
              </div>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${isLoadTabActive === 2 ? "active" : ""}`}
              href="javascript:void(0)"
              onClick={() => handleLoadTabActive(2)}
            >
              <div className="nav-link__text">
                <div className="d-flex-align-item-center">
                  {isLoadTabActive === 2 ? (
                    <IconPlay className="mr-2" />
                  ) : isLoadTabActive === 1 ? (
                    <IconDotSeperator className="mr-10" />
                  ) : (
                    <IconCheck className="mr-1" />
                  )}
                  Permissions
                </div>
              </div>
            </a>
          </li>
        </ul>
        {/* Tab Contents */}
        {isLoadTabActive === 1 && <GeneralInfo />}
        {isLoadTabActive === 2 && <Permissions />}
      </Modal.Body>
      <Modal.Footer>
        {isLoadTabActive === 1 && (
          <div className="">
            <button className="btn btn-close mr-30" onClick={onCancel}>
              Cancel
            </button>
            <button
              className="btn btn-success"
              onClick={() => handleLoadTabActive(2)}
            >
              Assign Permissions
            </button>
          </div>
        )}

        {isLoadTabActive === 2 && (
          <div className="">
            <button className="btn btn-close mr-30" onClick={onCancel}>
              Cancel
            </button>
            <button className="btn btn-outline-light mr-10" onClick={() => handleLoadTabActive(1)}>
              Back
            </button>
            <button
              className="btn btn-success"
              onClick={onCancel}
            >
              Confirm
            </button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AddPermissionModal;
