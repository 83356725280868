import React, { useState } from 'react';
import { IconPlus } from '../../../common/icons';
import SettingsTab from '../common-elements/SettingsTabs';
import AddPermissionModal from './elements/AddPermissionModal';

const SettingRoleManagement = () => {
  //Add Contact Modal
  const [PermissionshowModal, setPermissionShowModal] = useState(false);
  const handlePermissionShowModal = (event) => {
    setPermissionShowModal(true);
  };

  const handlePermissionHideModal = (event) => {
    setPermissionShowModal(false);
  };

  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <div className="subheader">
          <div className="subheader__inner d-flex align-items-center py-0">
            <SettingsTab
              activeTab={3}
              button={
                <button
                  className="ml-auto btn btn-success"
                  onClick={handlePermissionShowModal}
                >
                  <IconPlus className="mr-2" />
                  Assign Permission
                </button>
              }
            />
          </div>
        </div>
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-2 d-flex align-items-center ">
              <h4 className="font-20 "> Permission </h4>
            </div>
            <div className="card py-10 pl-20  mb-1">
              <div className="d-flex justify-content-start">
                <div className="">
                  <h6 className="font-16">Jane Cooper</h6>
                  <p className="font-size-regular text-gray-500">
                    michelle.rivera@example.com
                  </p>
                  <p className="font-size-regular text-gray-500">
                    +1-202-555-0167
                  </p>
                </div>
              </div>
            </div>
            <div className="card p-20 mb-2 ">
              <div className="form-row">
                <div className="col">
                  <h6 className="font-size-regular text-gray-500 mb-10">
                    Tracking
                  </h6>
                  <div className="form-check form-check">
                    <input
                      className="form-check-input rounded-circle"
                      type="checkbox"
                      id="isAll"
                      checked={true}
                    />
                    <label className="form-check-label" for="isAll">
                      Is All
                    </label>
                  </div>
                </div>
                <div className="col">
                  <h6 className="font-size-regular text-gray-500 mb-10 ">
                    Customers
                  </h6>
                  <div className="d-flex flex-column">
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="isAll"
                        checked={true}
                      />
                      <label className="form-check-label" for="isAll">
                        Is All
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="dashboard"
                        checked={true}
                      />
                      <label className="form-check-label" for="dashboard">
                        Dashboard
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="container"
                        checked={true}
                      />
                      <label className="form-check-label" for="container">
                        Container
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="calender"
                        checked={true}
                      />
                      <label className="form-check-label" for="calender">
                        Calender
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="upload"
                        checked={true}
                      />
                      <label className="form-check-label" for="upload">
                        Upload
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="customerMessaging"
                        checked={true}
                      />
                      <label
                        className="form-check-label w-25"
                        for="customerMessaging"
                      >
                        Customer Messaging
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="driveMessaging"
                        checked={true}
                      />
                      <label
                        className="form-check-label w-25"
                        for="driveMessaging"
                      >
                        Drive Messaging
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="load"
                        checked={true}
                      />
                      <label className="form-check-label" for="load">
                        Load
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="quotes"
                        checked={true}
                      />
                      <label className="form-check-label" for="quotes">
                        Quotes
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <h6 className="font-size-regular text-gray-500 mb-10 ">
                    Dashboard
                  </h6>
                  <div className="d-flex flex-column">
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="isAll"
                        checked={true}
                      />
                      <label className="form-check-label" for="isAll">
                        Is All
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="info"
                        checked={true}
                      />
                      <label className="form-check-label" for="info">
                        Info
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="billing"
                        checked={true}
                      />
                      <label className="form-check-label" for="billing">
                        Billing
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="documents"
                        checked={true}
                      />
                      <label className="form-check-label" for="documents">
                        Documents
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="payments"
                        checked={true}
                      />
                      <label className="form-check-label" for="payment">
                        Payments
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="status"
                        checked={true}
                      />
                      <label className="form-check-label" for="status">
                        Status
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="pay"
                        checked={true}
                      />
                      <label className="form-check-label" for="pay">
                        Pay
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="tracking"
                        checked={true}
                      />
                      <label className="form-check-label" for="tracking">
                        Tracking
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="serviceMessaging"
                        checked={true}
                      />
                      <label
                        className="form-check-label"
                        for="serviceMessaging"
                      >
                        ServiceMessaging
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="audit"
                        checked={true}
                      />
                      <label className="form-check-label" for="audit">
                        Audit
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <h6 className="font-size-regular text-gray-500 mb-10 ">
                    Billings
                  </h6>
                  <div className="d-flex flex-column">
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="isAll"
                        checked={true}
                      />
                      <label className="form-check-label" for="isAll">
                        Is All
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="pay"
                        checked={true}
                      />
                      <label className="form-check-label" for="pay">
                        Pay
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="settlement"
                        checked={true}
                      />
                      <label className="form-check-label" for="settlement">
                        Settlement
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="setting"
                        checked={true}
                      />
                      <label className="form-check-label" for="setting">
                        Setting
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="accountReceivable"
                        checked={true}
                      />
                      <label
                        className="form-check-label w-25"
                        for="accountReceivable"
                      >
                        Account Receivable
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="subaccountReceivable"
                        checked={true}
                      />
                      <label
                        className="form-check-label w-25"
                        for="subaccountReceivable"
                      >
                        Subaccount Receivable
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="payment"
                        checked={true}
                      />
                      <label className="form-check-label" for="payment">
                        Payment
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <h6 className="font-size-regular text-gray-500 mb-10 ">
                    Safeties
                  </h6>
                  <div className="d-flex flex-column">
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="isAll"
                        checked={true}
                      />
                      <label className="form-check-label" for="isAll">
                        Is All
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="chassis"
                        checked={true}
                      />
                      <label className="form-check-label" for="chassis">
                        Chassis
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="truck"
                        checked={true}
                      />
                      <label className="form-check-label" for="truck">
                        Truck
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="trailer"
                        checked={true}
                      />
                      <label className="form-check-label" for="trailer">
                        Trailer
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="driver"
                        checked={true}
                      />
                      <label className="form-check-label" for="driver">
                        Driver
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <h6 className="font-size-regular text-gray-500 mb-10 ">
                    Others
                  </h6>
                  <div className="d-flex flex-column">
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="isAll"
                        checked={true}
                      />
                      <label className="form-check-label" for="isAll">
                        Is All
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="permission"
                        checked={true}
                      />
                      <label className="form-check-label" for="permission">
                        Permission
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="truck"
                        checked={true}
                      />
                      <label className="form-check-label" for="truck">
                        Truck
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="trailer"
                        checked={true}
                      />
                      <label className="form-check-label" for="trailer">
                        Trailer
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="driver"
                        checked={true}
                      />
                      <label className="form-check-label" for="driver">
                        Driver
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <h6 className="font-size-regular text-gray-500 mb-10 ">
                    Container
                  </h6>
                  <div className="d-flex flex-column">
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="isAll"
                        checked={true}
                      />
                      <label className="form-check-label" for="isAll">
                        Is All
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="type"
                        checked={true}
                      />
                      <label className="form-check-label" for="type">
                        Type
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="size"
                        checked={true}
                      />
                      <label className="form-check-label" for="size">
                        Size
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="owner"
                        checked={true}
                      />
                      <label className="form-check-label" for="owner">
                        Owner
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="day"
                        checked={true}
                      />
                      <label className="form-check-label" for="day">
                        Day
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <h6 className="font-size-regular text-gray-500 mb-10 ">
                    Chassis
                  </h6>
                  <div className="d-flex flex-column">
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="isAll"
                        checked={true}
                      />
                      <label className="form-check-label" for="isAll">
                        Is All
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="type"
                        checked={true}
                      />
                      <label className="form-check-label" for="type">
                        Type
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="size"
                        checked={true}
                      />
                      <label className="form-check-label" for="size">
                        Size
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="owner"
                        checked={true}
                      />
                      <label className="form-check-label" for="owner">
                        Owner
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <h6 className="font-size-regular text-gray-500 mb-10 ">
                    Profile
                  </h6>
                  <div className="d-flex flex-column">
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="isAll"
                        checked={true}
                      />
                      <label className="form-check-label" for="isAll">
                        Is All
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="customer"
                        checked={true}
                      />
                      <label className="form-check-label" for="customer">
                        Customer
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="creditLimit"
                        checked={true}
                      />
                      <label className="form-check-label" for="creditLimit">
                        credit Limit
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="paymentTerms"
                        checked={true}
                      />
                      <label
                        className="form-check-label w-25"
                        for="paymentTerms"
                      >
                        Payment Terms
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="chassis"
                        checked={true}
                      />
                      <label className="form-check-label" for="chassis">
                        Chassis
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="truck"
                        checked={true}
                      />
                      <label className="form-check-label" for="truck">
                        Truck
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="trailer"
                        checked={true}
                      />
                      <label className="form-check-label" for="trailer">
                        Trailer
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="driver"
                        checked={true}
                      />
                      <label className="form-check-label" for="driver">
                        Driver
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <h6 className="font-size-regular text-gray-500 mb-10 ">
                    Reports
                  </h6>
                  <div className="d-flex flex-column">
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="isAll"
                        checked={true}
                      />
                      <label className="form-check-label" for="isAll">
                        Is All
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="revenue"
                        checked={true}
                      />
                      <label className="form-check-label" for="revenue">
                        Revenue
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="aging"
                        checked={true}
                      />
                      <label className="form-check-label" for="aging">
                        Aging
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="revenue"
                        checked={true}
                      />
                      <label className="form-check-label" for="revenue">
                        Revenue
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="driver"
                        checked={true}
                      />
                      <label className="form-check-label" for="driver">
                        Driver
                      </label>
                    </div>
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="report"
                        checked={true}
                      />
                      <label className="form-check-label" for="report">
                        Report
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <h6 className="font-size-regular text-gray-500 mb-10 ">
                    Settings
                  </h6>
                  <div className="d-flex flex-column">
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="setting"
                        checked={true}
                      />
                      <label className="form-check-label" for="setting">
                        Setting
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <h6 className="font-size-regular text-gray-500 mb-10 ">
                    Dispatcher
                  </h6>
                  <div className="d-flex flex-column">
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="dispatcher"
                        checked={true}
                      />
                      <label className="form-check-label" for="dispatcher">
                        Dispatcher
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <h6 className="font-size-regular text-gray-500 mb-10 ">
                    Accounts
                  </h6>
                  <div className="d-flex flex-column">
                    <div className="form-check form-check mb-1">
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        id="account"
                        checked={true}
                      />
                      <label className="form-check-label" for="account">
                        Accounts
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {PermissionshowModal && (
        <AddPermissionModal
          onCancel={handlePermissionHideModal}
          onBack={handlePermissionHideModal}
          onSubmit={handlePermissionHideModal}
        />
      )}
    </React.Fragment>
  );
};

export default SettingRoleManagement;
