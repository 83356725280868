import React, { useState } from "react";

import { IconDownload, IconSendEmail } from "../../../../common/icons/index";

const tableData = [];
for (let i = 0; i < 20; i++) {
  tableData.push({
    id: i,
    driver: "Arlene McCoy",
    loadType: "-",
    load: "POP2_M101675",
    loadDesc: "Delivered",
    date: "12/4/17",
    time: "03:48 am",
    container: "TEMU1234567",
    driverPay: 739.65,
  });
}

const options = [
  { value: "jessica.hanson@example.com", label: "jessica.hanson@example.com" },
  { value: "john.doe@example.com", label: "john.doe@example.com" },
  { value: "alex@example.com", label: "alex@example.com" },
];

const StaticTableContent = (props) => {
  const { showCustomerDetails } = props;
  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 258px)" }}
      >
        <table className="table table-card table-card--ls">
          <thead>
            <tr>
              <th>Driver</th>
              <th>Load Type</th>
              <th>Load #</th>
              <th>Load Description</th>
              <th>Date</th>
              <th>Container #</th>
              <th className="text-right">Driver Pay</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((tdata) => {
              return (
                <tr key={tdata.id}>
                  <td>
                    <div className="d-flex">
                      <div className="mr-3 align-self-center">
                        <div className="avatar-circle bg-light">
                          <div className="avatar-circle__initial ">AM</div>
                        </div>
                      </div>
                      <h6 className="mb-0 align-self-center d-flex align-items-center">
                        <a href="#" className="text-dark">
                          {tdata.driver}
                        </a>
                      </h6>
                    </div>
                  </td>
                  <td>{tdata.loadType}</td>
                  <td>{tdata.load}</td>
                  <td>{tdata.loadDesc}</td>
                  <td>
                    {tdata.date} - {tdata.time}
                  </td>
                  <td>{tdata.container}</td>
                  <td className="text-right">${tdata.driverPay}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default StaticTableContent;
