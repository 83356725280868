import React, { Component } from 'react';

import CustomSelect from '../../../common/CustomSelect';
import { IconDrag, IconFlag, IconLoadCartLeft, IconLoadCartRight, IconLoadCartRight2, IconMapMarkerLoad, IconNoteArrowRight, IconReturn, IconUploadAlt, IconDownloadAlt, IconPlus, IconCheck, IconTrash, IconCalendar, IconNotesPen } from '../../../common/icons';


const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
];
class OrderStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddPaymentModal: false,
        };
    }
    handleShowAddPaymentModal = (event) => {
        this.setState({ showAddPaymentModal: true });
    }
    handleHidePaymentModal = (event) => {
        this.setState({ showAddPaymentModal: false });
    }
    render() {
        return (
            <React.Fragment>
                <div className="tab">
                    <div className="form-row-md mt-10">
                        <div className="col" style={{ maxWidth: 220 }}>
                            <div className="card p-10 pb-10">
                                <div className="form-group mb-0">
                                    <label>Load Template</label>
                                    <CustomSelect
                                        name={'name'}
                                        options={options}
                                        value={1}
                                        isMulti={true}
                                        isClearable={true}
                                        size='small'
                                        placement='right'
                                        placeholder='Pick and Run + Drop... '
                                    />
                                </div>
                                <div className="hr-light">
                                </div>
                                <div className="form-group">
                                    <label>Drag & Drop to add legs</label>
                                    <button className="btn btn-outline-light btn-lg mb-1 d-flex align-items-center justify-content-start w-100 px-2">
                                        <IconDrag className="mr-10 text-gray-200" />
                                        <IconLoadCartRight className="mr-10" />
                                        <span>Chassis Pick</span>
                                        <IconLoadCartRight2 className="ml-auto" />
                                    </button>
                                    <button className="btn btn-outline-light btn-lg mb-1 d-flex align-items-center justify-content-start w-100 px-2">
                                        <IconDrag className="mr-10 text-gray-200" />
                                        <IconNoteArrowRight className="mr-10" />
                                        Pull Contianer
                                    </button>
                                    <button className="btn btn-outline-light btn-lg mb-1 d-flex align-items-center justify-content-start w-100 px-2">
                                        <IconDrag className="mr-10 text-gray-200" />
                                        <IconUploadAlt className="mr-10" />
                                        Hook Container
                                    </button>
                                    <button className="btn btn-outline-light btn-lg mb-1 d-flex align-items-center justify-content-start w-100 px-2">
                                        <IconDrag className="mr-10 text-gray-200" />
                                        <IconMapMarkerLoad className="mr-10" />
                                        Deliver Load
                                    </button>
                                    <button className="btn btn-outline-light btn-lg mb-1 d-flex align-items-center justify-content-start w-100 px-2">
                                        <IconDrag className="mr-10 text-gray-200" />
                                        <IconReturn />
                                        Return Container
                                    </button>
                                    <button className="btn btn-outline-light btn-lg mb-1 d-flex align-items-center justify-content-start w-100 px-2">
                                        <IconDrag className="mr-10 text-gray-200" />
                                        <IconDownloadAlt className="mr-10" />
                                        Drop Container
                                    </button>
                                    <button className="btn btn-outline-light btn-lg mb-1 d-flex align-items-center justify-content-start w-100 px-2">
                                        <IconDrag className="mr-10 text-gray-200" />
                                        <IconLoadCartLeft className="mr-10" />
                                        Chassis Termination
                                    </button>
                                    <button className="btn btn-outline-light btn-lg mb-1 d-flex align-items-center justify-content-start w-100 px-2">
                                        <IconDrag className="mr-10 text-gray-200" />
                                        <IconFlag className="mr-10" />
                                        Completed
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card p-15 card-blue mb-10">
                                <div className="d-flex mb-15 align-items-center">
                                    <div className="d-flex">
                                        <div className="avatar-circle card-blue__avatar text-white mr-10">
                                            <div className="avatar-title">01</div>
                                        </div>
                                        <CustomSelect
                                            name={'name'}
                                            className={'w-150'}
                                            options={options}
                                            value={1}
                                            isMulti={true}
                                            isClearable={true}
                                            size='small'
                                            placement='right'
                                            placeholder='Select Driver'
                                        />
                                    </div>
                                    <a href="javascript:void(0)" className=" ml-auto">
                                        <IconPlus className="mr-2" />
                                        Add Note
                                    </a>
                                </div>

                                <div className="outline-card outline-card--blue p-10 mb-1">
                                    <div className="d-flex align-items-center mb-10">
                                        <IconNoteArrowRight className="mr-10" />
                                        <span className="font-14">Pull Contianer</span>
                                        <a href="javascript:void()" className="ml-20">
                                            <IconPlus className="mr-2" />
                                            Add Note
                                        </a>
                                        <div className="ml-auto">
                                            <button className="btn btn-outline-success btn-sm">
                                                <IconCheck className="mr-2" />
                                                Arrived
                                            </button>
                                            <button className="btn btn-link text-danger">
                                                <IconTrash />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="d-flex form-row-md">
                                        <div className="form-group mb-0 col">
                                            <label>From</label>
                                            <input type="text" className="form-control" placeholder="" value="2118 Thornridge Cir. Syracuse, Connecticut 35624" disabled />
                                        </div>
                                        <div className="form-group mb-0 col" style={{ maxWidth: 200 }}>
                                            <label>Load assigned date</label>
                                            <div className="input-wrapper">
                                                <input type="text" className="form-control" placeholder="" value="Sep 27, 2021" disabled />
                                                <span className="input-icon">
                                                    <IconCalendar />
                                                </span>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="outline-card outline-card--blue p-10 mb-1">
                                    <div className="d-flex align-items-center mb-10">
                                        <IconMapMarkerLoad className="mr-10" />
                                        <span className="font-14">Deliver Load</span>
                                        <a href="javascript:void()" className="ml-20">
                                            <IconPlus className="mr-2" />
                                            Add Note
                                        </a>
                                        <div className="ml-auto">
                                            <button className="btn btn-primary btn-sm mr-2">
                                                Enroute
                                            </button>
                                            <button className="btn btn-success btn-sm mr-2">
                                                Arrived
                                            </button>
                                            <button className="btn btn-secondary btn-sm mr-2">
                                                Void Out
                                            </button>
                                            <button className="btn btn-link text-danger">
                                                <IconTrash />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="d-flex form-row-md">
                                        <div className="form-group mb-0 col">
                                            <label>From</label>
                                            <input type="text" className="form-control" placeholder="" value="2118 Thornridge Cir. Syracuse, Connecticut 35624" />
                                        </div>
                                        <div className="form-group mb-0 col" style={{ maxWidth: 200 }}>
                                            <label>Load assigned date</label>
                                            <div className="input-wrapper">
                                                <input type="text" className="form-control" placeholder="" value="Sep 27, 2021" />
                                                <span className="input-icon">
                                                    <IconCalendar />
                                                </span>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="outline-card outline-card--blue p-10 mb-1">
                                    <div className="d-flex align-items-center mb-10">
                                        <IconDownloadAlt className="mr-10" />
                                        <span className="font-14">Drop Container</span>
                                        <a href="javascript:void()" className="ml-20">
                                            <IconPlus className="mr-2" />
                                            Add Note
                                        </a>
                                        <div className="ml-auto">
                                            <button className="btn btn-primary btn-sm mr-2">
                                                Enroute
                                            </button>
                                            <button className="btn btn-success btn-sm mr-2">
                                                Arrived
                                            </button>
                                            <button className="btn btn-secondary btn-sm mr-2">
                                                Void Out
                                            </button>
                                            <button className="btn btn-link text-danger">
                                                <IconTrash />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="d-flex form-row-md">
                                        <div className="form-group mb-0 col">
                                            <label>From</label>
                                            <input type="text" className="form-control" placeholder="" value="2118 Thornridge Cir. Syracuse, Connecticut 35624" />
                                        </div>
                                        <div className="form-group mb-0 col" style={{ maxWidth: 200 }}>
                                            <label>Load assigned date</label>
                                            <div className="input-wrapper">
                                                <input type="text" className="form-control" placeholder="" value="Sep 27, 2021" />
                                                <span className="input-icon">
                                                    <IconCalendar />
                                                </span>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="card p-15 card-pink mb-10">
                                <div className="d-flex mb-15 align-items-center">
                                    <div className="d-flex">
                                        <div className="avatar-circle card-pink__avatar text-white mr-10">
                                            <div className="avatar-title">02</div>
                                        </div>
                                        <CustomSelect
                                            name={'name'}
                                            className={'w-150'}
                                            options={options}
                                            value={1}
                                            isMulti={true}
                                            isClearable={true}
                                            size='small'
                                            placement='right'
                                            placeholder='Select Driver'
                                        />
                                    </div>
                                    <a href="javascript:void(0)" className=" ml-auto">
                                        <IconPlus className="mr-2" />
                                        Add Note
                                    </a>
                                </div>

                                <div className="outline-card outline-card--pink p-10 mb-1">
                                    <div className="d-flex align-items-center mb-10">
                                        <IconNoteArrowRight className="mr-10" />
                                        <span className="font-14">Pull Contianer</span>
                                        <a href="javascript:void()" className="ml-20">
                                            <IconPlus className="mr-2" />
                                            Add Note
                                        </a>
                                        <div className="ml-auto">
                                            <button className="btn btn-outline-success btn-sm">
                                                <IconCheck className="mr-2" />
                                                Arrived
                                            </button>
                                            <button className="btn btn-link text-danger">
                                                <IconTrash />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="d-flex form-row-md">
                                        <div className="form-group mb-0 col">
                                            <label>From</label>
                                            <input type="text" className="form-control" placeholder="" value="2118 Thornridge Cir. Syracuse, Connecticut 35624" disabled />
                                        </div>
                                        <div className="form-group mb-0 col" style={{ maxWidth: 200 }}>
                                            <label>Load assigned date</label>
                                            <div className="input-wrapper">
                                                <input type="text" className="form-control" placeholder="" value="Sep 27, 2021" disabled />
                                                <span className="input-icon">
                                                    <IconCalendar />
                                                </span>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center outline-card__notes mt-10">
                                        <IconNotesPen className="mr-15 outline-card__notes--icon" />
                                        <div>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</div>
                                    </div>
                                </div>
                                <div className="outline-card outline-card--pink p-10 mb-1">
                                    <div className="d-flex align-items-center mb-10">
                                        <IconMapMarkerLoad className="mr-10" />
                                        <span className="font-14">Deliver Load</span>
                                        <a href="javascript:void()" className="ml-20">
                                            <IconPlus className="mr-2" />
                                            Add Note
                                        </a>
                                        <div className="ml-auto">
                                            <button className="btn btn-primary btn-sm mr-2">
                                                Enroute
                                            </button>
                                            <button className="btn btn-success btn-sm mr-2">
                                                Arrived
                                            </button>
                                            <button className="btn btn-secondary btn-sm mr-2">
                                                Void Out
                                            </button>
                                            <button className="btn btn-link text-danger">
                                                <IconTrash />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="d-flex form-row-md">
                                        <div className="form-group mb-0 col">
                                            <label>From</label>
                                            <input type="text" className="form-control" placeholder="" value="2118 Thornridge Cir. Syracuse, Connecticut 35624" />
                                        </div>
                                        <div className="form-group mb-0 col" style={{ maxWidth: 200 }}>
                                            <label>Load assigned date</label>
                                            <div className="input-wrapper">
                                                <input type="text" className="form-control" placeholder="" value="Sep 27, 2021" />
                                                <span className="input-icon">
                                                    <IconCalendar />
                                                </span>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="outline-card outline-card--pink p-10 mb-1">
                                    <div className="d-flex align-items-center mb-10">
                                        <IconDownloadAlt className="mr-10" />
                                        <span className="font-14">Drop Container</span>
                                        <a href="javascript:void()" className="ml-20">
                                            <IconPlus className="mr-2" />
                                            Add Note
                                        </a>
                                        <div className="ml-auto">
                                            <button className="btn btn-primary btn-sm mr-2">
                                                Enroute
                                            </button>
                                            <button className="btn btn-success btn-sm mr-2">
                                                Arrived
                                            </button>
                                            <button className="btn btn-secondary btn-sm mr-2">
                                                Void Out
                                            </button>
                                            <button className="btn btn-link text-danger">
                                                <IconTrash />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="d-flex form-row-md">
                                        <div className="form-group mb-0 col">
                                            <label>From</label>
                                            <input type="text" className="form-control" placeholder="" value="2118 Thornridge Cir. Syracuse, Connecticut 35624" />
                                        </div>
                                        <div className="form-group mb-0 col" style={{ maxWidth: 200 }}>
                                            <label>Load assigned date</label>
                                            <div className="input-wrapper">
                                                <input type="text" className="form-control" placeholder="" value="Sep 27, 2021" />
                                                <span className="input-icon">
                                                    <IconCalendar />
                                                </span>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default OrderStatus;