import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  IconBilling,
  IconHeartRate,
  IconSettings,
  IconPayment,
  IconSteering,
  IconTruck,
  IconUserBox,
  IconMap,
  IconMonitor,
  IconUserAlt,
} from "../icons";
import { LogoPortPro, LogoPortProSmall } from "../customIcons";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuHovered: false,
      isMenuClicked: null,
      isMenuOpen: false,
    };
    this.handleMenuHover = this.handleMenuHover.bind(this);
  }
  handleSmartRoute(getRoute) {
    localStorage.setItem("role", getRoute);
    window.location.replace("/");
  }
  handleMenuHover() {
    this.setState((prevState) => ({
      isMenuHovered: !prevState.isMenuHovered,
      isMenuClicked:
        prevState.isMenuHovered && prevState.isMenuClicked
          ? null
          : prevState.isMenuClicked,
    }));
  }
  handleMenuClick = (i) => {
    this.setState({
      isMenuClicked: this.state.isMenuClicked === i ? null : i,
    });
  };
  render() {
    return (
      <aside
        className={`left-sidebar ${
          this.state.isMenuHovered ? "ishovered" : ""
        }`}
        onMouseEnter={this.handleMenuHover}
        onMouseLeave={this.handleMenuHover}
      >
        {/* Sidebar scroll*/}
        {/* Sidebar navigation*/}
        <nav className="sidebar-nav">
          <div className="d-flex align-items-center justify-content-between">
            <a className="navbar-brand" href="javascript:void(0)">
              {/* Logo Expand */}
              <LogoPortPro className="navbar-brand__logo-big" />
              {/* Logo Small */}
              <LogoPortProSmall className="navbar-brand__logo-small" />
            </a>
          </div>
          <div id="sidebarnav" className="sidebar-content">
            <Link
              className="sidebar-item"
              to="/track"
              activeClassName={"active"}
            >
              <div className="sidebar-link">
                <IconMap />
                <span className="hide-menu">Tracking</span>
              </div>
            </Link>
            <Link
              className="sidebar-item"
              to="/Dispatcher"
              activeClassName="active"
            >
              <div className="sidebar-link">
                <IconMonitor />
                <span className="hide-menu">Dispatcher</span>
              </div>
            </Link>
            {/* This link has first level Dropdown */}
            <div
              className={`sidebar-item ${
                this.state.selected == 1 ? "active" : ""
              } ${this.state.isMenuClicked === 1 ? "isclicked" : ""}`}
              onClick={() => this.handleMenuClick(1)}
            >
              <div className="sidebar-link has-arrow">
                <IconUserAlt />
                <span className="hide-menu">Customer Service</span>
              </div>
              <div aria-expanded="false" className="collapse first-level">
                <Link
                  className="first-level__link"
                  to="/customer-dashboard"
                  activeClassName={"active"}
                >
                  Customer Dashboard
                </Link>
                <Link
                  className="first-level__link"
                  to="/customerServices/loads"
                  activeClassName={"active"}
                >
                  Load
                </Link>
                <Link
                  className="first-level__link"
                  to="/customerServices/quotes"
                  activeClassName={"active"}
                >
                  Quotes
                </Link>
                <Link
                  className="first-level__link"
                  to="/customerService/dropped-containers"
                  activeClassName={"active"}
                >
                  Yard Check
                </Link>
                <Link
                  className="first-level__link"
                  to="/customerServices/tender"
                  activeClassName={"active"}
                >
                  Tendered loads
                </Link>
                <Link
                  className="first-level__link"
                  to="/customerService/calendar"
                  activeClassName={"active"}
                >
                  Load Calendar
                </Link>
                <Link
                  className="first-level__link"
                  to="/customerService/bulk-uploads"
                  activeClassName={"active"}
                >
                  Bulk Uploads
                </Link>
                <Link
                  className="first-level__link"
                  to="/customerService/customer-messaging"
                  activeClassName={"active"}
                >
                  Messaging
                </Link>
                <Link
                  className="first-level__link"
                  to="/customerService/deleted-loads"
                  activeClassName={"active"}
                >
                  Deleted Loads
                </Link>
              </div>
            </div>

            <div
              className={`sidebar-item ${
                this.state.selected == 2 ? "active" : ""
              } ${this.state.isMenuClicked === 2 ? "isclicked" : ""}`}
              onClick={() => this.handleMenuClick(2)}
            >
              <div className="sidebar-link has-arrow">
                <IconBilling />
                <span className="hide-menu">Accounts Receivable</span>
              </div>
              <div
                aria-expanded="false"
                className={`first-level ${
                  this.state.isMenuOpen ? "collapse in" : "collapse"
                } `}
              >
                <Link
                  className="first-level__link"
                  to="/billing/accounts-receivable"
                  activeClassName={"active"}
                >
                  Dashboard
                </Link>
                <Link
                  className="first-level__link"
                  to="/billing/batch-apply-payments"
                  activeClassName={"active"}
                >
                  Apply Payments
                </Link>
                <Link
                  className="first-level__link"
                  to="/billing/sub-accounts-receivable"
                  activeClassName={"active"}
                >
                  Subaccounts Receivable
                </Link>
                <Link
                  className="first-level__link"
                  to="/billing/aging"
                  activeClassName={"active"}
                >
                  Aging
                </Link>
                <Link
                  className="first-level__link"
                  to="/billing/chassis-audit"
                  activeClassName={"active"}
                >
                  Chassis Audit
                </Link>
                <Link
                  className="first-level__link"
                  to="/billing/desynced-quickbooks"
                  activeClassName={"active"}
                >
                  Problem Invoices
                </Link>
              </div>
            </div>

            <div
              className={`sidebar-item ${
                this.state.selected == 3 ? "active" : ""
              } ${this.state.isMenuClicked === 3 ? "isclicked" : ""}`}
              onClick={() => this.handleMenuClick(3)}
            >
              <div className="sidebar-link has-arrow">
                <IconPayment />
                <span className="hide-menu">Accounts Payable</span>
              </div>
              <div
                aria-expanded="false"
                className={`first-level ${
                  this.state.isMenuOpen ? "collapse in" : "collapse"
                } `}
              >
                <Link
                  className="first-level__link"
                  to="/accountpayable/driver-pay"
                  activeClassName={"active"}
                >
                  Driver Pay
                </Link>
                <Link
                  className="first-level__link"
                  to="/accountpayable/driver-settlements"
                  activeClassName={"active"}
                >
                  Driver Settlements
                </Link>
                <Link
                  className="first-level__link"
                  to="/accountpayable/dynamic-pricing"
                  activeClassName={"active"}
                >
                  Dynamic Expenses
                </Link>
              </div>
            </div>

            <div
              className={`sidebar-item ${
                this.state.selected == 4 ? "active" : ""
              } ${this.state.isMenuClicked === 4 ? "isclicked" : ""}`}
              onClick={() => this.handleMenuClick(4)}
            >
              <div className="sidebar-link has-arrow">
                <IconSteering />
                <span className="hide-menu">Driver</span>
              </div>
              <div
                aria-expanded="false"
                className={`first-level ${
                  this.state.isMenuOpen ? "collapse in" : "collapse"
                } `}
              >
                <Link
                  className="first-level__link"
                  to="/driver/driver-profiles"
                  activeClassName={"active"}
                >
                  Driver Profiles
                </Link>
                <Link
                  className="first-level__link"
                  to="/driver/assign-truck"
                  activeClassName={"active"}
                >
                  Trucks Assignment
                </Link>
                <Link
                  className="first-level__link"
                  to="/driver/truck-owner"
                  activeClassName={"active"}
                >
                  Truck Owner
                </Link>
                <Link
                  className="first-level__link"
                  to="/driver/driver-pay-rates"
                  activeClassName={"active"}
                >
                  Driver Pay Rates
                </Link>
                <Link
                  className="first-level__link"
                  to="/driver/driver-deduction-rate"
                  activeClassName={"active"}
                >
                  Driver Deduction Rates
                </Link>
              </div>
            </div>

            <div
              className={`sidebar-item ${
                this.state.selected == 5 ? "active" : ""
              } ${this.state.isMenuClicked === 5 ? "isclicked" : ""}`}
              onClick={() => this.handleMenuClick(5)}
            >
              <div className="sidebar-link has-arrow">
                <IconTruck />
                <span className="hide-menu">Equipments</span>
              </div>
              <div
                aria-expanded="false"
                className={`first-level ${
                  this.state.isMenuOpen ? "collapse in" : "collapse"
                } `}
              >
                <Link
                  className="first-level__link"
                  to="/equipment/truck-profiles"
                  activeClassName={"active"}
                >
                  Truck Profile
                </Link>
                <Link
                  className="first-level__link"
                  to="/equipment/chassis"
                  activeClassName={"active"}
                >
                  Chasis Profile
                </Link>
                <Link
                  className="first-level__link"
                  to="/equipment/trailers"
                  activeClassName={"active"}
                >
                  Trailer Profile
                </Link>
              </div>
            </div>

            <div
              className={`sidebar-item ${
                this.state.selected == 6 ? "active" : ""
              } ${this.state.isMenuClicked === 6 ? "isclicked" : ""}`}
              onClick={() => this.handleMenuClick(6)}
            >
              <div className="sidebar-link has-arrow">
                <IconUserBox />
                <span className="hide-menu">Customers</span>
              </div>
              <div
                aria-expanded="false"
                className={`first-level ${
                  this.state.isMenuOpen ? "collapse in" : "collapse"
                } `}
              >
                <Link
                  className="first-level__link"
                  to="/customers/profile/customer"
                  activeClassName={"active"}
                >
                  Customer Profile
                </Link>
                <Link
                  className="first-level__link"
                  to="/customers/customer-rates"
                  activeClassName={"active"}
                >
                  Customer Rate
                </Link>
                <Link
                  className="first-level__link"
                  to="/customer/profile/group-customer"
                  activeClassName={"active"}
                >
                  Group Customer
                </Link>
              </div>
            </div>

            <div
              className={`sidebar-item ${
                this.state.selected == 7 ? "active" : ""
              } ${this.state.isMenuClicked === 7 ? "isclicked" : ""}`}
              onClick={() => this.handleMenuClick(7)}
            >
              <div className="sidebar-link has-arrow">
                <IconHeartRate />
                <span className="hide-menu">Reports</span>
              </div>
              <div
                aria-expanded="false"
                className={`first-level ${
                  this.state.isMenuOpen ? "collapse in" : "collapse"
                } `}
              >
                {/* <Link className="first-level__link" to="/reports/aging" activeClassName={"active"}>
                                    Aging
                                </Link> */}
                <Link
                  className="first-level__link"
                  to="/reports/charge"
                  activeClassName={"active"}
                >
                  Charge
                </Link>
                {/* <Link className="first-level__link" to="/reports/aging" activeClassName={"active"}>
                                    Aging
                                </Link> */}
                <Link
                  className="first-level__link"
                  to="/reports/customer-revenue"
                  activeClassName={"active"}
                >
                  Customer Revenue
                </Link>
                <Link
                  className="first-level__link"
                  to="/reports/loads"
                  activeClassName={"active"}
                >
                  Loads
                </Link>
                <Link
                  className="first-level__link"
                  to="/reports/productivity"
                  activeClassName={"active"}
                >
                  Productivity
                </Link>
                <Link
                  className="first-level__link"
                  to="/reports/vessel"
                  activeClassName={"active"}
                >
                  Vessel
                </Link>
                <Link
                  className="first-level__link"
                  to="/reports/lfd"
                  activeClassName={"active"}
                >
                  LFD
                </Link>
                <Link
                  className="first-level__link"
                  to="/reports/clock-report"
                  activeClassName={"active"}
                >
                  Clock Report
                </Link>
                <Link
                  className="first-level__link"
                  to="/reports/load-master-report"
                  activeClassName={"active"}
                >
                  Load Master Report
                </Link>
              </div>
            </div>

            <div
              className={`sidebar-item ${
                this.state.selected == 8 ? "active" : ""
              } ${this.state.isMenuClicked === 8 ? "isclicked" : ""}`}
              onClick={() => this.handleMenuClick(8)}
            >
              <div className="sidebar-link has-arrow">
                <IconSettings />
                <span className="hide-menu">Settings</span>
              </div>
              <div
                aria-expanded="false"
                className={`first-level ${
                  this.state.isMenuOpen ? "collapse in" : "collapse"
                } `}
              >
                <Link
                  className="first-level__link"
                  to="/settings/notifications"
                  activeClassName={"active"}
                >
                  Notification
                </Link>
                <Link
                  className="first-level__link"
                  to="/settings/permission"
                  activeClassName={"active"}
                >
                  Permission
                </Link>
                <Link
                  className="first-level__link"
                  to="/settings/role-management"
                  activeClassName={"active"}
                >
                  Role Management
                </Link>
                <Link
                  className="first-level__link"
                  to="/settings/general-expense"
                  activeClassName={"active"}
                >
                  Expense
                </Link>
                <Link
                  className="first-level__link"
                  to="/other/container/owner"
                  activeClassName={"active"}
                >
                  Container Settings
                </Link>
                <Link
                  className="first-level__link"
                  to="/other/chassis/owner"
                  activeClassName={"active"}
                >
                  Chassis Settings
                </Link>
                <Link
                  className="first-level__link"
                  to="/settings/commodity-profile"
                  activeClassName={"active"}
                >
                  Commodity Settings
                </Link>
                <Link
                  className="first-level__link"
                  to="/settings/commodity-terminal"
                  activeClassName={"active"}
                >
                  Terminal Settings
                </Link>
                <Link
                  className="first-level__link"
                  to="/settings/commodity-dispatch-color"
                  activeClassName={"active"}
                >
                  Dispatch Color Settings
                </Link>
                <Link
                  className="first-level__link"
                  to="/settings/carrier-group"
                  activeClassName={"active"}
                >
                  Group Settings
                </Link>
                {/* Super Admin Pages */}
                <Link
                  className="first-level__link"
                  to="/settings/general-info"
                  activeClassName={"active"}
                >
                  General Info
                </Link>
                <Link
                  className="first-level__link"
                  to="/settings/equipment-parameters"
                  activeClassName={"active"}
                >
                  Equipment Parameters
                </Link>
                <Link
                  className="first-level__link"
                  to="/settings/document-configuration"
                  activeClassName={"active"}
                >
                  Document Configuration
                </Link>
                <Link
                  className="first-level__link"
                  to="/settings/email-configuration"
                  activeClassName={"active"}
                >
                  Email Configuration
                </Link>
              </div>
            </div>
            <div
              className={`sidebar-item ${
                this.state.selected == 9 ? "active" : ""
              } ${this.state.isMenuClicked === 9 ? "isclicked" : ""}`}
              onClick={() => this.handleMenuClick(9)}
            >
              <div className="sidebar-link has-arrow">
                <i className="uil uil-files-landscapes-alt"></i>
                <span className="hide-menu">Documentation</span>
              </div>
              <div
                aria-expanded="false"
                className={`first-level ${
                  this.state.isMenuOpen ? "collapse in" : "collapse"
                } `}
              >
                <Link
                  className="first-level__link"
                  to="/documentation/icons"
                  activeClassName={"active"}
                >
                  Icons
                </Link>
                <Link
                  className="first-level__link"
                  to="/documentation/loader"
                  activeClassName={"active"}
                >
                  Loader
                </Link>
              </div>
            </div>
            <Link
              className="sidebar-item"
              to="/change-color"
              activeClassName="active"
            >
              <div className="sidebar-link">
                <IconMonitor />
                <span className="hide-menu">Change Color</span>
              </div>
            </Link>
            <div
              className={`sidebar-item ${
                this.state.selected == "goto" ? "active" : ""
              } ${this.state.isMenuClicked === "goto" ? "isclicked" : ""}`}
              onClick={() => this.handleMenuClick("goto")}
            >
              <div className="sidebar-link has-arrow">
                <i className="uil uil-files-landscapes-alt"></i>
                <span className="hide-menu">Go To</span>
              </div>
              <div
                aria-expanded="false"
                className={`first-level ${
                  this.state.isMenuOpen ? "collapse in" : "collapse"
                } `}
              >
                <Link
                  className="first-level__link"
                  onClick={() => this.handleSmartRoute("trackos")}
                  activeClassName={"active"}
                  to="/"
                >
                  TrackOs
                </Link>
                <Link
                  className="first-level__link"
                  onClick={() => this.handleSmartRoute("shipos")}
                  activeClassName={"active"}
                  to="/"

                >
                  ShipOs
                </Link>
              </div>
            </div>
          </div>  
        </nav>
      </aside>
    );
  }
}

export default Sidebar;
