import React, { useState } from 'react';
import BillingTabs from '../common-elements/BillingTabs';
import SearchFilter from './elements/SearchFilter';
import StaticTableContent from './elements/StaticTableContent';
import TopPage from './elements/TopPage';

const AccountsReceivable = () => {
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <BillingTabs activeTab={1} />
        </div>
      </div>
      <div className="page-fluid">
        <TopPage />
        <SearchFilter />
        <StaticTableContent />
      </div>
    </div>
  );
};

export default AccountsReceivable;
