import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { IconBilling, Icondots } from '../../../common/icons';

const chartoptions = {
  chart: {
    type: 'bar',
    events: {
      click: function (chart, w, e) {},
    },
    height: 350,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '80%',
      endingShape: 'rounded',
      color: '#367BF6',
      borderRadius: 3,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
  },

  style: {
    colors: [],
  },
  colors: ['#367BF6', '#864CCC'],

  xaxis: {
    tooltip: {
      enabled: false,
    },
    categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],

    labels: {
      show: true,

      style: {
        colors: '#A3A3A3',
        fontSize: '10px',
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },

  fill: {
    opacity: 1,
  },
  legend: {
    show: false,
  },

  yaxis: {
    axisBorder: {
      show: true,
      offsetX: 0,
    },
    labels: {
      show: true,

      style: {
        colors: '#A3A3A3',
        fontSize: '10px',
      },
    },
    axisTicks: {
      show: false,
    },
  },
  tooltip: {
    enabled: true,
    enabledOnSeries: undefined,
    shared: false,
    followCursor: false,
    intersect: true,
    inverseOrder: false,
    theme: 'dark',
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      return (
        '<div class="arrow_box">' +
        '<span class="text-white">' +
        'Revenue' +
        ': ' +
        series[seriesIndex][dataPointIndex] +
        '</span>' +
        '<span class="badge badge-sm badge-accent-100 ml-1 ">' +
        '5.8%' +
        ' ' +
        '</span>' +
        '<div class="mt-1 text-gray-300">' +
        '21 November, 2021' +
        '</div>' +
        '</div>'
      );
    },
    style: {
      fontSize: '12px',
      fontFamily: 'Inter',
    },
    onDatasetHover: {
      highlightDataSeries: false,
    },
    x: {
      show: true,
      // format: 'dd MMM',
      // formatter: undefined,
    },
    y: {
      show: true,
      // formatter: undefined,
      // title: {
      //   formatter: (seriesName) => seriesName,
      // },
    },

    marker: {
      show: false,
    },
    items: {
      display: 'flex',
    },
    fixed: {
      enabled: false,
      position: 'topRight',
      offsetX: 0,
      offsetY: 0,
    },
  },
};

const chartseries = [
  {
    data: [60, 25, 10, 65, 50, 40, 30, 45, 20],
  },
  {
    data: [70, 75, 10, 25, 60, 50, 55, 48, 54],
  },
];

const BarChart = () => {
  return (
    <>
      <div className="card mb-20">
        <div className="d-flex justify-content-between align-items-center pb-15 px-20 pt-20">
          <div className="d-flex flex-column mr-50 ">
            <div className="d-flex mb-1">
              <div className="mr-1 align-self-center">
                <div
                  className="bg-primary p-1 mb-1 mr-10 rounded-5"
                  style={{ height: '30px' }}
                >
                  <IconBilling className="text-white" />
                </div>
              </div>
              <div className="font-16 align-self-center font-weight-500">
                Total Completed
              </div>
            </div>
            <div className="d-flex mb-1">
              <div className="mr-1 align-self-center font-30 font-weight-500">
                417
              </div>
              <div className="d-flex flex-column">
                <div className="font-12 text-muted align-self-center font-weight-500">
                  Past Week
                </div>
                <div className="d-flex">
                  <div className="text-accent-500 mr-1">124</div>
                  <div className=" badge badge-sm badge-accent-100 px-1 rounded-10">
                    +5.8%
                  </div>
                </div>
              </div>
              <div className=""></div>
            </div>
          </div>
          <div className="d-flex flex-column ">
            <div className="d-flex mb-1">
              <div className="mr-1 align-self-center">
                <div
                  className="bg-purple p-1 mb-1 mr-10 rounded-5"
                  style={{ height: '30px' }}
                >
                  <IconBilling className="text-white" />
                </div>
              </div>
              <div className="font-16 align-self-center font-weight-500">
                Total Invoiced
              </div>
            </div>
            <div className="d-flex mb-1">
              <div className="mr-1 align-self-center font-30 font-weight-500">
                397
              </div>
              <div className="d-flex flex-column">
                <div className="font-12 text-muted align-self-center font-weight-500">
                  Past Week
                </div>
                <div className="d-flex">
                  <div className="text-accent-500 mr-1">85</div>
                  <div className=" badge badge-sm badge-danger-light px-1 rounded-10">
                    -5.8%
                  </div>
                </div>
              </div>
              <div className=""></div>
            </div>
          </div>
          <div className="ml-auto">
            <Icondots className="text-muted" />
          </div>
        </div>
        <div className="chart-graph px-20 pb-20">
          <div id="chart">
            <ReactApexChart
              options={chartoptions}
              series={chartseries}
              type="bar"
              height={350}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BarChart;
