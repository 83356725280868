import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomCreatableSelect from "../../../../common/CustomSelect/creatable";

const AddCarrierGroup = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;
  const [checked, setChecked] = useState(false);
  const [checkedSpecificDate, setCheckedSpecificDate] = useState(false);

  return (
    <Modal show={showModal} centered animation={false} size="sm">
      <Modal.Header>
        <Modal.Title>Add Carrier Group</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="form-wrapper">
          <form>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Career Group
                </label>
                <CustomCreatableSelect
                  name={"name"}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Enter Carrier Group names"
                  className="w-100"
                />
              </div>
            </div>
            <hr />
            <div className="form-row">
              <div className="col-md-12 form-group">
                <div className="form-check pl-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="commodityRestricted"
                  />
                  <label className="form-check-label" for="">
                    Commodity Restricted
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-success" onClick={onSubmit}>
          Add Carrier Group
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCarrierGroup;
