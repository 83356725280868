import React, { useState } from 'react';
import BillingTabs from '../common-elements/BillingTabs';
import { CustomIconUploadDocument } from '../../../common/customIcons';
import { IconPlus, IconUpload, IconDownload } from '../../../common/icons';
import AddUploadModal from './AddUploadModal';

const ChassisAudit = () => {
  const [UploadshowModal, setUploadShowModal] = useState(false);
  const handleUploadShowModal = (event) => {
    setUploadShowModal(true);
  };

  const handleUploadHideModal = (event) => {
    setUploadShowModal(false);
  };
  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <div className="subheader">
          <div className="subheader__inner d-flex align-items-center py-0">
            <BillingTabs
              activeTab={5}
              button={
                <div className="d-flex">
                  <button className="mr-2 btn btn-outline-light ">
                    <IconDownload className="mr-2" />
                    Downlaod Example
                  </button>
                  <button className="ml-auto btn btn-success">
                    <IconPlus className="mr-2" />
                    Upload CVS
                  </button>
                </div>
              }
            />
          </div>
        </div>
        <div className="page-fluid">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: 'calc(100vh - 150px)' }}
          >
            <div className=" d-flex flex-column align-items-center">
              <div className="mb-30">
                <CustomIconUploadDocument className="" />
              </div>

              <span className="mb-10">Drop and Drag CVS file here or...</span>
              <button className="btn btn-white" onClick={handleUploadShowModal}>
                <IconUpload className="mr-10" />
                Select file...
              </button>
            </div>
            <div className=" d-flex flex-column align-items-center"></div>
          </div>
          {UploadshowModal && (
            <AddUploadModal onCancel={handleUploadHideModal} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChassisAudit;
