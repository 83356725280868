import React, { useState } from 'react';
import {
  IconCart,
  IconHomeCheck,
  IconSteering,
  IconTruck,
  IconUsers,
} from '../../common/icons/index';
import AddCustomer from './AddCustomer';
import AddDriver from './AddDriver';
import AddTrailer from './AddTrailer';
import AddTruck from './AddTruck';
import AddChassis from './AddChassis';

const QuickAdd = () => {
  const [quickAddOptionModal, setQuickAddOptionModal] = useState(null);

  const showQuickAddOptionModal = (i) => {
    setQuickAddOptionModal(quickAddOptionModal === i ? null : i);
  };

  const hideQuickAddOptionModal = (i) => {
    setQuickAddOptionModal(quickAddOptionModal === i ? i : null);
  };

  return (
    <React.Fragment>
      <div className="card card--shadow-5 popdrop popdrop--bottom-no-space popdrop--right mb-0 p-2 animated w-200">
        <a
          href="javascript:void(0)"
          className="text-gray-900 rounded-3 d-flex align-items-center hover-gray-50 p-2 animated"
          onClick={() => showQuickAddOptionModal(1)}
        >
          <IconUsers className="mr-3 wh-20px" />
          Add Customer
        </a>
        <a
          href="javascript:void(0)"
          className="text-gray-900 rounded-3 d-flex align-items-center hover-gray-50 p-2 animated"
          onClick={() => showQuickAddOptionModal(2)}
        >
          <IconHomeCheck className="mr-3 wh-20px" />
          Add Chassis
        </a>
        <a
          href="javascript:void(0)"
          className="text-gray-900 rounded-3 d-flex align-items-center hover-gray-50 p-2 animated"
          onClick={() => showQuickAddOptionModal(3)}
        >
          <IconTruck className="mr-3 wh-20px" />
          Add Truck
        </a>
        <a
          href="javascript:void(0)"
          className="text-gray-900 rounded-3 d-flex align-items-center hover-gray-50 p-2 animated"
          onClick={() => showQuickAddOptionModal(4)}
        >
          <IconCart className="mr-3 wh-20px" />
          Add Trailer
        </a>
        <a
          href="javascript:void(0)"
          className="text-gray-900 rounded-3 d-flex align-items-center hover-gray-50 p-2 animated"
          onClick={() => showQuickAddOptionModal(5)}
        >
          <IconSteering className="mr-3 wh-20px" />
          Add Driver
        </a>
      </div>
      {/* Quick Add Options Modals */}
      {quickAddOptionModal === 1 && (
        <AddCustomer
          showModal={showQuickAddOptionModal}
          onCancel={hideQuickAddOptionModal}
        />
      )}
      {quickAddOptionModal === 2 && (
        <AddChassis
          showModal={showQuickAddOptionModal}
          onCancel={hideQuickAddOptionModal}
        />
      )}
      {quickAddOptionModal === 3 && (
        <AddTruck
          showModal={showQuickAddOptionModal}
          onCancel={hideQuickAddOptionModal}
        />
      )}
      {quickAddOptionModal === 4 && (
        <AddTrailer
          showModal={showQuickAddOptionModal}
          onCancel={hideQuickAddOptionModal}
        />
      )}
      {quickAddOptionModal === 5 && (
        <AddDriver
          showModal={showQuickAddOptionModal}
          onCancel={hideQuickAddOptionModal}
        />
      )}
    </React.Fragment>
  );
};

export default QuickAdd;
