import React, { useState } from "react";
import CustomSelect from "../../../../common/CustomSelect/index";

import {
  IconColumn,
  IconFilter,
  IconPen,
  IconPlus,
  IconTrash,
} from "../../../../common/icons/index";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const columns = [
  "Customer",
  "Port/Shipper",
  "Consignee",
  "ZIP",
  "Base price",
  "Extra Prices",
  "Total Prices",
];

const data = [
  {
    id: 1,
    customer: `Albert Flores`,
    portShipper: "Sea Lead Shipping",
    consignee: "TS Lines",
    zip: 45785,
    basePrice: 473.85,
    extraPrice: 576.28,
    totalPrice: 446.61,
  },
  {
    id: 2,
    customer: `Zim Integrated Shipping Services`,
    portShipper: "Transworld Group",
    consignee: "Yang Ming Marine Transport Corporation",
    zip: 40322,
    basePrice: 782.01,
    extraPrice: 854.08,
    totalPrice: 406.27
  },
  {
    id: 3,
    customer: `COSCO`,
    portShipper: "IRISL Group",
    consignee: "Zhonggu Logistics Corporation",
    zip: 85486,
    basePrice: 630.44,
    extraPrice: 710.68,
    totalPrice: 169.43,
  },
  {
    id: 4,
    customer: `Sinokor Merchant Marine`,
    portShipper: "Sinotrans",
    consignee: "Hapag-Lloyd",
    zip: 35697,
    basePrice: 106.58,
    extraPrice: 589.99,
    totalPrice: 450.54,
  },
  {
    id: 5,
    customer: `Arkas Container Transport`,
    portShipper: "Matson",
    consignee: "Pacific International Lines",
    zip: 73143,
    basePrice: 106.58,
    extraPrice: 328.85,
    totalPrice: 779.58,
  },
  {
    id: 6,
    customer: `HMM Co. Ltd.`,
    portShipper: "Global Feeder Shipping LLC",
    consignee: "Evergreen Marine Corporation",
    zip: 42574,
    basePrice: 396.84,
    extraPrice: 490.51,
    totalPrice: 601.13,
  },
  {
    id: 7,
    customer: `Regional Container Lines`,
    portShipper: "SM Line",
    consignee: "Maersk Line",
    zip: 44322,
    basePrice: 928.41,
    extraPrice: 293.01,
    totalPrice: 202.87,
  },
  {
    id: 8,
    customer: `Korea Marine Transport Corporation`,
    portShipper: "CMA CGM",
    consignee: "Wan Hai Lines",
    zip: 19897,
    basePrice: 943.65,
    extraPrice: 105.55,
    totalPrice: 351.02,
  },
  {
    id: 9,
    customer: `Zim Integrated Shipping Services`,
    portShipper: "Transworld Group",
    consignee: "Yang Ming Marine Transport Corporation",
    zip: 40322,
    basePrice: 782.01,
    extraPrice: 854.08,
    totalPrice: 406.27
  },
  {
    id: 10,
    customer: `COSCO`,
    portShipper: "IRISL Group",
    consignee: "Zhonggu Logistics Corporation",
    zip: 85486,
    basePrice: 630.44,
    extraPrice: 710.68,
    totalPrice: 169.43,
  },
];

const StaticTableContent = () => {
  const [isPopDrop, setIsPopDrop] = useState(false);

  const handlePopDrop = (event) => {
    setIsPopDrop(!isPopDrop);
  };
  return (
    <React.Fragment>
      {/* Search Filter */}
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>
        <div className="d-flex">
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select Customer"
            className="w-150 z-2 mr-2"
          />
          <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select Shipper"
            className="w-150 z-2 mr-2"
          />
        </div>
        <div className="ml-auto d-flex">
          <button className="btn btn-outline-light mr-2">
            <IconFilter className="mr-2" />
            Filter
          </button>
          <div className="position-relative">
            <button className="btn btn-outline-light position-relative">
              <IconColumn />
            </button>
          </div>
        </div>
      </div>
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 327px)" }}
      >
        <table className="table table-card">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
              <th style={{ width: "2%" }}></th>
              <th style={{ width: "2%" }}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((datum) => {
              return (
                <tr key={datum.id}>
                  <td>{datum.customer}</td>
                  <td>{datum.portShipper}</td>
                  <td>{datum.consignee}</td>
                  <td>{datum.zip}</td>
                  <td className="text-right">${datum.basePrice}</td>
                  <td className="text-right">${datum.extraPrice}</td>
                  <td className="text-right">${datum.totalPrice}</td>
                  <td>
                    <button className="btn btn-link">
                      <IconPen className="text-primary" />
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-link">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
