import React, { useState } from "react";
import { IconTrash, IconReset, IconHot } from "../../../common/icons/index";

const TrackOsImportAvailabilityBatchActions = (props) => {
  const [showPopOver, setshowPopOver] = useState(false);
  const handlePopOver = (i, e) => {
    e.preventDefault();
    setshowPopOver(showPopOver === i ? null : i);
  };
  return (
    <React.Fragment>
      <div className="batch-menu mx-auto d-flex justify-content-between px-15">
        <div className="d-flex align-items-center">
          <div className="avatar-circle avatar-sm bg-white mr-10">5</div>
          <div className="text-gray-200">Selected</div>
        </div>
        <div className="d-flex">
          <div className="position-relative">
            <div className={`batch-menu__item`}>
              <IconHot className="batch-menu__itemicon" />
              <div className="batch-menu__itemtext">Haz Hot</div>
            </div>
          </div>
          <div className="position-relative">
            <div className={`batch-menu__item`}>
              <IconTrash className="batch-menu__itemicon" />
              <div className="batch-menu__itemtext">Remove Selected</div>
            </div>
          </div>
          <div className="position-relative">
            <div className={`batch-menu__item`}>
              <IconReset className="batch-menu__itemicon" />
              <div className="batch-menu__itemtext">Refresh Selected Items</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TrackOsImportAvailabilityBatchActions;
