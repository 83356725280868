import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import CustomCreatableSelect from '../../../../common/CustomSelect/creatable';
import CustomSelect from '../../../../common/CustomSelect/index';

import { IconDownload, IconSendEmail } from '../../../../common/icons/index';

const columns = [
  'Invoice',
  'Driver',
  'Customer',
  'Load Status',
  'Delivered',
  'Container',
  'Reference #',
  'BOL/BKG',
  'Port',
  'Bill date ',
  'Invoice amount',
  'Payment received',
  'Outstanding',
  '',
];

const data = [
  {
    id: 1,
    settlement: 'POP2_M100520',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    status: 'Pending',
    date: '06 Jul',
    time: '08:50pm',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    billDate: '06/11/21',
    invoiceAmt: '767.50',
    payment: '739.65',
    outstanding: '105.55',
  },
  {
    id: 2,
    settlement: 'POP2_M100520',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    status: 'Pending',
    date: '06 Jul',
    time: '08:50pm',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    billDate: '06/11/21',
    invoiceAmt: '767.50',
    payment: '739.65',
    outstanding: '105.55',
  },
  {
    id: 3,
    settlement: 'POP2_M100520',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    status: 'Pending',
    date: '06 Jul',
    time: '08:50pm',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    billDate: '06/11/21',
    invoiceAmt: '767.50',
    payment: '739.65',
    outstanding: '105.55',
  },
  {
    id: 4,
    settlement: 'POP2_M100520',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    status: 'Pending',
    date: '06 Jul',
    time: '08:50pm',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    billDate: '06/11/21',
    invoiceAmt: '767.50',
    payment: '739.65',
    outstanding: '105.55',
  },
  {
    id: 5,
    settlement: 'POP2_M100520',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    status: 'Pending',
    date: '06 Jul',
    time: '08:50pm',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    billDate: '06/11/21',
    invoiceAmt: '767.50',
    payment: '739.65',
    outstanding: '105.55',
  },
  {
    id: 6,
    settlement: 'POP2_M100520',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    status: 'Pending',
    date: '06 Jul',
    time: '08:50pm',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    billDate: '06/11/21',
    invoiceAmt: '767.50',
    payment: '739.65',
    outstanding: '105.55',
  },
  {
    id: 7,
    settlement: 'POP2_M100520',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    status: 'Pending',
    date: '06 Jul',
    time: '08:50pm',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    billDate: '06/11/21',
    invoiceAmt: '767.50',
    payment: '739.65',
    outstanding: '105.55',
  },
  {
    id: 8,
    settlement: 'POP2_M100520',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    status: 'Pending',
    date: '06 Jul',
    time: '08:50pm',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    billDate: '06/11/21',
    invoiceAmt: '767.50',
    payment: '739.65',
    outstanding: '105.55',
  },
  {
    id: 9,
    settlement: 'POP2_M100520',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    status: 'Pending',
    date: '06 Jul',
    time: '08:50pm',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    billDate: '06/11/21',
    invoiceAmt: '767.50',
    payment: '739.65',
    outstanding: '105.55',
  },
  {
    id: 10,
    settlement: 'POP2_M100520',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    status: 'Pending',
    date: '06 Jul',
    time: '08:50pm',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    billDate: '06/11/21',
    invoiceAmt: '767.50',
    payment: '739.65',
    outstanding: '105.55',
  },
  {
    id: 11,
    settlement: 'POP2_M100520',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    status: 'Pending',
    date: '06 Jul',
    time: '08:50pm',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    billDate: '06/11/21',
    invoiceAmt: '767.50',
    payment: '739.65',
    outstanding: '105.55',
  },
  {
    id: 12,
    settlement: 'POP2_M100520',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    status: 'Pending',
    date: '06 Jul',
    time: '08:50pm',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    billDate: '06/11/21',
    invoiceAmt: '767.50',
    payment: '739.65',
    outstanding: '105.55',
  },
  {
    id: 13,
    settlement: 'POP2_M100520',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    status: 'Pending',
    date: '06 Jul',
    time: '08:50pm',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    billDate: '06/11/21',
    invoiceAmt: '767.50',
    payment: '739.65',
    outstanding: '105.55',
  },
  {
    id: 14,
    settlement: 'POP2_M100520',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    customer: 'OEC Group',
    status: 'Pending',
    date: '06 Jul',
    time: '08:50pm',
    container: 'SDEE4125554',
    reference: 'SDEE4125554',
    bol: 'EGLV12312312323',
    port: 'Port City WHSE',
    billDate: '06/11/21',
    invoiceAmt: '767.50',
    payment: '739.65',
    outstanding: '105.55',
  },
];

const options = [
  { value: 'jessica.hanson@example.com', label: 'jessica.hanson@example.com' },
  { value: 'john.doe@example.com', label: 'john.doe@example.com' },
  { value: 'alex@example.com', label: 'alex@example.com' },
];

const StaticTableContent = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (event) => {
    setShowModal(true);
  };

  const handleHideModal = (event) => {
    setShowModal(false);
  };

  return (
    <div className="table-responsive" style={{ height: 'calc(100vh - 408px)' }}>
      <table className="table table-card table-card--ls">
        <thead>
          <tr>
            <th>
              <input
                className="form-check-input rounded-3 border-secondary"
                type="checkbox"
                id="sr_no"
              />
            </th>
            {columns.map((column) => {
              return (
                <th scope="col" key={column}>
                  {column}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((datum) => {
            return (
              <tr key={datum.id}>
                <td>
                  <input
                    className="form-check-input rounded-3 border-secondary"
                    type="checkbox"
                    id="sr_no"
                  />
                </td>
                <td>
                  <a
                    href="javascript:void(0)"
                    className="text-primary font-weight-500"
                  >
                    {datum.settlement}
                  </a>
                </td>
                <td>
                  <div className="d-flex">
                    <div className="mr-2 align-self-center">
                      <img
                        src={datum.img}
                        className="avatar-xs rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <h5 className="mb-0 align-self-center">
                      <a href="#" className="text-dark">
                        {datum.driver}
                      </a>
                    </h5>
                  </div>
                </td>
                <td>{datum.customer}</td>
                <td>
                  <div className="">
                    {datum.status == 'Pending' && (
                      <span className="badge badge-sm badge-soft-warning">
                        {datum.status}
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className="date">{datum.date}</div>
                  <div className="time text-muted mt-1">{datum.time}</div>
                </td>
                <td>{datum.container}</td>
                <td>{datum.reference}</td>
                <td>${datum.bol}</td>
                <td>${datum.port}</td>
                <td>${datum.billDate}</td>
                <td>${datum.invoiceAmt}</td>
                <td>${datum.payment}</td>
                <td>${datum.outstanding}</td>
                <td>
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-link">
                      <IconDownload />
                    </button>
                    <button className="btn btn-link" onClick={handleShowModal}>
                      <IconSendEmail />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Send Reports Modal */}
      <Modal show={showModal} centered animation={false}>
        <Modal.Header>
          <Modal.Title>Send Reports</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-wrapper">
            <form>
              <div className="form-group mb-30">
                <div className="form-check form-group mb-3">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="byEmail"
                    name="sendReport"
                  />
                  <label className="form-check-label" for="byEmail">
                    Send Reports by Email
                  </label>
                </div>
                <CustomCreatableSelect
                  name={'name'}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Enter email address.."
                />
              </div>
              <div className="form-group mb-30">
                <div className="form-check form-group mb-3">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="byMail"
                    name="sendReport"
                  />
                  <label className="form-check-label" for="">
                    Send Reports in Mail to Driver
                  </label>
                </div>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="jessica.hanson@example.com"
                  className="w-100"
                />
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" onClick={handleHideModal}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleHideModal}>
            Send reports
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StaticTableContent;
