import React, { Component } from "react";

import CustomSelect from "../../../../common/CustomSelect";
import {
  IconCalendar
} from "../../../../common/icons";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
class TabTrailers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadTabActive: false,
      showUploadModal: false,
    };
  }
  handleShowUploadModal = (event) => {
    this.setState({ showUploadModal: true });
  };
  handleHideUploadModal = (event) => {
    this.setState({ showUploadModal: false });
  };
  handleLoadTabActive = (i) => {
    this.setState({
      isLoadTabActive: this.state.isLoadTabActive === i ? null : i,
    });
  };
  render() {
    return (
      <div className="tab" id="tab-chassis">
        <div className="card">
          <div className="pt-30 px-20 mb-10">
            <div className="row">
              <div className="form-group col-lg-4">
                <label>Equipment ID</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Equipment ID"
                />
              </div>
              <div className="form-group col-lg-4">
                <label>Year</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select Year"
                  className="w-150"
                />
              </div>
              <div className="form-group col-lg-4">
                <label>Registration Exp</label>
                <div className="input-wrapper w-150">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Select Date"
                  />
                  <span className="input-icon">
                    <IconCalendar />
                  </span>
                </div>
              </div>
              <div className="form-group col-lg-4">
                <label>Model</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Model"
                />
              </div>
              <div className="form-group col-lg-4">
                <label>Lisence Plate #</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Enter Lisence Plate #"
                />
              </div>
              <div className="form-group col-lg-4">
                <label>Inspection expiration</label>
                <div className="input-wrapper w-150">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Select Date"
                  />
                  <span className="input-icon">
                    <IconCalendar />
                  </span>
                </div>
              </div>
              <div className="form-group col-lg-4">
                <label>Size</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select Size"
                  className="w-150"
                />
              </div>
              <div className="form-group col-lg-4">
                <label>License State</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Lisence State"
                />
              </div>
              <div className="form-group col-lg-4">
                <label>HUT Expiration</label>
                <div className="input-wrapper w-150">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Select Date"
                  />
                  <span className="input-icon">
                    <IconCalendar />
                  </span>
                </div>
              </div>
              <div className="form-group col-lg-4">
                <label>Trailer Type</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select Type"
                  className="w-150"
                />
              </div>
              <div className="form-group col-lg-4">
                <label>ITD</label>
                <div className="input-wrapper w-150">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Select Date"
                  />
                  <span className="input-icon">
                    <IconCalendar />
                  </span>
                </div>
              </div>
              <div className="form-group col-lg-4">
                <label>Make</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Who Makes"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 form-group">
                <label>AID</label>
                <div className="input-wrapper w-150">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Select Date"
                  />
                  <span className="input-icon">
                    <IconCalendar />
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 form-group">
                <label>VIN</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter VIN"
                  />
              </div>
            </div>

            <div className="form-group">
              <label>Date monthly maintenance last received</label>
              <div className="input-wrapper w-150">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Select Date"
                />
                <span className="input-icon">
                  <IconCalendar />
                </span>
              </div>
            </div>
            <div className="form-group">
              <label>Date monthly maintenance next due</label>
              <div className="input-wrapper w-150">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Select Date"
                />
                <span className="input-icon">
                  <IconCalendar />
                </span>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <button className="btn btn-link">Cancle</button>
            <button className="btn btn-primary">Save Changes</button>
          </div>
        </div>
      </div>
    );
  }
}

export default TabTrailers;
