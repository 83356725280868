import React, { useState } from 'react';
import BillingTabs from '../common-elements/BillingTabs';
import SearchFilter from './elements/SearchFilter';
import StaticTableContent from './elements/StaticTableContent';
import TopPage from './elements/TopPage';

const SubAccountsReceivable = () => {
  const [tabActive, setTabActive] = useState(1);

  const handleTabActive = (i) => {
    setTabActive(i);
  };
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <BillingTabs activeTab={3} />
        </div>
      </div>
      <div className="page-fluid">
        <TopPage />
        <SearchFilter />
        <StaticTableContent />
      </div>
    </div>
  );
};

export default SubAccountsReceivable;
