import React from 'react';
import SubHeader from '../subheader';

const ChassisValidation = () => {

  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <SubHeader />
        <div className="page-fluid ">
          <h1>Chassis Validation Page</h1>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChassisValidation;
