import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomSelect from "../../../../common/CustomSelect";
import { IconMinus, IconPlus } from "../../../../common/icons";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const AddNewRates = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;
  const [tabActive, setTabActive] = useState(2);

  const handleTabActive = (i) => {
    setTabActive(i);
  };

  return (
    <Modal show={showModal} centered animation={false}>
      <Modal.Header>
        <Modal.Title>Add New Discount</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="form-wrapper">
          <form>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label"> <span className="text-danger">*</span>Customer</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select Port/Shipper"
                  className="w-100"
                />
              </div>
            </div>
            <hr className="my-2" />
            <div className="form-row">
              <div className="form-group col-md-5">
                <label className="col-form-label"> <span className="text-danger">*</span>Name of Charge</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select Name of Charge"
                  className="w-100"
                />
              </div>
              <div className="form-group col-md-3">
                <label className="col-form-label"> <span className="text-danger">*</span>Value</label>
                <input className="form-control" placeholder="0.00" />
              </div>
              <div className="form-group col-md-3">
                <label className="col-form-label"> <span className="text-danger">*</span>Type</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="%"
                  className="w-100"
                />
              </div>
              <div className="form-group col-md-1 d-flex align-items-center justify-content-center">
                  <div className="btn-wrapper mt-1">
                    <button className="btn btn-xs btn-circle bg-soft-danger mt-4 ">
                        <IconMinus className="text-danger" />
                    </button>
                  </div>
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group col-md-5">
                <label className="col-form-label"> <span className="text-danger">*</span>Name of Charge</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select Name of Charge"
                  className="w-100"
                />
              </div>
              <div className="form-group col-md-3">
                <label className="col-form-label"> <span className="text-danger">*</span>Value</label>
                <input className="form-control" placeholder="0.00" />
              </div>
              <div className="form-group col-md-3">
                <label className="col-form-label"> <span className="text-danger">*</span>Type</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="%"
                  className="w-100"
                />
              </div>
              <div className="form-group col-md-1 d-flex align-items-center justify-content-center">
                <div className="btn-wrapper mt-1">
                    <button className="btn btn-xs btn-circle bg-soft-danger mt-4 ">
                      <IconMinus className="text-danger" />
                    </button>
                </div>
              </div>
            </div>
            <div>
              <button className="btn btn-sm btn-outline-primary">
                <IconPlus className="mr-2" /> Add
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-success" onClick={onSubmit}>
          Add Discount
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewRates;
