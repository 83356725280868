import React, { useState } from "react";
import CustomSelect from "../../../../common/CustomSelect/index";

import {
  IconColumn,
  IconFilter,
  IconPen,
  IconPlus,
  IconTrash,
} from "../../../../common/icons/index";

const tableHeaderData = [
  "Driver ID",
  "Mobile ",
  "Truck #",
  "Load Status",
  "Location",
  "ELD Status",
  "Drive Time",
  "Shift Time",
  "Cycle Time",
  "License #",
];
const data = [];
for (let i = 0; i < 20; i++) {
  data.push({
    id: i,
    driverID: `Dianne Russell`,
    mobile: "(302) 555-0107",
    truck: "112332JJS",
    laodStatus: "Import",
    location: "Wisconsin",
    eldStatus: "OFF ",
    driveTime: "11:00AM",
    shiftTime: "02:00PM",
    cycleTime: "03:00PM",
    license: "KKS321188LS",
  });
}

const StaticTableContent = () => {
  const [isPopDrop, setIsPopDrop] = useState(false);

  const handlePopDrop = (event) => {
    setIsPopDrop(!isPopDrop);
  };
  return (
    <React.Fragment>
      {/* Filter Row */}
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="form-check form-check--gray pr-30">
            <input
              className="form-check-input"
              type="checkbox"
              id="Available"
            />
            <label className="form-check-label" for="Available">
              Enabled
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                47
              </span>
            </label>
          </div>
          <div className="form-check form-check--gray pr-30">
            <input className="form-check-input" type="checkbox" id="Pending" />
            <label className="form-check-label" for="Pending">
              Disabled
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                6
              </span>
            </label>
          </div>
        </div>
        <div className="ml-auto d-flex">
          <button className="btn btn-outline-light">Truck List</button>
        </div>
      </div>
      {/* Table */}
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 257px)" }}
      >
        <table className="table table-card table-card--ls">
          <thead>
            <tr>
              {tableHeaderData.map((headetItem) => {
                return (
                  <th scope="col" key={headetItem}>
                    {headetItem}
                  </th>
                );
              })}
              <th style={{ width: "5%" }}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((datum) => {
              return (
                <tr key={datum.id}>
                  <td>{datum.driverID}</td>
                  <td className="text-center" style={{ width: "5%" }}>
                    {datum.mobile}
                  </td>
                  <td>{datum.truck}</td>
                  <td>
                    {datum.laodStatus == "Import" && (
                      <span className="badge badge-gray-50">
                        {datum.laodStatus}
                      </span>
                    )}
                  </td>
                  <td>{datum.location}</td>
                  <td>{datum.eldStatus}</td>
                  <td>{datum.driveTime}</td>
                  <td>{datum.shiftTime}</td>
                  <td>{datum.cycleTime}</td>
                  <td>{datum.license}</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <button className="btn btn-link text-primary">
                        <IconPen />
                      </button>
                      <button className="btn btn-link">
                        <IconTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
