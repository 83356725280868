import React from "react";
import CustomSelect from "../../../../common/CustomSelect";
import { IconReset } from "../../../../common/icons";

const options = [{ value: "ZYXW12345", label: "ZYXW12345" }];

const StaticTableContent = () => {
  return (
    <React.Fragment>
      <table className="table table-card">
        <thead>
          <tr>
            <th>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </th>
            <th>Load #</th>
            <th>Status</th>
            <th>Driver</th>
            <th>Move</th>
            <th>ETA</th>
            <th>LFD</th>
            <th>Cut</th>
            <th>Pickup</th>
            <th>APT</th>
            <th>Container</th>
            <th>Chassis</th>
            <th>Size</th>
            <th>Type</th>
            <th>SSL</th>
            <th>BOL/BKG</th>
            <th>PORT</th>
            <th>Consignee</th>
            <th>Location</th>
            <th>RTN</th>
            <th>Bobtail</th>
            <th>Reset</th>
            <th>Change Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)">POP2_M100520</a>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div className="mt-1">Ollivander's Wand Shop</div>
            </td>
            <td>
              <div className="d-flex">
                <div className="mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    className="avatar-circle rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Robert Fox
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="ZYXW12345"
                className="w-150"
              />
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>-</td>
            <td>
              <button className="btn btn-sm btn-danger">
                <IconReset className="mr-2" />
                Reset
              </button>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Select"
                className="w-150"
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)">POP2_M100520</a>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div className="mt-1">Ollivander's Wand Shop</div>
            </td>
            <td>
              <div className="d-flex">
                <div className="mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    className="avatar-circle rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Robert Fox
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="ZYXW12345"
                className="w-150"
              />
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>-</td>
            <td>
              <button className="btn btn-sm btn-danger">
                <IconReset className="mr-2" />
                Reset
              </button>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Select"
                className="w-150"
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)">POP2_M100520</a>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div className="mt-1">Ollivander's Wand Shop</div>
            </td>
            <td>
              <div className="d-flex">
                <div className="mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    className="avatar-circle rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Robert Fox
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="ZYXW12345"
                className="w-150"
              />
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>-</td>
            <td>
              <button className="btn btn-sm btn-danger">
                <IconReset className="mr-2" />
                Reset
              </button>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Select"
                className="w-150"
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)">POP2_M100520</a>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div className="mt-1">Ollivander's Wand Shop</div>
            </td>
            <td>
              <div className="d-flex">
                <div className="mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    className="avatar-circle rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Robert Fox
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="ZYXW12345"
                className="w-150"
              />
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>-</td>
            <td>
              <button className="btn btn-sm btn-danger">
                <IconReset className="mr-2" />
                Reset
              </button>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Select"
                className="w-150"
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)">POP2_M100520</a>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div className="mt-1">Ollivander's Wand Shop</div>
            </td>
            <td>
              <div className="d-flex">
                <div className="mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    className="avatar-circle rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Robert Fox
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="ZYXW12345"
                className="w-150"
              />
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>-</td>
            <td>
              <button className="btn btn-sm btn-danger">
                <IconReset className="mr-2" />
                Reset
              </button>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Select"
                className="w-150"
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)">POP2_M100520</a>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div className="mt-1">Ollivander's Wand Shop</div>
            </td>
            <td>
              <div className="d-flex">
                <div className="mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    className="avatar-circle rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Robert Fox
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="ZYXW12345"
                className="w-150"
              />
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>-</td>
            <td>
              <button className="btn btn-sm btn-danger">
                <IconReset className="mr-2" />
                Reset
              </button>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Select"
                className="w-150"
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)">POP2_M100520</a>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div className="mt-1">Ollivander's Wand Shop</div>
            </td>
            <td>
              <div className="d-flex">
                <div className="mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    className="avatar-circle rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Robert Fox
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="ZYXW12345"
                className="w-150"
              />
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>-</td>
            <td>
              <button className="btn btn-sm btn-danger">
                <IconReset className="mr-2" />
                Reset
              </button>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Select"
                className="w-150"
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)">POP2_M100520</a>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div className="mt-1">Ollivander's Wand Shop</div>
            </td>
            <td>
              <div className="d-flex">
                <div className="mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    className="avatar-circle rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Robert Fox
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="ZYXW12345"
                className="w-150"
              />
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>-</td>
            <td>
              <button className="btn btn-sm btn-danger">
                <IconReset className="mr-2" />
                Reset
              </button>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Select"
                className="w-150"
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)">POP2_M100520</a>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div className="mt-1">Ollivander's Wand Shop</div>
            </td>
            <td>
              <div className="d-flex">
                <div className="mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    className="avatar-circle rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Robert Fox
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="ZYXW12345"
                className="w-150"
              />
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>-</td>
            <td>
              <button className="btn btn-sm btn-danger">
                <IconReset className="mr-2" />
                Reset
              </button>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Select"
                className="w-150"
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)">POP2_M100520</a>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div className="mt-1">Ollivander's Wand Shop</div>
            </td>
            <td>
              <div className="d-flex">
                <div className="mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    className="avatar-circle rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Robert Fox
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="ZYXW12345"
                className="w-150"
              />
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>-</td>
            <td>
              <button className="btn btn-sm btn-danger">
                <IconReset className="mr-2" />
                Reset
              </button>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Select"
                className="w-150"
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)">POP2_M100520</a>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div className="mt-1">Ollivander's Wand Shop</div>
            </td>
            <td>
              <div className="d-flex">
                <div className="mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    className="avatar-circle rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Robert Fox
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="ZYXW12345"
                className="w-150"
              />
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>-</td>
            <td>
              <button className="btn btn-sm btn-danger">
                <IconReset className="mr-2" />
                Reset
              </button>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Select"
                className="w-150"
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)">POP2_M100520</a>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div className="mt-1">Ollivander's Wand Shop</div>
            </td>
            <td>
              <div className="d-flex">
                <div className="mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    className="avatar-circle rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Robert Fox
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="ZYXW12345"
                className="w-150"
              />
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>-</td>
            <td>
              <button className="btn btn-sm btn-danger">
                <IconReset className="mr-2" />
                Reset
              </button>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Select"
                className="w-150"
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)">POP2_M100520</a>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div className="mt-1">Ollivander's Wand Shop</div>
            </td>
            <td>
              <div className="d-flex">
                <div className="mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    className="avatar-circle rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Robert Fox
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="ZYXW12345"
                className="w-150"
              />
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>-</td>
            <td>
              <button className="btn btn-sm btn-danger">
                <IconReset className="mr-2" />
                Reset
              </button>
            </td>
            <td>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Select"
                className="w-150"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default StaticTableContent;
