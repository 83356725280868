import React, { useState, useEffect } from 'react';
import {
  IconAngleArrowLeft,
  IconAngleArrowRight,
  IconCalendar,
  IconCaretDown,
} from '../../../common/icons';
import DispatcherTabs from '../common-elements/dispatcherTabs';
import Pagination from '../../../common/pagination';
import StaticTableContent from '../StaticTableContent';
import StreetTurnsFilterRow from './elements/streetTurnsFilterRow';
import StreetTurnsCard from './elements/streetTurnsCard';
import { IconColumn, IconFilter } from '../../../common/icons';

const cardItems = [
  {
    id: 1,
    count: 8,
    title: 'Ever Green',
  },
  {
    id: 2,
    count: 9,
    title: 'Ever Green',
  },
  {
    id: 3,
    count: 11,
    title: 'Ever Green',
  },
  {
    id: 4,
    count: 15,
    title: 'Ever Green',
  },
  {
    id: 5,
    count: 16,
    title: 'Ever Green',
  },
  {
    id: 6,
    count: 18,
    title: 'Ever Green',
  },
];

const StreetTurns = (props) => {
  const [CardActive, setCardActive] = useState(1);
  useEffect(() => {
    document.body.className = 'has-subheader';
    return () => {
      document.body.className = '';
    };
  });

  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <DispatcherTabs activeTab={4} />
        <div className="page-fluid">
          <div className="top-page">
            <div className="top-page">
              <div className="pb-15 d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <button className="btn btn-white mr-3">
                    <IconAngleArrowLeft />
                  </button>
                  <div className="d-flex align-items-center mr-3">
                    <h4 className="font-20 mb-0">Today</h4>
                    <IconCaretDown />
                  </div>
                  <button className="btn btn-white mr-3">
                    <IconAngleArrowRight />
                  </button>
                  <button className="btn btn-white">
                    <IconCalendar />
                  </button>
                </div>
                <Pagination className="ml-auto" />
              </div>
              {/* Cards Row */}
              <div
                className={`form-row mb-15 ${CardActive ? 'hasactive' : ''}`}
              >
                {cardItems.map(({ id, title, count }) => (
                  <StreetTurnsCard
                    title={title}
                    count={count}
                    onCardClicked={() => setCardActive(id)}
                    isCardActive={CardActive === id}
                  />
                ))}
              </div>
              <StreetTurnsFilterRow />
              {/* Double Moves Double Table */}
              <div className="form-row">
                <div className="col-md-6">
                  {/* Filter Row */}
                  <React.Fragment>
                    <div className="filter-row d-flex align-items-center">
                      <div className="font-14 font-weight-500 mr-20">
                        Imports
                      </div>
                      <div className="ml-auto d-flex">
                        <div className="position-relative">
                          <button className="btn btn-outline-light position-relative">
                            <IconColumn />
                          </button>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                  <div
                    className="table-responsive"
                    style={{ height: 'calc(100vh - 342px)' }}
                  >
                    <StaticTableContent />
                  </div>
                </div>
                <div className="col-md-6">
                  {/* Filter Row */}
                  <React.Fragment>
                    <div className="filter-row d-flex align-items-center">
                      <div className="font-14 font-weight-500 mr-20">
                        Exports
                      </div>
                      <div className="ml-auto d-flex">
                        <Pagination className="mr-10 pagination--bordered" />
                        <div className="position-relative">
                          <button className="btn btn-outline-light position-relative">
                            <IconColumn />
                          </button>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                  <div
                    className="table-responsive"
                    style={{ height: 'calc(100vh - 342px)' }}
                  >
                    <StaticTableContent />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StreetTurns;
