import { IconAngleArrowRight } from "../icons";

const TopCards = (props) => {
  const {cardData} = props;  
  console.log(cardData);
  return (
    <div className={`form-row`}>
      {cardData.map((data) => {
        let randomNumberOne = Math.round(Math.random(data.id) * 20);
        let randomNumberTwo = Math.round(Math.random(data.id) * 20);
        let randomNumberTotal = randomNumberOne + randomNumberTwo;
        return (
          <div className="col-xl col-md-3 col-sm-6 col-6 my-2">
            <div
              className={`ncard d-flex flex-column justify-content-between`}
              href="javascript:void(0)"
            >
              <div className="d-flex align-items-center mb-10">
                <div className={`mr-1 sbox sbox--default`}>
                  {randomNumberTotal}
                </div>
                <div className="font-medium">{data.cardMainName}</div>
                <div className="ml-auto">
                  <IconAngleArrowRight />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between form-row">
                <div className="col-6">
                  <div className={`bbox bbox--default`}>
                    <div className="bbox__title">{data.cardSubNameOne}</div>
                    <div className="bbox__number">{randomNumberOne}</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className={`bbox bbox--default`}>
                    <div className="bbox__title">{data.cardSubNameTwo}</div>
                    <div className="bbox__number">{randomNumberTwo}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TopCards;
