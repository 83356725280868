import React, { useState } from "react";
import Chatpop from "../../../common/chatpop";
import { IconPen } from "../../../common/icons";
import EditGroupModal from "./editGroupModal";

const TrackChat = () => {
  const [isEditGroupModal, setIsEditGroupModal] = useState(false);

  const showEditGroupModal = () => {
    setIsEditGroupModal(true);
  };

  const hideEditGroupModal = () => {
    setIsEditGroupModal(false);
  };

  return (
    <div className="col" style={{ height: "calc(100vh - 288px)" }}>
      <div className="top-chat-list">
        <div className=" card d-flex flex-row justify-content-between px-15 py-20 mb-1">
          <div className="group d-flex align-items-center mt-1">
            <span className="mr-20 font-20 text-dark font-weight-500">
              Day Drivers
            </span>
            <div className="group__avatar">
              <img
                src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
                alt=""
                className="avatar-xs avatar-circle"
              />
            </div>
            <div className="group__avatar">
              <img
                src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                alt=""
                className="avatar-xs avatar-circle"
              />
            </div>
            <div className="group__avatar">
              <img
                src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                alt=""
                className="avatar-xs avatar-circle"
              />
            </div>
            <div className="group__avatar">
              <img
                src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                alt=""
                className="avatar-xs avatar-circle"
              />
            </div>
            <div className="group__avatar">
              <img
                src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                alt=""
                className="avatar-xs avatar-circle"
              />
            </div>
            <div className="group__avatar">
              <div className="avatar-xs avatar-circle bg-light text-dark">
                +3
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <button
              className="btn btn-outline-light"
              onClick={showEditGroupModal}
            >
              <IconPen className="mr-2" />
              Edit Group
            </button>
          </div>
        </div>
      </div>
      <Chatpop className="chatpop--full" />

      {/* Edit Group modal */}
      {isEditGroupModal && (
        <EditGroupModal
          onCancel={hideEditGroupModal}
          onSubmit={hideEditGroupModal}
        />
      )}
    </div>
  );
};
export default TrackChat;
