import React from 'react';
import CustomSelect from '../../../../common/CustomSelect';

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
];

const GeneralInfo = () => {
    return (
        <div className="form-wrapper py-20 px-30">
            <form>
                <div className="form-row ">
                    <div className="form-group col-md-12">
                        <label className="col-form-label">
                            <span className="text-danger">*</span> First name
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter First Name"
                        />
                    </div>
                    <div className="form-group col-md-12">
                        <label className="col-form-label">
                            <span className="text-danger">*</span> Last name
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Last Name"
                        />
                    </div>
                    <div className="form-group col-md-5">
                        <label className="col-form-label">
                            <span className="text-danger">*</span> Phone
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Phone number"
                        />
                    </div>
                    <div className="form-group col-md-12">
                        <label className="col-form-label">
                            <span className="text-danger">*</span> Email
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter email address"
                        />
                    </div>
                </div>
                <div className="hr-light"></div>
                <div className="form-row ">
                    <div className="form-group col-md-12">
                        <label className="col-form-label">
                            <span className="text-danger">*</span> Password
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter password"
                        />
                    </div>
                    <div className="form-group col-md-12">
                        <label className="col-form-label">
                            <span className="text-danger">*</span> Confirm Password
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Confirm Password"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default GeneralInfo;
