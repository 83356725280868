import React, { useState } from 'react';
import CustomSelect from '../../../../common/CustomSelect/index';

import {
  IconColumn,
  IconFilter,
  IconPen,
  IconPlus,
  IconTrash,
} from '../../../../common/icons/index';

const options = [
  { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
  { value: 'John Doe', label: 'John Doe' },
  { value: 'Alex Mendes', label: 'Alex Mendes' },
];

const columns = [
  'Driver ID',
  'Mobile ',
  'Truck #',
  'Load Status',
  'Location',
  'ELD Status',
  'Drive Time',
  'Shift Time',
  'Cycle Time',
  'License #',
];

const data = [
  {
    id: 1,
    driverID: `Albert Flores`,
    mobile: '+1 (907) 555-0101',
    truck: '9123712923956',
    laodStatus: 'Dropped',
    location: '2972 Westheimer Rd. Santa Ana, Illinois 85486 ',
    eldStatus: 'OFF ',
    driveTime: '06:32',
    shiftTime: '06:32',
    cycleTime: '54:23:33',
    license: 'D8287812',
  },
  {
    id: 2,
    driverID: `Albert Flores`,
    mobile: '+1 (907) 555-0101',
    truck: '',
    laodStatus: 'Dropped',
    location: '2972 Westheimer Rd. Santa Ana, Illinois 85486 ',
    eldStatus: 'OFF ',
    driveTime: '06:32',
    shiftTime: '06:32',
    cycleTime: '54:23:33',
    license: 'D8287812',
  },
  {
    id: 3,
    driverID: `Albert Flores`,
    mobile: '+1 (907) 555-0101',
    truck: '9123712923956',
    laodStatus: 'Dropped',
    location: '2972 Westheimer Rd. Santa Ana, Illinois 85486 ',
    eldStatus: 'OFF ',
    driveTime: '06:32',
    shiftTime: '06:32',
    cycleTime: '54:23:33',
    license: 'D8287812',
  },
  {
    id: 4,
    driverID: `Albert Flores`,
    mobile: '+1 (907) 555-0101',
    truck: '9123712923956',
    laodStatus: 'Dropped',
    location: '2972 Westheimer Rd. Santa Ana, Illinois 85486 ',
    eldStatus: 'OFF ',
    driveTime: '06:32',
    shiftTime: '06:32',
    cycleTime: '54:23:33',
    license: 'D8287812',
  },
  {
    id: 5,
    driverID: `Albert Flores`,
    mobile: '+1 (907) 555-0101',
    truck: '9123712923956',
    laodStatus: 'Dropped',
    location: '2972 Westheimer Rd. Santa Ana, Illinois 85486 ',
    eldStatus: 'OFF ',
    driveTime: '06:32',
    shiftTime: '06:32',
    cycleTime: '54:23:33',
    license: 'D8287812',
  },
  {
    id: 6,
    driverID: `Albert Flores`,
    mobile: '+1 (907) 555-0101',
    truck: '9123712923956',
    laodStatus: 'Dropped',
    location: '2972 Westheimer Rd. Santa Ana, Illinois 85486 ',
    eldStatus: 'OFF ',
    driveTime: '06:32',
    shiftTime: '06:32',
    cycleTime: '54:23:33',
    license: 'D8287812',
  },
  {
    id: 7,
    driverID: `Albert Flores`,
    mobile: '+1 (907) 555-0101',
    truck: '9123712923956',
    laodStatus: 'Dropped',
    location: '2972 Westheimer Rd. Santa Ana, Illinois 85486 ',
    eldStatus: 'OFF ',
    driveTime: '06:32',
    shiftTime: '06:32',
    cycleTime: '54:23:33',
    license: 'D8287812',
  },
  {
    id: 8,
    driverID: `Albert Flores`,
    mobile: '+1 (907) 555-0101',
    truck: '9123712923956',
    laodStatus: 'Dropped',
    location: '2972 Westheimer Rd. Santa Ana, Illinois 85486 ',
    eldStatus: 'OFF ',
    driveTime: '06:32',
    shiftTime: '06:32',
    cycleTime: '54:23:33',
    license: 'D8287812',
  },
  {
    id: 9,
    driverID: `Albert Flores`,
    mobile: '+1 (907) 555-0101',
    truck: '9123712923956',
    laodStatus: 'Dropped',
    location: '2972 Westheimer Rd. Santa Ana, Illinois 85486 ',
    eldStatus: 'OFF ',
    driveTime: '06:32',
    shiftTime: '06:32',
    cycleTime: '54:23:33',
    license: 'D8287812',
  },
  {
    id: 10,
    driverID: `Albert Flores`,
    mobile: '+1 (907) 555-0101',
    truck: '9123712923956',
    laodStatus: 'Dropped',
    location: '2972 Westheimer Rd. Santa Ana, Illinois 85486 ',
    eldStatus: 'OFF ',
    driveTime: '06:32',
    shiftTime: '06:32',
    cycleTime: '54:23:33',
    license: 'D8287812',
  },
  {
    id: 11,
    driverID: `Albert Flores`,
    mobile: '+1 (907) 555-0101',
    truck: '9123712923956',
    laodStatus: 'Dropped',
    location: '2972 Westheimer Rd. Santa Ana, Illinois 85486 ',
    eldStatus: 'OFF ',
    driveTime: '06:32',
    shiftTime: '06:32',
    cycleTime: '54:23:33',
    license: 'D8287812',
  },
  {
    id: 12,
    driverID: `Albert Flores`,
    mobile: '+1 (907) 555-0101',
    truck: '9123712923956',
    laodStatus: 'Dropped',
    location: '2972 Westheimer Rd. Santa Ana, Illinois 85486 ',
    eldStatus: 'OFF ',
    driveTime: '06:32',
    shiftTime: '06:32',
    cycleTime: '54:23:33',
    license: 'D8287812',
  },
  {
    id: 13,
    driverID: `Albert Flores`,
    mobile: '+1 (907) 555-0101',
    truck: '9123712923956',
    laodStatus: 'Dropped',
    location: '2972 Westheimer Rd. Santa Ana, Illinois 85486 ',
    eldStatus: 'OFF ',
    driveTime: '06:32',
    shiftTime: '06:32',
    cycleTime: '54:23:33',
    license: 'D8287812',
  },
  {
    id: 14,
    driverID: `Albert Flores`,
    mobile: '+1 (907) 555-0101',
    truck: '9123712923956',
    laodStatus: 'Dropped',
    location: '2972 Westheimer Rd. Santa Ana, Illinois 85486 ',
    eldStatus: 'OFF ',
    driveTime: '06:32',
    shiftTime: '06:32',
    cycleTime: '54:23:33',
    license: 'D8287812',
  },
];

const StaticTableContent = () => {
  const [isPopDrop, setIsPopDrop] = useState(false);

  const handlePopDrop = (event) => {
    setIsPopDrop(!isPopDrop);
  };
  return (
    <React.Fragment>
      {/* Search Filter */}
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>
        <div className="d-flex">
          <CustomSelect
            name={'name'}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Customer"
            className="w-150 z-2 mr-2"
          />
          <CustomSelect
            name={'name'}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Shipper"
            className="w-150 z-2 mr-2"
          />
          <CustomSelect
            name={'name'}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Consignee"
            className="w-150 z-2 mr-2"
          />
        </div>
        <div className="ml-auto d-flex">
          <button className="btn btn-outline-light mr-2">
            <IconFilter className="mr-2" />
            Filter
          </button>
          <div className="position-relative">
            <button className="btn btn-outline-light position-relative">
              <IconColumn />
            </button>
          </div>
        </div>
      </div>
      <div
        className="table-responsive"
        style={{ height: 'calc(100vh - 254px)' }}
      >
        <table className="table table-card">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
              <th style={{ width: '2%' }}></th>
              <th style={{ width: '2%' }}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((datum) => {
              return (
                <tr key={datum.id}>
                  <td>{datum.driverID}</td>
                  <td className="text-center" style={{ width: '5%' }}>
                    {datum.mobile}
                  </td>
                  <td
                    className="text-center position-relative"
                    style={{ width: '5%' }}
                  >
                    {datum.truck == '' ? (
                      <React.Fragment>
                        <button
                          className="btn btn-sm btn-primary-dark position-relative"
                          onClick={handlePopDrop}
                        >
                          <IconPlus className="mr-2" />
                          Add Truck
                        </button>

                        {isPopDrop && (
                          <div className="card card--shadow-5 p-3 popdrop popdrop--left popdrop--bottom rounded-lg mt-n2 ml-2">
                            <div className="app-search header-search">
                              <span className="search-icon"> </span>
                              <div className="position-relative bg-gray-50 rounded-lg">
                                <input
                                  type="search"
                                  className="form-control input-search-left py-2"
                                  placeholder="Search..."
                                  value=""
                                />
                              </div>
                            </div>
                            {/* Add Driver Popdrop */}
                            <div
                              className="scrollable-light mt-3"
                              style={{ maxHeight: '15rem', overflow: 'auto' }}
                            >
                              <div className="text-left">
                                <div className="text-dark font-weight-500">
                                  DMC123
                                </div>
                                <div className="text-muted">
                                  Hundai, 2005, HA678533342
                                </div>
                              </div>
                              <div className="mt-3 text-left">
                                <div className="text-dark font-weight-500">
                                  DMC123
                                </div>
                                <div className="text-muted">
                                  Hundai, 2005, HA678533342
                                </div>
                              </div>
                              <div className="mt-3 text-left">
                                <div className="text-dark font-weight-500">
                                  DMC123
                                </div>
                                <div className="text-muted">
                                  Hundai, 2005, HA678533342
                                </div>
                              </div>
                              <div className="mt-3 text-left">
                                <div className="text-dark font-weight-500">
                                  DMC123
                                </div>
                                <div className="text-muted">
                                  Hundai, 2005, HA678533342
                                </div>
                              </div>
                              <div className="mt-3 text-left">
                                <div className="text-dark font-weight-500">
                                  DMC123
                                </div>
                                <div className="text-muted">
                                  Hundai, 2005, HA678533342
                                </div>
                              </div>
                              <div className="mt-3 text-left">
                                <div className="text-dark font-weight-500">
                                  DMC123
                                </div>
                                <div className="text-muted">
                                  Hundai, 2005, HA678533342
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    ) : (
                      <div>{datum.truck}</div>
                    )}
                  </td>
                  <td>
                    {datum.laodStatus == 'Dropped' && (
                      <span className="badge badge-sm badge-green ">
                        {datum.laodStatus}
                      </span>
                    )}
                  </td>
                  <td>{datum.location}</td>
                  <td>{datum.eldStatus}</td>
                  <td>{datum.driveTime}</td>
                  <td>{datum.shiftTime}</td>
                  <td>{datum.cycleTime}</td>
                  <td>{datum.license}</td>
                  <td>
                    <button className="btn btn-link">
                      <IconPen className="" />
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-link">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
