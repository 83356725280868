import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IconLoads } from '../../../../common/icons';

const LoadsReport = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;

  return (
    <Modal show={showModal} centered animation={false} size="lg">
      <Modal.Header className="py-20 px-30">
        <Modal.Title>
          <div>
            <IconLoads className="mr-2" />
            <span className="text-muted font-12">Loads</span>
          </div>
          <div>Loads Report</div>
          <div className="d-flex">
            <span className="text-muted font-12 mr-2">Receivers:</span>
            <span className="font-12 mr-2">nevaeh.simmons@example.com</span>
            <span className="font-12 mr-2">nevaeh.simmons@example.com</span>
            <span className="font-12 mr-2">nevaeh.simmons@example.com</span>
          </div>
          <div className="d-flex">
            <span className="text-muted font-12 mr-2">Frequency:</span>
            <span className="font-12 mr-2">Each Monday</span>
            <span className="font-12 mr-2">09:00am, 11:00am, 02:00pm</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 nav-border-bottom">
        {/* Table*/}
        <div className="bg-gray-50">
          <div
            className="table-responsive"
            style={{ height: 'calc(100vh - 255px)' }}
          >
            <table className="table table-card">
              <thead>
                <tr>
                  <th>My Customer</th>
                  <th>Chassis </th>
                  <th>Chassis</th>
                  <th>Chassis</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="font-weight-500">Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td class="font-weight-500">Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td class="font-weight-500">Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td class="font-weight-500">Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td class="font-weight-500">Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td class="font-weight-500">Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td class="font-weight-500">Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td class="font-weight-500">Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td class="font-weight-500">Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
                <tr>
                  <td class="font-weight-500">Stanredtax</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                  <td>Chassis name</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary px-40 py-1" onClick={onSubmit}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoadsReport;
