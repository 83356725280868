import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import CustomCreatableSelect from '../../../../common/CustomSelect/creatable';
import { CustomRocket } from '../../../../common/customIcons';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const InviteModal = (props) => {
  const { showModal, hideModal, onCancel } = props;
  return (
    <Modal show={showModal} centered animation={false} size="sm">
      <Modal.Header className="py-40 px-30 bg-brand-50 justify-content-center flex-column">
        <CustomRocket />
        <div className="text-center mt-10">
          <div className="font-30 font-weight-500 text-gray-900 pb-1">
            Welcome!
          </div>
          <div className="font-12 font-weight-normal text-gray-500">
            Now you can feel the power of TrackOS!
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="pt-20 px-30 pb-50">
        <React.Fragment>
          <div className="text-center">
            <div className="font-16 font-weight-500 text-gray-900 pb-1">
              Let’s invite your Team!
            </div>
            <div className="font-12 font-weight-normal text-gray-500">
              Please add your teammembers email addresses below to invite
            </div>
            <div className="font-weight-normal text-gray-500">
              them to join your account!
            </div>
          </div>
          <div className="form-wrapper mt-20">
            <form>
              <div className="form-group mb-1">
                <CustomCreatableSelect
                  name={'name'}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Enter email address.."
                />
              </div>
            </form>
          </div>
          <div className="font-12 font-weight-normal text-gray-400">
            You can add multiple address by comma.
          </div>
          <div className="font-12 font-weight-normal text-gray-400">
            E.g. email1@gmail.com, email2@gmail.com, email3@gmail.com
          </div>
        </React.Fragment>
      </Modal.Body>
      <Modal.Footer>
        <div className="">
          <button className="btn mr-20" onClick={hideModal}>
            Back
          </button>
          <button className="btn btn-outline-primary mr-10" onClick={onCancel}>
            Do it later
          </button>
          <button className="btn btn-primary text-white" onClick={onCancel}>
            Continue
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default InviteModal;
