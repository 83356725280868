import React, { useState } from "react";
import CustomSelect from "../../../../common/CustomSelect/index";

import {
  IconColumn,
  IconFilter,
  IconPen,
  IconPlus,
  IconTrash,
} from "../../../../common/icons/index";

const tableHeaderData = [
  "City",
  "State",
  "Amount",
  "Type of load"
];
const tableData = [
  {
    id: 1,
    city: "Ontario (CA)",
    state: "Montana",
    amount: "$180.00",
    typeOfLoad: "Import"
  },
  {
    id: 2,
    city: "San Isidro",
    state: "West Virginia",
    amount: "$180.00",
    typeOfLoad: "Import"
  },
  {
    id: 3,
    city: "Córdoba",
    state: "Tennessee",
    amount: "$180.00",
    typeOfLoad: "Import"
  },
  {
    id: 4,
    city: "Naltchik",
    state: "California",
    amount: "$180.00",
    typeOfLoad: "Import"
  },
  {
    id: 5,
    city: "Mar del Plata",
    state: "Georgia",
    amount: "$180.00",
    typeOfLoad: "Import"
  },
  {
    id: 6,
    city: "Lübeck",
    state: "New Jersey",
    amount: "$180.00",
    typeOfLoad: "Import"
  },
  {
    id: 7,
    city: "Krasnodar",
    state: "North Dakota",
    amount: "$180.00",
    typeOfLoad: "Import"
  },
  {
    id: 8,
    city: "Khabarovsk",
    state: "New Hampshire",
    amount: "$180.00",
    typeOfLoad: "Import"
  },
  {
    id: 9,
    city: "Herne",
    state: "Kansas",
    amount: "$180.00",
    typeOfLoad: "Import"
  },
  {
    id: 10,
    city: "Bahía Blanca",
    state: "Wisconsin",
    amount: "$180.00",
    typeOfLoad: "Import"
  },
  {
    id: 11,
    city: "Rubtsovsk,",
    state: "Wyoming",
    amount: "$180.00",
    typeOfLoad: "Import"
  },
  {
    id: 12,
    city: "Hagen",
    state: "Nebraska",
    amount: "$180.00",
    typeOfLoad: "Import"
  },
  {
    id: 13,
    city: "Mannheim",
    state: "Idaho",
    amount: "$180.00",
    typeOfLoad: "Import"
  },
  {
    id: 14,
    city: "Lomas de Zamora",
    state: "Texas",
    amount: "$180.00",
    typeOfLoad: "Import"
  },
  {
    id: 15,
    city: "Fort Lauderdale (FL)",
    state: "Virginia",
    amount: "$180.00",
    typeOfLoad: "Import"
  },
  {
    id: 16,
    city: "North Las Vegas (NV)",
    state: "Alaska",
    amount: "$180.00",
    typeOfLoad: "Import"
  },
];

const StaticTableContent = () => {
  const [isPopDrop, setIsPopDrop] = useState(false);

  const handlePopDrop = (event) => {
    setIsPopDrop(!isPopDrop);
  };
  return (
    <React.Fragment>
      {/* Filter Row */}
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="form-check form-check--gray pr-30">
            <input
              className="form-check-input"
              type="checkbox"
              id="Available"
            />
            <label className="form-check-label" for="Available">
              Enabled
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                47
              </span>
            </label>
          </div>
          <div className="form-check form-check--gray pr-30">
            <input className="form-check-input" type="checkbox" id="Pending" />
            <label className="form-check-label" for="Pending">
              Disabled
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                6
              </span>
            </label>
          </div>
        </div>
        <div className="ml-auto d-flex">
          <button className="btn btn-outline-light">
            Truck List
          </button> 
        </div>
      </div>
      {/* Table */}
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 257px)" }}
      >
        <table className="table table-card table-card--ls">
          <thead>
            <tr>
              {tableHeaderData.map((headetItem) => {
                return (
                  <th scope="col" key={headetItem}>
                    {headetItem}
                  </th>
                )
              })}
              <th style={{width: "5%"}}></th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((tdata) => {
              return (
                <tr key={tdata.id}>
                  <td>{tdata.city}</td>
                  <td>{tdata.state}</td>
                  <td style={{width: "5%"}}>{tdata.amount}</td>
                  <td style={{width: "5%"}}>
                    <div className="badge badge-gray-50">
                      {tdata.typeOfLoad}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <button className="btn btn-link text-primary">
                        <IconPen />
                      </button>
                      <button className="btn btn-link">
                        <IconTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
