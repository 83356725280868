import React, { useState } from "react";
import BillingTabs from '../common-elements/BillingTabs';
import SearchFilter from "./elements/searchFilter";
import StaticTableContent from "./elements/StaticTableContent";
import TopPage from "./elements/toppage";

const Aging = () => {
  
  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <div className="subheader">
          <div className="subheader__inner d-flex align-items-center py-0">
            <BillingTabs activeTab={4} />
          </div>
        </div>
        <div className="page-fluid">
          <TopPage />
          <SearchFilter />
          <StaticTableContent />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Aging;
