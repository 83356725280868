import { placeholder } from "@babel/types";
import React from "react";
import Select from "react-select";

const smallSelectStyle = {
  control: (base, state) => ({
    ...base,
    minHeight: 30,
    borderColor: "#BFCCDA",
    borderRadius: "3px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#687d96",
      boxShadow: "none",
    },
    "&:focus": {
      border: "1px solid var(--color-primary)",
      paddingTop: '6.5px',
      paddingBottom: '6.5px',
      boxShadow: "none",
    },
    "&:active": {
      border: "1px solid var(--color-primary)",
      boxShadow: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    innerHeight: 30,
    zIndex: 2,
  }),
  menuList: (base) => ({
    ...base,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 30,
    color: "#394E66",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    margin: "0",
    backgroundColor: "#BFCCDA",
  }),
};
const mediumSelectStyle = {
  control: (base, state) => ({
    ...base,
    minHeight: 40,
    borderColor: "#BFCCDA",
    borderRadius: "3px",
  }),
  menu: (base) => ({
    ...base,
    innerHeight: 40,
    zIndex: 2,
  }),
  menuList: (base) => ({
    ...base,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 40,
    color: "#394E66",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    margin: "0",
    backgroundColor: "#BFCCDA",
  }),
};
// React Select Input
const CustomSelect = (props) => {
  const {
    name,
    value,
    options,
    isMulti,
    isClearable,
    size,
    placement,
    placeholder,
    className,
    formatOptionLabel,
  } = props;

  return (
    <Select
      name={name}
      className={className}
      options={options}
      value={value}
      styles={
        size === "small"
          ? smallSelectStyle
          : size === "medium"
          ? mediumSelectStyle
          : mediumSelectStyle
      }
      isMulti={isMulti}
      isClearable={isClearable}
      menuPlacement={
        placement === "top" ? "top" : placement === "bottom" ? "bottom" : "auto"
      }
      placeholder={placeholder}
      formatOptionLabel={formatOptionLabel}
    />
  );
};

export default CustomSelect;
