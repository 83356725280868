import React, { useState } from 'react';
import { CustomIconSpreadSheet } from '../../../common/customIcons';
import { IconDownload, IconPlus, IconSendEmail } from '../../../common/icons';
import Pagination from '../../../common/pagination';
import TrackOsImportAvailabilityCards from './ImportAvailabilityCards';
import AddCompanyModal from './Components/AddCompanyModal';
import InviteModal from './Components/InviteModal';

const CompanyInfoForm = ({}) => {
  const [AddNewCompanyModal, setAddCompanyModal] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const showAddCompanyModal = (e) => {
    setAddCompanyModal(true);
  };
  const hideAddCompanyModal = (e) => {
    setAddCompanyModal(false);
  };
  const showInviteModal = () => {
    setInviteModal(true);
    setAddCompanyModal(false);
  };
  const hideInviteModal = () => {
    setInviteModal(false);
    setAddCompanyModal(true);
  };

  const cancelInviteModal = () => {
    setInviteModal(false);
  };

  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <div className="subheader">
          <div className="subheader__inner d-flex align-items-center py-0">
            <div className="d-flex flex-row align-items-center justify-content-between w-100">
              <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                <li className="nav-item">
                  <a href="#" className={`nav-link`}>
                    <div className="">Import Availability</div>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#" className={`nav-link`}>
                    <div className="">Archive</div>
                  </a>
                </li>
              </ul>
              <div className="ml-auto">
                <button className="btn btn-outline-light mr-2">
                  <CustomIconSpreadSheet />
                </button>
                <button className="btn btn-outline-light mr-2">
                  <IconDownload />
                </button>
                <button className="btn btn-outline-light mr-2">
                  <IconSendEmail />
                </button>
                <button
                  className="btn btn-success"
                  onClick={showAddCompanyModal}
                >
                  <IconPlus className="mr-2" />
                  Add Company Info
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <h4 className="mb-0">757 Containers</h4>
              <Pagination className={'ml-auto'} />
            </div>
            {/*Cards */}
            <TrackOsImportAvailabilityCards />
          </div>
        </div>
      </div>
      {AddNewCompanyModal && (
        <AddCompanyModal
          showModal={showAddCompanyModal}
          hideModal={hideAddCompanyModal}
          showNextModal={showInviteModal}
        />
      )}
      {inviteModal && (
        <InviteModal
          showModal={showInviteModal}
          hideModal={hideInviteModal}
          onCancel={cancelInviteModal}
        />
      )}
    </React.Fragment>
  );
};

export default CompanyInfoForm;
