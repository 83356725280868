import React from "react";
import {
  IconCalendar,
  IconColumn,
  IconFilter,
} from "../../../../common/icons/index";
import CustomSelect from "../../../../common/CustomSelect";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const SearchFilter = () => {
  return (
    <div className="filter-row d-flex align-items-center mt-2">
      <div className="d-flex">
        <CustomSelect
          name={"name"}
          options={options}
          value={1}
          isMulti={true}
          isClearable={true}
          size="small"
          placement="right"
          placeholder="Select Customer"
          className="w-150 mr-1"
        />
        <CustomSelect
          name={"name"}
          options={options}
          value={1}
          isMulti={true}
          isClearable={true}
          size="small"
          placement="right"
          placeholder="Select Agent..."
          className="w-150"
        />
      </div>
      {/* Filter Settings */}
      <div className="ml-auto">
        <button className="btn btn-outline-light mr-2">
          <IconFilter className="mr-2" />
          Filter
        </button>
        <button className="btn btn-outline-light">
          <IconColumn />
        </button>
      </div>
    </div>
  );
};

export default SearchFilter;
