import React, { useState } from "react";
import CustomSelect from "../../../../common/CustomSelect/index";

import {
  IconColumn,
  IconFilter,
  IconPen,
  IconPlus,
  IconTrash,
} from "../../../../common/icons/index";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const columns = ["Name", "Roles"];

const data = [
  {
    id: 1,
    name: `Jerome Bell`,
    role: "",
  },
  {
    id: 2,
    name: `Albert Flores`,
    role: "",
  },
  {
    id: 3,
    name: `Leslie Alexander`,
    role: "",
  },
  {
    id: 4,
    name: `Darlene Robertson`,
    role: "",
  },
  {
    id: 5,
    name: `Ralph EdwardsJerome Bell`,
    role: "",
  },
  {
    id: 6,
    name: `Cody Fisher`,
    role: "",
  },
  {
    id: 7,
    name: `Brooklyn Simmons`,
    role: "",
  },
  {
    id: 8,
    name: `Marvin McKinney`,
    role: "",
  },
  {
    id: 8,
    name: `Ronald Richards`,
    role: "",
  },
];

const StaticTableContent = () => {
  const [isPopDrop, setIsPopDrop] = useState(false);

  const handlePopDrop = (event) => {
    setIsPopDrop(!isPopDrop);
  };
  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 202px)" }}
      >
        <table className="table table-card">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column} style={{ width: "50%" }}>
                    {column}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((datum) => {
              return (
                <tr key={datum.id}>
                  <td>{datum.name}</td>
                  
                    {datum.role === "" ? (
                      <td>???</td>
                    ) : (
                      <>
                        <td>{datum.role}</td>
                      </>
                    )}
                  
                  <td>{datum.role}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
