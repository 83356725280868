import React, { useState } from 'react';
import TabComponent from '../../../common/tabComponent';
import { IconDownload } from '../../../common/icons';
import Chat from '..//../customerService/driverMessaging/DriverChat';
import Group from './GroupMessageList';
import Message from '../driverMessaging/Message';
import DriverChat from '../driverMessaging/DriverChat';
import DriverGroupChat from '../driverMessaging/DriverGroupChat';
import SubHeader from '../subheader';

const tabItems = [
  {
    id: 1,
    title: 'Dropped Container',
  },
  {
    id: 2,
    title: 'Load Calendar',
  },
  {
    id: 3,
    title: 'Bulk Uploads',
  },
  {
    id: 4,
    title: 'Chassis Validation',
  },
  {
    id: 5,
    title: 'Customer Messaging',
  },
  {
    id: 6,
    title: 'Driver Messaging',
  },
  {
    id: 7,
    title: 'Deleted Loads',
  },
];

const chatItems = [
  {
    avatar:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg',
    name: 'Darrell Steward',
    time: 'active 2 hr ago',
  },
  {
    avatar:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg',
    name: 'Wade Warren',
    time: 'active 2 hr ago',
  },
  {
    avatar:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg',
    name: 'Esther Howard',
    time: '26 Aug 2021, 10:10 am',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Arlene McCoy',
    time: '26 Aug 2021, 10:10 am',
  },
  {
    avatar:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg',
    name: 'Robert Fox',
    time: '26 Aug 2021, 10:10 am',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Jenny Wilson',
    time: '26 Aug 2021, 10:10 am',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Savannah Nguygen',
    time: '26 Aug 2021, 10:10 am',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Courtney Henry',
    time: 'October 31, 2017',
  },

  {
    avatar:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg',
    name: 'Annette Black',
    time: 'October 31, 2017',
  },
  {
    avatar:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg',
    name: 'Albert Flores',
    time: 'August 2, 2013',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Courtney Henry',
    time: 'October 31, 2017',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Courtney Henry',
    time: 'October 31, 2017',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Courtney Henry',
    time: 'October 31, 2017',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Courtney Henry',
    time: 'October 31, 2017',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Courtney Henry',
    time: 'October 31, 2017',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Courtney Henry',
    time: 'October 31, 2017',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Courtney Henry',
    time: 'October 31, 2017',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Courtney Henry',
    time: 'October 31, 2017',
  },
  {
    avatar:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg',
    name: 'Marvin Mckinney',
    time: 'August 2, 2013',
  },
];

const CustomerMessaging = () => {
  const [tabActive, setTabActive] = useState(1);

  const [chatTabActive, setChatTabActive] = useState(1);
  const handleChatTabActive = (i) => {
    setChatTabActive(i);
  };

  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <SubHeader />
        <div className="page-fluid ">
          <div className="form-row-lg d-flex justify-content-between">
            <div
              className="col"
              style={{ height: 'calc(100vh - 150px)', maxWidth: 313 }}
            >
              <div className="card p-2 mb-10">
                <div className="d-flex justify-content-between align-items-center ">
                  <div className="app-search mb-2 mt-2 ">
                    <input
                      type="text"
                      className="input-search-left form-control pt-2"
                      placeholder="Search.."
                    />
                    <span className="search-icon"></span>
                  </div>
                  {chatTabActive === 1 ? null : (
                    <div className="  ">
                      <button className="btn btn-sm btn-primary px-1 py-0 ">
                        <i className="uil uil-plus "></i>
                      </button>
                    </div>
                  )}
                </div>
                <ul className="nav nav-compact  nav-justified" role="tablist">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        chatTabActive === 1 ? 'active' : ''
                      }`}
                      data-bs-toggle="tab"
                      href="#message"
                      role="tab"
                      onClick={() => handleChatTabActive(1)}
                    >
                      Messaging
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        chatTabActive === 2 ? 'active' : ''
                      }`}
                      data-bs-toggle="tab"
                      href="#group"
                      role="tab"
                      onClick={() => handleChatTabActive(2)}
                    >
                      Groups
                    </a>
                  </li>
                </ul>
              </div>
              {chatTabActive === 1 ? <Message /> : <Group />}
            </div>
            {chatTabActive === 1 ? <DriverChat /> : <DriverGroupChat />}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerMessaging;
