import { Modal } from "react-bootstrap";
import { IconReward } from "../../../../common/icons";

const SubscriptionModal = (props) => {
    const { showModal, hideModal, showNextModal } = props;
    return (
        <Modal show={showModal} centered animation={false}>
            <Modal.Header className="d-block text-center">
                <Modal.Title>Subscription Type</Modal.Title>
                <div className="text-muted mt-1">
                    How do you prefer to make payments?
                </div>
            </Modal.Header>
            <Modal.Body className="px-60">
                <div className="row no-gutters">
                    <div className="col-6">
                        <div className="shadow-4 h-100 p-20 rounded-5 mr-10">
                            <div className="d-flex justify-content-between">
                                <div className="form-check mb-10">
                                    <input
                                        className="form-check-input form-check-input--radio-check rounded-circle wh-20px"
                                        type="checkbox"
                                        id="basic"
                                    />
                                    <label className="form-check-label font-20 font-weight-500 " for="basic">
                                        Basic
                                    </label>
                                </div>
                                <h4 className="font-20 text-gray-500">$0.00</h4>
                            </div>
                            <p className="text-gray-500 py-10">Try it for 9 days</p>
                            <button className="btn btn-lg btn-outline-primary w-100 justify-content-center">Try it for 9 days</button>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="bg-primary h-100 p-20 rounded-5 text-white ml-10">
                            <div className="d-flex justify-content-between">
                                <div className="form-check mb-10">
                                    <input
                                        className="form-check-input form-check-input--radio-check rounded-circle wh-20px"
                                        type="checkbox"
                                        id="pro"
                                    />
                                    <label className="form-check-label font-20 font-weight-500 " for="pro">
                                        Premium
                                    </label>
                                </div>
                                <h4 className="font-20 text-white">$49.99</h4>
                            </div>
                            <div className="d-flex align-items-baseline">
                                <p className="text-brand-100 py-10">Over 50 customers chose this plan in 2021!</p>
                                <span>
                                    <IconReward className="text-brand-100" />
                                </span>
                            </div>
                            <button className="btn btn-lg btn-white w-100 justify-content-center">Get Professional plan</button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-light mr-10 py-10 px-15" onClick={hideModal}>
                    Back
                </button>
                <button className="btn btn-primary py-10 px-15" onClick={showNextModal}>Create an Account</button>
            </Modal.Footer>
        </Modal>
    )
}

export default SubscriptionModal;