import React from 'react';
import { Modal } from 'react-bootstrap';
import CustomSelect from '../../../../common/CustomSelect';

const options = [
  { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
  { value: 'John Doe', label: 'John Doe' },
  { value: 'Alex Mendes', label: 'Alex Mendes' },
];

const AddPermissionModal = (props) => {
  const {
    showModal = true,
    onCancel = () => {},
    onBack = () => {},
    onSubmit = () => {},
  } = props;
  return (
    <Modal show={showModal} centered animation={false} size="xl">
      <Modal.Header className="pb-10">
        <Modal.Title>Assign Permission</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="form-wrapper">
          <div className="form-row">
            <div className="col-12">
              <h6 className="font-size-regular text-gray-500 mb-15">
                You can select the role or assign each permission customly
              </h6>
              <div className="mb-20">
                {/* Select Customer */}
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Manager"
                  className="w-200 z-2"
                />
              </div>
            </div>
          </div>
          <div className="border mb-20"></div>
          <form>
            <div className="form-row mt-4">
              <div className="col">
                <div className="d-flex flex-column">
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="other"
                      checked=""
                    />
                    <label className="form-check-label" for="other">
                      Other
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="Permission"
                      checked=""
                    />
                    <label className="form-check-label" for="permission">
                      Permission
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="truck"
                      checked=""
                    />
                    <label className="form-check-label" for="truck">
                      Truck
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="trailer"
                      checked=""
                    />
                    <label className="form-check-label" for="calender">
                      Trailer
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column">
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="other"
                      checked=""
                    />
                    <label className="form-check-label" for="other">
                      Other
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="Permission"
                      checked=""
                    />
                    <label className="form-check-label" for="permission">
                      Permission
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="truck"
                      checked=""
                    />
                    <label className="form-check-label" for="truck">
                      Truck
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="trailer"
                      checked=""
                    />
                    <label className="form-check-label" for="calender">
                      Trailer
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column">
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="other"
                      checked={true}
                    />
                    <label className="form-check-label" for="other">
                      Other
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="Permission"
                      checked={true}
                    />
                    <label className="form-check-label" for="permission">
                      Permission
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="truck"
                      checked={true}
                    />
                    <label className="form-check-label" for="truck">
                      Truck
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="trailer"
                      checked={true}
                    />
                    <label className="form-check-label" for="calender">
                      Trailer
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column">
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="other"
                      checked={true}
                    />
                    <label className="form-check-label" for="other">
                      Other
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="Permission"
                      checked={true}
                    />
                    <label className="form-check-label" for="permission">
                      Permission
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="truck"
                      checked={true}
                    />
                    <label className="form-check-label" for="truck">
                      Truck
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="trailer"
                      checked={true}
                    />
                    <label className="form-check-label" for="calender">
                      Trailer
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column">
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="other"
                      checked={true}
                    />
                    <label className="form-check-label" for="other">
                      Other
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="Permission"
                      checked={true}
                    />
                    <label className="form-check-label" for="permission">
                      Permission
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="truck"
                      checked={true}
                    />
                    <label className="form-check-label" for="truck">
                      Truck
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="trailer"
                      checked={true}
                    />
                    <label className="form-check-label" for="calender">
                      Trailer
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column">
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="other"
                      checked={true}
                    />
                    <label className="form-check-label" for="other">
                      Other
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="Permission"
                      checked={true}
                    />
                    <label className="form-check-label" for="permission">
                      Permission
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="truck"
                      checked={true}
                    />
                    <label className="form-check-label" for="truck">
                      Truck
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="trailer"
                      checked={true}
                    />
                    <label className="form-check-label" for="calender">
                      Trailer
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column">
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="other"
                      checked={true}
                    />
                    <label className="form-check-label" for="other">
                      Other
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="Permission"
                      checked={true}
                    />
                    <label className="form-check-label" for="permission">
                      Permission
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="truck"
                      checked={true}
                    />
                    <label className="form-check-label" for="truck">
                      Truck
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="trailer"
                      checked={true}
                    />
                    <label className="form-check-label" for="calender">
                      Trailer
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column">
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="other"
                      checked=""
                    />
                    <label className="form-check-label" for="other">
                      Other
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="Permission"
                      checked=""
                    />
                    <label className="form-check-label" for="permission">
                      Permission
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="truck"
                      checked=""
                    />
                    <label className="form-check-label" for="truck">
                      Truck
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="trailer"
                      checked=""
                    />
                    <label className="form-check-label" for="calender">
                      Trailer
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column">
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="other"
                      checked=""
                    />
                    <label className="form-check-label" for="other">
                      Other
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="Permission"
                      checked=""
                    />
                    <label className="form-check-label" for="permission">
                      Permission
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="truck"
                      checked=""
                    />
                    <label className="form-check-label" for="truck">
                      Truck
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="trailer"
                      checked=""
                    />
                    <label className="form-check-label" for="calender">
                      Trailer
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column">
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="other"
                      checked=""
                    />
                    <label className="form-check-label" for="other">
                      Other
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="Permission"
                      checked=""
                    />
                    <label className="form-check-label" for="permission">
                      Permission
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="truck"
                      checked=""
                    />
                    <label className="form-check-label" for="truck">
                      Truck
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="trailer"
                      checked=""
                    />
                    <label className="form-check-label" for="calender">
                      Trailer
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row mt-4">
              <div className="col">
                <div className="d-flex flex-column">
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="other"
                      checked=""
                    />
                    <label className="form-check-label" for="other">
                      Other
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="Permission"
                      checked=""
                    />
                    <label className="form-check-label" for="permission">
                      Permission
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="truck"
                      checked=""
                    />
                    <label className="form-check-label" for="truck">
                      Truck
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="trailer"
                      checked=""
                    />
                    <label className="form-check-label" for="calender">
                      Trailer
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column">
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="other"
                      checked=""
                    />
                    <label className="form-check-label" for="other">
                      Other
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="Permission"
                      checked=""
                    />
                    <label className="form-check-label" for="permission">
                      Permission
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="truck"
                      checked=""
                    />
                    <label className="form-check-label" for="truck">
                      Truck
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="trailer"
                      checked=""
                    />
                    <label className="form-check-label" for="calender">
                      Trailer
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column">
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="other"
                      checked=""
                    />
                    <label className="form-check-label" for="other">
                      Other
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="Permission"
                      checked=""
                    />
                    <label className="form-check-label" for="permission">
                      Permission
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="truck"
                      checked=""
                    />
                    <label className="form-check-label" for="truck">
                      Truck
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="trailer"
                      checked=""
                    />
                    <label className="form-check-label" for="calender">
                      Trailer
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column">
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="other"
                      checked=""
                    />
                    <label className="form-check-label" for="other">
                      Other
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="Permission"
                      checked=""
                    />
                    <label className="form-check-label" for="permission">
                      Permission
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="truck"
                      checked=""
                    />
                    <label className="form-check-label" for="truck">
                      Truck
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="trailer"
                      checked=""
                    />
                    <label className="form-check-label" for="calender">
                      Trailer
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column">
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="other"
                      checked=""
                    />
                    <label className="form-check-label" for="other">
                      Other
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="Permission"
                      checked=""
                    />
                    <label className="form-check-label" for="permission">
                      Permission
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="truck"
                      checked=""
                    />
                    <label className="form-check-label" for="truck">
                      Truck
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="trailer"
                      checked=""
                    />
                    <label className="form-check-label" for="calender">
                      Trailer
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column">
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="other"
                      checked=""
                    />
                    <label className="form-check-label" for="other">
                      Other
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="Permission"
                      checked=""
                    />
                    <label className="form-check-label" for="permission">
                      Permission
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="truck"
                      checked=""
                    />
                    <label className="form-check-label" for="truck">
                      Truck
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="trailer"
                      checked=""
                    />
                    <label className="form-check-label" for="calender">
                      Trailer
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column">
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="other"
                      checked=""
                    />
                    <label className="form-check-label" for="other">
                      Other
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="Permission"
                      checked=""
                    />
                    <label className="form-check-label" for="permission">
                      Permission
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="truck"
                      checked=""
                    />
                    <label className="form-check-label" for="truck">
                      Truck
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="trailer"
                      checked=""
                    />
                    <label className="form-check-label" for="calender">
                      Trailer
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column">
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="other"
                      checked=""
                    />
                    <label className="form-check-label" for="other">
                      Other
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="Permission"
                      checked=""
                    />
                    <label className="form-check-label" for="permission">
                      Permission
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="truck"
                      checked=""
                    />
                    <label className="form-check-label" for="truck">
                      Truck
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="trailer"
                      checked=""
                    />
                    <label className="form-check-label" for="calender">
                      Trailer
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column">
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="other"
                      checked=""
                    />
                    <label className="form-check-label" for="other">
                      Other
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="Permission"
                      checked=""
                    />
                    <label className="form-check-label" for="permission">
                      Permission
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="truck"
                      checked=""
                    />
                    <label className="form-check-label" for="truck">
                      Truck
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="trailer"
                      checked=""
                    />
                    <label className="form-check-label" for="calender">
                      Trailer
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="d-flex flex-column">
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="other"
                      checked=""
                    />
                    <label className="form-check-label" for="other">
                      Other
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="Permission"
                      checked=""
                    />
                    <label className="form-check-label" for="permission">
                      Permission
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="truck"
                      checked=""
                    />
                    <label className="form-check-label" for="truck">
                      Truck
                    </label>
                  </div>
                  <div className="form-check form-check mb-1">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="trailer"
                      checked=""
                    />
                    <label className="form-check-label" for="calender">
                      Trailer
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-outline-light" onClick={onBack}>
          Back
        </button>
        <button className="btn btn-success" onClick={onSubmit}>
          Confirm
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPermissionModal;
