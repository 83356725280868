import React from "react";
import { Modal } from "react-bootstrap";

const ConfirmationAlertModal = (props) => {
  const {
    title,
    message,
    icon,
    buttons,
    showModal = true,
    onCancel = () => {},
    onSubmit = () => {},
  } = props;

  return (
    <Modal show={showModal} centered size="sm" backdrop="static">
      <Modal.Body className="p-0">
        <div className="text-center py-50">
          <div className="mb-30">{icon}</div>
          <div className="px-60">{title}</div>
          <p className="text-muted mt-10 font-14">{message}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>{buttons}</Modal.Footer>
    </Modal>
  );
};

export default ConfirmationAlertModal;
