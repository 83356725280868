import React from 'react';
import { IconCalendar } from '../../../../common/icons';
import CustomSelect from '../../../../common/CustomSelect';

const Trailer = () => {
  const options = [
    { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
    { value: 'John Doe', label: 'John Doe' },
    { value: 'Alex Mendes', label: 'Alex Mendes' },
  ];
  return (
    <div className="form-wrapper p-20">
      <form>
        <div className="form-row">
          <div className="col-md-4">
            <div className="form-row">
              <div className="form-group col-md">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Equipment ID
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Equipment ID"
                />
              </div>
              <div className="form-group col-md">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Model
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Model"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-row">
              <div className="form-group col-md">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Year
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter "
                />
              </div>
              <div className="form-group col-md">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Make
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter "
                />
              </div>
              <div className="form-group col-md">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Size
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter "
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-row">
              <div className="form-group col-md">
                <label className="col-form-label">
                  <span className="text-danger">*</span> License Plate
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter "
                />
              </div>
              <div className="form-group col-md">
                <label className="col-form-label">
                  <span className="text-danger">*</span> License Plate #
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter "
                />
              </div>
              <div className="form-group col-md">
                <label className="col-form-label">
                  <span className="text-danger">*</span> ITD
                </label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Select"
                  />
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md-4">
            <div className="form-row">
              <div className="form-group col-md">
                <label className="col-form-label">
                  <span className="text-danger">*</span> AID
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter VIN"
                />
              </div>
              <div className="form-group col-md">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Registration Exp
                </label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Registration Exp"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-row">
              <div className="form-group col-md">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Inspection Expiration
                </label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Select Date"
                  />
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                </div>
              </div>
              <div className="form-group col-md">
                <label className="col-form-label">
                  <span className="text-danger">*</span> HUT Expiration
                </label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Select Date"
                  />
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-row">
              <div className="form-group col-md">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Trailer Type
                </label>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select Trailer Type"
                  className=""
                />
              </div>
              <div className="form-group col-md">
                <label className="col-form-label">
                  <span className="text-danger">*</span> VIN
                </label>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter VIN"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Trailer;
