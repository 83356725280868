import React, { useState } from "react";
import plaidLogo from '../../../../assets/assets/images/payments/logo plaid.png';
import stripLogo from '../../../../assets/assets/images/payments/logo stripe.png';
import { CustomCreditCard } from '../../../../common/customIcons';

const PaymentType = () => {
    const [selectedType, setSelectedType] = useState(0);

    const handleSelectedType = (i) => {
        setSelectedType(selectedType === i ? null : i);
    }
    return (
        <div className="row justify-content-center no-gutters">
            <div className="col-4" onClick={() => handleSelectedType(1)}>
                <div className={`clist__card clist__card--outline d-flex align-items-center justify-content-center h-100 py-10 px-10 mr-10 ${selectedType === 1 ? "border-primary bg-brand-50" : ""}`}>
                    <CustomCreditCard className="mr-10" />
                    <div className="text-gray-900 font-weight-normal font-12">
                        Credit Card
                    </div>
                </div>
            </div>
            <div className="col-4" onClick={() => handleSelectedType(2)}>
                <div className={`clist__card clist__card--outline d-flex align-items-center justify-content-center h-100 py-10 px-10 mr-10 ${selectedType === 2 ? "border-primary bg-brand-50" : ""}`}>
                    <img src={plaidLogo} alt="" className="" />
                </div>
            </div>
            <div className="col-4" onClick={() => handleSelectedType(3)}>
                <div className={`clist__card clist__card--outline d-flex align-items-center justify-content-center h-100 py-10 px-10 mr-10 ${selectedType === 3 ? "border-primary bg-brand-50" : ""}`}>
                    <img src={stripLogo} alt="" className="" />
                </div>
            </div>
        </div>
    )
}

export default PaymentType;