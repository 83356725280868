import React, { useState } from "react";
import { IconPlus } from "../../../../common/icons";
import OtherTabs from "../../common-elements/OtherTabs";
import ProfileTabs from "../profileTabs";
import AddContactModal from "./elements/AddContactModal";
import Pagination from "../../../../common/pagination";
import SearchFilter from "./elements/SearchFilter";
import StaticTableContent from "./elements/StaticTableContent";

function ProfileContact() {
  //Add Contact Modal
  const [ContactshowModal, setContactShowModal] = useState(false);
  const handleContactShowModal = (event) => {
    setContactShowModal(true);
  };

  const handleContactHideModal = (event) => {
    setContactShowModal(false);
  };
  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <div className="subheader">
          <div className="subheader__inner d-flex align-items-center py-0">
            <OtherTabs activeTab={1} />
          </div>
        </div>
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center justify-content-between">
              <ProfileTabs
                activeTab={"Contact"}
                button={
                  <button
                    className=" btn btn-success shadow"
                    onClick={handleContactShowModal}
                  >
                    <IconPlus className="mr-2" />
                    Add Contact
                  </button>
                }
              />
            </div>
          </div>
          <div className="pb-15 d-flex align-items-center justify-content-between">
            <h4 className="font-20 mb-0">435 Contacts</h4>
            <Pagination />
          </div>
          <SearchFilter />
          <StaticTableContent />
        </div>
      </div>

      {ContactshowModal && (
        <AddContactModal
          onCancel={handleContactHideModal}
          onSubmit={handleContactHideModal}
        />
      )}
    </React.Fragment>
  );
}

export default ProfileContact;
