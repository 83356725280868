import React, { useState } from "react";
import { IconAngleArrowLeft,IconDownload } from "../../../../common/icons";

const CustomerCompanySubHeader = (props) => {
  const { hideCustomerDetails } = props;
  return (
    <React.Fragment>
      {/*  Sub Header Tabs*/}
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center">
          <div className="d-flex flex-row align-items-center">
            <button className="btn btn-outline-light mr-15" onClick={hideCustomerDetails}>
              <IconAngleArrowLeft />
            </button>
            <div className="font-20 font-weight-medium">
              Salam Pacific Indonesia Lines 
            </div>
          </div>
          <div className="ml-auto">
            <button className="btn btn-outline-light mr-2">
              <IconDownload className="mr-2" />
              Download
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerCompanySubHeader;
