import SignInScreen from "./Components/SignInScreen";
import SignupForm from "./Components/SignupForm";

const DrayosSignup = () => {
  return (
    <div className="signup-wrapper bg-gray-50">
      <div className="row no-gutters">
        <div className="col-lg-4 col-xl-3">
          <SignInScreen />
        </div>
        <div className="col-lg-8 col-xl-9">
          <SignupForm />
      </div>
      </div>
    </div>
  );
};

export default DrayosSignup;
