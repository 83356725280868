import React, { useState } from "react";
import { IconDownload, IconPlus, IconQuickBook } from "../../../common/icons";
import Pagination from "../../../common/pagination/index";
import CounterCards from "../common-elements/CounterCards";
import SubHeader from "../common-elements/SubHeader";
import StaticTableContent from "./elements/StaticTableContent";

const cardItems = [
  {
    id: 1,
    count: 540,
    title: "Available",
  },
  {
    id: 2,
    count: 703,
    title: "Dispatched",
  },
  {
    id: 3,
    count: 740,
    title: "Loaded",
  },
  {
    id: 4,
    count: 561,
    title: "Empty",
  },
];

const CustomerSettings = () => {
  const [isCardActive, setCardActive] = useState(1);
  const [showAddModal, setShowAddModal] = useState(false);

  const handleShowAddModal = (event) => {
    setShowAddModal(true);
  };

  const handleHideAddModal = (event) => {
    setShowAddModal(false);
  };

  return (
    <div className="position-relative subheader-wrapper">
      <SubHeader
        mainTitle="Customer"
        button={
          <div className="d-flex">
            <button className="ml-auto btn btn-outline-light mr-2">
              <IconDownload className="mr-3" />
              Export CSV
            </button>
            <button className="btn btn-outline-light mr-2">
              <IconQuickBook className="mr-2" />
              Connect to QuickBooks
            </button>
            <button
              className="ml-auto btn btn-success"
              onClick={handleShowAddModal}
            >
              <IconPlus className="mr-2" />
              Add New Customer
            </button>
          </div>
        }
      />
      <div className="page-fluid">
        <div className="top-page">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="font-20 mb-0">1720 Customers</h4>
            <Pagination />
          </div>

          {/* Cards Row */}
          <div className={`form-row ${isCardActive ? "hasactive" : ""}`}>
            {cardItems.map(({ id, title, count }) => (
              <CounterCards
                title={title}
                count={count}
                onCardClicked={() => setCardActive(id)}
                isCardActive={isCardActive === id}
              />
            ))}
          </div>
        </div>

        {/* Static Table Content */}
        <StaticTableContent />
      </div>
    </div>
  );
};

export default CustomerSettings;
