import React, { useState } from "react";
import { IconPlus } from "../../../common/icons";
import ReportsTabs from "../common-elements/ReportsTabs";
import AddNewReport from "./elements/addNewReport";
import StaticTableContent from "./elements/StaticTableContent";
import TopPage from "./elements/topPage";

const LoadMasterReport = () => {
  const [isAddNewReport, setIsAddNewReport] = useState(false);
  const showAddNewReport = () => {
    setIsAddNewReport(true);
  };
  const hideAddNewReport = () => {
    setIsAddNewReport(false);
  };
  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <ReportsTabs
          activeTab={8}
          buttons={
            <button className="btn btn-success ml-2" onClick={showAddNewReport}>
              <IconPlus className="mr-2" />
              Add New Report
            </button>
          }
        />
        <div className="page-fluid">
          <TopPage />
          <StaticTableContent />
        </div>
      </div>

      {isAddNewReport === true && (
        <AddNewReport
          hideAddNewReport={hideAddNewReport}
          showAddNewReport={showAddNewReport}
        />
      )}
    </React.Fragment>
  );
};

export default LoadMasterReport;
