import React, { useState } from "react";
import { IconEye, IconEyeClosed } from "../../../common/icons";
import CompanyInfoModal from '../Components/CompanyInfoModal';
import WelcomeModal from '../Components/WelcomeModal'

const SignupForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showCompanyInfoModal, setShowCompanyInfoModal] = useState(false);
  
  const togglePasswordVisibility = () => {
    setShowPassword(showPassword ? false : true);
  };
  
  
  return (
    <div className="signup-wrapper--right bg-animation h-100 p-20">
      <div className="d-flex align-items-center justify-content-end">
        <div className="text-muted mr-10">Already have an account?</div>
        <a href="/trackos/signup">
          <button className="btn btn btn-outline-light bg-white">Sign In</button>
        </a>
      </div>
      <div className="d-flex align-items-center justify-content-center h-100">
        <div className="form-wrapper w-max p-50 bg-white rounded-10 shadow-5">
          <h3 className="text-center mb-50">Start Improving Your Shipping!</h3>
          <form className="form-sm">
            <div className="form-group mb-20">
              <label className="mb-10">
                <span className="text-danger">*</span> Email Address
              </label>
              <input
                className="form-control p-10"
                placeholder="e.g. trucker@gmail.com"
              />
              <div className="text-gray-400 font-12 mt-1">
                Your email address will be used as your log in name.
              </div>
            </div>
            <div className="form-group mb-20">
              <label className="mb-10">
                <span className="text-danger">*</span> Password
              </label>
              <div className="input-wrapper">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control p-10"
                  placeholder="e.g. 1Password#"
                />
                <div className="input-icon" onClick={togglePasswordVisibility}>
                  {showPassword ? <IconEye /> : <IconEyeClosed />}
                </div>
              </div>
              <div className="text-gray-400 font-12 mt-1">
                Password must contain at least 1 Uppercase, 1 Number and 1 Symbol{" "}
              </div>
            </div>
            {/* <div className="form-group mb-20">
              <label className="mb-10">
                <span className="text-danger">*</span> Confirm Password
              </label>
              <div className="input-wrapper">
                <input
                  type="password"
                  className="form-control p-10"
                  placeholder="Repeat Password"
                />
                <div className="input-icon">
                  <IconEyeClosed />
                </div>
              </div>
              <div className="text-gray-400 font-12 mt-1">
                Password must contain at least 1 Uppercase, 1 Number and 1 Symbol{" "}
              </div>
            </div> */}
            <div className="form-group mt-40">
              {/* <a href="/trackos/company-info"> */}
              <button
                className="btn btn-lg btn-primary w-100 justify-content-center"
                type="button"
                onClick={() => setShowCompanyInfoModal((showCompanyInfoModal) => !showCompanyInfoModal)}
              >
                  Continue
                </button>
              {/* </a> */}
            </div>
          </form>
        </div>
      </div>
      
      {
        showCompanyInfoModal && <CompanyInfoModal show={showCompanyInfoModal} setShowCompanyInfoModal={setShowCompanyInfoModal} />
      }
      
      <WelcomeModal />
    </div>
  );
};

export default SignupForm;
