import React from "react";
import { Modal } from "react-bootstrap";
import { IconPhone, IconAt, IconReset } from "../../common/icons/index";
import Routing from "./tabContent/routing";
import Map from "../../assets/assets/images/Map.png";

const OrderStatusModal = (props) => {
  const { showModal = true, onCancel = () => {}, onUpdate = () => {} } = props;
  return (
    <Modal show={showModal} centered size="fullpage" scrollable>
      <div className="bg-gray-50 p-15 h-100 rounded-5">
        <Modal.Title className="d-flex align-items-center mb-10">
          <h5 className="font-20 mb-0">Load #: 1123444</h5>
          <div className="d-flex ml-auto">
            <button
              className="btn btn-white shadow-none mr-10"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button className="btn btn-success" onClick={onUpdate}>
              Update
            </button>
          </div>
        </Modal.Title>
        <Modal.Body className="p-0 h-100">
          <div className="form-row-md">
            <div className="col-md mt-10" style={{ maxWidth: 270 }}>
              <div className="card p-15 mb-1">
                <h5>Customer</h5>
                <div>ABC DISTRIBUTION</div>
                <div className="mb-10">
                  Main St, Boston, MA 02129, dominican republic
                </div>

                <div className="mb-10">
                  <IconPhone className="mr-2" />
                  <span>+7 (903) 880-91-85</span>
                </div>
                <div>
                  <IconAt className="mr-2" />
                  <span>michelle.rivera@example.com</span>
                </div>
              </div>
              <div className="card p-15 mb-1">
                <h5>Summary</h5>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Created by</span>
                  <span>Cameron Williamson</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Container #</span>
                  <span>SSF223788</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">BOL #</span>
                  <span>33422716</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Chassis #</span>
                  <span>ODBC956</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">SSL</span>
                  <span>OOCL</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Size</span>
                  <span>20'</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Type</span>
                  <span>ST</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Reference</span>
                  <span>2340009</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Total Miles</span>
                  <span>
                    2804.33 <IconReset className="ml-1 text-primary" />
                  </span>
                </div>
                <div className="mapboxgl-map-300 mt-10" style={{ height: 184 }}>
                  <img src={Map} className="wh-100" />
                </div>
              </div>
            </div>
            <div className="col-md">
              <Routing />
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default OrderStatusModal;
