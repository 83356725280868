import { useState } from "react";
import { Modal } from "react-bootstrap";
import { CustomIconSpreadSheet } from "../../../common/customIcons";
import { IconDownload, IconPlus, IconSendEmail } from "../../../common/icons";
import Pagination from "../../../common/pagination";
import TopCards from "../../../common/TopCards/TopCards";
import TrackOsBookingsTable from "./BookingsTable";
import TrackOsBookingsFilterRow from "./FilterRow";

const TrackOsBookings = ({}) => {
  const [showAddModal, setShowAddModal] = useState(false);

  const handleShowAddModal = (event) => {
    setShowAddModal(true);
  };
  const handleHideAddModal = (event) => {
    setShowAddModal(false);
  };
  const cardData = [
    {
      id: 1,
      cardMainName: "Containers on Vessel",
      cardSubNameOne: "On Hold",
      cardSubNameTwo: "Released",
    },
    {
      id: 2,
      cardMainName: "Empties Out",
      cardSubNameOne: "Last Free Days",
      cardSubNameTwo: "Pickup Appts",
    },

    {
      id: 3,
      cardMainName: "Loads In",
      cardSubNameOne: "In Port",
      cardSubNameTwo: "In Yard",
    },
  ];
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center">
          <div className="d-flex flex-row align-items-center justify-content-between w-100">
            <div className="font-14 font-medium">
              Bookings
            </div>
            <div className="ml-auto">
              <button className="btn btn-outline-light mr-2">
                <CustomIconSpreadSheet />
              </button>
              <button className="btn btn-outline-light mr-2">
                <IconDownload />
              </button>
              <button className="btn btn-outline-light mr-2">
                <IconSendEmail />
              </button>
              <button className="btn btn-success" onClick={handleShowAddModal}>
                <IconPlus className="mr-2" />
                Add New Booking
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-15 d-flex align-items-center">
            <h4 className="mb-0">757 Bookings</h4>
            <Pagination className={"ml-auto"} />
          </div>
          {/*Cards */}
          <TopCards cardData={cardData} />
          {/* Filter Row */}
          <TrackOsBookingsFilterRow />
          <TrackOsBookingsTable />
        </div>
      </div>

      <Modal show={showAddModal} centered animation={false} size="sm">
        <Modal.Header>
          <Modal.Title>Add New Booking(s)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-wrapper">
            <div className="form-group">
              <label>Enter up to 100 bookings</label>
              <textarea
                className="form-control"
                placeholder="e.g. Booking 1, Booking 2&#10;or&#10;Booking 1&#10;Booking 2&#10;Booking 3"
                rows="8"
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" onClick={handleHideAddModal}>
            Cancel
          </button>
          <button className="btn btn-primary">Add Bookings</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TrackOsBookings;
