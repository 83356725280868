import React, { useEffect, useRef } from 'react';
import { debounce } from 'lodash';

const DynamicTableHeight = ({ pageId }) => {
  const resizeObserverRef = useRef(null);

  useEffect(() => {
    const dynamicTableId = `dth-table-${pageId}`;
    const topPageId = "dth-top-page";
    const tableRef = document.getElementById(dynamicTableId);
    const topPageRef = document.getElementById(topPageId);

    const handleResize = debounce((entries) => {
      for (const entry of entries) {
        if (entry.target.id === dynamicTableId || entry.target.id === topPageId) {
          const tableOffsetTop = tableRef.getBoundingClientRect().top;

          requestAnimationFrame(() => {
            document.documentElement.style.setProperty('--dynamic-table-height-offset', `${tableOffsetTop}px`);
          });
        }
      }
    }, 300);

    resizeObserverRef.current = new ResizeObserver(handleResize);

    if (tableRef) {
      resizeObserverRef.current.observe(tableRef);
    }

    if (topPageRef) {
      resizeObserverRef.current.observe(topPageRef);
    }

    return () => {
      if (tableRef) {
        resizeObserverRef.current.unobserve(tableRef);
      }

      if (topPageRef) {
        resizeObserverRef.current.unobserve(topPageRef);
      }
    };
  }, [pageId]);

  return (<div id={`dth-table-${pageId}`}/>);
};

export default DynamicTableHeight;
