import React, { useState } from 'react';
import SubHeader from '../subheader';
import { IconPlus } from '../../../common/icons/index';
import Pagination from '../common-elements/Pagination';
import StaticTableContent from '../../driver/driverPayRates/StaticTableContent';
import CreateGroupModal from './modalTabComponent/CreateGroupModal';

const DriverPayRates = () => {
  const [GroupshowModal, setGroupShowModal] = useState(false);
  const handleGroupShowModal = (event) => {
    setGroupShowModal(true);
  };

  const handleGroupHideModal = (event) => {
    setGroupShowModal(false);
  };
  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <div className="subheader">
          <div className="subheader__inner d-flex align-items-center py-0">
            <SubHeader
              activeTab={4}
              button={
                <div className="d-flex">
                  <button
                    className="ml-auto btn btn-success"
                    onClick={handleGroupShowModal}
                  >
                    <IconPlus className="mr-2" />
                    Add New Load
                  </button>
                </div>
              }
            />
          </div>
        </div>
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center justify-content-between">
              <h4 className="font-20 mb-0">55 Groups</h4>
              <Pagination />
            </div>
            <StaticTableContent />
          </div>
        </div>
      </div>
      {GroupshowModal && (
        <CreateGroupModal
          onCancel={handleGroupHideModal}
          onSubmit={handleGroupHideModal}
        />
      )}
    </React.Fragment>
  );
};

export default DriverPayRates;
