import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomSelect from "../../../../common/CustomSelect";
import CustomCreatableSelect from "../../../../common/CustomSelect/creatable";
import { IconClock, IconMinus, IconPlus } from "../../../../common/icons";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const AddAutoEmail = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;
  const [tabActive, setTabActive] = useState(2);

  const handleTabActive = (i) => {
    setTabActive(i);
  };

  return (
    <Modal show={showModal} centered animation={false} size="sm">
      <Modal.Header>
        <Modal.Title>Add New Report</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="form-wrapper">
          <form>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Report Name
                </label>
                <input
                  type="text"
                  placeholder="Enter Report Name"
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Email
                </label>
                <CustomCreatableSelect
                  name={"name"}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Enter Email"
                />
                <div className="text-muted mt-1">
                  Use ,(comma) for entering mulitple emails
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label mb-10">
                  <span className="text-danger">*</span> Frequency
                </label>
                <div className="form-check mb-10">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="Import"
                    name="Import"
                  />
                  <label className="form-check-label" for="Import">
                    Daily
                  </label>
                </div>
                <div className="form-check mb-10">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="Import"
                    name="Import"
                  />
                  <label className="form-check-label" for="Import">
                    Weekly
                  </label>
                </div>
                <div className="form-check mb-10">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="Import"
                    name="Import"
                  />
                  <label className="form-check-label" for="Import">
                    Every 2 Weeks
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="Import"
                    name="Import"
                  />
                  <label className="form-check-label" for="Import">
                    Specific Day of each month
                  </label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select"
                    className="w-150 ml-10"
                  />
                </div>
              </div>
            </div>
            <hr className="my-10" />
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="col-form-label">Time</label>
                <div className="d-flex">
                  <div className="d-flex align-items-center mr-15">
                    <div className="input-wrapper">
                      <input
                        className="form-control text-dark"
                        placeholder="09:00am"
                      />
                      <div className="input-icon">
                        <IconClock />
                      </div>
                    </div>
                    <div className="btn-wrapper ml-10">
                      <button className="btn btn-xs bg-soft-danger rounded-lg">
                        <IconMinus className="text-danger wh-10px" />
                      </button>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mr-15">
                    <div className="input-wrapper">
                      <input
                        className="form-control text-dark"
                        placeholder="Select"
                      />
                      <div className="input-icon">
                        <IconClock />
                      </div>
                    </div>
                    <div className="btn-wrapper ml-10">
                      <button className="btn btn-xs bg-soft-danger rounded-lg">
                        <IconMinus className="text-danger wh-10px" />
                      </button>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="input-wrapper">
                      <input
                        className="form-control text-dark"
                        placeholder="Select"
                      />
                      <div className="input-icon">
                        <IconClock />
                      </div>
                    </div>
                    <div className="btn-wrapper ml-10">
                      <button className="btn btn-xs bg-soft-danger rounded-lg">
                        <IconMinus className="text-danger wh-10px" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10">
              <a className="text-primary">
                <IconPlus className="wh-10px mr-2" /> Add Time
              </a>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-success" onClick={onSubmit}>
          Complete
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddAutoEmail;
