import React, { useState } from 'react';
import {
  IconColumn,
  IconFilter,
  IconCalendar,
} from '../../../../common/icons/index';
import CustomSelect from '../../../../common/CustomSelect';
import { SelectionState } from 'draft-js';
import FilterSettings from './FilterSettings';

const options = [
  { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
  { value: 'John Doe', label: 'John Doe' },
  { value: 'Alex Mendes', label: 'Alex Mendes' },
];

const SearchFilter = () => {
  const [openPopup, setOpenPopup] = useState(false);

  const handleShowPopup = (event) => {
    document.body.classList.add('bdpopup-open');
    setOpenPopup(true);
  };

  const handleHidePopup = (event) => {
    document.body.classList.add('bdpopup-open');
    setOpenPopup(false);
  };

  return (
    <React.Fragment>
      <div className="filter-row d-flex align-items-center mt-2">
        {/* Search Bar */}
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
              style={{ width: '150px' }}
            />
          </div>
        </div>
        {/*  From To Date*/}
        {/* <div className="d-flex mr-4">
          <div className="input-wrapper mr-2">
            <input className="form-control" placeholder="From" />
            <div className="input-icon">
              <IconCalendar />
            </div>
          </div>
          <div className="input-wrapper">
            <input className="form-control" placeholder="To" />
            <div className="input-icon">
              <IconCalendar />
            </div>
          </div>
        </div> */}
        {/* Select Customer */}
        <div className="d-flex mr-3 mr-4">
          <CustomSelect
            name={'name'}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select Customer"
            className="w-150 mr-2 z-2"
          />
          {/* Select Sub Customer */}
          {/* <CustomSelect
            name={"name"}
            options={options}
            value={1}
            isMulti={true}
            isClearable={true}
            size="small"
            placement="right"
            placeholder="Select Sub Customer"
            className="w-200 z-2"
          /> */}
        </div>
        {/* ALl SubCustomers and Email Sent Checkbox */}
        {/* <div className="d-flex mr-3 mr-4">
          <div className="form-check form-check--gray pr-30 mr-2">
            <input
              className="form-check-input"
              type="checkbox"
              id="allSubCustomers"
              checked={true}
            />
            <label className="form-check-label" for="allSubCustomers">
              All Sub Customers
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                47
              </span>
            </label>
          </div>
          <div className="form-check form-check--gray pr-30">
            <input
              className="form-check-input"
              type="checkbox"
              id="emailSent"
              checked={true}
            />
            <label className="form-check-label" for="emailSent">
              Email Sent
              <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                6
              </span>
            </label>
          </div>
        </div> */}
        {/* Filter Settings */}
        <div className="ml-auto">
          <button
            className="btn btn-outline-light mr-2"
            onClick={handleShowPopup}
          >
            <IconFilter className="mr-2" />
            Filter
          </button>
          <button className="btn btn-outline-light">
            <IconColumn />
          </button>
        </div>
      </div>
      {/* Filter Settings Popup */}
      {openPopup && <FilterSettings onClose={handleHidePopup} />}
    </React.Fragment>
  );
};

export default SearchFilter;
