import React, { useState } from "react";

const ChartCard = (props) => {
  const {
    id = "",
    title = "",
    count = "",
    price = "",
    date = "",
    chart = "",
    isCardActive = true,
    onCardClicked = () =>
      console.error("You passed no action to the component"),
  } = props;

  return (
    <React.Fragment>
      <div className="col-md my-2">
        <div
          className={`ncard d-flex flex-column justify-content-between p-3 ${
            isCardActive ? "active" : ""
          }`}
          onClick={onCardClicked}
        >
          <div className="d-flex align-items-center mb-10">
            <div
              className={`mr-1 sbox  px-2 h-100 ${
                isCardActive ? "bg-primary text-white" : "sbox--default"
              }`}
            >
              {count}
            </div>
            <div className="ml-2">
              <h6 className="font-weight-bold mb-0">{title}</h6>
              <span className="text-muted">{date}</span>
            </div>
            <div className="ml-auto">
              <input
                className="form-check-input rounded-3"
                type="checkbox"
                id="sr_no"
                checked={isCardActive ? "true" : ""}
              />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between form-row mt-n3">
            <div className="col-6">
              <div className="font-weight-bold font-20 mt-3">
                ${price}
                <span className="font-size-regular">.45</span>
              </div>
            </div>
            <div className="col-6 d-flex align-items-center justify-content-end mt-n2">
              {chart}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChartCard;
