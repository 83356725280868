import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { IconPlus } from "../../../common/icons";
import PaymentModal from "./Components/PaymentModal";
import PlanModal from "./Components/PlanModal";
import SubscriptionModal from "./Components/SubscriptionModal";

const PaymentOptions = () => {
    const [paymentOptionsModal, setPaymentOptionsModal] = useState(false);
    const [subscriptionModal, setSubscriptionModal] = useState(false);
    const [planModal, setPlanModal] = useState(false);

    const showPaymentOptionsModal = () => {
        setPaymentOptionsModal(true);
    };
    const hidePaymentOptionsModal = () => {
        setPaymentOptionsModal(false);
    };

    const showSubscriptionModal = () => {
        setSubscriptionModal(true);
        setPaymentOptionsModal(false);
    };
    const hideSubscriptionModal = () => {
        setSubscriptionModal(false);
        setPaymentOptionsModal(true);
    };
    const showPlanModal = () => {
        setPlanModal(true);
        setSubscriptionModal(false);
    };
    const hidePlanModal = () => {
        setPlanModal(false);
        setSubscriptionModal(true);
    };
    return (
        <div className="position-relative subheader-wrapper">
            <div className="subheader">
                <div className="subheader__inner d-flex align-items-center py-0">
                    <div className="d-flex flex-row align-items-center justify-content-between w-100">
                        <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                            <li className="nav-item">
                                <a href="#" className={`nav-link`}>
                                    <div className="">Payment Options</div>
                                </a>
                            </li>
                        </ul>
                        <div className="ml-auto">
                            <button className="btn btn-success" onClick={showPaymentOptionsModal}>
                                <IconPlus className="mr-2" />
                                Show Payment Options
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Payment Options Modal */}
            {paymentOptionsModal && <PaymentModal showModal={showSubscriptionModal} hideModal={hidePaymentOptionsModal} />}

            {/* Subscription Modal */}
            {subscriptionModal && <SubscriptionModal showModal={showSubscriptionModal} showNextModal={showPlanModal}  hideModal={hideSubscriptionModal}/>}

            {planModal && <PlanModal showModal={showPlanModal} hideModal={hidePlanModal} />}

        </div>
    );
};

export default PaymentOptions;
