import React, { useState } from "react";
import {
  IconAngleArrowLeft,
  IconAngleArrowRight,
  IconPlus,
  IconCaretDown,
  IconCalendar,
} from "../../../common/icons/index";
import ChartCard from "./ChartCard";
import Pagination from "./Pagination";
import Chart from "react-apexcharts";

const cardItems = [
  {
    id: 1,
    count: 8,
    date: "26 July",
    title: "Monday",
    price: 33,
  },
  {
    id: 2,
    count: 8,
    date: "27 July",
    title: "Tuesday",
    price: 99,
  },
  {
    id: 3,
    count: 8,
    date: "28 July",
    title: "Wednesday",
    price: 445,
  },
  {
    id: 4,
    count: 8,
    date: "30 July",
    title: "Thrusday",
    pprice: 366,
  },
  {
    id: 5,
    count: 12,
    date: "31 July",
    title: "Friday",
    price: 14,
  },
  {
    id: 6,
    count: 8,
    date: "1 Aug",
    title: "Saturday",
    price: 113,
  },
  {
    id: 7,
    count: 8,
    date: "1 Aug",
    title: "Sunday",
    price: 113,
  },
];

const options = {
  chart: {
    id: "driver-pay-chart",
    toolbar: {
      show: false,
    },
  },
  colors: [
    function ({ value, seriesIndex, w }) {
      if (value == 20) {
        return "#367BF6";
      } else {
        return "#DBE2EB";
      }
    },
  ],
  plotOptions: {
    bar: {
      columnWidth: "55%",
      barHeight: '100%',
      endingShape: "rounded",
      borderRadius: 1,
    },
  },

  xaxis: {
    show: false,
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  sparkline: {
    enabled: true,
  },
  tooltip: {
    enabled: false,
  },
  grid: {
    show: false,
  },
};

const series = [
  {
    name: "series-1",
    data: [20, 60, 100, 90, 10, 40, 70],
  },
];

const TopPage = () => {
  const [CardActive, setCardActive] = useState(1);

  return (
    <div className="top-page">
      <div className="pb-15 d-flex align-items-center">
        <div className="d-flex align-items-center">
          <button className="btn btn-white mr-3">
            <IconAngleArrowLeft />
          </button>
          <div className="d-flex align-items-center mr-3">
            <h4 className="font-20 mb-0">This week</h4>
            <IconCaretDown />
          </div>
          <button className="btn btn-white mr-3">
            <IconAngleArrowRight />
          </button>
          <button className="btn btn-white">
            <IconCalendar />
          </button>
        </div>
        {/* Pagination  */}
        <Pagination />
      </div>
      {/* Cards Row */}
      <div className={`form-row ${CardActive ? "hasactive" : ""}`}>
        {cardItems.map(({ id, title, date, count, price, chart }) => (
          <ChartCard
            title={title}
            date={date}
            count={count}
            price={price}
            chart={<Chart options={options} series={series} type="bar" width="120" height="90" />}
            onCardClicked={() => setCardActive(id)}
            isCardActive={CardActive === id}
          />
        ))}
      </div>
    </div>
  );
};

export default TopPage;
