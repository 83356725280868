import React, { useState } from 'react';
import ChassisTabs from '../ChassisTabs';
import Pagination from '../../../../common/pagination';
import StaticTableContent from './elements/StaticTableContent';
import SettingsTab from '../../common-elements/SettingsTabs';
import { IconDownload, IconPlus } from '../../../../common/icons';

const ChassisType = () => {
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <SettingsTab
            activeTab={6}
            button={
              <div className="d-flex">
                <button className="mr-2 btn btn-outline-light ">
                  <IconDownload className="mr-2" />
                  Downlaod CVS
                </button>
                <button className=" btn btn-success ">
                  <IconPlus className="mr-2" />
                  Add Chasis Owner
                </button>
              </div>
            }
          />
        </div>
      </div>
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-15 d-flex align-items-center justify-content-between">
            <h4 className="font-20 mb-0">7 Chassis Types</h4>
            <Pagination />
          </div>
          <ChassisTabs activeTab={'Type'} />
        </div>

        {/* Static table Content */}
        <StaticTableContent />
      </div>
    </div>
  );
};

export default ChassisType;
