import React, { useState } from "react";
import BillingTabs from "../common-elements/BillingTabs";
import Pagination from "../common-elements/Pagination";
import SearchFilter from "./elements/SearchFilter";
import StaticTableContent from "./elements/StaticTableContent";

const DesyncedQBLoads = () => {
  const [tabActive, setTabActive] = useState(1);

  const handleTabActive = (i) => {
    setTabActive(i);
  };

  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <BillingTabs activeTab={6} />
        </div>
      </div>
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-10 d-flex align-items-center">
            {/* Tabs */}
            <div className="d-flex flex-row align-items-center justify-content-between">
              <ul className="nav nav-compact bg-light" role="tablist">
                <li className="nav-item">
                  <a
                    className={`nav-link ${tabActive === 1 ? "active" : ""}`}
                    data-bs-toggle="tab"
                    href="#Loads"
                    role="tab"
                    onClick={() => handleTabActive(1)}
                  >
                    Main Customer
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${tabActive === 2 ? "active" : ""}`}
                    data-bs-toggle="tab"
                    href="#payments"
                    role="tab"
                    onClick={() => handleTabActive(2)}
                  >
                    Sub Customer
                  </a>
                </li>
              </ul>
            </div>
            {/* Pagination */}
            <Pagination />
          </div>
        </div>
        <SearchFilter />
        <StaticTableContent />
      </div>
    </div>
  );
};

export default DesyncedQBLoads;
