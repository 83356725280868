import React, { Component } from 'react';

class FilterSettings extends Component {
    render() {
        return (
            <React.Fragment>
                
            </React.Fragment>
        );
    }
}

export default FilterSettings;