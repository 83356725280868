import { SetStateAction, useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  IconAngleArrowRight,
  IconCheck,
  IconEye,
  IconEyeClosed,
  IconAngleArrowDown,
  IconInfo,
} from '../../common/icons/index';

const PortLogin = (props) => {
  const { showModal, hideModal } = props;
  const [selectedPort, setSelectedPort] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPortCred, setShowPortCred] = useState(1);
  const handleSelectedPort = () => {
    setSelectedPort(!selectedPort);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(showPassword ? false : true);
  };

  const handlePortCred = (i) => {
    setShowPortCred(showPortCred === i ? null : i);
  };

  return (
    <Modal show={showModal} centered animation={false} size="xl">
      <Modal.Header className="">
        <Modal.Title>Port of NJ/NY</Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-0'>
        <form className="mb-1">
          <div className="row align-items-center bg-warning-50 py-2 rounded-3 no-gutters">
            <div className="col-4">
              <div className="px-10">
                <label className="font-14 font-weight-500">MAHER</label>
                <p className="text-warning-500 mb-0">Credentials Required</p>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group mb-0 mr-10">
                <input
                  className="form-control border-orange"
                  placeholder="Enter login"
                />
              </div>
            </div>
            <div className="col-4">
              <div className="input-wrapper mr-2">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control border-orange"
                  placeholder="Enter Password"
                />
                <div className="input-icon" onClick={togglePasswordVisibility}>
                  {showPassword ? <IconEye /> : <IconEyeClosed />}
                </div>
              </div>
            </div>
          </div>
        </form>
        <form className="mb-2">
          <div className="row align-items-center bg-danger-light py-2 rounded-3 no-gutters">
            <div className="col-4">
              <div className="px-10">
                <label className="font-14 font-weight-500">VIG</label>
                <p className="text-error-500 mb-0">
                  Login or Password is wrong
                </p>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group mb-0 mr-10">
                <input
                  className="form-control border-error-500"
                  placeholder="Port Username"
                />
              </div>
            </div>
            <div className="col-4">
              <div className="input-wrapper mr-2">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control border-error-500"
                  placeholder="Enter Password"
                />
                <div className="input-icon" onClick={togglePasswordVisibility}>
                  {showPassword ? <IconEye /> : <IconEyeClosed />}
                </div>
              </div>
            </div>
          </div>
        </form>
        <form>
          <div className="row align-items-center badge-brand-50 py-2 rounded-3 no-gutters">
            <div className="col-4">
              <div className="px-10">
                <label className="font-14 font-weight-500 text-gray-900">
                  Miami Port
                </label>
                <p className="text-brand-500 mb-0">Connected!</p>
              </div>
            </div>
            <div className="col-4">
              <span className="badge-gray-200 mr-10 rounded-circle mr-15 w-20px h-20px d-inline-flex align-items-center justify-content-center">
                <IconInfo />
              </span>
              <h5 className="font-14 d-inline-block mb-0">
                There is no login required
              </h5>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          className=" btn btn-light py-2 mr-10"
          onClick={showModal}
        >
          Cancel
        </button>
        <button className="btn btn-primary py-2" onClick={hideModal}>
          Apply & Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PortLogin;
