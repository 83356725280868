import React from 'react';
import CustomSelect from '../../../../common/CustomSelect';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const GeneralInfo = () => {
  return (
    <div className="form-wrapper p-30">
      <form>
        <div className="form-row ">
          <div className="form-group col-md-12">
            <label className="col-form-label">
              <span className="text-danger">*</span> Group Name
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Group Name"
            />
          </div>
        </div>
        <div className="form-row ">
          <div className="form-group col-md-6">
            <label className="col-form-label">
              <span className="text-danger">*</span> Payment Type
            </label>
            <CustomSelect
              name={'name'}
              options={options}
              value={1}
              isMulti={true}
              isClearable={true}
              size="small"
              placement="right"
              placeholder="Select Here.."
            />
          </div>

          <div className="form-group col-md-6">
            <label className="col-form-label">
              <span className="text-danger">*</span> Value
            </label>
            <input type="text" className="form-control" placeholder="$ 0.00" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default GeneralInfo;
