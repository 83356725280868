import React from 'react';
import { IconReset, IconTimesThin } from '../../../../../common/icons';

const FilterPop = (props) => {
  const { hideFilter } = props;
   const [checked, setChecked] = React.useState(true);
   return (
     <React.Fragment>
       <div className="bdpopup-backdrop"></div>
       <div className="bdpopup">
         <div className="bdpopup__content">
           <button
             className="btn btn-none bdpopup__close text-white"
             onClick={hideFilter}
           >
             <IconTimesThin className="close-icon" />
           </button>
           <div className="d-flex px-30 align-items-center justify-content-between pt-20">
             <p className="font-20 mb-0">Filters</p>
             <button className="btn btn-outline-light">
               <IconReset className="" />
             </button>
           </div>
           <React.Fragment>
             <div
               className="px-30 bdpopup__content__scroll"
               style={{ height: 'calc(100% - 182px)' }}
             >
               <div className="row">
                 <div className="col-md-12 d-flex align-items-center mt-20">
                   <div className="form-check">
                     <input
                       className="form-check-input"
                       type="checkbox"
                       id="SSL"
                       defaultChecked={checked}
                       onChange={() => setChecked(!checked)}
                     />
                     <label
                       className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                       for="SSL"
                     >
                       SSL
                     </label>
                   </div>
                 </div>
               </div>
               <div className="row">
                 <div className="col-md-4 d-flex align-items-center mt-20">
                   <div className="form-check">
                     <input
                       className="form-check-input"
                       type="checkbox"
                       id="CHV"
                       defaultChecked={checked}
                       onChange={() => setChecked(!checked)}
                     />
                     <label
                       className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                       for="CHV"
                     >
                       CHV
                     </label>
                   </div>
                 </div>
                 <div className="col-md-4 d-flex align-items-center mt-20">
                   <div className="form-check">
                     <input
                       className="form-check-input"
                       type="checkbox"
                       id="MSC"
                       defaultChecked={checked}
                       onChange={() => setChecked(!checked)}
                     />
                     <label
                       className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                       for="MSC"
                     >
                       MSC
                     </label>
                   </div>
                 </div>
                 <div className="col-md-4 d-flex align-items-center mt-20">
                   <div className="form-check">
                     <input
                       className="form-check-input"
                       type="checkbox"
                       id="Hapag-Lloyd"
                       defaultChecked={checked}
                       onChange={() => setChecked(!checked)}
                     />
                     <label
                       className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                       for="Hapag-Lloyd"
                     >
                       Hapag-Lloyd
                     </label>
                   </div>
                 </div>
                 <div className="col-md-4 d-flex align-items-center mt-20">
                   <div className="form-check">
                     <input
                       className="form-check-input"
                       type="checkbox"
                       id="BAL"
                       defaultChecked={checked}
                       onChange={() => setChecked(!checked)}
                     />
                     <label
                       className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                       for="BAL"
                     >
                       BAL
                     </label>
                   </div>
                 </div>
                 <div className="col-md-4 d-flex align-items-center mt-20">
                   <div className="form-check">
                     <input
                       className="form-check-input"
                       type="checkbox"
                       id="COSCO"
                       defaultChecked={checked}
                       onChange={() => setChecked(!checked)}
                     />
                     <label
                       className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                       for="COSCO"
                     >
                       COSCO
                     </label>
                   </div>
                 </div>
                 <div className="col-md-4 d-flex align-items-center mt-20">
                   <div className="form-check">
                     <input
                       className="form-check-input"
                       type="checkbox"
                       id="ONE"
                       defaultChecked={checked}
                       onChange={() => setChecked(!checked)}
                     />
                     <label
                       className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                       for="ONE"
                     >
                       ONE
                     </label>
                   </div>
                 </div>
                 <div className="col-md-4 d-flex align-items-center mt-20">
                   <div className="form-check">
                     <input
                       className="form-check-input"
                       type="checkbox"
                       id="MAERSK"
                       defaultChecked={checked}
                       onChange={() => setChecked(!checked)}
                     />
                     <label
                       className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                       for="MAERSK"
                     >
                       MAERSK
                     </label>
                   </div>
                 </div>
                 <div className="col-md-4 d-flex align-items-center mt-20">
                   <div className="form-check">
                     <input
                       className="form-check-input"
                       type="checkbox"
                       id="CMACGM"
                       defaultChecked={checked}
                       onChange={() => setChecked(!checked)}
                     />
                     <label
                       className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                       for="CMACGM"
                     >
                       CMA CGM
                     </label>
                   </div>
                 </div>
                 <div className="col-md-4 d-flex align-items-center mt-20">
                   <div className="form-check">
                     <input
                       className="form-check-input"
                       type="checkbox"
                       id="Evergreen"
                       defaultChecked={checked}
                       onChange={() => setChecked(!checked)}
                     />
                     <label
                       className="form-check-label  font-weight-500 font-14 d-flex align-items-center"
                       for="Evergreen"
                     >
                       Evergreen
                     </label>
                   </div>
                 </div>
                 <div className="col-md-4 d-flex align-items-center mt-20">
                   <div className="form-check">
                     <input
                       className="form-check-input"
                       type="checkbox"
                       id="HMM"
                       defaultChecked={checked}
                       onChange={() => setChecked(!checked)}
                     />
                     <label
                       className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                       for="HMM"
                     >
                       HMM
                     </label>
                   </div>
                 </div>
                 <div className="col-md-4 d-flex align-items-center mt-20">
                   <div className="form-check">
                     <input
                       className="form-check-input"
                       type="checkbox"
                       id="YangMing"
                       defaultChecked={checked}
                       onChange={() => setChecked(!checked)}
                     />
                     <label
                       className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                       for="YangMing"
                     >
                       Yang Ming
                     </label>
                   </div>
                 </div>
                 <div className="col-md-4 d-flex align-items-center mt-20">
                   <div className="form-check">
                     <input
                       className="form-check-input"
                       type="checkbox"
                       id="WanHai"
                       defaultChecked={checked}
                       onChange={() => setChecked(!checked)}
                     />
                     <label
                       className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                       for="WanHai"
                     >
                       Wan Hai
                     </label>
                   </div>
                 </div>
               </div>
               <div className="hr-light"></div>
               <React.Fragment>
                 <div className="row">
                   <div className="col-md-12 d-flex align-items-center">
                     <div className="form-check">
                       <input
                         className="form-check-input"
                         type="checkbox"
                         id="ContainerTypes"
                         defaultChecked={checked}
                         onChange={() => setChecked(!checked)}
                       />
                       <label
                         className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                         for="ContainerTypes"
                       >
                         Container Types
                       </label>
                     </div>
                   </div>
                 </div>
                 <div className="row">
                   <div className="col-md-4 d-flex align-items-center mt-20">
                     <div className="form-check">
                       <input
                         className="form-check-input"
                         type="checkbox"
                         id="20ST"
                         defaultChecked={checked}
                         onChange={() => setChecked(!checked)}
                       />
                       <label
                         className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                         for="20ST"
                       >
                         20 ST
                       </label>
                     </div>
                   </div>
                   <div className="col-md-4 d-flex align-items-center mt-20">
                     <div className="form-check">
                       <input
                         className="form-check-input"
                         type="checkbox"
                         id="45"
                         defaultChecked={checked}
                         onChange={() => setChecked(!checked)}
                       />
                       <label
                         className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                         for="45"
                       >
                         45
                       </label>
                     </div>
                   </div>
                   <div className="col-md-4 d-flex align-items-center mt-20">
                     <div className="form-check">
                       <input
                         className="form-check-input"
                         type="checkbox"
                         id="20FlatRocks"
                         defaultChecked={checked}
                         onChange={() => setChecked(!checked)}
                       />
                       <label
                         className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                         for="20FlatRocks"
                       >
                         20 Flat Rocks
                       </label>
                     </div>
                   </div>
                   <div className="col-md-4 d-flex align-items-center mt-20">
                     <div className="form-check">
                       <input
                         className="form-check-input"
                         type="checkbox"
                         id="40ST"
                         defaultChecked={checked}
                         onChange={() => setChecked(!checked)}
                       />
                       <label
                         className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                         for="40ST"
                       >
                         40 ST
                       </label>
                     </div>
                   </div>
                   <div className="col-md-4 d-flex align-items-center mt-20">
                     <div className="form-check">
                       <input
                         className="form-check-input"
                         type="checkbox"
                         id="20Reefer"
                         defaultChecked={checked}
                         onChange={() => setChecked(!checked)}
                       />
                       <label
                         className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                         for="20Reefer"
                       >
                         20 Reefer
                       </label>
                     </div>
                   </div>
                   <div className="col-md-4 d-flex align-items-center mt-20">
                     <div className="form-check">
                       <input
                         className="form-check-input"
                         type="checkbox"
                         id="SpecialEq"
                         defaultChecked={checked}
                         onChange={() => setChecked(!checked)}
                       />
                       <label
                         className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                         for="Special Eq"
                       >
                         Special Eq
                       </label>
                     </div>
                   </div>
                   <div className="col-md-4 d-flex align-items-center mt-20">
                     <div className="form-check">
                       <input
                         className="form-check-input"
                         type="checkbox"
                         id="40HC"
                         defaultChecked={checked}
                         onChange={() => setChecked(!checked)}
                       />
                       <label
                         className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                         for="40HC"
                       >
                         40 HC
                       </label>
                     </div>
                   </div>
                   <div className="col-md-4 d-flex align-items-center mt-20">
                     <div className="form-check">
                       <input
                         className="form-check-input"
                         type="checkbox"
                         id="40Reefer"
                         defaultChecked={checked}
                         onChange={() => setChecked(!checked)}
                       />
                       <label
                         className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                         for="40Reefer"
                       >
                         40 Reefer
                       </label>
                     </div>
                   </div>
                 </div>
               </React.Fragment>
               <div className="hr-light"></div>
               <React.Fragment>
                 <div className="row">
                   <div className="col-md-12 d-flex align-items-center">
                     <div className="form-check">
                       <input
                         className="form-check-input"
                         type="checkbox"
                         id="Terminals"
                         defaultChecked={checked}
                         onChange={() => setChecked(!checked)}
                       />
                       <label
                         className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                         for="Terminals"
                       >
                         Terminals
                       </label>
                     </div>
                   </div>
                 </div>
                 <div className="row">
                   <div className="col-md-4 d-flex align-items-center mt-20">
                     <div className="form-check">
                       <input
                         className="form-check-input"
                         type="checkbox"
                         id="20ST"
                         defaultChecked={checked}
                         onChange={() => setChecked(!checked)}
                       />
                       <label
                         className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                         for="20ST"
                       >
                         APM
                       </label>
                     </div>
                   </div>
                   <div className="col-md-4 d-flex align-items-center mt-20">
                     <div className="form-check">
                       <input
                         className="form-check-input"
                         type="checkbox"
                         id="45"
                         defaultChecked={checked}
                         onChange={() => setChecked(!checked)}
                       />
                       <label
                         className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                         for="45"
                       >
                         45
                       </label>
                     </div>
                   </div>
                   <div className="col-md-4 d-flex align-items-center mt-20">
                     <div className="form-check">
                       <input
                         className="form-check-input"
                         type="checkbox"
                         id="20FlatRocks"
                         defaultChecked={checked}
                         onChange={() => setChecked(!checked)}
                       />
                       <label
                         className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                         for="20FlatRocks"
                       >
                         20 Flat Rocks
                       </label>
                     </div>
                   </div>
                   <div className="col-md-4 d-flex align-items-center mt-20">
                     <div className="form-check">
                       <input
                         className="form-check-input"
                         type="checkbox"
                         id="Fenix"
                         defaultChecked={checked}
                         onChange={() => setChecked(!checked)}
                       />
                       <label
                         className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                         for="Fenix"
                       >
                         Fenix
                       </label>
                     </div>
                   </div>
                   <div className="col-md-4 d-flex align-items-center mt-20">
                     <div className="form-check">
                       <input
                         className="form-check-input"
                         type="checkbox"
                         id="20Reefer"
                       />
                       <label
                         className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                         for="20Reefer"
                       >
                         20 Reefer
                       </label>
                     </div>
                   </div>
                   <div className="col-md-4 d-flex align-items-center mt-20">
                     <div className="form-check">
                       <input
                         className="form-check-input"
                         type="checkbox"
                         id="SpecialEq"
                         defaultChecked={checked}
                         onChange={() => setChecked(!checked)}
                       />
                       <label
                         className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                         for="Special Eq"
                       >
                         Special Eq
                       </label>
                     </div>
                   </div>
                   <div className="col-md-4 d-flex align-items-center mt-20">
                     <div className="form-check">
                       <input
                         className="form-check-input"
                         type="checkbox"
                         id="TraPac"
                         defaultChecked={checked}
                         onChange={() => setChecked(!checked)}
                       />
                       <label
                         className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                         for="TraPac"
                       >
                         TraPac
                       </label>
                     </div>
                   </div>
                   <div className="col-md-4 d-flex align-items-center mt-20">
                     <div className="form-check">
                       <input
                         className="form-check-input"
                         type="checkbox"
                         id="40Reefer"
                         defaultChecked={checked}
                         onChange={() => setChecked(!checked)}
                       />
                       <label
                         className="form-check-label font-weight-500 font-14 d-flex align-items-center"
                         for="40Reefer"
                       >
                         40 Reefer
                       </label>
                     </div>
                   </div>
                 </div>
               </React.Fragment>
             </div>
             <div className="bdpopup__footer page-fluid">
               <button className=" btn btn-primary w-100 justify-content-center align-items-center text-center">
                 Apply
               </button>
             </div>
           </React.Fragment>
         </div>
       </div>
     </React.Fragment>
   );
};

export default FilterPop;
