import React, { useState } from "react";
import { IconPlus } from "../../../../common/icons";
import OtherTabs from "../../common-elements/OtherTabs";
import ProfileTabs from "../profileTabs";
import Pagination from "../../../../common/pagination";
import SearchFilter from "./elements/SearchFilter";
import StaticTableContent from "./elements/StaticTableContent";

function ProfileDriver() {
  const [CardActive, setCardActive] = useState(1);
  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <div className="subheader">
          <div className="subheader__inner d-flex align-items-center py-0">
            <OtherTabs activeTab={1} />
          </div>
        </div>
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center justify-content-between">
              <ProfileTabs
                activeTab={"Driver"}
                button={
                  <button className=" btn btn-success shadow">
                    <IconPlus className="mr-2" />
                    Add Driver
                  </button>
                }
              />
            </div>
          </div>
          <div className="pb-15 d-flex align-items-center justify-content-between">
            <h4 className="font-20 mb-0">435 Drivers</h4>
            <Pagination />
          </div>
          <SearchFilter />
          <StaticTableContent />
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProfileDriver;
