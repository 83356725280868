import React, { useState } from 'react';
import { IconPlus } from '../../../common/icons';
import Pagination from '../../../common/pagination';
import AddSubscription from './SubscriptionModal';
import Testimonial from './Testimonial';

const Subscription = ({}) => {
  const [showTestModal, setTestModal] = useState();
  const handleShowTestModal = () => {
    setTestModal(true);
    setShowAddModal(false);
  };
  const handleHideTestModal = () => {
    setTestModal(false);
  };
  const [showAddModal, setShowAddModal] = useState(true);

  const handleShowAddModal = () => {
    setShowAddModal(true);
  };
  const handleHideAddModal = () => {
    setShowAddModal(false);
  };
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <div className="d-flex flex-row align-items-center justify-content-between w-100">
            <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
              <li className="nav-item">
                <a href="#" className={`nav-link`}>
                  <div className="">Active</div>
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className={`nav-link`}>
                  <div className="">Archived</div>
                </a>
              </li>
            </ul>
            <div className="ml-auto">
              <button className="btn btn-success" onClick={handleShowAddModal}>
                <IconPlus className="mr-2" />
                Add New Profile
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-15 d-flex align-items-center">
            <h4 className="mb-0">253 Profiles</h4>
            <Pagination className={'ml-auto'} />
          </div>
        </div>
      </div>
      {showAddModal && (
        <AddSubscription
          onCancel={handleHideAddModal}
          onSubmit={handleHideAddModal}
          onNext={handleShowTestModal}
        />
      )}
      {showTestModal && <Testimonial  />}
    </div>
  );
};

export default Subscription;
