import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import CustomSelect from '../../../common/CustomSelect';
import CustomCreatableSelect from '../../../common/CustomSelect/creatable';
import {
  IconPlus,
  IconCalendar,
  IconMinus,
  IconDownload,
  IconSendEmail,
  IconTrash,
} from '../../../common/icons';
import { Modal } from 'react-bootstrap';
import { WithContext as ReactTags } from 'react-tag-input';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { CustomIconNotes } from '../../../common/customIcons';

const options = [
  { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
  { value: 'John Doe', label: 'John Doe' },
  { value: 'Alex Mendes', label: 'Alex Mendes' },
];
class SubCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadTabActive: false,
      showUploadModal: false,
      showSendEmailModal: false,
      checked: false,

      tags: [
        { id: '1', text: 'felicia.reid@example.com' },
        { id: '2', text: 'dolores.chambers@example.com' },
      ],

      editorState: EditorState.createEmpty(),
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
  }

  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  }

  handleAddition(tag) {
    this.setState((state) => ({ tags: [...state.tags, tag] }));
  }

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  }

  handleShowUploadModal = (event) => {
    this.setState({ showUploadModal: true });
  };
  handleHideUploadModal = (event) => {
    this.setState({ showUploadModal: false });
  };
  handleLoadTabActive = (i) => {
    this.setState({
      isLoadTabActive: this.state.isLoadTabActive === i ? null : i,
    });
  };
  handleShowSendEmailModal = (event) => {
    this.setState({ showSendEmailModal: true });
  };
  handleHideSendEmailModal = (event) => {
    this.setState({ showSendEmailModal: false });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };
  handleCheckedState = (i) => {
    this.setState({
      checked: this.state.checked === i ? null : i,
    });
  };

  render() {
    const { tags } = this.state;
    const { editorState } = this.state;

    return (
      <React.Fragment>
        <div className="card p-15 mb-1 d-flex flex-row justify-content-between">
          {/* Select Sub Customer */}
          <div className=" d-flex flex-row">
            <CustomSelect
              name={'name'}
              options={options}
              value={1}
              isMulti={true}
              isClearable={true}
              size="small"
              placement="right"
              placeholder="Select Sub Customer..."
              className="custom-colored-select"
            />
            <button className="btn btn-link text-muted">
              <IconTrash />
            </button>
          </div>

          <div className="d-flex flex-row">
            <button
              className="ml-auto btn btn-outline-light mr-2"
              onClick={this.handleShowSendEmailModal}
            >
              <IconSendEmail className="mr-2" />
              Email
            </button>
            <button className="ml-auto btn btn-outline-light">
              <IconDownload className="mr-2" />
              Download PDF
            </button>
          </div>
        </div>

        <div className="table mb-1">
          {/*  Table Card*/}
          <table className="table table-card">
            <thead>
              <tr>
                <th width="15%">Name of Charge #</th>
                <th width="30%">Description</th>
                <th>Price</th>
                <th>Unit Count</th>
                <th width="15%">Unit of measure</th>
                <th>Free Units</th>
                <th>Per Units</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <CustomSelect
                    name={'name'}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Perfect Christmas Trees"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Placeholder"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button type="button" className="btn btn-outline-light">
                        <IconMinus />
                      </button>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      readOnly
                      placeholder="0"
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-outline-light">
                        <IconPlus />
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <CustomSelect
                    name={'name'}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Perfect Christmas Trees"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <button className="btn btn-xs btn-circle bg-soft-danger">
                    <IconMinus className="text-danger" />
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <CustomSelect
                    name={'name'}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select..."
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Placeholder"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button type="button" className="btn btn-outline-light">
                        <IconMinus />
                      </button>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      readOnly
                      placeholder="0"
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-outline-light">
                        <IconPlus />
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <CustomSelect
                    name={'name'}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select..."
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <button className="btn btn-xs btn-circle bg-soft-danger">
                    <IconMinus className="text-danger" />
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <CustomSelect
                    name={'name'}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select..."
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Placeholder"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button type="button" className="btn btn-outline-light">
                        <IconMinus />
                      </button>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      readOnly
                      placeholder="0"
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-outline-light">
                        <IconPlus />
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <CustomSelect
                    name={'name'}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select..."
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <button className="btn btn-xs btn-circle bg-soft-danger">
                    <IconMinus className="text-danger" />
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <CustomSelect
                    name={'name'}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select..."
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Placeholder"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button type="button" className="btn btn-outline-light">
                        <IconMinus />
                      </button>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      readOnly
                      placeholder="0"
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-outline-light">
                        <IconPlus />
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <CustomSelect
                    name={'name'}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select..."
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <button className="btn btn-xs btn-circle bg-soft-danger">
                    <IconMinus className="text-danger" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          {/* Add Charge */}
          <div className="card rounded-0 mb-0 p-3">
            <a className="text-primary font-weight-500">
              <IconPlus className="mr-2" />
              Add Charge
            </a>
          </div>
          {/* Approve Options Buttons */}
          <div className="card mt-1 mb-3 p-3">
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div className="d-flex flex-row">
                <div className={`rounded-3 py-2 px-2 mr-1  badge-brand-300`}>
                  {/* <Form.Check type="checkbox" label="Approve" className="rounded-checkbox text-white" /> */}
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input form-check-input--radio-check"
                      id="approve"
                      name="billStatus"
                      value="approve"
                      checked={true}
                    />
                    <label
                      className="form-check-label text-white"
                      for="approve"
                    >
                      Approve
                    </label>
                  </div>
                </div>
                <div className="badge-light rounded-3 py-2 px-2 mr-1">
                  {/* <Form.Check type="checkbox" label="Unapprove" className="rounded-checkbox" /> */}
                  <div className="form-check">
                    <input
                      className="form-check-input form-check-input--radio-check"
                      type="radio"
                      id="unapprove"
                      name="billStatus"
                      value="unapprove"
                    />
                    <label className="form-check-label" for="unapprove">
                      Unapprove
                    </label>
                  </div>
                </div>
                <div className="badge-light rounded-3 py-2 px-2 mr-1">
                  {/* <Form.Check type="checkbox" label="Rebill" className="rounded-checkbox" /> */}
                  <div className="form-check">
                    <input
                      className="form-check-input form-check-input--radio-check"
                      type="radio"
                      id="rebill"
                      name="billStatus"
                      value="rebill"
                    />
                    <label className="form-check-label" for="rebill">
                      Rebill
                    </label>
                  </div>
                </div>
                <div className="badge-light rounded-3 py-2 px-2 mr-1">
                  {/* <Form.Check type="checkbox" label="Invoice" className="rounded-checkbox" /> */}
                  <div className="form-check">
                    <input
                      className="form-check-input form-check-input--radio-check"
                      type="radio"
                      id="invoice"
                      name="billStatus"
                      value="invoice"
                    />
                    <label className="form-check-label" for="invoice">
                      Invoice
                    </label>
                  </div>
                </div>
                <div className="badge-light rounded-3 py-2 px-2 mr-1">
                  {/* <Form.Check type="checkbox" label="Approve & Invoice" className="rounded-checkbox" /> */}
                  <div className="form-check">
                    <input
                      className="form-check-input form-check-input--radio-check"
                      type="radio"
                      id="approve-invoice"
                      name="billStatus"
                      value="approve-invoice"
                    />
                    <label className="form-check-label" for="approve-invoice">
                      Approve & Invoice
                    </label>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="text-dark font-weight-500 mr-3">
                  Sub Billing Date
                </span>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Billing Date & Time"
                  />
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Select Sub Customer */}
          <div className="card p-20 mb-1 d-flex flex-row justify-content-between">
            <div className=" d-flex flex-row">
              <CustomSelect
                name={'name'}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Cameron Williamson"
                className="custom-colored-select"
              />
              <button className="btn btn-link text-muted">
                <IconTrash />
              </button>
            </div>
            <div className="d-flex flex-row">
              <button
                className="ml-auto btn btn-outline-light mr-2"
                onClick={this.handleShowSendEmailModal}
              >
                <IconSendEmail className="mr-2" />
                Email
              </button>
              <button className="ml-auto btn btn-outline-light">
                <IconDownload className="mr-2" />
                Download PDF
              </button>
            </div>
          </div>
          {/* Table Card */}
          <table className="table table-card mb-1">
            <thead>
              <tr>
                <th width="15%">Name of Charge #</th>
                <th width="30%">Description</th>
                <th>Price</th>
                <th>Unit Count</th>
                <th width="15%">Unit of measure</th>
                <th>Free Units</th>
                <th>Per Units</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <CustomSelect
                    name={'name'}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Perfect Christmas Trees"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Placeholder"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button type="button" className="btn btn-outline-light">
                        <IconMinus />
                      </button>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      readOnly
                      placeholder="0"
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-outline-light">
                        <IconPlus />
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <CustomSelect
                    name={'name'}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Perfect Christmas Trees"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <button className="btn btn-xs btn-circle bg-soft-danger">
                    <IconMinus className="text-danger" />
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <CustomSelect
                    name={'name'}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select..."
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Placeholder"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button type="button" className="btn btn-outline-light">
                        <IconMinus />
                      </button>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      readOnly
                      placeholder="0"
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-outline-light">
                        <IconPlus />
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <CustomSelect
                    name={'name'}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select..."
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <button className="btn btn-xs btn-circle bg-soft-danger">
                    <IconMinus className="text-danger" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          {/* Add Charge */}
          <div className="card rounded-0 mb-0 p-3">
            <a className="text-primary font-weight-500">
              <IconPlus className="mr-2" />
              Add Charge
            </a>
          </div>
          {/* Approve Options Radio Buttons */}
          <div className="card mt-1 mb-3 p-3">
            <div className="text-right mb-3">
              <span className="text-muted">#POP2_M100583 </span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div className="d-flex flex-row">
                <div className={`badge-brand-300 rounded-3 py-2 px-2 mr-1 `}>
                  {/* <Form.Check type="checkbox" label="Approve" className="rounded-checkbox text-white" /> */}
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input form-check-input--radio-check"
                      id="approve"
                      name="billStatus"
                      value="approve"
                      checked={true}
                    />
                    <label className="form-check-label" for="approve">
                      Approve
                    </label>
                  </div>
                </div>
                <div className={`badge-light rounded-3 py-2 px-2 mr-1`}>
                  {/* <Form.Check type="checkbox" label="Unapprove" className="rounded-checkbox" /> */}
                  <div className="form-check">
                    <input
                      className="form-check-input form-check-input--radio-check"
                      type="radio"
                      id="unapprove"
                      name="billStatus"
                      value="unapprove"
                    />
                    <label className="form-check-label" for="unapprove">
                      Unapprove
                    </label>
                  </div>
                </div>
                <div className="badge-light rounded-3 py-2 px-2 mr-1">
                  {/* <Form.Check type="checkbox" label="Rebill" className="rounded-checkbox" /> */}
                  <div className="form-check">
                    <input
                      className="form-check-input form-check-input--radio-check"
                      type="radio"
                      id="rebill"
                      name="billStatus"
                      value="rebill"
                    />
                    <label className="form-check-label" for="rebill">
                      Rebill
                    </label>
                  </div>
                </div>
                <div className="badge-light rounded-3 py-2 px-2 mr-1">
                  {/* <Form.Check type="checkbox" label="Invoice" className="rounded-checkbox" /> */}
                  <div className="form-check">
                    <input
                      className="form-check-input form-check-input--radio-check"
                      type="radio"
                      id="invoice"
                      name="billStatus"
                      value="invoice"
                    />
                    <label className="form-check-label" for="invoice">
                      Invoice
                    </label>
                  </div>
                </div>
                <div className="badge-light rounded-3 py-2 px-2 mr-1">
                  {/* <Form.Check type="checkbox" label="Approve & Invoice" className="rounded-checkbox" /> */}
                  <div className="form-check">
                    <input
                      className="form-check-input form-check-input--radio-check"
                      type="radio"
                      id="approve-invoice"
                      name="billStatus"
                      value="approve-invoice"
                    />
                    <label className="form-check-label" for="approve-invoice">
                      Approve & Invoice
                    </label>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span className="text-dark font-weight-500 mr-3">
                  Sub Billing Date
                </span>
                <div className="input-wrapper">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Sub Billing Date & Time"
                  />
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Summmary Cards */}
        <div className="d-flex flex-row no-gutters">
          <div className="col-md-4">
            <div className="card rounded-5 p-4 mr-3 h-100">
              <h5 className="mb-4 font-weight-bold">
                <CustomIconNotes className="mr-2" />
                Summary
              </h5>
              <div className="summary">
                <div className="row mb-10">
                  <div className="col-6 text-muted">POP2_M100583_1</div>
                  <div className="col-6 font-size-regular text-right">
                    $500.00
                  </div>
                </div>
                <div className="row mb-10">
                  <div className="col-6 font-size-regular text-muted">
                    POP2_M100583_2
                  </div>
                  <div className="col-6 font-size-regular text-right">
                    $600.00
                  </div>
                </div>
                <hr />
                <div className="row mb-10">
                  <div className="col-6 font-size-regular font-weight-500 text-primary">
                    Total
                  </div>
                  <div className="col-6 font-size-medium font-weight-500 text-primary text-right">
                    $1100.00
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Billing Notes */}
          <div className="col-md-4">
            <div className="card rounded-5 p-4 mr-3 h-100">
              <h5 className="mb-4 font-weight-bold">Billing Notes</h5>
              <input
                type="message"
                placeholder="Notes about Driver"
                className="border rounded px-3 pt-3 pb-70 font-size-regular"
                style={{ minHeight: '80%' }}
              />
            </div>
          </div>
          {/* Load Notes */}
          <div className="col-md-4">
            <div className="card rounded-5 p-4 h-100">
              <h5 className="mb-4 font-weight-bold">Load Notes</h5>
              <input
                type="message"
                placeholder="Notes about Loads"
                className="border rounded px-3 pt-3 pb-70 font-size-regular"
                style={{ minHeight: '80%' }}
              />
            </div>
          </div>
        </div>
        <div className="card mb-0 p-20 flex-row d-none">
          <div className="">
            <span className="badge badge-danger">Not Approved</span>
            <h5 className="font-16 mt-10 mb-1">
              <span className="text-muted">Bill To: </span>
              OEC Group
            </h5>
            <p className="text-muted">Invoice: POP2_M100642</p>
            <div className="form-group mb-0">
              <div className="input-wrapper">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Invoice Date & Time"
                />
                <div className="input-icon">
                  <IconCalendar />
                </div>
              </div>
            </div>
          </div>
          <div className="ml-auto" style={{ width: 230 }}>
            <div className="row mb-10">
              <div className="col-6 text-muted">Invoice #</div>
              <div className="col-6 text-right">POP2_M100619</div>
            </div>
            <div className="row mb-10">
              <div className="col-6 text-muted">Invoice Date</div>
              <div className="col-6 text-right">-</div>
            </div>
            <div className="row mb-10">
              <div className="col-6 text-muted">Reference #</div>
              <div className="col-6 text-right">-</div>
            </div>
            <div className="row mb-10">
              <div className="col-6 text-muted">Terms</div>
              <div className="col-6 text-right">Net 30</div>
            </div>
            <div className="row">
              <div className="col-6 text-muted">Due Date</div>
              <div className="col-6 text-right">-</div>
            </div>
          </div>
        </div>
        <div className="table mb-20 d-none">
          <table className="table table-card table-card--ls mb-10">
            <thead>
              <tr>
                <th width="15%" className="font-weight-bold">
                  Name of Charge
                </th>
                <th width="30%">Description</th>
                <th>Units</th>
                <th>Rates</th>
                <th>Charges</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Base Price</td>
                <td>07/27 - 02/27</td>
                <td>1</td>
                <td>-</td>
                <td>$500</td>
              </tr>
              <tr>
                <td>Fuel</td>
                <td>07/27 - 02/27</td>
                <td>1</td>
                <td>-</td>
                <td>$500</td>
              </tr>
              <tr>
                <td>Chassis</td>
                <td>07/27 - 02/27</td>
                <td>1</td>
                <td>-</td>
                <td>$500</td>
              </tr>
              <tr>
                <td>Port Congestion Fee</td>
                <td>07/27 - 02/27</td>
                <td>1</td>
                <td>-</td>
                <td>$500</td>
              </tr>
              <tr>
                <td>Port Congestion Fee</td>
                <td>07/27 - 02/27</td>
                <td>1</td>
                <td>-</td>
                <td>$500</td>
              </tr>
              <tr>
                <td>Port Congestion Fee</td>
                <td>07/27 - 02/27</td>
                <td>1</td>
                <td>-</td>
                <td>$500</td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* Send Email Modal */}
        <Modal show={this.state.showSendEmailModal} centered animation={false}>
          <Modal.Header>
            <Modal.Title>Send Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-wrapper">
              <form>
                <div className="form-group">
                  <label className="col-form-label">To</label>
                  {/* <ReactTags
                    tags={tags}
                    inline={true}
                    inputFieldPosition="inline"
                    handleDelete={this.handleDelete}
                    handleAddition={this.handleAddition}
                    handleDrag={this.handleDrag}
                    handleTagClick={this.handleTagClick}
                    placeholder="curtis.weaver@example.com"
                  /> */}

                  <CustomCreatableSelect
                    name={'name'}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Enter emails.."
                  />

                  <span className="text-gray-400 d-block mt-1">
                    You can add multiple email addresses
                  </span>
                </div>
                <div className="form-group">
                  <label className="col-form-label">CC/BCC</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter CC Email"
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label">Subject</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Subject"
                  />
                </div>
                <div className="editor">
                  <label className="col-form-label">Message</label>
                  <Editor
                    editorState={editorState}
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onEditorStateChange}
                    placeholder="Enter Messsage"
                  />
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={this.handleHideSendEmailModal}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={this.handleHideSendEmailModal}
            >
              Send Email
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default SubCustomer;
