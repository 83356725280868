import React from "react";
import { IconTrash } from "../../../../common/icons";

const GeoFence = () => {
  return (
    <div className="position-relative">
      <div className="mapboxgl-map overflow-hidden" style={{ height: 450 }}>
        <img src="https://i.imgur.com/ELNbBFS.png" className="wh-100" />
      </div>
      <div className="position-absolute d-flex" style={{ top: 15, right: 15 }}>
        <button className="btn btn-primary mr-2">Draw a Geofence</button>
        <button className="btn btn-white">
          <IconTrash />
        </button>
      </div>
    </div>
  );
};

export default GeoFence;
