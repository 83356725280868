import React, { useState } from "react";
import TabComponent from "../../../common/tabComponent";

const tabItems = [
  {
    id: 1,
    title: "Notifications",
    path: "/settings/notifications",
  },
  {
    id: 2,
    title: "Permissions",
    path: "/settings/permission",
  },
  {
    id: 3,
    title: "Role Management",
    path: "/settings/role-management",
  },
  {
    id: 4,
    title: "Expense",
    path: "/settings/general-expense",
  },
  {
    id: 5,
    title: "Container Settings",
    path: "/other/container/owner",
  },
  {
    id: 6,
    title: "Chassis Settings",
    path: "/other/chassis/owner",
  },
  {
    id: 7,
    title: "Commodity Settings",
    path: "/settings/commodity-profile",
  },
  {
    id: 8,
    title: "Terminal Settings",
    path: "/settings/commodity-terminal",
  },
  {
    id: 9,
    title: "Dispatch Color Settings",
    path: "/settings/commodity-dispatch-color",
  },
  {
    id: 10,
    title: "Group Settings",
    path: "/settings/carrier-group",
  },
  {
    id: 11,
    title: "General Info",
    path: "/settings/general-info",
  },
  {
    id: 12,
    title: "Equipment Parameters",
    path: "/settings/equipment-parameters",
  },
  {
    id: 13,
    title: "Document Configuration",
    path: "/settings/document-configuration",
  },
  {
    id: 14,
    title: "Email Configuration",
    path: "/settings/email-configuration",
  },
  {
    id: 15,
    title: "Container Tracking",
    path: "/settings/container-tracking",
  },
  // {
  //   id: 11,
  //   title: 'Notification Settings',
  // },
  // {
  //   id: 12,
  //   title: 'Email Notif. Report',
  // },
  // {
  //   id: 12,
  //   title: 'Commodity Profile',
  // },
  // {
  //   id: 13,
  //   title: 'Load Status Color Sett.',
  // },
  // {
  //   id: 14,
  //   title: 'Carrier Group Sett.',
  // },
];

const SettingsTab = ({ activeTab, button }) => {
  const [tabActive, setTabActive] = useState(activeTab);

  return (
    <div className="d-flex flex-row align-items-center justify-content-between w-100">
      {/*  Sub Header Tabs*/}
      <ul
        className="nav nav-tabs nav-tabs-custom flex-grow-1 overflow-x-auto text-nowrap flex-nowrap mr-2"
        role="tablist"
        style={{overflowX: 'auto'}}
      >
        {tabItems.map(({ id, title, path }) => (
          <TabComponent
            key={title}
            title={title}
            path={path}
            onItemClicked={() => setTabActive(id)}
            isActive={tabActive === id}
          />
        ))}
      </ul>
      {/* CTA BUTTON */}
      <div className="ml-auto d-flex flex-shrink-0 ml-2">{button}</div>
    </div>
  );
};

export default SettingsTab;
