import React from 'react';
import Pagination from '../common-elements/Pagination';
import StaticTableContent from './elements/StaticTableContent';
import SubHeader from '../subheader';
import { IconPlus, IconDownload } from '../../../common/icons/index';

const AssignTruck = () => {
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <SubHeader
            activeTab={2}
            button={
              <div className="d-flex">
                <button className="ml-auto btn btn-success">
                  <IconPlus className="mr-2" />
                  Add New Load
                </button>
              </div>
            }
          />
        </div>
      </div>
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-15 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <h4 className="font-20 mb-0">12 Drivers</h4>
            </div>
            {/* Pagination  */}
            <Pagination className="ml-auto" />
          </div>
        </div>
        {/* Static Table and Search Filter */}
        <StaticTableContent />
      </div>
    </div>
  );
};

export default AssignTruck;
