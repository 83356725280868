import React, { useState } from "react";
import CustomSelect from "../../../common/CustomSelect";
import {
  IconAngleArrowRight,
  IconArrowLongRight,
  IconDownload,
  IconNoteArrowRight,
  IconPlus,
} from "../../../common/icons";
import Pagination from "../../../common/pagination";

import SettingsTab from "../common-elements/SettingsTabs";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const SAgeneralInfo = () => {
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <SettingsTab
            activeTab={11}
            button={
              <div className="d-flex">
                <button className="mr-2 btn btn-outline-light ">Cancle</button>
                <button className=" btn btn-success ">Save Changes</button>
              </div>
            }
          />
        </div>
      </div>
      <div className="page-fluid">
        <div className="form-row-lg mb-10">
          <div className="col-md-5">
            <div className="card px-20 pt-20 pb-1">
              <React.Fragment>
                <h3 className="mb-15 font-16">General Info</h3>
                <div className="form-row-md">
                  <div className="col-md-6 form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter First Name"
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Last Name"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Company Name"
                  />
                </div>
                <div className="form-group">
                  <label>Address</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Address"
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Company Email Address"
                  />
                </div>
                <div className="form-group w-50">
                  <label>Phone</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Phone Number"
                  />
                </div>
                <div className="form-row-md">
                  <div className="col-md-6 form-group">
                    <label>MC#</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="MC#"
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>USDOT Number</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter USDOT Number"
                    />
                  </div>
                </div>
              </React.Fragment>
              <div className="hr-light"></div>
              <React.Fragment>
                <h3 className="mb-15 font-16">Location Info</h3>

                <div className="form-group">
                  <label>Time Zone</label>
                  <CustomSelect size="small" placeholder="Select Time Zone" />
                </div>
                <div className="form-group">
                  <label>Port Market</label>
                  <CustomSelect size="small" placeholder="Select Port Market" />
                </div>
              </React.Fragment>
              <div className="hr-light"></div>
              <React.Fragment>
                <h3 className="mb-15 font-16">Measurement Info</h3>

                <div className="form-group">
                  <label>Select Distance</label>
                  <CustomSelect size="small" placeholder="Select Distance" />
                </div>
                <div className="form-group w-50">
                  <label>Weight</label>
                  <CustomSelect size="small" placeholder="Select weight" />
                </div>
                <div className="form-group">
                  <label>Default Drop</label>
                  <CustomSelect
                    size="small"
                    placeholder="Select Default Drop"
                  />
                </div>
              </React.Fragment>
            </div>
          </div>
          <div className="col-md-7">
            <div className="card p-20">
              <h3 className="mb-3">Driver Clock in Location</h3>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3533.049517059821!2d85.31106346186048!3d27.684864403675494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2snp!4v1635357695621!5m2!1sen!2snp"
                width="100%"
                height="400"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
            <div className="card p-20">
              <h3 className="mb-3">Current Plan</h3>
              <div className="form-row">
                <div className="col-lg-8">
                  <div className="p-20 border-gray-100 border rounded-5">
                    <div className="d-flex align-items-top justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="badge badge-info mr-1">PRO</div> Plan
                      </div>
                      <div className="text-right">
                        <div className="font-weight-bold font-30">$150.00</div>
                        <div className="text-muted">/month</div>
                      </div>
                    </div>
                    <div className="d-flex align-items-end justify-content-between">
                      <div className="flex-grow-1 flex-column pr-40">
                        <div className="w-100 mb-15">
                          <div className="mb-1">3 of 10 Users</div>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "30%" }}
                              aria-valuenow="30"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                        <div className="w-100">
                          <div className="mb-1">7 of 10 Trucks</div>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "70%" }}
                              aria-valuenow="70"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <button className="btn btn-primary">
                        Upgrade Plan
                        <IconArrowLongRight className="ml-2" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="p-20 border border-gray-100 rounded-5">
                    <div className="mb-10">
                      <div className="text-muted">Payment Method</div>
                      <div>**** **** **** 0001</div>
                    </div>
                    <div className="mb-10">
                      <div className="text-muted">Next Payment</div>
                      <div>on February 20, 2022</div>
                    </div>
                    <div className="pointer btn btn-outline-primary btn-block">
                      Manage Payment Method
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <React.Fragment>
              <table className="table table-card mb-10">
                <tbody>
                  <tr>
                    <td>
                    Invoice - December 2021.pdf
                    </td>
                    <td>
                    12/20/2021
                    </td>
                    <td>
                      <span className="badge badge-info text-uppercase">PRO</span>
                    </td>
                    <td className="text-right">$150.00</td>
                    <td width={42}>
                      <button className="btn bt-link">
                        <IconDownload />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                    Invoice - November 2021.pdf
                    </td>
                    <td>
                    12/20/2021
                    </td>
                    <td>
                      <span className="badge badge-info text-uppercase">PRO</span>
                    </td>
                    <td className="text-right">$150.00</td>
                    <td width={42}>
                      <button className="btn bt-link">
                        <IconDownload />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                    Invoice - October 2021.pdf
                    </td>
                    <td>
                    12/20/2021
                    </td>
                    <td>
                      <span className="badge badge-info text-uppercase">FREEMIUM</span>
                    </td>
                    <td className="text-right">$0.00</td>
                    <td width={42}>
                      <button className="btn bt-link">
                        <IconDownload />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                    Invoice - September 2021.pdf
                    </td>
                    <td>
                    12/20/2021
                    </td>
                    <td>
                      <span className="badge badge-info text-uppercase">FREEMIUM</span>
                    </td>
                    <td className="text-right">$0.00</td>
                    <td width={42}>
                      <button className="btn bt-link">
                        <IconDownload />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                    Invoice - August 2021.pdf
                    </td>
                    <td>
                    12/20/2021
                    </td>
                    <td>
                      <span className="badge badge-info text-uppercase">FREEMIUM</span>
                    </td>
                    <td className="text-right">$0.00</td>
                    <td width={42}>
                      <button className="btn bt-link">
                        <IconDownload />
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                    Invoice - July 2021.pdf
                    </td>
                    <td>
                    12/20/2021
                    </td>
                    <td>
                      <span className="badge badge-info text-uppercase">FREEMIUM</span>
                    </td>
                    <td className="text-right">$0.00</td>
                    <td width={42}>
                      <button className="btn bt-link">
                        <IconDownload />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Pagination />
            </React.Fragment>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SAgeneralInfo;
