import React, { Component } from "react";
import { Modal } from "react-bootstrap";

import CustomSelect from "../../../../common/CustomSelect";
import {
  IconDownload,
  IconPrinter,
  IconTrash,
  IconUpload,
} from "../../../../common/icons";
import UploadDocument from "../../../../common/uploadDocument";
import UploadedDocuments from "../../../../common/uploadedDocuments";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

class TabDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpDocumentUploadModal: false,
    };
  }
  handleshowUpDocumentUploadModal = (event) => {
    this.setState({ showUpDocumentUploadModal: true });
  };
  handlehideUpDocumentUploadModal = (event) => {
    this.setState({ showUpDocumentUploadModal: false });
  };
  render() {
    return (
      <div className="tab" id="tab-document">
        <div className="card p-20 mb-10 d-flex flex-row align-items-center justify-content-between">
          <h4 className="mb-0 font-20">4 Documents </h4>
          <button
            className="ml-auto btn btn-primary"
            onClick={this.handleshowUpDocumentUploadModal}
          >
            <IconUpload className="mr-2" />
            Upload Document
          </button>
        </div>
        <UploadedDocuments />
        <Modal show={this.state.showUpDocumentUploadModal} centered animation={false}>
          <Modal.Header>
            <Modal.Title>Upload Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UploadDocument />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={this.handlehideUpDocumentUploadModal}
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              disabled
              onClick={this.handlehideUpDocumentUploadModal}
            >
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default TabDocument;
