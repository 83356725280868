import React, { useState } from 'react';
import CustomSelect from '../../../../common/CustomSelect';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const ResultColumn = () => {
  return (
    <>
      <div className="title d-flex justify-content-center align-items-center pt-60 pb-50 font-20 font-weight-500 text-dark">
        Choose and sort the result columns you want to see
      </div>
      <div className="form-wrapper p-30">
        <form>
          <div className="form-row ">
            <div className="form-group col-md-6">
              <CustomSelect
                name={'name'}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="select Column"
              />
            </div>
            <div className="form-group col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Rename Column (optional) "
              />
            </div>
          </div>
          <div className="text-primary ">+ Add Column</div>
        </form>
      </div>
    </>
  );
};

export default ResultColumn;
