import React, { useState } from 'react';
import CustomSelect from '../../../../common/CustomSelect/index';

import {
  IconColumn,
  IconFilter,
  IconPen,
  IconPlus,
  IconTrash,
} from '../../../../common/icons/index';

const options = [
  { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
  { value: 'John Doe', label: 'John Doe' },
  { value: 'Alex Mendes', label: 'Alex Mendes' },
];

const columns = ['Customer', 'Discount'];

const data = [
  {
    id: 1,
    customer: `Evergreen Marine Corporation`,
    discount: 'Base Price 10%',
  },
  {
    id: 2,
    customer: `Hapag-Lloyd`,
    discount: 'Pre Pull 50%',
  },
  {
    id: 3,
    customer: `Sea Lead Shipping`,
    discount: 'Base Price 15%',
  },
  {
    id: 4,
    customer: `Regional Container Lines`,
    discount: 'Base Price 3%',
  },
  {
    id: 5,
    customer: `Mediterranean Shipping Company`,
    discount: 'Base Price 5%',
  },
  {
    id: 6,
    customer: `Zim Integrated Shipping Services`,
    discount: 'Base Price 10%',
  },
  {
    id: 7,
    customer: `COSCO`,
    discount: 'Base Price 10%',
  },
];

const StaticTableContent = () => {
  const [isPopDrop, setIsPopDrop] = useState(false);

  const handlePopDrop = (event) => {
    setIsPopDrop(!isPopDrop);
  };
  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{ height: 'calc(100vh - 203px)' }}
      >
        <table className="table table-card">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column} style={{ width: '48%' }}>
                    {column}
                  </th>
                );
              })}
              <th style={{ width: '2%' }}></th>
              <th style={{ width: '2%' }}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((datum) => {
              return (
                <tr key={datum.id}>
                  <td>{datum.customer}</td>
                  <td>{datum.discount}</td>
                  <td>
                    <button className="btn btn-link">
                      <IconPen className="" />
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-link">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
