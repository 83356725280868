import React, { useState } from "react";
import { IconCalendar, IconPlus } from "../../../common/icons";
import ReportsTabs from "../common-elements/ReportsTabs";
import AddAutoEmail from "./elements/AddAutoEmail";
import SearchFilter from "./elements/searchFilter";
import StaticTableContent from "./elements/StaticTableContent";
import TopPage from "./elements/toppage";

const tableData = [];
for (let i = 0; i < 12; i++) {
  tableData.push({
    id: i,
    productivityType: "Import Loads Scheduled",
    day: 8,
    night: 4,
  });
}

const Productivity = () => {
  const [addModal, setAddModal] = useState(false);

  const showAddModal = (event) => {
    setAddModal(true);
  };

  const hideAddModal = (event) => {
    setAddModal(false);
  };

  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <ReportsTabs
          activeTab={4}
          buttons={
            <button className="btn btn-success ml-2" onClick={showAddModal}>
              <IconPlus className="mr-2" />
              Add Auto Email
            </button>
          }
        />
        <div className="page-fluid">
          <div className="d-flex my-10">
            <div className="input-wrapper mr-1">
              <input
                className="form-control w-200 border-0 shadow-1"
                type="text"
                placeholder="Select Date"
              />
              <span className="input-icon">
                <IconCalendar />
              </span>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-card table-card--ls">
              <thead>
                <tr>
                  <th>Productivity Type</th>
                  <th>Day (6:00AM-6:00PM)</th>
                  <th>Night (6:00PM-6:00AM)</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((tdata) => {
                  return (
                    <tr key={tdata.id}>
                      <td>{tdata.productivityType}</td>
                      <td>{tdata.day}</td>
                      <td>{tdata.night}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <TopPage />
          <SearchFilter />
          <StaticTableContent />
        </div>
      </div>

      {addModal && (
        <AddAutoEmail onCancel={hideAddModal} onSubmit={hideAddModal} />
      )}
    </React.Fragment>
  );
};

export default Productivity;
