import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  IconDocument,
  IconNotes,
  IconClock9,
  IconCart,
} from '../../../common/icons';
import Documents from './tabs/documents';
import Trailer from './tabs/trailer';
import History from './tabs/History';
import Notes from './tabs/Notes';

const AddTrailer = (props) => {
  const [isLoadTabActive, setisLoadTabActive] = useState(1);
  const handleLoadTabActive = (i) => {
    setisLoadTabActive(isLoadTabActive === i ? null : i);
  };

  const { showModal = false, onCancel = () => {}, onSubmit = () => {} } = props;
  return (
    <Modal show={showModal} size="xl" centered>
      <Modal.Header className="py-20">
        <Modal.Title>Add New Trailer</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 nav-border-bottom">
        <div className="card mb-0">
          {/* Trailer Tabs */}
          <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom">
            <li className="nav-item">
              <a
                className={`nav-link ${isLoadTabActive === 1 ? 'active' : ''}`}
                href="javascript:void(0)"
                onClick={() => handleLoadTabActive(1)}
              >
                <IconCart />
                <div className="nav-link__text">Trailer</div>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${isLoadTabActive === 2 ? 'active' : ''}`}
                href="javascript:void(0)"
                onClick={() => handleLoadTabActive(2)}
              >
                <IconDocument />
                <div className="nav-link__text">Documents</div>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${isLoadTabActive === 3 ? 'active' : ''}`}
                href="javascript:void(0)"
                onClick={() => handleLoadTabActive(3)}
              >
                <IconClock9 />
                <div className="nav-link__text">History</div>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${isLoadTabActive === 4 ? 'active' : ''}`}
                href="javascript:void(0)"
                onClick={() => handleLoadTabActive(4)}
              >
                <IconNotes />
                <div className="nav-link__text">Notes</div>
              </a>
            </li>
          </ul>

          {/* Tab Contents */}
          {isLoadTabActive === 1 && <Trailer />}
          {isLoadTabActive === 2 && <Documents />}
          {isLoadTabActive === 3 && <History />}
          {isLoadTabActive === 4 && <Notes />}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-success" onClick={onSubmit}>
          Add Trailer
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTrailer;
