import React, { useState } from "react";
import CustomSelect from "../../../common/CustomSelect";
import { IconMinus, IconPlus } from "../../../common/icons";

import SettingsTab from "../common-elements/SettingsTabs";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const tableHeaderData = [
  "Container Size",
  "Container Type",
  "Field To Change",
  "Chassis Type",
  "Condition",
  "Amount ",
  "", 
  "Condition",
  "Amount",
  " ",
];
const tableData = [];
for (let i = 0; i < 20; i++) {
  tableData.push({
    id: i,
  });
}
const SAequipmentParameters = () => {
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <SettingsTab
            activeTab={12}
            button={
              <div className="d-flex">
                <button className="mr-2 btn btn-outline-light ">Cancle</button>
                <button className=" btn btn-success ">Save Changes</button>
              </div>
            }
          />
        </div>
      </div>
      <div className="page-fluid">
        <div className="table-responsive">
          <table className="table table-card mb-10">
            <thead>
              <tr>
                {tableHeaderData.map((headetItem) => {
                  return (
                    <th scope="col" key={headetItem}>
                      {headetItem}
                    </th>
                  );
                })}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((tdata) => {
                return (
                  <tr key={tdata.id}>
                    <td>
                      <CustomSelect
                        size="small"
                        placeholder="Select..."
                        options={options}
                      />
                    </td>
                    <td>
                      <CustomSelect
                        size="small"
                        placeholder="Select..."
                        options={options}
                      />
                    </td>
                    <td>
                      <CustomSelect
                        size="small"
                        placeholder="Select..."
                        options={options}
                      />
                    </td>
                    <td>
                      <CustomSelect
                        size="small"
                        placeholder="Select..."
                        options={options}
                      />
                    </td>
                    <td>
                      <CustomSelect
                        size="small"
                        placeholder="Select Condition"
                        options={options}
                      />
                    </td>
                    <td>
                      <input type="number" className="form-control" placeholder="Enter.." />
                    </td>
                    <td className="text-center">and</td>
                    <td>
                      <CustomSelect
                        size="small"
                        placeholder="Select Condition"
                        options={options}
                      />
                    </td>
                    <td>
                      <input type="number" className="form-control" placeholder="Enter.." />
                    </td>
                    <td>
                      <button className="btn btn-circle btn-xs bg-soft-danger mx-auto">
                          <IconMinus />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <button className="btn btn-white btn-sm text-primary mb-20">
            <IconPlus className="mr-2" />Add Parameters
          </button>
        </div>
      </div>
    </div>
  );
};

export default SAequipmentParameters;
