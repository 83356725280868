import React, { useState } from "react";
import { CustomTerminalIcon, LogoPortProColorSmall } from "../../../common/customIcons";

const LiveShare = () => {
  const [openCard, setOpenCard] = useState(false); 
  return (
    <div
      className={`wrapper home position-relative`}
    >
      <div className={`position-relative track-page `}>
        {/* START TerminalBar Design */}
        <div className="terminalbar col-lg-12 px-0 mx-0" >
            <div className="card terminal_card rounded-0  px-30 py-20">
            <div className="d-flex justify-content-between mb-15">
              <div className="d-flex align-items-center">
                <CustomTerminalIcon />
                <div className="font-20 font-weight-500 ml-10">TraPac Logistics</div>
              </div>
              <div className="d-flex align-items-center">
                <div className="text-gray-900 font-12 font-weight-400 mr-10">Powered by</div>
                <LogoPortProColorSmall/>
              </div>
            </div>
              <div className="d-flex ">
                <div>
                  <div className="pb-1 font-12 font-weight-400  text-gray-500">Container #</div>
                  <div className="font-14 font-weight-500">MS111223</div>
                </div>
                <div className="ml-40">
                  <div className="pb-1 font-12 font-weight-400 text-gray-500">Current Location</div>
                  <div className="font-14 font-weight-500">YARD</div>
                </div>
                <div className="ml-40">
                  <div className="pb-1 font-12 font-weight-400 text-gray-500">ETA to Destination</div>
                  <div className="font-14 font-weight-500">2hr 1min</div>
                </div>
                <div className="ml-40">
                  <div className="pb-1 font-12 font-weight-400 text-gray-500">Driver</div>
                  <div className="font-14 font-weight-500">John Malkovich</div>
                </div>
              </div>
            
            </div>
          </div>
        {/* END TerminalBar Design */}
        <div
          id="Trackmap"
          style={{
            height: `calc(100vh - ${openCard ? "112px" : "60px"})`,
            backgroundImage: `url("https://i.imgur.com/ELNbBFS.png")`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default LiveShare;
