import React, { useState } from "react";
import {
  IconColumn,
  IconFilter,
  IconCalendar,
} from "../../../../../common/icons";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const SearchFilter = () => {
  const [openPopup, setOpenPopup] = useState(false);

  const handleShowPopup = (event) => {
    document.body.classList.add("bdpopup-open");
    setOpenPopup(true);
  };

  const handleHidePopup = (event) => {
    document.body.classList.add("bdpopup-open");
    setOpenPopup(false);
  };


  return (
    <React.Fragment>
      <div className="filter-row d-flex align-items-center">
        {/* Search Bar */}
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>
        {/* Filter Settings */}
        <div className="ml-auto">
          <button
            className="btn btn-outline-light mr-2"
         
          >
            <IconFilter className="mr-2" />
            Filter
          </button>
          <button className="btn btn-outline-light">
            <IconColumn />
          </button>
        </div>
      </div>
     

    </React.Fragment>
  );
};

export default SearchFilter;
