import React from 'react';
import { IconDotSeperator } from '../../../../common/icons';
const driverData = [];
for (let i = 0; i < 20; i++) {
  driverData.push({
    id: i,
    driverName: 'Robert Fox',
  });
}
const DriverItineraryList = (props) => {
  return (
    <React.Fragment>
      <div className="card p-1 mb-1">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>
      </div>
      <div className="dlist" style={{ height: 'calc(100vh - 241px)' }}>
        {driverData.map((ddata) => {
          return (
            <div className="dlist__card mb-1 shadow-none">
              <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                <div className="flex-shrink-0 mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    className="avatar-xs rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <div className="flex-grow-1">
                  <h5 className="font-14 mb-1">
                    <a href="#" className="text-dark">
                      {ddata.driverName}
                    </a>
                  </h5>
                  <p className="text-muted mb-0">
                    <span>Albany</span>
                    <IconDotSeperator className="mx-2" />
                    <span>12 days ago</span>
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <span className="badge badge-light font-12">
                    (702) 555-0122
                  </span>
                </div>
              </div>
              <div className="dlist__load">
                <div className="dlist__load-current">
                  <div className="d-flex align-items-start p-2">
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Accem - Hook
                        </a>
                      </h5>
                      <p className="text-muted mb-0">Springville, AR</p>
                      <span className="badge badge-secondary font-12 mt-2">
                        +2 more loads
                      </span>
                    </div>
                    <div className="flex-shrink-0">
                      <span className="badge badge-sm badge-soft-brown">
                        Departed
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default DriverItineraryList;
