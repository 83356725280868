import React, { useState } from 'react';
import {
  IconPlus,
  IconCalendar,
  IconDownload,
} from '../../common/icons';
import TabComponent from '../../common/tabComponent';

const tabItems = [
  {
    id: 1,
    title: 'Truck Profiles',
    path: '/equipment/truck-profiles',
  },
  {
    id: 2,
    title: 'Chassis Profiles',
    path: '/equipment/chassis',
  },
  {
    id: 3,
    title: 'Trailer Profiles',
    path: '/equipment/trailers',
  },
];

const options = [
  { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
  { value: 'John Doe', label: 'John Doe' },
  { value: 'Alex Mendes', label: 'Alex Mendes' },
];

const EquipmentTab = ({ activeTab, button }) => {
  const [tabActive, setTabActive] = useState(activeTab);
  
  return (
    <div className="d-flex flex-row align-items-center justify-content-between w-100">
      {/*  Sub Header Tabs*/}
      <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
        {tabItems.map(({ id, title, path }) => (
          <TabComponent
            key={title}
            title={title}
            path={path}
            onItemClicked={() => setTabActive(id)}
            isActive={tabActive === id}
          />
        ))}
      </ul>
      {/* 
      {tabActive == '2' && (
        <button className="ml-auto btn btn-outline-light mr-2">
          <IconDownload className="mr-3" />
          Download CSV
        </button>
      )}

      {[1, 2].includes(tabActive) && (
        <button
          className="btn btn-success font-weight-500"
          onClick={handleShowAddModal}
        >
          <IconPlus className="mr-2" />
          Add New Load
        </button>
      )} */}

      {/* CTA BUTTON */}
      {button}
    </div>
  );
};

export default EquipmentTab ;
