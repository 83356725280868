import { CheckoutLogoPortPro } from '../../customIcons/index'
import SeparatorLine from './Separator';

// sub component
const Rowclip = ({ desc, status, price }) => {
 return (
  <div className='billing my-3 d-flex justify-content-between'>
   <div className='d-flex flex-column'>
    <span>{desc}</span>
    <span>{status}</span>
   </div>
   <span>{price}</span>
  </div>
 )
}

const Col1Footer = () => {
 return (
  <div className="col1__footer d-flex justify-content-center align-items-center">
   <span className='mr-2 text-secondary'>Powered by <b>stripe</b></span>
   <SeparatorLine type='vertical' />
   <a href="#" className='mx-3 text-secondary col1__footer__link'>Terms</a>
   <a href="#" className='text-secondary col1__footer__link'>Privacy</a>
  </div>
 )
}


const Index = () => {
 return (
  <div className="col-md-4 col1 pr-5 pt-5 px-5 d-flex flex-column justify-content-between">
   <div className='col1__body'>
    <CheckoutLogoPortPro /><br /><br /><br /><br />
    <span id='col1__title'>Subscribe to PortPro drayOS Monthly Subscription</span>
    <div className='price d-flex align-items-center'>
     <h1 className='mt-1'>$117.75</h1>
     <div className='price__rate d-flex flex-column ml-2'>
      <span>Per</span>
      <span>Month</span>
     </div>
    </div><br />
    <Rowclip desc='PortPro drayOS Monthly Subscription' status='Billed monthly' price='$117.75' /><br />
    <Rowclip desc='Subtotal' status='' price='$117.75' />
    <SeparatorLine type='horizontal' />
    <span className='text-primary' id='promotion-title'>Add promotion code </span>
    <SeparatorLine type='horizontal' />
    <Rowclip desc='Total due today' status='' price='$117.75' />
   </div>
   <Col1Footer />
  </div>
 )
}

export default Index;