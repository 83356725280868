import React, { useState } from 'react';
import { IconDownload, IconPlus, IconPrinter } from '../../../common/icons';
import TabComponent from '../../../common/tabComponent';

const tabItems = [
  {
    id: 1,
    title: 'Charge',
    path: '/reports/charge',
  },
  {
    id: 2,
    title: 'Customer Revenue',
    path: '/reports/customer-revenue',
  },
  {
    id: 3,
    title: 'Loads',
    path: '/reports/loads',
  },
  {
    id: 4,
    title: 'Productivity',
    path: '/reports/productivity',
  },
  {
    id: 5,
    title: 'Vessel',
    path: '/reports/vessel',
  },
  {
    id: 6,
    title: 'LFD',
    path: '/reports/lfd',
  },
  {
    id: 7,
    title: 'Clock Report',
    path: '/reports/clock-report',
  },
  {
    id: 8,
    title: 'Load Master Report',
    path: '/reports/load-master-report',
  },
  {
    id: 9,
    title: 'Custom Master Report',
    path: '/reports/custom-master-report',
  },
  {
    id: 10,
    title: 'Driver Pay Report',
    path: '/reports/driver-pay-report',
  },
  {
    id: 11,
    title: 'Driver Revenue',
    path: '/reports/driver-revenue',
  },
  {
    id: 12,
    title: 'New Driver Revenue',
    path: '/reports/new-driver-revenue',
  },
  {
    id: 13,
    title: 'Leg Type Report',
    path: '/reports/leg-type-report',
  },
  {
    id: 14,
    title: 'Driver Status Report',
    path: '/reports/driver-status-report',
  },
  {
    id: 15,
    title: 'Aging',
    path: '/reports/aging',
  },
  {
    id: 16,
    title: 'Admin Dashboard',
    path: '/reports/admin-dashboard',
  },
  {
    id: 17,
    title: 'Improvement Dashboard',
    path: '/reports/improvement-dashboard',
  },
];

const ReportsTabs = ({ activeTab, buttons }) => {
  const [tabActive, setTabActive] = useState(activeTab);

  return (
    <React.Fragment>
      {/*  Sub Header Tabs*/}
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <div className="d-flex flex-row align-items-center">
            <ul
              className="nav nav-tabs nav-tabs-custom flex-grow-1 overflow-x-auto text-nowrap flex-nowrap mr-2"
              role="tablist"
            >
              {tabItems.map(({ id, title, path }) => (
                <TabComponent
                  key={title}
                  title={title}
                  path={path}
                  onItemClicked={() => setTabActive(id)}
                  isActive={tabActive === id}
                />
              ))}
            </ul>
          </div>
          <div className="ml-auto d-flex flex-shrink-0 ml-2">
            <button className="btn btn-outline-light mr-2">
              <IconDownload className="mr-2" />
              Download
            </button>
            <button className="btn btn-outline-light">
              <IconPrinter className="mr-2" />
              Print
            </button>
            {buttons}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReportsTabs;
