import React, { useState } from 'react';
import CustomSelect from '../../../../common/CustomSelect/index';

import { IconPen, IconTrash } from '../../../../common/icons/index';

const columns = ['Profile customers', 'Customers'];

const data = [
  {
    id: 1,
    profileName: 'Group name 1',
    customers: `Evergreen Marine Corporation, X-Press Feeders, Maersk Line, IRISL Group, Sea Lead Shipping, Yang Ming Marine Transport Corporation `,
  },
  {
    id: 2,
    profileName: 'Group name 2',
    customers: `Evergreen Marine Corporation`,
  },
  {
    id: 3,
    profileName: 'Group name 3',
    customers: `X-Press Feeders`,
  },
  {
    id: 4,
    profileName: 'Group name 4',
    customers: `Maersk Line, Maersk Line`,
  },
  {
    id: 5,
    profileName: 'Group name 5',
    customers: `IRISL Group	`,
  },
  {
    id: 6,
    profileName: 'Group name 6',
    customers: `IRISL Group, Sea Lead Shipping, Yang Ming Marine Transport Corporation `,
  },
];

const StaticTableContent = () => {
  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{ height: 'calc(100vh - 248px)' }}
      >
        <table className="table table-card">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
              <th style={{ width: '2%' }}></th>
              <th style={{ width: '2%' }}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((datum) => {
              return (
                <tr key={datum.id}>
                  <td style={{ width: '10%' }}>{datum.profileName}</td>
                  <td>{datum.customers}</td>
                  <td>
                    <button className="btn btn-link">
                      <IconPen className="text-muted" />
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-link">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
