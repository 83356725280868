import React, { useState } from 'react';
import BillingTabs from '../common-elements/BillingTabs';
import { IconPlus, IconDownload } from '../../../common/icons';
import StaticTableContent from './StaticTableContent';

const UploadAuditDocument = () => {
  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <div className="subheader">
          <div className="subheader__inner d-flex align-items-center py-0">
            <BillingTabs
              activeTab={5}
              button={
                <div className="d-flex">
                  <button className="mr-2 btn btn-outline-light ">
                    <IconDownload className="mr-2" />
                    Downlaod Example
                  </button>
                  <button className="ml-auto btn btn-success">
                    <IconPlus className="mr-2" />
                    Upload CVS
                  </button>
                </div>
              }
            />
          </div>
        </div>
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <div className="d-flex align-items-center">
                <h4 className="font-20 mb-0">Downloaded file name.SCV</h4>
              </div>
            </div>
            <StaticTableContent />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UploadAuditDocument;
