import React, { useState } from "react";
import {
  IconAngleArrowLeft,
  IconAngleArrowRight,
  IconAt,
  IconBilling,
  IconCalendar,
  IconDocument,
  IconDownload,
  IconMinus,
  IconNotes,
  IconPayment,
  IconPhone,
  IconPlus,
  IconPrinter,
  IconSearchData,
  IconSendEmail,
  IconTrash,
} from "../../../common/icons";
const columns = [
  "Load #",
  "Container #",
  "Price",
  "BOL/BKF",
  "Ref #",
  "Port",
  "Consignee",
  "",
];
const tableData = [];
for (let i = 0; i < 6; i++) {
  tableData.push({
    id: i,
    load: "M100012",
    container: "XLXU237238",
    price: "$800",
    bolbkf: 2,
    ref: 2,
    port: 2,
    consignee: 2,
  });
}

const CombinedBillsPopup = () => {
  return (
    <div className="page-fluid">
      <div className="form-row-md">
        <div className="col-md" style={{ maxWidth: 270 }}>
          <div className="card p-15 mb-1">
            <h5>Customer</h5>
            <div>ABC DISTRIBUTION</div>
            <div className="mb-10">
              Main St, Boston, MA 02129, dominican republic
            </div>

            <div className="text-primary">
              <IconPhone className="mr-15" />
              <IconAt />
            </div>
          </div>

          <div className="card p-15 mb-1">
            <h5 className="mb-15">Summary of Total Charges</h5>
            <div className="d-flex align-items-center justify-content-between mb-10">
              <span className="text-muted">Base Price</span>
              <span>$3000.00</span>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-10">
              <span className="text-muted">Storage</span>
              <span>$300.00</span>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-10">
              <span className="text-muted">Chassis</span>
              <span>$250.00</span>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-10">
              <span className="text-muted">Demurrage</span>
              <span>$100.00</span>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-10">
              <span className="text-muted">Per Diem</span>
              <span>$300.00</span>
            </div>

            <div
              className="d-flex align-items-center justify-content-between mt-15 bg-gray-50 rounded-3 px-10 py-1"
              style={{ marginBottom: 2 }}
            >
              <span className="text-muted">Total</span>
              <span>$3950.00</span>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-10 bg-gray-50 rounded-3 px-10 py-1">
              <span className="text-muted">Amount Paid</span>
              <span>$300.00</span>
            </div>
          </div>
        </div>
        <div className="col-md">
          <div className="card mb-0">
            <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom">
              <li className="nav-item">
                <a className={`nav-link`} href="javascript:void(0)">
                  <IconBilling />
                  <div className="nav-link__text">Billing</div>
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link`} href="javascript:void(0)">
                  <IconDocument />
                  <div className="nav-link__text">Documents</div>
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link`} href="javascript:void(0)">
                  <IconPayment />
                  <div className="nav-link__text">Payment</div>
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link`} href="javascript:void(0)">
                  <IconSearchData />
                  <div className="nav-link__text">Audit</div>
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link`} href="javascript:void(0)">
                  <IconNotes />
                  <div className="nav-link__text">Notes</div>
                </a>
              </li>
            </ul>
          </div>
          <div className="tab-loads">
            <div class="card p-15 mb-0 d-flex flex-row align-items-center justify-content-between">
              <div className="font-20 font-medium">45 Total Invoices</div>
              <div className="ml-auto">
                <button className="btn btn-outline-light mr-1">
                  <IconSendEmail className="mr-2" />
                  Email
                </button>
                <button className="btn btn-outline-light">
                  <IconDownload className="mr-2" />
                  Download PDF
                </button>
              </div>
            </div>
            <table className="table table-card table-card--sm">
              <thead>
                <tr>
                  {columns.map((column) => {
                    return (
                      <th scope="col" key={column}>
                        {column}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {tableData.map((tdata) => {
                  return (
                    <tr key={tdata.id}>
                      <td>{tdata.load}</td>
                      <td>{tdata.container}</td>
                      <td>${tdata.price}</td>
                      <td>{tdata.bolbkf}</td>
                      <td>{tdata.ref}</td>
                      <td>{tdata.port}</td>
                      <td>{tdata.consignee}</td>
                      <td width={20}>
                        <button className="btn btn-circle btn-xs bg-soft-danger">
                          <IconMinus />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="card p-10" style={{ marginBottom: 1 }}>
              <div className="d-flex align-items-center text-primary">
                <IconPlus className="mr-10" /> Add Charge Option
              </div>
            </div>

            <div class="card d-flex flex-row align-items-center justify-content-between mb-10 p-15">
              <div class="d-flex flex-row">
                <div class="rounded-3 py-2 px-2 mr-1  badge-brand-300">
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input form-check-input--radio-check"
                      id="approve"
                      name="billStatus"
                      value="approve"
                      checked=""
                    />
                    <label class="form-check-label text-white" for="approve">
                      Approve
                    </label>
                  </div>
                </div>
                <div class="badge-light rounded-3 py-2 px-2 mr-1">
                  <div class="form-check">
                    <input
                      class="form-check-input form-check-input--radio-check"
                      type="radio"
                      id="unapprove"
                      name="billStatus"
                      value="unapprove"
                    />
                    <label class="form-check-label" for="unapprove">
                      Unapprove
                    </label>
                  </div>
                </div>
                <div class="badge-light rounded-3 py-2 px-2 mr-1">
                  <div class="form-check">
                    <input
                      class="form-check-input form-check-input--radio-check"
                      type="radio"
                      id="rebill"
                      name="billStatus"
                      value="rebill"
                    />
                    <label class="form-check-label" for="rebill">
                      Rebill
                    </label>
                  </div>
                </div>
                <div class="badge-light rounded-3 py-2 px-2 mr-1">
                  <div class="form-check">
                    <input
                      class="form-check-input form-check-input--radio-check"
                      type="radio"
                      id="invoice"
                      name="billStatus"
                      value="invoice"
                    />
                    <label class="form-check-label" for="invoice">
                      Invoice
                    </label>
                  </div>
                </div>
                <div class="badge-light rounded-3 py-2 px-2 mr-1">
                  <div class="form-check">
                    <input
                      class="form-check-input form-check-input--radio-check"
                      type="radio"
                      id="approve-invoice"
                      name="billStatus"
                      value="approve-invoice"
                    />
                    <label class="form-check-label" for="approve-invoice">
                      Approve &amp; Invoice
                    </label>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <span class="text-dark font-weight-500 mr-3">Billing Date</span>
                <div class="input-wrapper">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Billing Date Time"
                  />
                  <div class="input-icon">
                    <IconCalendar />
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-20">
              <div className="font-16 font-medium mb-20">Billing Notes</div>
                <textarea className="form-control" rows="3" placeholder="Enter your billing notes here ..">

                </textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CombinedBillsPopup;
