import React, { useState } from "react";
import OtherTabs from "../common-elements/OtherTabs";
import { IconPlus } from "../../../common/icons";
import StaticTableContent from "./elements/StaticTableContent";
import AddNewExpense from "./elements/AddNewExpense";

const DynamicExpense = () => {
  const  [showNewExpenseModal, setShowNewExpenseModal] = useState(false);

  const handleShowNewExpenseModal = (event) => {
    setShowNewExpenseModal(true);
  };

  const handleHideNewExpenseModal = (event) => {
    setShowNewExpenseModal(false);
  };

  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <OtherTabs
            activeTab={6}
            button={
              <button className="ml-auto btn btn-success" onClick={handleShowNewExpenseModal}>
                <IconPlus className="mr-2" />
                Add Expense 
              </button>
            }
          />
        </div>
      </div>
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-15 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <h4 className="font-20 mb-0">5 Prices</h4>
            </div>
          </div>
        </div>

        {/* Static Table Content */}
        <StaticTableContent />
      </div>
      {/* Add New Pricing */}
      {showNewExpenseModal && (
        <AddNewExpense
          onCancel={handleHideNewExpenseModal}
          onSubmit={handleHideNewExpenseModal}
        />
      )}
    </div>
  );
};

export default DynamicExpense;
