import React, { useState } from "react";

import { IconDownload, IconSendEmail } from "../../../../common/icons/index";

const tableData = [];
for (let i = 0; i < 20; i++) {
  tableData.push({
    id: i,
    company: "Salam Pacific Indonesia Lines",
    customer: "Jenny Wilson",
  });
}

const options = [
  { value: "jessica.hanson@example.com", label: "jessica.hanson@example.com" },
  { value: "john.doe@example.com", label: "john.doe@example.com" },
  { value: "alex@example.com", label: "alex@example.com" },
];

const StaticTableContent = (props) => {
  const { showCustomerDetails } = props;
  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 258px)" }}
      >
        <table className="table table-card table-card--ls">
          <thead>
            <tr>
              <th>Customer</th>
              <th></th>
              <th style={{ width: 50 }}></th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((tdata) => {
              return (
                <tr key={tdata.id}>
                  <td>
                    <a
                      className="text-primary font-weight-500"
                      href="javascript:void(0)"
                      onClick={showCustomerDetails}
                    >
                      {tdata.company}
                    </a>
                  </td>
                  <td>{tdata.customer}</td>
                  <td className="text-center">
                    <button className="btn btn-none">
                      <IconDownload className="text-muted" />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default StaticTableContent;
