import React, { Component } from "react";
import ConfirmationAlertModal from "../../../../common/confirmationAlert/ConfirmationAlertModal";
import { CustomIconAlert } from "../../../../common/customIcons";

import CustomSelect from "../../../../common/CustomSelect";
import { IconCalendar } from "../../../../common/icons";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
class TabChassis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadTabActive: false,
      showUploadModal: false,
      isConfirmModal: false,
    };
  }

  handleShowUploadModal = (event) => {
    this.setState({ showUploadModal: true });
  };
  handleHideUploadModal = (event) => {
    this.setState({ showUploadModal: false });
  };
  handleLoadTabActive = (i) => {
    this.setState({
      isLoadTabActive: this.state.isLoadTabActive === i ? null : i,
    });
  };

  showConfirmModal = (event) => {
    this.setState({ isConfirmModal: true });
  };
  hideConfirmModal = (event) => {
    this.setState({ isConfirmModal: false });
  };

  render() {
    return (
      <>
        <div className="tab" id="tab-chassis">
          <div className="card">
            <div className="pt-30 px-20 mb-10">
              <div className="row">
                <div className="form-group col-lg-4">
                  <label>Chassis #</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Chassis #"
                  />
                </div>
                <div className="form-group col-lg-4">
                  <label>Year</label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select Year"
                    className="w-150"
                  />
                </div>
                <div className="form-group col-lg-4">
                  <label>Regisration date</label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select Date"
                    className="w-150"
                  />
                </div>
                <div className="form-group col-lg-4">
                  <label>Chassis Owner</label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select Owner"
                    className="w-100"
                  />
                </div>
                <div className="form-group col-lg-4">
                  <label>AID</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter AID"
                  />
                </div>
                <div className="form-group col-lg-4">
                  <label>ITD</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter ITD"
                  />
                </div>
                <div className="form-group col-lg-4">
                  <label>Chassis Type</label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select Type"
                    className="w-100"
                  />
                </div>
                <div className="form-group col-lg-4">
                  <label>License Plate #</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter License Plate #"
                  />
                </div>
                <div className="form-group col-lg-4">
                  <label>VIN</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter VIN"
                  />
                </div>
                <div className="form-group col-lg-4">
                  <label>Chassis Size</label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select Size"
                    className="w-100"
                  />
                </div>
                <div className="form-group col-lg-4">
                  <label>License State</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter License State"
                  />
                </div>
                <div className="form-group col-lg-4">
                  <label>Insurance</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Insurance"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-4">
                  <label>Model</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Model"
                  />
                </div>
                <div className="form-group col-lg-4">
                  <label>Inspection Date</label>
                  <div className="input-wrapper w-150">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Select Date"
                    />
                    <span className="input-icon">
                      <IconCalendar />
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-4">
                  <label>GPS</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter GPS"
                  />
                </div>
                <div className="form-group col-lg-4">
                  <label>Preventative Maintenance</label>
                  <div className="input-wrapper w-150">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Select Date"
                    />
                    <span className="input-icon">
                      <IconCalendar />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <button className="btn btn-link" onClick={this.showConfirmModal}>
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={this.showConfirmModal}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>

        {this.state.isConfirmModal && (
          <ConfirmationAlertModal
            showModal={this.state.isConfirmModal}
            icon={<CustomIconAlert />}
            title={<h4 className="font-20">You have unsaved changes</h4>}
            message="Your changes will be lost if you don’t save them"
            buttons={
              <div className="d-flex">
                <button
                  className="btn btn-none mr-10 px-20"
                  onClick={this.hideConfirmModal}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-outline-light mr-10 px-20"
                  onClick={this.hideConfirmModal}
                >
                  Don’t save
                </button>
                <button
                  className="btn btn-primary"
                  onClick={this.hideConfirmModal}
                >
                  Save & Close
                </button>
              </div>
            }
          />
        )}
      </>
    );
  }
}

export default TabChassis;
