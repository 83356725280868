import React, { Component } from 'react';
import { IconPen, IconTrash } from '../../../common/icons';

import CustomSelect from '../../../common/CustomSelect';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

class StaticTableContent extends Component {
  render() {
    return (
      <div
        className="table-responsive"
        style={{ height: 'calc(100vh - 355.8px)' }}
      >
        <table className="table-card" style={{ width: '200rem' }}>
          <thead>
            <tr>
              <th>Company name</th>
              <th>Company ID</th>
              <th>Address</th>
              <th>Lat</th>
              <th>Lng</th>
              <th>City</th>
              <th>State</th>
              <th>Zip</th>
              <th width="6%" style={{ zIndex: '9999' }}>
                Country
              </th>
              <th>Main Contact Name</th>
              <th width="6%" style={{ zIndex: '9999' }}>
                Secondary Contact Name
              </th>
              <th>Secondary Contact </th>
              <th>Secondary Contact Email </th>
              <th>Credit Limit</th>
              <th>Terminal</th>
              <th width="5%" style={{ zIndex: '9999' }}>
                Customer type
              </th>
              <th>Mc #</th>
              <th>Fleet Customer</th>
              <th>Receiver email</th>
              <th width="5%" style={{ zIndex: '9999' }}>
                Pay Type
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="Krusty Krab"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="3123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="1901 Thornridge Cir Sh"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="40.9665656"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder=".73.674959"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="St.Portsmouth"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="OH"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="10580"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="United States"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Kristin Waston"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="tanny.hill@example.com"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="$0"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="-"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="select"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="12322322"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="bill.sender@example.com"
                />
              </td>

              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Factoring"
                />
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="Krusty Krab"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="3123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="1901 Thornridge Cir Sh"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="40.9665656"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder=".73.674959"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="St.Portsmouth"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="OH"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="10580"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="United States"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Kristin Waston"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="tanny.hill@example.com"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="$0"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="-"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="select"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="12322322"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="bill.sender@example.com"
                />
              </td>

              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Factoring"
                />
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="Krusty Krab"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="3123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="1901 Thornridge Cir Sh"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="40.9665656"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder=".73.674959"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="St.Portsmouth"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="OH"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="10580"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="United States"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Kristin Waston"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="tanny.hill@example.com"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="$0"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="-"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="select"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="12322322"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="bill.sender@example.com"
                />
              </td>

              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Factoring"
                />
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="Krusty Krab"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="3123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="1901 Thornridge Cir Sh"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="40.9665656"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder=".73.674959"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="St.Portsmouth"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="OH"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="10580"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="United States"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Kristin Waston"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="tanny.hill@example.com"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="$0"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="-"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="select"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="12322322"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="bill.sender@example.com"
                />
              </td>

              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Factoring"
                />
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="Krusty Krab"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="3123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="1901 Thornridge Cir Sh"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="40.9665656"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder=".73.674959"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="St.Portsmouth"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="OH"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="10580"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="United States"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Kristin Waston"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="tanny.hill@example.com"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="$0"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="-"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="select"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="12322322"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="bill.sender@example.com"
                />
              </td>

              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Factoring"
                />
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="Krusty Krab"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="3123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="1901 Thornridge Cir Sh"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="40.9665656"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder=".73.674959"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="St.Portsmouth"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="OH"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="10580"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="United States"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Kristin Waston"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="tanny.hill@example.com"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="$0"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="-"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="select"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="12322322"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="bill.sender@example.com"
                />
              </td>

              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Factoring"
                />
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="Krusty Krab"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="3123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="1901 Thornridge Cir Sh"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="40.9665656"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder=".73.674959"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="St.Portsmouth"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="OH"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="10580"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="United States"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Kristin Waston"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="tanny.hill@example.com"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="$0"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="-"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="select"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="12322322"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="bill.sender@example.com"
                />
              </td>

              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Factoring"
                />
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="Krusty Krab"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="3123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="1901 Thornridge Cir Sh"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="40.9665656"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder=".73.674959"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="St.Portsmouth"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="OH"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="10580"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="United States"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Kristin Waston"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="tanny.hill@example.com"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="$0"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="-"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="select"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="12322322"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="bill.sender@example.com"
                />
              </td>

              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Factoring"
                />
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="Krusty Krab"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="3123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="1901 Thornridge Cir Sh"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="40.9665656"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder=".73.674959"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="St.Portsmouth"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="OH"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="10580"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="United States"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Kristin Waston"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="tanny.hill@example.com"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="$0"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="-"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="select"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="12322322"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="bill.sender@example.com"
                />
              </td>

              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Factoring"
                />
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="Krusty Krab"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="3123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="1901 Thornridge Cir Sh"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="40.9665656"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder=".73.674959"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="St.Portsmouth"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="OH"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="10580"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="United States"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Kristin Waston"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="tanny.hill@example.com"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="$0"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="-"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="select"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="12322322"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="bill.sender@example.com"
                />
              </td>

              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Factoring"
                />
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="Krusty Krab"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="3123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="1901 Thornridge Cir Sh"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="40.9665656"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder=".73.674959"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="St.Portsmouth"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="OH"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="10580"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="United States"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Kristin Waston"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="(270) 555-0117"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="tanny.hill@example.com"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="$0"
                />
              </td>

              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="-"
                />
              </td>
              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="select"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="123123123"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="12322322"
                />
              </td>
              <td>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  placeholder="bill.sender@example.com"
                />
              </td>

              <td>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Factoring"
                />
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default StaticTableContent;
