import React from 'react';
import ReportsTabs from '../common-elements/ReportsTabs';
import {
  IconPlus,
  IconAngleArrowLeft,
  IconAngleArrowRight,
  IconCalendar,
  IconCaretDown,
} from '../../../common/icons';
import DriverList from './DriverList';
import AreaChart from './AreaChart';
import RadialChart from './RadialChart';
import BarChart from './BarChart';
import LineChart from './LineChart';
import BillingCard from './BillingCard';
import CustomerList from './CustomerList';
import AccessorialsList from './AccessorialsList';

const index = () => {
  return (
    <div className="position-relative subheader-wrapper">
      <ReportsTabs
        activeTab={16}
        buttons={
          <button className=" btn btn-success ml-2">
            <IconPlus className="mr-2" />
            Add Custom Report
          </button>
        }
      />
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-20 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <button className="btn btn-white mr-3">
                <IconAngleArrowLeft />
              </button>
              <div className="d-flex align-items-center mr-3">
                <h4 className="font-20 mb-0">Today</h4>
                <IconCaretDown />
              </div>
              <button className="btn btn-white mr-3">
                <IconAngleArrowRight />
              </button>
              <button className="btn btn-white">
                <IconCalendar />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7">
              <AreaChart />
              <div className="bar-chart">
                <BarChart />
              </div>
            </div>
            <div className="col-lg-5">
              <BillingCard />
              <div className="form-row">
                <div className="col-lg-12">
                  <DriverList />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <CustomerList />
            </div>
            <div className="col-lg-3">
              <AccessorialsList />
            </div>
            <div className="col-lg-3">
              <RadialChart />
            </div>
            <div className="col-lg-3">
              <LineChart />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default index;
