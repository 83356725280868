import React, { useEffect } from "react";
import {
  IconAngleArrowLeft,
  IconAngleArrowRight,
  IconCalendar,
  IconCaretDown,
} from "../../../common/icons";
import DispatcherTabs from "../common-elements/dispatcherTabs";
import Pagination from "../../../common/pagination";
import DriverItineraryList from "./elements/driverList";
import DriverItineraryTable from "./elements/driverItineraryTable";

const DriverItinerary = (props) => {
  useEffect(() => {
    document.body.className = "has-subheader";
    return () => {
      document.body.className = "";
    };
  });
  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <DispatcherTabs activeTab={2} />
        <div className="page-fluid">
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center">
              <div className="d-flex align-items-center">
                <button className="btn btn-white mr-3">
                  <IconAngleArrowLeft />
                </button>
                <div className="d-flex align-items-center mr-3">
                  <h4 className="font-20 mb-0">Today</h4>
                  <IconCaretDown />
                </div>
                <button className="btn btn-white mr-3">
                  <IconAngleArrowRight />
                </button>
                <button className="btn btn-white">
                  <IconCalendar />
                </button>
              </div>
              <Pagination className="ml-auto" />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md" style={{ maxWidth: 280 }}>
              <DriverItineraryList />
            </div>
            <div className="col-md">
              <DriverItineraryTable />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DriverItinerary;
