import React from "react";
import { IconColumn, IconFilter } from "../../../../common/icons/index";
import CustomSelect from "../../../../common/CustomSelect";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const SearchFilter = () => {
  return (
    <div className="filter-row d-flex align-items-center py-15">
      <div className="form-check form-check--gray pr-30">
        <input className="form-check-input" type="checkbox" id="Available" />
        <label className="form-check-label" for="Available">
          Day Shift
          <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
            4
          </span>
        </label>
      </div>
      <div className="form-check form-check--gray pr-30">
        <input className="form-check-input" type="checkbox" id="Pending" />
        <label className="form-check-label" for="Pending">
          Pending
          <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
            49
          </span>
        </label>
      </div>
    </div>
  );
};

export default SearchFilter;
