import React, { useState } from "react";
import { IconAngleArrowDown, IconAngleArrowRight } from "../../../../common/icons";

const DoubleMovesCard = (props) => {
  const {
    id = "",
    title = "",
    count = "",
    isCardActive = true,
    onCardClicked = () =>
      console.error("You passed no action to the component"),
  } = props;

  return (
    <React.Fragment>
      <div className="col-md mb-1">
        <div
          className={`ncard d-flex flex-column justify-content-between p-10 ${
            isCardActive ? "active" : ""
          }`}
          onClick={onCardClicked}
        >
          <div className="d-flex align-items-center">
            <div>{title}</div>
            <div className="ml-auto">
              {isCardActive ? <IconAngleArrowDown /> : <IconAngleArrowRight />}
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-6 d-flex align-items-center">
              <div className="mr-1 sbox sbox--default px-2">{count}</div>
              <div className="text-muted">Available</div>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <div className="mr-1 sbox sbox--default px-2">{count}</div>
              <div className="text-muted">Dropped</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DoubleMovesCard;
