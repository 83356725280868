import React, { useState } from 'react';

const columns = [
  'Column 1',
  'Column 2',
  'Column 3',
  'Column 4',
  'Column 5 ',
  'Column 6',
  'Column 7',
  'Column 8',
];

const data = [];
for (let i = 0; i < 20; i++) {
  data.push({
    id: i,
    column1: 'SDEE4125554',
    column2: 'SDEE4125554',
    column3: 'SDEE4125554',
    column4: 'SDEE4125554',
    column5: 'SDEE4125554',
    column6: 'SDEE4125554',
    column7: 'SDEE4125554',
    column8: 'SDEE4125554',
  });
}

const StaticTableContent = () => {
  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{ height: 'calc(100vh - 193px)' }}
      >
        <table className="table table-card">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((datum) => {
              return (
                <tr key={datum.id}>
                  <td>{datum.column1}</td>
                  <td>{datum.column2}</td>
                  <td>{datum.column3}</td>
                  <td>{datum.column4}</td>
                  <td>{datum.column5}</td>
                  <td>{datum.column6}</td>
                  <td>{datum.column7}</td>
                  <td>{datum.column8}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
