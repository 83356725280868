import React from 'react';
import ReportsTabs from '../common-elements/ReportsTabs';
import {
  IconPlus,
  IconAngleArrowLeft,
  IconAngleArrowRight,
  IconCalendar,
  IconCaretDown,
} from '../../../common/icons';

import AreaChart from './AreaChart';
import TopCard from './TopCard';
import CustomerList from './CustomerList';
import EquipmentList from './EquipmentList';
import DriverList from './DriverList';
import RadialChart from './RadialChart';

const index = () => {
  return (
    <div className="position-relative subheader-wrapper">
      <ReportsTabs
        activeTab={17}
        buttons={
          <button className=" btn btn-success ml-2">
            <IconPlus className="mr-2" />
            Add Custom Report
          </button>
        }
      />
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-20 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <button className="btn btn-white mr-3">
                <IconAngleArrowLeft />
              </button>
              <div className="d-flex align-items-center mr-3">
                <h4 className="font-20 mb-0">Today</h4>
                <IconCaretDown />
              </div>
              <button className="btn btn-white mr-3">
                <IconAngleArrowRight />
              </button>
              <button className="btn btn-white">
                <IconCalendar />
              </button>
            </div>
          </div>
          <TopCard />
          <AreaChart />
          <div className="row">
            <div className="col-lg-6">
              <CustomerList />
              <EquipmentList />
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6">
                  <DriverList />
                </div>
                <div className="col-lg-6">
                  <RadialChart />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default index;
