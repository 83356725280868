import React from "react";
import { Modal } from "react-bootstrap";
import CustomSelect from "../../../../common/CustomSelect";

import {
  IconCalendar,
  IconClock
} from "../../../../common/icons/index";
import UploadDocument from "../../../../common/uploadDocument";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const SendReportsModal = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;
  return (
    <Modal show={showModal} centered animation={false}>
      <Modal.Header>
        <Modal.Title>Add Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-wrapper">
          <form>
            <div className="form-row mb-1">
              <div className="form-group col-md-12">
                <label className="col-form-label">Customer</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select Customer"
                  className="w-100"
                />
              </div>
            </div>
            <div className="form-row-md mb-1">
              <div className="form-group col-md-6">
                <label>Date</label>
                <div className="input-wrapper">
                  <input className="form-control" placeholder="MM/DD/YY" />
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                </div>
              </div>
              <div className="form-group col-md-6">
                <label>Time</label>
                <div className="input-wrapper">
                  <input className="form-control" placeholder="00:00 AM" />
                  <div className="input-icon">
                    <IconClock />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row mb-1">
              <div className="form-group col-md-12">
                <label className="col-form-label">Check #</label>
                <input className="form-control" placeholder="Check #" />
              </div>
            </div>
            <div className="form-row mb-1">
              <div className="form-group col-md-12">
                <label className="col-form-label">Payment method</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select..."
                  className="w-100"
                />
              </div>
            </div>
            <div className="form-row mb-1">
              <div className="form-group col-md-12">
                <label className="col-form-label">Account to deposit</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select..."
                  className="w-100"
                />
              </div>
            </div>
            <div className="form-row mb-1">
              <div className="form-group col-md-12">
                <label className="col-form-label">Document type</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Select..."
                  className="w-100"
                />
              </div>
            </div>
            <div className="form-row mb-1">
              <div className="form-group col-md-12">
                <label className="col-form-label">Total amount</label>
                <input className="form-control" placeholder="$0.00" />
              </div>
            </div>
            <div className="form-group">
              <div className="d-flex align-items-center justify-content-between w-100">
                <label className="col-form-label">Upload document</label>
                <span className="text-muted">optional</span>
              </div>
              <UploadDocument />
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={onSubmit}>
          Add Payment
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SendReportsModal;
