import React, { useState } from 'react';
import {
  IconSortDown,
  IconSortUp,
  IconAngleArrowDown,
  IconAngleArrowTop,
} from '../../../common/icons';
import LoadCard from './loadCard';

const StaticTableContent = () => {
  const [isLoadDetails, setIsLoadDetails] = useState(2);

  const showLoadDetails = (i) => {
    setIsLoadDetails(isLoadDetails === i ? null : i);
  };

  return (
    <React.Fragment>
      <table className=" table table-card">
        <thead>
          <tr>
            <th className="text-dark" width="30%">
              Date
              <IconSortUp />
            </th>
            <th width="30%">Order</th>
            <th width="30%">Sent via </th>
            <th width="8%"></th>
            <th width="2%"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>May 29, 2017</td>
            <td className="text-primary">
              settlement.from.client.5126.in.case.45.docx
            </td>
            <td>
              <div>San Bernardino</div>
              <div className="text-muted">California</div>
            </td>
            <td>
              <div className="">
                <button className=" btn btn-primary">Confirm 3 Loads</button>
              </div>
            </td>
            <td>
              <button
                className="btn btn-none"
                onClick={() => showLoadDetails(1)}
              >
                {isLoadDetails === 1 ? (
                  <IconAngleArrowTop />
                ) : (
                  <IconAngleArrowDown />
                )}
              </button>
            </td>
          </tr>
          {isLoadDetails === 1 && (
            <tr>
              <td colspan="5">
                <LoadCard />
              </td>
            </tr>
          )}
          <tr>
            <td>May 29, 2017</td>
            <td className="text-primary">
              settlement.from.client.5126.in.case.45.docx
            </td>
            <td>
              <div>San Bernardino</div>
              <div className="text-muted">California</div>
            </td>
            <td>
              <div className="">
                <button className=" btn btn-primary">Confirm 3 Loads</button>
              </div>
            </td>
            <td className=" ">
              <button
                className="btn btn-none"
                onClick={() => showLoadDetails(2)}
              >
                {isLoadDetails === 2 ? (
                  <IconAngleArrowTop />
                ) : (
                  <IconAngleArrowDown />
                )}
              </button>
            </td>
          </tr>
          {isLoadDetails === 2 && (
            <tr>
              <td colspan="5">
                <LoadCard />
              </td>
            </tr>
          )}
          <tr>
            <td>May 29, 2017</td>
            <td className="text-primary">
              settlement.from.client.5126.in.case.45.docx
            </td>
            <td>
              <div>San Bernardino</div>
              <div className="text-muted">California</div>
            </td>
            <td>
              <div className="">
                <button className=" btn btn-primary">Confirm 3 Loads</button>
              </div>
            </td>
            <td>
              <button
                className="btn btn-none"
                onClick={() => showLoadDetails(3)}
              >
                {isLoadDetails === 3 ? (
                  <IconAngleArrowTop />
                ) : (
                  <IconAngleArrowDown />
                )}
              </button>
            </td>
          </tr>
          {isLoadDetails === 3 && (
            <tr>
              <td colspan="5">
                <LoadCard />
              </td>
            </tr>
          )}
          <tr>
            <td>May 29, 2017</td>
            <td className="text-primary">
              settlement.from.client.5126.in.case.45.docx
            </td>
            <td>
              <div>San Bernardino</div>
              <div className="text-muted">California</div>
            </td>
            <td>
              <div className="">
                <button className=" btn btn-primary">Confirm 3 Loads</button>
              </div>
            </td>
            <td className=" ">
              <button
                className="btn btn-none"
                onClick={() => showLoadDetails(4)}
              >
                {isLoadDetails === 4 ? (
                  <IconAngleArrowTop />
                ) : (
                  <IconAngleArrowDown />
                )}
              </button>
            </td>
          </tr>
          {isLoadDetails === 4 && (
            <tr>
              <td colspan="5">
                <LoadCard />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default StaticTableContent;
