

const SeparatorLine = ({ type, middleText }) => {
 const horizonatalStyle = { height: '1.5px', background: '#d6d6d6', width: '100%' }
 const verticalStyle = { ...horizonatalStyle, height: '15px', width: '1.6px' }
 return <div
  className='separator d-flex justify-content-center my-4'
  style={type === 'horizontal' ? horizonatalStyle : verticalStyle}>
  {middleText && <span className='px-2 bg-white' id='middleText'>{middleText}</span>}
 </div>
}

export default SeparatorLine;