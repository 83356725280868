import React, { useState } from "react";
import {
  IconAngleArrowDown,
  IconAngleArrowRight,
} from "../../../../common/icons/index";
import Pagination from '../../../../common/pagination';
const cardItems = [
  {
    id: 1,
    price: "1,580.00",
    title: "Total Outstanding",
  },
  {
    id: 2,
    price: "1580.00",
    title: "0-30 Days",
  },
  {
    id: 3,
    price: 0,
    title: "30-60 Days",
  },
  {
    id: 4,
    price: 0,
    title: "60-90 Days",
  },
  {
    id: 5,
    price: 0,
    title: "90-120 Days",
  },
  {
    id: 6,
    price: 0,
    title: "120+ Days",
  },
];
const TopPage = () => {
  const [isCardActive, setCardActive] = useState(1);
  return (
    <div className="top-page">
      {/* Cards Row */}
      <div className={`form-row ${isCardActive ? "hasactive" : ""}`}>
        {cardItems.map((cardItem) => (
          <div className="col-md my-2">
            <div
              className={`ncard d-flex flex-column justify-content-between p-10 ${
                isCardActive === cardItem.id ? "active" : ""
              }`}
              onClick={() => setCardActive(cardItem.id)}
            >
              <div className="d-flex align-items-center">
                <div className="font-medium">{cardItem.title}</div>
                <div className="ml-auto">
                  {isCardActive === cardItem.id ? (
                    <IconAngleArrowDown />
                  ) : (
                    <IconAngleArrowRight />
                  )}
                </div>
              </div>
              <div className="font-medium font-20 mt-10">${cardItem.price}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopPage;
