import { useState } from "react";
import * as allIcons from "../../../common/customIcons/newCustomIcons";
import IconLayout from "./IconLayout";
import IconCard from "./IconCard";

const DocumentationCustomIcons = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const filteredIcons = Object.entries(allIcons).filter(([iconName]) => iconName.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <IconLayout activeTabName="Custom Icons" searchTerm={searchTerm} setSearchTerm={setSearchTerm}>
      {filteredIcons.map(([iconName, IconComponent]) => (
        <IconCard key={iconName} iconName={iconName} IconComponent={IconComponent} />
      ))}
    </IconLayout>
  );
};

export default DocumentationCustomIcons;
