import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import {
  Icondots,
  IconFilePointer,
  IconTrash,
  IconPrinter,
  IconAngleArrowLeft,
  IconAngleArrowRight,
  IconDownload,
} from "../icons";
const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const UploadedDocuments = (props) => {
  const [ShowUpDocumentUploadModal, setShowUpDocumentUploadModal] =
    useState(false);

  const handleShowUpDocumentUploadModal = (event) => {
    event.stopPropagation();
    setShowUpDocumentUploadModal(true);
  };

  const handleHideDocumentUploadModal = (event) => {
    setShowUpDocumentUploadModal(false);
  };

  const [isMoreOptions, setisMoreOptions] = useState(false);

  const handleisMoreOptions = (i,e) => {
    e.stopPropagation();
    setisMoreOptions(isMoreOptions === i ? null : i);
  };

  return (
    <React.Fragment>
      <div className="form-row-md">
        <div className="col-md-3">
          <div
            className={`card mb-0 p-10 ${
              isMoreOptions === 1 && "card--shadow-3"
            }`}
            onClick={handleShowUpDocumentUploadModal}
          >
            <div className="d-flex justify-content-between align-items-center">
              <h5>Cargo Password</h5>
              <div className="position-relative">
                <button
                  className="btn btn-link"
                  onClick={(e) => handleisMoreOptions(1,e)}
                >
                  <Icondots />
                </button>
                {isMoreOptions === 1 && (
                  <div className="card card--shadow-5 popdrop popdrop--right popdrop--sm mb-0 p-10 animated">
                    <a href="" className="popdrop__dropdown-item">
                      <IconFilePointer className="mr-2" />
                      Change Type
                    </a>
                    <a
                      href="javascript:void(0)"
                      className="popdrop__dropdown-item"
                    >
                      <IconPrinter className="mr-2" />
                      Print
                    </a>
                    <a
                      href="javascript:void(0)"
                      className="popdrop__dropdown-item text-danger"
                    >
                      <IconTrash className="mr-2" />
                      Remove
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="document-preview">
              <img
                className="document-preview__image"
                src="https://images.pexels.com/photos/590037/pexels-photo-590037.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
              />
            </div>
            <div className="">
              <div className="text-muted mb-1">PortDocsScan003.pdf</div>
              <div className="text-muted mb-2">Bessie Cooper</div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="text-muted">16/08/2013 • 1:09 am</div>
                <div className="form-check form-switch form-switch-md">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="document1"
                    checked
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div
            className={`card mb-0 p-10 ${
              isMoreOptions === 2 && "card--shadow-3"
            }`}
            onClick={handleShowUpDocumentUploadModal}
          >
            <div className="d-flex justify-content-between align-items-center">
              <h5>Cargo Password</h5>
              <div className="position-relative">
                <button
                  className="btn btn-link"
                  onClick={(e) => handleisMoreOptions(2,e)}
                >
                  <Icondots />
                </button>
                {isMoreOptions === 2 && (
                  <div className="card card--shadow-5 popdrop popdrop--right popdrop--sm mb-0 p-10 animated">
                    <a href="" className="popdrop__dropdown-item">
                      <IconFilePointer className="mr-2" />
                      Change Type
                    </a>
                    <a
                      href="javascript:void(0)"
                      className="popdrop__dropdown-item"
                    >
                      <IconPrinter className="mr-2" />
                      Print
                    </a>
                    <a
                      href="javascript:void(0)"
                      className="popdrop__dropdown-item text-danger"
                    >
                      <IconTrash className="mr-2" />
                      Remove
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="document-preview">
              <img
                className="document-preview__image"
                src="https://images.pexels.com/photos/590037/pexels-photo-590037.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
              />
            </div>
            <div className="">
              <div className="text-muted mb-1">PortDocsScan003.pdf</div>
              <div className="text-muted mb-2">Bessie Cooper</div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="text-muted">16/08/2013 • 1:09 am</div>
                <div className="form-check form-switch form-switch-md">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="document2"
                    checked
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div
            className={`card mb-0 p-10 card--disabled ${
              isMoreOptions === 3 && "card--shadow-3"
            }`}
            onClick={handleShowUpDocumentUploadModal}
          >
            <div className="d-flex justify-content-between align-items-center">
              <h5>Cargo Password</h5>
              <div className="position-relative">
                <button
                  className="btn btn-link"
                  onClick={(e) => handleisMoreOptions(3,e)}
                >
                  <Icondots />
                </button>
                {isMoreOptions === 3 && (
                  <div className="card card--shadow-5 popdrop popdrop--right popdrop--sm mb-0 p-10 animated">
                    <a href="" className="popdrop__dropdown-item">
                      <IconFilePointer className="mr-2" />
                      Change Type
                    </a>
                    <a
                      href="javascript:void(0)"
                      className="popdrop__dropdown-item"
                    >
                      <IconPrinter className="mr-2" />
                      Print
                    </a>
                    <a
                      href="javascript:void(0)"
                      className="popdrop__dropdown-item text-danger"
                    >
                      <IconTrash className="mr-2" />
                      Remove
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="document-preview">
              <img
                className="document-preview__image"
                src="https://images.pexels.com/photos/590037/pexels-photo-590037.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
              />
            </div>
            <div className="">
              <div className="text-muted mb-1">PortDocsScan003.pdf</div>
              <div className="text-muted mb-2">Bessie Cooper</div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="text-muted">16/08/2013 • 1:09 am</div>
                <div className="form-check form-switch form-switch-md">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="document3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div
            className={`card mb-0 p-10 ${
              isMoreOptions === 4 && "card--shadow-3"
            }`}
            onClick={handleShowUpDocumentUploadModal}
          >
            <div className="d-flex justify-content-between align-items-center">
              <h5>Cargo Password</h5>
              <div className="position-relative">
                <button
                  className="btn btn-link"
                  onClick={(e) => handleisMoreOptions(4,e)}
                >
                  <Icondots />
                </button>
                {isMoreOptions === 4 && (
                  <div className="card card--shadow-5 popdrop popdrop--right popdrop--sm mb-0 p-10 animated">
                    <a href="" className="popdrop__dropdown-item">
                      <IconFilePointer className="mr-2" />
                      Change Type
                    </a>
                    <a
                      href="javascript:void(0)"
                      className="popdrop__dropdown-item"
                    >
                      <IconPrinter className="mr-2" />
                      Print
                    </a>
                    <a
                      href="javascript:void(0)"
                      className="popdrop__dropdown-item text-danger"
                    >
                      <IconTrash className="mr-2" />
                      Remove
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="document-preview">
              <img
                className="document-preview__image"
                src="https://images.pexels.com/photos/590037/pexels-photo-590037.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
              />
            </div>
            <div className="">
              <div className="text-muted mb-1">PortDocsScan003.pdf</div>
              <div className="text-muted mb-2">Bessie Cooper</div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="text-muted">16/08/2013 • 1:09 am</div>
                <div className="form-check form-switch form-switch-md">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="document4"
                    checked="true"
                    value="document4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={ShowUpDocumentUploadModal} centered animation={false}>
        <Modal.Header className="d-flex align-items-start modal-header--gray">
          <div>
            <h5>Cargo Passwort</h5>
            <div className="text-muted mb-1">PortDocsScan003.pdf</div>
            <div className="text-muted mb-2">Bessie Cooper</div>
            <div className="text-muted">16/08/2013 • 1:09 am</div>
          </div>
          <div className="form-check form-check-right form-switch form-switch-md">
            <label className="form-check-label text-muted">
              Ready For Sending
            </label>
            <input
              type="checkbox"
              className="form-check-input"
              id="document1"
              checked
            />
          </div>
        </Modal.Header>
        <Modal.Body className="py-30">
          <img src="https://images.pexels.com/photos/590037/pexels-photo-590037.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div className="d-flex align-items-center">
            <button className="btn btn-link">
              <IconAngleArrowLeft />
            </button>
            <div className="mx-15">
              <span>1</span>
              <span className="text-muted mx-1">/</span>
              <span className="text-muted">3</span>
            </div>
            <button className="btn btn-link">
              <IconAngleArrowRight />
            </button>
          </div>
          <div className="d-flex align-items-center">
            <button className="btn btn-outline-light mr-2">
              <IconDownload className="mr-2" />
              Download
            </button>
            <button className="btn btn-outline-light mr-2">
              <IconPrinter className="mr-2" />
              Print
            </button>
            <button className="btn btn-outline-light mr-2">
              <IconTrash />
            </button>
            <button
              className="btn btn-primary"
              onClick={handleHideDocumentUploadModal}
            >
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default UploadedDocuments;
