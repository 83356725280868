import React, { Component } from "react";
import {
  CustomIconBilling,
  CustomIconBuilding,
  CustomIconMapMarker,
  CustomIconTruck,
} from "../../../common/customIcons";
class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNoteActive: false,
    };
  }

  handleNoteActive = (i) => {
    this.setState({
      isNoteActive: this.state.isNoteActive === i ? null : i,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="tab">
          <div
            className={`card my-10 ${
              this.state.isNoteActive === 1 ? "active" : ""
            }`}
            onClick={() => this.handleNoteActive(1)}
          >
            <div className="px-30 py-20">
              <div className="d-flex align-items-center mb-20">
                <CustomIconTruck className="mr-10" />
                <h5 className="font-16 mb-0">Driver</h5>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  placeholder="Notes for driver"
                ></textarea>
              </div>
            </div>
            {this.state.isNoteActive && (
              <div className="card-footer">
                <button className="btn btn-close">Cancel</button>
                <button className="btn btn-primary">Add Note</button>
              </div>
            )}
          </div>
          <div
            className={`card my-10 ${
              this.state.isNoteActive === 2 ? "active" : ""
            }`}
            onClick={() => this.handleNoteActive(2)}
          >
            <div className="px-30 py-20">
              <div className="d-flex align-items-center mb-20">
                <CustomIconBilling className="mr-10" />
                <h5 className="font-16 mb-0">Billing</h5>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  placeholder="Notes for billing"
                ></textarea>
              </div>
            </div>
            {this.state.isNoteActive && (
              <div className="card-footer">
                <button className="btn btn-close">Cancel</button>
                <button className="btn btn-primary">Add Note</button>
              </div>
            )}
          </div>
          <div
            className={`card my-10 ${
              this.state.isNoteActive === 3 ? "active" : ""
            }`}
            onClick={() => this.handleNoteActive(3)}
          >
            <div className="px-30 py-20">
              <div className="d-flex align-items-center mb-20">
                <CustomIconMapMarker className="mr-10" />
                <h5 className="font-16 mb-0">Yard</h5>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  placeholder="Notes for yard"
                ></textarea>
              </div>
            </div>
            {this.state.isNoteActive && (
              <div className="card-footer">
                <button className="btn btn-close">Cancel</button>
                <button className="btn btn-primary">Add Note</button>
              </div>
            )}
          </div>
          <div
            className={`card my-10 ${
              this.state.isNoteActive === 4 ? "active" : ""
            }`}
            onClick={() => this.handleNoteActive(4)}
          >
            <div className="px-30 py-20">
              <div className="d-flex align-items-center mb-20">
                <CustomIconBuilding className="mr-10" />
                <h5 className="font-16 mb-0">Office</h5>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  placeholder="Notes for office"
                ></textarea>
              </div>
            </div>
            {this.state.isNoteActive && (
              <div className="card-footer">
                <button className="btn btn-close">Cancel</button>
                <button className="btn btn-primary">Add Note</button>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Notes;
