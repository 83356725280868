import React from 'react';
import { Icondots } from '../../../common/icons';

const CustomerList = () => {
  return (
    <div className="card mb-20 p-20" style={{ height: '350px' }}>
      <div className="d-flex justify-content-between align-items-center pb-25">
        <div className="d-flex flex-column">
          <div className="font-16 font-weight-500 mb-1">Top 5 Customers</div>
          <div className="d-flex flex-row ">
            <div className=" badge badge-sm badge-accent-100 px-1 mr-1 rounded-10">
              +5.8%
            </div>
            <div className="text-muted">Since past week</div>
          </div>
        </div>
        <div className="ml-auto">
          <Icondots className="text-muted" />
        </div>
      </div>
      <div className="d-flex justify-content-between pb-20">
        <div className="d-flex">
          <div className="mr-10 align-self-center">
            <div className="avatar-circle ">
              <div className="font-12">F</div>
            </div>
          </div>
          <h5 className="mb-0 align-self-center font-12">
            <a href="#" className="text-dark">
              Funholding
            </a>
          </h5>
        </div>
        <div className="d-flex flex-column">
          <div className="text-gray-900 mr-1">$202.87</div>
          <div className=" badge badge-sm badge-accent-100 px-1 mr-1 rounded-10">
            +5.8%
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between pb-20">
        <div className="d-flex">
          <div className="mr-10 align-self-center">
            <div className="avatar-circle ">
              <div className="font-12">F</div>
            </div>
          </div>
          <h5 className="mb-0 align-self-center font-12">
            <a href="#" className="text-dark">
              Funholding
            </a>
          </h5>
        </div>
        <div className="d-flex flex-column">
          <div className="text-gray-900 mr-1">$202.87</div>
          <div className=" badge badge-sm badge-danger-light px-1 mr-1 rounded-10">
            +5.8%
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between pb-20">
        <div className="d-flex">
          <div className="mr-10 align-self-center">
            <div className="avatar-circle ">
              <div className="font-12">F</div>
            </div>
          </div>
          <h5 className="mb-0 align-self-center font-12">
            <a href="#" className="text-dark">
              Funholding
            </a>
          </h5>
        </div>
        <div className="d-flex flex-column">
          <div className="text-gray-900 mr-1">$202.87</div>
          <div className=" badge badge-sm badge-accent-100 px-1 mr-1 rounded-10">
            +5.8%
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between pb-20">
        <div className="d-flex">
          <div className="mr-10 align-self-center">
            <div className="avatar-circle ">
              <div className="font-12">F</div>
            </div>
          </div>
          <h5 className="mb-0 align-self-center font-12">
            <a href="#" className="text-dark">
              Funholding
            </a>
          </h5>
        </div>
        <div className="d-flex flex-column">
          <div className="text-gray-900 mr-1">$202.87</div>
          <div className=" badge badge-sm badge-accent-100 px-1 mr-1 rounded-10">
            +5.8%
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between pb-10">
        <div className="d-flex">
          <div className="mr-10 align-self-center">
            <div className="avatar-circle ">
              <div className="font-12">F</div>
            </div>
          </div>
          <h5 className="mb-0 align-self-center font-12">
            <a href="#" className="text-dark">
              Funholding
            </a>
          </h5>
        </div>
        <div className="d-flex flex-column">
          <div className="text-gray-900 mr-1">$202.87</div>
          <div className=" badge badge-sm badge-accent-100 px-1 mr-1 rounded-10">
            +5.8%
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerList;
