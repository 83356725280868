import React from 'react';
import CustomSelect from '../../../../common/CustomSelect';
import { IconMinus } from '../../../../common/icons';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

const Accessorials = () => {
  return (
    <div className="form-wrapper p-30">
      <form>
        <div className="form-row ">
          <div className="form-group col-md">
            <label className="col-form-label">
              <span className="text-danger">*</span> Accessorial
            </label>
            <CustomSelect
              name={'name'}
              options={options}
              value={1}
              isMulti={true}
              isClearable={true}
              size="small"
              placement="right"
              placeholder="Chassis"
            />
          </div>
          <div className="form-group col-md">
            <label className="col-form-label">
              <span className="text-danger">*</span> Unit
            </label>
            <CustomSelect
              name={'name'}
              options={options}
              value={1}
              isMulti={true}
              isClearable={true}
              size="small"
              placement="right"
              placeholder="Flat %"
            />
          </div>
          <div class="form-group col-md-1 d-flex align-items-center justify-content-center">
            <div class="btn-wrapper mt-1">
              <button class="btn btn-xs btn-circle bg-soft-danger mt-4 ">
                <IconMinus />
              </button>
            </div>
          </div>
        </div>
        <div className="form-row mb-35">
          <div className="form-group col-md mb-0">
            <label className="col-form-label">Pull</label>
            <input type="text" className="form-control" placeholder="0%" />
          </div>
          <div className="form-group col-md mb-0">
            <label className="col-form-label">Pull Shuttle</label>
            <input type="text" className="form-control" placeholder="0%" />
          </div>
          <div className="form-group col-md mb-0">
            <label className="col-form-label">Delivery</label>
            <input type="text" className="form-control" placeholder="0%" />
          </div>
          <div className="form-group col-md mb-0">
            <label className="col-form-label">Delivery Shuttle</label>
            <input type="text" className="form-control" placeholder="0%" />
          </div>
          <div className="form-group col-md mb-0">
            <label className="col-form-label">Return</label>
            <input type="text" className="form-control" placeholder="0%" />
          </div>
        </div>
        <div className="hr-light m-0"></div>
        <div className="form-row mt-35">
          <div className="form-group col-md">
            <label className="col-form-label">
              <span className="text-danger">*</span> Accessorial
            </label>
            <CustomSelect
              name={'name'}
              options={options}
              value={1}
              isMulti={true}
              isClearable={true}
              size="small"
              placement="right"
              placeholder="Chassis"
            />
          </div>
          <div className="form-group col-md">
            <label className="col-form-label">
              <span className="text-danger">*</span> Unit
            </label>
            <CustomSelect
              name={'name'}
              options={options}
              value={1}
              isMulti={true}
              isClearable={true}
              size="small"
              placement="right"
              placeholder="Flat %"
            />
          </div>
          <div class="form-group col-md-1 d-flex align-items-center justify-content-center">
            <div class="btn-wrapper mt-1">
              <button class="btn btn-xs btn-circle bg-soft-danger mt-4 ">
                <IconMinus />
              </button>
            </div>
          </div>
        </div>
        <div className="form-row mb-35">
          <div className="form-group col-md mb-0">
            <label className="col-form-label">Pull</label>
            <input type="text" className="form-control" placeholder="$0.00" />
          </div>
          <div className="form-group col-md mb-0">
            <label className="col-form-label">Pull Shuttle</label>
            <input type="text" className="form-control" placeholder="$0.00" />
          </div>
          <div className="form-group col-md mb-0">
            <label className="col-form-label">Delivery</label>
            <input type="text" className="form-control" placeholder="$0.00" />
          </div>
          <div className="form-group col-md mb-0">
            <label className="col-form-label">Delivery Shuttle</label>
            <input type="text" className="form-control" placeholder="$0.00" />
          </div>
          <div className="form-group col-md mb-0">
            <label className="col-form-label">Return</label>
            <input type="text" className="form-control" placeholder="$0.00" />
          </div>
        </div>
        <div className="hr-light m-0"></div>
        <div className="form-row mt-35 ">
          <div className="form-group col-md">
            <label className="col-form-label">
              <span className="text-danger">*</span> Accessorial
            </label>
            <CustomSelect
              name={'name'}
              options={options}
              value={1}
              isMulti={true}
              isClearable={true}
              size="small"
              placement="right"
              placeholder="Chassis"
            />
          </div>
          <div className="form-group col-md">
            <label className="col-form-label">
              <span className="text-danger">*</span> Unit
            </label>
            <CustomSelect
              name={'name'}
              options={options}
              value={1}
              isMulti={true}
              isClearable={true}
              size="small"
              placement="right"
              placeholder="Flat %"
            />
          </div>
          <div class="form-group col-md-1 d-flex align-items-center justify-content-center">
            <div class="btn-wrapper mt-1">
              <button class="btn btn-xs btn-circle bg-soft-danger mt-4 ">
                <IconMinus />
              </button>
            </div>
          </div>
        </div>
        <div className="form-row mb-35">
          <div className="form-group col-md-4 mb-0">
            <label className="col-form-label">Amount</label>
            <input type="text" className="form-control" placeholder="0%" />
          </div>
        </div>
        <div className="hr-light m-0"></div>
        <div className="form-row mt-35">
          <div className="form-group col-md">
            <label className="col-form-label">
              <span className="text-danger">*</span> Accessorial
            </label>
            <CustomSelect
              name={'name'}
              options={options}
              value={1}
              isMulti={true}
              isClearable={true}
              size="small"
              placement="right"
              placeholder="Chassis"
            />
          </div>
          <div className="form-group col-md">
            <label className="col-form-label">
              <span className="text-danger">*</span> Unit
            </label>
            <CustomSelect
              name={'name'}
              options={options}
              value={1}
              isMulti={true}
              isClearable={true}
              size="small"
              placement="right"
              placeholder="Pound %"
            />
          </div>
          <div class="form-group col-md-1 d-flex align-items-center justify-content-center">
            <div class="btn-wrapper mt-1">
              <button class="btn btn-xs btn-circle bg-soft-danger mt-4 ">
                <IconMinus />
              </button>
            </div>
          </div>
        </div>
        <div className="form-row ">
          <div className="form-group col-md mb-0">
            <label className="col-form-label">Amount</label>
            <input type="text" className="form-control" placeholder="0%" />
          </div>
          <div className="form-group col-md mb-0">
            <label className="col-form-label">Pull</label>
            <input type="text" className="form-control" placeholder="0%" />
          </div>
          <div className="form-group col-md mb-0">
            <label className="col-form-label">Pull Shuttle</label>
            <input type="text" className="form-control" placeholder="0%" />
          </div>
          <div className="form-group col-md mb-0">
            <label className="col-form-label">Delivery</label>
            <input type="text" className="form-control" placeholder="0%" />
          </div>
          <div className="form-group col-md mb-0">
            <label className="col-form-label">Delivery Shuttle</label>
            <input type="text" className="form-control" placeholder="0%" />
          </div>
          <div className="form-group col-md mb-0">
            <label className="col-form-label">Return</label>
            <input type="text" className="form-control" placeholder="0%" />
          </div>
        </div>
        <div className="text-primary mt-30">+ Add Accessorial</div>
      </form>
    </div>
  );
};

export default Accessorials;
