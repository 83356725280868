import React, { useState } from 'react';
import OtherTabs from '../common-elements/OtherTabs';
import Cards from '../common-elements/Cards';
import StaticTableContent from './elements/StaticTableContent';
import { IconQuickBook, IconDownload, IconPlus } from '../../../common/icons';
import AddNewRates from './elements/AddNewRates';
import ProfileTabs from '../common-elements/ProfileTabs';
import Pagination from '../common-elements/Pagination';

const cardItems = [
  {
    id: 1,
    count: 540,
    title: 'Lane Rates ',
  },
  {
    id: 2,
    count: 703,
    title: 'ZIP Code Rates',
  },
];

const CustomerRates = () => {
  const [CardActive, setCardActive] = useState(1);
  const [ShowNewRatesModal, setShowNewRatesModal] = useState(false);

  const handleShowNewRatesModal = (event) => {
    setShowNewRatesModal(true);
  };

  const handleHideNewRatesModal = (event) => {
    setShowNewRatesModal(false);
  };
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <OtherTabs
            activeTab={2}
            button={
              <div className="d-flex">
                <button className="mr-2 btn btn-outline-light ">
                  <IconDownload className="mr-2" />
                  Downlaod CVS
                </button>
                <button
                  className="ml-auto btn btn-success"
                  onClick={handleShowNewRatesModal}
                >
                  <IconPlus className="mr-2" />
                  Add Rates
                </button>
              </div>
            }
          />
        </div>
      </div>
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-15 d-flex align-items-center justify-content-between">
            <ProfileTabs activeTab={'Rates'} />
            <Pagination />
          </div>
          {/* Cards Row */}
          <div className={`form-row mb-15 ${CardActive ? 'hasactive' : ''}`}>
            {cardItems.map(({ id, title, count }) => (
              <Cards
                title={title}
                count={count}
                onCardClicked={() => setCardActive(id)}
                isCardActive={CardActive === id}
              />
            ))}
          </div>
        </div>
        {/* Static Table and Search Filter */}
        <StaticTableContent />
      </div>

      {ShowNewRatesModal && (
        <AddNewRates
          onCancel={handleHideNewRatesModal}
          onSubmit={handleHideNewRatesModal}
        />
      )}
    </div>
  );
};

export default CustomerRates;
