import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import AccountSettingModal from "./AccountSettingModal/index";

const Index = () => {
  const [openAccountSettingModal, setOpenAccountSettingModal] = useState(false);

  return (
    <div className="">
      <Modal show={!openAccountSettingModal} centered size="lg" backdrop="static" style={{
        boxShadow: "0px 15px 35px rgba(20, 28, 52, 0.2)",
        fontFamily: 'Inter',
        lineHeight: "15px"
      }}>
        <Modal.Body className="p-0 px-5">
          <div className="text-dark pt-4">
            <p className='text-center' style={{ fontSize: "18px", color: "#172A41", fontWeight: "500" }}>Welcome to PortPro's drayOS, we are very excited to have you abroad! 👋👋👋</p><br />
            <video
              className="rounded-5"
              src="https://www.portpro.io/assets/videos/PortPro_Introduces_drayOS.mp4"
              controls
              muted
              style={{ height: "300px", width: "100%", objectFit: "cover" }}
            />
            <p className="mt-10 text-start font-14" style={{ lineHeight: "20px", fontWeight: "400" }}>
              You have now already scheduled your onboarding call, where you will meet your Account Manager who will be with you every step of the way getting you
              rocking and rolling on the system! <br /><br />
              Before that meeting, please share across files you may have with equipment information, driver details, customer and delivery location information, as well as
              your customer rates and driver pay rates. You can provide this information in any format, excel, pdf, email, you name it! With those files we will automatically
              set up your account so you don't have to spend anytime doing it! <br /><br />
              Also, please check out our "How to use drayS" section, which will take you through a step by step guide, leading you about how to use the system.
              Again, we are privileged to have the opportunity to work with you and look forward to helping your company excel! <br /><br />
              See you on the road! <br /><br />
              <b>Mike Mecca</b><br />
              <span>Founder / CEO</span>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-primary' onClick={() => setOpenAccountSettingModal((openAccountSettingModal) => !openAccountSettingModal)}>OK, let's start!</button>
        </Modal.Footer>
      </Modal>
      {
        openAccountSettingModal && <AccountSettingModal show={openAccountSettingModal} />
      }
    </div>
  )
}

export default Index
