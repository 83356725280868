import React, { useState } from 'react';
import TabComponent from '../../../common/tabComponent';
import { IconDownload } from '../../../common/icons';
import Chat from '..//../customerService/customerMessaging/chat';
import CustomerTabs from '../common-elements/CustomerTabs';
import MessageTabs from '../common-elements/MessageTabs';

const tabItems = [
  {
    id: 1,
    title: 'Dropped Container',
  },
  {
    id: 2,
    title: 'Load Calendar',
  },
  {
    id: 3,
    title: 'Bulk Uploads',
  },
  {
    id: 4,
    title: 'Chassis Validation',
  },
  {
    id: 5,
    title: 'Customer Messaging',
  },
  {
    id: 6,
    title: 'Driver Messaging',
  },
  {
    id: 7,
    title: 'Deleted Loads',
  },
];

const chatItems = [
  {
    avatar:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg',
    name: 'Darrell Steward',
    time: 'active 2 hr ago',
  },
  {
    avatar:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg',
    name: 'Wade Warren',
    time: 'active 2 hr ago',
  },
  {
    avatar:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg',
    name: 'Esther Howard',
    time: '26 Aug 2021, 10:10 am',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Arlene McCoy',
    time: '26 Aug 2021, 10:10 am',
  },
  {
    avatar:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg',
    name: 'Robert Fox',
    time: '26 Aug 2021, 10:10 am',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Jenny Wilson',
    time: '26 Aug 2021, 10:10 am',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Savannah Nguygen',
    time: '26 Aug 2021, 10:10 am',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Courtney Henry',
    time: 'October 31, 2017',
  },

  {
    avatar:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg',
    name: 'Annette Black',
    time: 'October 31, 2017',
  },
  {
    avatar:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg',
    name: 'Albert Flores',
    time: 'August 2, 2013',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Courtney Henry',
    time: 'October 31, 2017',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Courtney Henry',
    time: 'October 31, 2017',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Courtney Henry',
    time: 'October 31, 2017',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Courtney Henry',
    time: 'October 31, 2017',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Courtney Henry',
    time: 'October 31, 2017',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Courtney Henry',
    time: 'October 31, 2017',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Courtney Henry',
    time: 'October 31, 2017',
  },
  {
    avatar:
      'https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png',
    name: 'Courtney Henry',
    time: 'October 31, 2017',
  },
  {
    avatar:
      'https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg',
    name: 'Marvin Mckinney',
    time: 'August 2, 2013',
  },
];

const CustomerMessaging = () => {
  const [tabActive, setTabActive] = useState(1);

  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <CustomerTabs activeTab={8} />
        <div className="page-fluid ">
          <MessageTabs activeTab={'Driver'} />
          <div className="form-row-lg d-flex justify-content-between">
            <div
              className="col"
              style={{ height: 'calc(100vh - 150px)', maxWidth: 313 }}
            >
              <div className="card p-2 mb-10">
                <div className="app-search ">
                  <input
                    type="text"
                    className="input-search-left form-control"
                    placeholder="Search.."
                  />
                  <span className="search-icon"></span>
                </div>
              </div>
              <div className="dlist" style={{ height: 'calc(100% - 55px)' }}>
                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Darrell Steward
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>active 2 hrs ago</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="clist__card mb-1 bg-primary ">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
                        className="avatar rounded-circle  border-white"
                        style={{ border: '2px solid' }}
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-white ">
                          Wade Warren
                        </a>
                      </h5>
                      <p className="text-white mb-0">
                        <span>active 2 hrs ago</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Esther Horward
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>26 Aug 2021, 10:10 am</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Arlene McCoy
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>26 Aug 2021, 10:10 am</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Robert Fox
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>26 Aug 2021, 10:10 am</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Jenny Wilson
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>26 Aug 2021, 10:10 am</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Savannah Nguyen
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>Februrary 9, 2015</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Courtney Henry
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>October 31, 2017</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Ralph Edwards
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>May 20, 2015</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Annette Black
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>October 24, 2018</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Albert Flores
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>August 2, 2013</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Marvin McKinney
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>February 29, 2012</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Marvin McKinney
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>February 29, 2012</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Marvin McKinney
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>February 29, 2012</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Marvin McKinney
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>February 29, 2012</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Marvin McKinney
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>February 29, 2012</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Marvin McKinney
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>February 29, 2012</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Marvin McKinney
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>February 29, 2012</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Marvin McKinney
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>February 29, 2012</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Marvin McKinney
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>February 29, 2012</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Marvin McKinney
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>February 29, 2012</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Marvin McKinney
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>February 29, 2012</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Marvin McKinney
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>February 29, 2012</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Marvin McKinney
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>February 29, 2012</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Marvin McKinney
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>February 29, 2012</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Marvin McKinney
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>February 29, 2012</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Marvin McKinney
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>February 29, 2012</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Marvin McKinney
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>February 29, 2012</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="clist__card mb-1">
                  <div className="dlist__info d-flex align-items-start pl-2 py-2 pr-1">
                    <div className="flex-shrink-0 mr-2 align-self-center">
                      <img
                        src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                        className="avatar rounded-circle"
                        width="30"
                        alt=""
                      />
                    </div>
                    <div className="flex-grow-1">
                      <h5 className="font-14 mb-1">
                        <a href="#" className="text-dark">
                          Eleanor Pena
                        </a>
                      </h5>
                      <p className="text-muted mb-0">
                        <span>May 12, 2019</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Chat />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerMessaging;
