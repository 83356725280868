import React, { Component } from "react";
import chartUser from "../../../assets/assets/images/chat/chartUser.png";
import Chatpop from "../../../common/chatpop";
import { IconPlus, Icondots } from "../../../common/icons";
class TrackChat extends Component {
  render() {
    return (
      <div className="col" style={{ height: "calc(100vh - 216px)" }}>
        <div className="top-chat-list">
          <div
            className="filter-row d-flex justify-content-between  mb-2"
            style={{ height: "56px", borderRadius: "3px 3px 0px 0px" }}
          >
            <div className="d-flex justify-content-center align-items-center">
              <div className="image-list position-relative">
                <span className="d-inline-block border-2 rounded-circle border-white ml-n1">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
                    className="avatar rounded-circle"
                    width="20"
                    alt=""
                  />
                </span>
                <span
                  className="d-inline-block border-2 rounded-circle border-white ml-n1"
                  ml-n1
                >
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    className="avatar rounded-circle"
                    width="20"
                    alt=""
                  />
                </span>
                <span className="d-inline-block ml-n1">
                  <img
                    src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                    className="avatar rounded-5"
                    width="20"
                    alt=""
                  />
                </span>
                <span className="d-inline-block border-2 rounded-circle border-white ml-n1">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
                    className="avatar rounded-circle"
                    width="20"
                    alt=""
                  />
                </span>
                <span className="d-inline-block border-2 rounded-circle border-white ml-n1">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
                    className="avatar rounded-circle"
                    width="20"
                    alt=""
                  />
                </span>
                <span className="d-inline-block ml-n1">
                  <img
                    src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                    className="avatar rounded-5"
                    width="20"
                    alt=""
                  />
                </span>
                <span className="d-inline-block ml-n1">
                  <img
                    src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                    className="avatar rounded-5"
                    width="20"
                    alt=""
                  />
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-center align-items-center">
                <div className="mr-4">
                  <button className="btn btn-outline-light py-1 font-weight-500">
                    <IconPlus className="mr-2" />
                    Add user
                  </button>
                </div>
                <div className="mr-2">
                  <Icondots className="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Chatpop className="chatpop--full" />
      </div>
    );
  }
}

export default TrackChat;
