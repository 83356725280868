import React, { useState } from 'react'
import {IconShare } from '../../../common/icons';
import LiveShareModal from './ShareModal';

const DriverDetails = () => {
  const [isSelectCard, setisSelectCard] = useState(false)
  const [isPlayed, setisPlayed] = useState(false)

  const handleSelectCard = (i) => {
    setisSelectCard(isSelectCard === i ? null : i);
  }

  const handlePlayed = (e) => {
    setisPlayed({ isPlayed: !isPlayed });
  }
  const [shareModal, setshareModal] = useState(false);

  const handleShowModal = (e) => {
    setshareModal(true);
  }

  const handleHideModal = (e) => {
    setshareModal(false);
  }

  
  return (
      <React.Fragment>
        {/* START TrackBar Design */}
        <div
          className="mapbar mapbar-big"
          style={{ height: "calc(100vh - 120px)" }}
        >
          <div
            className={`dlist ${
              isSelectCard ? "dlist--cardselected" : ""
            }`}
            style={{ height: "calc(100% - 10px)" }}
          >
            <div
              className={`dlist__card dlist__card--complete mb-1 p-20 ${
                isSelectCard === 1 ? "dlist__card--selected" : ""
              }`}
              onClick={(e) => handleSelectCard(1)}
            >
              <div className="d-flex align-items-start py-2">
                <div className="flex-grow-1">
                  <h5 className="font-14 mb-1">
                    <a href="/shipos/live-share" className="text-dark">
                      M07982233
                    </a>
                  </h5>
                  <p className="text-muted mb-0">
                    <span>21 Aug, 2021, 06:32 pm</span>
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <div className="text-muted">40’ HC</div>
                  <div className="text-muted">UJSK8223432</div>
              </div>
              <div className="flex-shrink-0 ml-10">
                <button className="btn btn-outline-primary"
                  onClick={(e) => {
                    handleShowModal()
                    e.stopPropagation();
                  }}
                >   
                  <IconShare />
                </button>
              </div>
            </div>
              
              <div className="dlist__distance">
                <div className="dlist__distance--row d-flex">
                  <div className="dlist__timeframe">
                    <div className="dlist__timeframe--icon">
                      <i className="uil uil-check"></i>
                    </div>
                    <div className="dlist__timeframe--miles">423 mi</div>
                  </div>
                  <div className="dlist__distance--info w-100">
                    <div className="d-flex align-items-start pb-2">
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="/shipos/live-share" className="text-dark">
                            VG - Van Group
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>Springville, AR</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-soft-success">
                          Hookcontainer
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center pb-2">
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                      <div className="flex-shrink-0 mx-2">
                        <span className="badge bg-light">7 min</span>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                    </div>
                    <div className="dlist__hr">
                      <span className="badge badge-light font-12 dlist__hr__time">
                        7 min 32 sec
                      </span>
                    </div>
                  </div>
                </div>
                <div className="dlist__distance--row d-flex">
                  <div className="dlist__timeframe">
                    <div className="dlist__timeframe--icon">
                      <i className="uil uil-check"></i>
                    </div>
                  </div>
                  <div className="dlist__distance--info w-100">
                    <div className="d-flex align-items-start pb-2">
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="/shipos/live-share" className="text-dark">
                            VG - Van Group
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>Springville, AR</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-soft-success">
                          Hookcontainer
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center pb-2">
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                      <div className="flex-shrink-0 mx-2">
                        <span className="badge bg-light">7 min</span>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`dlist__card dlist__card--active mb-1 p-20 ${
                isSelectCard === 2 ? "dlist__card--selected" : ""
              }`}
              onClick={() => handleSelectCard(2)}
            >
              <div className="d-flex align-items-start py-2">
                <div className="flex-grow-1">
                  <h5 className="font-14 mb-1">
                    <a href="/shipos/live-share" className="text-dark">
                      M07982233
                    </a>
                  </h5>
                  <p className="text-muted mb-0">
                    <span>21 Aug, 2021, 06:32 pm</span>
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <div className="text-muted">40’ HC</div>
                  <div className="text-muted">UJSK8223432</div>
              </div>
              <div className="flex-shrink-0 ml-10">
                <button className="btn btn-outline-primary"
                  onClick={(e) => {
                    handleShowModal()
                    e.stopPropagation();
                  }}
                >   
                  <IconShare />
                </button>
              </div>
              </div>
              <div className="dlist__distance">
                <div className="dlist__distance--row d-flex">
                  <div className="dlist__timeframe">
                    <div className="dlist__timeframe--icon">A</div>
                    <div className="dlist__timeframe--miles">423 mi</div>
                  </div>
                  <div className="dlist__distance--info w-100">
                    <div className="d-flex align-items-start pb-2">
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="/shipos/live-share" className="text-dark">
                            VG - Van Group
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>Springville, AR</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-soft-success">
                          Hookcontainer
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center pb-2">
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                      <div className="flex-shrink-0 mx-2">
                        <span className="badge bg-light">7 min</span>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                    </div>
                    <div className="dlist__hr">
                      <span className="badge badge-light font-12 dlist__hr__time">
                        7 min 32 sec
                      </span>
                    </div>
                  </div>
                </div>
                <div className="dlist__distance--row d-flex">
                  <div className="dlist__timeframe">
                    <div className="dlist__timeframe--icon">B</div>
                    <div className="dlist__timeframe--miles">13 mi</div>
                  </div>
                  <div className="dlist__distance--info w-100">
                    <div className="d-flex align-items-start pb-2">
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="/shipos/live-share" className="text-dark">
                            VG - Van Group
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>Springville, AR</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-soft-success">
                          Hookcontainer
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center pb-2">
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                      <div className="flex-shrink-0 mx-2">
                        <span className="badge bg-light">7 min</span>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                    </div>
                    <div className="dlist__hr">
                      <span className="badge badge-light font-12 dlist__hr__time">
                        7 min 32 sec
                      </span>
                    </div>
                  </div>
                </div>
                <div className="dlist__distance--row d-flex">
                  <div className="dlist__timeframe">
                    <div className="dlist__timeframe--icon">C</div>
                  </div>
                  <div className="dlist__distance--info w-100">
                    <div className="d-flex align-items-start pb-2">
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="/shipos/live-share" className="text-dark">
                            VG - Van Group
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>Springville, AR</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-soft-success">
                          Hookcontainer
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center pb-2">
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                      <div className="flex-shrink-0 mx-2">
                        <span className="badge bg-light">7 min</span>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`dlist__card dlist__card--upcoming mb-1 p-20 ${
               isSelectCard === 3 ? "dlist__card--selected" : ""
              }`}
              onClick={() => handleSelectCard(3)}
            >
              <div className="d-flex align-items-start py-2">
                <div className="flex-grow-1">
                  <h5 className="font-14 mb-1">
                    <a href="#" className="text-dark">
                      M07982233
                    </a>
                  </h5>
                  <p className="text-muted mb-0">
                    <span>21 Aug, 2021, 06:32 pm</span>
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <div className="text-muted">40’ HC</div>
                  <div className="text-muted">UJSK8223432</div>
              </div>
                <div className="flex-shrink-0 ml-10">
                  <button className="btn btn-outline-primary"
                    onClick={(e) => {
                      handleShowModal()
                      e.stopPropagation();
                    }}
                  >   
                    <IconShare />
                  </button>
                </div>
              </div>
              <div className="dlist__distance">
                <div className="dlist__distance--row d-flex">
                  <div className="dlist__timeframe">
                    <div className="dlist__timeframe--icon">
                      <i className="uil uil-clock-nine"></i>
                    </div>
                  </div>
                  <div className="dlist__distance--info w-100">
                    <div className="d-flex align-items-start pb-2">
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="/shipos/live-share" className="text-dark">
                            VG - Van Group
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>Springville, AR</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-soft-success">
                          Hookcontainer
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center pb-2">
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                      <div className="flex-shrink-0 mx-2">
                        <span className="badge bg-light">7 min</span>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dlist__distance--row d-flex">
                  <div className="dlist__timeframe">
                    <div className="dlist__timeframe--icon">
                      <i className="uil uil-clock-nine"></i>
                    </div>
                  </div>
                  <div className="dlist__distance--info w-100">
                    <div className="d-flex align-items-start pb-2">
                      <div className="flex-grow-1">
                        <h5 className="font-14 mb-1">
                          <a href="/shipos/live-share" className="text-dark">
                            VG - Van Group
                          </a>
                        </h5>
                        <p className="text-muted mb-0">
                          <span>Springville, AR</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span className="badge badge-soft-success">
                          Hookcontainer
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center pb-2">
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                      <div className="flex-shrink-0 mx-2">
                        <span className="badge bg-light">7 min</span>
                      </div>
                      <div className="flex-grow-1">
                        <p className="text-muted mb-0">
                          <span>Enroute</span>
                        </p>
                        <div>21 Aug, 2021, 06:32 pm</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        {shareModal && (
          <LiveShareModal
            onCancel={handleHideModal}
            showModal={shareModal}
          />
        )}
      </div>
      
        {/* END TrackBar Design */}
      </React.Fragment>
    );
}

export default DriverDetails