import React, { useState } from "react";
import {
  IconAngleArrowDown,
  IconAngleArrowRight,
} from "../../../../common/icons/index";
import Pagination from "../../../../common/pagination";
const cardItems = [
  {
    id: 1,
    price: 164,
    title: "Day Drivers on Day Shift",
  },
  {
    id: 2,
    price: 79,
    title: "Night Drivers on Night Shift",
  },
];
const TopPage = () => {
  return (
    <div className="top-page">
      <div className="d-flex align-items-center justify-content-between my-20">
        <h4 className="font-20 mb-0">53 Co. Drivers</h4>
        <Pagination />
      </div>
    </div>
  );
};

export default TopPage;
