import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CustomSelect from "../../../../common/CustomSelect";
import { IconCalendar } from "../../../../common/icons";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const AddNewDriverPay = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;

  return (
    <Modal show={showModal} centered animation={false}>
      <Modal.Header>
        <Modal.Title>Add Driver Pay</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-wrapper">
          <form>
            <div className="form-row mb-10">
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Driver
                </label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="- Select Driver"
                  className="w-100"
                />
              </div>
            </div>
            <div className="form-row-md mb-10">
              <div className="form-group col-md-12">
                <label>
                  <span className="text-danger">*</span> From
                </label>
                <input className="form-control" placeholder="-  Select Port" />
              </div>
              <div className="form-group col-md-12">
                <label>
                  <span className="text-danger">*</span> To
                </label>
                <input className="form-control" placeholder="-  Select Port" />
              </div>
              <div className="form-group col-md-12">
                <div className="input-wrapper w-150">
                  <input
                    className="form-control"
                    placeholder="-Select Port"
                  />
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                </div>
              </div>
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Amount
                </label>
                <input className="form-control w-150" placeholder="$0.00" />
              </div>
              <div className="form-group col-md-12">
                <label className="col-form-label">Description</label>
                <textarea
                  className="form-control"
                  placeholder="-  Select consignee"
                  style={{ minHeight: "8rem" }}
                ></textarea>
              </div>
            </div>
            <div className="form-row mb-10"></div>
            <div className="form-row mb-10"></div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={onSubmit}>
          Add Driver Pay
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewDriverPay;
