import React, { useState } from "react";
import {
  IconFilter,
  IconTimesThin,
  IconReset,
} from "../../../../common/icons/index";
import CustomSelect from "../../../../common/CustomSelect";
import Pagination from "../../../../common/pagination";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const TopPage = () => {
  const [isFilterActive, setIsFilterActive] = useState(false);
  const showFilter = () => {
    setIsFilterActive(true);
  };
  const hideFilter = () => {
    setIsFilterActive(false);
  };

  return (
    <div className="top-page">
      <div className="d-flex align-items-center mb-15">
        <h3 className="font-20 mb-0">53 Reports</h3>
        <Pagination className="ml-auto" />
      </div>
      <div className="filter-row d-flex align-items-center">
        <CustomSelect
          placeholder="Select Customer"
          className="w-150 mr-1"
          size="small"
          options={options}
        />
        <CustomSelect
          placeholder="Select Agent.."
          className="w-150"
          size="small"
          options={options}
        />

        <button className="btn btn-outline-light ml-auto" onClick={showFilter}>
          <IconFilter className="mr-2" />
          Filter
        </button>
      </div>
      {isFilterActive && (
        <React.Fragment>
          <div className="bdpopup-backdrop"></div>
          <div className="bdpopup">
            <div className="bdpopup__content">
              <button
                className="btn btn-none bdpopup__close text-white"
                onClick={hideFilter}
              >
                <IconTimesThin className="close-icon" />
              </button>
              <div className="d-flex px-30 align-items-center justify-content-between pt-20">
                <p className="font-20 mb-0">Filters & Settings</p>
                <button className="btn btn-outline-light btn-sm mr-2">
                  <IconReset className="mr-2" />
                  Reset
                </button>
              </div>

              <div
                className="px-30 bdpopup__content__scroll"
                style={{ height: "calc(100% - 118px)" }}
              >
                <div className="hr-light"></div>
                <React.Fragment>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">APT From</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select APT from date"
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">APT To</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select APT from date"
                      ></input>
                    </div>
                  </div>
                </React.Fragment>
                <div className="hr-light"></div>
                <React.Fragment>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">
                      Pickup APT From
                    </label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select Pickup APT from date"
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">
                      Pickup APT From
                    </label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select Pickup APT from date"
                      ></input>
                    </div>
                  </div>
                </React.Fragment>
                <div className="hr-light"></div>
                <React.Fragment>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Load Type</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select..."
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Customer</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select..."
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Port</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select..."
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Consignee</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select..."
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Return</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select..."
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Driver</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select..."
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">
                      Created by
                    </label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select..."
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Status</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select..."
                      ></input>
                    </div>
                  </div>
                </React.Fragment>
                <div className="hr-light"></div>
                <React.Fragment>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">
                      Container Type
                    </label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select"
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">
                      Container Size
                    </label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select"
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">SSL</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select"
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">BOL/BKG</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select"
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">
                      Vessel Name
                    </label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select"
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Return</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select"
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Seal No</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select"
                      ></input>
                    </div>
                  </div>
                  <div className="form-group form-row">
                    <label className="col-md-5 col-form-label">Pickup #</label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Select"
                      ></input>
                    </div>
                  </div>
                </React.Fragment>
              </div>
              <div className="bdpopup__footer page-fluid">
                <button className="btn btn-primary">Apply</button>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default TopPage;
