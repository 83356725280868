import React, { useState } from "react";

import { IconDownload, IconSendEmail } from "../../../../common/icons/index";

const columns = [
  "Driver",
  "Total Loads",
  "Legs",
  "Pull Container",
  "Deliver Loads",
  "Drop Yard",
  "Return Container",
  "Total Driver Pay",
  "Total Miles",
  "Total Hours",
  "Total Days",
  "Loads/Day",
  "Rev/HR",
  "Revenue",
  "%",
  "Driver profitability",
  "Stand by Time",
  "Driver Effiency ",
];

const tableData = [];
for (let i = 0; i < 20; i++) {
  tableData.push({
    driver: "Eleanor Pena",
    totalLoads: 14,
  });
}

const options = [
  { value: "jessica.hanson@example.com", label: "jessica.hanson@example.com" },
  { value: "john.doe@example.com", label: "john.doe@example.com" },
  { value: "alex@example.com", label: "alex@example.com" },
];

const StaticTableContent = () => {
  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 334px)" }}
      >
        <table className="table table-card table-card--ls">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableData.map((tdata) => {
              return (
                <tr key={tdata.id}>
                  <td>
                    <div class="d-flex">
                      <h5 class="mb-0 align-self-center">
                        <a href="#" class="text-dark">
                          {tdata.driver}
                        </a>
                      </h5>
                    </div>
                  </td>
                  <td>{tdata.totalLoads}</td>
                  <td>14</td>
                  <td>14</td>
                  <td>14</td>
                  <td>14</td>
                  <td>14</td>
                  <td>14</td>
                  <td>$450.54</td>
                  <td>2860.75</td>
                  <td>43.23</td>
                  <td>23</td>
                  <td>23</td>
                  <td>$948.55</td>
                  <td>$28,417.85</td>
                  <td>34%</td>
                  <td>01.00	</td>
                  <td>34%</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default StaticTableContent;
