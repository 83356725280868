import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { CustomIconFileUpload } from '../../../common/customIcons';

import CustomSelect from '../../../common/CustomSelect';
import {
  IconPlus,
  IconDownload,
  IconUpload,
  IconPrinter,
  IconTrash,
  IconTimes,
  IconInfo,
  IconPen,
} from '../../../common/icons';

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];
class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddPaymentModal: false,
    };
  }
  handleShowAddPaymentModal = (event) => {
    this.setState({ showAddPaymentModal: true });
  };
  handleHidePaymentModal = (event) => {
    this.setState({ showAddPaymentModal: false });
  };
  render() {
    return (
      <React.Fragment>
        <div className="tab">
          <div className="card p-20 mb-10 d-flex flex-row align-items-center justify-content-between">
            <ul className="nav nav-compact" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-bs-toggle="tab"
                  href="#all-drivers"
                  role="tab"
                >
                  Main Customer
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#dispatched"
                  role="tab"
                >
                  Sub Customer
                </a>
              </li>
            </ul>
            <button
              className="ml-auto btn btn-primary"
              onClick={this.handleShowAddPaymentModal}
            >
              <IconPlus className="mr-2" />
              Add Payment
            </button>
          </div>

          <div className="table mb-20">
            <table className="table table-card table-card--ls mb-10">
              <thead>
                <tr>
                  <th>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="sr_no"
                    />
                  </th>
                  <th>Amount</th>
                  <th>Note</th>
                  <th>Check</th>
                  <th>File Name</th>
                  <th>Doc Type</th>
                  <th>Date & Time</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="sr_no"
                    />
                  </td>
                  <td>$150</td>
                  <td>Note description</td>
                  <td>2255555</td>
                  <td>
                    <a
                      href="javascript:void(0)"
                      className="text-primary font-14"
                    >
                      PortDocsScan003.pdf
                    </a>
                  </td>
                  <td>
                    <span className="badge badge-sm badge-light">
                      Delivery Order
                    </span>
                  </td>
                  <td>
                    <div className="font-14">02 July, 2021</div>
                    <div className="text-muted">08:50pm</div>
                  </td>
                  <td className="text-right">
                    <div className="d-flex justify-content-end ">
                      <button className="btn btn-link text-muted">
                        <IconPen />
                      </button>
                      <button className="btn btn-link text-dark">
                        <IconDownload />
                      </button>
                      <button className="btn btn-link text-dark">
                        <IconPrinter />
                      </button>
                      <button className="btn btn-link text-muted">
                        <IconTrash />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="sr_no"
                    />
                  </td>
                  <td>$150</td>
                  <td>Note description</td>
                  <td>2255555</td>
                  <td>
                    <a
                      href="javascript:void(0)"
                      className="text-primary font-14"
                    >
                      PortDocsScan003.pdf
                    </a>
                  </td>
                  <td>
                    <span className="badge badge-sm badge-light">
                      Delivery Order
                    </span>
                  </td>
                  <td>
                    <div className="font-14">02 July, 2021</div>
                    <div className="text-muted">08:50pm</div>
                  </td>
                  <td className="text-right">
                    <div className="d-flex justify-content-end ">
                      <button className="btn btn-link text-muted">
                        <IconPen />
                      </button>
                      <button className="btn btn-link text-dark">
                        <IconDownload />
                      </button>
                      <button className="btn btn-link text-dark">
                        <IconPrinter />
                      </button>
                      <button className="btn btn-link text-muted">
                        <IconTrash />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="sr_no"
                    />
                  </td>
                  <td>$150</td>
                  <td>Note description</td>
                  <td>2255555</td>
                  <td>
                    <a
                      href="javascript:void(0)"
                      className="text-primary font-14"
                    >
                      PortDocsScan003.pdf
                    </a>
                  </td>
                  <td>
                    <span className="badge badge-sm badge-light">
                      Delivery Order
                    </span>
                  </td>
                  <td>
                    <div className="font-14">02 July, 2021</div>
                    <div className="text-muted">08:50pm</div>
                  </td>
                  <td className="text-right">
                    <div className="d-flex justify-content-end ">
                      <button className="btn btn-link text-muted">
                        <IconPen />
                      </button>
                      <button className="btn btn-link text-dark">
                        <IconDownload />
                      </button>
                      <button className="btn btn-link text-dark">
                        <IconPrinter />
                      </button>
                      <button className="btn btn-link text-muted">
                        <IconTrash />
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="sr_no"
                    />
                  </td>
                  <td>$150</td>
                  <td>Note description</td>
                  <td>2255555</td>
                  <td>
                    <a
                      href="javascript:void(0)"
                      className="text-primary font-14"
                    >
                      PortDocsScan003.pdf
                    </a>
                  </td>
                  <td>
                    <span className="badge badge-sm badge-light">
                      Delivery Order
                    </span>
                  </td>
                  <td>
                    <div className="font-14">02 July, 2021</div>
                    <div className="text-muted">08:50pm</div>
                  </td>
                  <td className="text-right">
                    <div className="d-flex justify-content-end ">
                      <button className="btn btn-link text-muted">
                        <IconPen />
                      </button>
                      <button className="btn btn-link text-dark">
                        <IconDownload />
                      </button>
                      <button className="btn btn-link text-dark">
                        <IconPrinter />
                      </button>
                      <button className="btn btn-link text-muted">
                        <IconTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Modal show={this.state.showAddPaymentModal} centered animation={false}>
          <Modal.Header>
            <Modal.Title>Add Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className="font-14">Payment Info</h5>
            <div className="form-row-md mb-20">
              <div className="col-md-4 form-group">
                <label>
                  <span className="text-danger">*</span> Amount
                </label>
                <input type="text" className="form-control" placeholder="$" />
              </div>
              <div className="col-md-4">
                <label>
                  <span className="text-danger">*</span> Amount
                </label>
                <input type="text" className="form-control" placeholder="$" />
              </div>
              <div className="col-md-4">
                <label>
                  <span className="text-danger">*</span> Amount
                </label>
                <input type="text" className="form-control" placeholder="$" />
              </div>
            </div>
            <h6>Document upload</h6>
            <div className="dropzone mb-20">
              <div className="dz-message needsclick" tabindex="0">
                <input
                  multiple=""
                  type="file"
                  autocomplete="off"
                  tabindex="-1"
                  style={{ display: 'none' }}
                />
                <div className="d-flex align-items-center justify-content-around">
                  <CustomIconFileUpload />
                  <div>
                    <div className="title mb-10">
                      Drop files here or click to upload.
                    </div>
                    <button className="btn btn-primary btn-sm">
                      Choose from your device...
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="dropzone-fileupload dropzone-fileupload--uploading d-flex">
              <IconUpload className="mr-20" />
              <div className="w-100">
                <div className="font-medium d-flex justify-content-between w-100 mb-1">
                  <div>PortDocsScan003.pdf</div>
                  <div className="ml-auto">25%</div>
                </div>
                <div className="progress">
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: '25%' }}
                  ></div>
                </div>
              </div>
              <button className="btn btn-white btn-circle btn-xs ml-3 shadow-none">
                <IconTimes className="" />
              </button>
            </div>
            {/* After File is Uploaded sue the code block below */}
            <div className="dropzone-fileupload d-flex">
              <button className="btn btn-warning btn-circle btn-xs mr-15">
                <IconInfo />
              </button>
              <div className="w-100 d-flex">
                <div>
                  <div className="font-medium ">PortDocsScan003.pdf</div>
                  <div className="text-muted">256 kb</div>
                </div>
                <div className="w-150 ml-auto">
                  <CustomSelect
                    name={'name'}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select doc type"
                  />
                </div>
              </div>
              <button className="btn btn-link btn-circle btn-xs ml-3">
                <IconTrash />
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={this.handleHidePaymentModal}
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              disabled
              onClick={this.handleHidePaymentModal}
            >
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Payments;
