import React, { useState } from "react";
import { IconPlus, IconCalendar } from "../../common/icons";
import { Modal } from "react-bootstrap";
import CustomSelect from "../../common/CustomSelect";
import TabComponent from "../../common/tabComponent";
import UploadDocument from "../../common/uploadDocument";

const tabItems = [
  {
    id: 1,
    title: "Chassis",
    path: "/safety/chassis",
  },
  {
    id: 2,
    title: "Trucks",
    path: "/safety/trucks",
  },
  {
    id: 3,
    title: "Trailers",
    path: "/safety/trailers",
  },
  {
    id: 4,
    title: "Drivers",
    path: "/safety/drivers",
  },
];
const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];
const BillingTabs = ({ activeTab }) => {
  const [tabActive, setTabActive] = useState(activeTab);
  const [showAddModal, setShowAddModal] = useState(false);

  const handleShowAddModal = (event) => {
    setShowAddModal(true);
  };

  const handleHideAddModal = (event) => {
    setShowAddModal(false);
  };

  return (
    <React.Fragment>
      {/*  Sub Header Tabs*/}
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <div className="d-flex flex-row align-items-center">
            <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
              {tabItems.map(({ id, title, path }) => (
                <TabComponent
                  key={title}
                  title={title}
                  path={path}
                  onItemClicked={() => setTabActive(id)}
                  isActive={tabActive === id}
                />
              ))}
            </ul>
          </div>
          <button
            className="ml-auto btn btn-success"
            onClick={handleShowAddModal}
          >
            <IconPlus className="mr-3" />
            Add New Load
          </button>
        </div>
      </div>

      <Modal show={showAddModal} centered animation={false} size="xl">
        <Modal.Header>
          <Modal.Title>Add New Chassis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-wrapper">
            <form>
              <div className="row mb-10">
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Chassis #
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Chassis Number.."
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Year
                  </label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select Year"
                    className="w-150"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Registration Date
                  </label>
                  <div className="input-wrapper w-150">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Select Date"
                    />
                    <div className="input-icon">
                      <IconCalendar />
                    </div>
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Chassis Owner
                  </label>
                  <div className="input-wrapper">
                    <CustomSelect
                      name={"name"}
                      options={options}
                      value={1}
                      isMulti={true}
                      isClearable={true}
                      size="small"
                      placement="right"
                      placeholder="Select Owner"
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> AID
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter AID"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> ITD
                  </label>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter ITD"
                    />
                  </div>
                </div>

                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Chassis Type
                  </label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select Type"
                    className="w-100"
                  />
                </div>

                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> License Plate #
                  </label>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter License Plate #"
                    />
                  </div>
                </div>

                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> VIN
                  </label>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter VIN"
                    />
                  </div>
                </div>

                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Chassis Size
                  </label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select Size"
                    className="w-100"
                  />
                </div>

                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> License State
                  </label>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter VIN"
                    />
                  </div>
                </div>

                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Insurance
                  </label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select Insurance"
                    className="w-150"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Model
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Model"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Inspection Date
                  </label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select Date"
                    className="w-150"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> GPS #
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter GPS"
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="col-form-label">
                    <span className="text-danger">*</span> Preventative
                    Maintenance
                  </label>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select Date"
                    className="w-150"
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-form-label">Upload Document</label>
                <UploadDocument />
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" onClick={handleHideAddModal}>
            Cancel
          </button>
          <button className="btn btn-success" onClick={handleHideAddModal}>
            Add New Chassis
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default BillingTabs;
