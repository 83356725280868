import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IconPlay, IconPending, IconCheck } from '../../../../common/icons';
import BaseReport from './baseReport';
import ReportName from './ReportName';
import Filter from './Filter';
import ResultColumn from './ResultColumn';
import Schedule from './Schedule';

const AddReportModal = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;

  const [isLoadTabActive, setisLoadTabActive] = useState(1);
  const handleLoadTabActive = (i) => {
    setisLoadTabActive(isLoadTabActive === i ? null : i);
  };

  return (
    <Modal show={showModal} centered animation={false} size="lg">
      <Modal.Header className="py-20 px-30">
        <Modal.Title>Create Custom Report</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 nav-border-bottom">
        <div className="card mb-0">
          {/*  Reports Tabs */}
          <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom">
            <li className="nav-item">
              <a
                className={`nav-link ${isLoadTabActive === 1 ? 'active' : ''}`}
                href="javascript:void(0)"
                onClick={() => handleLoadTabActive(1)}
              >
                <div className="nav-link__text">
                  <div className="d-flex-align-item-center">
                    {isLoadTabActive === 2 ||
                    isLoadTabActive === 3 ||
                    isLoadTabActive === 4 ? (
                      <IconCheck className="mr-1" />
                    ) : (
                      <IconPlay className="mr-1" />
                    )}
                    Base Your Report
                  </div>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${isLoadTabActive === 2 ? 'active' : ''}`}
                href="javascript:void(0)"
                onClick={() => handleLoadTabActive(2)}
              >
                <div className="nav-link__text">
                  <div className="d-flex-align-item-center">
                    {isLoadTabActive === 2 ? (
                      <IconPlay className="mr-2" />
                    ) : isLoadTabActive === 1 ? (
                      <IconPending className="mr-1" />
                    ) : (
                      <IconCheck className="mr-1" />
                    )}
                    Report Name
                  </div>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${isLoadTabActive === 3 ? 'active' : ''}`}
                href="javascript:void(0)"
                onClick={() => handleLoadTabActive(3)}
              >
                <div className="nav-link__text">
                  <div className="d-flex-align-item-center">
                    {isLoadTabActive === 3 ? (
                      <IconPlay className="mr-2" />
                    ) : isLoadTabActive === 1 || isLoadTabActive === 2 ? (
                      <IconPending className="mr-1" />
                    ) : (
                      <IconCheck className="mr-1" />
                    )}
                    Filters
                  </div>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${isLoadTabActive === 4 ? 'active' : ''}`}
                href="javascript:void(0)"
                onClick={() => handleLoadTabActive(4)}
              >
                <div className="nav-link__text">
                  <div className="d-flex-align-item-center">
                    {isLoadTabActive === 4 ? (
                      <IconPlay className="mr-2" />
                    ) : isLoadTabActive === 1 || isLoadTabActive === 2 ? (
                      <IconPending className="mr-1" />
                    ) : (
                      <IconCheck className="mr-1" />
                    )}
                    Result Columns
                  </div>
                </div>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${isLoadTabActive === 5 ? 'active' : ''}`}
                href="javascript:void(0)"
                onClick={() => handleLoadTabActive(5)}
              >
                <div className="nav-link__text">
                  <div className="d-flex-align-item-center">
                    {isLoadTabActive === 5 ? (
                      <IconPlay className="mr-2" />
                    ) : isLoadTabActive === 1 ||
                      isLoadTabActive === 2 ||
                      isLoadTabActive === 3 ? (
                      <IconPending className="mr-1" />
                    ) : (
                      <IconPending className="mr-1" />
                    )}
                    Schedule
                  </div>
                </div>
              </a>
            </li>
          </ul>
          {/* Tab Contents */}
          {isLoadTabActive === 1 && <BaseReport />}
          {isLoadTabActive === 2 && <ReportName />}
          {isLoadTabActive === 3 && <Filter />}
          {isLoadTabActive === 4 && <ResultColumn />}
          {isLoadTabActive === 5 && <Schedule />}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {isLoadTabActive === 1 && (
          <div className="">
            <button className="btn btn-close" onClick={onCancel}>
              Cancel
            </button>
            {/* <button
              className="btn btn-primary"
              onClick={() => handleLoadTabActive(2)}
            >
              Next
            </button> */}
          </div>
        )}

        {isLoadTabActive === 2 && (
          <div className="">
            <button className="btn btn-close mr-30" onClick={onCancel}>
              Cancel
            </button>
            <button className="btn btn-outline-light mr-10" onClick={onCancel}>
              Back
            </button>
            <button
              className="btn btn-gray-100 px-40 text-gray-400"
              onClick={() => handleLoadTabActive(3)}
            >
              Next
            </button>
          </div>
        )}

        {isLoadTabActive === 3 && (
          <div className="">
            <button className="btn btn-close mr-30" onClick={onCancel}>
              Cancel
            </button>
            <button className="btn btn-outline-light mr-10" onClick={onCancel}>
              Back
            </button>
            <button
              className="btn  btn-gray-100 px-40 text-gray-400"
              onClick={() => handleLoadTabActive(4)}
            >
              Next
            </button>
          </div>
        )}

        {isLoadTabActive === 4 && (
          <div className="">
            <button className="btn btn-close mr-30" onClick={onCancel}>
              Cancel
            </button>
            <button className="btn btn-outline-light mr-10" onClick={onCancel}>
              Back
            </button>
            <button
              className="btn btn-gray-100 px-40 text-gray-400"
              onClick={() => handleLoadTabActive(5)}
            >
              Next
            </button>
          </div>
        )}

        {isLoadTabActive === 5 && (
          <div className="">
            <button className="btn btn-close mr-30" onClick={onCancel}>
              Cancel
            </button>
            <button className="btn btn-outline-light mr-10" onClick={onCancel}>
              Back
            </button>
            <button
              className="btn btn-gray-100 px-40 text-gray-400"
              onClick={() => handleLoadTabActive(5)}
            >
              Add Report
            </button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AddReportModal;
