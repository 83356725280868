import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import PlansList from './PlansList';

const Testimonial = (props) => {
  const { showAddModal = true, onCancel = () => { } } = props;

  return (
    <Modal show={showAddModal} centered animation={false}>
      <Modal.Header className="d-flex p-40 justify-content-center">
        <Modal.Title>Choose a subscription</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-60 pt-0 pb-50">
        <div className="subscription-plan">
          <PlansList />
          <div className="title font-14 mb-20 font-weight-500 d-flex justify-content-center align-items-center">
            Loved by 100+ organizations
          </div>
          <div className="ncard rounded-5 bg-gray-50 p-10">
            <div className="font-12 font-weight-normal pb-10">
              “Amazing service! Claire helped me to reduce the shipping price a
              little and shipped it immediately after purchasing. The amethyst
              cave I got is a beauty and I c...”
            </div>
            <div className="font-12 font-weight-normal pb-10 text-gray-500">
              Body Czeladka
            </div>
            <div className="font-12 font-weight-normal pb-10 text-gray-500">
              Director at BigPort
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <a href="/trackos/payment-options">
          <button className=" btn btn-outline-light">
            Back
          </button>
        </a>
      </Modal.Footer>
    </Modal>
  );
};

export default Testimonial;
