import React, { useEffect, Component } from 'react';
import CustomerTabs from '../common-elements/CustomerTabs';
import Pagination from '../../customerService/common-elements/Pagination';
import StaticTableContent from '../../customerService/tenderedLoads/StaticTableContent';
import LoadCard from './loadCard';

const tabItems = [
  {
    id: 1,
    title: 'APM: 1',
  },
  {
    id: 2,
    title: 'APM: 2',
  },
  {
    id: 3,
    title: 'APM: 3',
  },
  {
    id: 4,
    title: 'Everport: 1',
  },
  {
    id: 5,
    title: 'Everport: 2',
  },
  {
    id: 6,
    title: 'Everport: 3',
  },
];

class TenderedLoads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTabActive: false,
      isCardActive: false,
      isPopupOpen: false,
      openPopup: false,
      hidePopup: false,
      issubCardItem: false,
      openLoad: false,
      isPopDrop: false,
      isTrackCard: false,
      isSaveOptions: false,
      isFilterTabActive: false,
      isCardSubItemActive: false,
    };
  }

  handleIsCardSubItemActive = (e, i) => {
    e.stopPropagation();
    this.setState({
      isCardSubItemActive: this.state.isCardSubItemActive === i ? null : i,
    });
  };

  handleIsFilterTab = (i) => {
    this.setState({
      isFilterTabActive: this.state.isFilterTabActive === i ? null : i,
    });
  };

  handleIsPopDrop = (event) => {
    this.setState({ isPopDrop: !this.state.isPopDrop });
  };
  openLoad = (event) => {
    document.body.classList.add('has-subheader');
    this.setState({ openLoad: true });
  };

  hideLoad = (event) => {
    document.body.classList.remove('has-subheader');
    this.setState({ openLoad: false });
  };

  openPopup = (event) => {
    document.body.classList.add('bdpopup-open');
    this.setState({ isPopupOpen: true });
  };

  hidePopup = (event) => {
    document.body.classList.remove('bdpopup-open');
    this.setState({ isPopupOpen: false });
  };

  handleTabActive = (e, i) => {
    this.setState({
      isTabActive: this.state.isTabActive === i ? null : i,
    });
  };
  handleCardActive = (e, i) => {
    e.stopPropagation();
    this.setState({
      isCardActive: this.state.isCardActive === i ? null : i,
    });
  };
  handleSubCardItem() {
    this.setState({ isSubCardItem: !this.state.isSubCardItem });
  }
  handleIsSaveOptions = (event) => {
    this.setState({ isSaveOptions: !this.state.isSaveOptions });
  };

  render() {
    return (
      <React.Fragment>
        <div className="position-relative subheader-wrapper">
          <CustomerTabs activeTab={5} />
          <div className="page-fluid">
            <div className="top-page">
              <div className="pb-15 d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <h4 className="font-20 mb-0">5 Tendered Loads</h4>
                </div>
                <Pagination className="ml-auto" />
              </div>
              <div>
                <StaticTableContent />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TenderedLoads;
