import React, { useState } from "react";
import {
  IconAngleArrowDown,
  IconAngleArrowRight,
} from "../../../../common/icons/index";
import Pagination from "../../../../common/pagination";

const TopPage = () => {
  return (
    <div className="top-page">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h4 className="font-20 mb-0">53 Customers</h4>
        <div className="ml-auto">
          <Pagination />
        </div>
      </div>
    </div>
  );
};

export default TopPage;
