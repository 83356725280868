import React, { useState } from "react";
import ContainerTabs from "../ContainerTabs";
import Pagination from "../../../../common/pagination";
import StaticTableContent from "./elements/StaticTableContent";
import SettingsTab from "../../common-elements/SettingsTabs";
import { IconDownload, IconPlus } from "../../../../common/icons";

const ContainerFreeDay = () => {
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <SettingsTab
            activeTab={5}
            button={
              <div className="d-flex">
                <button className="mr-2 btn btn-outline-light ">
                  <IconDownload className="mr-2" />
                  Downlaod CVS
                </button>
                <button className=" btn btn-success ">
                  <IconPlus className="mr-2" />
                  Add Customers
                </button>
              </div>
            }
          />
        </div>
      </div>
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-15 d-flex align-items-center justify-content-between">
            <ContainerTabs activeTab={"Free Day"} />
            <Pagination />
          </div>
        </div>

        {/* Static Table Content */}
        <StaticTableContent />
      </div>
    </div>
  );
};

export default ContainerFreeDay;
