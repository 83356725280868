import React, { useState } from 'react';
import { IconPlus } from '../../../common/icons';
import Pagination from '../common-elements/Pagination';
import StaticTableContent from './elements/StaticTableContent';
import AddFleetModal from './elements/AddFleetModal';
import SubHeader from '../subheader';

function TruckOwner() {
  const [FleetshowModal, setFleetShowModal] = useState(false);
  const handleFleetShowModal = (event) => {
    setFleetShowModal(true);
  };

  const handleFleetHideModal = (event) => {
    setFleetShowModal(false);
  };
  return (
    <React.Fragment>
      <div className="position-relative subheader-wrapper">
        <div className="subheader">
          <div className="subheader__inner d-flex align-items-center py-0">
            <SubHeader
              activeTab={3}
              button={
                <div className="d-flex">
                  <button
                    className="ml-auto btn btn-success"
                    onClick={handleFleetShowModal}
                  >
                    <IconPlus className="mr-2" />
                    Add Truck Owner
                  </button>
                </div>
              }
            />
          </div>
        </div>
        <div className="page-fluid">
          <div className="pb-15 d-flex align-items-center justify-content-between">
            <h4 className="font-20 mb-0">435 Fleet Owners</h4>
            <Pagination />
          </div>
          <StaticTableContent />
        </div>
      </div>

      {FleetshowModal && (
        <AddFleetModal
          onCancel={handleFleetHideModal}
          onSubmit={handleFleetHideModal}
        />
      )}
    </React.Fragment>
  );
}

export default TruckOwner;
