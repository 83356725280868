import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { CustomIconSpreadSheet } from "../../../common/customIcons";
import {
  IconAngleArrowRight,
  IconColumn,
  IconDownload,
  IconFilter,
  IconPlus,
  IconSendEmail,
  IconTrash,
} from "../../../common/icons";
import Pagination from "../../../common/pagination";
import TrackOsImportAvailabilityFilterRow from "./FilterRow";
import TrackOsImportAvailabilityCards from "./ImportAvailabilityCards";
import TrackOsImportAvailabilityTable from "./ImportAvailabilityTable";

const TrackOsImportAvailability = ({}) => {
  const [showAddModal, setShowAddModal] = useState(false);

  const handleShowAddModal = (event) => {
    setShowAddModal(true);
  };
  const handleHideAddModal = (event) => {
    setShowAddModal(false);
  };
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <div className="d-flex flex-row align-items-center justify-content-between w-100">
            <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
              <li className="nav-item">
                <a href="#" className={`nav-link`}>
                  <div className="">Import Availability</div>
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className={`nav-link`}>
                  <div className="">Archive</div>
                </a>
              </li>
            </ul>
            <div className="ml-auto">
              <button className="btn btn-outline-light mr-2">
                <CustomIconSpreadSheet />
              </button>
              <button className="btn btn-outline-light mr-2">
                <IconDownload />
              </button>
              <button className="btn btn-outline-light mr-2">
                <IconSendEmail />
              </button>
              <button className="btn btn-success" onClick={handleShowAddModal}>
                <IconPlus className="mr-2" />
                Add New Container
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-15 d-flex align-items-center">
            <h4 className="mb-0">757 Containers</h4>
            <Pagination className={"ml-auto"} />
          </div>
          {/*Cards */}
          <TrackOsImportAvailabilityCards />
          {/* Filter Row */}
          <TrackOsImportAvailabilityFilterRow />
        </div>
        <TrackOsImportAvailabilityTable />
      </div>

      <Modal show={showAddModal} centered animation={false} size="sm">
        <Modal.Header>
          <Modal.Title>Add New Container's</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-wrapper">
            <div className="form-group">
              <label>Enter up to 100 containers</label>
              <textarea
                className="form-control"
                placeholder="e.g. Container 1, Container 2&#10;or&#10;Container 1&#10;Container 2&#10;Container 3"
                rows="8"
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" onClick={handleHideAddModal}>
            Cancel
          </button>
          <button className="btn btn-primary">Add Containers</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TrackOsImportAvailability;
