import React from "react";
import ReactApexChart from "react-apexcharts";

// const series = [70];

const options = {
  chart: {
    type: "radialBar",
    sparkline: {
      enabled: true,
    },
  },
  colors: ["#367BF6"],
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "50%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: false,
      },
    },
  },
};

const RadialChart = (props) => {
  const { percent = "" } = props;
  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={[percent]}
        type="radialBar"
        width="50"
        height="50"
      />
    </div>
  );
};

export default RadialChart;
