import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { CustomIconSpreadSheet } from "../../../common/customIcons";
import { IconDownload, IconPlus, IconSendEmail } from "../../../common/icons";
import Pagination from "../../../common/pagination";
import TabComponent from "../../../common/tabComponent";
import EmptyReturnTab from "./Components/EmptyReturnTab";
import TrackOsEmptyReturnTable from "./EmptyReturnTable";
import TrackOsEmptyReturnFilterRow from "./FilterRow";

const tabItems = [
  {
    id: 1,
    title: "Active",
    path: "/trackos/empty-return",
  },
  {
    id: 2,
    title: "Archived",
    path: "/trackos/empty-return",
  },
  {
    id: 3,
    title: "Locations",
    path: "/trackos/empty-return-locations",
  },
]

const TrackOsEmptyReturn = ({ id }) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [tabActive, setTabActive] = useState(1);

  const handleShowAddModal = () => {
    setShowAddModal(true);
  };
  const handleHideAddModal = () => {
    setShowAddModal(false);
  };
  return (
    <div className="position-relative subheader-wrapper">
      <EmptyReturnTab activeTab={1} buttons={
        <button className="btn btn-success" onClick={handleShowAddModal}>
          <IconPlus className="mr-2" />
          Add New Container
        </button>
      } />
      <div className="page-fluid">
        <div className="top-page">
          <div className="pb-15 d-flex align-items-center">
            <h4 className="mb-0">253 Terminals</h4>
            <Pagination className={"ml-auto"} />
          </div>
          <TrackOsEmptyReturnFilterRow size={"small"} />
        </div>
        <TrackOsEmptyReturnTable />
      </div>

      <Modal show={showAddModal} centered animation={false} size="sm">
        <Modal.Header>
          <Modal.Title>Add New Container's</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-wrapper">
            <div className="form-group">
              <label>Enter up to 100 containers</label>
              <textarea
                className="form-control"
                placeholder="e.g. Container 1, Container 2&#10;or&#10;Container 1&#10;Container 2&#10;Container 3"
                rows="8"
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-close" onClick={handleHideAddModal}>
            Cancel
          </button>
          <button className="btn btn-primary">Add Containers</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TrackOsEmptyReturn;
