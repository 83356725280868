import React from 'react';
import { Modal } from 'react-bootstrap';

const AddFleetModal = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;
  return (
    <Modal show={showModal} centered animation={false} size="sm">
      <Modal.Header>
        <Modal.Title>Add Fleet Owner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-wrapper">
          <form>
            <div className="row ">
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Company name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter company name"
                />
              </div>
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter address"
                />
              </div>
            </div>
            <div className=" form-row ">
              <div className="form-group col-md-8">
                <label className="col-form-label">
                  <span className="text-danger">*</span> City
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter City"
                />
              </div>
              <div className="form-group col-md-2">
                <label className="col-form-label">
                  <span className="text-danger">*</span> State
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter"
                />
              </div>
              <div className="form-group col-md-2">
                <label className="col-form-label">
                  <span className="text-danger">*</span> ZIP
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter"
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-12 ">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Country
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Country"
                />
              </div>
            </div>
            <div class="border mt-2 mb-2"></div>
            <div className="row ">
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Main Contact name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Main Contact name"
                />
              </div>
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Secondary Contact name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Secondary Contact name"
                />
              </div>
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Phone
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Phone"
                />
              </div>
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Email"
                />
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-success" onClick={onSubmit}>
          Add Fleet Owner
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddFleetModal;
