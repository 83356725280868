import React, { useState, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { CustomIconAlert } from '../../../../common/customIcons';
import {
  IconPen,
  IconTrash,
  IconTimes,
  IconCalendar,
} from '../../../../common/icons/index';

const columns = [
  'Load #',
  'Driver',
  'Truck',
  'Owner',
  'Load Status',
  'From',
  'To',
  'Date & Time ',
  'Amount',
  '',
  '',
];

const data = [
  {
    id: 1,
    load: 'POP2_M100520',
    status: 'Unapproved',
    date: '',
    time: '',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    truck: '1469',
    owner: 'DB Trucks',
    amount: '',
    loadStatus: 'Delivery - Detention',
    from: '2715 Ash Dr. San Jose, South Dakota 83475',
    to: '1901 Thornridge Cir. Shiloh, Hawaii 81063',
  },
  {
    id: 2,
    load: 'POP2_M100520',
    status: 'Unapproved',
    date: '',
    time: '',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    truck: '1469',
    owner: 'DB Trucks',
    amount: '',
    loadStatus: 'Delivery - Detention',
    from: '2715 Ash Dr. San Jose, South Dakota 83475',
    to: '1901 Thornridge Cir. Shiloh, Hawaii 81063',
  },
  {
    id: 3,
    load: 'POP2_M100520',
    status: 'Approved',
    date: '',
    time: '',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    truck: '1469',
    owner: 'DB Trucks',
    amount: '',
    loadStatus: 'Delivery - Detention',
    from: '2715 Ash Dr. San Jose, South Dakota 83475',
    to: '1901 Thornridge Cir. Shiloh, Hawaii 81063',
  },
  {
    id: 4,
    load: 'POP2_M100520',
    status: 'Approved',
    date: '',
    time: '',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    truck: '1469',
    owner: 'DB Trucks',
    amount: '',
    loadStatus: 'Delivery - Detention',
    from: '2715 Ash Dr. San Jose, South Dakota 83475',
    to: '1901 Thornridge Cir. Shiloh, Hawaii 81063',
  },
  {
    id: 5,
    load: 'POP2_M100520',
    status: 'Approved',
    date: '',
    time: '',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    truck: '1469',
    owner: 'DB Trucks',
    amount: '',
    loadStatus: 'Delivery - Detention',
    from: '2715 Ash Dr. San Jose, South Dakota 83475',
    to: '1901 Thornridge Cir. Shiloh, Hawaii 81063',
  },
  {
    id: 6,
    load: 'POP2_M100520',
    status: 'Approved',
    date: '06/11',
    time: '07:40 am',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    truck: '1469',
    owner: 'DB Trucks',
    amount: 0.0,
    loadStatus: 'Delivery - Detention',
    from: '2715 Ash Dr. San Jose, South Dakota 83475',
    to: '1901 Thornridge Cir. Shiloh, Hawaii 81063',
  },
  {
    id: 7,
    load: 'POP2_M100520',
    status: 'Approved',
    date: '06/11',
    time: '07:40 am',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    truck: '1469',
    owner: 'DB Trucks',
    amount: 0.0,
    loadStatus: 'Delivery - Detention',
    from: '2715 Ash Dr. San Jose, South Dakota 83475',
    to: '1901 Thornridge Cir. Shiloh, Hawaii 81063',
  },
  {
    id: 11,
    load: 'POP2_M100520',
    status: 'Approved',
    date: '06/11',
    time: '07:40 am',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    truck: '1469',
    owner: 'DB Trucks',
    amount: 0.0,
    loadStatus: 'Delivery - Detention',
    from: '2715 Ash Dr. San Jose, South Dakota 83475',
    to: '1901 Thornridge Cir. Shiloh, Hawaii 81063',
  },
  {
    id: 12,
    load: 'POP2_M100520',
    status: 'Approved',
    date: '06/11',
    time: '07:40 am',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    truck: '1469',
    owner: 'DB Trucks',
    amount: 0.0,
    loadStatus: 'Delivery - Detention',
    from: '2715 Ash Dr. San Jose, South Dakota 83475',
    to: '1901 Thornridge Cir. Shiloh, Hawaii 81063',
  },
  {
    id: 8,
    load: 'POP2_M100520',
    status: 'Finalized',
    date: '06/11',
    time: '07:40 am',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    truck: '1469',
    owner: 'DB Trucks',
    amount: 0.0,
    loadStatus: 'Delivery - Detention',
    from: '2715 Ash Dr. San Jose, South Dakota 83475',
    to: '1901 Thornridge Cir. Shiloh, Hawaii 81063',
  },
  {
    id: 9,
    load: 'POP2_M100520',
    status: 'Finalized',
    date: '06/11',
    time: '07:40 am',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    truck: '1469',
    owner: 'DB Trucks',
    amount: 0.0,
    loadStatus: 'Delivery - Detention',
    from: '2715 Ash Dr. San Jose, South Dakota 83475',
    to: '1901 Thornridge Cir. Shiloh, Hawaii 81063',
  },
  {
    id: 10,
    load: 'POP2_M100520',
    status: 'Finalized',
    date: '06/11',
    time: '07:40 am',
    driver: 'Robert Fox',
    img: 'https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg',
    truck: '1469',
    owner: 'DB Trucks',
    amount: 0.0,
    loadStatus: 'Delivery - Detention',
    from: '2715 Ash Dr. San Jose, South Dakota 83475',
    to: '1901 Thornridge Cir. Shiloh, Hawaii 81063',
  },
];

const StaticTableContent = () => {
  const [showModal, setShowModal] = useState(false);
  const [isStatusHovered, setIsStatusHovered] = useState(null);
  const [isStatusChanged, setIsStatusChanged] = useState(null);

  const onMouseEnterHandler = (i) => {
    setIsStatusHovered(isStatusHovered === i ? null : i);
  };

  const onMouseLeaveHandler = (i) => {
    setIsStatusHovered(isStatusChanged === i && null);
  };

  const onStatusChanged = (i) => {
    setIsStatusChanged(isStatusChanged === i ? null : i);
    console.log('Approve Status changed Clicked');
  };

  const handleShowModal = (event) => {
    setShowModal(true);
  };

  const handleHideModal = (event) => {
    setShowModal(false);
  };

  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{ height: 'calc(100vh - 408px)' }}
      >
        <table className="table table-card table-card--ls">
          <thead>
            <tr>
              <th>
                <input
                  className="form-check-input rounded-3 border-secondary"
                  type="checkbox"
                  id="sr_no"
                />
              </th>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((datum) => {
              return (
                <tr key={datum.id}>
                  <td>
                    <input
                      className="form-check-input rounded-3 border-secondary"
                      type="checkbox"
                      id="sr_no"
                    />
                  </td>
                  <td>
                    <a
                      href="javascript:void(0)"
                      className="text-primary font-weight-500"
                    >
                      {datum.load}
                    </a>
                  </td>
                  <td>
                    <div className="d-flex">
                      <div className="mr-2 align-self-center">
                        <img
                          src={datum.img}
                          className="avatar-xs rounded-circle"
                          width="30"
                          alt=""
                        />
                      </div>
                      <h5 className="mb-0 align-self-center">
                        <a href="#" className="text-dark">
                          {datum.driver}
                        </a>
                      </h5>
                    </div>
                  </td>
                  <td>{datum.truck}</td>
                  <td>{datum.owner}</td>
                  <td>
                    <div className="badge badge-sm badge-light">
                      {datum.loadStatus}
                    </div>
                  </td>
                  <td>{datum.from}</td>
                  <td>{datum.to}</td>
                  <td style={{ width: '8%' }}>
                    {datum.date === '' && datum.time === '' ? (
                      <div className="input-wrapper w-150">
                        <input
                          className="form-control text-dark bg-gray-50"
                          placeholder="06/11 • 08:50pm"
                        />
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex">
                        {datum.date} - {datum.time}
                      </div>
                    )}
                  </td>

                  <td style={{ width: '6%' }}>
                    {datum.amount === '' ? (
                      <input
                        className="form-control text-dark text-right bg-gray-50"
                        placeholder="$50.00"
                      />
                    ) : (
                      <div className="text-right">${datum.amount}</div>
                    )}
                  </td>
                  <td style={{ width: '5%' }}>
                    <div className="text-center">
                      {datum.status == 'Approved' ? (
                        <>
                          {isStatusHovered === datum.id ? (
                            <button
                              className="btn btn-sm btn-danger font-10"
                              onClick={() => onStatusChanged(datum.id)}
                            >
                              UnApprove
                            </button>
                          ) : (
                            <span
                              className={`badge ${
                                isStatusChanged === datum.id
                                  ? 'badge-danger-light'
                                  : 'badge-accent-100'
                              }`}
                              onMouseEnter={() => onMouseEnterHandler(datum.id)}
                              onMouseLeave={() => onMouseLeaveHandler(datum.id)}
                            >
                              {isStatusChanged === datum.id
                                ? 'Unapproved'
                                : datum.status}
                            </span>
                          )}
                        </>
                      ) : datum.status == 'Unapproved' ? (
                        <>
                          {isStatusHovered === datum.id ? (
                            <button
                              className="btn btn-sm btn-success font-10"
                              onClick={() => onStatusChanged(datum.id)}
                            >
                              Approve
                            </button>
                          ) : (
                            <span
                              className={`badge  ${
                                isStatusChanged === datum.id
                                  ? 'badge-accent-100'
                                  : 'badge-danger-light'
                              }`}
                              onMouseEnter={() => onMouseEnterHandler(datum.id)}
                              onMouseLeave={() => onMouseLeaveHandler(datum.id)}
                            >
                              {isStatusChanged === datum.id
                                ? 'Approved'
                                : 'Unapproved'}
                            </span>
                          )}
                        </>
                      ) : datum.status == 'Finalized' ? (
                        <span className="badge badge-purple-50  ">
                          {datum.status}
                        </span>
                      ) : (
                        <div className="d-flex">
                          <button
                            className="btn btn-sm btn-success mr-1"
                            onClick={handleShowModal}
                          >
                            Approve
                          </button>
                          <button
                            className="btn btn-sm btn-danger p-1"
                            onClick={handleShowModal}
                          >
                            <IconTimes />
                          </button>
                        </div>
                      )}
                    </div>
                  </td>
                  <td style={{ width: '2%' }}>
                    <div className="d-flex justify-content-end">
                      <button className="btn btn-link text-muted">
                        <IconTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* Confirmation Modal */}
      <Modal show={showModal} centered animation={false} size="sm">
        <Modal.Body className="p-0">
          <div className="text-center py-5">
            <CustomIconAlert className="mb-4" />
            <h3>You have unsaved changes</h3>
            <p className="text-muted">
              Your changes will be lost if you don’t save them
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <a className="text-muted mr-5" onClick={handleHideModal}>
            Cancel
          </a>
          <button className="btn btn-outline-light" onClick={handleHideModal}>
            Don’t save
          </button>
          <button className="btn btn-primary" onClick={handleHideModal}>
            Save & Close
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default StaticTableContent;
