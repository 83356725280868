import React from 'react';
import { Icondots, IconTruck, IconCart } from '../../../common/icons';

const equipment = [
  {
    id: 1,
    equipmentName: 'Trucks 500',
    eqipmentModel: 'Volvo VNL 760',
    status: 'Last Use',
    date: '18/09/2016',
  },
  {
    id: 2,
    equipmentName: 'Trucks 500',
    eqipmentModel: 'Volvo VNL 760',
    status: '',
    date: '18/09/2016',
  },
  {
    id: 3,
    equipmentName: 'Trucks 500',
    eqipmentModel: 'Volvo VNL 760',
    status: '',
    date: '18/09/2016',
  },
  {
    id: 4,
    equipmentName: 'Chassis TPNZ - 551323',
    eqipmentModel: 'Volvo VNL 760',
    status: '',
    date: '18/09/2016',
  },
  {
    id: 5,
    equipmentName: 'Chassis TPNZ - 551323',
    eqipmentModel: 'Volvo VNL 760',
    status: '',
    date: '18/09/2016',
  },
];

const EquipmentList = () => {
  return (
    <>
      <div className="card mb-20 p-20">
        <div className="d-flex justify-content-between align-items-center pb-15">
          <div className="d-flex flex-column">
            <div className="font-16 font-weight-500 mb-1">Unused Equipment</div>
            <div className="d-flex flex-row ">
              <div className="text-muted">In over 30 Days</div>
            </div>
          </div>
        </div>
        {equipment.map((equipments) => (
          <div className="d-flex justify-content-between pb-20">
            <div className="d-flex">
              <div className="bg-gray-50 p-1 mb-1 mr-10 rounded-5">
                <IconCart className="text-gray-500" />
              </div>
              <div className="d-flex flex-column ">
                <div className="font-12  font-weight-500">
                  {equipments.equipmentName}
                </div>
                <div className="text-muted"> {equipments.eqipmentModel}</div>
              </div>
            </div>
            <div className="d-flex flex-column ml-auto">
              {equipments.status === 'Last Use' ? (
                <div>
                  <div className="font-12 mr-1">{equipments.status}</div>
                  <div className="badge badge-sm badge-light">
                    {equipments.date}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="badge badge-sm badge-light">
                    {equipments.date}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default EquipmentList;
