import React, { Component } from 'react';

class LoadCard extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="w-100">
          <div className="card p-20">
            <div className="d-flex-justify-content-center">
              <div className="d-flex align-items-center mb-20">
                <h6 className="font-16 font-weight-500">Load 1</h6>
                <button className=" btn btn-primary ml-10">Confirm</button>
              </div>
              <div className="mb-10 font-14 font-weight-500">
                <h6 className="">Customer</h6>
              </div>
              <div className="row pb-20">
                <div className="col-md-4">
                  <div className="mb-1 font-12 font-weight-500">
                    <span>Pick Up</span>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span> Terminal Location:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>3037 CN Rail - Brampton</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Address:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>1901 Thornridge Cir. Shiloh, Hawaii 81063</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Location:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>3037 CN Rail - Brampton</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Weight:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>2995 kg</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-1 font-12 font-weight-500">
                    <span>Delivery</span>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Consignee:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>3037 CN Rail - Brampton</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Address:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>1901 Thornridge Cir. Shiloh, Hawaii 81063</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Location:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>3037 CN Rail - Brampton</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Weight:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>1233 kg</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-1 font-12 font-weight-500">
                    <span>Return</span>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Terminal Location:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>3037 CN Rail - Brampton</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Address:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>1901 Thornridge Cir. Shiloh, Hawaii 81063</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Location:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>3037 CN Rail - Brampton</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Weight:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>Base Dray:</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Weight:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>3037 CN Rail - Brampton</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Purchase Order #:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>3037 CN Rail - Brampton</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Fuel Surcharge:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>1231</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pb-20">
                <div className="col-md-4">
                  <div className="mb-1 font-12 font-weight-500">
                    <span>Reference</span>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span> Seal Number:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>THA9289129128</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Originator Office Code:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>CHI</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Receiver Office Code:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>TOR</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Work Order Number:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>CCHH213123213121231-SS23123213123</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Bill of Landing Number</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>CCHH2131232</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Voyage Number:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>00007</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Vessel:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>M Triumph</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Port of Landing:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>Maersk Line</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Port of Dishacrge:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>Maersk Line</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-1 font-12 font-weight-500">
                    <span>Equipment Detail</span>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Container #:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>M0FU231321</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Container Type:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>Standard</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Container Size:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>40’</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Container Initials</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>M0FU</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Sjipment</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>M0FU</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-1 font-12 font-weight-500">
                    <span>Profile</span>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Bill to Party:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>2464 Royal Ln. Mesa, New Jersey 45463</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Shipper:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>Salam Pacific Indonesia Lines</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Dispatcher:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>Cameron Williamson</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Carrier:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>X</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Weight:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>3037 CN Rail - Brampton</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Purchase Order #:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>3037 CN Rail - Brampton</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Fuel Surcharge:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>1231</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-10 ">
                <h6 className="font-14 font-weight-500">Instructions</h6>
              </div>
              <div className="col-md-12 px-0">
                <div className="mb-1 font-12 font-weight-500">
                  <span>Mutually Defined</span>
                </div>
                <div className="font-12 font-normal">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                  Exercitation veniam consequat sunt nostrud amet.
                </div>
              </div>
            </div>
          </div>
          <div className="border"></div>
          <div className="card p-20">
            <div className="d-flex-justify-content-center">
              <div className="d-flex align-items-center mb-20">
                <h6 className="font-16 font-weight-500">Load 2</h6>
                <button className=" btn btn-primary ml-10">Confirm</button>
              </div>
              <div className="mb-10 font-14 font-weight-500">
                <h6 className="">Customer</h6>
              </div>
              <div className="row pb-20">
                <div className="col-md-4">
                  <div className="mb-1 font-12 font-weight-500">
                    <span>Pick Up</span>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span> Terminal Location:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>3037 CN Rail - Brampton</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Address:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>1901 Thornridge Cir. Shiloh, Hawaii 81063</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Location:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>3037 CN Rail - Brampton</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Weight:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>2995 kg</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-1 font-12 font-weight-500">
                    <span>Delivery</span>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Consignee:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>3037 CN Rail - Brampton</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Address:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>1901 Thornridge Cir. Shiloh, Hawaii 81063</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Location:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>3037 CN Rail - Brampton</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Weight:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>1233 kg</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-1 font-12 font-weight-500">
                    <span>Return</span>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Terminal Location:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>3037 CN Rail - Brampton</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Address:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>1901 Thornridge Cir. Shiloh, Hawaii 81063</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Location:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>3037 CN Rail - Brampton</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Weight:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>Base Dray:</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Weight:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>3037 CN Rail - Brampton</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Purchase Order #:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>3037 CN Rail - Brampton</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Fuel Surcharge:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>1231</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pb-20">
                <div className="col-md-4">
                  <div className="mb-1 font-12 font-weight-500">
                    <span>Reference</span>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span> Seal Number:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>THA9289129128</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Originator Office Code:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>CHI</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Receiver Office Code:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>TOR</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Work Order Number:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>CCHH213123213121231-SS23123213123</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Bill of Landing Number</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>CCHH2131232</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Voyage Number:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>00007</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Vessel:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>M Triumph</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Port of Landing:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>Maersk Line</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Port of Dishacrge:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>Maersk Line</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-1 font-12 font-weight-500">
                    <span>Equipment Detail</span>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Container #:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>M0FU231321</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Container Type:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>Standard</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Container Size:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>40’</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Container Initials</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>M0FU</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Sjipment</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>M0FU</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-1 font-12 font-weight-500">
                    <span>Profile</span>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Bill to Party:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>2464 Royal Ln. Mesa, New Jersey 45463</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Shipper:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>Salam Pacific Indonesia Lines</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Dispatcher:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>Cameron Williamson</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Carrier:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>X</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Weight:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>3037 CN Rail - Brampton</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Purchase Order #:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>3037 CN Rail - Brampton</span>
                    </div>
                  </div>
                  <div className=" d-flex align-items-center mb-1">
                    <div className="font-12 font-normal text-muted">
                      <span>Fuel Surcharge:</span>
                    </div>
                    <div className="ml-1 text-gray-900">
                      <span>1231</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-10 ">
                <h6 className="font-14 font-weight-500">Instructions</h6>
              </div>
              <div className="col-md-12 px-0">
                <div className="mb-1 font-12 font-weight-500">
                  <span>Mutually Defined</span>
                </div>
                <div className="font-12 font-normal">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                  Exercitation veniam consequat sunt nostrud amet.
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LoadCard;
