import { SetStateAction, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  IconAngleArrowRight,
  IconCheck,
  IconEye,
  IconEyeClosed,
  IconAngleArrowDown,
  IconInfo,
} from "../../../../common/icons";

export interface ModalPropsI {
  showModal: any;
  hideModal: any;
}

const PortLogin = (props: ModalPropsI) => {
  const { showModal, hideModal } = props;
  const [selectedPort, setSelectedPort] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPortCred, setShowPortCred] = useState(1);
  const handleSelectedPort = () => {
    setSelectedPort(!selectedPort);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(showPassword ? false : true);
  };

  const handlePortCred = (i: any) => {
    setShowPortCred(showPortCred === i ? null : i);
  };

  return (
    <Modal show={showModal} centered animation={false} size="xl">
      <Modal.Header className="justify-content-center">
        <Modal.Title>Enter Port Login Credentials</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* No login required */}
        <div>
          <div className="d-flex align-items-center mb-10">
            <span className="badge-brand-500 rounded-circle mr-15 w-20px h-20px d-flex align-items-center justify-content-center">
              <IconCheck />
            </span>
            <h5 className="font-14 mb-0">Port of Miami</h5>
            <a
              href="javascript:void(0)"
              className="text-gray-900 ml-15"
              onClick={() => handlePortCred(1)}
            >
              {showPortCred === 1 ? (
                <IconAngleArrowDown />
              ) : (
                <IconAngleArrowRight />
              )}
            </a>
          </div>
          {showPortCred === 1 && (
            <form>
              <div className="row align-items-center badge-brand-50 py-2 rounded-3 no-gutters">
                <div className="col-4">
                  <div className="px-10">
                    <label className="font-14 font-weight-500 text-gray-900">
                      Miami Port
                    </label>
                    <p className="text-brand-500 mb-0">Connected!</p>
                  </div>
                </div>
                <div className="col-4">
                  <span className="badge-gray-200 mr-10 rounded-circle mr-15 w-20px h-20px d-inline-flex align-items-center justify-content-center">
                    <IconInfo />
                  </span>
                  <h5 className="font-14 d-inline-block mb-0">
                    There is no login required
                  </h5>
                </div>
              </div>
            </form>
          )}
        </div>
        <hr className="border-gray-100" />
        {/* Connected */}
        <div>
          <div className="d-flex align-items-center mb-10">
            <span className="badge-brand-500 rounded-circle mr-15 w-20px h-20px d-flex align-items-center justify-content-center">
              <IconCheck />
            </span>
            <h5 className="font-14 mb-0">Port of Boston</h5>
            <a
              href="javascript:void(0)"
              className="text-gray-900 ml-15"
              onClick={() => handlePortCred(2)}
            >
              {showPortCred === 2 ? (
                <IconAngleArrowDown />
              ) : (
                <IconAngleArrowRight />
              )}
            </a>
          </div>
          {showPortCred === 2 && (
            <form>
              <div className="row align-items-center bg-brand-50 py-2 rounded-3 no-gutters">
                <div className="col-4">
                  <div className="px-10">
                    <label className="font-14 font-weight-500">
                      CONLEY TERMINAL
                    </label>
                    <p className="text-brand-500 mb-0">Connected!</p>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group mb-0 mr-10">
                    <input
                      className="form-control"
                      placeholder="Port Username"
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="input-wrapper mr-2">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className="form-control"
                      placeholder="Enter Password"
                    />
                    <div
                      className="input-icon"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <IconEye /> : <IconEyeClosed />}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
        <hr className="border-gray-100" />
        {/* Invalid Credentials */}
        <div>
          <div className="d-flex align-items-center mb-10">
            <span className="badge-outline-danger rounded-circle mr-15 w-20px h-20px d-flex align-items-center justify-content-center">
              3
            </span>
            <h5 className="font-14 mb-0">Port of Los Angeles</h5>
            <a
              href="javascript:void(0)"
              className="text-gray-900 ml-15"
              onClick={() => handlePortCred(3)}
            >
              {showPortCred === 3 ? (
                <IconAngleArrowDown />
              ) : (
                <IconAngleArrowRight />
              )}
            </a>
          </div>
          {showPortCred === 3 && (
            <>
              <form className="mb-2">
                <div className="row align-items-center bg-danger-light py-2 rounded-3 no-gutters">
                  <div className="col-4">
                    <div className="px-10">
                      <label className="font-14 font-weight-500">VIG</label>
                      <p className="text-error-500 mb-0">
                        Login or Password is wrong
                      </p>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group mb-0 mr-10">
                      <input
                        className="form-control border-error-500"
                        placeholder="Port Username"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="input-wrapper mr-2">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control border-error-500"
                        placeholder="Enter Password"
                      />
                      <div
                        className="input-icon"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <IconEye /> : <IconEyeClosed />}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <form>
                <div className="row align-items-center bg-gray-50 py-2 rounded-3 no-gutters">
                  <div className="col-4">
                    <div className="px-10">
                      <label className="font-14 font-weight-500 px-10">
                        NIT
                      </label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group mb-0 mr-10">
                      <input
                        className="form-control"
                        placeholder="Port Username"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="input-wrapper mr-2">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                        placeholder="Enter Password"
                      />
                      <div
                        className="input-icon"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <IconEye /> : <IconEyeClosed />}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
        <hr className="border-gray-100" />
        {/*Missed Credentials*/}
        <div>
          <div className="d-flex align-items-center mb-10">
            <span className="badge-outline-gray-50 rounded-circle mr-15 w-20px h-20px d-flex align-items-center justify-content-center">
              4
            </span>
            <h5 className="font-14 mb-0">Port of Virginia</h5>
            <a
              href="javascript:void(0)"
              className="text-gray-900 ml-15"
              onClick={() => handlePortCred(4)}
            >
              {showPortCred === 4 ? (
                <IconAngleArrowDown />
              ) : (
                <IconAngleArrowRight />
              )}
            </a>
          </div>
          {showPortCred === 4 && (
            <>
              <form className="mb-2">
                <div className="row align-items-center bg-warning-50 py-2 rounded-3 no-gutters">
                  <div className="col-4">
                    <div className="px-10">
                      <label className="font-14 font-weight-500">NIT</label>
                      <p className="text-warning-500 mb-0">
                        You’ve missed the data
                      </p>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group mb-0 mr-10">
                      <input
                        className="form-control border-orange"
                        placeholder="Port Username"
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="input-wrapper mr-2">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control border-orange"
                        placeholder="Enter Password"
                      />
                      <div
                        className="input-icon"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <IconEye /> : <IconEyeClosed />}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-outline-light py-2 mr-10"
          onClick={showModal}
        >
          Back
        </button>
        <button className="btn btn-primary py-2" onClick={hideModal}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PortLogin;
