import React from 'react';
import { Modal } from 'react-bootstrap';

const AddContactModal = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;
  return (
    <Modal show={showModal} centered animation={false} size="sm">
      <Modal.Header>
        <Modal.Title>Add Contact</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-wrapper">
          <form>
            <div className="row mb-10">
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> First name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter first name"
                />
              </div>
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Last name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter last name"
                />
              </div>
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Customer
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter customer"
                />
              </div>
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Email"
                />
              </div>
            </div>
            <div className="border mb-3"></div>
            <div className="row ">
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Password
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="create password"
                />
              </div>
              <div className="form-group col-md-12">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Confirm Password
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="confirm password"
                />
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-success" onClick={onSubmit}>
          Add Contact
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddContactModal;
