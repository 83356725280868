import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IconPen, IconTrash } from '../../../common/icons';
import DeductionRatesModal from './DeductionRatesModal';

const StaticTableContent = () => {
  const [isDeductionRatesModal, setIsDeductionRatesModal] = useState(false);

  const showDeductionRates = (event) => {
    setIsDeductionRatesModal(true);
  };

  const hideDeductionRates = (event) => {
    setIsDeductionRatesModal(false);
  };
  return (
    <React.Fragment>
      {/* Search Filter */}
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>
      </div>
      <div
        className="table-responsive"
        style={{ height: 'calc(100vh - 255px)' }}
      >
        <table className="table table-card">
          <thead>
            <tr>
              <th>Driver</th>
              <th>Deduction Type</th>
              <th width="2%"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">GH</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Guy Hawkins
                    </a>
                  </h5>
                </div>
              </td>
              <td>ELD, Loan, ELD, Loan, ELD, Loan</td>
              <td>
                <button className="btn btn-link" onClick={showDeductionRates}>
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">GH</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Guy Hawkins
                    </a>
                  </h5>
                </div>
              </td>
              <td>ELD, Loan, ELD, Loan, ELD, Loan</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">GH</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Guy Hawkins
                    </a>
                  </h5>
                </div>
              </td>
              <td>ELD, Loan, ELD, Loan, ELD, Loan</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">GH</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Guy Hawkins
                    </a>
                  </h5>
                </div>
              </td>
              <td>ELD, Loan, ELD, Loan, ELD, Loan</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">GH</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Guy Hawkins
                    </a>
                  </h5>
                </div>
              </td>
              <td>ELD, Loan, ELD, Loan, ELD, Loan</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">GH</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Guy Hawkins
                    </a>
                  </h5>
                </div>
              </td>
              <td>ELD, Loan, ELD, Loan, ELD, Loan</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">KW</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Kristin Watson
                    </a>
                  </h5>
                </div>
              </td>
              <td>Move</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">BS</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Brooklyn Simmons
                    </a>
                  </h5>
                </div>
              </td>
              <td className="text-muted">No deductions found</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">AM</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Arlene McCoy
                    </a>
                  </h5>
                </div>
              </td>
              <td className="text-muted">No deductions found</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">AM</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Arlene McCoy
                    </a>
                  </h5>
                </div>
              </td>
              <td className="text-muted">No deductions found</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">AM</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Arlene McCoy
                    </a>
                  </h5>
                </div>
              </td>
              <td className="text-muted">No deductions found</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">AM</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Arlene McCoy
                    </a>
                  </h5>
                </div>
              </td>
              <td className="text-muted">No deductions found</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">AM</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Arlene McCoy
                    </a>
                  </h5>
                </div>
              </td>
              <td className="text-muted">No deductions found</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">AM</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Arlene McCoy
                    </a>
                  </h5>
                </div>
              </td>
              <td className="text-muted">No deductions found</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">AM</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Arlene McCoy
                    </a>
                  </h5>
                </div>
              </td>
              <td className="text-muted">No deductions found</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">AM</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Arlene McCoy
                    </a>
                  </h5>
                </div>
              </td>
              <td className="text-muted">No deductions found</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">AM</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Arlene McCoy
                    </a>
                  </h5>
                </div>
              </td>
              <td className="text-muted">No deductions found</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">AM</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Arlene McCoy
                    </a>
                  </h5>
                </div>
              </td>
              <td className="text-muted">No deductions found</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">AM</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Arlene McCoy
                    </a>
                  </h5>
                </div>
              </td>
              <td className="text-muted">No deductions found</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">AM</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Arlene McCoy
                    </a>
                  </h5>
                </div>
              </td>
              <td className="text-muted">No deductions found</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">AM</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Arlene McCoy
                    </a>
                  </h5>
                </div>
              </td>
              <td className="text-muted">No deductions found</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">AM</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Arlene McCoy
                    </a>
                  </h5>
                </div>
              </td>
              <td className="text-muted">No deductions found</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <div class="d-flex">
                  <div class="mr-1 align-self-center">
                    <div class="avatar-circle  ">
                      <div class="font-12">AM</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center font-12">
                    <a href="#" class="text-dark">
                      Arlene McCoy
                    </a>
                  </h5>
                </div>
              </td>
              <td className="text-muted">No deductions found</td>
              <td>
                <button className="btn btn-link">
                  <IconPen className="" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        {isDeductionRatesModal && (
          <DeductionRatesModal
            onCancel={hideDeductionRates}
            onSubmit={hideDeductionRates}
          />
        )}
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
