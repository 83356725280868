import React, { useState, useRef, useEffect } from "react";
import BatchActionLoads from "../../common/batchActionLoads";
import { CustomIconAddUser } from "../../common/customIcons";
import {
  IconFire,
  IconMapMarker,
  IconSnowFlake,
  IconWarning,
  IconWeightTool,
  IconFile,
  IconCheck,
  IconTimes,
  IconSortDown,
  IconSortUp,
  IconSort,
} from "../../common/icons";
import OrderStatusModal from "./OrderStatusModal";

const StaticTableContent = (props) => {
  const ref = useRef();

  const { handleLoadOpen } = props;
  const [isBatchAction, setisBatchAction] = useState(false);
  const [isSelectDriver, setisSelectDriver] = useState(false);
  const [isOrderStatusModal, setIsOrderStatusModal] = useState(false);

  const showOrderStatusModal = () => {
    setIsOrderStatusModal(true);
  };

  const hideOrderStatusModal = () => {
    setIsOrderStatusModal(false);
  };

  const handleSelectDriver = (event) => {
    setisSelectDriver(!isSelectDriver);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isSelectDriver && ref.current && !ref.current.contains(e.target)) {
        setisSelectDriver(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isSelectDriver]);

  const handleBatchAction = () => {
    setisBatchAction(!isBatchAction);
  };

  return (
    <React.Fragment>
      {isBatchAction && <BatchActionLoads />}
      <table className="table-card">
        <thead>
          <tr>
            <th>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </th>
            <th className="table-sort table-sort--default">Load #</th>
            <th className="table-sort table-sort--default">Status</th>
            <th className="table-sort table-sort--default">Driver</th>
            <th className="table-sort table-sort--default">Move</th>
            <th className="table-sort table-sort--default">Next Address</th>
            <th className="table-sort table-sort--default">ETA</th>
            <th className="table-sort table-sort--default">LFD</th>
            <th className="table-sort table-sort--default">Cut</th>
            <th className="table-sort table-sort--default">
              Pickup
            </th>
            <th className="table-sort table-sort--default">
              APT
              
            </th>
            <th className="table-sort table-sort--default">
              Customer
              
            </th>
            <th className="table-sort table-sort--default">Container</th>
            <th className="table-sort table-sort--default">Chassis</th>
            <th className="table-sort table-sort--default">Size</th>
            <th>Type</th>
            <th>SSL</th>
            <th>BOL/BKG</th>
            <th>PORT</th>
            <th>Consignee</th>
            <th>Location</th>
            <th>RTN</th>
            <th>Distance (ml)</th>
            <th>Bobtail</th>
            <th>Return</th>
            <th>Seal #</th>
            <th>Pickup</th>
            <th>Return</th>
            <th>Change Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                  onClick={handleBatchAction}
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)" className="text-primary" onClick={() => handleLoadOpen()}>
                POP2_M100520
              </a>
              <div className="d-flex align-items">
                <span>
                  <IconWarning className="dispatch-icon text-warning-400" />
                </span>
                <span>
                  <IconMapMarker className="dispatch-icon text-gray-700" />
                </span>
                <span>
                  <IconWeightTool className="dispatch-icon text-gray-700" />
                </span>
                <span>
                  <IconFile className="dispatch-icon text-gray-700" />
                </span>
                <span>
                  <IconSnowFlake className="dispatch-icon text-badgeblue" />
                </span>
                <span>
                  <IconFire className="dispatch-icon text-error-500" />
                </span>
              </div>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div>Ollivander's Wand Shop</div>
            </td>
            <td>
              <div className="d-flex" onClick={showOrderStatusModal}>
                <div className="mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    className="avatar-circle rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Robert Fox
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>
              <div>
                <span className="badge badge-sm badge-light">Deliverload</span>
              </div>
              6391 Elgin St. Celina...
            </td>
            <td>06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>Home Decor Supp</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>5680.40</td>
            <td>-</td>
            <td>06/11 - 08:50pm</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="Select"
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)" className="text-primary" onClick={() => handleLoadOpen()}>
                POP2_M100521
              </a>
              <div className="d-flex align-items">
                <span>
                  <IconWarning className="dispatch-icon" />
                </span>
                <span>
                  <IconMapMarker className="dispatch-icon" />
                </span>
                <span>
                  <IconWeightTool className="dispatch-icon" />
                </span>
                <span>
                  <IconFile className="dispatch-icon" />
                </span>
                <span>
                  <IconSnowFlake className="dispatch-icon" />
                </span>
                <span>
                  <IconFire className="dispatch-icon" />
                </span>
              </div>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div>Ollivander's Wand Shop</div>
            </td>
            <td>
              <div className="d-flex" onClick={showOrderStatusModal}>
                <div className="mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                    className="avatar-circle rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Robert Fox
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>
              <div>
                <span className="badge badge-sm badge-light">Deliverload</span>
              </div>
              6391 Elgin St. Celina...
            </td>
            <td>06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>Home Decor Supp</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>5680.40</td>
            <td>-</td>
            <td>06/11 - 08:50pm</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="Select"
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)" className="text-primary" onClick={() => handleLoadOpen()}>
                POP2_M100522
              </a>
              <div className="d-flex align-items">
                <span>
                  <IconWarning className="dispatch-icon" />
                </span>
                <span>
                  <IconMapMarker className="dispatch-icon" />
                </span>
                <span>
                  <IconWeightTool className="dispatch-icon" />
                </span>
                <span>
                  <IconFile className="dispatch-icon" />
                </span>
                <span>
                  <IconSnowFlake className="dispatch-icon" />
                </span>
                <span>
                  <IconFire className="dispatch-icon" />
                </span>
              </div>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-purple">Dispatched</span>
              </div>
              <div>Wonka Industries</div>
            </td>
            <td>
              <div className="d-flex" onClick={showOrderStatusModal}>
                <div className="mr-2 align-self-center">
                  <div className="avatar-circle avatar-xs bg-warning">
                    <div className="avatar-circle__initial ">?</div>
                  </div>
                </div>
                <h5 className="mb-0 align-self-center d-flex align-items-center">
                  <a href="#" className="text-dark">
                    Ralph Edwards
                  </a>
                  <div className="d-flex ml-1">
                    <button className="btn btn-xs btn-success mr-1">
                      <IconCheck />
                    </button>
                    <button className="btn btn-xs btn-danger">
                      <IconTimes />
                    </button>
                  </div>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>
              <div>
                <span className="badge badge-sm badge-light">Deliverload</span>
              </div>
              6391 Elgin St. Celina...
            </td>
            <td>06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>Home Decor Supp</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>5680.40</td>
            <td>-</td>
            <td>06/11 - 08:50pm</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="Select"
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)" className="text-primary" onClick={() => handleLoadOpen()}>
                POP2_M100520
              </a>
              <div className="d-flex align-items">
                <span>
                  <IconWarning className="dispatch-icon text-warning-400" />
                </span>
                <span>
                  <IconMapMarker className="dispatch-icon" />
                </span>
                <span>
                  <IconWeightTool className="dispatch-icon" />
                </span>
                <span>
                  <IconFile className="dispatch-icon" />
                </span>
                <span>
                  <IconSnowFlake className="dispatch-icon" />
                </span>
                <span>
                  <IconFire className="dispatch-icon" />
                </span>
              </div>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-info">
                  Enroute to Delivery load
                </span>
              </div>
              <div>Wonka Industries</div>
            </td>
            <td>
              <div className="d-flex" onClick={showOrderStatusModal}>
                <div className="mr-2 align-self-center">
                  <div className="avatar-circle avatar-xs bg-warning">
                    <div className="avatar-circle__initial ">?</div>
                  </div>
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Ralph Edwards
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>
              <div>
                <span className="badge badge-sm badge-light">Deliverload</span>
              </div>
              6391 Elgin St. Celina...
            </td>
            <td>06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>Home Decor Supp</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>5680.40</td>
            <td>-</td>
            <td>06/11 - 08:50pm</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="Select"
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)" className="text-primary" onClick={() => handleLoadOpen()}>
                POP2_M100520
              </a>
              <div className="d-flex align-items">
                <span>
                  <IconWarning className="dispatch-icon text-warning-400" />
                </span>
                <span>
                  <IconMapMarker className="dispatch-icon" />
                </span>
                <span>
                  <IconWeightTool className="dispatch-icon" />
                </span>
                <span>
                  <IconFile className="dispatch-icon" />
                </span>
                <span>
                  <IconSnowFlake className="dispatch-icon" />
                </span>
                <span>
                  <IconFire className="dispatch-icon" />
                </span>
              </div>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-pink">
                  Arrived at Deliver load
                </span>
              </div>
              <div>Hooli</div>
            </td>
            <td>
              <div className="d-flex" onClick={showOrderStatusModal}>
                <div className="mr-2 align-self-center">
                  <div className="avatar-circle avatar-xs bg-warning">
                    <div className="avatar-circle__initial ">?</div>
                  </div>
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Ralph Edwards
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>
              <div>
                <span className="badge badge-sm badge-light">Deliverload</span>
              </div>
              6391 Elgin St. Celina...
            </td>
            <td>06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>Home Decor Supp</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>5680.40</td>
            <td>-</td>
            <td>06/11 - 08:50pm</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="Select"
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)" className="text-primary" onClick={() => handleLoadOpen()}>
                POP2_M100520
              </a>
              <div className="d-flex align-items">
                <span>
                  <IconWarning className="dispatch-icon text-warning-400" />
                </span>
                <span>
                  <IconMapMarker className="dispatch-icon" />
                </span>
                <span>
                  <IconWeightTool className="dispatch-icon" />
                </span>
                <span>
                  <IconFile className="dispatch-icon" />
                </span>
                <span>
                  <IconSnowFlake className="dispatch-icon" />
                </span>
                <span>
                  <IconFire className="dispatch-icon" />
                </span>
              </div>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-primary">Available</span>
              </div>
              <div>Rundofase</div>
            </td>
            <td>
              <div className="d-flex" onClick={showOrderStatusModal}>
                <div className="mr-2 align-self-center">
                  <div className="avatar-circle avatar-xs bg-warning">
                    <div className="avatar-circle__initial ">?</div>
                  </div>
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Ralph Edwards
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>
              <div>
                <span className="badge badge-sm badge-light">Deliverload</span>
              </div>
              6391 Elgin St. Celina...
            </td>
            <td>06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>Home Decor Supp</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>5680.40</td>
            <td>-</td>
            <td>06/11 - 08:50pm</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="Select"
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)" className="text-primary" onClick={() => handleLoadOpen()}>
                POP2_M100520
              </a>
              <div className="d-flex align-items">
                <span>
                  <IconWarning className="dispatch-icon text-warning-400" />
                </span>
                <span>
                  <IconMapMarker className="dispatch-icon" />
                </span>
                <span>
                  <IconWeightTool className="dispatch-icon" />
                </span>
                <span>
                  <IconFile className="dispatch-icon" />
                </span>
                <span>
                  <IconSnowFlake className="dispatch-icon" />
                </span>
                <span>
                  <IconFire className="dispatch-icon" />
                </span>
              </div>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-warning">Pending</span>
              </div>
              <div>Wayne Enterprises</div>
            </td>
            <td>
              <div className="d-flex" onClick={showOrderStatusModal}>
                <div className="mr-2 align-self-center">
                  <div className="avatar-circle avatar-xs bg-warning">
                    <div className="avatar-circle__initial ">?</div>
                  </div>
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Ralph Edwards
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>
              <div>
                <span className="badge badge-sm badge-light">Deliverload</span>
              </div>
              6391 Elgin St. Celina...
            </td>
            <td>06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>Home Decor Supp</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>5680.40</td>
            <td>-</td>
            <td>06/11 - 08:50pm</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="Select"
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)" className="text-primary" onClick={() => handleLoadOpen()}>
                POP2_M100520
              </a>
              <div className="d-flex align-items">
                <span>
                  <IconWarning className="dispatch-icon text-warning-400" />
                </span>
                <span>
                  <IconMapMarker className="dispatch-icon" />
                </span>
                <span>
                  <IconWeightTool className="dispatch-icon" />
                </span>
                <span>
                  <IconFile className="dispatch-icon" />
                </span>
                <span>
                  <IconSnowFlake className="dispatch-icon" />
                </span>
                <span>
                  <IconFire className="dispatch-icon" />
                </span>
              </div>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-warning">Pending</span>
              </div>
              <div>Massive Dynamic</div>
            </td>
            <td>
              <div className="d-flex" onClick={showOrderStatusModal}>
                <div className="mr-2 align-self-center">
                  <div className="avatar-circle avatar-xs bg-warning">
                    <div className="avatar-circle__initial ">?</div>
                  </div>
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Ralph Edwards
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>
              <div>
                <span className="badge badge-sm badge-light">Deliverload</span>
              </div>
              6391 Elgin St. Celina...
            </td>
            <td>06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>Home Decor Supp</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>5680.40</td>
            <td>-</td>
            <td>06/11 - 08:50pm</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="Select"
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)" className="text-primary" onClick={() => handleLoadOpen()}>
                POP2_M100520
              </a>
              <div className="d-flex align-items">
                <span>
                  <IconWarning className="dispatch-icon text-warning-400" />
                </span>
                <span>
                  <IconMapMarker className="dispatch-icon" />
                </span>
                <span>
                  <IconWeightTool className="dispatch-icon" />
                </span>
                <span>
                  <IconFile className="dispatch-icon" />
                </span>
                <span>
                  <IconSnowFlake className="dispatch-icon" />
                </span>
                <span>
                  <IconFire className="dispatch-icon" />
                </span>
              </div>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-brown">Departed</span>
              </div>
              <div>The New York Inquirers</div>
            </td>
            <td className="text-center">
              <div className="position-relative d-inline-block" ref={ref}>
                <div
                  className="pointer"
                  onClick={() => setisSelectDriver((oldState) => !oldState)}
                >
                  <CustomIconAddUser />
                </div>
                {isSelectDriver && (
                  <div className="card card--shadow-5 popdrop popdrop--hcenter popdrop--sm mb-0 p-10 text-left">
                    <div className="app-search position-relative mb-1">
                      <span className="search-icon ml-1"></span>
                      <div className="position-relative">
                        <input
                          type="search"
                          className="form-control input-search-left pl-3 bg-gray-50"
                          placeholder="Search.."
                          value=""
                        />
                      </div>
                    </div>
                    <div style={{ maxHeight: 120, overflow: "auto" }}>
                      <a href="" className="popdrop__dropdown-item">
                        Bessie Cooper
                      </a>
                      <a
                        href="javascript:void(0)"
                        className="popdrop__dropdown-item"
                      >
                        Fernan Torres
                      </a>
                      <a
                        href="javascript:void(0)"
                        className="popdrop__dropdown-item"
                      >
                        Leo Messi
                      </a>
                      <a
                        href="javascript:void(0)"
                        className="popdrop__dropdown-item"
                      >
                        Ronaldo
                      </a>
                      <a
                        href="javascript:void(0)"
                        className="popdrop__dropdown-item"
                      >
                        Miley
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </td>
            <td>Import</td>
            <td>
              <div>
                <span className="badge badge-sm badge-light">Deliverload</span>
              </div>
              6391 Elgin St. Celina...
            </td>
            <td>06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>Home Decor Supp</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>5680.40</td>
            <td>-</td>
            <td>06/11 - 08:50pm</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="Select"
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              <a href="javascript:void(0)" className="text-primary" onClick={() => handleLoadOpen()}>
                POP2_M100520
              </a>
              <div className="d-flex align-items">
                <span>
                  <IconWarning className="dispatch-icon text-warning-400" />
                </span>
                <span>
                  <IconMapMarker className="dispatch-icon" />
                </span>
                <span>
                  <IconWeightTool className="dispatch-icon" />
                </span>
                <span>
                  <IconFile className="dispatch-icon" />
                </span>
                <span>
                  <IconSnowFlake className="dispatch-icon" />
                </span>
                <span>
                  <IconFire className="dispatch-icon" />
                </span>
              </div>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div>Vehement Capital Partners</div>
            </td>
            <td>
              <div className="d-flex" onClick={showOrderStatusModal}>
                <div className="mr-2 align-self-center">
                  <div className="avatar-circle avatar-xs bg-warning">
                    <div className="avatar-circle__initial ">?</div>
                  </div>
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Ralph Edwards
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>
              <div>
                <span className="badge badge-sm badge-light">Deliverload</span>
              </div>
              6391 Elgin St. Celina...
            </td>
            <td>06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>Home Decor Supp</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>5680.40</td>
            <td>-</td>
            <td>06/11 - 08:50pm</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="Select"
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              {" "}
              <a href="javascript:void(0)" className="text-primary" onClick={() => handleLoadOpen()}>
                POP2_M100520
              </a>
              <div className="d-flex align-items">
                <span>
                  <IconWarning className="dispatch-icon text-warning-400" />
                </span>
                <span>
                  <IconMapMarker className="dispatch-icon" />
                </span>
                <span>
                  <IconWeightTool className="dispatch-icon" />
                </span>
                <span>
                  <IconFile className="dispatch-icon" />
                </span>
                <span>
                  <IconSnowFlake className="dispatch-icon" />
                </span>
                <span>
                  <IconFire className="dispatch-icon" />
                </span>
              </div>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div>Bubba Gump</div>
            </td>
            <td>
              <div className="d-flex" onClick={showOrderStatusModal}>
                <div className="mr-2 align-self-center">
                  <div className="avatar-circle avatar-xs bg-warning">
                    <div className="avatar-circle__initial ">?</div>
                  </div>
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Ralph Edwards
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>
              <div>
                <span className="badge badge-sm badge-light">Deliverload</span>
              </div>
              6391 Elgin St. Celina...
            </td>
            <td>06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>Home Decor Supp</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>5680.40</td>
            <td>-</td>
            <td>06/11 - 08:50pm</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="Select"
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              {" "}
              <a href="javascript:void(0)" className="text-primary" onClick={() => handleLoadOpen()}>
                POP2_M100520
              </a>
              <div className="d-flex align-items">
                <span>
                  <IconWarning className="dispatch-icon text-warning-400" />
                </span>
                <span>
                  <IconMapMarker className="dispatch-icon" />
                </span>
                <span>
                  <IconWeightTool className="dispatch-icon" />
                </span>
                <span>
                  <IconFile className="dispatch-icon" />
                </span>
                <span>
                  <IconSnowFlake className="dispatch-icon" />
                </span>
                <span>
                  <IconFire className="dispatch-icon" />
                </span>
              </div>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div>Bubba Gump</div>
            </td>
            <td>
              <div className="d-flex" onClick={showOrderStatusModal}>
                <div className="mr-2 align-self-center">
                  <div className="avatar-circle avatar-xs bg-warning">
                    <div className="avatar-circle__initial ">?</div>
                  </div>
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Ralph Edwards
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>
              <div>
                <span className="badge badge-sm badge-light">Deliverload</span>
              </div>
              6391 Elgin St. Celina...
            </td>
            <td>06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>Home Decor Supp</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>5680.40</td>
            <td>-</td>
            <td>06/11 - 08:50pm</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="Select"
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              {" "}
              <a href="javascript:void(0)" className="text-primary" onClick={() => handleLoadOpen()}>
                POP2_M100520
              </a>
              <div className="d-flex align-items">
                <span>
                  <IconWarning className="dispatch-icon text-warning-400" />
                </span>
                <span>
                  <IconMapMarker className="dispatch-icon" />
                </span>
                <span>
                  <IconWeightTool className="dispatch-icon" />
                </span>
                <span>
                  <IconFile className="dispatch-icon" />
                </span>
                <span>
                  <IconSnowFlake className="dispatch-icon" />
                </span>
                <span>
                  <IconFire className="dispatch-icon" />
                </span>
              </div>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div>Bubba Gump</div>
            </td>
            <td>
              <div className="d-flex">
                <div className="mr-2 align-self-center">
                  <div className="avatar-circle avatar-xs bg-warning">
                    <div className="avatar-circle__initial ">?</div>
                  </div>
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Ralph Edwards
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>
              <div>
                <span className="badge badge-sm badge-light">Deliverload</span>
              </div>
              6391 Elgin St. Celina...
            </td>
            <td>06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>Home Decor Supp</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>5680.40</td>
            <td>-</td>
            <td>06/11 - 08:50pm</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="Select"
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              {" "}
              <a href="javascript:void(0)" className="text-primary" onClick={() => handleLoadOpen()}>
                POP2_M100520
              </a>
              <div className="d-flex align-items">
                <span>
                  <IconWarning className="dispatch-icon text-warning-400" />
                </span>
                <span>
                  <IconMapMarker className="dispatch-icon" />
                </span>
                <span>
                  <IconWeightTool className="dispatch-icon" />
                </span>
                <span>
                  <IconFile className="dispatch-icon" />
                </span>
                <span>
                  <IconSnowFlake className="dispatch-icon" />
                </span>
                <span>
                  <IconFire className="dispatch-icon" />
                </span>
              </div>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div>Bubba Gump</div>
            </td>
            <td>
              <div className="d-flex">
                <div className="mr-2 align-self-center">
                  <div className="avatar-circle avatar-xs bg-warning">
                    <div className="avatar-circle__initial ">?</div>
                  </div>
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Ralph Edwards
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>
              <div>
                <span className="badge badge-sm badge-light">Deliverload</span>
              </div>
              6391 Elgin St. Celina...
            </td>
            <td>06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>Home Decor Supp</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>5680.40</td>
            <td>-</td>
            <td>06/11 - 08:50pm</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="Select"
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              {" "}
              <a href="javascript:void(0)" className="text-primary" onClick={() => handleLoadOpen()}>
                POP2_M100520
              </a>
              <div className="d-flex align-items">
                <span>
                  <IconWarning className="dispatch-icon text-warning-400" />
                </span>
                <span>
                  <IconMapMarker className="dispatch-icon" />
                </span>
                <span>
                  <IconWeightTool className="dispatch-icon" />
                </span>
                <span>
                  <IconFile className="dispatch-icon" />
                </span>
                <span>
                  <IconSnowFlake className="dispatch-icon" />
                </span>
                <span>
                  <IconFire className="dispatch-icon" />
                </span>
              </div>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div>Bubba Gump</div>
            </td>
            <td>
              <div className="d-flex">
                <div className="mr-2 align-self-center">
                  <div className="avatar-circle avatar-xs bg-warning">
                    <div className="avatar-circle__initial ">?</div>
                  </div>
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Ralph Edwards
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>
              <div>
                <span className="badge badge-sm badge-light">Deliverload</span>
              </div>
              6391 Elgin St. Celina...
            </td>
            <td>06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>Home Decor Supp</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>5680.40</td>
            <td>-</td>
            <td>06/11 - 08:50pm</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="Select"
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              {" "}
              <a href="javascript:void(0)" className="text-primary" onClick={() => handleLoadOpen()}>
                POP2_M100520
              </a>
              <div className="d-flex align-items">
                <span>
                  <IconWarning className="dispatch-icon text-warning-400" />
                </span>
                <span>
                  <IconMapMarker className="dispatch-icon" />
                </span>
                <span>
                  <IconWeightTool className="dispatch-icon" />
                </span>
                <span>
                  <IconFile className="dispatch-icon" />
                </span>
                <span>
                  <IconSnowFlake className="dispatch-icon" />
                </span>
                <span>
                  <IconFire className="dispatch-icon" />
                </span>
              </div>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div>Bubba Gump</div>
            </td>
            <td>
              <div className="d-flex">
                <div className="mr-2 align-self-center">
                  <div className="avatar-circle avatar-xs bg-warning">
                    <div className="avatar-circle__initial ">?</div>
                  </div>
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Ralph Edwards
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>
              <div>
                <span className="badge badge-sm badge-light">Deliverload</span>
              </div>
              6391 Elgin St. Celina...
            </td>
            <td>06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>Home Decor Supp</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>5680.40</td>
            <td>-</td>
            <td>06/11 - 08:50pm</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="Select"
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              {" "}
              <a href="javascript:void(0)" className="text-primary" onClick={() => handleLoadOpen()}>
                POP2_M100520
              </a>
              <div className="d-flex align-items">
                <span>
                  <IconWarning className="dispatch-icon text-warning-400" />
                </span>
                <span>
                  <IconMapMarker className="dispatch-icon" />
                </span>
                <span>
                  <IconWeightTool className="dispatch-icon" />
                </span>
                <span>
                  <IconFile className="dispatch-icon" />
                </span>
                <span>
                  <IconSnowFlake className="dispatch-icon" />
                </span>
                <span>
                  <IconFire className="dispatch-icon" />
                </span>
              </div>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div>Bubba Gump</div>
            </td>
            <td>
              <div className="d-flex">
                <div className="mr-2 align-self-center">
                  <div className="avatar-circle avatar-xs bg-warning">
                    <div className="avatar-circle__initial ">?</div>
                  </div>
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Ralph Edwards
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>
              <div>
                <span className="badge badge-sm badge-light">Deliverload</span>
              </div>
              6391 Elgin St. Celina...
            </td>
            <td>06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>Home Decor Supp</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>5680.40</td>
            <td>-</td>
            <td>06/11 - 08:50pm</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="Select"
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              {" "}
              <a href="javascript:void(0)" className="text-primary" onClick={() => handleLoadOpen()}>
                POP2_M100520
              </a>
              <div className="d-flex align-items">
                <span>
                  <IconWarning className="dispatch-icon text-warning-400" />
                </span>
                <span>
                  <IconMapMarker className="dispatch-icon" />
                </span>
                <span>
                  <IconWeightTool className="dispatch-icon" />
                </span>
                <span>
                  <IconFile className="dispatch-icon" />
                </span>
                <span>
                  <IconSnowFlake className="dispatch-icon" />
                </span>
                <span>
                  <IconFire className="dispatch-icon" />
                </span>
              </div>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div>Bubba Gump</div>
            </td>
            <td>
              <div className="d-flex">
                <div className="mr-2 align-self-center">
                  <div className="avatar-circle avatar-xs bg-warning">
                    <div className="avatar-circle__initial ">?</div>
                  </div>
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Ralph Edwards
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>
              <div>
                <span className="badge badge-sm badge-light">Deliverload</span>
              </div>
              6391 Elgin St. Celina...
            </td>
            <td>06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>Home Decor Supp</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>5680.40</td>
            <td>-</td>
            <td>06/11 - 08:50pm</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="Select"
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              {" "}
              <a href="javascript:void(0)" className="text-primary" onClick={() => handleLoadOpen()}>
                POP2_M100520
              </a>
              <div className="d-flex align-items">
                <span>
                  <IconWarning className="dispatch-icon text-warning-400" />
                </span>
                <span>
                  <IconMapMarker className="dispatch-icon" />
                </span>
                <span>
                  <IconWeightTool className="dispatch-icon" />
                </span>
                <span>
                  <IconFile className="dispatch-icon" />
                </span>
                <span>
                  <IconSnowFlake className="dispatch-icon" />
                </span>
                <span>
                  <IconFire className="dispatch-icon" />
                </span>
              </div>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div>Bubba Gump</div>
            </td>
            <td>
              <div className="d-flex">
                <div className="mr-2 align-self-center">
                  <div className="avatar-circle avatar-xs bg-warning">
                    <div className="avatar-circle__initial ">?</div>
                  </div>
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Ralph Edwards
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>
              <div>
                <span className="badge badge-sm badge-light">Deliverload</span>
              </div>
              6391 Elgin St. Celina...
            </td>
            <td>06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>Home Decor Supp</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>5680.40</td>
            <td>-</td>
            <td>06/11 - 08:50pm</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="Select"
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <div className="from-check form-check--gray">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sr_no"
                />
              </div>
            </td>
            <td>
              {" "}
              <a href="javascript:void(0)" className="text-primary" onClick={() => handleLoadOpen()}>
                POP2_M100520
              </a>
              <div className="d-flex align-items">
                <span>
                  <IconWarning className="dispatch-icon text-warning-400" />
                </span>
                <span>
                  <IconMapMarker className="dispatch-icon" />
                </span>
                <span>
                  <IconWeightTool className="dispatch-icon" />
                </span>
                <span>
                  <IconFile className="dispatch-icon" />
                </span>
                <span>
                  <IconSnowFlake className="dispatch-icon" />
                </span>
                <span>
                  <IconFire className="dispatch-icon" />
                </span>
              </div>
            </td>
            <td>
              <div className="">
                <span className="badge badge-sm badge-green">Dropped</span>
              </div>
              <div>Bubba Gump</div>
            </td>
            <td>
              <div className="d-flex">
                <div className="mr-2 align-self-center">
                  <div className="avatar-circle avatar-xs bg-warning">
                    <div className="avatar-circle__initial ">?</div>
                  </div>
                </div>
                <h5 className="mb-0 align-self-center">
                  <a href="#" className="text-dark">
                    Ralph Edwards
                  </a>
                </h5>
              </div>
            </td>
            <td>Import</td>
            <td>
              <div>
                <span className="badge badge-sm badge-light">Deliverload</span>
              </div>
              6391 Elgin St. Celina...
            </td>
            <td>06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td className="bg-warning-200">06/11</td>
            <td>06/11 - 03:48 am</td>
            <td>06/11 - 03:48 am</td>
            <td>Home Decor Supp</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>40'</td>
            <td>ST</td>
            <td>Yang Ming</td>
            <td>MAEU210308615</td>
            <td>Mediterranean Shipping Company</td>
            <td>Hooli</td>
            <td>San Bernardino, CL</td>
            <td>GCT BAYONNE</td>
            <td>5680.40</td>
            <td>-</td>
            <td>06/11 - 08:50pm</td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="PROU1302541"
              ></input>
            </td>
            <td>
              <input
                className="w-150 form-control"
                placeholder="Select"
              ></input>
            </td>
          </tr>
        </tbody>
      </table>
      {/* Order Status Modal */}
      {isOrderStatusModal && (
        <OrderStatusModal
          onCancel={hideOrderStatusModal}
          onUpdate={hideOrderStatusModal}
        />
      )}
    </React.Fragment>
  );
};

export default StaticTableContent;
