import React, { Component } from "react";
import { CustomIconEquipment, CustomIconReference, CustomIconSave } from "../../../common/customIcons";

import CustomSelect from "../../../common/CustomSelect";
import { IconPlus, IconCalendar, IconMinus } from "../../../common/icons";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
class LoadInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadTabActive: false,
      showUploadModal: false,
    };
  }
  handleShowUploadModal = (event) => {
    this.setState({ showUploadModal: true });
  };
  handleHideUploadModal = (event) => {
    this.setState({ showUploadModal: false });
  };
  handleLoadTabActive = (i) => {
    this.setState({
      isLoadTabActive: this.state.isLoadTabActive === i ? null : i,
    });
  };
  render() {
    return (
      <div className="tab" id="tab-load-info">
        <div className="card pt-30 px-20 mb-10">
          <div>
            <h4 className="font-16 mb-20">
              <CustomIconSave className="mr-10" />
              Customer Info
            </h4>
            <div className="row mb-20">
              <div className="form-group col-md-3">
                <label>
                  <span className="text-danger">*</span> Customer
                </label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Perfect Christmas Trees"
                />
              </div>
            </div>
            <div className="form-row-md">
              <div className="form-group col">
                <label>Vessel ETA</label>
                <div className="input-wrapper">
                  <input className="form-control" placeholder="MM/DD/YY" />
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                </div>
              </div>
              <div className="form-group col">
                <label>Empty Date</label>
                <div className="input-wrapper">
                  <input className="form-control" placeholder="MM/DD/YY" />
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                </div>
              </div>
              <div className="form-group col">
                <label>Return Day</label>
                <div className="input-wrapper">
                  <input className="form-control" placeholder="MM/DD/YY" />
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                </div>
              </div>
              <div className="form-group col">
                <label>Return Form</label>
                <div className="input-wrapper">
                  <input className="form-control" placeholder="MM/DD/YY" />
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                </div>
              </div>
              <div className="form-group col">
                <label>Return To</label>
                <div className="input-wrapper">
                  <input className="form-control" placeholder="MM/DD/YY" />
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hr-light"></div>
          <div>
            <h4 className="font-16 mb-20">Port</h4>
            <div className="form-row-md mb-10">
              <div className="form-group col-md-4">
                <label>
                  <span className="text-danger">*</span> Port name 1
                </label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Perfect Christmas Trees"
                />
              </div>
              <div className="form-group col-md-2">
                <label>Pick Up From</label>
                <div className="input-wrapper">
                  <input className="form-control" placeholder="MM/DD/YY" />
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                </div>
              </div>
              <div className="form-group col-md-2">
                <label>Pick Up To</label>
                <div className="input-wrapper">
                  <input className="form-control" placeholder="MM/DD/YY" />
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                </div>
              </div>
            </div>
            <button className="btn btn-outline-primary btn-sm">
              <IconPlus className="mr-2" />
              Add Port
            </button>
          </div>
          <div className="hr-light"></div>
          <div>
            <h4 className="font-16 mb-20">Consignee</h4>
            <div className="form-row-md mb-10">
              <div className="form-group col-md-4">
                <label>
                  <span className="text-danger">*</span> Consignee
                </label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Perfect Christmas Trees"
                />
              </div>
              <div className="form-group col-md-2">
                <label>Pick Up From</label>
                <div className="input-wrapper">
                  <input className="form-control" placeholder="MM/DD/YY" />
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                </div>
              </div>
              <div className="form-group col-md-2">
                <label>Pick Up To</label>
                <div className="input-wrapper">
                  <input className="form-control" placeholder="MM/DD/YY" />
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                </div>
              </div>
            </div>
            <button className="btn btn-outline-primary btn-sm">
              <IconPlus className="mr-2" />
              Add Consignee
            </button>
          </div>
          <div className="hr-light"></div>
          <div>
            <h4 className="font-16 mb-20">Container Return</h4>
            <div className="form-row-md mb-10">
              <div className="form-group col-md-4">
                <label>Container Return</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Perfect Christmas Trees"
                />
              </div>
            </div>
          </div>
          <div className="hr-light"></div>
          <div>
            <h4 className="font-16 mb-20">Chassis Termination</h4>
            <div className="form-row-md mb-10">
              <div className="form-group col-md-4">
                <label>Chassis Termination</label>
                <CustomSelect
                  name={"name"}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Perfect Christmas Trees"
                />
              </div>
              <div className="form-group col-md-2">
                <label>Pick Up From</label>
                <div className="input-wrapper">
                  <input className="form-control" placeholder="MM/DD/YY" />
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                </div>
              </div>
              <div className="form-group col-md-2">
                <label>Pick Up To</label>
                <div className="input-wrapper">
                  <input className="form-control" placeholder="MM/DD/YY" />
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card pt-30 px-20 mb-10">
          <h4 className="font-16 mb-20">
            <CustomIconReference className="mr-10" />
            Reference
          </h4>
          <div className="form-row-md mb-10">
            <div className="form-group col-lg-2">
              <label>Master Bill of Lading</label>
              <input
                className="form-control"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>House Bill of Lading</label>
              <input
                className="form-control"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Pick Up #</label>
              <input
                className="form-control"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Reference #</label>
              <input
                className="form-control"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Purchase Order #</label>
              <input
                className="form-control"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Shipment #</label>
              <input
                className="form-control"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Seal #</label>
              <input
                className="form-control"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Vessel name #</label>
              <input
                className="form-control"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Custom Hold</label>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Freight Hold</label>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Carrier Hold</label>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Broker Hold</label>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Voyage #</label>
              <input
                className="form-control"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Return #</label>
              <input
                className="form-control"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>APT #</label>
              <input
                className="form-control"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>RV</label>
              <input
                className="form-control"
                placeholder="Perfect Christmas Trees"
              />
            </div>
          </div>
        </div>
        <div className="card pt-30 px-20 mb-10">
          <h4 className="font-16 mb-20">
            <CustomIconEquipment className="mr-10" />
            Equipment
          </h4>
          <div className="form-row-md mb-20">
            <h4 className="font-16 mb-15 col-md-12">Pre Order Status</h4>
            <div className="col-md">
              <div className="form-check form-group">
                <input
                  type="radio"
                  className="form-check-input"
                  id="prepull-drop-hook"
                  name="pre-order-status"
                />
                <label className="form-check-label" for="prepull-drop-hook">
                  Prepull + Drop & Hook
                </label>
              </div>
            </div>
            <div className="col-md">
              <div className="form-check form-group">
                <input
                  type="radio"
                  className="form-check-input"
                  id="prepull-drop-hook"
                  name="pre-order-status"
                />
                <label className="form-check-label" for="prepull-drop-hook">
                  Prepull + Drop & Hook
                </label>
              </div>
            </div>
            <div className="col-md">
              <div className="form-check form-group">
                <input
                  type="radio"
                  className="form-check-input"
                  id="prepull-drop-hook"
                  name="pre-order-status"
                />
                <label className="form-check-label" for="prepull-drop-hook">
                  Prepull + Drop & Hook
                </label>
              </div>
            </div>
            <div className="col-md">
              <div className="form-check form-group">
                <input
                  type="radio"
                  className="form-check-input"
                  id="prepull-drop-hook"
                  name="pre-order-status"
                />
                <label className="form-check-label" for="prepull-drop-hook">
                  Prepull + Drop & Hook
                </label>
              </div>
            </div>
            <div className="col-md">
              <div className="form-check form-group">
                <input
                  type="radio"
                  className="form-check-input"
                  id="prepull-drop-hook"
                  name="pre-order-status"
                />
                <label className="form-check-label" for="prepull-drop-hook">
                  Prepull + Drop & Hook
                </label>
              </div>
            </div>
          </div>
          <div className="form-row-md mb-10">
            <div className="form-group col-lg-2">
              <label>Container #</label>
              <input
                className="form-control"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Type</label>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Size</label>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Owner</label>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Chassis #</label>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Type </label>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Perfect Christmas Trees"
              />
            </div>
          </div>
          <div className="form-row-md mb-10">
            <div className="form-group col-lg-2">
              <label>Temperature</label>
              <input
                className="form-control"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Size</label>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Owner</label>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Hazmat</label>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Hot</label>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Liquor</label>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Perfect Christmas Trees"
              />
            </div>
          </div>
          <div className="form-row-md mb-10">
            <div className="form-group col-lg-2">
              <label>Route</label>
              <CustomSelect
                name={"name"}
                options={options}
                value={1}
                isMulti={true}
                isClearable={true}
                size="small"
                placement="right"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>Overweight</label>
              <input
                className="form-control"
                placeholder="Perfect Christmas Trees"
              />
            </div>
            <div className="form-group col-lg-2">
              <label>SCAC</label>
              <input
                className="form-control"
                placeholder="Perfect Christmas Trees"
              />
            </div>
          </div>
        </div>

        <div className="table mb-30">
          <table className="table table-card table-card--ls mb-10">
            <thead>
              <tr>
                <th width="20%">Commodity #</th>
                <th width="35%">Description</th>
                <th>Pieces</th>
                <th>Weight LBS</th>
                <th>Weight KGS</th>
                <th>Pallets</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <CustomSelect
                    name={"name"}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Placeholder"
                  />
                </td>
                <td>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button type="button" className="btn btn-outline-light">
                        <IconMinus />
                      </button>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      readOnly
                      placeholder="number"
                      value="61"
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-outline-light">
                        <IconPlus />
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button type="button" className="btn btn-outline-light">
                        <IconMinus />
                      </button>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      readOnly
                      placeholder="number"
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-outline-light">
                        <IconPlus />
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button type="button" className="btn btn-outline-light">
                        <IconMinus />
                      </button>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      readOnly
                      placeholder="number"
                      value="18110"
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-outline-light">
                        <IconPlus />
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button type="button" className="btn btn-outline-light">
                        <IconMinus />
                      </button>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      readOnly
                      placeholder="0"
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-outline-light">
                        <IconPlus />
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <button className="btn btn-white btn-sm text-primary">
            <IconPlus className="mr-2" />
            Add Row
          </button>
        </div>
      </div>
    );
  }
}

export default LoadInfo;
