import React from 'react';
import { Modal } from 'react-bootstrap';
import CustomSelect from '../../../common/CustomSelect';
import {
  IconPlus,
  IconCalendar,
  IconMinus,
  IconSortDown,
} from '../../../common/icons';

const AddQuotesModal = (props) => {
  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;
  return (
    <Modal show={showModal} centered animation={false} size="md">
      <Modal.Header>
        <Modal.Title>Add New Quote </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-wrapper">
          <form>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label>
                  <span className="text-danger">*</span> Customer
                </label>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="select customer"
                />
                <small id="" class="form-text text-primary font-12">
                  Group #1, Group #2
                </small>
              </div>
              <div className="form-group col-md-4">
                <label>
                  <span className="text-danger">*</span> Port
                </label>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="select Port"
                />
                <small id="" class="form-text  text-primary font-12">
                  Group #1, Group #2
                </small>
              </div>
              <div className="form-group col-md-4">
                <label>
                  <span className="text-danger">*</span> Zip Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter zip code here"
                />
              </div>
            </div>
            <div class="border mb-20"></div>
            <div className="form-row-md ">
              <h4 className="font-12 mb-10 col-md-12">Load Type</h4>
              <div className="col-md-2">
                <div className="form-check form-group">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="import"
                    name="import"
                  />
                  <label className="form-check-label " for="import">
                    Import
                  </label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check form-group">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="Export"
                    name="Export"
                  />
                  <label className="form-check-label" for="Export">
                    Export
                  </label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check form-group">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="road"
                    name="road"
                  />
                  <label className="form-check-label" for="road">
                    Road
                  </label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check form-group">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="bill-only"
                    name="bill-only"
                  />
                  <label className="form-check-label" for="bill-only">
                    Bill Only
                  </label>
                </div>
              </div>
            </div>
            <div className=" form-row ">
              <div className="form-group col-md-4">
                <label className="col-form-label">
                  <span className="text-danger">*</span> # of Loads
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter zip"
                />
              </div>
              <div className="form-group col-md-4">
                <label className="col-form-label">Expiration Date</label>
                <div className="input-wrapper">
                  <input
                    className="form-control"
                    placeholder="select Expiration Date"
                  />
                  <div className="input-icon">
                    <IconCalendar />
                  </div>
                </div>
              </div>
              <div className="form-group col-md-4">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Sale Agents
                </label>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="select sale agents"
                />
              </div>
              <div className="form-group col-md-4">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Container Type
                </label>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder="Container Type"
                />
              </div>
              <div className="form-group col-md-4">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Container Size
                </label>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder=" select Container Size"
                />
              </div>
              <div className="form-group col-md-4">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Chassis Type
                </label>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder=" select Chassis Type"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Hazmat
                </label>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder=" No"
                />
              </div>
              <div className="form-group col">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Hot
                </label>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder=" No"
                />
              </div>
              <div className="form-group col">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Liquor
                </label>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder=" select"
                />
              </div>
              <div className="form-group col">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Over Weight
                </label>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder=" select"
                />
              </div>
              <div className="form-group col">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Reefer
                </label>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder=" select"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Commodity
                </label>
                <CustomSelect
                  name={'name'}
                  options={options}
                  value={1}
                  isMulti={true}
                  isClearable={true}
                  size="small"
                  placement="right"
                  placeholder=" select"
                />
              </div>
              <div className="form-group col-md-4">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Weight Lbs
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="1234"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12 ">
                <label className="col-form-label">
                  <span className="text-danger">*</span> Notes
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Notes"
                />
              </div>
            </div>
          </form>
          <table className=" table table-card">
            <thead>
              <tr>
                <th widtj="20%" className="text-dark">
                  Name of Charge
                  <IconSortDown />
                </th>
                <th>Description</th>
                <th>Price</th>
                <th>Unit Count</th>
                <th>Unit of measure</th>
                <th>Free Units</th>
                <th>Per Units</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <CustomSelect
                    name={'name'}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="Select..."
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="placeholder"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="$0"
                    className="form-control"
                  />
                </td>
                <td>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button type="button" className="btn btn-outline-light">
                        <IconMinus />
                      </button>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      readOnly
                      placeholder="number"
                      value="61"
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-outline-light">
                        <IconPlus />
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <CustomSelect
                    name={'name'}
                    className={'w-150'}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select..."
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <button className="btn btn-circle btn-xs bg-soft-danger">
                    <IconMinus />
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <CustomSelect
                    name={'name'}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="Select..."
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="placeholder"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="$0"
                    className="form-control"
                  />
                </td>
                <td>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button type="button" className="btn btn-outline-light">
                        <IconMinus />
                      </button>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      readOnly
                      placeholder="number"
                      value="61"
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-outline-light">
                        <IconPlus />
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <CustomSelect
                    name={'name'}
                    className={'w-150'}
                    options={options}
                    value={1}
                    isMulti={true}
                    isClearable={true}
                    size="small"
                    placement="right"
                    placeholder="Select..."
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="$0"
                  />
                </td>
                <td>
                  <button className="btn btn-circle btn-xs bg-soft-danger">
                    <IconMinus />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <button className="btn btn-sm text-primary">
            <IconPlus className="mr-2" />
            Add charge
          </button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-success" onClick={onSubmit}>
          Add New Quotes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddQuotesModal;
