import React from "react";
import Chatpop from "../chatpop";
import { IconAngleArrowTop, IconTimes, IconPen } from "../icons";

const ChatBox = (props) => {
  const { category, closeChatBox = () => {} } = props;
  return (
    <div className="chatbox rounded-5">
      <div className="chatbox__head bg-white d-flex align-items-center px-15 py-10">
        <div className="chatpop-image">
          <img
            src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
            className="avatar rounded-circle"
            width="30"
            alt=""
          />
        </div>
        <div className="ml-10">
          <h5 className="font-14 mb-0 ">
            <a href="#" className="text-dark">
              Darlene Robertson
              <IconAngleArrowTop />
            </a>
          </h5>
          {category === "customer" && (
            <div className="badge badge-gray-300 mt-1">Company name</div>
          )}
          {category === "group" && (
            <div className="group d-flex align-items-center mt-1">
              <div className="group__avatar">
                <img
                  src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-3.jpg"
                  alt=""
                  className="avatar-xs avatar-circle"
                />
              </div>
              <div className="group__avatar">
                <img
                  src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-2.jpg"
                  alt=""
                  className="avatar-xs avatar-circle"
                />
              </div>
              <div className="group__avatar">
                <img
                  src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                  alt=""
                  className="avatar-xs avatar-circle"
                />
              </div>
              <div className="group__avatar">
                <img
                  src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                  alt=""
                  className="avatar-xs avatar-circle"
                />
              </div>
              <div className="group__avatar">
                <img
                  src="https://axle-logistics.s3.amazonaws.com/production/tms/shipment_WrEUM672717.png"
                  alt=""
                  className="avatar-xs avatar-circle"
                />
              </div>
              <div className="group__avatar">
                <div className="avatar-xs avatar-circle bg-light text-dark">
                  +3
                </div>
              </div>
            </div>
          )}
        </div>
        <a href="javascript:void(0)" className="d-none ml-auto" onClick={closeChatBox}>
          <IconPen className="text-muted wh-10px" />
        </a>
        <a href="javascript:void(0)" className="ml-auto" onClick={closeChatBox}>
          <IconTimes className="text-muted wh-20px" />
        </a>
      </div>
      <Chatpop className="chatpop--full" chatBoxOpen={true} />
    </div>
  );
};

export default ChatBox;
