import React, { useState } from "react";
import ColumnFilter from "../../../../common/columnFilter";
import FilterPop from "../../../../common/filterPop";
import { IconColumn, IconFilter } from "../../../../common/icons";

const ProblemContainerFilterRow = (props) => {
  //Column
  const [ShowPopDrop, setShowPopDrop] = useState(false);
  const handlePopDrop = (event) => {
    setShowPopDrop(!ShowPopDrop);
  };

  //Filter
  const [ShowFilter, setShowFilter] = useState(false);
  const handleShowFilter = (event) => {
    setShowFilter(true);
  };
  const handleHideFilter = (event) => {
    setShowFilter(false);
  };

  return (
    <React.Fragment>
      <div className="filter-row d-flex align-items-center">
        <div className="app-search header-search">
          <span className="search-icon"> </span>
          <div className="position-relative">
            <input
              type="search"
              className="form-control input-search-left"
              placeholder="Search..."
              value=""
            />
          </div>
        </div>
       
        <div className="ml-auto d-flex">
          <button
            className="btn btn-outline-light mr-2"
            onClick={handleShowFilter}
          >
            <IconFilter className="mr-2" />
            Filter
          </button>
          <div className="position-relative">
            <button
              className="btn btn-outline-light position-relative"
              onClick={handlePopDrop}
            >
              <IconColumn />
            </button>
            {ShowPopDrop && <ColumnFilter />}
          </div>
        </div>
      </div>
      {/* Filter Popup */}
      {ShowFilter && <FilterPop hideFilter={handleHideFilter} />}
    </React.Fragment>
  );
};

export default ProblemContainerFilterRow;
