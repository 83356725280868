import React, { useState } from "react";
import CustomSelect from "../../../common/CustomSelect";
import { IconMinus } from "../../../common/icons";
import UploadDocument from "../../../common/uploadDocument";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import SettingsTab from "../common-elements/SettingsTabs";

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const SAemaildocumentConfiguration = () => {
  const [verifyEmail, setverifyEmail] = useState(false);

  const handleVerifyEmail = () => {
    setverifyEmail(!verifyEmail);
  };

  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <SettingsTab
            activeTab={14}
            button={
              <div className="d-flex">
                <button className="mr-2 btn btn-outline-light ">Cancle</button>
                <button className=" btn btn-success ">Save Changes</button>
              </div>
            }
          />
        </div>
      </div>
      <div className="page-fluid">
        <div className="card p-20 mb-20">
          <h3 className="mb-20 font-16">Domain Settings</h3>
          <div className="row">
            <div className="col-md-6 form-group">
              <label>Mailbox</label>
              <div className="d-flex align-items-center">
                {verifyEmail === false ? (
                  <input
                    type="text"
                    placeholder="Enter Address"
                    className="form-control"
                  />
                ) : (
                  <React.Fragment>
                    <input
                      type="text"
                      placeholder="Enter Address"
                      className="form-control"
                      value="company.email@gmail.com"
                      disabled
                    />
                  </React.Fragment>
                )}
                {verifyEmail === false && (
                  <button
                    className="btn btn-primary ml-2 text-nowrap"
                    onClick={handleVerifyEmail}
                  >
                    Verify Email
                  </button>
                )}
              </div>
              {verifyEmail === true && (
                <div className="mt-1">
                  <div className="text-muted">
                    We have sent a confirmation on your email. Check your
                    mailbox
                  </div>
                  <a href="#!" className="text-primary">
                    Send Again
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="hr-light"></div>
          <div className="pt-10">
            <h3 className="font-16 mb-20">Email Content</h3>
            <div className="form-group">
              <label>Email From</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Email From"
              />
            </div>
            <div className="form-group">
              <label>Email Subject</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Subject"
              />
            </div>
            <div className="form-group">
              <label>Email Body</label>
              <Editor
                editorClassName="editorClassName"
                placeholder="Enter Messsage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SAemaildocumentConfiguration;
