import React, { useState } from "react";
import { IconDownload, IconPlus } from "../../../common/icons";
import Pagination from "../../../common/pagination/index";
import SubHeader from "../common-elements/SubHeader";
import AddNewReport from "./elements/AddNewReport";
import StaticTableContent from "./elements/StaticTableContent";

const EmailNotificationsSettings = () => {
  const [showAddModal, setShowAddModal] = useState(false);

  const handleShowAddModal = (event) => {
    setShowAddModal(true);
  };

  const handleHideAddModal = (event) => {
    setShowAddModal(false);
  };

  return (
    <div className="position-relative subheader-wrapper">
      <SubHeader
        mainTitle="Email Notification Reports"
        button={
          <div className="d-flex">
            <button
              className="ml-auto btn btn-success"
              onClick={handleShowAddModal}
            >
              <IconPlus className="mr-2" />
              Add New Report
            </button>
          </div>
        }
      />
      <div className="page-fluid">
        <div className="top-page">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h4 className="font-20 mb-0">6 Reports</h4>
            <Pagination />
          </div>
        </div>

        {/* Static Table Content */}
        <StaticTableContent />

        {/* Add New Truck */}
        {showAddModal && (
          <AddNewReport
            onCancel={handleHideAddModal}
            onSubmit={handleHideAddModal}
          />
        )}
      </div>
    </div>
  );
};

export default EmailNotificationsSettings;
