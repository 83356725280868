export const getAllThemes = async () => {
  return new Promise((resolve, _) => {
    setTimeout(() => {
      // resolve([
      //     { value: "hsl(218, 91%, 59%)", label: "Blue", hvalue: '218', svalue: '91%', lvalue: '59%' },
      //     { value: "#65c466", label: "Green" },
      //     { value: "#eb4e3d", label: "Red" },
      //   ]);
      resolve({
        themes: [
          {
            name: "Default PortPro",
            themeData: {
              "--color-primary-50": "hsl(221, 90%, 96%)", 
              "--color-primary-50-h": "221", 
              "--color-primary-50-s": "90%", 
              "--color-primary-50-l": "96%", 

              "--color-primary-100": "hsl(219, 91%, 87%)",
              "--color-primary-100-h": "219",
              "--color-primary-100-s": "91%",
              "--color-primary-100-l": "87%",

              "--color-primary-200": "hsl(220, 92%, 79%)",
              "--color-primary-200-h": "220",
              "--color-primary-200-s": "492%",
              "--color-primary-200-l": "79%",

              "--color-primary-300": "hsl(220, 92%, 71%)",
              "--color-primary-300-h": "220",
              "--color-primary-300-s": "92%",
              "--color-primary-300-l": "71%",

              "--color-primary-400": "hsl(219, 92%, 63%)",
              "--color-primary-400-h": "219",
              "--color-primary-400-s": "92%",
              "--color-primary-400-l": "63%",

              "--color-primary-500": "hsl(218, 91%, 59%)",
              "--color-primary-500-h": "218",
              "--color-primary-500-s": "91%",
              "--color-primary-500-l": "59%",

              "--color-primary": "hsl(218, 91%, 59%)",
              "--color-primary-h": "218",
              "--color-primary-s": "91%",
              "--color-primary-l": "59%",

              "--color-primary-600": "hsl(220, 72%, 53%)",
              "--color-primary-600-h": "220",
              "--color-primary-600-s": "72%",
              "--color-primary-600-l": "53%",

              "--color-primary-700": "hsl(219, 65%, 41%)",
              "--color-primary-700-h": "219",
              "--color-primary-700-s": "65%",
              "--color-primary-700-l": "41%",

              "--color-primary-800": "hsl(219, 65%, 35%)",
              "--color-primary-800-h": "219",
              "--color-primary-800-s": "65%",
              "--color-primary-800-l": "35%",

              "--color-primary-900": "hsl(220, 65%, 23%)",
              "--color-primary-900-h": "220",
              "--color-primary-900-s": "65%",
              "--color-primary-900-l": "23%",
            },
          },
          {
            name: "Lime",
            themeData: {

              "--color-primary-50": "hsl(86, 50%, 95%)", 
              "--color-primary-50-h": "86", 
              "--color-primary-50-s": "50%", 
              "--color-primary-50-l": "95%", 

              "--color-primary-100": "hsl(84, 46%, 86%)",
              "--color-primary-100-h": "84",
              "--color-primary-100-s": "46%",
              "--color-primary-100-l": "86%",

              "--color-primary-200": "hsl(84, 45%, 77%)",
              "--color-primary-200-h": "84",
              "--color-primary-200-s": "45%",
              "--color-primary-200-l": "77%",

              "--color-primary-300": "hsl(84, 45%, 68%)",
              "--color-primary-300-h": "84",
              "--color-primary-300-s": "45%",
              "--color-primary-300-l": "68%",

              "--color-primary-400": "hsl(85, 45%, 62%)",
              "--color-primary-400-h": "85",
              "--color-primary-400-s": "45%",
              "--color-primary-400-l": "62%",

              "--color-primary-500": "hsl(85, 44%, 56%)",
              "--color-primary-500-h": "85",
              "--color-primary-500-s": "44%",
              "--color-primary-500-l": "56%",

              "--color-primary": "hsl(85, 44%, 56%)",
              "--color-primary-h": "85",
              "--color-primary-s": "44%",
              "--color-primary-l": "56%",

              "--color-primary-600": "hsl(86, 38%, 51%)",
              "--color-primary-600-h": "86",
              "--color-primary-600-s": "38%",
              "--color-primary-600-l": "51%",

              "--color-primary-700": "hsl(89, 38%, 45%)",
              "--color-primary-700-h": "89",
              "--color-primary-700-s": "38%",
              "--color-primary-700-l": "45%",

              "--color-primary-800": "hsl(92, 38%, 39%)",
              "--color-primary-800-h": "92",
              "--color-primary-800-s": "38%",
              "--color-primary-800-l": "39%",

              "--color-primary-900": "hsl(97, 43%, 28%)",
              "--color-primary-900-h": "97",
              "--color-primary-900-s": "43%",
              "--color-primary-900-l": "28%",

            },
          },
          {
            name: "Green",
            themeData: {

              "--color-primary-50": "hsl(120, 35%, 94%)", 
              "--color-primary-50-h": "120", 
              "--color-primary-50-s": "35%", 
              "--color-primary-50-l": "94%", 

              "--color-primary-100": "hsl(113, 33%, 85%)",
              "--color-primary-100-h": "13",
              "--color-primary-100-s": "33%",
              "--color-primary-100-l": "85%",

              "--color-primary-200": "hsl(114, 33%, 75%)",
              "--color-primary-200-h": "114",
              "--color-primary-200-s": "33%",
              "--color-primary-200-l": "75%",

              "--color-primary-300": "hsl(113, 33%, 65%)",
              "--color-primary-300-h": "113",
              "--color-primary-300-s": "33%",
              "--color-primary-300-l": "65%",

              "--color-primary-400": "hsl(112, 33%, 58%)",
              "--color-primary-400-h": "112",
              "--color-primary-400-s": "33%",
              "--color-primary-400-l": "58%",

              "--color-primary-500": "hsl(111, 33%, 52%)",
              "--color-primary-500-h": "111",
              "--color-primary-500-s": "33%",
              "--color-primary-500-l": "52%",

              "--color-primary": "hsl(111, 33%, 52%)",
              "--color-primary-h": "111",
              "--color-primary-s": "33%",
              "--color-primary-l": "52%",

              "--color-primary-600": "hsl(111, 31%, 47%)",
              "--color-primary-600-h": "111",
              "--color-primary-600-s": "31%",
              "--color-primary-600-l": "47%",

              "--color-primary-700": "hsl(111, 33%, 41%)",
              "--color-primary-700-h": "111",
              "--color-primary-700-s": "33%",
              "--color-primary-700-l": "41%",

              "--color-primary-800": "hsl(111, 35%, 36%)",
              "--color-primary-800-h": "111",
              "--color-primary-800-s": "35%",
              "--color-primary-800-l": "36%",

              "--color-primary-900": "hsl(112, 40%, 26%)",
              "--color-primary-900-h": "112",
              "--color-primary-900-s": "40%",
              "--color-primary-900-l": "26%",

            },
          },
          {
            name: "Red",
            themeData: {

              "--color-primary-50": "hsl(353, 73%, 96%)", 
              "--color-primary-50-h": "353", 
              "--color-primary-50-s": "73%", 
              "--color-primary-50-l": "96%", 

              "--color-primary-100": "hsl(354, 71%, 89%)",
              "--color-primary-100-h": "354",
              "--color-primary-100-s": "71%",
              "--color-primary-100-l": "89%",

              "--color-primary-200": "hsl(2, 56%, 75%)",
              "--color-primary-200-h": "2",
              "--color-primary-200-s": "56%",
              "--color-primary-200-l": "75%",

              "--color-primary-300": "hsl(2, 54%, 65%)",
              "--color-primary-300-h": "2",
              "--color-primary-300-s": "54%",
              "--color-primary-300-l": "65%",

              "--color-primary-400": "hsl(4, 67%, 60%)",
              "--color-primary-400-h": "4",
              "--color-primary-400-s": "67%",
              "--color-primary-400-l": "60%",

              "--color-primary-500": "hsl(6, 73%, 57%)",
              "--color-primary-500-h": "6",
              "--color-primary-500-s": "73%",
              "--color-primary-500-l": "57%",

              "--color-primary": "hsl(6, 73%, 57%)",
              "--color-primary-h": "6",
              "--color-primary-s": "73%",
              "--color-primary-l": "57%",

              "--color-primary-600": "hsl(4, 63%, 54%)",
              "--color-primary-600-h": "4",
              "--color-primary-600-s": "63%",
              "--color-primary-600-l": "54%",

              "--color-primary-700": "hsl(3, 55%, 49%)",
              "--color-primary-700-h": "3",
              "--color-primary-700-s": "55%",
              "--color-primary-700-l": "49%",

              "--color-primary-800": "hsl(3, 58%, 45%)",
              "--color-primary-800-h": "3",
              "--color-primary-800-s": "58%",
              "--color-primary-800-l": "45%",

              "--color-primary-900": "hsl(4, 63%, 40%)",
              "--color-primary-900-h": "4",
              "--color-primary-900-s": "63%",
              "--color-primary-900-l": "40%",

            },
          },
          {
            name: "Yellow",
            themeData: {

              "--color-primary-50": "hsl(55, 100%, 96%)", 
              "--color-primary-50-h": "55", 
              "--color-primary-50-s": "100%", 
              "--color-primary-50-l": "96%", 

              "--color-primary-100": "hsl(54, 96%, 89%)",
              "--color-primary-100-h": "54",
              "--color-primary-100-s": "96%",
              "--color-primary-100-l": "89%",

              "--color-primary-200": "hsl(54, 96%, 83%)",
              "--color-primary-200-h": "54",
              "--color-primary-200-s": "96%",
              "--color-primary-200-l": "83%",

              "--color-primary-300": "hsl(53, 97%, 76%)",
              "--color-primary-300-h": "53",
              "--color-primary-300-s": "97%",
              "--color-primary-300-l": "76%",

              "--color-primary-400": "hsl(54, 96%, 72%)",
              "--color-primary-400-h": "54",
              "--color-primary-400-s": "96%",
              "--color-primary-400-l": "72%",

              "--color-primary-500": "hsl(53, 96%, 68%)",
              "--color-primary-500-h": "53",
              "--color-primary-500-s": "96%",
              "--color-primary-500-l": "68%",

              "--color-primary": "hsl(53, 96%, 68%)",
              "--color-primary-h": "53",
              "--color-primary-s": "96%",
              "--color-primary-l": "68%",

              "--color-primary-600": "hsl(48, 91%, 66%)",
              "--color-primary-600-h": "48",
              "--color-primary-600-s": "91%",
              "--color-primary-600-l": "66%",

              "--color-primary-700": "hsl(42, 86%, 63%)",
              "--color-primary-700-h": "42",
              "--color-primary-700-s": "86%",
              "--color-primary-700-l": "63%",

              "--color-primary-800": "hsl(36, 82%, 60%)",
              "--color-primary-800-h": "36",
              "--color-primary-800-s": "82%",
              "--color-primary-800-l": "60%",

              "--color-primary-900": "hsl(26, 78%, 56%)",
              "--color-primary-900-h": "26",
              "--color-primary-900-s": "78%",
              "--color-primary-900-l": "56%",

            },
          },
          {
            name: "Orange Mid",
            themeData: {

              "--color-primary-50": "hsl(47, 93%, 94%)", 
              "--color-primary-50-h": "55", 
              "--color-primary-50-s": "100%", 
              "--color-primary-50-l": "96%", 

              "--color-primary-100": "hsl(45, 92%, 86%)",
              "--color-primary-100-h": "54",
              "--color-primary-100-s": "96%",
              "--color-primary-100-l": "89%",

              "--color-primary-200": "hsl(45, 91%, 77%)",
              "--color-primary-200-h": "54",
              "--color-primary-200-s": "96%",
              "--color-primary-200-l": "83%",

              "--color-primary-300": "hsl(46, 91%, 69%)",
              "--color-primary-300-h": "53",
              "--color-primary-300-s": "97%",
              "--color-primary-300-l": "76%",

              "--color-primary-400": "hsl(44, 91%, 64%)",
              "--color-primary-400-h": "54",
              "--color-primary-400-s": "96%",
              "--color-primary-400-l": "72%",

              "--color-primary-500": "hsl(42, 91%, 62%)",
              "--color-primary-500-h": "53",
              "--color-primary-500-s": "96%",
              "--color-primary-500-l": "68%",

              "--color-primary": "hsl(42, 91%, 62%)",
              "--color-primary-h": "53",
              "--color-primary-s": "96%",
              "--color-primary-l": "68%",

              "--color-primary-600": "hsl(39, 89%, 60%)",
              "--color-primary-600-h": "48",
              "--color-primary-600-s": "91%",
              "--color-primary-600-l": "66%",

              "--color-primary-700": "hsl(34, 88%, 59%)",
              "--color-primary-700-h": "42",
              "--color-primary-700-s": "86%",
              "--color-primary-700-l": "63%",

              "--color-primary-800": "hsl(30, 86%, 58%)",
              "--color-primary-800-h": "36",
              "--color-primary-800-s": "82%",
              "--color-primary-800-l": "60%",

              "--color-primary-900": "hsl(23, 84%, 56%)",
              "--color-primary-900-h": "36",
              "--color-primary-900-s": "82%",
              "--color-primary-900-l": "60%",

            },
          },
          {
            name: "Orange",
            themeData: {

              "--color-primary-50": "hsl(11, 55%, 94%)", 
              "--color-primary-50-h": "11", 
              "--color-primary-50-s": "55%", 
              "--color-primary-50-l": "94%", 

              "--color-primary-100": "hsl(16, 78%, 86%) ",
              "--color-primary-100-h": "16",
              "--color-primary-100-s": "78%",
              "--color-primary-100-l": "86%",

              "--color-primary-200": "hsl(16, 79%, 77%)",
              "--color-primary-200-h": "16",
              "--color-primary-200-s": "79%",
              "--color-primary-200-l": "77%",

              "--color-primary-300": "hsl(16, 81%, 68%)",
              "--color-primary-300-h": "16",
              "--color-primary-300-s": "81%",
              "--color-primary-300-l": "68%",

              "--color-primary-400": "hsl(16, 82%, 62%)",
              "--color-primary-400-h": "16",
              "--color-primary-400-s": "82%",
              "--color-primary-400-l": "62%",

              "--color-primary-500": "hsl(14, 83%, 57%)",
              "--color-primary-500-h": "14",
              "--color-primary-500-s": "83%",
              "--color-primary-500-l": "57%",

              "--color-primary": "hsl(14, 83%, 57%)",
              "--color-primary-h": "14",
              "--color-primary-s": "83%",
              "--color-primary-l": "57%",

              "--color-primary-600": "hsl(14, 75%, 54%)",
              "--color-primary-600-h": "14",
              "--color-primary-600-s": "75%",
              "--color-primary-600-l": "54%",

              "--color-primary-700": "hsl(14, 67%, 51%)",
              "--color-primary-700-h": "14",
              "--color-primary-700-s": "67%",
              "--color-primary-700-l": "51%",

              "--color-primary-800": "hsl(14, 66%, 47%)",
              "--color-primary-800-h": "14",
              "--color-primary-800-s": "66%",
              "--color-primary-800-l": "47%",

              "--color-primary-900": "hsl(13, 68%, 41%)",
              "--color-primary-900-h": "13",
              "--color-primary-900-s": "68%",
              "--color-primary-900-l": "41%",

            },
          },
          {
            name: "Purple",
            themeData: {

              "--color-primary-50": "hsl(261, 41%, 93%)", 
              "--color-primary-50-h": "261", 
              "--color-primary-50-s": "41%", 
              "--color-primary-50-l": "93%", 

              "--color-primary-100": "hsl(259, 40%, 84%) ",
              "--color-primary-100-h": "259",
              "--color-primary-100-s": "40%",
              "--color-primary-100-l": "84%",

              "--color-primary-200": "hsl(257, 41%, 73%)",
              "--color-primary-200-h": "257",
              "--color-primary-200-s": "41%",
              "--color-primary-200-l": "73%",

              "--color-primary-300": "hsl(259, 42%, 62%)",
              "--color-primary-300-h": "259",
              "--color-primary-300-s": "42%",
              "--color-primary-300-l": "62%",

              "--color-primary-400": "hsl(258, 42%, 55%)",
              "--color-primary-400-h": "258",
              "--color-primary-400-s": "42%",
              "--color-primary-400-l": "55%",

              "--color-primary-500": "hsl(258, 47%, 47%)",
              "--color-primary-500-h": "258",
              "--color-primary-500-s": "47%",
              "--color-primary-500-l": "47%",

              "--color-primary": "hsl(258, 47%, 47%)",
              "--color-primary-h": "258",
              "--color-primary-s": "47%",
              "--color-primary-l": "47%",

              "--color-primary-600": "hsl(256, 49%, 45%)",
              "--color-primary-600-h": "256",
              "--color-primary-600-s": "49%",
              "--color-primary-600-l": "45%",

              "--color-primary-700": "hsl(254, 53%, 42%)",
              "--color-primary-700-h": "254",
              "--color-primary-700-s": "53%",
              "--color-primary-700-l": "42%",

              "--color-primary-800": "hsl(252, 56%, 39%)",
              "--color-primary-800-h": "252",
              "--color-primary-800-s": "56%",
              "--color-primary-800-l": "39%",

              "--color-primary-900": "hsl(248, 63%, 34%)",
              "--color-primary-900-h": "248",
              "--color-primary-900-s": "63%",
              "--color-primary-900-l": "34%",

            },
          },
          {
            name: "Pink",
            themeData: {
              "--color-primary-50":"hsl(338, 58%, 94%)", 
              "--color-primary-50-h": "338", 
              "--color-primary-50-s": "58%", 
              "--color-primary-50-l": "94%", 
              "--color-primary-100":"hsl(338, 61%, 84%)",
              "--color-primary-100-h": "338",
              "--color-primary-100-s": "61%",
              "--color-primary-100-l": "84%",
              "--color-primary-200":"hsl(340, 62%, 74%)",
              "--color-primary-200-h": "340",
              "--color-primary-200-s": "62%",
              "--color-primary-200-l": "74%",
              "--color-primary-300":"hsl(340, 64%, 65%)",
              "--color-primary-300-h": "340",
              "--color-primary-300-s": "64%",
              "--color-primary-300-l": "65%",
              "--color-primary-400": "hsl(341, 65%, 58%)",
              "--color-primary-400-h": "341",
              "--color-primary-400-s": "65%",
              "--color-primary-400-l": "58%",
              "--color-primary-500": "hsl(343, 66%, 53%)",
              "--color-primary-500-h": "343",
              "--color-primary-500-s": "66%",
              "--color-primary-500-l": "53%",
              "--color-primary": "hsl(343, 66%, 53%)",
              "--color-primary-h": "343",
              "--color-primary-s": "66%",
              "--color-primary-l": "53%",
              "--color-primary-600": "hsl(341, 59%, 49%)",
              "--color-primary-600-h": "341",
              "--color-primary-600-s": "59%",
              "--color-primary-600-l": "49%",
              "--color-primary-700": "hsl(340, 59%, 44%)",
              "--color-primary-700-h": "340",
              "--color-primary-700-s": "59%",
              "--color-primary-700-l": "44%",
              "--color-primary-800": "hsl(336, 60%, 39%)",
              "--color-primary-800-h": "336",
              "--color-primary-800-s": "60%",
              "--color-primary-800-l": "39%",
              "--color-primary-900":"hsl(329, 61%, 30%)",
              "--color-primary-900-h": "329",
              "--color-primary-900-s": "61%",
              "--color-primary-900-l": "30%",
            },
          },

          {
            name: "Cyan",
            themeData: {

              "--color-primary-50": "hsl(189, 64%, 94%)", 
              "--color-primary-50-h": "189", 
              "--color-primary-50-s": "64%", 
              "--color-primary-50-l": "94%", 

              "--color-primary-100": "hsl(189, 65%, 85%) ",
              "--color-primary-100-h": "189",
              "--color-primary-100-s": "65%",
              "--color-primary-100-l": "85%",

              "--color-primary-200": "hsl(189, 64%, 75%)",
              "--color-primary-200-h": "189",
              "--color-primary-200-s": "64%",
              "--color-primary-200-l": "75%",

              "--color-primary-300": "hsl(190, 62%, 66%)",
              "--color-primary-300-h": "190",
              "--color-primary-300-s": "62%",
              "--color-primary-300-l": "66%",

              "--color-primary-400": "hsl(190, 60%, 61%)",
              "--color-primary-400-h": "190",
              "--color-primary-400-s": "60%",
              "--color-primary-400-l": "61%",

              "--color-primary-500": "hsl(192, 57%, 58%)",
              "--color-primary-500-h": "192",
              "--color-primary-500-s": "57%",
              "--color-primary-500-l": "58%",

              "--color-primary": "hsl(192, 57%, 58%)",
              "--color-primary-h": "192",
              "--color-primary-s": "57%",
              "--color-primary-l": "58%",

              "--color-primary-600": "hsl(191, 47%, 52%)",
              "--color-primary-600-h": "191",
              "--color-primary-600-s": "47%",
              "--color-primary-600-l": "52%",

              "--color-primary-700": "hsl(192, 43%, 45%)",
              "--color-primary-700-h": "192",
              "--color-primary-700-s": "43%",
              "--color-primary-700-l": "45%",

              "--color-primary-800": "hsl(189, 42%, 39%)",
              "--color-primary-800-h": "189",
              "--color-primary-800-s": "42%",
              "--color-primary-800-l": "39%",

              "--color-primary-900": "hsl(185, 42%, 27%)",
              "--color-primary-900-h": "185",
              "--color-primary-900-s": "42%",
              "--color-primary-900-l": "27%",

            },
          },
        ],
      });
    }, 1000);
  });
};
