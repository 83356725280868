import React, { Component } from "react";
import { IconPen, IconTrash } from "../../../../../common/icons";

class StaticTableContent extends Component {
  render() {
    return (
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 305.8px)" }}
      >
        <table className="table table-card ">
          <thead>
            <tr>
              <th width="">First name</th>
              <th width="">Last Name</th>
              <th width="">Truck</th>
              <th>Fleet Owner</th>
              <th>Phone</th>
              <th>DL Exp</th>
              <th>Med.Exp.</th>
              <th>Sealink exp.</th>
              <th>Sealink #</th>
              <th>Twic exp.</th>
              <th>Birth date</th>
              <th>Hire date</th>
              <th>Email</th>
              <th>Emerg. contact#</th>
              <th>Emerg. relation</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
            <tr>
              <td>philip</td>
              <td>Edwards</td>
              <td>DMC123</td>
              <td>Wade Warren</td>
              <td>+1 300-555-0135</td>
              <td>15/08/2017</td>
              <td>12/06/2020</td>
              <td>18/09/2016</td>
              <td>123456</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>18/09/2016</td>
              <td>tanya.hill@example.com</td>
              <td>Guy Hawkins</td>
              <td>(808) 555-0111</td>
              <td>Panas</td>
              <td>
                <button className="btn btn-none text-primary">
                  <IconPen />
                </button>
              </td>
              <td>
                <button className="btn btn-none text-muted">
                  <IconTrash />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default StaticTableContent;
