import React, { useState } from "react";
import { IconLink, IconPlus } from "../../../common/icons";
import Pagination from "../../../common/pagination/index";
import SubHeader from "../common-elements/SubHeader";
import StaticTableContent from "./elements/StaticTableContent";

const AssignTruckSettings = () => {
  const [isCardActive, setCardActive] = useState(1);
  const [showAddModal, setShowAddModal] = useState(false);

  const handleShowAddModal = (event) => {
    setShowAddModal(true);
  };

  const handleHideAddModal = (event) => {
    setShowAddModal(false);
  };

  return (
    <div className="position-relative subheader-wrapper">
      <SubHeader
        mainTitle="Assign Truck"
        button={
          <div className="d-flex">
            <button className="btn btn-outline-light mr-2">
              <IconLink className="mr-2" />
              Connect to QuickBooks
            </button>
            <button
              className="ml-auto btn btn-success"
              onClick={handleShowAddModal}
            >
              <IconPlus className="mr-2" />
              Add New Driver
            </button>
          </div>
        }
      />
      <div className="page-fluid">
        <div className="top-page">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h4 className="font-20 mb-0">Drivers</h4>
            <Pagination />
          </div>
        </div>

        {/* Static Table Content */}
        <StaticTableContent />
      </div>
    </div>
  );
};

export default AssignTruckSettings;
