import React from "react";
import { Modal } from "react-bootstrap";
import { IconPlus } from "../../../common/icons";

const EditGroupModal = (props) => {
  const { showModal = true, onCancel = () => {}, onSubmit = () => {} } = props;
  return (
    <Modal show={showModal} centered animation={false} size="sm">
      <Modal.Header>
        <Modal.Title>Edit Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form action="">
          <div className="form-group">
            <label className="col-form-label">Group name</label>
            <input className="form-control" placeholder="Day Drivers" />
          </div>

          <div className="form-group">
            <label className="col-form-label">Members</label>
            <div className="app-search bg-gray-50 rounded-lg mb-10">
              <input
                type="text"
                className="input-search-left form-control py-2"
                placeholder="Search..."
              />
              <span className="search-icon ml-1"></span>
            </div>
            <div className="members-list mt-20">
              <div class="d-flex align-items-center mb-10">
                <div class="flex-shrink-0 mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                    class="avatar rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <div class="flex-grow-1">
                  <h5 class="font-14">Brooklyn Simmons</h5>
                </div>
                <div class="flex-shrink-0">
                  <button class="btn btn-primary">
                    <IconPlus className="mr-10" />
                    Add
                  </button>
                </div>
              </div>
              <div class="d-flex align-items-center mb-10">
                <div class="flex-shrink-0 mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                    class="avatar rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <div class="flex-grow-1">
                  <h5 class="font-14">Brooklyn Simmons</h5>
                </div>
                <div class="flex-shrink-0">
                  <button class="btn btn-primary">
                    <IconPlus className="mr-10" />
                    Add
                  </button>
                </div>
              </div>
              <div class="d-flex align-items-center mb-10">
                <div class="flex-shrink-0 mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                    class="avatar rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <div class="flex-grow-1">
                  <h5 class="font-14">Brooklyn Simmons</h5>
                </div>
                <div class="flex-shrink-0">
                  <button class="btn btn-outline-light">Added</button>
                </div>
              </div>
              <div class="d-flex align-items-center mb-10">
                <div class="flex-shrink-0 mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                    class="avatar rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <div class="flex-grow-1">
                  <h5 class="font-14">Brooklyn Simmons</h5>
                </div>
                <div class="flex-shrink-0">
                  <button class="btn btn-primary">
                    <IconPlus className="mr-10" />
                    Add
                  </button>
                </div>
              </div>
              <div class="d-flex align-items-center mb-10">
                <div class="flex-shrink-0 mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                    class="avatar rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <div class="flex-grow-1">
                  <h5 class="font-14">Brooklyn Simmons</h5>
                </div>
                <div class="flex-shrink-0">
                  <button class="btn btn-primary">
                    <IconPlus className="mr-10" />
                    Add
                  </button>
                </div>
              </div>
              <div class="d-flex align-items-center mb-10">
                <div class="flex-shrink-0 mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                    class="avatar rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <div class="flex-grow-1">
                  <h5 class="font-14">Brooklyn Simmons</h5>
                </div>
                <div class="flex-shrink-0">
                  <button class="btn btn-primary">
                    <IconPlus className="mr-10" />
                    Add
                  </button>
                </div>
              </div>
              <div class="d-flex align-items-center mb-10">
                <div class="flex-shrink-0 mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                    class="avatar rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <div class="flex-grow-1">
                  <h5 class="font-14">Brooklyn Simmons</h5>
                </div>
                <div class="flex-shrink-0">
                  <button class="btn btn-primary">
                    <IconPlus className="mr-10" />
                    Add
                  </button>
                </div>
              </div>
              <div class="d-flex align-items-center mb-10">
                <div class="flex-shrink-0 mr-2 align-self-center">
                  <img
                    src="https://themesbrand.com/minible/layouts/assets/images/users/avatar-7.jpg"
                    class="avatar rounded-circle"
                    width="30"
                    alt=""
                  />
                </div>
                <div class="flex-grow-1">
                  <h5 class="font-14">Brooklyn Simmons</h5>
                </div>
                <div class="flex-shrink-0">
                  <button class="btn btn-primary">
                    <IconPlus className="mr-10" />
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={onSubmit}>
          Save Edits
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditGroupModal;
