import React, { useState } from "react";
import CustomSelect from "../../../../common/CustomSelect";
import { IconCalendar, IconCheck, IconPlus, IconTrash } from "../../../../common/icons";
import { Modal } from "react-bootstrap";

const columns = ["Load", "From", "To", "Status", "Date & Time", "Payment"];

const options = [
  { value: "Cameron Williamson", label: "Cameron Williamson " },
  { value: "John Doe", label: "John Doe" },
  { value: "Alex Mendes", label: "Alex Mendes" },
];

const data = [
  {
    id: 1,
    load: "POP2_M122334",
    container: "KSS11111334",
    from: "2715 Ash Dr. San Jose, South Dakota 83475",
    to: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    containerStatus: "Pull Container",
    date: "06/11",
    time: "03:48 am",
    status: "Unapproved",
  },
  {
    id: 2,
    load: "POP2_M122334",
    container: "KSS11111334",
    from: "2715 Ash Dr. San Jose, South Dakota 83475",
    to: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    containerStatus: "Pull Container",
    date: "06/11",
    time: "03:48 am",
    status: "Unapproved",
  },
  {
    id: 3,
    load: "POP2_M122334",
    container: "KSS11111334",
    from: "2715 Ash Dr. San Jose, South Dakota 83475",
    to: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    containerStatus: "Pull Container",
    date: "06/11",
    time: "03:48 am",
    status: "Unapproved",
  },
  {
    id: 4,
    load: "POP2_M122334",
    container: "KSS11111334",
    from: "2715 Ash Dr. San Jose, South Dakota 83475",
    to: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    containerStatus: "Pull Container",
    date: "06/11",
    time: "03:48 am",
    status: "Unapproved",
  },
  {
    id: 5,
    load: "POP2_M122334",
    container: "KSS11111334",
    from: "2715 Ash Dr. San Jose, South Dakota 83475",
    to: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    containerStatus: "Pull Container",
    date: "06/11",
    time: "03:48 am",
    status: "Approved",
  },
  {
    id: 6,
    load: "POP2_M122334",
    container: "KSS11111334",
    from: "2715 Ash Dr. San Jose, South Dakota 83475",
    to: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    containerStatus: "Pull Container",
    date: "06/11",
    time: "03:48 am",
    status: "Approved",
  },
];

const DriverPaySettlement = () => {
  const [tabActive, setTabActive] = useState(1);
  const [isStatusHovered, setIsStatusHovered] = useState(null);
  const [isStatusChanged, setIsStatusChanged] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [inputValue, setinputValue] = useState("");

  const handleinput = (event) => {
    setinputValue(event.target.value);
  };

  const handleShowAddModal = (event) => {
    setShowAddModal(true);
  };

  const handleHideAddModal = (event) => {
    setShowAddModal(false);
  };

  const onMouseEnterHandler = (i) => {
    setIsStatusHovered(isStatusHovered === i ? null : i);
  };

  const onMouseLeaveHandler = (i) => {
    setIsStatusHovered(isStatusChanged === i && null);
  };

  const onStatusChanged = (i) => {
    setIsStatusChanged(isStatusChanged === i ? null : i);
    console.log("Approve Status changed Clicked");
  };

  const handleTabActive = (i) => {
    setTabActive(tabActive === i ? null : i);
  };

  return (
    <>
      <div className="d-flex flex-row align-items-center justify-content-between my-10 mx-15">
        <ul
          className="nav nav-compact bg-gray-50 align-items-center"
          role="tablist"
        >
          <li className="nav-item">
            <a
              className={`nav-link ${tabActive === 1 ? "active" : ""}`}
              data-bs-toggle="tab"
              href="#Loads"
              role="tab"
              onClick={() => handleTabActive(1)}
            >
              Unapproved
              <span className="badge badge-orange font-12 ml-2 badge-pill">
                4
              </span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${tabActive === 2 ? "active" : ""}`}
              data-bs-toggle="tab"
              href="#payments"
              role="tab"
              onClick={() => handleTabActive(2)}
            >
              Approved
            </a>
          </li>
        </ul>
        <button
          className="btn btn-outline-light font-weight-500"
          onClick={handleShowAddModal}
        >
          <IconPlus className="mr-2" />
          Add New Driver Pay
        </button>
      </div>

      {/* Settlement UnApproved */}
      {tabActive === 1 && (
        <div className="table-responsive bg-gray-50">
          <table className="table table-card table-card--ls">
            <thead>
              <tr>
                {columns.map((column) => {
                  return (
                    <th scope="col" key={column}>
                      {column}
                    </th>
                  );
                })}
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data
                .filter((datum) => datum.status === "Unapproved")
                .map((filteredData) => {
                  return (
                    <tr key={filteredData.id}>
                      <td>
                        <a
                          href="javascript:void(0)"
                          className="text-uppercase text-dark"
                        >
                          {filteredData.load}
                        </a>
                        <div className="text-muted">
                          Container: {filteredData.container}
                        </div>
                      </td>
                      <td>
                          {filteredData.from}
                      </td>
                      <td>{filteredData.to}</td>
                      <td style={{width: "15%"}}>
                        <input
                          className="form-control"
                          placeholder={filteredData.containerStatus}
                        />
                      </td>
                      <td>
                        {filteredData.date} - {filteredData.time}
                      </td>
                      <td style={{width: "10%"}}>
                        <div className="input-wrapper w-100">
                          <input
                            className="form-control"
                            placeholder="$50.00"
                            value={inputValue}
                            onChange={handleinput}
                          />
                          {inputValue && (
                            <div className="input-icon mr-n1">
                              <button className="btn btn-primary btn-sm rounded-3 px-1">
                                <IconCheck />
                              </button>
                            </div>
                          )}
                        </div>
                      </td>

                      <td style={{ width: "5%" }}>
                        <div className="text-center">
                          {filteredData.status == "Approved" ? (
                            <>
                              {isStatusHovered === filteredData.id ? (
                                <button
                                  className="btn btn-sm btn-danger font-10"
                                  onClick={() =>
                                    onStatusChanged(filteredData.id)
                                  }
                                >
                                  UnApprove
                                </button>
                              ) : (
                                <span
                                  className={`badge py-1 ${
                                    isStatusChanged === filteredData.id
                                      ? "badge-danger-light"
                                      : "badge-accent-100"
                                  }`}
                                  onMouseEnter={() =>
                                    onMouseEnterHandler(filteredData.id)
                                  }
                                  onMouseLeave={() =>
                                    onMouseLeaveHandler(filteredData.id)
                                  }
                                >
                                  {isStatusChanged === filteredData.id
                                    ? "Unapproved"
                                    : filteredData.status}
                                </span>
                              )}
                            </>
                          ) : filteredData.status == "Unapproved" ? (
                            <>
                              {isStatusHovered === filteredData.id ? (
                                <button
                                  className="btn btn-sm btn-success font-10"
                                  onClick={() =>
                                    onStatusChanged(filteredData.id)
                                  }
                                >
                                  Approve
                                </button>
                              ) : (
                                <span
                                  className={`badge py-1 ${
                                    isStatusChanged === filteredData.id
                                      ? "badge-accent-100"
                                      : "badge-danger-light"
                                  }`}
                                  onMouseEnter={() =>
                                    onMouseEnterHandler(filteredData.id)
                                  }
                                  onMouseLeave={() =>
                                    onMouseLeaveHandler(filteredData.id)
                                  }
                                >
                                  {isStatusChanged === filteredData.id
                                    ? "Approved"
                                    : "Unapproved"}
                                </span>
                              )}
                            </>
                          ) : (
                            <span className="badge badge-purple-50 py-1 ">
                              {filteredData.status}
                            </span>
                          )}
                        </div>
                      </td>
                      <td style={{ width: "2%" }}>
                        <div className="d-flex justify-content-end">
                          <button className="btn btn-link text-muted">
                            <IconTrash />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}

      {/* Settlement Approved */}
      {tabActive === 2 && (
        <div className="table-responsive bg-gray-50">
          <table className="table table-card table-card--ls">
            <thead>
              <tr>
                {columns.map((column) => {
                  return (
                    <th scope="col" key={column}>
                      {column}
                    </th>
                  );
                })}
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data
                .filter((datum) => datum.status === "Approved")
                .map((filteredData) => {
                  return (
                    <tr key={filteredData.id}>
                      <td>
                        <a
                          href="javascript:void(0)"
                          className="text-uppercase text-dark"
                        >
                          {filteredData.load}
                        </a>
                        <div className="text-muted">
                          Container: {filteredData.container}
                        </div>
                      </td>
                      <td>{filteredData.from}</td>
                      <td>{filteredData.to}</td>
                      <td>
                        <input
                          className="form-control"
                          placeholder={filteredData.containerStatus}
                        />
                      </td>
                      <td>
                        {filteredData.date} - {filteredData.time}
                      </td>
                      <td>
                        <input
                          className="form-control text-dark text-right bg-gray-50"
                          placeholder="$50.00"
                        />
                      </td>

                      <td style={{ width: "5%" }}>
                        <div className="text-center">
                          {filteredData.status == "Approved" ? (
                            <>
                              {isStatusHovered === filteredData.id ? (
                                <button
                                  className="btn btn-sm btn-danger font-10"
                                  onClick={() =>
                                    onStatusChanged(filteredData.id)
                                  }
                                >
                                  UnApprove
                                </button>
                              ) : (
                                <span
                                  className={`badge py-1 ${
                                    isStatusChanged === filteredData.id
                                      ? "badge-danger-light"
                                      : "badge-accent-100"
                                  }`}
                                  onMouseEnter={() =>
                                    onMouseEnterHandler(filteredData.id)
                                  }
                                  onMouseLeave={() =>
                                    onMouseLeaveHandler(filteredData.id)
                                  }
                                >
                                  {isStatusChanged === filteredData.id
                                    ? "Unapproved"
                                    : filteredData.status}
                                </span>
                              )}
                            </>
                          ) : filteredData.status == "Unapproved" ? (
                            <>
                              {isStatusHovered === filteredData.id ? (
                                <button
                                  className="btn btn-sm btn-success font-10"
                                  onClick={() =>
                                    onStatusChanged(filteredData.id)
                                  }
                                >
                                  Approve
                                </button>
                              ) : (
                                <span
                                  className={`badge py-1 ${
                                    isStatusChanged === filteredData.id
                                      ? "badge-accent-100"
                                      : "badge-danger-light"
                                  }`}
                                  onMouseEnter={() =>
                                    onMouseEnterHandler(filteredData.id)
                                  }
                                  onMouseLeave={() =>
                                    onMouseLeaveHandler(filteredData.id)
                                  }
                                >
                                  {isStatusChanged === filteredData.id
                                    ? "Approved"
                                    : "Unapproved"}
                                </span>
                              )}
                            </>
                          ) : (
                            <span className="badge badge-purple-50 py-1 ">
                              {filteredData.status}
                            </span>
                          )}
                        </div>
                      </td>
                      <td style={{ width: "2%" }}>
                        <div className="d-flex justify-content-end">
                          <button className="btn btn-link text-muted">
                            <IconTrash />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}

      {/* Add Driver Pay */}
      {showAddModal && (
        <Modal show={showAddModal} size="sm" centered>
          <Modal.Header>
            <Modal.Title>Add Driver Pay</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-wrapper">
              <form>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="col-form-label">
                      <span className="text-danger">*</span> Load
                    </label>
                    <input className="form-control" placeholder="Enter Load" />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="col-form-label">
                      <span className="text-danger">*</span> Container
                    </label>
                    <input
                      className="form-control"
                      placeholder="Enter Container"
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="col-form-label">
                      <span className="text-danger">*</span> Enter Description
                    </label>
                    <input
                      className="form-control"
                      placeholder="Enter Description"
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="col-form-label">
                      <span className="text-danger">*</span> From
                    </label>
                    <input
                      className="form-control"
                      placeholder="Enter From address"
                    />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="col-form-label">
                      <span className="text-danger">*</span> To
                    </label>
                    <input
                      className="form-control"
                      placeholder="Enter To address"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="col-form-label">
                      <span className="text-danger">*</span> Amount
                    </label>
                    <input className="form-control w-150" placeholder="$0.00" />
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-close" onClick={handleHideAddModal}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={handleHideAddModal}>
              Add Driver Pay
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default DriverPaySettlement;
