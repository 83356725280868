// IconLayout.js
import React from "react";
import DocumentationTabs from "../common-elements/subheader";
import IconTabs from "./iconTabs";
import DynamicTableHeight from "../../utils/dynamicTableHeight";

const IconLayout = ({ activeTabName, children, searchTerm, setSearchTerm }) => {
  const iconListStyles = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
    gap: "10px",
    maxHeight: `calc(100vh - var(--dynamic-table-height-offset))`,
    overflow: "auto",
  };
  return (
    <div className="position-relative subheader-wrapper">
      <div className="subheader">
        <div className="subheader__inner d-flex align-items-center py-0">
          <DocumentationTabs activeTab={1} />
        </div>
      </div>
      <div className="page-fluid">
        <div className="top-page" id="dth-top-page">
          <IconTabs activeTab={activeTabName} />
        </div>
        <div className="filter-row d-flex align-items-center mb-10">
          <div className="app-search header-search">
            <span className="search-icon"></span>
            <div className="position-relative">
              <input type="text" className="form-control input-search-left" placeholder="Search for an icon..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            </div>
          </div>
        </div>
        <DynamicTableHeight pageId={`iconpage-card-${activeTabName}`} />
        <div className="d-grid card mb-0 p-15" style={iconListStyles}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default IconLayout;
