import React, { useState } from 'react';
import CustomSelect from '../../../../../common/CustomSelect/index';

import {
  IconColumn,
  IconDownload,
  IconFilter,
  IconPen,
  IconTrash,
} from '../../../../../common/icons/index';

const options = [
  { value: 'Cameron Williamson', label: 'Cameron Williamson ' },
  { value: 'John Doe', label: 'John Doe' },
  { value: 'Alex Mendes', label: 'Alex Mendes' },
];

const columns = ['Company name', 'Contact name', 'Address', 'SCAC'];

const data = [
  {
    id: 1,
    company: `Zotware`,
    contact: 'Annette Black',
    address: '+1-202-555-0161',
    sca: 'KL12',
  },
  {
    id: 2,
    company: `Goodsilron`,
    contact: 'Cameron Williamson',
    address: '+1-202-555-0161',
    sca: 'KL12',
  },
  {
    id: 3,
    company: `Doncon`,
    contact: 'Esther Howard',
    address: '+1-202-555-0161',
    sca: 'KL12',
  },
  {
    id: 4,
    company: `Plexzap`,
    contact: 'Leslie Alexander',
    address: '+1-202-555-0161',
    sca: 'KL12',
  },
  {
    id: 5,
    company: `Lexiqvolax`,
    contact: 'Marvin McKinney',
    address: '+1-202-555-0161',
    sca: 'KL12',
  },
  {
    id: 6,
    company: `Plussunin`,
    contact: 'Darlene Robertson',
    address: '+1-202-555-0161',
    sca: 'KL12',
  },
  {
    id: 7,
    company: `Donware`,
    contact: 'Ralph Edwards',
    address: '+1-202-555-0161',
    sca: 'KL12',
  },
  {
    id: 8,
    company: `Xx-zobam`,
    contact: 'Eleanor Pena',
    address: '+1-202-555-0161',
    sca: 'KL12',
  },
  {
    id: 9,
    company: `Streethex`,
    contact: 'Devon Lane',
    address: '+1-202-555-0161',
    sca: 'KL12',
  },
  {
    id: 10,
    company: `Konex`,
    contact: 'Jenny Wilson',
    address: '+1-202-555-0161',
    sca: 'KL12',
  },
  {
    id: 11,
    company: `Dontechi`,
    contact: 'Floyd Miles',
    address: '+1-202-555-0161',
    sca: 'KL12',
  },
  {
    id: 12,
    company: `Treequote`,
    contact: 'Kathryn Murphy',
    address: '+1-202-555-0161',
    sca: 'KL12',
  },
  {
    id: 13,
    company: `Toughzap`,
    contact: 'Jacob Jones',
    address: '+1-202-555-0161',
    sca: 'KL12',
  },
  {
    id: 14,
    company: `Domzoom`,
    contact: 'Cody Fisher',
    address: '+1-202-555-0161',
    sca: 'KL12',
  },
  {
    id: 15,
    company: `Zencorporation`,
    contact: 'Wade Warren',
    address: '+1-202-555-0161',
    sca: 'KL12',
  },
  {
    id: 16,
    company: `Ganjaflex`,
    contact: 'Jerome Bell',
    address: '+1-202-555-0161',
    sca: 'KL12',
  },
];

const StaticTableContent = () => {
  return (
    <React.Fragment>
      {/* Table Content */}
      <div
        className="table-responsive"
        style={{ height: 'calc(100vh - 248px)' }}
      >
        <table className="table table-card">
          <thead>
            <tr>
              {columns.map((column) => {
                return (
                  <th scope="col" key={column}>
                    {column}
                  </th>
                );
              })}
              <th style={{ width: '2%' }}></th>
              <th style={{ width: '2%' }}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((datum) => {
              return (
                <tr key={datum.id}>
                  <td>{datum.company}</td>
                  <td>{datum.contact}</td>
                  <td>{datum.address}</td>
                  <td>{datum.sca}</td>
                  <td>
                    <button className="btn btn-link">
                      <IconPen className="text-muted" />
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-link">
                      <IconTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
export default StaticTableContent;
