
const PaymentTypeBox = ({ name, Icon, paymentDetails, setPaymentDetails }) => {
 return (
  <div
   className='col2__body__paymentType px-3 pb-1 my-2 mr-1 w-100 rounded-5 d-flex flex-column'
   id={name}
   onClick={() => {
    if (name === 'Card') {
     document.getElementById(name).style.cssText = `border: 1px solid black; fontWeight: bold;`
     document.getElementById('US bank account').style.cssText = `border: 1px solid #bfccda; fontWeight: light;`
     setPaymentDetails({ ...paymentDetails, method: 'Card' })
    } else {
     document.getElementById('US bank account').style.cssText = `border: 1px solid black; fontWeight: bold;`
     document.getElementById('Card').style.cssText = `border: 1px solid #bfccda; fontWeight: light;`
     setPaymentDetails({ ...paymentDetails, method: 'US bank account' })
    }
   }}>
   <span style={{ marginLeft: '-10px' }}>{Icon}</span>
   <span style={{ marginTop: '-5px' }}>{name}</span>
  </div>
 )
}


export default PaymentTypeBox;