const PlansList = () => {
  return (
    <div className="row align-items-end">
      <div className="col-lg-6 mb-40">
        <div className="bg-primary text-white px-20 rounded-top-5 py-2 d-flex justify-content-center align-items-center">
          Most Popular
        </div>
        <div className="border border-gray-100  rounded-5 rounded-top-0 rounded-top-0">
          <div className="d-flex flex-column justify-content-center align-items-center p-20">
            <div className="text-gray-900 font-weight-500 font-20 mb-20">
              Annually
            </div>
            <div className="text-gray-900 font-30 font-weight-500">$41</div>
            <div className="text-gray-500 font-12 font-weight-normal">
              per month (billed annually)
            </div>
            <div className="text-gray-500 font-12 font-weight-normal mb-20">
              $492 per year
            </div>
            <button className="btn btn-primary py-10 px-40 rounded w-100 justify-content-center align-items-center text-center">
              Try for Free
            </button>
          </div>
        </div>
      </div>
      <div className="col-lg-6 mb-40">
        <div className="border border-gray-100 rounded-5">
          <div className="d-flex flex-column justify-content-center align-items-center p-20">
            <div className="text-gray-900 font-weight-500 font-20 mb-20">
              Monthly
            </div>
            <div className="text-gray-900 font-30 font-weight-500">$50</div>
            <div className="text-gray-500 font-12 font-weight-normal">
              per month (billed annually)
            </div>
            <div className="text-gray-500 font-12 font-weight-normal mb-20">
              $492 per year
            </div>
            <button className="btn btn-primary py-10 px-40 rounded w-100 justify-content-center align-items-center text-center">
              Try for Free
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlansList;
