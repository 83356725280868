import React from 'react';
import { IconSortDown } from '../../../../common/icons';

const StaticTableContent = (props) => {
  return (
    <React.Fragment>
      <div className="bg-gray-50 p-20">
        <table className=" table table-card table-card--ls">
          <thead>
            <tr>
              <th className="text-dark">
                Equipment #
                <IconSortDown />
              </th>
              <th>Load #</th>
              <th>Container #</th>
              <th>Driver Name</th>
              <th>Status </th>
              <th>Location</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>EQ22335511</td>
              <td>KJS333322</td>
              <td>KJS333322</td>
              <td>
                <div class="d-flex">
                  <div class="mr-2 align-self-center">
                    <div class="avatar-circle ">
                      <div class=" ">Tw</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center">
                    <a href="#" class="text-dark">
                      Theresa Webb
                    </a>
                  </h5>
                </div>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-light">Status</span>
                </div>
              </td>

              <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
              <td>
                <td>
                  <div class="date">02 July, 2021</div>
                  <div class="date text-muted ">08:50pm</div>
                </td>
              </td>
            </tr>
            <tr>
              <td>EQ22335511</td>
              <td>KJS333322</td>
              <td>KJS333322</td>
              <td>
                <div class="d-flex">
                  <div class="mr-2 align-self-center">
                    <div class="avatar-circle ">
                      <div class=" ">Tw</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center">
                    <a href="#" class="text-dark">
                      Theresa Webb
                    </a>
                  </h5>
                </div>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-light">Status</span>
                </div>
              </td>

              <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
              <td>
                <td>
                  <div class="date">02 July, 2021</div>
                  <div class="date text-muted ">08:50pm</div>
                </td>
              </td>
            </tr>
            <tr>
              <td>EQ22335511</td>
              <td>KJS333322</td>
              <td>KJS333322</td>
              <td>
                <div class="d-flex">
                  <div class="mr-2 align-self-center">
                    <div class="avatar-circle ">
                      <div class=" ">Tw</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center">
                    <a href="#" class="text-dark">
                      Theresa Webb
                    </a>
                  </h5>
                </div>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-light">Status</span>
                </div>
              </td>

              <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
              <td>
                <td>
                  <div class="date">02 July, 2021</div>
                  <div class="date text-muted ">08:50pm</div>
                </td>
              </td>
            </tr>
            <tr>
              <td>EQ22335511</td>
              <td>KJS333322</td>
              <td>KJS333322</td>
              <td>
                <div class="d-flex">
                  <div class="mr-2 align-self-center">
                    <div class="avatar-circle ">
                      <div class=" ">Tw</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center">
                    <a href="#" class="text-dark">
                      Theresa Webb
                    </a>
                  </h5>
                </div>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-light">Status</span>
                </div>
              </td>

              <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
              <td>
                <td>
                  <div class="date">02 July, 2021</div>
                  <div class="date text-muted ">08:50pm</div>
                </td>
              </td>
            </tr>
            <tr>
              <td>EQ22335511</td>
              <td>KJS333322</td>
              <td>KJS333322</td>
              <td>
                <div class="d-flex">
                  <div class="mr-2 align-self-center">
                    <div class="avatar-circle ">
                      <div class=" ">Tw</div>
                    </div>
                  </div>
                  <h5 class="mb-0 align-self-center">
                    <a href="#" class="text-dark">
                      Theresa Webb
                    </a>
                  </h5>
                </div>
              </td>
              <td>
                <div className="">
                  <span className="badge badge-sm badge-light">Status</span>
                </div>
              </td>

              <td>2715 Ash Dr. San Jose, South Dakota 83475</td>
              <td>
                <td>
                  <div class="date">02 July, 2021</div>
                  <div class="date text-muted ">08:50pm</div>
                </td>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default StaticTableContent;
